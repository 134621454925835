import React, {useCallback} from "react";
import classNames from 'classnames';
import {isDataExist} from "common/commonFunction";
import {FILL_REQUIRED_FILED_SELECT} from "constant/ConstantMsg";

/**
 *  @summary      RadioButton 컴포넌트
 *  @author       주예리나
 *  @version
 *  @see          none
 *  @component    RadioButton
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  RadioButton 컴포넌트
 */
const RadioButton = (props) => {

    // props
    /*
      name            : Input tag의 값들을 구별하기 위한 name
      className       : 스타일 클래스
      buttonClassName : 버튼 스타일 클래스
      dataList        : Select option에 사용 될 데이터 리스트
      onChange        : Select box 변경 시 이벤트 전달을 위해 사용(name, 변경 값)으로 호출
      currentValue    : 현재 설정된 값
      disabled        : 사용불가로 만들기 위해사용
      validation      : validation 한 값(true, false)
      onFocus         : invalid 해제 하기 위한 focus 이벤트
    */

    const {
        name,
        className,
        buttonClassName,
        dataList,
        onChange,
        currentValue,
        disabled,
        validation,
        onFocus,
        compare
    } = props;

    /**
     *  @memberOf     RadioButton
     *  @function     handleChange
     *  @param        {event} e - 이벤트 객체
     *  @return       none
     *  @description  이벤트 처리 함수
     */
    const handleChange = useCallback((e) => {
        const targetValue = e.target.value;

        if (onChange !== undefined) {
            onChange(name, targetValue);
        }

        // invalid 할 때


    }, [name, onChange]);

    const handleFocus = useCallback((e) => {
        const targetValue = e.target.value;

        if (validation !== undefined && validation === false) {
            if (onFocus !== undefined) {
                onFocus(name, targetValue);
            }
        }
    }, [name, onFocus, validation]);

    /**
     *  @memberOf     RadioButton
     *  @function     dataListItem
     *  @param        none
     *  @return       {ReactElement} button list element
     *  @description  button list 출력 표시
     */
    const dataListItem = useCallback(() => {
        if (isDataExist(dataList)) {
            return dataList.map((item, index) => {

                const {text, value} = item; //할당

                let styleObject = {
                    // "font-weight-bolder px-4": true,
                    "active": value === currentValue,
                    "btn-brown": true,
                }

                // 버튼에 스타일이 존재하면
                if (isDataExist(buttonClassName)) {
                    const classArray = buttonClassName.split(" ");
                    for (const classUnit of classArray) {
                        styleObject[classUnit] = true;
                    }
                }

                return (
                    <button className={classNames(styleObject, "btn")}
                            value={value}
                            key={index}
                            onClick={handleChange}
                            onFocus={handleFocus}
                            disabled={disabled}>
                        {text}
                    </button>
                )
            })
        }
    }, [buttonClassName, currentValue, dataList, disabled, handleChange, handleFocus]);

    /**
     *  @memberOf     RadioButton
     *  @function     getInValidText
     *  @param        none
     *  @return       {ReactElement | null} invalid 문구
     *  @description  in valid 일 때 출력할 text 내용
     */
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false) {
            return (
                <>
                    <div className="invalid-feedback">{FILL_REQUIRED_FILED_SELECT}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [validation]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/

    return (
        <>
            <div className='d-block '>
                <div className={classNames('btn-group ', {
                    className,
                    'rounded': true,
                    'is-invalid': ((validation !== undefined && !validation) || compare),
                })}
                >
                    {dataListItem()}
                </div>
                {getInValidText()}
            </div>
        </>
    );
};

export default React.memo(RadioButton);
