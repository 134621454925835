/**
 *  @constant {array} CONCEPT_TAB_OPTION
 *  @description  MDR > Concept tab option list
 */
export const CONCEPT_TAB_OPTION = [
    {
        text: 'Information',
        value: 'Information'
    },
    {
        text: 'View',
        value: 'View'
    },
    {
        text: 'Matrix',
        value: 'Matrix'
    }
];


export const CRITERIA_OPTION = [
    {
        text: 'Inclusion',
        value: 'Inclusion'
    },
    {
        text: 'Exclusion',
        value: 'Exclusion'
    },
];


export const ENDPOINT_OPTION = [
    {
        text: 'Efficacy',
        value: 'Efficacy'
    },
    {
        text: 'Safety',
        value: 'Safety'
    },
    {
        text: 'PK',
        value: 'PK'
    },
    {
        text: 'PD',
        value: 'PD'
    },
    {
        text: 'DDI',
        value: 'DDI'
    },
    {
        text: 'Food effect',
        value: 'Food effect'
    },
];


/**
 *  @constant
 *  @type {object}
 *  @description  category list 데이터
 */
export const CATEGORY_DATA_LIST = [
    {
        text  : '임상시험 가이드라인',
        value :  '임상시험 가이드라인',
    },
    {
        text  : '진료지침',
        value : '진료지침',
    }
];

export const PARTNER_INFO_DEFINE = {
    NAME: 'name',
    CODE: 'code',
    DOMAIN: 'domain',
    PHONE: 'phone',
    ADDRESS: 'address',
    MANAGER: 'manager',
    DESCRIPTION: 'description',
    CREATED_TIME: 'createdTime',
    STATE: 'state',
    LOCK_REASON: 'lockReason',
    ID: 'ID',
};

/**
 *  @constant     {Array} ISO3166_CONSTANT
 *  @description  ISO 3166 필드에 표시될 목록
 */
export const ISO3166_CONSTANT = [
    {
        text: 'Afghanistan - AFG',
        value: 'AFG'
    },
    {
        text: 'Albania - ALB',
        value: 'ALB'
    },
    {
        text: 'Algeria - DZA',
        value: 'DZA'
    },
    {
        text: 'American Samoa - ASM',
        value: 'ASM'
    },
    {
        text: 'Andorra - AND',
        value: 'AND'
    },
    {
        text: 'Angola - AGO',
        value: 'AGO'
    },
    {
        text: 'Anguilla - AIA',
        value: 'AIA'
    },
    {
        text: 'Antarctica - ATA',
        value: 'ATA'
    },
    {
        text: 'Antigua and Barbuda - ATG',
        value: 'ATG'
    },
    {
        text: 'Argentina - ARG',
        value: 'ARG'
    },
    {
        text: 'Armenia - ARM',
        value: 'ARM'
    },
    {
        text: 'Aruba - ABW',
        value: 'ABW'
    },
    {
        text: 'Australia - AUS',
        value: 'AUS'
    },
    {
        text: 'Austria - AUT',
        value: 'AUT'
    },
    {
        text: 'Azerbaijan - AZE',
        value: 'AZE'
    },
    {
        text: 'Bahamas (the) - BHS',
        value: 'BHS'
    },
    {
        text: 'Bahrain - BHR',
        value: 'BHR'
    },
    {
        text: 'Bangladesh - BGD',
        value: 'BGD'
    },
    {
        text: 'Barbados - BRB',
        value: 'BRB'
    },
    {
        text: 'Belarus - BLR',
        value: 'BLR'
    },
    {
        text: 'Belgium - BEL',
        value: 'BEL'
    },
    {
        text: 'Belize - BLZ',
        value: 'BLZ'
    },
    {
        text: 'Benin - BEN',
        value: 'BEN'
    },
    {
        text: 'Bermuda - BMU',
        value: 'BMU'
    },
    {
        text: 'Bhutan - BTN',
        value: 'BTN'
    },
    {
        text: 'Bolivia (Plurinational State of) - BOL',
        value: 'BOL'
    },
    {
        text: 'Bonaire, Sint Eustatius and Saba - BES',
        value: 'BES'
    },
    {
        text: 'Bosnia and Herzegovina - BIH',
        value: 'BIH'
    },
    {
        text: 'Botswana - BWA',
        value: 'BWA'
    },
    {
        text: 'Bouvet Island - BVT',
        value: 'BVT'
    },
    {
        text: 'Brazil - BRA',
        value: 'BRA'
    },
    {
        text: 'British Indian Ocean Territory (the) - IOT',
        value: 'IOT'
    },
    {
        text: 'Brunei Darussalam - BRN',
        value: 'BRN'
    },
    {
        text: 'Bulgaria - BGR',
        value: 'BGR'
    },
    {
        text: 'Burkina Faso - BFA',
        value: 'BFA'
    },
    {
        text: 'Burundi - BDI',
        value: 'BDI'
    },
    {
        text: 'Cabo Verde - CPV',
        value: 'CPV'
    },
    {
        text: 'Cambodia - KHM',
        value: 'KHM'
    },
    {
        text: 'Cameroon - CMR',
        value: 'CMR'
    },
    {
        text: 'Canada - CAN',
        value: 'CAN'
    },
    {
        text: 'Cayman Islands (the) - CYM',
        value: 'CYM'
    },
    {
        text: 'Central African Republic (the) - CAF',
        value: 'CAF'
    },
    {
        text: 'Chad - TCD',
        value: 'TCD'
    },
    {
        text: 'Chile - CHL',
        value: 'CHL'
    },
    {
        text: 'China - CHN',
        value: 'CHN'
    },
    {
        text: 'Christmas Island - CXR',
        value: 'CXR'
    },
    {
        text: 'Cocos (Keeling) Islands (the) - CCK',
        value: 'CCK'
    },
    {
        text: 'Colombia - COL',
        value: 'COL'
    },
    {
        text: 'Comoros (the) - COM',
        value: 'COM'
    },
    {
        text: 'Congo (the Democratic Republic of the) - COD',
        value: 'COD'
    },
    {
        text: 'Congo (the) - COG',
        value: 'COG'
    },
    {
        text: 'Cook Islands (the) - COK',
        value: 'COK'
    },
    {
        text: 'Costa Rica - CRI',
        value: 'CRI'
    },
    {
        text: 'Croatia - HRV',
        value: 'HRV'
    },
    {
        text: 'Cuba - CUB',
        value: 'CUB'
    },
    {
        text: 'Curaçao - CUW',
        value: 'CUW'
    },
    {
        text: 'Cyprus - CYP',
        value: 'CYP'
    },
    {
        text: 'Czechia - CZE',
        value: 'CZE'
    },
    {
        text: 'Côte d\'Ivoire - CIV',
        value: 'CIV'
    },
    {
        text: 'Denmark - DNK',
        value: 'DNK'
    },
    {
        text: 'Djibouti - DJI',
        value: 'DJI'
    },
    {
        text: 'Dominica - DMA',
        value: 'DMA'
    },
    {
        text: 'Dominican Republic (the) - DOM',
        value: 'DOM'
    },
    {
        text: 'Ecuador - ECU',
        value: 'ECU'
    },
    {
        text: 'Egypt - EGY',
        value: 'EGY'
    },
    {
        text: 'El Salvador - SLV',
        value: 'SLV'
    },
    {
        text: 'Equatorial Guinea - GNQ',
        value: 'GNQ'
    },
    {
        text: 'Eritrea - ERI',
        value: 'ERI'
    },
    {
        text: 'Estonia - EST',
        value: 'EST'
    },
    {
        text: 'Eswatini - SWZ',
        value: 'SWZ'
    },
    {
        text: 'Ethiopia - ETH',
        value: 'ETH'
    },
    {
        text: 'Falkland Islands (the) [Malvinas] - FLK',
        value: 'FLK'
    },
    {
        text: 'Faroe Islands (the) - FRO',
        value: 'FRO'
    },
    {
        text: 'Fiji - FJI',
        value: 'FJI'
    },
    {
        text: 'Finland - FIN',
        value: 'FIN'
    },
    {
        text: 'France - FRA',
        value: 'FRA'
    },
    {
        text: 'French Guiana - GUF',
        value: 'GUF'
    },
    {
        text: 'French Polynesia - PYF',
        value: 'PYF'
    },
    {
        text: 'French Southern Territories (the) - ATF',
        value: 'ATF'
    },
    {
        text: 'Gabon - GAB',
        value: 'GAB'
    },
    {
        text: 'Gambia (the) - GMB',
        value: 'GMB'
    },
    {
        text: 'Georgia - GEO',
        value: 'GEO'
    },
    {
        text: 'Germany - DEU',
        value: 'DEU'
    },
    {
        text: 'Ghana - GHA',
        value: 'GHA'
    },
    {
        text: 'Gibraltar - GIB',
        value: 'GIB'
    },
    {
        text: 'Greece - GRC',
        value: 'GRC'
    },
    {
        text: 'Greenland - GRL',
        value: 'GRL'
    },
    {
        text: 'Grenada - GRD',
        value: 'GRD'
    },
    {
        text: 'Guadeloupe - GLP',
        value: 'GLP'
    },
    {
        text: 'Guam - GUM',
        value: 'GUM'
    },
    {
        text: 'Guatemala - GTM',
        value: 'GTM'
    },
    {
        text: 'Guernsey - GGY',
        value: 'GGY'
    },
    {
        text: 'Guinea - GIN',
        value: 'GIN'
    },
    {
        text: 'Guinea-Bissau - GNB',
        value: 'GNB'
    },
    {
        text: 'Guyana - GUY',
        value: 'GUY'
    },
    {
        text: 'Haiti - HTI',
        value: 'HTI'
    },
    {
        text: 'Heard Island and McDonald Islands - HMD',
        value: 'HMD'
    },
    {
        text: 'Holy See (the) - VAT',
        value: 'VAT'
    },
    {
        text: 'Honduras - HND',
        value: 'HND'
    },
    {
        text: 'Hong Kong - HKG',
        value: 'HKG'
    },
    {
        text: 'Hungary - HUN',
        value: 'HUN'
    },
    {
        text: 'Iceland - ISL',
        value: 'ISL'
    },
    {
        text: 'India - IND',
        value: 'IND'
    },
    {
        text: 'Indonesia - IDN',
        value: 'IDN'
    },
    {
        text: 'Iran (Islamic Republic of) - IRN',
        value: 'IRN'
    },
    {
        text: 'Iraq - IRQ',
        value: 'IRQ'
    },
    {
        text: 'Ireland - IRL',
        value: 'IRL'
    },
    {
        text: 'Isle of Man - IMN',
        value: 'IMN'
    },
    {
        text: 'Israel - ISR',
        value: 'ISR'
    },
    {
        text: 'Italy - ITA',
        value: 'ITA'
    },
    {
        text: 'Jamaica - JAM',
        value: 'JAM'
    },
    {
        text: 'Japan - JPN',
        value: 'JPN'
    },
    {
        text: 'Jersey - JEY',
        value: 'JEY'
    },
    {
        text: 'Jordan - JOR',
        value: 'JOR'
    },
    {
        text: 'Kazakhstan - KAZ',
        value: 'KAZ'
    },
    {
        text: 'Kenya - KEN',
        value: 'KEN'
    },
    {
        text: 'Kiribati - KIR',
        value: 'KIR'
    },
    {
        text: 'Korea (the Democratic People\'s Republic of) - PRK',
        value: 'PRK'
    },
    {
        text: 'Korea (the Republic of) - KOR',
        value: 'KOR'
    },
    {
        text: 'Kuwait - KWT',
        value: 'KWT'
    },
    {
        text: 'Kyrgyzstan - KGZ',
        value: 'KGZ'
    },
    {
        text: 'Lao People\'s Democratic Republic (the) - LAO',
        value: 'LAO'
    },
    {
        text: 'Latvia - LVA',
        value: 'LVA'
    },
    {
        text: 'Lebanon - LBN',
        value: 'LBN'
    },
    {
        text: 'Lesotho - LSO',
        value: 'LSO'
    },
    {
        text: 'Liberia - LBR',
        value: 'LBR'
    },
    {
        text: 'Libya - LBY',
        value: 'LBY'
    },
    {
        text: 'Liechtenstein - LIE',
        value: 'LIE'
    },
    {
        text: 'Lithuania - LTU',
        value: 'LTU'
    },
    {
        text: 'Luxembourg - LUX',
        value: 'LUX'
    },
    {
        text: 'Macao - MAC',
        value: 'MAC'
    },
    {
        text: 'Madagascar - MDG',
        value: 'MDG'
    },
    {
        text: 'Malawi - MWI',
        value: 'MWI'
    },
    {
        text: 'Malaysia - MYS',
        value: 'MYS'
    },
    {
        text: 'Maldives - MDV',
        value: 'MDV'
    },
    {
        text: 'Mali - MLI',
        value: 'MLI'
    },
    {
        text: 'Malta - MLT',
        value: 'MLT'
    },
    {
        text: 'Marshall Islands (the) - MHL',
        value: 'MHL'
    },
    {
        text: 'Martinique - MTQ',
        value: 'MTQ'
    },
    {
        text: 'Mauritania - MRT',
        value: 'MRT'
    },
    {
        text: 'Mauritius - MUS',
        value: 'MUS'
    },
    {
        text: 'Mayotte - MYT',
        value: 'MYT'
    },
    {
        text: 'Mexico - MEX',
        value: 'MEX'
    },
    {
        text: 'Micronesia (Federated States of) - FSM',
        value: 'FSM'
    },
    {
        text: 'Moldova (the Republic of) - MDA',
        value: 'MDA'
    },
    {
        text: 'Monaco - MCO',
        value: 'MCO'
    },
    {
        text: 'Mongolia - MNG',
        value: 'MNG'
    },
    {
        text: 'Montenegro - MNE',
        value: 'MNE'
    },
    {
        text: 'Montserrat - MSR',
        value: 'MSR'
    },
    {
        text: 'Morocco - MAR',
        value: 'MAR'
    },
    {
        text: 'Mozambique - MOZ',
        value: 'MOZ'
    },
    {
        text: 'Myanmar - MMR',
        value: 'MMR'
    },
    {
        text: 'Namibia - NAM',
        value: 'NAM'
    },
    {
        text: 'Nauru - NRU',
        value: 'NRU'
    },
    {
        text: 'Nepal - NPL',
        value: 'NPL'
    },
    {
        text: 'Netherlands (the) - NLD',
        value: 'NLD'
    },
    {
        text: 'New Caledonia - NCL',
        value: 'NCL'
    },
    {
        text: 'New Zealand - NZL',
        value: 'NZL'
    },
    {
        text: 'Nicaragua - NIC',
        value: 'NIC'
    },
    {
        text: 'Niger (the) - NER',
        value: 'NER'
    },
    {
        text: 'Nigeria - NGA',
        value: 'NGA'
    },
    {
        text: 'Niue - NIU',
        value: 'NIU'
    },
    {
        text: 'Norfolk Island - NFK',
        value: 'NFK'
    },
    {
        text: 'Northern Mariana Islands (the) - MNP',
        value: 'MNP'
    },
    {
        text: 'Norway - NOR',
        value: 'NOR'
    },
    {
        text: 'Oman - OMN',
        value: 'OMN'
    },
    {
        text: 'Pakistan - PAK',
        value: 'PAK'
    },
    {
        text: 'Palau - PLW',
        value: 'PLW'
    },
    {
        text: 'Palestine, State of - PSE',
        value: 'PSE'
    },
    {
        text: 'Panama - PAN',
        value: 'PAN'
    },
    {
        text: 'Papua New Guinea - PNG',
        value: 'PNG'
    },
    {
        text: 'Paraguay - PRY',
        value: 'PRY'
    },
    {
        text: 'Peru - PER',
        value: 'PER'
    },
    {
        text: 'Philippines (the) - PHL',
        value: 'PHL'
    },
    {
        text: 'Pitcairn - PCN',
        value: 'PCN'
    },
    {
        text: 'Poland - POL',
        value: 'POL'
    },
    {
        text: 'Portugal - PRT',
        value: 'PRT'
    },
    {
        text: 'Puerto Rico - PRI',
        value: 'PRI'
    },
    {
        text: 'Qatar - QAT',
        value: 'QAT'
    },
    {
        text: 'Republic of North Macedonia - MKD',
        value: 'MKD'
    },
    {
        text: 'Romania - ROU',
        value: 'ROU'
    },
    {
        text: 'Russian Federation (the) - RUS',
        value: 'RUS'
    },
    {
        text: 'Rwanda - RWA',
        value: 'RWA'
    },
    {
        text: 'Réunion - REU',
        value: 'REU'
    },
    {
        text: 'Saint Barthélemy - BLM',
        value: 'BLM'
    },
    {
        text: 'Saint Helena, Ascension and Tristan da Cunha - SHN',
        value: 'SHN'
    },
    {
        text: 'Saint Kitts and Nevis - KNA',
        value: 'KNA'
    },
    {
        text: 'Saint Lucia - LCA',
        value: 'LCA'
    },
    {
        text: 'Saint Martin (French part) - MAF',
        value: 'MAF'
    },
    {
        text: 'Saint Pierre and Miquelon - SPM',
        value: 'SPM'
    },
    {
        text: 'Saint Vincent and the Grenadines - VCT',
        value: 'VCT'
    },
    {
        text: 'Samoa - WSM',
        value: 'WSM'
    },
    {
        text: 'San Marino - SMR',
        value: 'SMR'
    },
    {
        text: 'Sao Tome and Principe - STP',
        value: 'STP'
    },
    {
        text: 'Saudi Arabia - SAU',
        value: 'SAU'
    },
    {
        text: 'Senegal - SEN',
        value: 'SEN'
    },
    {
        text: 'Serbia - SRB',
        value: 'SRB'
    },
    {
        text: 'Seychelles - SYC',
        value: 'SYC'
    },
    {
        text: 'Sierra Leone - SLE',
        value: 'SLE'
    },
    {
        text: 'Singapore - SGP',
        value: 'SGP'
    },
    {
        text: 'Sint Maarten (Dutch part) - SXM',
        value: 'SXM'
    },
    {
        text: 'Slovakia - SVK',
        value: 'SVK'
    },
    {
        text: 'Slovenia - SVN',
        value: 'SVN'
    },
    {
        text: 'Solomon Islands - SLB',
        value: 'SLB'
    },
    {
        text: 'Somalia - SOM',
        value: 'SOM'
    },
    {
        text: 'South Africa - ZAF',
        value: 'ZAF'
    },
    {
        text: 'South Georgia and the South Sandwich Islands - SGS',
        value: 'SGS'
    },
    {
        text: 'South Sudan - SSD',
        value: 'SSD'
    },
    {
        text: 'Spain - ESP',
        value: 'ESP'
    },
    {
        text: 'Sri Lanka - LKA',
        value: 'LKA'
    },
    {
        text: 'Sudan (the) - SDN',
        value: 'SDN'
    },
    {
        text: 'Suriname - SUR',
        value: 'SUR'
    },
    {
        text: 'Svalbard and Jan Mayen - SJM',
        value: 'SJM'
    },
    {
        text: 'Sweden - SWE',
        value: 'SWE'
    },
    {
        text: 'Switzerland - CHE',
        value: 'CHE'
    },
    {
        text: 'Syrian Arab Republic - SYR',
        value: 'SYR'
    },
    {
        text: 'Taiwan (Province of China) - TWN',
        value: 'TWN'
    },
    {
        text: 'Tajikistan - TJK',
        value: 'TJK'
    },
    {
        text: 'Tanzania, United Republic of - TZA',
        value: 'TZA'
    },
    {
        text: 'Thailand - THA',
        value: 'THA'
    },
    {
        text: 'Timor-Leste - TLS',
        value: 'TLS'
    },
    {
        text: 'Togo - TGO',
        value: 'TGO'
    },
    {
        text: 'Tokelau - TKL',
        value: 'TKL'
    },
    {
        text: 'Tonga - TON',
        value: 'TON'
    },
    {
        text: 'Trinidad and Tobago - TTO',
        value: 'TTO'
    },
    {
        text: 'Tunisia - TUN',
        value: 'TUN'
    },
    {
        text: 'Turkey - TUR',
        value: 'TUR'
    },
    {
        text: 'Turkmenistan - TKM',
        value: 'TKM'
    },
    {
        text: 'Turks and Caicos Islands (the) - TCA',
        value: 'TCA'
    },
    {
        text: 'Tuvalu - TUV',
        value: 'TUV'
    },
    {
        text: 'Uganda - UGA',
        value: 'UGA'
    },
    {
        text: 'Ukraine - UKR',
        value: 'UKR'
    },
    {
        text: 'United Arab Emirates (the) - ARE',
        value: 'ARE'
    },
    {
        text: 'United Kingdom of Great Britain and Northern Ireland (the) - GBR',
        value: 'GBR'
    },
    {
        text: 'United States Minor Outlying Islands (the) - UMI',
        value: 'UMI'
    },
    {
        text: 'United States of America (the) - USA',
        value: 'USA'
    },
    {
        text: 'Uruguay - URY',
        value: 'URY'
    },
    {
        text: 'Uzbekistan - UZB',
        value: 'UZB'
    },
    {
        text: 'Vanuatu - VUT',
        value: 'VUT'
    },
    {
        text: 'Venezuela (Bolivarian Republic of) - VEN',
        value: 'VEN'
    },
    {
        text: 'Viet Nam - VNM',
        value: 'VNM'
    },
    {
        text: 'Virgin Islands (British) - VGB',
        value: 'VGB'
    },
    {
        text: 'Virgin Islands (U.S.) - VIR',
        value: 'VIR'
    },
    {
        text: 'Wallis and Futuna - WLF',
        value: 'WLF'
    },
    {
        text: 'Western Sahara - ESH',
        value: 'ESH'
    },
    {
        text: 'Yemen - YEM',
        value: 'YEM'
    },
    {
        text: 'Zambia - ZMB',
        value: 'ZMB'
    },
    {
        text: 'Zimbabwe - ZWE',
        value: 'ZWE'
    },
    {
        text: 'Åland Islands - ALA',
        value: 'ALA'
    }
];