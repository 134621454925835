import React, {useCallback, useRef, useState} from 'react';
import produce from "immer";
import {AXIOS_PUT, isDataExist} from "common/commonFunction";
import NetworkLayout from "common/NetworkLayout";
import {COMMAND} from "common/dataProcessAgent";
import {EXTRA_ACCOUNT_URL} from "constant/ConstantURL";
import useToast from "hooks/useToast";
import TextField from "imtrial/components/TextField";
import {isEmpty} from "codemirror/src/util/misc";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     FindPassword
 *  @constant     {Object} FIND_PASSWORD_DEFINE
 *  @description  field 명 정의
 */
const FIND_PASSWORD_DEFINE = {
    ACCOUNT_ID: 'accountID',
    NAME: 'name'
};

/**
 *  @memberOf     FindPassword
 *  @constant     {Object} VALIDATION_DATA
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION_DATA = {
    accountID: true,
    name: true
};

/**
 *  @memberOf     FindPassword
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, sendObject} = params;
    let response = null;

    switch (command) {
        case COMMAND.LOGIN_INFO:
            response = await AXIOS_PUT(requestUrl, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @component    FindPassword
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  패스워드 찾기 화면 컴포넌트
 */
const FindPassword = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     FindPassword
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     FindPassword
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     FindPassword
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     FindPassword
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState({});

    /**
     *  @memberOf     FindPassword
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_DATA);

    /**
     *  @memberOf     FindPassword
     *  @var          {Boolean} isSuccess
     *  @description  패스워드 찾기 성공 여부(true - 성공, false - 실패)
     */
    const [isSuccess, setSuccess] = useState(false);

    /**
     *  @memberOf     FindPassword
     *  @var          {String} email
     *  @description  이메일 값 저장 해놓는 값
     */
    const [email, setEmail] = useState('');

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     FindPassword
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  input 필드 변경시 호출되는 함수
     */
    const handleChange = useCallback((name, value) => {
        setDataInfo(produce(dataInfo, draft => {
            draft[name] = value
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     FindPassword
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     FindPassword
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        let key;
        for (key of validateKeys) {
            validateData[key] = isDataExist(dataInfo[key]);
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, validation]);

    /**
     *  @memberOf     FindPassword
     *  @function     goToLogin
     *  @description  로그인 화면으로 이동하는 함수(로그아웃)
     */
    const goToLogin = useCallback(() => {
        history.push("/account/login");
    }, [history]);

    /**
     *  @memberOf     FindPassword
     *  @function     handleFind
     *  @description  Send 버튼 클릭시 호출 되는 함수. Password 리셋 Api 호출
     */
    const handleFind = useCallback(() => {
        const valid = validateData();
        if (valid === false) {
            return;
        }

        const command = COMMAND.LOGIN_INFO;
        // 검색에 필요한 parameter 셋팅
        const params = {
            requestUrl: `${EXTRA_ACCOUNT_URL}?type=reset`,
            sendObject: JSON.stringify(dataInfo)
        };

        // back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, [dataInfo, validateData]);

    /**
     *  @memberOf     FindPassword
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.LOGIN_INFO:
                    if (!isEmpty(data.data)) {
                        setSuccess(true);
                        setEmail(dataInfo[FIND_PASSWORD_DEFINE.ACCOUNT_ID]);
                        showToast();
                    }
                    break;

                //no default
            }
        }
    }, [dataInfo, showToast]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div
                style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                className="row justify-content-center align-items-center py-4 w-100">
                <div className="col-md-3">
                    <h3 className="mb-4">Find Password</h3>
                    <div className="row pl-2 justify-content-end">
                        <div className="col-sm-3 py-2">
                            <span>Email</span>
                            <span className="ml-3 text-danger">*</span>
                        </div>

                        <div className="col-sm-9 py-2">
                            <TextField
                                className='w-100'
                                currentValue={dataInfo[FIND_PASSWORD_DEFINE.ACCOUNT_ID]}
                                name={FIND_PASSWORD_DEFINE.ACCOUNT_ID}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                validation={validation[FIND_PASSWORD_DEFINE.ACCOUNT_ID]}/>
                        </div>

                        <div className="col-sm-3 py-2">
                            <span>User</span>
                            <span className="ml-3 text-danger">*</span>
                        </div>

                        <div className="col-sm-9 py-2">
                            <TextField
                                className='w-100'
                                currentValue={dataInfo[FIND_PASSWORD_DEFINE.NAME]}
                                name={FIND_PASSWORD_DEFINE.NAME}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                validation={validation[FIND_PASSWORD_DEFINE.NAME]}/>
                        </div>

                        {isSuccess && (
                            <div className="col-sm-12">
                                <div className="py-5 text-center" style={{fontSize: "1rem"}}>
                                    A temporary password has been sent to your email address {email}
                                </div>
                            </div>
                        )}

                        <div className="col-sm-12 py-2">
                            <div className='w-100'>
                                {!isSuccess ? (
                                    <div className="d-flex justify-content-end py-4">
                                        <button
                                            className="btn btn-new btn-inner-shadow px-3 px-md-5"
                                            onClick={goToLogin}>
                                            Return Login
                                        </button>

                                        <button
                                            className="btn btn-orange btn-inner-shadow px-3 px-md-5 mx-1"
                                            onClick={handleFind}>
                                            Send
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-end py-4">
                                        <button
                                            className="btn btn-new btn-inner-shadow px-3 px-md-5"
                                            onClick={goToLogin}>
                                            Return Login
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(FindPassword);
