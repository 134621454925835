import React, {useCallback, useEffect, useRef, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, copyObject, getSessionState} from "../../../../../common/commonFunction";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import NetworkLayout from "../../../../../common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import useToast from "../../../../../hooks/useToast";
import AUIGrid from "../../../../components/AUIGrid";
import MethodsModal from "./MethodsModal";
import cn from "classnames";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/**
 *  @memberOf     Methods
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    Methods
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Methods Tab 컴포넌트
 */
const Methods = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     Methods
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     Methods
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     Methods
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     Methods
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     Methods
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     Methods
     *  @var          {Boolean} isEditModal
     *  @description  Methods 탭의 Add,Edit Modal 의 Open/Close 상태
     */
    const [isEditModal, setIsEditModal] = useState(false);

    /**
     *  @memberOf     Methods
     *  @var          {Object} rowEvent
     *  @description  선택 된 row의 정보
     */
    const [rowEvent, setRowEvent] = useState();

    /**
     *  @memberOf     Methods
     *  @var          {String} mode
     *  @description  Modal창에 NEW인지 EDIT인지 넘겨주는 상태 값
     */
    const [mode, setMode] = useState();

    /**
     *  @memberOf     Methods
     *  @var          {String} availableMethodCount
     *  @description  available Method 카운트
     */
    const [availableMethodCount, setAvailableMethodCount] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     Methods
     *  @function     getDataList
     *  @param        {Object} params - API 통신에 필요한 parameter 값들
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/functionList`,
            ID: ID
        }

        netWorkAgent.current.request(command, params);
    }, [ID]);

    const handleAdd = useCallback(() => {
        let _defaultObj = {
            mappingID: ID,
            OID: "",
            name: "",
            type: "Computation",
            description: "",
            expressionContext: "",
            expressionCode: "",
            document: "",
            pages: "",
            used: 1
        };

        setRowEvent(_defaultObj);
        setIsEditModal(true);
        setMode("NEW");
    }, [ID]);

    /**
     *  @memberOf     Methods
     *  @function     handleRowClick
     *  @param        {String} rowID - 리스트 ID
     *  @description  리스트 클릭 시 실행되는 함수. session 에 정보 저장 후 리스트 상세 정보로 넘어가기 기능.
     */
    const handleRowClick = useCallback((rowID) => {
        const findObj = dataList.find(data => data.ID === rowID); //List에서 선택 된 data 정보 찾기

        //data를 찾았을때만
        if (findObj !== undefined) {
            setRowEvent(findObj); //찾은 data를 rowEvent state에 저장
            setIsEditModal(true);
            setMode("EDIT");
        }
    }, [dataList]);

    /**
     *  @memberOf     Methods
     *  @function     handleClose
     *  @description  Modal 창 닫기
     */
    const handleClose = useCallback(() => {
        setIsEditModal(false);
    }, []);

    /**
     *  @memberOf     Methods
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     Methods
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        showToast(getDataList()); //toast 창 띄우고 데이터 리스트 다시 요청

        setIsEditModal(false); //모달창 close
    }, [getDataList, showToast]);

    /**
     *  @memberOf     Methods
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     Methods
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let _dataList = copyObject(data.data);
                    _dataList.sort((a, b) => {
                        return b.used.toString().localeCompare(a.used.toString());
                    })

                    _dataList.sort((a, b) => {
                        if (a.used === b.used) {
                            return a.name.localeCompare(b.name);
                        }
                    })
                    setDataList(_dataList);
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     Methods
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "used",
            headerText: "",
            width: "2.5%",
            editable: false,
            renderer: {
                type: "TemplateRenderer"
            },
            labelFunction(rowIndex, columnIndex, value) {
                // 코드리스트 탭 아이콘과 동일하게 사용
                return value === 1 ? `<i class="fas fa-genderless" />` : `<i class="fas fa-times" style="color:red"></i>`;
            }
        },
        {
            dataField: "OID",
            headerText: "ID",
            editable: false,
            style: "cell-left-align",
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "name",
            headerText: "Name",
            editable: false,
            width: "7%",
            style: "cell-left-align",
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "type",
            headerText: "Type",
            editable: false,
            width: "7%"
        },
        {
            dataField: "description",
            headerText: "Description",
            editable: false,
            width: "35%",
            style: "cell-left-align",
            renderer: {
                type: "TemplateRenderer"  // HTML 템플릿 렌더러 사용
            },
            labelFunction(rowIndex, columnIndex, value) { // HTML 템플릿 작성
                if (!value) {
                    return "";
                }

                // return `<div><pre style='font-size: 12px; margin: 0; white-space: pre-wrap'>${value}</pre></div>`;
                return `<div style='font-size: 12px; margin: 0; white-space: pre-wrap'>${value}</div>`;
            }
        },
        {
            dataField: "expressionContext",
            headerText: "Expression<br/>Context",
            editable: false
        },
        {
            dataField: "expressionCode",
            headerText: "Expression<br/>Code",
            editable: false
        },
        {
            dataField: "document",
            headerText: "Document",
            editable: false
        },
        {
            dataField: "pages",
            headerText: "Pages",
            editable: false,
            width: "5%"
        }
    ];

    // usedMethod, availableMethod 카운트 하는 곳
    useEffect(() => {
        const _availableMethod = [];
        dataList.forEach((e) => {
            if (e.used === 1) {
                _availableMethod.push(e.OID);
            }
        })
        setAvailableMethodCount(_availableMethod.length)
    }, [dataList])

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="btnContent" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar className="height-full p-20" options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-end m-b-10">
                                            <h5>
                                                Available Methods : {availableMethodCount}
                                            </h5>
                                            <div className="flex-1"></div>
                                            <button
                                                className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                onClick={handleAdd}>
                                                Add
                                            </button>
                                        </div>

                                        <AUIGrid
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onClick={handleRowClick}
                                            isWordWrap={true}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}
                                            enableFilter={true}/>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between border-top bg-light">
                            <div className='p-10'>
                                <button
                                    className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                    onClick={goToList}>
                                    List
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isEditModal && (
                <MethodsModal
                    onClose={handleClose}
                    onSave={handleSave}
                    dataList={dataList}
                    data={rowEvent}
                    mode={mode}/>
            )}
        </>
    );
};

export default React.memo(Methods);