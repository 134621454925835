import React, {useCallback, useEffect, useState} from 'react';
import ACRFCover from "../../sdtm/Mapping/ACRF/ACRFCover";
import {copyObject} from "../../../../common/commonFunction";

/**
 *  @author       김성연
 *  @version      1.0
 *  @component    CompareCover
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  amendment의 cover 화면
 */
const CompareCover = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     CompareCover
     *  @type         {Object} props
     *  @property     {Object} leftData - 왼쪽에 표시할 데이터
     *  @property     {Object} rightData - 오른쪽에 표시할 데이터
     *  @property     {Array} reasonForChangeList - reasonForChange 리스트
     *  @property     {function} setReasonForChangeList - reasonForChangeList state 업데이트 해주는 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {leftData, rightData, reasonForChangeList, setReasonForChangeList, versionList} = props;

    /**
     *  @memberOf     CompareCover
     *  @var          {Object} leftCompareData
     *  @description  compare쪽으로 넘어갈 left data
     */
    const [leftCompareData, setLeftCompareData] = useState();

    /**
     *  @memberOf     CompareCover
     *  @var          {Object} leftCompareData
     *  @description  compare쪽으로 넘어갈 right data
     */
    const [rightCompareData, setRightCompareData] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CompareCover
     *  @function     isObjectEmpty
     *  @param        {Object} obj
     *  @description  object가 비어있는지 판단하는 함수
     */
    const isObjectEmpty = (obj) => {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    /**
     *  @memberOf     CompareCover
     *  @function     onChange
     *  @param        {Object} event - event 오브젝트
     *  @description  textarea 변경될 때 실행되는 함수
     */
    const onChange = (event) => {
        // state에서 해당 path값 찾아서 있으면 데이터를 받아와서 그 인덱스를 갱신, ID값과 같이 넣기
        // path값 없다면 새로 데이터 생성
        let selectedIndex = reasonForChangeList.findIndex(o => o.type === "Cover")
        if (selectedIndex === -1) {
            // 새로 put 하는 경우
            setReasonForChangeList([...reasonForChangeList, {
                "ID": "",
                "firstID": versionList[0].ID,
                "secondID": versionList[1].ID,
                "path": "",
                "type": "Cover",
                "value": event.target.value
            }])
        } else {
            // 기존 배열에 존재하는 경우
            const _reasonForChangeList = copyObject(reasonForChangeList);
            _reasonForChangeList[selectedIndex] = {
                ..._reasonForChangeList[selectedIndex],
                "firstID": versionList[0].ID,
                "secondID": versionList[1].ID,
                "path": "",
                "type": "Cover",
                "value": event.target.value
            }
            setReasonForChangeList(_reasonForChangeList);
        }
    }

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    useEffect(() => {
        if (leftData && rightData) {
            const _leftData = copyObject(leftData);
            const _rightData = copyObject(rightData);

            _leftData.diffObj = {
                compareColor: 'rgba(49, 247, 49, 0.5)'
            }
            _rightData.diffObj = {
                compareColor: 'rgba(255, 255, 0, 0.5)'
            }

            if (_leftData.protocolNo !== _rightData.protocolNo) {
                _leftData.diffObj.protocolNo = true;
                _rightData.diffObj.protocolNo = true;
            }

            const leftTitle = _leftData.language === "en" ? _leftData.studyTitleEN : _leftData.studyTitleKR;
            const rightTitle = _rightData.language === "en" ? _rightData.studyTitleEN : _rightData.studyTitleKR;
            if (leftTitle !== rightTitle) {
                _leftData.diffObj.studyTitle = true;
                _rightData.diffObj.studyTitle = true;
            }

            if (_leftData.protocolVersion !== _rightData.protocolVersion) {
                _leftData.diffObj.protocolVersion = true;
                _rightData.diffObj.protocolVersion = true;
            }

            if (_leftData.version !== _rightData.version) {
                _leftData.diffObj.version = true;
                _rightData.diffObj.version = true;
            }

            if (_leftData.effectiveDate !== _rightData.effectiveDate) {
                _leftData.diffObj.effectiveDate = true;
                _rightData.diffObj.effectiveDate = true;
            }

            if (_leftData.sponsor !== _rightData.sponsor) {
                _leftData.diffObj.sponsor = true;
                _rightData.diffObj.sponsor = true;
            }

            setLeftCompareData(_leftData)
            setRightCompareData(_rightData)
        }
    }, [leftData, rightData])

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            id="soa-container"
            style={(isObjectEmpty(leftCompareData?.diffObj) && isObjectEmpty(rightCompareData?.diffObj)) ? {display: "none"} : {display: "block"}}>
            <div
                style={{
                    display: "flex",
                    border: "1px solid #d5dbe0",
                    borderTop: "0",
                    breakBefore: "always"
                }}>
                <div style={{width: "10%"}}>
                    <div style={{padding: "10px", fontSize: "15px"}}>
                        Cover Page
                    </div>
                </div>

                {!(isObjectEmpty(leftCompareData?.diffObj) && isObjectEmpty(rightCompareData?.diffObj)) &&
                    <div
                        style={{
                            display: "flex",
                            flex: "9"
                        }}>
                        <div id="cover" style={{flex: "1", borderLeft: "1px solid #d5dbe0"}}>
                            {
                                leftData && (
                                    <div className="p-5">
                                        <ACRFCover
                                            display="block"
                                            coverInfo={leftCompareData}
                                            isCompareCover={true}
                                        />
                                    </div>
                                )
                            }
                        </div>

                        <div id="cover" style={{flex: "1", borderLeft: "1px solid #d5dbe0"}}>
                            {
                                rightData && (
                                    <div className="p-5">
                                        <ACRFCover
                                            display="block"
                                            coverInfo={rightCompareData}
                                            isCompareCover={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                }

                <div style={{
                    flex: "2",
                    display: "flex"
                }}>
                    <div
                        style={{
                            padding: "10px",
                            borderLeft: "1px solid #d5dbe0",
                            flex: "1",
                            display: "flex"
                        }}>
                        <textarea
                            style={{
                                border: "none",
                                resize: "none",
                                flex: "1",
                                fontSize: "15px"
                            }}
                            onChange={(event) => onChange(event)}
                            value={reasonForChangeList[reasonForChangeList?.findIndex(o => o.type === "Cover")]?.value}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompareCover;