import axios from "axios";
import {SESSION_INFO} from "constant/CommonConstant";
import moment from "moment-timezone";
import * as crypto from "crypto";
import CryptoJS from 'crypto-js';

/*################################################################################*/
//## costant 관련
/*################################################################################*/
/**
 *  @constant
 *  @type {Object}
 *  @description  http method type
 */
const METHOD_TYPE = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    FORM: 'FORM',
};

/**
 *  @constant
 *  @type {number}
 *  @description  데이터 요청 타임아웃 시각
 */
const TIME_OUT = 1800000;

/**
 *  @type      {function(*): *}
 *  @function  isDataExist - 데이터 존재 여뷰 체크
 *  @param     {Object} inData - 데이터 존재 체크 입력 변수
 *  @return     true  - 데이터 존재
 *              false - 데이터 null, undefined, space
 */
export const isDataExist = (inData) => {
    const blank_pattern = /[\s]/g; // 공백 체크

    if (inData === null) return false;
    if (inData === undefined) return false;
    if (inData.length === 0) return false;
    if (typeof inData === 'string') {
        return inData.replace(blank_pattern, '') !== '';
    }

    return true;
};


/**
 *  @type      {function(*): *}
 *  @function  isFieldDataExist - 데이터 존재 여뷰 체크
 *  @param     {Object} obj     - 데이터
 *  @param     {String} key     - 필드 키
 *  @return     true  - 데이터 존재
 *              false - 데이터 null, undefined, space
 */
export const isFieldDataExist = (obj, key) => {
    if (!obj.hasOwnProperty(key)) return false;
    return isDataExist(obj[key]);
};

/**
 *  @type      {function(*): *}
 *  @function  getAuthHeader - 인증 토큰을  검색해서  header 셋팅
 *  @param     {string} method - 데이터 존재 체크 입력 변수
 *  @return    {string} - axios 요청에 포함될 헤더 값 셋팅
 */
const getAuthHeader = (method) => {
    const sessionUserData = getSessionUserData();

    let token = '';

    if (isDataExist(sessionUserData) && isDataExist(sessionUserData[SESSION_INFO.ACCESS_TOKEN])) {
        token = sessionUserData[SESSION_INFO.ACCESS_TOKEN];
    }

    let header = {};

    switch (method) {
        // get, delete type 일 경우
        case METHOD_TYPE.GET:
        case METHOD_TYPE.DELETE:
            header['Authorization'] = token;
            break;

        // post, put 타입일 경우
        case METHOD_TYPE.POST:
        case METHOD_TYPE.PUT:
            header['Authorization'] = token;
            header['Content-Type'] = 'application/json';
            break;

        // form type 일 경우
        case METHOD_TYPE.FORM:
            header['Authorization'] = token;
            header['Content-Type'] = 'multipart/form-data';
            break;

        default:
            break;
    }

    return header;
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_GET - header 가 셋팅 된 axios get 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_GET = (url, timeOut = TIME_OUT) => {
    console.log(`=======AXIOS_GET========`);
    console.log(url);

    const header = getAuthHeader(METHOD_TYPE.GET);

    return axios.get(url, {
        headers: header,
        timeout: timeOut
    });
};

// /**
//  *  @type      {function(*): *}
//  *  @function  AXIOS_GET - header 가 셋팅 된 axios get 요청
//  *  @param     {string} url - 요청 URL
//  */
export const AXIOS_GET_BLOB = (url, timeOut = TIME_OUT) => {
    console.log(`=======AXIOS_GET========`);
    console.log(url);

    const header = getAuthHeader(METHOD_TYPE.GET);

    return axios.get(url, {
        headers: header,
        timeout: timeOut,
        // responseType: responseType
    });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_DELETE - header 가 셋팅 된 axios delete 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_DELETE = (url) => {

    const header = getAuthHeader(METHOD_TYPE.DELETE);

    return axios.delete(url, {
        headers: header,
        timeout: TIME_OUT
    });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_DELETE_FORM - header, form data 가 셋팅 된 axios delete
 *  @param     {string} url - 요청 URL
 *  @param     {Object} sendObject - 요청 sendObject
 */
export const AXIOS_DELETE_FORM = (url, sendObject) => {
    const header = getAuthHeader(METHOD_TYPE.DELETE);
    return axios.delete(url, {
        data: sendObject,
        headers: header,
        timeout: TIME_OUT
    });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_POST - header, send data 가 셋팅 된 axios post 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_POST = (url, sendString = '', timeOut = TIME_OUT) => {
    const header = getAuthHeader(METHOD_TYPE.POST);

    let encryptSendData;
    if (isDataExist(sendString)) {
        encryptSendData = _encrypt(sendString);
    }

    return axios.post(url, encryptSendData, {
        headers: header,
        timeout: TIME_OUT
    });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_POST_FORM - header, send data 가 셋팅 된 axios post를 통해  form 데이터 전송
 *  @param     {string} url - 요청 URL
 *  @param     {Object} sendObject - 전송 form data
 */
export const AXIOS_POST_FORM = (url, sendObject, timeOut = TIME_OUT) => {

    const header = getAuthHeader(METHOD_TYPE.FORM);

    return axios.post(url, sendObject, {
        headers: header,
        timeout: timeOut
    });
};


/**
 *  @type      {function(*): *}
 *  @function  AXIOS_PUT_FORM - header, send data 가 셋팅 된 axios post를 통해  form 데이터 전송
 *  @param     {string} url - 요청 URL
 *  @param     {Object} sendObject - 전송 form data
 */
export const AXIOS_PUT_FORM = (url, sendObject) => {

    const header = getAuthHeader(METHOD_TYPE.FORM);

    return axios.put(url, sendObject, {
        headers: header,
        timeout: TIME_OUT
    });
};

/**
 *  @type      {function(string, string): Promise<AxiosResponse<T>>}
 *  @function  AXIOS_PUT - header, send data 가 셋팅 된 axios put 요청
 *  @param     {string} url - 요청 URL
 *  @param     {string} sendString - 업데이터 정보
 */
export const AXIOS_PUT = (url, sendString = '') => {
    const header = getAuthHeader(METHOD_TYPE.PUT);

    console.log(`=======AXIOS_PUT========`);
    console.log(url);

    let encryptSendData;
    if (isDataExist(sendString)) {
        encryptSendData = _encrypt(sendString);
    }

    return axios.put(url, encryptSendData, {
        headers: header,
        timeout: TIME_OUT
    });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_DOWNLOAD - header 가 셋팅 된 axios get 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_DOWNLOAD = (url) => {
    console.log(`=======AXIOS_GET========`);
    console.log(url);

    const header = getAuthHeader(METHOD_TYPE.GET);

    return axios.get(url, {
        headers: header,
        timeout: TIME_OUT,
        // responseType: 'blob', // important!!!!!!/
    });
};

/**
 *  @type      {function(*): *}
 *  @function  getOptionText - option(value, text) 에서 해당 value 에 맞는 text 추출
 *  @param     {object} options - (value, text)로 구성된 object
 *  @param     {string} value   - options 에서 찾으려는 value 값
 */
export const getOptionText = (options, value) => {

    const findText = options.find(data => data.value === value);

    if (findText === undefined) {
        return 'no data';
    }

    return findText.text;
};


/**
 *  @type      {function(*): *}
 *  @function  parseDateTime - UTC 서버시간은 타임존에 맞게 format 하여 시각 응답
 *  @param     {datetime} dateValue   - utc-0 시각
 *  @param     {string} dataFormat    - 표시를 위한 format
 */
export const parseDateTime = (dateValue, dataFormat = 'YYYY-MM-DD HH:mm:ss') => {
    const sessionUserData = getSessionUserData();

    // time zone 데이터가 있는 경우
    if (sessionUserData[SESSION_INFO.TIME_ZONE] !== null) {

        if (dateValue) {
            const timeZone = sessionUserData[SESSION_INFO.TIME_ZONE];
            return moment(dateValue).tz(timeZone).format(dataFormat);
        }
        return '';
    }
    // 타임 존이 없는 경우(실행되는 browser 기준으로 시각 생성
    else {
        if (dateValue) {
            return moment(dateValue).format(dataFormat);
        }
        return '';
    }
};
//
// /**
//  *  @type      {function(*): *}
//  *  @function  getFlagList - 국기 view 응답
//  *  @param     {array} flagList  - 나라 정보 배열
//  */
// export const getFlagList = (flagList) => {
//
//   if (flagList === null || flagList === undefined || flagList.length === 0) return null;
//
//   return flagList.map((data, idx) => {
//       const flagItem = FLAG_LIST.find((flagItem) => {
//         return flagItem.value === data
//       });
//
//       return (
//         <Col sm="12" key={idx} className="py-1 px-1">
//           <div
//             className={classNames({"d-flex justify-content-between form-control text-break": true, "bg-gray-200": true})}>
//             <span>{flagItem.text}</span>
//           </div>
//         </Col>
//       );
//     }
//   );
// };


/**
 *  @type      {function(*): *}
 *  @function  getExtension - 파일명에서 확장자명 추출
 *  @param     {string} filename  - 파일 이름
 */
export const getExtension = (filename) => {

    const _fileLen = filename.length;

    /**
     * lastIndexOf('.')
     * 뒤에서부터 '.'의 위치를 찾기위한 함수
     * 검색 문자의 위치를 반환한다.
     * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
     */
    const _lastDot = filename.lastIndexOf('.');

    // 확장자 명만 추출한 후 소문자로 변경
    return filename.substring(_lastDot + 1, _fileLen).toLowerCase();

};


/**
 *  @type      {function(*): *}
 *  @function  removeSessionData - session data 삭제
 */
export const removeSessionData = () => {

    // for(const dataKey in SESSION_INFO) {
    //   sessionStorage.removeItem(SESSION_INFO[dataKey]);
    // }
};

//Todo 기존 2020년 getNameByLanguage
// /**
//  *  @type      {function(*): *}
//  *  @function  getNameByLanguage    - 사용자 언어 설정에
//  *  @param     {object} dataObject  - Name 정보를 가지고 있는 object
//  *  @param     {string} dataObject  - Name 정보를 가지고 있는 object
//  */
// export const getNameByLanguage = (dataObject, NAME_FILED = 'Name') => {
//
//   const sessionUserData = getSessionUserData();
//   // session 정보에서 language 데이터 get
//   let language = sessionUserData[SESSION_INFO.LANGUAGE];
//
//   // session 정보에 언어 정보가 있을 경우
//   if (isDataExist(language) === true) {
//
//     // object 에 언어 코드 필드가 있는 경우
//     if (dataObject.hasOwnProperty(language)) {
//
//       // 언어 코드 하위에 Name 정보가 있는 경우
//       if (dataObject[language].hasOwnProperty(NAME_FILED)) {
//         return dataObject[language][NAME_FILED];
//       }
//     }
//   }
//
//   // 언어 코드에 따른 name 처리가 안 된 경우에 name 정보가 있을 경우 name 정보를 응답
//   if (dataObject.hasOwnProperty(NAME_FILED)) {
//     return dataObject[NAME_FILED];
//   }
//
//   // name 정보가 없는 경우
//   return 'UNDEFINED';
// };

/**
 *  @type      {function(*): *}
 *  @function  getNameByLanguage    - 사용자 언어 설정에
 *  @param     {object} dataObject  - Name 정보를 가지고 있는 object
 *  @param     {string} dataObject  - Name 정보를 가지고 있는 object
 */
export const getNameByLanguage = (dataObject, NAME_FILED = 'name') => {

    const sessionUserData = getSessionUserData();
    // session 정보에서 language 데이터 get
    let language = sessionUserData[SESSION_INFO.LANGUAGE].toLowerCase();

    // session 정보에 언어 정보가 있을 경우
    if (isDataExist(language) === true) {

        // object 에 언어 코드 필드가 있는 경우
        if (dataObject.hasOwnProperty(language)) {

            // 언어 코드 하위에 Name 정보가 있는 경우
            if (dataObject[language].hasOwnProperty(NAME_FILED)) {
                return dataObject[language][NAME_FILED];
            }
        }
    }

    // 언어 코드에 따른 name 처리가 안 된 경우에 name 정보가 있을 경우 name 정보를 응답
    if (dataObject.hasOwnProperty(NAME_FILED)) {
        return dataObject[NAME_FILED];
    }

    // name 정보가 없는 경우
    return '';
};

/**
 *  @type      {function(*): *}
 *  @function  _encrypt
 *  @param     {*} json  - 암호화 처리할 json string
 *  @param     {*} PUBKEY  - 암호화 키
 *  @return    {String} 암호화 처리 된 content
 */
// 대칭키 생성 후 암호화 처리
export const _encrypt = (json) => {
    /*
    * 암호화 로직
    * 1. 대칭키 생성 - AES256 방식
    * 2. 이 키로 메세지 암호화 - RSA 방식
    * 3. 1번 키를 비대칭 알고리즘으로 암호화
    * 4. 2번과 3번을 함께 전송
    * */
    const algorithm = 'aes-256-cbc';
    const KEY = crypto.randomBytes(32);
    const IV = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv(algorithm, KEY, IV);

    // stringify 되지 않았을 경우 string 으로 변환
    if (typeof json === 'object') {
        json = JSON.stringify(json);
    }

    const ciphertext = cipher.update(json, 'utf-8', 'base64') + cipher.final('base64');

    const _encryptRSA = (plainText, PUBKEY) => {
        if (!PUBKEY) {
            PUBKEY = '-----BEGIN PUBLIC KEY-----\n' +
                'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsay3v6w8xd7FCLuSSY0Q\n' +
                '5nzpVQKbZbg5FNTlvmJzdZppx5VqLfnRKiOr8eMu/iErlHf/lntctVBJgpuAZNNE\n' +
                'vKWuLuzk074nei5OSou9SuM9WtOcpVjqfvCdSBUL02XSYHIUosY+R3uLveQ1/9MD\n' +
                'TuKg1GRk0je3fmF3YHT1ksGC8gwNUd9bmdhvlcBGSgBccWcxrOAi/dNu7cD4OKK0\n' +
                'l53zU268oYwQeYbjQzJXG++x81rv3dM7eilq3JRAjaJHk4YsgQuDaaHWZi0ecfJ/\n' +
                'Qm+bEdT6HQ3CczCNyIp+XMgxcs9TMxCAxZKfgMkv5ilVY8btwmUOgU5LCJqXf15V\n' +
                'cQIDAQAB\n' +
                '-----END PUBLIC KEY-----\n';
        }

        return crypto.publicEncrypt(PUBKEY, Buffer.from(plainText)).toString('base64');
    };

    const encryptedData = {};
    encryptedData.key = _encryptRSA(KEY); // 암호화 된 키를 다시 비대칭 키 방식으로 암호화 처리
    encryptedData.iv = _encryptRSA(IV); // 암호화 된 키를 다시 비대칭 키 방식으로 암호화 처리
    encryptedData.text = ciphertext; // key, iv, text를 객체로 묶어 base 문자열로 처리

    return encryptedData;
}

/**
 *  @type      {function(*): *}
 *  @function  getValueByLanguage   - 언어 설정에 따라 필드 값을 응답
 *  @param     {object} dataObject  - 값을 가지고 있는 레코드
 *  @param     {string} language    - 언어 정보
 *  @param     {string} fieldName   - 언어 정보를 가져가기 위한 필드명
 */
export const getValueByLanguage = (dataObject, language, fieldName) => {

    // language 객체가 없는 경우
    if (dataObject.hasOwnProperty(language) === false) {
        return dataObject[fieldName];
    }

    // language 객체의 필드 값이 없는 경우
    if (dataObject[language].hasOwnProperty(fieldName) === false) {
        return dataObject[fieldName];
    }

    console.log(dataObject[language][fieldName]);
    return dataObject[language][fieldName];

};

/**
 *  @function  getStateType
 *  @param  state; -> Lock정보
 *  @description  현재 lock state 정보 얻기
 */
export const getStateLock = (state) => {
    switch (state) {
        case 1 :
            return 'Unlocked';
        case 2 :
            return 'Locked';
        default:
            break;
    }
}


/**
 *  @function   getSponsorName
 *  @param      {object[]} list   - 데이터 배열
 *  @description 배열 데이터 이름 반환
 */
export const getJoinListName = (list, name) => {
    if (Array.isArray(list) && list.length > 0) {
        return list.join(', ');
    } else {
        return '';
    }
};


/**
 *  @type      {function(*): *}
 *  @function  GenerateID    - 신규 아이디 생성
 */
export const GenerateID = () => {
    return Math.random().toString(36).substring(2, 16);
};

/////////////////////////////////////////////////////////////////////////////
//////////////////////////// SESSION ////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

// session 값 세팅
export const setSession = (key, value) => {
    sessionStorage.setItem(key, value);
};

// session에서 값 추출
export const getSession = (key) => {
    return sessionStorage.getItem(key);
};

// session 에서 특정 데이터 삭제
export const removeSession = (key) => {
    sessionStorage.removeItem(key);
};

// session에서 전체 데이터 삭제
export const clearSession = () => {
    sessionStorage.clear();
};

// state 값을 저장 하기 위한 key
export const SESSION_STATE_KEY = 'STATE';

//  state 데이터를 session 에 저장
export const setSessionState = (state) => {
    const jsonString = JSON.stringify(state);

    // # 개발용일 때는 주석
    // TODO : session 정보 암호화 처리 (현재는 주석)
    // const encrypted = aes256.encrypt(SESSION_STATE_KEY, jsonString);
    // setSession(SESSION_STATE_KEY, encrypted);
    // # 개발용일 때는 주석

    // # 서비스 때 삭제
    setSession(SESSION_STATE_KEY, jsonString);
    // # 서비스 때 삭제
};

//  state 데이터를 session 에서 추출
export const getSessionState = () => {
    const sessionState = getSession(SESSION_STATE_KEY);
    if (sessionState) {
        // # 개발용일 때는 주석
        // TODO : session 정보 복호화 처리 (현재는 주석)
        // const decrypted = aes256.decrypt(SESSION_STATE_KEY, sessionState);
        // return JSON.parse(decrypted);
        // # 개발용일 때는 주석

        // # 서비스 때 삭제
        return JSON.parse(sessionState);
        // # 서비스 때 삭제
    }

    return {};
};

const SESSION_USER_KEY = 'USER_DATA';


export const setSessionUserData = (userData) => {

    const jsonString = JSON.stringify(userData);
    // TODO : session user 정보 암호화 처리 (현재는 주석)
    // const encrypted = aes256.encrypt(SESSION_USER_KEY, jsonString);
    //
    // setSession(SESSION_USER_KEY, encrypted);

    setSession(SESSION_USER_KEY, jsonString);

};

export const getSessionUserData = () => {

    const sessionUserData = getSession(SESSION_USER_KEY);

    if (sessionUserData) {
        // TODO : session user 정보 복호화 처리 (현재는 주석)
        // const decrypted = aes256.decrypt(SESSION_USER_KEY, sessionUserData);
        // return JSON.parse(decrypted);

        return JSON.parse(sessionUserData);
    }
    return null;
};

/**
 *  @type      {function(*): *}
 *  @function  copyObject   - 깊은 복사
 *  @param     {object | array} inObject    - object 또는 array
 */
export const copyObject = (inObject) => {

    if (typeof inObject !== "object" || inObject === null) {
        return inObject;
    }

    let outObject = Array.isArray(inObject) ? [] : {}
    for (const key in inObject) {
        const value = inObject[key];
        outObject[key] = (typeof value === "object" && value !== null) ? copyObject(value) : value;
    }

    return outObject;
}


/**
 *  @type      {function(*): *}
 *  @function  generateRandomId   - object 의 랜덤한 24 자리 id 값 생성
 *  @param     none
 */
export const generateRandomId = () => {
    let id = '';

    const charList = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 16; i++) {
        id += charList.charAt(Math.floor(Math.random() * charList.length));
    }

    return id;

}

/**
 *  @function     getListToString
 *  @param        {Array} list - ',' 추가 시키기 위한 리스트
 *  @return       {String} strVal - 리스트에 ',' 가 추가 된 문자열
 *  @description  리스트를 ','으로 구분하여 문자열로 변경 해주는 함수
 */
export const getListToString = (list) => {
    if (isDataExist(list) && list.length !== 0) {
        return list.join(', ');
    }
}

/**
 *  @function     handleExamplePopup
 *  @description  Mapping에서 Trial Design별 Example 팝업 오픈
 */
export const handleExamplePopup = (domain) => {
    // 듀얼 모니터 기준
    let left = (window.screen.availWidth - 800) / 2;
    let top = (window.screen.availHeight - 640) / 2 - 10;

    if (window.screenLeft < 0) {
        left += window.screen.width * -1;
    } else if (window.screenLeft > window.screen.width) {
        left += window.screen.width;
    }

    window.open(`#/mapping/example/${domain}`, `_${domain}_popup`, `width=1000, height=500, left=${left}, top=${top}, menubar=no, status=no, toolbar=no`);
}

//mapping > acrf 탭에서 download 눌렀을때 새창 띄워서 pdf 저장 하도록(임시방편)
export const acrfPopup = (acrfElem) => {
    let outerACRFMain = acrfElem.outerHTML; //acrf element를 string으로 변경
    outerACRFMain = outerACRFMain.replaceAll('display: none', 'display: block'); //string으로 변경된 acrf element에서 'display: none' 속성을 'display: block'로 변경 작업
    let acrfWin = window.open("", "", "fullscreen=1"); //전체 화면으로 빈 popup 창 open
    acrfWin.document.write(`<html lang="en">
                                <head>
                                    <meta charSet="utf-8"/>
                                    <title>acrf</title>
                                </head>
                                <body>${outerACRFMain}</body>
                            </html>`); //popup 창에 표시 될 내용 작성
    acrfWin.print();
}

//rave Info 암호화
export const encrypt = (data, key) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};

//rave Info 복호화
export const decrypt = (text, key) => {
    try {
        const bytes = CryptoJS.AES.decrypt(text, key);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
        console.error(err);
        return;
    }
};