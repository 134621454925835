import React from 'react';
import MasterRoutes from './_master';
import ExtraRoutes from './_extra';
import AdminRoutes from './_admin';

import {Redirect} from "react-router-dom";

import {TAB_OPTION} from "../imtrial/views/sdtm/Mapping/SdtmMappingDetail";

// Study
const Study = React.lazy(() => import("imtrial/views/study/Study"));
const StudyDetail = React.lazy(() => import("imtrial/views/study/StudyDetail"));

// Design
const Design = React.lazy(() => import("imtrial/views/design/Design"));
const DesignNew = React.lazy(() => import("imtrial/views/design/DesignNew"));
const DesignDetail = React.lazy(() => import("imtrial/views/design/DesignDetail"));

const EdcBuild = React.lazy(() => import("imtrial/views/dci/edc/EdcBuild"));
const EdcBuildPreview = React.lazy(() => import("imtrial/views/dci/edc/EdcBuildPreview"));

// SDTM
const SdtmList = React.lazy(() => import("imtrial/views/sdtm/My SDTM/SDTMList/SdtmList"));
const SdtmEdit = React.lazy(() => import("imtrial/views/sdtm/My SDTM/SDTMList/SdtmEdit"));

const SdtmMapping = React.lazy(() => import("imtrial/views/sdtm/Mapping/SdtmMapping"));
const SdtmMappingDetail = React.lazy(() => import("imtrial/views/sdtm/Mapping/SdtmMappingDetail"));

const SdtmMappingDataSets = React.lazy(() => import("imtrial/views/sdtm/Mapping/DataSets/DataSets"));

const SdtmMappingDomain = React.lazy(() => import("imtrial/views/sdtm/Mapping/Variables/Variables"));

const SdtmMappginRelrecDetail = React.lazy(() => import("imtrial/views/sdtm/Mapping/RELREC/RelrecDetail"));

const SdtmTabulation = React.lazy(() => import("imtrial/views/sdtm/tabulation/SdtmTabulation"));

const SdtmTabulationDetail = React.lazy(() => import("imtrial/views/sdtm/tabulation/SdtmTabulationDetail"));

const routes = [
    ...MasterRoutes,
    ...AdminRoutes,
    ...ExtraRoutes,
    {
        path: '/',
        exact: true,
        title: 'Home',
        // component: () => <Redirect to="/study/study"/>,
    },
    {
        path: "/study",
        name: "Study",
        exact: true,
        component: () => <Redirect to="/study/study"/>,
//component: () => {window.location.href = '/'},
    },
    {
        path: "/study/study",
        name: "Study",
        exact: true,
        component: Study,
    },
    {
        path: "/study/study/detail",
        name: "Study Detail",
        component: StudyDetail,
    },
    {
        path: "/study/study/new",
        name: "New Study",
        component: StudyDetail,
    },

    {
        path: "/design",
        name: "Design",
        exact: true,
        component: () => <Redirect to="/design/design"/>,
    },
    {
        path: "/design/design",
        name: "Design",
        exact: true,
        component: Design
        //component: () => {window.location.href = '/'},
    },
    {
        path: "/design/design/detail",
        name: "Design Detail",
        component: DesignDetail,
    },
    {
        path: "/design/design/new",
        name: "New Design",
        component: DesignNew,
    },

    {
        path: "/dci",
        name: "DCI",
        exact: true,
        component: () => <Redirect to="/dci/edc"/>,
    },
    {
        path: "/dci/edc",
        name: "EDC Build",
        exact: true,
        component: EdcBuild
        //component: () => {window.location.href = '/'},
    },
    {
        path: "/dci/edc/preview",
        name: "EDC Build Preview",
        component: EdcBuildPreview,
    },


    // SDTM path 정리
    {
        name: "SDTM",
        path: "/sdtm",
        exact: true,
        component: () => <Redirect to="/sdtm/sdtm-list"/>
    },
    {
        path: "/sdtm/sdtm-list",
        exact: true,
        name: "SDTM Package",
        component: SdtmList
    },
    {path: "/sdtm/sdtm-list/edit", name: "SDTM Package Detail", component: SdtmEdit},


    {
        path: "/sdtm/mapping",
        name: "Mapping List",
        exact: true,
        component: SdtmMapping
    },
    {
        path: "/sdtm/mapping/info",
        name: "Mapping Detail",
        exact: true,
        component: SdtmMappingDetail
    },
    {
        path: "/sdtm/mapping/info/datasets",
        name: "DataSets",
        exact: true,
        component: SdtmMappingDataSets
    },
    {
        path: "/sdtm/mapping/info/domain",
        name: "Domain",
        exact: true,
        component: SdtmMappingDomain
    },
    {
        path: "/sdtm/mapping/info/relrec",
        name: "RELREC",
        exact: true,
        component: () => <Redirect to={{pathname: "/sdtm/mapping/info", state: {type: TAB_OPTION[2].text}}}/>
    },
    {
        path: "/sdtm/mapping/info/relrec/new",
        name: "New RELREC",
        exact: true,
        component: SdtmMappginRelrecDetail
    },
    {
        path: "/sdtm/mapping/info/relrec/detail",
        name: "RELREC Detail",
        exact: true,
        component: SdtmMappginRelrecDetail
    },
    {
        path: "/sdtm/tabulation",
        exact: true,
        name: "Tabulation",
        component: SdtmTabulation
    },
    {
        path: "/sdtm/tabulation/info",
        exact: true,
        name: "Tabulation Detail",
        component: SdtmTabulationDetail
    },
];


export default routes;