import React, {useCallback, useEffect, useState} from "react";
import ACRFItemGroup2 from "./ACRFItemGroup2";
import ACRFItem2 from "./ACRFItem2";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";
import addIcon from "../../../../../assets/icons/add.svg";
import AnnotationComponent from "../../../../components/AnnotationComponent";
import {MAXIMUM_ANNOTATION, SEND_ANNOTATION_INFO} from "./ACRFMain";
import ACRFEditModal from "./ACRFEditModal";
import {copyObject} from "../../../../../common/commonFunction";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFStudyEvent2
 *  @constant     {Object} LANDSCAPE_TABLE_TYPE
 *  @description  HEADER_FIRST_ROW, HEADER_SECOND_ROW, BODY_FIRST_ROW, BODY_SECOND_ROW 정의
 */
const LANDSCAPE_TABLE_TYPE = {
    HEADER_FIRST_ROW: "headerFirstRow",
    HEADER_SECOND_ROW: "headerSecondRow",
    HEADER_THIRD_ROW: "headerThirdRow",
    HEADER_FOURTH_ROW: "headerFourthRow",

    BODY_FIRST_ROW: "bodyFirstRow",
    BODY_SECOND_ROW: "bodySecondRow",
    BODY_THIRD_ROW: "bodyThirdRow",
    BODY_FOURTH_ROW: "bodyFourthRow",
};

/**
 *  @memberOf     ACRFStudyEvent2
 *  @constant     {Object} TYPE_DEFINE
 *  @description  QUESTION, RESULT 정의
 */
const TYPE_DEFINE = {
    QUESTION: "QUESTION",
    RESULT: "RESULT"
};

/**
 /**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFStudyEvent2
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Study Event 컴포넌트
 */
const ACRFStudyEvent2 = (props) => {
    /**
     *  @memberOf     ACRFStudyEvent2
     *  @type         {Object} props
     *  @property     {Object} studyEventData - Study Evnet 정보
     *  @property     {Boolean} isEdit - acrf의 edit 구분 값(false: view, true: edit)
     *  @property     {Array} domainList - Form에 해당하는 Domain 리스트
     *  @property     {Function} onChangeDataList - annotation 추가, 수정, 삭제시 실행될 상위 이벤트 함수
     *  @property     {String} formID - 포함 된 form의 ID
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @property     {Array} itemGroupVisit - itemGroupVisit 정보
     *  @property     {Array} armList - arm 정보
     *  @property     {Array} formVisit - formVisit 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        formData,
        isEdit,
        domainList,
        onChangeDataList,
        formID,
        formMode,
        itemGroupVisit,
        formVisit,
        armList,
        EDCType
    } = props;

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {Boolean} isModal
     *  @description  ACRF edit 팝업 오픈 여부
     */
    const [isModal, setIsModal] = useState(false);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {String} type
     *  @description  Annotation type(QUESTION, RESULT)
     */
    const [type, setType] = useState("");

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {Object} currentAnnotation
     *  @description  선택 된 Annotation 정보
     */
    const [currentAnnotation, setCurrentAnnotation] = useState({});

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {Object} itemData
     *  @description  선택 된 item 정보
     */
    const [itemData, setItemData] = useState({});

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {Object} itemData
     *  @description  선택 된 item 정보
     */
    const [itemGroupID, setItemGroupID] = useState("");

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @var          {String} itemID
     *  @description  선택 된 item ID 정보
     */
    const [itemID, setItemID] = useState("");

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} DDLContentList
     *  @description  form 하단 DDL 주석들을 표시하기 위한 데이터
     */
    const [DDLContentList, setDDLContentList] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} commentList
     *  @description  form 하단 comment를 표시하기 위한 데이터
     */
    const [commentList, setCommentList] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labTableData
     *  @description  labForm 테이블 데이터
     */
    const [labTableData, setLabTableData] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labPortraitData
     *  @description  labform 안에서 portrait로 표시될 데이터
     */
    const [labPortraitData, setLabPortraitData] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labFormDDLComment
     *  @description  labform ddl comment 데이터
     */
    const [labFormDDLComment, setLabFormDDLComment] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    let repeatingYesList = formData && formData.itemGroups.filter(itemGroup => itemGroup.logInfo !== undefined && itemGroup.logInfo.logDirection === "Landscape" && (itemGroup.logInfo.repeating === "Yes" || itemGroup.logInfo.repeating === undefined));

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     handleAdd
     *  @param        {String} type - 추가 할 Annotation의 type(QUESTION, RESULT)
     *  @param        {Object} item - 추가 할 Annotation의 item 정보
     *  @description  Annotation Add 함수
     */
    const handleAdd = useCallback((type, item) => {
        let questionAnnotationLength = item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length; //item의 annotation 리스트에서 type이 QUESTION인 것만 필터하여 리스트 길이 저장
        let resultAnnotationLength = item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length; //item의 annotation 리스트에서 type이 RESULT인 것만 필터하여 리스트 길이 저장

        // type이 QUESTION이고 필터된 리스트의 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.QUESTION && questionAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
            setItemData(item);
            setItemID(item.ID);
            if (item.common === 1) {
                setItemGroupID("COMMON");
            } else {
                setItemGroupID(item.itemGroupID);
            }
        }

        //type이 RESULT이고 필터된 리스트의 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.RESULT && resultAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
            setItemData(item);
            setItemID(item.ID);

            if (item.common === 1) {
                setItemGroupID("COMMON");
            } else {
                setItemGroupID(item.itemGroupID);
            }
        }

        setCurrentAnnotation({}); //add 일때는 선택됐던 annotation 정보 초기화
    }, []);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 상위로 annotation 정보 전달하는 함수
     */
    const changeDataList = useCallback((data) => {
        if (onChangeDataList !== undefined) {
            onChangeDataList(data);
        }
    }, [onChangeDataList]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     handleSave
     *  @param        {Object} data - Annotation 정보
     *  @param        {String} dataType - 추가, 수정, 삭제 구분
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback((data, dataType) => {
        data.path = `${formID}/${itemGroupID}/${itemID}`;
        //포함 된 Form ID, ItemGroup ID, Item ID를 path key에 추가
        changeDataList(data); //상위로 추가, 수정 된 annotation 정보 전달

        SEND_ANNOTATION_INFO[dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가

        setIsModal(false);
        setCurrentAnnotation({}); //선택했던 annotation 정보 초기화

    }, [changeDataList, formID, itemGroupID, itemID]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     handleClick
     *  @param        {Object} annotation - 선택 된 annotation의 정보
     *  @param        {Object} item - 추가 할 Annotation의 item 정보
     *  @description  Annotation 클릭 되면 호출 되는 함수
     */
    const handleClick = useCallback((annotation, itemData) => {
        if (isEdit) {
            setCurrentAnnotation(annotation);
            setIsModal(true);
            setType(annotation.type);
            setItemData(itemData);
            setItemID(itemData.ID);

            if (itemData.common === 1) {
                setItemGroupID("COMMON");
            } else {
                setItemGroupID(itemData.itemGroupID);
            }
        }
    }, [isEdit]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     handleDelete
     *  @param        {Number} idx - 리스트에서 선택 된 index
     *  @param        {String} type - 리스트에서 선택 된 type(QUESTION, RESULT)
     *  @description  Annotation Delete 하는 함수
     */
    const handleDelete = useCallback((idx, type, itemData) => {
        if (itemData?.annotations !== undefined && itemData.annotations.length > 0) {
            const annotationList = itemData.annotations.filter(annotation => annotation.type === type); //type에 따라 annotations를 filter

            const data = annotationList[idx]; //삭제 될 data

            if (itemData.common === 1) {
                data.path = `${formID}/${"COMMON"}/${itemData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID를 path key에 추가
            } else {
                data.path = `${formID}/${itemData.itemGroupID}/${itemData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID를 path key에 추가
            }

            data.dataType = "delete"; //dataType에 delete 추가

            changeDataList(data); //상위로 삭제 된 annotation 정보 전달

            SEND_ANNOTATION_INFO.delete.push(data); //전역 변수로 지정 된 곳 delete에 데이터 추가
        }
    }, [changeDataList, formID]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getFirstRepeatingYesIG
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 에 "firstYes" 라는 key 넣는 함수
     *  @description  해당 firstYes 키는 getLandscapeTable함수에서 landscape table을 한 번만 생성하기 위해 사용
     */
    const getFirstRepeatingYesIG = useCallback(() => {
        let itemGroups = formData.itemGroups;

        if (formData.logDirection === "Landscape") {
            let find = itemGroups.find(x => x.logInfo.repeating === "Yes");
            find.firstYes = true;
        }
    }, [formData.itemGroups, formData.logDirection]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getItemQuestion
     *  @param        {Object} item - item data
     *  @param        {String} type - item의 question의 대체할 type("br", "/")
     *  @return       {Element} - item question element
     *  @description  DDL 여부와 "<br />", "/"의 여부에 따라 해당하는 item question 값 리턴하는 함수
     */
    const getItemQuestion = useCallback((item, type) => {
        if (type === "br") {
            return item.componentType === "DropDownList" ?
                // `${item.question?.replaceAll('<br/>', '\n')} *` : // 추후 되돌릴 예정
                `${item.question?.replaceAll('<br/>', '\n')}` :
                `${item.question?.replaceAll('<br/>', '\n')}`
        } else if (type === "slash") {
            return item.componentType === "DropDownList" ?
                // `${item.question?.replaceAll("/", "/ \n")} *` : // 추후 되돌릴 예정
                `${item.question?.replaceAll("/", "/ \n")}` :
                `${item.question?.replaceAll("/", "/ \n")}`
        }
    }, []);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getLandscapeTableHeaderBody
     *  @param        {String} type - landscape 표의 type(headerFirstRow, headerSecondRow, bodyFirstRow, bodySecondRow)
     *  @param        {Number} columnIdx - item의 componentType 이 "DropDownList"이고, question이 "TimePoint" 인 codelist의 배열의 인덱스
     *  @return       {Element} Landscape 표에 해당하는 header, body 항목
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 의 header, body 데이터 뿌리는 함수
     */
    const getLandscapeTableHeaderBody = useCallback((type, columnIdx) => {
        /* ---------------------------------------------- landscape header ---------------------------------------------- */
        // landscape 표 헤더 첫 번째 row
        if (type === LANDSCAPE_TABLE_TYPE.HEADER_FIRST_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(0, 5);

            return sliced.map((item, idx) => (
                <>
                    <td
                        style={{
                            border: "1px solid #000000",
                            padding: "10px",
                            background: "#EAEAEC",
                            breakInside: "avoid"
                        }}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                fontFamily: "Arial, sans-serif",
                            }}>
                            <span
                                style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "1px solid #ff0000" : ""}}>
                                {item.question.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif"
                                        }}>
                                            {/*landscape 시 visit 정보 추후 추가*/}
                                            {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                            {/*    if (each) {*/}
                                            {/*        return (*/}
                                            {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                            {/*    }*/}
                                            {/*})}*/}
                                            {item.question !== undefined && (
                                                getItemQuestion(item, "br"))}
                                            {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}
                                        {item.question !== undefined && (
                                            getItemQuestion(item, "slash"))}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </>
                                }
                            </span>
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("QUESTION", item)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*question의 annotaion 영역*/}
                                    {item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </td>
                </>
            ));

            // landscape 표 헤더 두 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.HEADER_SECOND_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(5, 10);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td
                        style={{
                            border: "1px solid #000000",
                            padding: "10px",
                            background: "#EAEAEC"
                        }}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            <span
                                style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "1px solid #ff0000" : ""}}>
                                {item?.question?.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif"
                                        }}>
                                            {/*landscape 시 visit 정보 추후 추가*/}
                                            {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                            {/*    if (each) {*/}
                                            {/*        return (*/}
                                            {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                            {/*    }*/}
                                            {/*})}*/}
                                            {item.question !== undefined && (
                                                getItemQuestion(item, "br"))}
                                            {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}
                                        {item.question !== undefined && (
                                            getItemQuestion(item, "slash"))}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </>
                                }
                            </span>
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("QUESTION", item,)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*question의 annotaion 영역*/}
                                    {item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </td>
                ));
            } else {
                return (sliced.map((item, idx) => (
                        <td
                            style={{
                                border: "1px solid #000000",
                                padding: "10px",
                            }}>
                            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    // padding: "10px",
                                    fontFamily: "Arial, sans-serif"
                                }}>
                        <span
                            style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "1px solid #ff0000" : ""}}>
                            {item.question.includes('<br/>') ?
                                <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                    <pre style={{
                                        whiteSpace: "pre-wrap",
                                        fontSize: "9pt",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (*/}
                                        {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}
                                        {item.question !== undefined && (
                                            item.question?.replaceAll('<br/>', '\n'))}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </pre>
                                </div> :
                                <>
                                    {/*landscape 시 visit 정보 추후 추가*/}
                                    {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                    {/*    if (each) {*/}
                                    {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                    {/*    }*/}
                                    {/*})}*/}
                                    {item.question !== undefined && (
                                        item.question?.replaceAll("/", "/ \n"))}
                                    {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                </>
                            }
                        </span>
                                </div>

                                {formMode !== FORM_COMPARE && (
                                    <>
                                        {isEdit && (
                                            <img
                                                src={addIcon}
                                                onClick={() => handleAdd("QUESTION", item)}
                                                style={{
                                                    marginLeft: "5px",
                                                    paddingRight: "15px",
                                                    height: "1rem",
                                                    alignSelf: "center"
                                                }}
                                                alt=""/>
                                        )}

                                        {/*question의 annotaion 영역*/}
                                        {item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                            <div style={{flex: 1, alignSelf: "center"}}>
                                                <AnnotationComponent
                                                    annotationList={item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                    onClick={handleClick}
                                                    onDelete={handleDelete}
                                                    isEdit={isEdit}
                                                    item={item}/>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </td>
                    ))
                )
            }

            // landscape 표 헤더 세 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.HEADER_THIRD_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(10, 15);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td
                        style={{
                            border: "1px solid #000000",
                            padding: "10px",
                            background: "#EAEAEC"
                        }}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            <span
                                style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "2px solid #ff0000" : ""}}>
                                {item?.question?.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif"
                                        }}>
                                            {/*landscape 시 visit 정보 추후 추가*/}
                                            {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                            {/*    if (each) {*/}
                                            {/*        return (*/}
                                            {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                            {/*    }*/}
                                            {/*})}*/}
                                            {item.question !== undefined && (
                                                getItemQuestion(item, "br"))}
                                            {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}
                                        {item.question !== undefined && (
                                            getItemQuestion(item, "slash"))}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </>
                                }
                            </span>
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("QUESTION", item,)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*question의 annotaion 영역*/}
                                    {item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </td>
                ));
            } else {
                return (sliced.map((item, idx) => (
                        <td
                            style={{
                                border: "1px solid #000000",
                                padding: "10px",
                            }}>
                            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    // padding: "10px",
                                    fontFamily: "Arial, sans-serif"
                                }}>
                        <span
                            style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "2px solid #ff0000" : ""}}>
                            {item.question.includes('<br/>') ?
                                <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                    <pre style={{
                                        whiteSpace: "pre-wrap",
                                        fontSize: "9pt",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (*/}
                                        {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}

                                        {item.question?.replaceAll('<br/>', '\n')}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </pre>
                                </div> :
                                <>
                                    {/*landscape 시 visit 정보 추후 추가*/}
                                    {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                    {/*    if (each) {*/}
                                    {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                    {/*    }*/}
                                    {/*})}*/}
                                    {item.question?.replaceAll("/", "/ \n")}
                                    {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                </>
                            }
                        </span>
                                </div>

                                {formMode !== FORM_COMPARE && (
                                    <>
                                        {isEdit && (
                                            <img
                                                src={addIcon}
                                                onClick={() => handleAdd("QUESTION", item)}
                                                style={{
                                                    marginLeft: "5px",
                                                    paddingRight: "15px",
                                                    height: "1rem",
                                                    alignSelf: "center"
                                                }}
                                                alt=""/>
                                        )}

                                        {/*question의 annotaion 영역*/}
                                        {item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                            <div style={{flex: 1, alignSelf: "center"}}>
                                                <AnnotationComponent
                                                    annotationList={item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                    onClick={handleClick}
                                                    onDelete={handleDelete}
                                                    isEdit={isEdit}
                                                    item={item}/>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </td>
                    ))
                )
            }

            // landscape 표 헤더 세 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.HEADER_FOURTH_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(15, 20);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td
                        style={{
                            border: "1px solid #000000",
                            padding: "10px",
                            background: "#EAEAEC"
                        }}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            <span
                                style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "2px solid #ff0000" : ""}}>
                                {item?.question?.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif"
                                        }}>
                                            {/*landscape 시 visit 정보 추후 추가*/}
                                            {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                            {/*    if (each) {*/}
                                            {/*        return (*/}
                                            {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                            {/*    }*/}
                                            {/*})}*/}

                                            {getItemQuestion(item, "br")}
                                            {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}

                                        {getItemQuestion(item, "slash")}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </>
                                }
                            </span>
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("QUESTION", item,)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*question의 annotaion 영역*/}
                                    {item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item?.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </td>
                ));
            } else {
                return (sliced.map((item, idx) => (
                        <td
                            style={{
                                border: "1px solid #000000",
                                padding: "10px",
                            }}>
                            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    // padding: "10px",
                                    fontFamily: "Arial, sans-serif"
                                }}>
                        <span
                            style={{border: item.hasOwnProperty("isQuestionDiff") && item.isQuestionDiff ? "2px solid #ff0000" : ""}}>
                            {item.question.includes('<br/>') ?
                                <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                    <pre style={{
                                        whiteSpace: "pre-wrap",
                                        fontSize: "9pt",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                        {/*landscape 시 visit 정보 추후 추가*/}
                                        {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                        {/*    if (each) {*/}
                                        {/*        return (*/}
                                        {/*            <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                        {/*    }*/}
                                        {/*})}*/}

                                        {item.question?.replaceAll('<br/>', '\n')}
                                        {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                    </pre>
                                </div> :
                                <>
                                    {/*landscape 시 visit 정보 추후 추가*/}
                                    {/*{designVisit[itemGroupID]?.map((each, idx) => {*/}
                                    {/*    if (each) {*/}
                                    {/*        return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);*/}
                                    {/*    }*/}
                                    {/*})}*/}
                                    {item.question?.replaceAll("/", "/ \n")}
                                    {item.hasOwnProperty("measurementUnit") && item.measurementUnit !== "" && ` (${item.measurementUnit})`}
                                </>
                            }
                        </span>
                                </div>

                                {formMode !== FORM_COMPARE && (
                                    <>
                                        {isEdit && (
                                            <img
                                                src={addIcon}
                                                onClick={() => handleAdd("QUESTION", item)}
                                                style={{
                                                    marginLeft: "5px",
                                                    paddingRight: "15px",
                                                    height: "1rem",
                                                    alignSelf: "center"
                                                }}
                                                alt=""/>
                                        )}

                                        {/*question의 annotaion 영역*/}
                                        {item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                            <div style={{flex: 1, alignSelf: "center"}}>
                                                <AnnotationComponent
                                                    annotationList={item.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                    onClick={handleClick}
                                                    onDelete={handleDelete}
                                                    isEdit={isEdit}
                                                    item={item}/>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </td>
                    ))
                )
            }

            /* ---------------------------------------------- landscape body ---------------------------------------------- */
            // landscape 표 바디 첫 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(0, 5);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            return _sliced.map((item, idx) => (
                <td style={{border: "1px solid #000000", padding: "5px", alignSelf: "center"}}>
                    <ACRFItem2 key={idx} itemData={item} isEdit={isEdit} domainList={domainList}
                               onChangeDataList={onChangeDataList} formID={formID}
                               formMode={formMode} itemGroupID={item.itemGroupID} columnIdx={columnIdx}/>
                    {(item?.logInfo?.logDirection === 'Landscape' && (item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined)) &&
                        formMode !== FORM_COMPARE && (
                            <>
                                {isEdit && (
                                    <img
                                        src={addIcon}
                                        onClick={() => handleAdd("RESULT", item)}
                                        style={{
                                            marginLeft: "5px",
                                            paddingRight: "15px",
                                            height: "1rem",
                                            alignSelf: "center"
                                        }}
                                        alt=""/>
                                )}

                                {/*item의 annotation 영역*/}
                                {item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                    <div style={{flex: 1, alignSelf: "center"}}>
                                        <AnnotationComponent
                                            annotationList={item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                            onClick={handleClick}
                                            onDelete={handleDelete}
                                            isEdit={isEdit}
                                            item={item}/>
                                    </div>
                                )}
                            </>
                        )}
                </td>
            ));

            // landscape 표 바디 두 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(5, 10);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px"}}>
                        <ACRFItem2 key={idx} itemData={item} isEdit={isEdit} domainList={domainList}
                                   onChangeDataList={onChangeDataList} formID={formID} /*itemGroupID={itemGroup.ID}*/
                                   formMode={formMode} itemGroupID={itemGroupID} columnIdx={columnIdx}/>
                        {(item?.logInfo?.logDirection === 'Landscape' && (item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined)) &&
                            formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("RESULT", item)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*item의 annotation 영역*/}
                                    {item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우
                    <td style={{}}>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }

            // landscape 표 바디 세 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(10, 15);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px"}}>
                        <ACRFItem2 key={idx} itemData={item} isEdit={isEdit} domainList={domainList}
                                   onChangeDataList={onChangeDataList} formID={formID} /*itemGroupID={itemGroup.ID}*/
                                   formMode={formMode} itemGroupID={itemGroupID} columnIdx={columnIdx}/>
                        {(item?.logInfo?.logDirection === 'Landscape' && (item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined)) &&
                            formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("RESULT", item)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*item의 annotation 영역*/}
                                    {item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우
                    <td style={{}}>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }

            // landscape 표 바디 네 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(15, 20);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px"}}>
                        <ACRFItem2 key={idx} itemData={item} isEdit={isEdit} domainList={domainList}
                                   onChangeDataList={onChangeDataList} formID={formID} /*itemGroupID={itemGroup.ID}*/
                                   formMode={formMode} itemGroupID={itemGroupID} columnIdx={columnIdx}/>
                        {(item?.logInfo?.logDirection === 'Landscape' && (item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined)) &&
                            formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("RESULT", item)}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*item의 annotation 영역*/}
                                    {item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={item.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}
                                                item={item}/>
                                        </div>
                                    )}
                                </>
                            )}
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우
                    <td style={{}}>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }
        }
    }, [domainList, formID, formMode, getItemQuestion, handleAdd, handleClick, handleDelete, isEdit, itemGroupID, onChangeDataList, repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getLandscapeTableBody
     *  @return       {Element} codelist의 갯수만큼 생성된 landscape표의 body의 행
     *  @description  Landscape 표에 해당하는 데이터의 body부분을 codelist의 갯수만큼 행 추가하는 함수
     *  @description  question이 Time Point이고, item의 componentType인 item이 있는 경우, codelist의 갯수만큼 행이 추가됨
     *  @description  TPT itemData 찾기 : codelist의 codeListID 가 TPT/ itemData의 question이 Time Point
     */
    const getLandscapeTableBody = useCallback(() => {
        let TPTCodeListsArr = []; // item의 component type 이 "DropDownList"이고, question이 "TimePoint" 인 codelist의 배열

        repeatingYesList.map((itemGroup) => (
            itemGroup.items.forEach(item => {
                if (item.element.includes("TPT") && item.componentType === "DropDownList") {
                    item.codelists && item.codelists.forEach((codelist) => {
                        TPTCodeListsArr.push(codelist)
                    });
                }
            })
        ));

        const generateRowsInLandscapeTableBody = TPTCodeListsArr.map((tptCodeList, idx) => (
                <>
                    <tr>
                        {repeatingYesList.length > 0 ? (
                            <td rowSpan={4}
                                style={{border: "1px solid #000000", paddingLeft: "5px", background: "#EAEAEC"}}>
                                <div style={{display: "flex", justifyContent: "center"}}>{idx + 1}</div>
                            </td>
                        ) : (
                            <></>
                        )}
                        {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW, idx)}
                    </tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW, idx)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW, idx)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW, idx)}</tr>
                </>
            )
        );

        return generateRowsInLandscapeTableBody;
    }, [getLandscapeTableHeaderBody, repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getLandscapeTable
     *  @param        {Object} data - item group 객체
     *  @return       {Element} landscape 표
     *  @description  landscape 표 생성하는 함수
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 에서만 1회 호출 (landscape 표는 form 당 최대 한 개)
     *  @description  question이 Time Point이고, item의 componentType인 item이 있는 경우, codelist의 갯수만큼 행이 추가됨
     */
    const getLandscapeTable = useCallback((data) => {
        return data.firstYes === true ?
            <>
                {/* item group의 description */}
                {formData?.itemGroupDesNum && formData?.itemGroupDesNum > 0 ?
                    <div>
                        {repeatingYesList.length > 0 &&
                            repeatingYesList?.map(itemGroup =>
                                itemGroup?.description !== null && (<div
                                    style={itemGroup.description ? {
                                        padding: "5px",
                                        border: "1px solid #000000",
                                        background: "#F2F2F2",
                                        marginTop: "-1px",
                                        fontWeight: "bold",
                                        fontSize: "9pt",
                                        fontFamily: "Arial, sans-serif",
                                    } : {
                                        padding: "0px",
                                        border: "0px"
                                    }}>
                                    {itemGroup.description && itemGroup.description?.split('<br/>').map((description) => (
                                        <>
                                            {description}
                                            <br/>
                                        </>
                                    ))}
                                </div>))}
                    </div> : <></>}

                {/* table 영역 */}
                <table
                    style={{
                        tableLayout: "fixed",
                        wordBreak: "keep-all",
                        borderCollapse: "collapse",
                        fontSize: "9pt",
                        width: "100%",
                        border: "1px solid #000000",
                        marginTop: "-1px",
                    }}>

                    {/* item의 question */}
                    <thead style={{padding: "5px", border: "1px solid #000000"}}>
                    <tr>
                        {repeatingYesList.length > 0 ? (
                            <td rowSpan={4} style={{
                                border: "1px solid #000000",
                                width: "7%",
                                background: "#EAEAEC"
                            }}>
                                <div style={{display: "flex", justifyContent: "center"}}>No</div>
                            </td>
                        ) : (
                            <></>
                        )}
                        {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_FIRST_ROW)}
                    </tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_SECOND_ROW)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_THIRD_ROW)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_FOURTH_ROW)}</tr>
                    </thead>

                    {/* item의 value */}
                    <tbody>
                    {data.itemTPDDLNum > 0 ?
                        // item의 question이 "Time Point" 이고 item의 component type이 "DDL"인 item이 있는 경우: landscape 표에 (codelist 갯수만큼)행 추가하는 함수 호출
                        getLandscapeTableBody() :

                        // 없는 경우: 일반 landscape body 형태로 표 생성
                        <>
                            <tr>
                                {repeatingYesList.length > 0 ? (
                                    <td rowSpan={4}
                                        style={{border: "1px solid #000000", background: "#EAEAEC"}}>
                                        <div style={{display: "flex", justifyContent: "center"}}>1</div>
                                        <div
                                            style={{display: "flex", justifyContent: "center", cursor: "pointer"}}>Add
                                        </div>
                                        <div
                                            style={{display: "flex", justifyContent: "center", cursor: "pointer"}}>row
                                        </div>
                                    </td>
                                ) : (
                                    <></>
                                )}
                                {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW)}
                            </tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW)}</tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW)}</tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW)}</tr>
                        </>
                    }
                    </tbody>
                </table>
            </>
            : <></>
    }, [formData.itemGroupDesNum, getLandscapeTableBody, getLandscapeTableHeaderBody, repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent2
     *  @function     getItemGroupComp
     *  @return       {Element} ItemGroup Data render
     *  @description  Item group의 LogDirection, repeating 여부에 따라 Item group 구성 그려주는 함수
     */
    const getItemGroupComp = useCallback(() => {
        /*
        - logDirection 정보는 form에, repeating 정보는 form이나 각 item group에 저장되어 있음
        - Form 의 logDirection의 종류: 'landscape', 'portrait', ''
        - item group의 repeating의 종류: 'Yes', 'No', undefined(COMMON일 때)

        -> landscape에 해당되는 조건: logDirection이 "landscape"이고 repeating이 "Yes" 이거나 "undefined"일 때
         */

        // 뉴로 바이오젠 ID 에 해당하는 디자인들은 현재 진행 중인 과제이므로 landscape 표를 적용하면 안 됨
        if (formData.designID === "DEGN000000000093" || formData.designID === "DEGN000000000100" || formData.designID === "DEGN000000000112") {
            return formData.itemGroups.map((data, idx) => {
                return (
                    <div>
                        <div
                            key={idx}>
                            <ACRFItemGroup2
                                key={idx}
                                itemGroupData={data}
                                isEdit={isEdit}
                                domainList={domainList}
                                onChangeDataList={onChangeDataList}
                                formID={formID}
                                formMode={formMode}
                                itemGroupVisit={itemGroupVisit}
                                armList={armList}
                                formVisit={formVisit}/>
                        </div>
                    </div>
                );
            });
        } else { // 뉴로 바이오젠 외의 모든 디자인들
            // labform 화면 출력부
            if (formData.formType === 'LABORATORY') {
                return (
                    <>
                        {labPortraitData.map((itemgroup, idx) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            key={idx}>
                                            <ACRFItemGroup2
                                                key={idx}
                                                itemGroupData={itemgroup}
                                                compareColor={formData.compareColor}
                                                formType={formData.formType}
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                        {formData.labDiff === undefined ? (
                            <>
                                <table
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        border: "1px solid #000000",
                                        borderCollapse: "collapse",
                                        fontSize: "9pt",
                                        tableLayout: "fixed",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                    <thead>
                                    <tr style={{backgroundColor: "#eaeaec"}}>
                                        {labTableData.header?.map((data) => (
                                            <td style={{
                                                width:
                                                    data === 'Test item' ? "170px" :
                                                        data === 'Result' ? "100px" :
                                                            data === 'Normality' ? "70px" :
                                                                data === 'Clinical Significance' ? "70px" :
                                                                    data === 'Comment' ? "70px" : "",
                                                textAlign: "center",
                                                border: "1px solid #000000",
                                                fontWeight: data === 'Clinical Significance' || data === "Normality" ? "600" : "",
                                            }}>{data}</td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {labTableData.body?.map((data) => (
                                        <tr>
                                            {data.map((each, idx) => (
                                                <td style={{
                                                    background: each === null && '#d3d3d3',
                                                    textAlign: idx === 0 ? "left" : "center",
                                                    padding: "5px 0px",
                                                    paddingLeft: idx === 0 ? "10px" : "",
                                                    border: "1px solid #000000",
                                                }}>{each}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                {labFormDDLComment.length === 0 ?
                                    <></> :
                                    <div style={{fontSize: "8pt", fontFamily: "Arial, sans-serif", padding: "5px",}}>
                                        <span style={{fontWeight: "600"}}>Clinical Significance</span>=DDL
                                        [
                                        {labFormDDLComment?.map((comment, idx, arr) => (
                                            <span>{comment}{arr.length - 1 === idx ? "" : ", "}</span>
                                        ))}
                                        ]
                                    </div>
                                }

                            </>
                        ) : (
                            <>
                                <table
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        border: "1px solid #000000",
                                        borderCollapse: "collapse",
                                        fontSize: "9pt",
                                        tableLayout: "fixed",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                    <thead>
                                    <tr style={{backgroundColor: "#eaeaec"}}>
                                        {formData.labDiff.headerDiff?.map((data) => (
                                            <td style={{
                                                width:
                                                    data === 'Test item' ? "170px" :
                                                        data === 'Result' ? "100px" :
                                                            data === 'Normality' ? "70px" :
                                                                data === 'Clinical Significance' ? "70px" :
                                                                    data === 'Comment' ? "70px" : "",
                                                textAlign: "center",
                                                border: "1px solid #000000",
                                                fontWeight: data.value === 'Clinical Significance' || data.value === "Normality" ? "600" : "",
                                            }}><span
                                                style={{backgroundColor: data.highlight ? formData.compareColor : ""}}>{data.value}</span>
                                            </td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {formData.labDiff.bodyDiff.map((data) => (
                                        <tr>
                                            {data?.map((each, idx) => (
                                                <td style={{
                                                    backgroundColor: each.value === null ? (each.highlight ? formData.compareColor : '#d3d3d3') : "",
                                                    textAlign: idx === 0 ? "left" : "center",
                                                    padding: "5px 0px",
                                                    paddingLeft: idx === 0 ? "10px" : "",
                                                    border: "1px solid #000000",

                                                }}>
                                                <span style={{
                                                    backgroundColor: each.highlight ? formData.compareColor : "",
                                                }}>
                                                    {each.value}
                                                </span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {formData.labDiff.DDLDiff.length === 0 ?
                                    <></> :
                                    <div style={{fontSize: "8pt", fontFamily: "Arial, sans-serif", padding: "5px",}}>
                                        <span style={{fontWeight: "600"}}>Clinical Significance</span>=DDL
                                        [
                                        {formData.labDiff.DDLDiff?.map((comment, idx, arr) => (
                                            <span style={{
                                                backgroundColor: comment.highlight ? formData.compareColor : ""
                                            }}>{comment.value}{arr.length - 1 === idx ? "" : ", "}</span>
                                        ))}
                                        ]
                                    </div>
                                }
                            </>
                        )
                        }
                    </>
                )
            }


            return formData.itemGroups.map((data, idx) => {
                if (formData.logDirection !== "Landscape" || (data.logInfo.logDirection === "Landscape" && data.logInfo.repeating === "No")) {
                    // [일반 폼 형태] itemgroup의 logDirection이 Landscape 이 아닐 때 또는 Landscape 이고 repeating No일 때 원래 form 의 형태로 출력
                    return (
                        <div>
                            <div
                                key={idx}>
                                <ACRFItemGroup2
                                    key={idx}
                                    itemGroupData={data}
                                    isEdit={isEdit}
                                    domainList={domainList}
                                    onChangeDataList={onChangeDataList}
                                    formID={formID}
                                    formMode={formMode}
                                    itemGroupVisit={itemGroupVisit}
                                    armList={armList}
                                    formVisit={formVisit}
                                />
                            </div>
                        </div>
                    );
                } else if (data.logInfo?.logDirection === "Landscape" && (data?.logInfo?.repeating === "Yes" || data?.logInfo?.repeating === undefined)) {
                    // [Landscape 표 형태] itemgroup의 logDirection이 Landscape 이고 repeating이 Yes 이거나 undefined (COMMON) 일 때 landscape 표 생성하는 함수 호출
                    return getLandscapeTable(data); // 일반 landscape 함수 생성하는 함수 호출
                }
            });
        }
    }, [formData.designID, formData.itemGroups, formData.logDirection, isEdit, domainList, onChangeDataList, formID, formMode, itemGroupVisit, armList, formVisit, getLandscapeTable]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    useEffect(() => {
        getFirstRepeatingYesIG();
    }, [formData, getFirstRepeatingYesIG]);

    useEffect(() => {
        const _DDLContentList = [];
        formData.itemGroups.forEach((data) => {
            data.items.forEach((itemData) => {
                let _DDLContent = "";
                if ((itemData.componentType === "DropDownList" || itemData.componentType === "SearchList") && !itemData.element.includes("TPT") && !itemData.defaultValue.includes("|")) {
                    _DDLContent = [`${itemData.question}`, `=DDL [${itemData.codelists?.map(e => e.label).join([', '])}]`];
                } else {
                    if ((itemData.componentType === "DropDownList" || itemData.componentType === "SearchList") && itemData.element.includes("TPT") && itemData.defaultValue.includes("|")) {
                        _DDLContent = "";
                    } else {
                        if ((itemData.componentType === "DropDownList" || itemData.componentType === "SearchList") && itemData.element.includes("TPT") && !itemData.defaultValue.includes("|")) {
                            _DDLContent = [`${itemData.question}`, `=DDL [${itemData.codelists?.map(e => e.label).join([', '])}]`];
                        } else {
                            _DDLContent = "";
                        }
                    }
                }
                _DDLContentList.push(_DDLContent);
            });
        });
        setDDLContentList([...new Set(_DDLContentList)]); // 중복 제거 후 저장
    }, [formData])

    // form 하단 comment 처리
    useEffect(() => {
        if (formData.ID === formID) {
            const _commentList = [];
            formData.itemGroups.forEach((data) => {
                data.items.forEach((itemData) => {
                    _commentList.push(itemData.comment)
                })
            })
            setCommentList(_commentList);
        }
    }, [formData, formID])

    // labform 데이터 가공 부분
    useEffect(() => {
        const _data = copyObject(formData);
        const _labItemgroupIDs = [];

        _data.itemGroups.forEach((itemgroup) => {
            if (_data.formType === 'LABORATORY' && itemgroup.laboratory === 1) {
                _labItemgroupIDs.push(itemgroup.ID);
            }
        })

        const _labTableData = {
            header: [],
            body: []
        }

        // labform 헤더 추가
        _data.itemGroups.forEach((itemgroup) => {
            if (_labItemgroupIDs.includes(itemgroup.ID)) {
                itemgroup.items.forEach((item) => {
                    if (EDCType === 'boim' && item.element === "LBTEST") {
                        _labTableData.header.push("Test item")
                    } else if (EDCType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        _labTableData.header.push("Result")
                    } else if (EDCType !== 'boim' && item.element === "LBORRES") {
                        _labTableData.header.push("Test item")
                        _labTableData.header.push("Result")
                    } else if (item.element === 'LBNRIND') {
                        _labTableData.header.push("Normality")
                    } else if (item.element === 'LBCLSIG') {
                        _labTableData.header.push("Clinical Significance")
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        _labTableData.header.push("Comment")
                    }
                })
            }
        })

        _labTableData.header = [...new Set(_labTableData.header)];

        const DDLComment = [];

        // labform body 추가
        _data.itemGroups.forEach((itemgroup) => {
            if (_labItemgroupIDs.includes(itemgroup.ID)) {
                const temp = [];
                itemgroup.items.forEach((item) => {
                    let _content = '';
                    if (item.dataType === "Datetime" && item.format !== "") {
                        _content = item.format;
                    } else if (item.dataType === "Float") {
                        let num = '_ '.repeat(Math.floor(item.format) - item.format.slice(item.format.indexOf('.') + 1));
                        let primeNum = '_ '.repeat(item.format.slice(item.format.indexOf('.') + 1));
                        _content = num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
                    } else if (item.dataType === "Integer") {
                        _content = "_ ".repeat(item.format);
                    } else if (item.dataType === "Text") {
                        _content = "__________";
                    }

                    if (EDCType === 'boim' && item.element === "LBTEST") {
                        temp[_labTableData.header.indexOf("Test item")] = item.defaultValue;
                    } else if (EDCType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        temp[_labTableData.header.indexOf("Result")] = _content
                    } else if (EDCType !== 'boim' && item.element === "LBORRES") {
                        temp[_labTableData.header.indexOf("Test item")] = item.question;
                        temp[_labTableData.header.indexOf("Result")] = _content;
                    } else if (item.element === 'LBNRIND') {
                        temp[_labTableData.header.indexOf("Normality")] = _content;
                    } else if (item.element === 'LBCLSIG') {
                        temp[_labTableData.header.indexOf("Clinical Significance")] = "DDL";
                        item.codelists.forEach((codelist) => {
                            DDLComment.push(codelist.label);
                        })
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        temp[_labTableData.header.indexOf("Comment")] = "__________" // comment는 항상 text로 고정
                    }
                })
                _labTableData.body.push(temp);
            }
        })


        // 빈값이 들어가면 table이 깨지기 때문에 다른값 대입해주기
        _labTableData.body.forEach((row, index) => {
            _labTableData.body[index] = Array.from(row, item => item === undefined ? null : item);
        })

        // 상단 portrait 데이터 넣어주기
        const _labPortraitData = [];
        _data.itemGroups.forEach((itemgroup) => {
            if (_data.formType === 'LABORATORY' && itemgroup.laboratory === 0) {
                _labPortraitData.push(itemgroup);
            }
        })

        setLabTableData(_labTableData)
        setLabPortraitData(_labPortraitData)
        setLabFormDDLComment([...new Set(DDLComment)])

    }, [formData, EDCType])

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {/*Study Event Name 영역*/}
            {/*Item Group 출력*/}
            {getItemGroupComp()}

            {/*DDL 주석들 출력*/}
            {!(DDLContentList.length === 1 && DDLContentList[0] === '') &&
                <div style={{
                    border: "1px solid #000",
                    marginTop: "-1px",
                    fontSize: "9pt",
                    padding: "5px 10px",
                    fontFamily: "Arial, sans-serif"
                }}>
                    {DDLContentList?.map((DDLContent, idx) => (
                        <div key={idx} style={{marginBottom: "3px"}}>
                            <span style={{fontWeight: "600"}}>{DDLContent[0]}</span>
                            {DDLContent[1]}
                        </div>
                    ))}
                </div>
            }

            {isModal && (
                <ACRFEditModal
                    onClose={() => setIsModal(false)}
                    onSave={handleSave}
                    type={type}
                    data={itemData}
                    domainList={domainList}
                    annotationInfo={currentAnnotation}/>
            )}
        </>
    );
};

export default React.memo(ACRFStudyEvent2);