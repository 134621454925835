import React, { useCallback } from 'react';
import classNames from "classnames";
import { FILL_REQUIRED_FIELD } from "constant/ConstantMsg";

/**
 *  @summary      TextAreaSearch 컴포넌트
 *  @author       김한나
 *  @version
 *  @see          none
 *  @component    TextAreaSearch
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  textarea ui 컴포넌트
 */
const TextAreaSearch = (props) => {

    // props
    /*
      name          : 컴포넌트를 구별 하기 위한 tag 이름
      className     : 크기 지정 classname
      rows          : 행 크기 지정
      onChange      : 변경 처리시 호출 할 상위 컴포넌트 함수
      currentValue  : 현재 value에 표시될 값
      readOnly      : 읽기 전용 여부
      validation    : validation 한 값(true, false)
      onFocus       : focus 가 왔을 때 처리되는 상위 컴포넌트 함수
    */
    const {
        name,
        style,
        className,
        rows,
        onChange,
        currentValue,
        readOnly,
        validation,
        onFocus,
        compare,
        placeholder
    } = props;

    /**
     *  @memberOf     TextAreaSearch
     *  @function     handleChange
     *  @param        {event} e - 이벤트 객체
     *  @return       none
     *  @description  입력값에 변화에 따른 Change함수
     */
    const handleChange = useCallback((e) => {
        //  props로 전달 된 이벤트 처리 함수 호출;
        const targetValue = e.target.value;
        if (onChange !== undefined) {
            onChange(targetValue, name);
        }
    }, [name, onChange]);

    /**
     *  @memberOf     TextAreaSearch
     *  @function     handleFocus
     *  @param        {event} e - 이벤트 객체
     *  @return       none
     *  @description  focus가 왔을 때 처리
     */
    const handleFocus = useCallback((e) => {
        e.preventDefault();
        if (onFocus !== undefined) {
            onFocus(name);
        }
    }, [name, onFocus]);

    /**
     *  @memberOf     TextAreaSearch
     *  @function     getInValidText
     *  @param        none
     *  @return       none
     *  @description  invalid 일 때 출력할 text 내용
     */
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false) {
            return (
                <>
                    <div className="invalid-feedback">{FILL_REQUIRED_FIELD}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [validation]);

    return (
        <>
        <textarea
            rows={rows}
            className={classNames("form-control", {
                className,
                'is-invalid': ((validation !== undefined && validation === false) === true || compare),
            })}
            onChange={handleChange}
            value={currentValue === undefined ? '' : currentValue}
            disabled={readOnly}
            onFocus={handleFocus}
            style={style}
            placeholder={placeholder}
        />
            {getInValidText()}
        </>
    );
};

export default React.memo(TextAreaSearch);
