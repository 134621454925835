import React, { useCallback } from 'react';
import { PaginationItem, PaginationLink, } from 'reactstrap';
import { isDataExist } from "../../common/commonFunction";


/**
 *  @summary    pagination
 *
 *  @author  최종 수정자
 *  @version 1.0, 작업 내용
 *  @see     None
 */
const PagingUtil = (props) => {

    let pageCount = 0;      // 페이지 수
    let beginOB = 0; 		// 블럭의 첫번째 페이지
    let endOB = 0;     // 블럭의 마지막 페이지
    let firstPage = 1;    	// 가장 첫번째 페이지

    /*
        rowCount        // 게시물 수
        currentPage     // 현재 페이지
        pageSize        // 페이지당 글 수
        blockSize       // 블럭당 페이지 수
    */
    const {rowCount, currentPage, pageSize, blockSize, onMove, dataList, onChange, name} = props;


    /**
     *  @memberOf     PagingUtil
     *  @function     handleMove
     *  @param        {number} page   - page
     *  @return       none
     *  @description  페이지 이동 처리
     */
    const handleMove = useCallback((page) => {
        onMove(page);
    }, [onMove]);

    /**
     *  @memberOf     PagingUtil
     *  @function     getFirst
     *  @param        none
     *  @return
     *  @description  첫 페이지를 구한다
     */
    const getFirst = () => {
        if (beginOB === firstPage) {
            if (pageCount !== 0) {
                return (
                    <PaginationItem key={'FIRST'}>
                        <PaginationLink first tag={'button'} onClick={() => {
                            handleMove(firstPage)
                        }}/>
                    </PaginationItem>
                );
            } else {
                return null;
            }
        } else {
            return (
                <PaginationItem key={'FIRST'}>
                    <PaginationLink first tag={'button'} onClick={() => {
                        handleMove(firstPage)
                    }}/>
                </PaginationItem>
            );
        }
    };

    /**
     *  @memberOf     PagingUtil
     *  @function     getPrev
     *  @param        none
     *  @return
     *  @description  이전 블록의 마지막 페이지를 구한다
     */
    const getPrev = () => {
        const tmp = beginOB > 1 ? beginOB - 1 : 1;

        if (beginOB === 1) {
            return null;
        } else {
            return (
                <PaginationItem key={'PREV'}>
                    <PaginationLink previous tag={'button'} onClick={() => {
                        handleMove(tmp)
                    }}/>
                </PaginationItem>
            );
        }
    };

    /**
     *  @memberOf     PagingUtil
     *  @function     getNext
     *  @param        none
     *  @return
     *  @description  다음 블록의 첫 페이지를 구한다
     */
    const getNext = () => {

        const tmp = endOB < pageCount ? endOB + 1 : beginOB;
        if (endOB === pageCount) {
            return null;
        } else {
            return (
                <PaginationItem key={'NEXT'}>
                    <PaginationLink next tag={'button'} onClick={() => {
                        handleMove(tmp)
                    }}/>
                </PaginationItem>
            );
        }
    };

    /**
     *  @memberOf     PagingUtil
     *  @function     getLast
     *  @param        none
     *  @return
     *  @description   마지막 페이지를 구한다
     */
    const getLast = () => {

        if (endOB === pageCount) {
            if (pageCount !== 0) {
                return (
                    <PaginationItem key={'LAST'}>
                        <PaginationLink last tag={'button'} onClick={() => {
                            handleMove(pageCount)
                        }}/>
                    </PaginationItem>
                );
            } else {
                return null;
            }
        } else {
            return (
                <PaginationItem key={'LAST'}>
                    <PaginationLink last tag={'button'} onClick={() => {
                        handleMove(pageCount)
                    }}/>
                </PaginationItem>
            );
        }
    };

    /**
     *  @memberOf     PagingUtil
     *  @function     setPageData
     *  @param        none
     *  @return
     *  @description   현재 페이지 설정 하기
     */
    const setPageData = () => {

        pageCount = Math.ceil(rowCount / pageSize);
        pageCount = pageCount === 0 ? 1 : pageCount;

        // currentBlock
        const currentBlock = Math.ceil(currentPage / blockSize);

        // beginOB
        beginOB = blockSize * (currentBlock - 1) + 1;

        endOB = blockSize * currentBlock;
        // endOB
        endOB = endOB < pageCount ? endOB : pageCount;
    };

    /**
     *  @memberOf     PagingUtil
     *  @function     getPages
     *  @param        none
     *  @return       pageList
     *  @description   페이지 표시
     */
    const getPages = () => {

        setPageData();

        let pageList = [];
        pageList.push(getFirst());
        pageList.push(getPrev());

        for (let i = beginOB; i <= endOB; i++) {
            if (currentPage === i) {
                pageList.push(
                    <li className="page-item active" key={i} onClick={() => {
                        handleMove(i)
                    }}>
                        <button className="btn btn-new">{i}
                            {/*<button className="page-link">{i}*/}
                        </button>
                    </li>
                    // <PaginationItem key={i} active><PaginationLink tag={'button'} onClick={()=>{handleMove(i)}} >{i}</PaginationLink></PaginationItem>
                );
            } else {
                pageList.push(
                    <li className="page-item" key={i} onClick={() => {
                        handleMove(i)
                    }}>
                        <button className="page-link">{i}
                        </button>
                    </li>
                    // <PaginationItem key={i}><PaginationLink tag={'button'} onClick={()=>{handleMove(i)}} >{i}</PaginationLink></PaginationItem>
                );
            }
        }

        pageList.push(getNext());
        pageList.push(getLast());

        return pageList;
    }

    const getPagingNumber = useCallback(() => {
        if (isDataExist(dataList) && dataList[0].hasOwnProperty('no')) {
            return `${dataList[0].no} - ${dataList[dataList.length - 1].no}`;
        } else {
            return "0"
        }
    }, [dataList]);

    const getPageCount = useCallback(() => {
        if (isDataExist(dataList)) {
            return (
                <div className=" d-flex justify-content-center align-items-center m-r-10 height-full m-t-15 ">
                    <p className="f-s-13 ">{getPagingNumber()}</p>
                    <p className="f-s-13">/ {rowCount}</p>
                </div>
            )
        }
    },[dataList, getPagingNumber, rowCount]);

    const Page = [
        10, 25, 50
    ]

    /**
     *  @memberOf     pageSize
     *  @function     handleChange
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  props로 전달 된 이벤트 처리 함수 호출
     */
    const handleChange = useCallback((e) => {
        e.preventDefault();
        if (onChange !== undefined) {
            onChange(name, e.target.value);
        }
    }, [name, onChange]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <ul className="pagination m-t-0 m-b-5 d-flex align-items-center">
                    {getPageCount()}
                    {getPages()}
                    <div className="m-l-10">
                        <select onChange={handleChange} value={pageSize} className="form-control">
                            {Page.map((num) => (
                                <option key={num}>{num}</option>))}
                        </select>
                    </div>
                </ul>
            </div>
        </>
    );

}

export default React.memo(PagingUtil);
