import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import produce from "immer";
import {AXIOS_PUT, getSessionState} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "constant/ConstantURL";
import NetworkLayout from "common/NetworkLayout";
import cn from "classnames";
import useToast from "hooks/useToast";
import deleteIcon from "../../../../../../assets/icons/delete.svg";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import {FILL_REQUIRED_FIELD} from "../../../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SdmStudyElements
 *  @constant     {Object} DEFAULT_DATA_ELEMENT
 *  @description  DEFAULT ELEMENT 데이터 리스트
 */
const DEFAULT_DATA_ELEMENT = {
    ID: "",
    order: "",
    elementName: "",
    elementCode: "",
    elementSubType: "",
    elementType: ""
};

/**
 *  @memberOf       SdmStudyElements
 *  @constant       {Array} ELEMENT_TYPE_LIST
 *  @description    Element Type 리스트
 */
const ELEMENT_TYPE_LIST = [
    {
        text: 'Post-treatment',
        value: 'Post-treatment'
    },
    {
        text: 'No Treatment',
        value: 'No Treatment'
    },
    {
        text: 'Treatment',
        value: 'Treatment'
    },
    {
        text: 'Pre-treatment',
        value: 'Pre-treatment'
    }];

/**
 *  @memberOf       SdmStudyElements
 *  @constant       {Array} ELEMENT_SUBTYPE_LIST
 *  @description    Element SubType 리스트
 */
const ELEMENT_SUBTYPE_LIST = [
    {
        text: 'Screening',
        value: 'Screening',
    },
    {
        text: 'Run-in',
        value: 'Run-in',
    },
    {
        text: 'Treatment',
        value: 'Treatment'
    },
    {
        text: 'Wash-out',
        value: 'Wash-out'
    },
    {
        text: 'Follow-up',
        value: 'Follow-up'
    }];

/**
 *  @memberOf     SdmStudyElements
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {ID, sendObject} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${SDTM_MAPPING_URL}/SDM/studyElements/${ID}`
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmStudyElements
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal에서 Study Elements 탭 편집 컴포넌트
 */
const SdmStudyElements = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyElements
     *  @type         {Object} props
     *  @property     {String} ID - 리스트 ID
     *  @property     {Object} history -  url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {ID, history, onClose} = props;

    /**
     *  @memberOf     SdmStudyElements
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf     SdmStudyElements
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     SdmStudyElements
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmStudyElements
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SdmStudyElements
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     SdmStudyElements
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf       SdmStudyElements
     *  @var            {Array} elementsInputFieldData
     *  @description    현재 입력된 epochs input 데이터
     */
    const [elementsInputFieldData, setElementsInputFieldData] = useState(DEFAULT_DATA_ELEMENT);

    /**
     *  @memberOf     SdmStudyElements
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        showRowNumColumn: false,  // no 표시
        wrapSelectionMove: false,
        enableSorting: false,
        editable: lock !== 2,
        wordWrap: false,
        height: 620,
        enableDrag: lock !== 2,
        enableDragByCellDrag: lock !== 2,
        enableDrop: lock !== 2,
        blankToNullOnEditing: true
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyElements
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_LIST;

        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/SDM/studyElements/${ID}`
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     handleAdd
     *  @description  추가 버튼 클릭 시 실행되는 함수
     */
    const handleAdd = useCallback(() => {
        if (lock !== 2) {
            const inputField = elementsInputFieldData;

            const lastIndex = dataList.length;

            // dataTable에 추가할 데이터 필드 정의
            let addData = {
                ID: "",
                order: lastIndex + 1,
                elementName: inputField.elementName,
                elementCode: inputField.elementCode,
                elementSubType: inputField.elementSubType,
                elementType: inputField.elementType,
            };

            // 데이터 info에 default 값 추가
            setDataList(
                produce(dataList, draft => {
                    draft.push(addData);
                })
            );

            // add 후 입력 필드 초기화
            setElementsInputFieldData(DEFAULT_DATA_ELEMENT);
        }
    }, [dataList, elementsInputFieldData, lock]);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     handleGridChange
     *  @param        {Array}  list  - Epochs 변경 된 List
     *  @description  grid 변경 시(수정, Drag & Drop, 삭제) 실행되는 함수
     */
    const handleGridChange = useCallback((list) => {
        setDataList(list);
    }, []);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     validateData
     *  @return       {Boolean} validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        return window.AUIGrid.validateGridData(`#sdmStudyElements-grid`, ["elementName", "elementCode"], FILL_REQUIRED_FIELD);
    }, []);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {

            // 데이터 validation
            const valid = validateData();

            if (valid === false) {
                return;
            }

            dataList.map((data, index) => {
                data.order = index + 1
                return data;
            });

            // 데이터 수정 요청
            const sendObject = {
                list: dataList
            };
            const command = COMMAND.DATA_UPDATE; // 데이터 수정 요청
            const params = {
                requestUrl: `${SDTM_MAPPING_URL}/SDM/studyElements`,
                ID: ID,
                sendObject: JSON.stringify(sendObject)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, lock, validateData]);

    /**
     *  @memberOf     SdmStudyElements
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST :
                    if (data.hasOwnProperty('data')) {
                        setDataList(data.data);// 서버 데이터 저장
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                //no default
            }
        }
    }, [getDataInfo, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    // grid 데이터 setting 작업
    useEffect(() => {
        tableRef.current = window.AUIGrid.create(`#sdmStudyElements-grid`, columnLayout, auiGridProps);

        if (dataList.length > 0) {
            window.AUIGrid.setGridData(tableRef.current, dataList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#sdmStudyElements-grid`);
        };
    }, [ID]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell edit 이벤트
        window.AUIGrid.bind(tableRef.current, "cellEditEnd", (event) => {
            if (handleGridChange !== undefined) {
                window.AUIGrid.updateRow(tableRef.current, event.rowIndex);
                const changeGridData = window.AUIGrid.getGridData(tableRef.current);

                handleGridChange(changeGridData);
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "dropEnd", (event) => {
            if (handleGridChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                handleGridChange(changeGridData);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (handleGridChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                handleGridChange(changeGridData);
            }
        });
    }, [handleGridChange]);

    /**
     *  @memberOf     SdmStudyElements
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'order',
            headerText: 'No.',
            width: '5%',
            editable: false,
        },
        {
            dataField: 'elementName',
            headerText: 'Element Name<span class=\"ml-1 text-danger\">*</span>',
            editable: lock !== 2,
            editRenderer: {
                type: " InputEditRenderer",
                maxlength: 40
            }
        },
        {
            dataField: 'elementCode',
            headerText: 'Element Code<span class=\"ml-1 text-danger\">*</span>',
            editable: lock !== 2,
            editRenderer: {
                type: " InputEditRenderer",
                maxlength: 8
            }
        },
        {
            dataField: 'elementType',
            headerText: 'Element Type',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: ELEMENT_TYPE_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            },
        },
        {
            dataField: 'elementSubType',
            headerText: 'Element Subtype',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: ELEMENT_SUBTYPE_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteTableRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="d-flex justify-content-end m-b-10">
                <button className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleAdd}>Add
                </button>
            </div>
            <div className='grid-style' id="sdmStudyElements-grid"/>
            <div className="modal-button">
                <div className="d-flex justify-content-end p-10 border-top">
                    <button
                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(SdmStudyElements);


