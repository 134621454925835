import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import cn from 'classnames';
import {produce} from "immer";
import html2canvas from "html2canvas";
import PerfectScrollbar from "react-perfect-scrollbar";
import {DESIGN_URL} from "constant/ConstantURL";
import {
    AXIOS_GET,
    AXIOS_PUT_FORM,
    copyObject,
    generateRandomId,
    getSessionState,
    isDataExist
} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import useToast from "hooks/useToast";
import TextField from "imtrial/components/TextField";
import SchemaEditPopup, {CELL_DATA_FIELD} from "./SchemaEditPopup";
import DesignBottom from "../DesignBottom";
import SchemaPreviewModal from "./SchemaPreviewModal";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} SCHEMA_DEFINE_DATA
 *  @description  Objective field 명 정의
 */
const SCHEMA_DEFINE_DATA = {
    EPOCH: 'epoch',
    CELL: 'cell',
    ARM: 'arm',
    TYPE: 'type',
    TREATMENT: 'treatment',
    ARM_IDS: 'armIDs',
    EPOCH_ID: 'epochID',
    NAME: 'name',
    IP: 'ip',
    AMOUNT: 'amount',
    ID: 'ID'
};

/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} EDITING_TYPE
 *  @description  데이터 추가 or 삭제 타입 define
 */
const EDITING_TYPE = {
    ADD: 'add',
    DELETE: 'delete'
};

/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} MILE_STONE_DEFINE
 *  @description  Milestone field 명 정의
 */
const MILE_STONE_DEFINE = {
    START: 'start',
    END: 'end'
};

/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} INIT_ARM_DATA
 *  @description  arm 추가 시 필요한 데이터 default 값
 */
const INIT_ARM_DATA = {
    ID: '',
    groupName: '',
    studySchedule: '',
};

/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} INIT_CELL_DATA
 *  @description  cell 추가 시 필요한 데이터 default 값
 */
const INIT_CELL_DATA = {
    ID: '',
    armIDs: [],
    epochID: ''
};

/**
 *  @memberOf     SchemaConfig
 *  @constant     {Object} INIT_EPOCH_DATA
 *  @description  epoch 추가 시 필요한 데이터 default 값
 */
const INIT_EPOCH_DATA = {
    ID: '',
    start: "",
    end: "",
    name: "Epoch",
    no: ''
};

/**
 *  @memberOf       SchemaConfig
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, type, sendObject} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO :
            url = `${requestUrl}/synopsis/schema/${ID}`;
            response = await AXIOS_GET(url);
            break;

        // IP 데이터 상세 정보 요청
        case COMMAND.IP_LIST :
            url = `${requestUrl}/synopsis/ip/meta/${ID}?type=${type}`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${requestUrl}/synopsis/schema/${ID}`;
            response = await AXIOS_PUT_FORM(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @see          html2canvas (https://github.com/niklasvh/html2canvas)
 *  @component    SchemaConfig
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  design information 의 Schema 편집 컴포넌트
 */
const SchemaConfig = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf    SchemaConfig
     *  @type        {Object} props
     *  @property    {String} ID - 리스트 ID
     *  @property    {Object} history - url 이동을 위해 사용
     *  @property    {Function} referencePopup - reference 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property    {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property    {Function} onChangeData - 변경 전 데이터, 변경 후 데이터 호출하는 함수
     *  @property    {Boolean} isSaved - isDataChange Modal에서 Yes 버튼 상태
     *  @property    {Boolean} onSetSave - isDataChange Modal Yes 버튼 클릭 상태 처리
     *  @property    {Boolean} isClosed - isDataChange Modal에서 No 버튼 상태
     *  @property    {Boolean} onSetClose - isDataChange Modal No 버튼 클릭 상태 처리
     *  @property    {Boolean} onDataChangeModal - isDataChange Modal 상태
     *  @property      {Boolean} isChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태
     *  @property      {Boolean} isChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태
     *  @property      {Boolean} onChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property      {Boolean} onChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property      {Boolean} onChangeDataCheckModal - Top Tab의 isDataChange Modal 상태 처리
     *  @description 상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        history,
        referencePopup,
        onList,
        onChangeData,
        isSaved,
        onSetSave,
        isClosed,
        onSetClose,
        onDataChangeModal,
        isChangeTabSave,
        isChangeTabClose,
        onChangeTabSave,
        onChangeTabClose,
        onChangeDataCheckModal
    } = props;

    /**
     *  @memberOf     SchemaConfig
     *  @type         Object} getSessionState
     *  @property     String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf     SchemaConfig
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SchemaConfig
     *  @var          {HTMLDivElement} captureRef
     *  @description  저장 시 file 객체로 전송할 이미지 캡쳐 영역 ref
     */
    const captureRef = useRef(null);

    /**
     *  @memberOf       SchemaConfig
     *  @var            {Object} dataInfo
     *  @description    화면에 표시 될 상세 정보 및 validation 필드 table
     */
    const [dataInfo, setDataInfo] = useState({arm: [], epoch: [], cell: [], ip: []});

    /**
     *  @memberOf       SchemaConfig
     *  @var            {Object} originDataInfo
     *  @description    원본 데이터
     */
    const [originDataInfo, setOriginDataInfo] = useState({});

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} isOpen
     *  @description  schema 수정 modal 오픈 여부
     */
    const [isOpen, setIsOpen] = useState(false);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} isEditingArmName
     *  @description  arm 이름 수정 중인지 여부
     */
    const [isEditingArmName, setIsEditingArmName] = useState([]);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} isEditingStudySchedule
     *  @description  studySchedule 이름 수정 중인지 여부
     */
    const [isEditingStudySchedule, setIsEditingStudySchedule] = useState([]);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} currentEpochId
     *  @description  cell 클릭 시 cell의 epoch ID 정보 저장
     */
    const [currentEpochId, setCurrentEpochId] = useState('');

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} currentEpochName
     *  @description  cell 클릭 시 cell의 epoch name 정보 저장
     */
    const [currentEpochName, setCurrentEpochName] = useState('');

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} editCellData
     *  @description  cell 클릭 시 저장할 cell 데이터
     */
    const [editCellData, setCurrentCellData] = useState({});

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Object} currentIpListData
     *  @description  cell 클릭 시 cell 의 ip list
     */
    const [currentIpListData, setCurrentIpListData] = useState([]);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Boolean} isCapturingView
     *  @description  이미지 생성 시 schema 테이블 버튼 노출 여부
     */
    const [isCapturingView, setIsCapturingView] = useState(false);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Boolean} isPreviewModal
     *  @description  Schema Preview Modal의 Open/Close 상태
     */
    const [isPreviewModal, setIsPreviewModal] = useState(false);

    /**
     *  @memberOf     SchemaConfig
     *  @var          {Boolean} isSaveCheck
     *  @description  Schema save 클릭 상태
     */
    const [isSaveCheck, setIsSaveCheck] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SchemaConfig
     *  @function     getDataInfo
     *  @description  상세 정보 조회 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
        };
        (netWorkAgent.current !== null && (
            netWorkAgent.current.request(command, params)
        ));
    }, [ID]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleEditEpochList
     *  @param        {String} type - 추가 혹은 삭제 타입 구분 값 (add / remove)
     *  @param        {Number} index - 삭제 시 해당 epoch 항목의 index
     *  @description  epoch 추가 혹은 삭제 버튼 클릭 시 실행되어 epoch 와 cell 데이터 변경
     */
    const handleEditEpochList = useCallback((type, index) => {
        if (lock !== 2) {
            // 추가 버튼 클릭 시
            if (type === EDITING_TYPE.ADD) {
                const newCellList = [];
                const epochData = copyObject(INIT_EPOCH_DATA); // epoch init data
                epochData.ID = generateRandomId(); // 랜덤 아이디 생성

                // arm 갯수 만큼 필요한 cell init data 생성
                let i;
                for (i = 0; i < dataInfo[SCHEMA_DEFINE_DATA.ARM].length; i++) {
                    let cellData = copyObject(INIT_CELL_DATA); // cell init data
                    let currentArmId = dataInfo[SCHEMA_DEFINE_DATA.ARM][i].ID; // 현재 arm id
                    cellData.ID = generateRandomId(); // cell 랜덤 아이디 생성
                    cellData.epochID = epochData.ID; // epoch id 추가

                    cellData.armIDs.push(currentArmId); // arm id 추가
                    newCellList.push(cellData); // cell data 변경
                }

                setDataInfo(produce(dataInfo, draft => {
                    draft[SCHEMA_DEFINE_DATA.EPOCH].splice(index + 1, 0, epochData); // epoch 데이터 추가

                    let epochIdx;
                    for (epochIdx = 0; epochIdx < draft[SCHEMA_DEFINE_DATA.EPOCH].length; epochIdx++) {
                        draft[SCHEMA_DEFINE_DATA.EPOCH][epochIdx].no = epochIdx + 1; // epoch 순서 값 정렬, 중간 삭제가 있을 경우 다시 순서 재배열
                    }

                    draft[SCHEMA_DEFINE_DATA.CELL].push(...newCellList); // 변경한 cell 데이터 추가
                }));
            } else if (type === EDITING_TYPE.DELETE) { // 삭제 버튼 클릭시
                // epoch는 무조건 하나 이상 존재해야 함
                if (dataInfo[SCHEMA_DEFINE_DATA.EPOCH].length > 1) {
                    const selectEpochId = dataInfo[SCHEMA_DEFINE_DATA.EPOCH][index].ID; // index 통해 해당 epoch id 조회
                    // 삭제할 epoch 에 속하지 않은 cell 객체 리스트
                    //dataInfo에 넣어주기 위함
                    const filteredCellList = dataInfo[SCHEMA_DEFINE_DATA.CELL].filter((cell) => {
                        return selectEpochId !== cell.epochID;
                    });
                    // 삭제할 epoch 에 속한 cell 객체 리스트
                    const deleteCellList = dataInfo[SCHEMA_DEFINE_DATA.CELL].filter(cell => {
                        return cell.epochID === selectEpochId;
                    });
                    // 삭제할 cell 리스트에 속하지 않은 ip 리스트
                    //ip : cell 정보, cell 정보는 cell 클릭 시 모달에 넘겨줘야 함
                    const filteredIpList = dataInfo[SCHEMA_DEFINE_DATA.IP].filter((ipData => {
                        let deleteIdList = deleteCellList.map(cell => cell.ID);

                        return !deleteIdList.includes(ipData.cellID);
                    }));

                    setDataInfo(produce(dataInfo, draft => {
                        draft[SCHEMA_DEFINE_DATA.EPOCH].splice(index, 1); // epoch 삭제

                        let epochIdx;
                        for (epochIdx = 0; epochIdx < draft[SCHEMA_DEFINE_DATA.EPOCH].length; epochIdx++) {
                            draft[SCHEMA_DEFINE_DATA.EPOCH][epochIdx].no = epochIdx + 1; // epoch 순서 재정렬
                        }

                        draft[SCHEMA_DEFINE_DATA.CELL] = filteredCellList; // cell 데이터 변경
                        draft[SCHEMA_DEFINE_DATA.IP] = filteredIpList; // ip 데이터 변경
                    }));
                }
            }
        }
    }, [dataInfo, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleEditArmName
     *  @param        {Number} index - 선택한 요소의 index
     *  @param        {String} value - 입력 값
     *  @description  arm input 필드 변경 시 실행되는 함수
     */
    const handleEditArmName = useCallback((index, value) => {
        setDataInfo(produce(dataInfo, draft => {
            draft[SCHEMA_DEFINE_DATA.ARM][index].groupName = value;
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleEditStudySchedule
     *  @param        {Number} index - 선택한 요소의 index
     *  @param        {String} value - 입력 값
     *  @description  study Schedule input 필드 변경 시 실행되는 함수
     */
    const handleEditStudySchedule = useCallback((index, value) => {
        setDataInfo(produce(dataInfo, draft => {
            draft[SCHEMA_DEFINE_DATA.ARM][index].studySchedule = value;
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleAddArm
     *  @description  arm 추가 버튼 클릭 시 실행되어 arm 과 cell 데이터 추가
     */
    const handleAddArm = useCallback(() => {
        if (lock !== 2) {
            const newCellList = []; // 빈 cell data list
            const armData = copyObject(INIT_ARM_DATA); // arm init data
            armData.ID = generateRandomId(); // 랜덤 아이디 생성 - 서버

            // epoch 갯수 만큼 필요한 cell init data 생성
            let i;
            for (i = 0; i < dataInfo[SCHEMA_DEFINE_DATA.EPOCH].length; i++) {
                let cellData = copyObject(INIT_CELL_DATA); // cell init data
                let currentEpochId = dataInfo[SCHEMA_DEFINE_DATA.EPOCH][i].ID; // 현재 epoch id
                cellData.ID = generateRandomId(); // cell 랜덤 아이디 생성
                cellData.epochID = currentEpochId; // cell 의 epoch id 추가
                cellData.armIDs.push(armData.ID); // arm id 추가
                newCellList.push(cellData);
            }

            setDataInfo(produce(dataInfo, draft => {
                draft[SCHEMA_DEFINE_DATA.ARM].push(armData); // arm 데이터 변경
                draft[SCHEMA_DEFINE_DATA.CELL].push(...newCellList); // cell 데이터 변경
            }));
        }
    }, [dataInfo, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleDeleteArm
     *  @param        {Number} index - 클릭한 요소의 index
     *  @param        {String} id - 클릭하 요소의 ID
     *  @description  arm 삭제 버튼 클릭 시 실행되어 arm 과 cell 데이터 함께 삭제
     */
    const handleDeleteArm = useCallback((index, id) => {
        if (lock !== 2) {
            // 데이터 복사
            const copyDataInfo = JSON.parse(JSON.stringify(dataInfo));
            // arm 과 1:1 로 생성 된 cell 지우기 (병합 안된 cell)
            const filterCellList = copyDataInfo[SCHEMA_DEFINE_DATA.CELL].filter((currentCell, i) => {
                return !(currentCell.armIDs.length === 1 && currentCell.armIDs.includes(id));
            });
            // 병합된 cell의 경우, arm IDs 에서 해당 arm 만 삭제
            const finalCellList = filterCellList.map((currentCell, i) => {
                let findIndex = currentCell.armIDs.findIndex((armId) => armId === id);
                if (findIndex > -1) {
                    currentCell.armIDs.splice(findIndex, 1);
                }

                return currentCell;
            });

            let newIPinfo = dataInfo.ip.filter((item) => {
                return finalCellList.find((list) => list.ID === item.cellID)
            });

            setDataInfo(produce(dataInfo, draft => {
                // arm 갯수는 무조건 한 개 이상
                if (draft[SCHEMA_DEFINE_DATA.ARM].length > 1) {
                    draft[SCHEMA_DEFINE_DATA.ARM].splice(index, 1);
                }
                draft[SCHEMA_DEFINE_DATA.CELL] = finalCellList; // 변경한 cell 데이터 덮어쓰기
                draft[SCHEMA_DEFINE_DATA.IP] = newIPinfo; // 변경된 IP 데이터 덮어쓰기
            }));
        }
    }, [dataInfo, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleChange
     *  @param        {String} name   - 입력 요소 필드 값
     *  @param        {String} value  - 입력 값
     *  @param        {Number} index  - 입력 요소의 index
     *  @description  milestone input 필드 변경시 실행되는 함수
     */
    const handleChange = useCallback((name, value, index) => {
        switch (name) {
            case MILE_STONE_DEFINE.START :
            case MILE_STONE_DEFINE.END :
                setDataInfo(produce(dataInfo, draft => {
                    draft[SCHEMA_DEFINE_DATA.EPOCH][index][name] = value;
                }));
                break;

            // no default
        }
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleOpenPopup
     *  @param        {Object} cellData - 현재 클릭한 cell 의 데이터
     *  @param        {String} ipList - 현재 클릭한 ip 의 데이터
     *  @param        {String} epochId - 현재 클릭한 cell 의 epoch id
     *  @param        {String} epochName - 현재 클릭한 cell 의 epoch 이름
     *  @description  cell 클릭했을 시 실행되는 함수
     */
    const handleOpenPopup = useCallback((cellData, ipList, epochId, epochName) => {
        if (lock !== 2) {
            // modal 컴포넌트로 넘겨줄 데이터
            setCurrentEpochId(epochId);
            setCurrentEpochName(epochName);
            setCurrentCellData(cellData);
            setCurrentIpListData(ipList);
            // 팝업 오픈
            setIsOpen(true);
        }
    }, [lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleClose
     *  @param        (Number) num - modal close 하기 위한 구분
     *  @description  팝업 close 버튼 클릭 시 실행
     */
    const handleClose = useCallback((num) => {
        if (num === 1) {
            setIsOpen(false);
        } else if (num === 2) {
            setIsPreviewModal(false);
        }
    }, []);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleDoubleClickStudySchedule
     *  @param        {Number} index - 현재 선택한 요소의 index
     *  @description  studySchedule 이름을 두번 클릭 시 실행되어 editing 모드로 변경
     */
    const handleDoubleClickStudySchedule = useCallback((index) => {
        if (lock !== 2) {
            setIsEditingStudySchedule(produce(isEditingStudySchedule, draft => {
                draft[index] = true;
            }));
        }
    }, [isEditingStudySchedule, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleBlur
     *  @param        {Number} index - 현재 선택한 요소의 index
     *  @description  studySchedule input 요소가 focus 를 잃었을 시 실행되는 함수
     */
    const handleBlur = useCallback(() => {
        const _isEditingStudySchedule = [];
        const _isEditingArmName = [];
        if (isDataExist(dataInfo) && dataInfo !== undefined) {
            dataInfo.arm.forEach((arm, idx) => {
                if (arm.studySchedule !== "") {
                    _isEditingStudySchedule.push(false)
                } else {
                    _isEditingStudySchedule.push(true);
                }
                if (arm.groupName !== "") {
                    _isEditingArmName.push(false);
                } else {
                    _isEditingArmName.push(true);
                }
            });
            setIsEditingStudySchedule(_isEditingStudySchedule);
            setIsEditingArmName(_isEditingArmName);
        }
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleDoubleClickArm
     *  @param        {Number} index - 현재 선택한 요소의 index
     *  @description  arm 이름을 두번 클릭 시 실행되어 editing 모드로 변경
     */
    const handleDoubleClickArm = useCallback((index) => {
        if (lock !== 2) {
            setIsEditingArmName(produce(isEditingArmName, draft => {
                draft[index] = true;
            }));
        }
    }, [isEditingArmName, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleBlurArm
     *  @param        {Number} index - 현재 선택한 요소의 index
     *  @description  arm input 요소가 focus 를 잃었을 시 실행되는 함수
     */
    const handleBlurArm = useCallback((index) => {
        setIsEditingArmName(produce(isEditingArmName, draft => {
            draft[index] = false;
        }));
    }, [isEditingArmName]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getMileStoneTr
     *  @param        {Array} epochList - 해당 위치와 일치하는 epoch 데이터
     *  @return       {ReactElement} milestone 표시 요소
     *  @description  epoch 에 해당하는 milestone 요소 생성 및 출력
     */
    const getMileStoneTr = useCallback((epochList) => {

        return epochList.map((epoch, epochIdx) => {
            const isLastEpoch = epochList.length - 1 === epochIdx; // 마지막 epoch 인지 체크

            return (
                <th
                    key={epochIdx}
                    className={cn("px-3 py-1 h-100",
                        {
                            'border-0 epoch-before': true,
                            'epoch-after': isLastEpoch
                        })}>
                    <div className={"d-flex align-items-stretch justify-content-between"}>
                        <TextField
                            name={MILE_STONE_DEFINE.START}
                            currentValue={epoch[MILE_STONE_DEFINE.START]}
                            onChange={(name, value) => {
                                handleChange(name, value, epochIdx)
                            }}
                            className={cn({'py-3': epoch[MILE_STONE_DEFINE.START] === ''})}
                            disabled={lock === 2}/>

                        {/* 마지막 epoch 의 경우 milestone 2개 표시 */}
                        {isLastEpoch && (
                            <TextField
                                name={MILE_STONE_DEFINE.END}
                                currentValue={epoch[MILE_STONE_DEFINE.END]}
                                className={"ml-2"}
                                onChange={(name, value) => {
                                    handleChange(name, value, epochIdx)
                                }}
                                placeholder={"End of Study (EoS)"}
                                disabled={lock === 2}/>
                        )}
                    </div>
                </th>
            )
        });
    }, [handleChange, lock]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     settingEpochNameList
     *  @return       {Array} epoch name 리스트
     *  @description  epoch treatment 가 여러 개 일 경우 이름 뒤에 순번 매기기 기능
     */
    const settingEpochNameList = useCallback(() => {
        if (dataInfo.hasOwnProperty('epoch')) {
            const treatmentEpoch = [];

            // treatment epoch 조회
            dataInfo.epoch.forEach((epoch, index) => {
                // epoch 이름이 treatment 일 경우,
                if (epoch.name.includes('Treatment')) {
                    treatmentEpoch.push(index); // treatment 갯수 파악 위해 index 넣기
                }
            });
            return dataInfo.epoch.map((epoch, epochIndex) => {

                // treatment epoch에 해당하는 epoch라면 이름 + 갯수 붙여서 return
                if (treatmentEpoch.includes(epochIndex)) {
                    if (treatmentEpoch.length > 1) { // treatment 2개 이상일 경우
                        return `Treatment ${treatmentEpoch.indexOf(epochIndex) + 1}`; // treatment 이름 생성
                    }
                }

                return epoch.name;
            });
        }
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @var          epochNameList
     *  @description  epoch treatment 갯수에 따라 변경되는 epoch 이름 리스트 메모이제이션
     */
    const epochNameList = useMemo(() => settingEpochNameList(), [settingEpochNameList]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getTableEpochTr
     *  @param        {Array} epochList - 전체 epoch List 데이터
     *  @return       {ReactElement} epoch 가 표시된 table thead
     *  @description  epoch 가 표시된 테이블 헤더 생성 및 출력
     */
    const getTableEpochTr = useCallback((epochList) => {
        return epochList.map((epoch, epochIdx) => {
            return (
                <th key={epochIdx} className="epoch">
                    {epoch}
                    {/* 저장 시 이미지 생성할 때 버튼 미표시 */}
                    {!isCapturingView && (
                        <span>
                             <button
                                 className={cn("btn btn-xs btn-blue ml-2", {'disabled': lock === 2})}
                                 onClick={() => {
                                     handleEditEpochList(EDITING_TYPE.ADD, epochIdx)
                                 }}>
                                 +
                             </button>

                            <button
                                className={cn("btn btn-xs btn-orange text-white ml-2", {'disabled': lock === 2})}
                                onClick={() => {
                                    handleEditEpochList(EDITING_TYPE.DELETE, epochIdx)
                                }}>
                                -
                            </button>
                        </span>
                    )}
                </th>
            )
        });
    }, [isCapturingView, lock, handleEditEpochList]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getArmIds
     *  @param        {Array} armList - 전체 arm 리스트
     *  @return       {Array} armIDs - arm ID 리스트
     *  @description  arm 리스트의 ID 만 조회
     */
    const getArmIds = useCallback((armList) => {
        let armIds = [];

        let arm;
        for (arm of armList) {
            armIds.push(arm.ID);
        }

        return armIds;
    }, []);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getFindCellData
     *  @param        {Object} epochData - epoch 데이터
     *  @param        {Array} cellList - 전체 cell 리스트 데이터
     *  @return       {Array} cellListByEpoch - epoch에 속한 cell 조회
     *  @description  epoch 에 속한 cell 리스트 조회
     */
    const getFindCellData = useCallback((epochData, cellList) => {
        let cellListByEpoch = null;

        if (isDataExist(cellList)) {
            cellListByEpoch = cellList.filter((cell) => {
                return cell.epochID === epochData.ID;
            });
        }

        return cellListByEpoch;
    }, []);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getCellDataSet
     *  @param        {Object} dataInfo - 전체 data
     *  @return       {Array} cellDataSet
     *  @description  Schema table 에 표시할 cell 리스트 데이터 가공
     */
    const getCellDataSet = useCallback((dataInfo) => {
        const armIDs = getArmIds(dataInfo[SCHEMA_DEFINE_DATA.ARM]); // arm ID 리스트
        let cellDataSet = new Array(dataInfo[SCHEMA_DEFINE_DATA.ARM].length);

        let i;
        let cellDataLength = cellDataSet.length;
        for (i = 0; i < cellDataLength; i++) {
            cellDataSet[i] = new Array(dataInfo[SCHEMA_DEFINE_DATA.EPOCH].length); // arm - epoch 를 표현하기 위한 이차원 배열 생성
        }


        let epochIdx;
        for (epochIdx = 0; epochIdx < dataInfo[SCHEMA_DEFINE_DATA.EPOCH].length; epochIdx++) {
            const epoch = dataInfo[SCHEMA_DEFINE_DATA.EPOCH][epochIdx]; // epoch 정보
            const findCellArrayByEpoch = getFindCellData(epoch, dataInfo[SCHEMA_DEFINE_DATA.CELL]); // epoch 에 속한 cell 정보 찾기

            // 만일 epoch 안에 cell 이 없을 경우
            if (!isDataExist(findCellArrayByEpoch)) {
                continue; // 그냥 넘김 (cell 생성할 필요 X)
            }

            let cellFindIdx;
            let findCellLength = findCellArrayByEpoch.length; // epoch 에 해당하는 cell 의 갯수
            for (cellFindIdx = 0; cellFindIdx < findCellLength; cellFindIdx++) {
                const cellFind = findCellArrayByEpoch[cellFindIdx]; // cell 에 하나씩 접근
                let armMinIndex = -1;

                // table cell 세로 병합 여부 확인 후 처리
                // 각 cell data 마다 span 될 size 값 구하기
                let armID;
                for (armID of cellFind.armIDs) {
                    const armIdx = armIDs.indexOf(armID);
                    let size = cellFind.armIDs.length; // span size

                    // armIDs 가 비어있으면 cell 객체 생성 X
                    if (armIdx === -1) {
                        continue;
                    }

                    if (armMinIndex === -1 || armIdx < armMinIndex) {
                        armMinIndex = armIdx;
                    }

                    // armMinIndex 가 현재 armIdx 보다 작으면 cell 그릴 필요 없음
                    if (armMinIndex < armIdx) {
                        size = 0;
                    }

                    // 데이터 셋 객체 생성
                    cellDataSet[armIdx][epochIdx] = {
                        size: size, // 세로 span 시킬 사이즈
                        object: cellFind, // cell 정보
                        epochName: epoch.name, // epoch 이름
                        epochId: epoch.ID // epoch ID
                    }
                }
            }
        }

        return cellDataSet;
    }, [getArmIds, getFindCellData]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     findIpObjectsByCell
     *  @param        {String} cellID - cell id
     *  @return       {Array} ip list
     *  @description  cell 에 포함된 ip 리스트 조회
     */
    const findIpObjectsByCell = useCallback((cellID) => {
        if (cellID !== undefined) {
            return dataInfo.ip.filter(ipData => ipData.cellID === cellID);
        }
    }, [dataInfo.ip]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getTableCellData
     *  @param        {Object} cellTable - 생성한 화면용 cell list data
     *  @return       {ReactElement} cell td element
     *  @description  cell 데이터에 맞춰 화면 element 요소 반환
     */
    const getTableCellData = useCallback((cellTable) => {
        // eslint-disable-next-line array-callback-return
        return cellTable.map((cell, cellIdx) => {
            // cell 이 있을 경우만
            if (isDataExist(cell)) {
                if (cell.size > 0) { // cell size 가 있을 경우만 cell 생성
                    const findIpList = findIpObjectsByCell(cell.object.ID); // cell 에 속한 ip 리스트 조회

                    return (
                        <td
                            className={'p-10 cursor-pointer hover'}
                            key={cellIdx}
                            rowSpan={cell.size}
                            onClick={() => {
                                handleOpenPopup(cell.object, findIpList, cell.epochID, cell.epochName)
                            }}>

                            {/* ip 리스트 요소 화면에 표시 */}
                            {isDataExist(findIpList) && (
                                findIpList.map((ipData, ipIdx) => {
                                    //소수점 둘째 자리까지 보이게 처리
                                    const calculationDrug = (Math.floor(([ipData.amount] * [ipData.dose]) * 100) / 100);
                                    const changePoint = parseFloat(calculationDrug);
                                    const convertText = `${ipData.name} ${ipData.shape} ${changePoint} ${ipData.unit}`; // ip 이름 정보 표시
                                    return (
                                        <div key={ipIdx}
                                             className={cn({
                                                 'segment p-10 mx-1 border': true,
                                                 'm-b-10': ipIdx !== findIpList.length - 1
                                             })}>
                                            {convertText}
                                        </div>
                                    )
                                })
                            )}
                        </td>
                    )
                }
            }
        });
    }, [findIpObjectsByCell, handleOpenPopup]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     createTableStructure
     *  @return       {ReactElement} 화면 table 요소 반환
     *  @description  테이블 구조 그리기
     */
    const createTableStructure = useCallback(() => {
        const cellDataTable = getCellDataSet(dataInfo); // 화면용 cell dataset 조회
        return dataInfo[SCHEMA_DEFINE_DATA.ARM].map((arm, armIdx) => {
            return (
                <>
                    <tr key={armIdx}>
                        <th
                            className="px-3"
                            onDoubleClick={() => {
                                handleDoubleClickStudySchedule(armIdx)
                            }}>

                            <div className="justify-content-between d-flex align-items-center">
                                {/* arm 이름 수정 혹은 조회 시 보여줄 요소 */}
                                {isEditingStudySchedule[armIdx] ? (
                                    <TextField currentValue={arm.studySchedule} onChange={handleEditStudySchedule}
                                               name={armIdx}
                                               className={"w-50"}
                                               validation={isDataExist(arm.studySchedule)}/>
                                ) : (
                                    <div className={"cursor-pointer"}>{arm.studySchedule}</div>
                                )}

                                <div className="d-flex justify-content-between m-l-10">
                                    {/* 마지막 arm 이 아니거나 이미지 캡쳐를 하지 않을 경우에 arm 추가 버튼 노출*/}
                                    {dataInfo[SCHEMA_DEFINE_DATA.ARM].length - 1 === armIdx && !isCapturingView && (
                                        <button
                                            className={cn("btn btn-xs btn-blue", {'disabled': lock === 2})}
                                            onClick={handleAddArm}>+</button>
                                    )}

                                    {/* 첫번째 arm 이 아니거나 이미지 캡쳐를 하지 않을 경우에 arm 삭제 버튼 노출*/}
                                    {armIdx !== 0 && !isCapturingView && (
                                        <button
                                            className={cn("btn btn-xs btn-orange text-white ml-2", {'disabled': lock === 2})}
                                            onClick={() => {
                                                handleDeleteArm(armIdx, arm.ID)
                                            }}>-
                                        </button>
                                    )}
                                </div>
                            </div>
                        </th>
                        {/*arm 정보 th 출력*/}
                        <th
                            className="px-3"
                            onDoubleClick={() => {
                                handleDoubleClickArm(armIdx)
                            }}>

                            <div className="justify-content-between d-flex align-items-center">
                                {/* arm 이름 수정 혹은 조회 시 보여줄 요소 */}
                                {isEditingArmName[armIdx] ? (
                                    <TextField currentValue={arm.groupName} onChange={handleEditArmName} name={armIdx}
                                               className={"w-50"}
                                               validation={isDataExist(arm.groupName)}/>
                                ) : (
                                    <div className={"cursor-pointer"}>{arm.groupName}</div>
                                )}
                            </div>
                        </th>
                        {/* ip cell data 출력 */}
                        {getTableCellData(cellDataTable[armIdx], arm)}
                    </tr>
                </>
            )
        });
    }, [getCellDataSet, dataInfo, isEditingStudySchedule, handleEditStudySchedule, isCapturingView, lock, handleAddArm, isEditingArmName, handleEditArmName, getTableCellData, handleDoubleClickStudySchedule, handleDeleteArm, handleDoubleClickArm]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleApply
     *  @param        {Object} editCellData - 변경한 cell 데이터
     *  @param        {object} editIpData - 변경한 ip 데이터
     *  @param        {object} editEpochName - 변경한 epoch 이름
     *  @description  팝업 apply 버튼 클릭 시 실행되어 data 변경
     */
    const handleApply = useCallback((editCellData, editIpData, editEpochName) => {
        const findEpochIndex = dataInfo[SCHEMA_DEFINE_DATA.EPOCH].findIndex(epoch => epoch.ID === editCellData[CELL_DATA_FIELD.EPOCHID]); // epoch 이름 수정을 위해 해당 epoch index 찾기
        const findCellIndex = dataInfo[SCHEMA_DEFINE_DATA.CELL].findIndex(cell => cell.ID === editCellData[CELL_DATA_FIELD.ID]); // cell 수정을 위해 해당 cell index 찾기
        let epoch = null;

        if (findEpochIndex > -1) { // epoch index 찾으면
            epoch = dataInfo[SCHEMA_DEFINE_DATA.EPOCH][findEpochIndex];
        }

        // 현재 cell 에 포함된 ip 제외 list
        const filteringIpList = dataInfo[SCHEMA_DEFINE_DATA.IP].filter((editIpData) => editCellData.ID !== editIpData.cellID);
        const newIpList = [];
        newIpList.push(...filteringIpList);
        newIpList.push(...editIpData);

        setDataInfo(
            produce(
                dataInfo, draft => {
                    // ip 데이터 저장
                    draft[SCHEMA_DEFINE_DATA.IP] = newIpList;
                    // epoch 이름 저장
                    if (findEpochIndex !== -1) {
                        draft[SCHEMA_DEFINE_DATA.EPOCH][findEpochIndex].name = editEpochName;
                    }
                    // cell 데이터 저장 처리
                    if (findCellIndex !== -1) {
                        // 삭제 대상이 되는 cell index
                        let deleteCellIndex = [];
                        // 추가 cell object
                        let addCellObject = [];

                        let arm;
                        for (arm of dataInfo[SCHEMA_DEFINE_DATA.ARM]) {
                            const armID = arm.ID;
                            // current cell 에 존재
                            if (editCellData[CELL_DATA_FIELD.ARMIDS].includes(armID)) {
                                // 다른 cell 에도 있는지 확인
                                const otherCellIndex = dataInfo[SCHEMA_DEFINE_DATA.CELL].findIndex(cell => {
                                    return (cell.ID !== editCellData[CELL_DATA_FIELD.ID]
                                        && cell[SCHEMA_DEFINE_DATA.ARM_IDS].includes(armID) === true
                                        && cell[SCHEMA_DEFINE_DATA.EPOCH_ID] === epoch.ID
                                    )
                                });

                                // 존재 하는 경우(삭제 대상 cell)
                                if (otherCellIndex !== -1) {
                                    // 삭제 대상 cell 검사
                                    let delCellObject = copyObject(dataInfo[SCHEMA_DEFINE_DATA.CELL][otherCellIndex]);
                                    const delArmIndex = delCellObject[SCHEMA_DEFINE_DATA.ARM_IDS].indexOf(armID);

                                    // 해당 arm 에 해당 정보를 지움
                                    if (delArmIndex !== -1) {
                                        delCellObject[SCHEMA_DEFINE_DATA.ARM_IDS].splice(delArmIndex);
                                    }

                                    // arm id 하나도 없으면 그 때 삭제 대상
                                    if (delCellObject[SCHEMA_DEFINE_DATA.ARM_IDS].length === 0) {
                                        deleteCellIndex.push(otherCellIndex);
                                    } else {
                                        draft[SCHEMA_DEFINE_DATA.CELL][otherCellIndex] = delCellObject;
                                    }
                                }
                            }
                            // cell 에 없음
                            else {
                                // 다른 cell 에도 있는지 확인
                                const otherCellIndex = dataInfo[SCHEMA_DEFINE_DATA.CELL].findIndex(cell => {
                                    return (cell.ID !== editCellData[CELL_DATA_FIELD.ID]
                                        && cell[SCHEMA_DEFINE_DATA.ARM_IDS].includes(armID) === true
                                        && cell[SCHEMA_DEFINE_DATA.EPOCH_ID] === epoch.ID
                                    )
                                });

                                // 존재 하지 않는  경우(추가 대상 cell)
                                if (otherCellIndex === -1) {
                                    let cellData = copyObject(INIT_CELL_DATA);
                                    let currentEpochId = epoch.ID;

                                    cellData.ID = generateRandomId(); // 랜덤 아이디 생성
                                    // epoch id 추가
                                    cellData.epochID = currentEpochId;
                                    // arm id 추가
                                    cellData.armIDs.push(armID);
                                    addCellObject.push(cellData);
                                }
                            }
                        }

                        // cell 데이터 수정
                        draft[SCHEMA_DEFINE_DATA.CELL][findCellIndex] = copyObject(editCellData);

                        // 삭제 처리
                        // 기존 셀 삭제(sort)
                        deleteCellIndex.sort((a, b) => {
                            return b - a;
                        });

                        let cellIndex;
                        for (cellIndex of deleteCellIndex) {
                            draft[SCHEMA_DEFINE_DATA.CELL].splice(cellIndex, 1);
                        }

                        let addCell;
                        // cell 추가 처리
                        for (addCell of addCellObject) {
                            draft[SCHEMA_DEFINE_DATA.CELL].push(addCell);
                        }
                    }
                }
            )
        );
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleToList
     *  @description  리스트로 돌아가는 기능
     */
    const goToList = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    const handleOpen = useCallback(() => {
        setIsPreviewModal(true);
    }, []);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleReference
     *  @description  ref 버튼 클릭시 실행되는 함수
     */
    const handleReference = useCallback(() => {
        if (referencePopup !== undefined) {
            referencePopup();
        }
    }, [referencePopup]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        let returnBool = true;
        if (isDataExist(dataInfo)) {
            dataInfo.arm.forEach((data) => {
                if (data.studySchedule === "" || data.groupName === "") {
                    returnBool = false;
                    return returnBool;
                }
            });
        }
        return returnBool;
    }, [dataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     saveDataInfo
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  image 생성 시 callback 으로 실행되어 데이터 저장 api 호출
     */
    const saveDataInfo = useCallback((image) => {
        setIsSaveCheck(false);
        if (validateData() === false) {
            return;
        }

        // 데이터 업데이트 command
        const command = COMMAND.DATA_UPDATE;
        const formData = new FormData();
        const sendIpList = dataInfo[SCHEMA_DEFINE_DATA.IP].map((ipData) => {
            return {
                ID: ipData.ID,
                cellID: ipData.cellID,
                ipID: ipData.ipID,
                type: ipData.type,
                amount: ipData.amount
            }
        });
        // epoch name 재정의 (treatment no 처리)
        const newEpochData = dataInfo[SCHEMA_DEFINE_DATA.EPOCH].map((epochData, epochIndex) => {
            return {
                ...epochData,
                name: epochNameList[epochIndex]
            }
        });
        const sendDataInfo = {
            ...dataInfo,
            epoch: newEpochData
        }
        const sendObject = {
            ...sendDataInfo,
            ip: sendIpList
        };

        formData.append("data", JSON.stringify(sendObject));
        formData.append("file", image, "schema.png");

        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
            sendObject: formData,
        };

        netWorkAgent.current.request(command, params);

        setIsCapturingView(false);
    }, [ID, dataInfo, epochNameList, validateData]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     getImageBlob
     *  @param        {Function} callback - image 저장 후 실행시킬 callback 함수
     *  @description  save 버튼 클릭 시 실행되어 현재 캡쳐 영역 image 생성 후 callback 함수 실행
     */
    const getImageBlob = useCallback((callback) => {
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0);
            const blobBin = window.atob(image.split(',')[1]);    // base64 데이터 디코딩
            const array = [];

            let i;
            for (i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }

            const blobImage = new Blob([new Uint8Array(array)], {type: 'image/png'});    // Blob 생성

            callback(blobImage);
        });
    }, []);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleChangeData
     *  @description  origin data와 화면에 보이는 data를 전달하는 함수
     */
    const handleChangeData = useCallback(() => {
        onChangeData(dataInfo, originDataInfo);
    }, [dataInfo, onChangeData, originDataInfo]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        handleBlur();
        dataInfo.arm.forEach((arm) => {
            if (arm.groupName !== "" && arm.studySchedule !== "") {
                setIsSaveCheck(true);
            }
        });

        setIsCapturingView(true);
    }, [dataInfo, handleBlur]);

    /**
     *  @memberOf     SchemaConfig
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 검색어
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;

            switch (command) {
                // 데이터 상세 정보에 대한 응답 시
                case COMMAND.DATA_INFO :
                    if (data.hasOwnProperty('data')) {
                        if (!isDataExist(data.data.arm)) {
                            const addCellList = []; //빈 cell data list
                            let armData = copyObject(INIT_ARM_DATA); // arm init data
                            armData.groupName = 'Group A' //arm name 추가

                            armData.ID = generateRandomId(); // 랜덤 아이디 생성 - 서버
                            const copyEpoch = data.data.epoch;

                            //epoch 갯수 만큼 필요한 cell init data 생성
                            let i;
                            for (i = 0; i < data.data[SCHEMA_DEFINE_DATA.EPOCH].length; i++) {
                                let cellData = copyObject(INIT_CELL_DATA);
                                let currentEpochId = data.data[SCHEMA_DEFINE_DATA.EPOCH][i].ID; // 현재 epoch id
                                cellData.ID = generateRandomId(); // cell 랜덤 아이디 생성
                                cellData.epochID = currentEpochId; // cell 의 epoch id 추가
                                cellData.armIDs.push(armData.ID); // arm id 추가
                                addCellList.push(cellData);
                            }

                            setDataInfo(produce(dataInfo, draft => {
                                draft[SCHEMA_DEFINE_DATA.ARM].push(armData); // arm 데이터 변경
                                draft[SCHEMA_DEFINE_DATA.CELL].push(...addCellList); // cell 데이터 변경
                                draft[SCHEMA_DEFINE_DATA.EPOCH].push(...copyEpoch); //epoch 데이터 추가
                            }));
                        } else {
                            setDataInfo(data.data);
                            setOriginDataInfo(data.data);
                        }
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    if (data.hasOwnProperty('data')) {
                        showToast(getDataInfo);
                    }
                    break;

                // no default
            }
        }
    }, [dataInfo, showToast, getDataInfo]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 데이터 상세 정보 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    // arm 이름 editing 모드인 지 체크
    useEffect(() => {
        if (isCapturingView && isSaveCheck) {
            getImageBlob(saveDataInfo);
        }
    }, [saveDataInfo, getImageBlob, isCapturingView, isSaveCheck]);

    // origin data와 화면에 보이는 data를 전달하는 함수 호출
    useEffect(() => {
        handleChangeData();
    }, [handleChangeData]);

    //데이터 변경 후 Tab 이동 시 저장하라고 나오는 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isSaved === true) {
            onSetClose(false);
            handleSave();
            onSetSave(false);
        }

        if (isClosed === true && isSaved === false) {
            getDataInfo();
            onSetClose(false);
        }

        onDataChangeModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosed, isSaved, onDataChangeModal, onSetClose, onSetSave]);

    // Design Top Tab에서 데이터 변경 후 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isChangeTabSave === true) {
            onChangeTabClose(false);
            handleSave();
            onChangeTabSave(false);
        }

        if (isChangeTabClose === true && isChangeTabSave === false) {
            getDataInfo();
            onChangeTabClose(false);
        }
        onChangeDataCheckModal(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeTabClose, isChangeTabSave, onChangeDataCheckModal, onChangeTabClose, onChangeTabSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box-row">
                <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                        <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                            {dataInfo.hasOwnProperty('arm') && (
                                <div className="overflow-y-auto m-4 p-t-30 px-2 table-wrapper" ref={captureRef}>
                                    <table className="table table-bordered schema-table ">
                                        <thead className="border-0 table-style">

                                        <tr style={{"backgroundColor": '#fff'}}>
                                            <th className="border-0"/>
                                            <th className="border-0"/>
                                            {dataInfo.hasOwnProperty('epoch') && getMileStoneTr(dataInfo.epoch)}
                                        </tr>

                                        <tr>
                                            <th>Study Schedule<span className="ml-1 text-danger">*</span></th>
                                            <th>Arm Group<span className="ml-1 text-danger">*</span></th>
                                            {dataInfo.hasOwnProperty('epoch') && getTableEpochTr(epochNameList)}
                                        </tr>

                                        </thead>

                                        <tbody>
                                            {dataInfo.hasOwnProperty('arm') && createTableStructure()}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>

            {isOpen && (
                <SchemaEditPopup
                    ID={ID}
                    onClose={() => handleClose(1)}
                    epochName={currentEpochName}
                    onApply={handleApply}
                    cellData={editCellData}
                    ipList={currentIpListData}
                    epochID={currentEpochId}
                    armList={dataInfo[SCHEMA_DEFINE_DATA.ARM]}/>
            )}

            {/* Schema Preview Modal 컴포넌트 */}
            {isPreviewModal && (
                <SchemaPreviewModal
                    onClose={() => handleClose(2)}
                    dataInfo={dataInfo}/>
            )}

            {/* 하단 처리 버튼 */}
            <DesignBottom
                onList={goToList}
                onOpen={handleOpen}
                previewBtnName={"Schema Preview"}
                onRef={handleReference}
                onSave={handleSave}/>
        </>
    );
};

export default React.memo(SchemaConfig);
