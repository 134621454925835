import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import TextField from "../../../../components/TextField";
import produce from "immer";
import {
    AXIOS_GET,
    copyObject,
    GenerateID, getSessionState,
    isDataExist
} from "../../../../../common/commonFunction";
import RadioButton from "../../../../components/RadioButton";
import {isEmpty} from "codemirror/src/util/misc";
import SelectBox from "../../../../components/SelectBox";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import NetworkLayout from "../../../../../common/NetworkLayout";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFEditModal
 *  @constant     {Object} ACRF_MODAL_DEFINE
 *  @description  field 명 정의
 */
const ACRF_MODAL_DEFINE = {
    VALUE: 'value',
    DOMAIN: 'domain',
    BORDER_TYPE: 'borderType'
};

/**
 *  @memberOf     ACRFEditModal
 *  @constant     {Object} VALIDATION
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION = {
    value: true,
    domain: true,
    borderType: true
};

/**
 *  @memberOf     ACRFEditModal
 *  @constant     {Array} BORDER_TYPE
 *  @description  Annotation의 border type option 값
 */
const BORDER_TYPE = [
    {
        text: "Solid",
        value: "solid"
    },
    {
        text: "Dashed",
        value: "dashed"
    }
];

/**
 *  @memberOf     ACRFEditModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, mappingID} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/crf-json/domains/${mappingID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFEditModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  ACRF 추가 팝업 컴포넌트
 */
const ACRFEditModal = (props) => {
    /**
     *  @memberOf     ACRFEditModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Function} onClose - 닫기 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Function} onSave - 저장 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {String} type - ACRF 구분 짓기 위한 type
     *  @property     {Object} data - 선택 위치의 Data
     *  @property     {Array} domainList - Form에 해당하는 Domain 리스트
     *  @property     {Object} annotationInfo - Annotation 수정하기 위해 선택 된 값
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, onClose, onSave, type, data, domainList, annotationInfo = {}} = props;

    /**
     *  @memberOf     ACRFEditModal
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 목록에서 선택한 mapping의 ID
     *  @description  session 에서 받아오는 정보
     */
    const {ID} = getSessionState();

    /**
     *  @memberOf     ACRFEditModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     ACRFEditModal
     *  @var          {Object} allDomainList
     *  @description  Form Annotation의 경우 Domain에 뿌려줄 목록
     */
    const [allDomainList, setAllDomainList] = useState([]);

    /**
     *  @memberOf     ACRFEditModal
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(annotationInfo);

    /**
     *  @memberOf     ACRFEditModal
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFEditModal
     *  @function     getDomainList
     *  @description  Form Annotation의 Domain 목록 가지고 오는 api 호출
     */
    const getDomainList = useCallback(() => {
        const command = COMMAND.DATA_LIST; // 데이터 리스트 요청 command
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            mappingID: ID
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  input 필드 변경시 호출되는 함수
     */
    const handleChange = useCallback((name, value) => {
        setDataInfo(produce(dataInfo, draft => {
            draft[name] = value;
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (!validation[name]) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        for (let key of validateKeys) {
            validateData[key] = isDataExist(dataInfo[key]);
        }

        //FORM 타입일때는 Domain 입력 화면이 없어서 무조건 true
        if (type === "FORM") {
            validateData[ACRF_MODAL_DEFINE.DOMAIN] = true;
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, type, validation]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        const valid = validateData(); // 데이터 validation
        if (!valid) {
            return;
        }

        if (onSave !== undefined) {
            let _dataInfo = copyObject(dataInfo);

            //domain이 존재 하지 않다면 value의 앞 두자리 잘라서 domain으로 저장
            if (type === "FORM") {
                _dataInfo.domain = _dataInfo.value.substr(0, 2);
            }

            if (isEmpty(annotationInfo)) {
                _dataInfo.ID = GenerateID(); //새로 추가 될때 임시 ID 발급
                _dataInfo.type = type;
                _dataInfo.path = data.ID;
                _dataInfo.dataType = "add";

                onSave(_dataInfo, "add");
            } else {
                _dataInfo.type = type;
                _dataInfo.path = data.ID;
                _dataInfo.dataType = "update";

                onSave(_dataInfo, "update");
            }
        }
    }, [annotationInfo, data.ID, dataInfo, onSave, type, validateData]);

    /**
     *  @memberOf     ACRFEditModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    if (data.hasOwnProperty('data') && data.data !== null) {
                        let _allDomainList = [];

                        data.data.forEach(data => {
                            _allDomainList.push({
                                text: data,
                                value: data
                            });
                        });

                        setAllDomainList(_allDomainList);
                    }
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //Modal에서 add 할때 기본 border type 정의
    useEffect(() => {
        //form
        if (type === "FORM") { //type이 FOMR 일때
            getDomainList(); //Form Annotation일때 Domain 목록 가지고 오는 api 호출
        }

        //상위에서 annotationInfo가 없다면 추가로 구분
        if (isEmpty(annotationInfo)) { //annotation 추가 시
            let _dataInfo = {};

            if (domainList && domainList.length === 1) { // Domain 1개일 경우, annotation edit 모달에서 해당 값으로 디폴트값 설정
                _dataInfo = {domain: domainList[0].value};
            }

            if (type === "FORM") { //type이 FORM 일때
                _dataInfo = {..._dataInfo, borderType: "solid"}; //borderType을 solid로 지정
            } else if (type === "FORMCOMMENT" || type === "ITEMGROUP") { //type이 FORMCOMMENT나 ITEMGROUP 일때
                _dataInfo = {..._dataInfo, borderType: "dashed"}; //borderType을 dashed로 지정
            }
            
            setDataInfo(_dataInfo);
        }
    }, [annotationInfo, domainList, getDomainList, type]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen className="modal-md" style={{maxWidth: "900px"}}>
                <ModalHeader
                    className='header-title bg- main'
                    toggle={handleClose}>
                    Annotation Edit
                </ModalHeader>

                <ModalBody>
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>{type === "FORM" ? "Domain" : "Annotation"}</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                {type === "FORM" ?
                                    <SelectBox
                                        name={ACRF_MODAL_DEFINE.VALUE}
                                        onChange={handleChange}
                                        dataList={allDomainList}
                                        currentValue={dataInfo[ACRF_MODAL_DEFINE.VALUE]}
                                        onFocus={handleFocus}
                                        validation={validation[ACRF_MODAL_DEFINE.VALUE]}/> :
                                    <TextField
                                        name={ACRF_MODAL_DEFINE.VALUE}
                                        currentValue={dataInfo[ACRF_MODAL_DEFINE.VALUE]}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        validation={validation[ACRF_MODAL_DEFINE.VALUE]}/>}

                            </div>
                        </div>
                    </div>

                    {type !== "FORM" && (
                        <div className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span>Domain</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>

                            <div className="col-md-9">
                                <div className="w-75">
                                    <RadioButton
                                        name={ACRF_MODAL_DEFINE.DOMAIN}
                                        onChange={handleChange}
                                        currentValue={dataInfo[ACRF_MODAL_DEFINE.DOMAIN]}
                                        dataList={domainList}
                                        onFocus={handleFocus}
                                        validation={validation[ACRF_MODAL_DEFINE.DOMAIN]}/>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Border Type</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <RadioButton
                                    name={ACRF_MODAL_DEFINE.BORDER_TYPE}
                                    onChange={handleChange}
                                    currentValue={dataInfo[ACRF_MODAL_DEFINE.BORDER_TYPE]}
                                    dataList={BORDER_TYPE}
                                    onFocus={handleFocus}
                                    validation={validation[ACRF_MODAL_DEFINE.BORDER_TYPE]}
                                    disabled={type === "FORM" || type === "FORMCOMMENT" || type === "ITEMGROUP"}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter className='p-3'>
                    <button
                        className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className="btn btn-new btn-inner-shadow text-white px-3 px-md-5 mx-1"
                        onClick={handleSave}>
                        Ok
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ACRFEditModal;