import React, {useCallback, useState} from "react";
import AnnotationComponent from "../../../../components/AnnotationComponent";
import ACRFEditModal from "./ACRFEditModal";
import addIcon from "../../../../../assets/icons/add.svg";
import {SEND_ANNOTATION_INFO} from "./ACRFMain";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFCodeList
 *  @constant     {Object} MAXIMUM_ANNOTATION
 *  @description  Annotation 최대 갯수
 */
const MAXIMUM_ANNOTATION = 1;

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFCodeList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 CodeList 컴포넌트
 */
const ACRFCodeList = (props) => {
    /**
     *  @memberOf     ACRFCodeList
     *  @type         {Object} props
     *  @property     {Object} codeListData - CodeList 정보
     *  @property     {String} type - radio인지 checkbox인지 구분 값
     *  @property     {Boolean} isEdit - acrf의 edit 구분 값(false: view, true: edit)
     *  @property     {Array} domainList - Form에 해당하는 Domain 리스트
     *  @property     {Boolean} isVertical - Vertical 표현 구분 값
     *  @property     {Function} onChangeDataList - annotation 추가, 수정, 삭제시 실행될 상위 이벤트 함수
     *  @property     {String} formID - 포함 된 form의 ID
     *  @property     {String} itemGroupID - 포함 된 itemGroup의 ID
     *  @property     {String} itemID - 포함 된 item의 ID
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @property     {String} itemData - item 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        codeListData,
        type,
        isEdit,
        domainList,
        isVertical = false,
        onChangeDataList,
        formID,
        itemGroupID,
        itemID,
        formMode,
        itemData,
        compareColor
    } = props;

    /**
     *  @memberOf     ACRFCodeList
     *  @var          {Boolean} isModal
     *  @description  ACRF edit 팝업 오픈 여부
     */
    const [isModal, setIsModal] = useState(false);

    /**
     *  @memberOf     ACRFCodeList
     *  @var          {Array} currentAnnotation
     *  @description  선택 된 Annotation 정보
     */
    const [currentAnnotation, setCurrentAnnotation] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFCodeList
     *  @function     handleAdd
     *  @description  Annotation Add 함수
     */
    const handleAdd = useCallback(() => {
        if (codeListData.annotations.length < MAXIMUM_ANNOTATION) {
            setIsModal(true);
        }

        setCurrentAnnotation({}); //add 일때는 선택됐던 annotation 정보 초기화
    }, [codeListData.annotations]);

    /**
     *  @memberOf     ACRFCodeList
     *  @function     handleClick
     *  @param        {Object} annotation - 선택 된 annotation의 정보
     *  @description  Annotation 클릭 되면 호출 되는 함수
     */
    const handleClick = useCallback((annotation) => {
        if (isEdit) {
            setCurrentAnnotation(annotation);
            setIsModal(true);
        }
    }, [isEdit]);

    /**
     *  @memberOf     ACRFCodeList
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 상위로 annotation 정보 전달하는 함수
     */
    const changeDataList = useCallback((data) => {
        if (onChangeDataList !== undefined) {
            onChangeDataList(data);
        }
    }, [onChangeDataList]);

    /**
     *  @memberOf     ACRFCodeList
     *  @function     handleDelete
     *  @param        {Number} idx - 리스트에서 선택 된 index
     *  @description  Annotation Delete 하는 함수
     */
    const handleDelete = useCallback((idx) => {
        if (codeListData !== undefined && codeListData.annotations.length > 0) {
            let data = codeListData.annotations[idx]; //삭제 될 data
            data.path = `${formID}/${itemGroupID}/${itemID}/${codeListData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID, CodeList ID를 path key에 추가
            data.dataType = "delete"; //dataType에 delete 추가

            changeDataList(data); //상위로 삭제 된 annotation 정보 전달

            SEND_ANNOTATION_INFO.delete.push(data); //전역 변수로 지정 된 곳 delete에 데이터 추가
        }
    }, [changeDataList, codeListData, formID, itemGroupID, itemID]);

    /**
     *  @memberOf     ACRFCodeList
     *  @function     handleSave
     *  @param        {Object} data - Annotation 정보
     *  @param        {String} dataType - 추가, 수정, 삭제 구분
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback((data, dataType) => {
        data.path = `${formID}/${itemGroupID}/${itemID}/${codeListData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID, CodeList ID를 path key에 추가

        changeDataList(data); //상위로 추가, 수정 된 annotation 정보 전달

        SEND_ANNOTATION_INFO[dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가

        setIsModal(false);
        setCurrentAnnotation({}); //선택했던 annotation 정보 초기화
    }, [changeDataList, formID, itemGroupID, itemID, codeListData.ID]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            style={isVertical ?
                {marginRight: "10px", display: "flex", flexDirection: "row"} :
                {marginRight: "10px"}
            }>

            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                padding: "5px",
                wordBreak: 'break-all'
            }}>
                <input
                    type={type}
                    name="radio_default_inline"
                    style={type === "checkbox" ? (
                        {
                            marginRight: "5px",
                            alignSelf: "center",
                            height: "10px"
                        }
                    ) : (
                        {
                            marginRight: "5px",
                            alignSelf: "center",
                            height: "8px"
                        }
                    )}
                    readOnly/>

                <div
                    style={{
                        alignSelf: "center",
                        marginRight: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                        wordBreak: "break-all"
                    }}>
                    <span
                        style={{backgroundColor: codeListData.hasOwnProperty("isDiff") && codeListData.isDiff ? compareColor : ""}}>
                        {type === "checkbox" ? itemData.fixedUnit : (codeListData.label !== "" ? codeListData.label : codeListData.value)}
                    </span>
                </div>
            </div>

            {formMode !== FORM_COMPARE && (
                <div style={{
                    flex: codeListData.annotations.length > 0 ? 1 : 0,
                    display: "flex",
                    flexDirection: "row"
                }}>
                    {isEdit && (
                        <img
                            src={addIcon}
                            onClick={handleAdd}
                            style={{
                                marginLeft: "5px",
                                height: "1rem",
                                alignSelf: "center"
                            }}
                            alt=""/>
                    )}

                    {/*form의 annotation 영역*/}
                    {codeListData.annotations.length > 0 && (
                        <div style={{alignSelf: "center"}}>
                            <AnnotationComponent
                                annotationList={codeListData.annotations}
                                onClick={handleClick}
                                onDelete={handleDelete}
                                isEdit={isEdit}/>
                        </div>
                    )}
                </div>
            )}

            {isModal && (
                <ACRFEditModal
                    onClose={() => setIsModal(false)}
                    onSave={handleSave}
                    type={'CODELIST'}
                    data={codeListData}
                    domainList={domainList}
                    annotationInfo={currentAnnotation}/>
            )}
        </div>
    );
};

export default React.memo(ACRFCodeList);