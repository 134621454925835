import React, { useCallback, useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ERROR_CODE, SESSION_INFO } from "constant/CommonConstant";
import { AXIOS_POST, getSessionUserData, setSessionUserData } from "common/commonFunction";
import { LOGIN_URL } from "constant/ConstantURL";

const NO_TOKEN_VALUE = 'NO TOKEN VALUE';


const ReFreshToken = (props) => {

  const { onSuccess, onFail } = props;

  const [sessionData] = useState(getSessionUserData());

  // 성공시
  const handleSuccess = useCallback(() => {
    if(onSuccess !== undefined){
      onSuccess();
    }
  },[onSuccess]);

  // 실패시
  const handleFail = useCallback((code, errorData) => {
    if(onFail !== undefined){
      onFail(code, errorData);
    }
  }, [onFail]);

  // 토큰 재발급 처리
  const processToken = useCallback(async()=>{

    const url = LOGIN_URL;

    if(sessionData[SESSION_INFO.REFRESH_TOKEN] === null){
      handleFail();
      return;
    }

    const RefreshToken = sessionData[SESSION_INFO.REFRESH_TOKEN];
    const tokenData = {
      "Token" : RefreshToken
    };

    try {

      // 데이터 처리 응답
      const response = await AXIOS_POST(url, JSON.stringify(tokenData));

      const data = response.data ;

      // 응답코드가 음수인 경우 error
      if (data.hasOwnProperty("code") && data.code < 0) {
        const errorString = data.msg ;
        handleFail(data.code, errorString);
      }
      else{
        if(data.data.AccessToken){
          const accessToken = data.data.AccessToken;

          let newSessionData = {...sessionData};

          newSessionData[SESSION_INFO.ACCESS_TOKEN] = accessToken;

          setSessionUserData(newSessionData);
          // sessionStorage.setItem(SESSION_INFO.ACCESS_TOKEN , accessToken);
          handleSuccess();
        }
        else{
          handleFail(ERROR_CODE.NO_TOKEN, NO_TOKEN_VALUE);
        }
      }
    }
    catch (e) {
      handleFail(ERROR_CODE.TOKEN_EXPIRED, e.toString());
    }

  },[handleFail, handleSuccess, sessionData]);

  useEffect(()=>{
    processToken();
  },[processToken]);

  //
  return (
    <>
    </>
  );
};

export default React.memo(ReFreshToken);
