import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { HashRouter } from 'react-router-dom';
import {positions, Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic'

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-calendar/dist/Calendar.css';
import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import './scss/imtrial.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './scss/custom-helper.scss';
import './scss/particles.scss';

// ========================================
const options = {
    position: positions.TOP_RIGHT,
    containerStyle: {
        zIndex: 10000,
        // backgroundColor: "#ff0",
    },
};

ReactDOM.render(
    <HashRouter>
        <AlertProvider template={AlertTemplate} {...options}>
            <App/>
        </AlertProvider>
    </HashRouter>,
    document.getElementById('root')
);
