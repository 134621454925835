import React from 'react';
import { Link,withRouter} from 'react-router-dom';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';
import { PageSettings } from '../../routes/page-settings.js';
import Logo from '../../assets/imtrial-logo.svg';
import LogOutAlert from "../../imtrial/components/LogOutAlert";
import UserTimer from "../../imtrial/components/UserTimer";
// import DropdownNotification from "./dropdown/notification";
import {getSessionUserData} from "../../common/commonFunction";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
        this.state = {
            collapseMegaMenu: true,
            logOut: false   // 로그아웃 처리를 위한 state 값
        };
    }


    toggleMegaMenu() {
        this.setState({collapseMegaMenu: !this.state.collapseMegaMenu});
    }

    signOut = (e) => {

        e.preventDefault();

        this.setState(() => {
            return {logOut: true};
        });
    };

    // 로그아웃 메시지 show 후 처리
    handleLogoutComplete = () => {

        const {history} = this.props;
        history.push("/account/login");
    };



    render() {
        const sessionData = getSessionUserData();

        return (
            <PageSettings.Consumer>
                {({
                      toggleMobileSidebar,
                      toggleRightSidebar,
                      toggleMobileRightSidebar,
                      toggleMobileTopMenu,
                      pageHeaderLanguageBar,
                      pageHeaderMegaMenu,
                      pageTwoSidebar,
                      pageTopMenu,
                      pageSidebar,
                      sessionUserData
                  }) => (
                    <div id="header" className="header navbar-default">
                        <div className="navbar-header">
                            {pageTwoSidebar && (
                                <button type="button" className="navbar-toggle pull-left"
                                        onClick={toggleMobileRightSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            <Link to={sessionData.passwordExpire !== 1 && sessionData.agreementMust !== 0 &&  sessionData.trainingComplete !== 0 && "/study"} className="navbar-brand"><img src={Logo} width={108} height={30}
                                                                            alt={"imtrial"}/></Link>

                            {pageHeaderMegaMenu && (
                                <button type="button" className="navbar-toggle pt-0 pb-0 mr-0"
                                        onClick={this.toggleMegaMenu}>
                                        <span className="fa-stack fa-lg text-inverse">
                                            <i className="far fa-square fa-stack-2x"></i>
                                            <i className="fa fa-cog fa-stack-1x"></i>
                                        </span>
                                </button>
                            )}
                            {pageTopMenu && pageSidebar && (
                                <button type="button" className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                                        onClick={toggleMobileTopMenu}>
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                        <i className="fa fa-cog fa-stack-1x"></i>
                                    </span>
                                </button>
                            )}
                            {!pageSidebar && pageTopMenu && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            {pageSidebar && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                        </div>

                        {true && (
                            <DropdownMegaMenu collapse={this.state.collapseMegaMenu}/>
                        )}
                        <ul className="navbar-nav navbar-right">
                            {pageHeaderLanguageBar && (
                                <DropdownLanguage/>
                            )}
                            <div className="d-flex align-items-center justify-content-center">
                                <UserTimer history={this.props.history}></UserTimer>
                            </div>
                            {/*<DropdownNotification />*/}
                            <DropdownProfile sessionUserData={sessionUserData} onLogout={this.signOut}/>
                            {pageTwoSidebar && (
                                <li className="divider d-none d-md-block"></li>
                            )}

                            {pageTwoSidebar && (
                                <li className="d-none d-md-block">
                                    <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                                        <i className="fa fa-th"></i>
                                    </Link>
                                </li>
                            )}
                            {/*<li className="d-none d-md-block">*/}
                            {/*    <Link to="/support" className="f-s-14">*/}
                            {/*        Support*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </ul>
                        {this.state.logOut && <LogOutAlert onComplete={() => {
                            this.handleLogoutComplete()
                        }}/>}

                    </div>
                )}


            </PageSettings.Consumer>
        )
    }
} 
export default withRouter(Header);
