import React, {useState, useEffect} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import AUIGrid from "../../../../components/AUIGrid";
import {
    TA_COLUMN_LIST,
    TAExamDataList, TE_COLUMN_LIST,
    TEExamDataList, TM_COLUMN_LIST,
    TMExamDataList,
    TS_COLUMN_LIST,
    TSExamDataList, TV_COLUMN_LIST,
    TVExamDataList,
    TI_COLUMN_LIST, TIExamDataList
} from "./ExampleData";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    MappingExamplePopup
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  Trial Design의 도메인별 Example 화면 컴포넌트
 */
const MappingExamplePopup = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     MappingExamplePopup
     *  @type         {Object} props
     *  @property     {Object} match - url의 params를 가져오기 위해
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {match} = props;

    /**
     *  @memberOf     MappingExamplePopup
     *  @var          {Array} columnList
     *  @description  화면에 표시 될 그리드 컬럼 리스트
     */
    const [columnList, setColumnList] = useState();

    /**
     *  @memberOf     MappingExamplePopup
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    //주소에서 받은 도메인으로 값에 따른 화면 생성
    useEffect(() => {
        switch (match.params.domain) {
            case "TS":
                setColumnList(TS_COLUMN_LIST);
                setDataList(TSExamDataList);
                break;

            case "TA":
                setColumnList(TA_COLUMN_LIST);
                setDataList(TAExamDataList);
                break;

            case "TE":
                setColumnList(TE_COLUMN_LIST);
                setDataList(TEExamDataList);
                break;

            case "TV":
                setColumnList(TV_COLUMN_LIST);
                setDataList(TVExamDataList);
                break;

            case "TM":
                setColumnList(TM_COLUMN_LIST);
                setDataList(TMExamDataList);
                break;

            case "TI":
                setColumnList(TI_COLUMN_LIST);
                setDataList(TIExamDataList);

            //no default
        }
    }, [match.params.domain]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className="vertical-box p-l-20 p-r-20 p-t-20">
                <div className="vertical-box-column">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bolder m-b-0">{match.params.domain} Domain Example</h4>
                    </div>
                </div>
            </div>

            <div className="vertical-box with-grid inbox bg-light m-t-30" style={{height: "calc(100vh - 85px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div className="vertical-box-cell">
                            <div className="vertical-box-inner-cell">
                                <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                                    <AUIGrid
                                        columnList={columnList}
                                        dataList={dataList}/>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(MappingExamplePopup);
