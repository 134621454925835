import React, {useCallback, useState, useEffect} from 'react';
import {getSessionState} from "common/commonFunction";
import {VIEW_USE_MODE} from "constant/CommonConstant";
import PagingTap from "imtrial/components/PagingTap";
import DataSets from "./DataSets/DataSets";
import MappingDetail from "./Variables/Variables";
import Relrec from "./RELREC/RelrecList";
import CodeLists from "./CodeLists/CodeLists";
import Methods from "./Methods/Methods";
import NewDefine from "./NewDefine/NewDefine";
import ACRFMain from "./ACRF/ACRFMain";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SdtmMappingDetail
 *  @constant     {Array} TAB_OPTION
 *  @description  탭 옵션 리스트
 */
export const TAB_OPTION = [
    {
        text: 'DataSets',
        value: 'DataSets'
    },
    {
        text: 'Variables',
        value: 'Variables'
    },
    {
        text: 'CodeLists',
        value: 'CodeLists'
    },
    {
        text: 'Methods',
        value: 'Methods'
    },
    {
        text: 'aCRF',
        value: 'aCRF'
    },
    {
        text: 'Define.xml',
        value: 'Define.xml'
    }
];

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    SdtmMappingDetail
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping list 의 상세 화면 컴포넌트
 */
const SdtmMappingDetail = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdtmMappingDetail
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     SdtmMappingDetail
     *  @type         {Object} getSessionState
     *  @property     {String} name - 화면 왼쪽 상단에 표시할 리스트 name
     *  @property     {String} mode - 화면 생성 및 수정 구분 값 (NEW | EDIT)
     *  @property     {String} type - 값이 있으면 type의 값으로 탭 선택 되어 지도록 하는 값
     *  @property     {String} domain - DataSets 탭에서 선택한 Domain 값
     *  @property     {String} version - 화면 왼쪽 상단에 표시할 버전 정보
     *  @description  session 에서 받아오는 정보
     */
    const {mode, name, type, domain, relrecState, version} = getSessionState();

    /**
     *  @memberOf     SdtmMappingDetail
     *  @var          {String} currentTab
     *  @description  현재 활성화 된 tab 정보
     */
    const [currentTab, setCurrentTab] = useState(type !== undefined ? type : TAB_OPTION[0].text);

    /**
     *  @memberOf     SdtmMappingDetail
     *  @var          {String} relrec
     *  @description  현재 RELREC의 활성화 정보
     */
    const [relrec, setRelrec] = useState(relrecState);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdtmMappingDetail
     *  @function     getNameVersion
     *  @description  상세화면의 왼쪽 상단 제목에 포함되는 버전 정보 출력 함수
     */
    const getNameVersion = useCallback(() => {
        let fullVersion = version && version.toString().split('');

        if (version && fullVersion) { // New RELREC 하고 뒤로가기로 돌아왔을 때 오류 안 나도록
            if (!fullVersion.some(item => item === '.')) { // version에 '.'이 포함되지 않은 경우: 뒤에 '.0'을 붙여준다 (ex. 1)
                return `(v${fullVersion}.0)`;
            } else { // version에 '.'이 포함된 경우: version 그대로 리턴 (ex. 1.1)
                return `(v${version})`;
            }
        }
    }, [version]);

    /**
     *  @memberOf     SdtmMappingDetail
     *  @function     getName
     *  @return       {String} title - 화면 상단에 표시할 현재 상세 정보 타이틀
     *  @description  상세화면의 왼쪽 상단에 표시 될 값 관련 함수
     */
    const getName = useCallback(() => {
        let title = "Mapping";

        if (mode === VIEW_USE_MODE.EDIT) {
            title = `${name} ${getNameVersion()}`;
        }

        return title;
    }, [getNameVersion, mode, name]);

    /**
     *  @memberOf     SdtmMappingDetail
     *  @function     handleChangeTab
     *  @param        {String} name - Tab name
     *  @param        {String} value - 선택된 Tab 값
     *  @description  Tab 변경시 호출되는 함수
     */
    const handleChangeTab = useCallback((name, value) => {
        setCurrentTab(value);
        setRelrec(false);
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 현재 선택 된 탭 상태 변경
    useEffect(() => {
        if (type !== undefined && domain !== undefined) {
            setCurrentTab(type);
        }

        return () => {
            setCurrentTab(null);
        }
    }, [domain, type]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/

    return (
        <>
            <div className="vertical-box height-50 p-l-20 p-r-20">
                <div className="vertical-box-column">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bolder m-b-0">{getName()}</h4>

                        {/*{mode === VIEW_USE_MODE.EDIT && (*/}
                        {/*    <PagingTap*/}
                        {/*        dataList={TAB_OPTION}*/}
                        {/*        currentValue={currentTab}*/}
                        {/*        onChange={handleChangeTab}/>*/}
                        {/*)}*/}
                        <PagingTap
                            dataList={TAB_OPTION}
                            currentValue={currentTab}
                            onChange={handleChangeTab}/>
                    </div>
                </div>
            </div>

            {currentTab === TAB_OPTION[0].value && (
                !relrec ?
                <DataSets history={history} relrec={relrec} setRelrec={setRelrec}/> : <Relrec history={history} setRelrec={setRelrec}/>
            )}

            {currentTab === TAB_OPTION[1].value && (
                <MappingDetail history={history}/>
            )}

            {currentTab === TAB_OPTION[2].value && (
                <CodeLists history={history}/>
            )}

            {currentTab === TAB_OPTION[3].value && (
                <Methods history={history}/>
            )}

            {currentTab === TAB_OPTION[4].value && (
                <ACRFMain history={history}/>
                )}

            {currentTab === TAB_OPTION[5].value && (
                <NewDefine history={history}/>
            )}
        </>
    );
};

export default React.memo(SdtmMappingDetail);