import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { USER_INFO_DEFINE } from "../../../constant/study/ConstantName";
import {getSessionUserData} from "../../../common/commonFunction";

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	// log out 기능
	handleLogout = (e) => {
		if(this.props.onLogout !== undefined){
			console.log(e);
			this.props.onLogout(e);
		}
	};


	render() {
		const sessionData = getSessionUserData();
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle tag="a">
					<div className="image image-icon bg-gradient-gray-500">
						<i className="fa fa-user"></i>
					</div>
					<span className="d-none d-md-inline">{this.props.sessionUserData[USER_INFO_DEFINE.NAME]}</span> <b className="caret"></b>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
					<DropdownItem>
						<Link to={sessionData.passwordExpire !== 1 && sessionData.agreementMust !== 0 && "/user/my-info"}>My Info</Link>
					</DropdownItem>
					<DropdownItem>
						<Link to={sessionData.passwordExpire !== 1 && sessionData.agreementMust !== 0 && "/user/my-password"}>My Password</Link>
					</DropdownItem>
					<DropdownItem>
						<Link to={sessionData.passwordExpire !== 1 && sessionData.agreementMust !== 0 && "/user/training"}>Training</Link>
					</DropdownItem>
					<DropdownItem>
						<Link to={sessionData.passwordExpire !== 1 && sessionData.agreementMust !== 0 && "/support/notice"}>Support</Link>
					</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={this.handleLogout}>Log Out</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default DropdownProfile;
