import React, {useCallback, useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import RadioButton from "../../../../components/RadioButton";
import DataSetsModalContent from "./DataSetsModalContent";
import {copyObject} from "../../../../../common/commonFunction";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       DataSetsModal
 *  @constant       {Object} DATASETS_OPTION
 *  @description    Datasets edit modal의 tab 에 표시할 리스트
 */
const DATASETS_OPTION = [
    {
        text: 'Domain',
        value: 'Domain'
    },
    {
        text: 'SUPP',
        value: 'SUPP'
    }
];

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    DataSetsModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 DataSets Tab의 Key 컬럼의 Cell 클릭시 나오는 Modal 컴포넌트
 */
const DataSetsModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DataSetsModal
     *  @type         {Object} props
     *  @property     {String} mappingID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {Object} rowEvent - List에서 선택 된 row의 정보
     *  @property     {Function} onSave - 현재 Modal 저장 위한 함수
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {mappingID, lock, rowEvent, onSave, onClose} = props;

    /**
     *  @memberOf     DataSetsModal
     *  @var          {String} tabMenu
     *  @description  현재 활성화 된 tab menu 값
     */
    const [tabMenu, setTabMenu] = useState(DATASETS_OPTION[0].value);

    /**
     *  @memberOf     DataSetsModal
     *  @var          {Object} dataInfo
     *  @description  부모에서 받아온 row event 정보
     */
    const [dataInfo, setDataInfo] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     DataSetsModal
     *  @function     handleChange
     *  @param        {String} name - change 되는 element의 name
     *  @param        {String} value - 변경 되는 값
     *  @description  tab change 시 호출 되는 함수
     */
    const handleChange = useCallback((name, value) => {
        setTabMenu(value);
    }, []);

    /**
     *  @memberOf     DataSetsModal
     *  @function     handleSave
     *  @param        {Object} obj - 저장 할 obj
     *  @description  저장 버튼 클릭 시 처리 되는 함수. DataSets탭의 getKeyData 함수로 선택한 key 값 전달. key 가 선택 안됐을 경우 Warning Modal 창 오픈.
     */
    const handleSave = useCallback((obj) => {
        let _dataInfo = copyObject(dataInfo);
        _dataInfo.item.no = _dataInfo.rowIndex + 1; //no가

        if (obj.type === "DOMAIN") {
            _dataInfo.item.domainInfo = obj; //Domain 탭에서 저장 누르면 domainInfo에 받아온 obj 저장
        } else {
            _dataInfo.item.suppInfo = obj; //SUPP 탭에서 저장 누르면 suppInfo에 받아온 obj 저장
        }

        //불필요 key 제거
        delete _dataInfo.item._$uid; //grid에서 자동 생성 되는 _$uid key 제거
        delete _dataInfo.item.undefined; //List앞에 ... dataField가 없어서 undefine 생성 된 key 제거

        if (onSave !== undefined) {
            onSave(_dataInfo);
        }
    }, [dataInfo, onSave]);

    /**
     *  @memberOf     DataSetsModal
     *  @function     handleClose
     *  @param        {Number} num - Modal Close 하기 위한 구분 값
     *  @description  Key 선택 창 닫기(1: Select Key 창, 2: Warning 창)
     */
    const handleClose = useCallback((num) => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 key 리스트 요청
    useEffect(() => {
        let _dataInfo = copyObject(rowEvent);

        //domainInfo가 비어있는 경우 default로 넣어주기
        if (_dataInfo.item.domainInfo === "") {
            _dataInfo.item.domainInfo = {
                ID: "",
                mappingID: mappingID,
                mappingDomainID: _dataInfo.item.ID,
                type: "DOMAIN",
                repeating: "",
                standard: "",
                nonStandard: 0,
                name: _dataInfo.item.domain,
                structure: "",
                referenceData: "",
                comment: "",
                description: "",
                keyVariable: "",
                SASDatasetName: _dataInfo.item.domain,
                developerNote: ""
            };
        } else if (!_dataInfo.item.domainInfo.hasOwnProperty("SASDatasetName") &&
            _dataInfo.item.domainInfo.hasOwnProperty("repeating") &&
            _dataInfo.item.domainInfo.hasOwnProperty("structure") &&
            _dataInfo.item.domainInfo.hasOwnProperty("description") &&
            _dataInfo.item.domainInfo.hasOwnProperty("standard") &&
            _dataInfo.item.domainInfo.hasOwnProperty("keys")) { //repeating, structure, description, standard, keys만 들어있는 경우
            _dataInfo.item.domainInfo = {
                ID: "",
                mappingID: mappingID,
                mappingDomainID: _dataInfo.item.ID,
                type: "DOMAIN",
                repeating: _dataInfo.item.domainInfo.repeating,
                standard: _dataInfo.item.domainInfo.standard,
                nonStandard: 0,
                name: _dataInfo.item.domain,
                structure: _dataInfo.item.domainInfo.structure,
                referenceData: "",
                comment: "",
                description: _dataInfo.item.domainInfo.description,
                keyVariable: _dataInfo.item.domainInfo.keys,
                SASDatasetName: _dataInfo.item.domain,
                developerNote: ""
            };
        }

        //suppInfo가 비어있는 경우 default로 넣어주기
        if (_dataInfo.item.suppInfo === "") {
            _dataInfo.item.suppInfo = {
                ID: "",
                mappingID: mappingID,
                mappingDomainID: _dataInfo.item.ID,
                type: "SUPP",
                repeating: "",
                standard: "",
                nonStandard: 0,
                name: _dataInfo.item.SUPP,
                structure: "",
                referenceData: "",
                comment: "",
                description: "",
                keyVariable: "",
                SASDatasetName: _dataInfo.item.SUPP,
                developerNote: ""
            };
        } else if (!_dataInfo.item.suppInfo.hasOwnProperty("SASDatasetName") &&
            _dataInfo.item.suppInfo.hasOwnProperty("repeating") &&
            _dataInfo.item.suppInfo.hasOwnProperty("structure") &&
            _dataInfo.item.suppInfo.hasOwnProperty("description") &&
            _dataInfo.item.suppInfo.hasOwnProperty("standard") &&
            _dataInfo.item.suppInfo.hasOwnProperty("keys")) { //repeating, structure, description, standard, keys만 들어있는 경우
            _dataInfo.item.suppInfo = {
                ID: "",
                mappingID: mappingID,
                mappingDomainID: _dataInfo.item.ID,
                type: "SUPP",
                repeating: _dataInfo.item.suppInfo.repeating,
                standard: _dataInfo.item.suppInfo.standard,
                nonStandard: 0,
                name: _dataInfo.item.SUPP,
                structure: _dataInfo.item.suppInfo.structure,
                referenceData: "",
                comment: "",
                description: _dataInfo.item.suppInfo.description,
                keyVariable: _dataInfo.item.suppInfo.keys,
                SASDatasetName: _dataInfo.item.SUPP,
                developerNote: ""
            };
        }

        setDataInfo(_dataInfo);
    }, [mappingID, rowEvent, tabMenu]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <Modal isOpen={true} className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    {rowEvent.item.domain}
                </ModalHeader>

                <ModalBody>
                    <div className="d-flex align-items-center justify-content-end mb-4">
                        <RadioButton
                            name="datasets_tab"
                            dataList={DATASETS_OPTION}
                            onChange={handleChange}
                            currentValue={tabMenu}/>
                    </div>

                    {tabMenu === DATASETS_OPTION[0].value && dataInfo !== undefined && (
                        <DataSetsModalContent
                            lock={lock}
                            domainName={dataInfo.item.domain}
                            className={dataInfo.item.class}
                            dataObj={dataInfo.item.domainInfo}
                            onSave={handleSave}
                            onClose={handleClose}/>
                    )}

                    {tabMenu === DATASETS_OPTION[1].value && dataInfo !== undefined && (
                        <DataSetsModalContent
                            lock={lock}
                            domainName={dataInfo.item.domain}
                            className={dataInfo.item.class}
                            dataObj={dataInfo.item.suppInfo}
                            isSUPP={dataInfo.item.isSUPP === 0}
                            onSave={handleSave}
                            onClose={handleClose}/>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(DataSetsModal);
