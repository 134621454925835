import React, {useEffect, useState} from 'react';
import {copyObject} from "../../../../common/commonFunction";
import ACRFForm from "../../sdtm/Mapping/ACRF/ACRFForm";
import {isEmpty} from "codemirror/src/util/misc";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     CompareCrf
 *  @constant     {String} FORM_COMPARE
 *  @description  CRF 화면 타입 ('CRF' / 'compare')
 */
export const FORM_COMPARE = "compare";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    CompareCrf
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  Design Compare 팝업 내 CRF 화면 컴포넌트
 */
const CompareCrf = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      CompareCrf
     *  @type          {Object} props
     *  @property      {Object} compareData - 비교를 위한 버전 별 데이터 리스트
     *  @property     {Function} onChange - Reason for Change 값 전달 받는 상위 함수
     *  @property      {Object} leftArmList - 왼쪽 studySchedule 표시를 위한 armList
     *  @property      {Object} rightArmList - 오른쪽 studySchedule 표시를 위한 armList
     *  @property      {Array} versionList - 버전 목록
     *  @property      {string} leftEdcType - 왼쪽 데이터 edcType
     *  @property      {string} rightEdcType - 오른쪽 데이터 edcType
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        compareData,
        reasonForChangeList,
        setReasonForChangeList,
        leftArmList,
        rightArmList,
        versionList,
        leftEdcType,
        rightEdcType
    } = props;
    /**
     *  @memberOf     CompareCrf
     *  @var          {Array} leftDataList
     *  @description  왼쪽 화면의 crf를 그리기 위해 가공한 data 리스트
     */
    const [leftDataList, setLeftDataList] = useState([]);

    /**
     *  @memberOf     CompareCrf
     *  @var          {Array} rightDataList
     *  @description  오른쪽 화면의 crf를 그리기 위해 가공한 data 리스트
     */
    const [rightDataList, setRightDataList] = useState([]);

    /**
     * @memberOf     CompareCrf
     * @param        {String } str
     * @description  labformTable에서 null값을 하이라이트 처리해야 되는데, null값에 id값이 있어야 해서, 같은 열의 test item ID값을 기반으로 해시값 생성
     * @returns      {number}
     */
    function hashCode(str) {
        let hash = 0;
        for (let i = 0, len = str.length; i < len; i++) {
            let chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash + "";
    }

    /**
     *  @memberOf     CompareCrf
     *  @function     convertVisitListWithHighlightData
     *  @param        {Array} targetVisitList - visitList 비교하는데 기준이 되는 목록
     *  @param        {Array} compareVisitList - visitList targetVisitList와 비교할 목록
     *  @description  두개의 visitList를 받아서 차집합에 하이라이트 데이터를 넣음
     */
    const convertVisitListWithHighlightData = (targetVisitList, compareVisitList) => {

        if (targetVisitList === undefined) {
            targetVisitList = [targetVisitList]
        }
        if (compareVisitList === undefined) {
            compareVisitList = [compareVisitList]
        }

        const result = [];

        // 두 데이터 모두 디자인명이 존재할 경우
        if (targetVisitList[0]?.includes(':') && compareVisitList[0]?.includes(':')) {
            targetVisitList.forEach((targetVisit) => {
                let _highlightAll = true;
                let _values = [];

                const targetVisit_key = targetVisit.split(" : ")[0];
                const targetVisit_values = targetVisit.split(" : ")[1].split(", ");

                targetVisit_values.forEach((value) => {
                    _values.push({
                        value: value,
                        highlight: true
                    });
                });

                compareVisitList.forEach((compareVisit) => {
                    const compareVisit_key = compareVisit.split(" : ")[0];
                    const compareVisit_values = compareVisit.split(" : ")[1].split(", ");

                    if (targetVisit_key === compareVisit_key) {
                        _highlightAll = false;
                        targetVisit_values.forEach((targetValue, idx) => {
                            compareVisit_values.forEach((compareValue) => {
                                if (targetValue === compareValue) {
                                    _values[idx].highlight = false;
                                }
                            })
                        })
                    }
                });

                result.push({
                    key: targetVisit_key,
                    highlightAll: _highlightAll,
                    values: _values
                });
            });
        } else if ( // 두 데이터 모두 원소명만 존재할 경우 (이 경우는 양쪽 배열의 길이가 1인 경우 밖에 없음)
            targetVisitList.length !== 0 &&
            targetVisitList[0] !== undefined &&
            compareVisitList.length !== 0 &&
            compareVisitList[0] !== undefined
        ) {
            let _values = [];

            const targetVisit_values = targetVisitList[0].split(", ");
            const compareVisit_values = compareVisitList[0].split(", ");

            targetVisit_values.forEach((value) => {
                _values.push({
                    value: value,
                    highlight: true
                });
            });

            targetVisit_values.forEach((targetValue, idx) => {
                compareVisit_values.forEach((compareValue) => {
                    if (targetValue === compareValue) {
                        _values[idx].highlight = false;
                    }
                })
            })

            result.push({
                key: "",
                highlightAll: false,
                values: _values
            });
        } else { // 그 외는 모두 하이라이트 처리
            let _values = [];

            targetVisitList.forEach((targetVisit) => {
                _values.push(targetVisit)
            })

            result.push({
                key: "",
                highlightAll: true,
                values: _values
            });
        }
        return result;

    }

    /**
     *  @memberOf     CompareCrf
     *  @function     convertLabFormWithHighlightData
     *  @param        {Array} tItemgroups - 비교하는데 기준이 되는 목록
     *  @param        {Array} findItemgroups - 비교할 목록
     *  @description  하이라이트 정보를 담고 있는 데이터를 리턴함
     */
    const convertLabFormWithHighlightData = (tItemgroups, findItemgroups) => {
        const _leftLabItemgroupIDs = [];
        const _rightLabItemgroupIDs = [];

        tItemgroups.forEach((itemgroup) => {
            if (itemgroup.laboratory === 1) {
                _leftLabItemgroupIDs.push(itemgroup.ID);
            }
        })

        findItemgroups.forEach((itemgroup) => {
            if (itemgroup.laboratory === 1) {
                _rightLabItemgroupIDs.push(itemgroup.ID);
            }
        })

        const _leftLabTableData = {
            header: [],
            body: []
        }

        const _rightLabTableData = {
            header: [],
            body: []
        }

        // 왼쪽 데이터 가공
        // labform 헤더 추가
        tItemgroups.forEach((itemgroup) => {
            if (_leftLabItemgroupIDs.includes(itemgroup.ID)) {
                itemgroup.items.forEach((item) => {
                    if (leftEdcType === 'boim' && item.element === "LBTEST") {
                        _leftLabTableData.header.push("Test item")
                    } else if (leftEdcType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        _leftLabTableData.header.push("Result")
                    } else if (leftEdcType !== 'boim' && item.element === "LBORRES") {
                        _leftLabTableData.header.push("Test item")
                        _leftLabTableData.header.push("Result")
                    } else if (item.element === 'LBNRIND') {
                        _leftLabTableData.header.push("Normality")
                    } else if (item.element === 'LBCLSIG') {
                        _leftLabTableData.header.push("Clinical Significance")
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        _leftLabTableData.header.push("Comment")
                    }
                })
            }
        })

        _leftLabTableData.header = [...new Set(_leftLabTableData.header)];

        let leftDDLComment = [];

        // labform body 추가
        tItemgroups.forEach((itemgroup) => {
            if (_leftLabItemgroupIDs.includes(itemgroup.ID)) {
                const temp = [];
                itemgroup.items.forEach((item) => {
                    let _content = '';
                    if (item.dataType === "Datetime" && item.format !== "") {
                        _content = item.format;
                    } else if (item.dataType === "Float") {
                        let num = '_ '.repeat(Math.floor(item.format) - item.format.slice(item.format.indexOf('.') + 1));
                        let primeNum = '_ '.repeat(item.format.slice(item.format.indexOf('.') + 1));
                        _content = num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
                    } else if (item.dataType === "Integer") {
                        _content = "_ ".repeat(item.format);
                    } else if (item.dataType === "Text") {
                        _content = "__________";
                    }

                    if (leftEdcType === 'boim' && item.element === "LBTEST") {
                        temp[_leftLabTableData.header.indexOf("Test item")] = {
                            value: item.defaultValue,
                            id: item.ID,
                            headerName: "Test item"
                        };
                    } else if (leftEdcType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        temp[_leftLabTableData.header.indexOf("Result")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Result"
                        }
                    } else if (leftEdcType !== 'boim' && item.element === "LBORRES") {
                        temp[_leftLabTableData.header.indexOf("Test item")] = {
                            value: item.question,
                            id: item.ID,
                            headerName: "Test item"
                        }
                        temp[_leftLabTableData.header.indexOf("Result")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Result"
                        }
                    } else if (item.element === 'LBNRIND') {
                        temp[_leftLabTableData.header.indexOf("Normality")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Normality"
                        }
                    } else if (item.element === 'LBCLSIG') {
                        temp[_leftLabTableData.header.indexOf("Clinical Significance")] = {
                            value: "DDL",
                            id: item.ID,
                            headerName: "Clinical Significance"
                        };
                        item.codelists.forEach((codelist) => {
                            leftDDLComment.push(codelist.label);
                        })
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        temp[_leftLabTableData.header.indexOf("Comment")] = {
                            value: "__________",
                            id: item.ID,
                            headerName: "Comment"
                        }
                    }
                })
                _leftLabTableData.body.push(temp);
            }
        })

        // 표 잘림 현상 해결 위해서 짧은 배열에 undefined 값 넣어주기
        let _maxRowLength = 0;
        _leftLabTableData.body.forEach((row) => {
            if (_maxRowLength < row.length) {
                _maxRowLength = row.length;
            }
        })
        _leftLabTableData.body.forEach((row, index) => {
            let _newRow = copyObject(row);
            if (_newRow.find(e => e.headerName === "Test item")) {
                if (_newRow.length < _maxRowLength) {
                    for (let i = 0; i < _maxRowLength; i++) {
                        if (_newRow[i] === undefined) {
                            _newRow[i] = undefined;
                        }
                    }
                }
            }
            _leftLabTableData.body[index] = _newRow
        })

        // 빈값이 들어가면 table이 깨지기 때문에 다른값 대입해주기
        // 이때 변경대비를 위해 NULL값에 "test item" id의 해시값을 이용해서 id 부여
        _leftLabTableData.body.forEach((row, index) => {
            let _nullID = '';
            row.forEach((each) => {
                if (each?.headerName === 'Test item') {
                    _nullID = hashCode(each.id.slice(4));
                }
            })

            _leftLabTableData.body[index] = Array.from(row, (item, itemIndex) => item === undefined ? {
                value: null,
                id: _nullID + hashCode(_leftLabTableData.header[itemIndex]),
                headerName: _leftLabTableData.header[itemIndex]
            } : item);
        })

        // 오른쪽 데이터 가공
        // labform 헤더 추가
        findItemgroups.forEach((itemgroup) => {
            if (_rightLabItemgroupIDs.includes(itemgroup.ID)) {
                itemgroup.items.forEach((item) => {
                    if (rightEdcType === 'boim' && item.element === "LBTEST") {
                        _rightLabTableData.header.push("Test item")
                    } else if (rightEdcType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        _rightLabTableData.header.push("Result")
                    } else if (rightEdcType !== 'boim' && item.element === "LBORRES") {
                        _rightLabTableData.header.push("Test item")
                        _rightLabTableData.header.push("Result")
                    } else if (item.element === 'LBNRIND') {
                        _rightLabTableData.header.push("Normality")
                    } else if (item.element === 'LBCLSIG') {
                        _rightLabTableData.header.push("Clinical Significance")
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        _rightLabTableData.header.push("Comment")
                    }

                })
            }
        })

        _rightLabTableData.header = [...new Set(_rightLabTableData.header)];

        let rightDDLComment = [];

        // labform body 추가
        findItemgroups.forEach((itemgroup) => {
            if (_rightLabItemgroupIDs.includes(itemgroup.ID)) {
                const temp = [];
                itemgroup.items.forEach((item) => {
                    let _content = '';
                    if (item.dataType === "Datetime" && item.format !== "") {
                        _content = item.format;
                    } else if (item.dataType === "Float") {
                        let num = '_ '.repeat(Math.floor(item.format) - item.format.slice(item.format.indexOf('.') + 1));
                        let primeNum = '_ '.repeat(item.format.slice(item.format.indexOf('.') + 1));
                        _content = num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
                    } else if (item.dataType === "Integer") {
                        _content = "_ ".repeat(item.format);
                    } else if (item.dataType === "Text") {
                        _content = "__________";
                    }

                    if (rightEdcType === 'boim' && item.element === "LBTEST") {
                        temp[_rightLabTableData.header.indexOf("Test item")] = {
                            value: item.defaultValue,
                            id: item.ID,
                            headerName: "Test item"
                        }
                    } else if (rightEdcType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        temp[_rightLabTableData.header.indexOf("Result")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Result"
                        }
                    } else if (rightEdcType !== 'boim' && item.element === "LBORRES") {
                        temp[_rightLabTableData.header.indexOf("Test item")] = {
                            value: item.question,
                            id: item.ID,
                            headerName: "Test item"
                        }
                        temp[_rightLabTableData.header.indexOf("Result")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Result"
                        }
                    } else if (item.element === 'LBNRIND') {
                        temp[_rightLabTableData.header.indexOf("Normality")] = {
                            value: _content,
                            id: item.ID,
                            headerName: "Normality"
                        }
                    } else if (item.element === 'LBCLSIG') {
                        temp[_rightLabTableData.header.indexOf("Clinical Significance")] = {
                            value: "DDL",
                            id: item.ID,
                            headerName: "Clinical Significance"
                        }
                        item.codelists.forEach((codelist) => {
                            rightDDLComment.push(codelist.label);
                        })
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        temp[_rightLabTableData.header.indexOf("Comment")] = {
                            value: "__________",
                            id: item.ID,
                            headerName: "Comment"
                        }
                    }
                })
                _rightLabTableData.body.push(temp);
            }
        })

        // 표 잘림 현상 해결 위해서 짧은 배열에 undefined 값 넣어주기
        _maxRowLength = 0;
        _rightLabTableData.body.forEach((row) => {
            if (_maxRowLength < row.length) {
                _maxRowLength = row.length;
            }
        })
        _rightLabTableData.body.forEach((row, index) => {
            let _newRow = copyObject(row);
            if (_newRow.find(e => e.headerName === "Test item")) {
                if (_newRow.length < _maxRowLength) {
                    for (let i = 0; i < _maxRowLength; i++) {
                        if (_newRow[i] === undefined) {
                            _newRow[i] = undefined;
                        }
                    }
                }
            }
            _rightLabTableData.body[index] = _newRow
        })

        // 빈값이 들어가면 table이 깨지기 때문에 다른값 대입해주기
        // 이때 변경대비를 위해 NULL값에 "test item" id의 해시값을 이용해서 id 부여
        _rightLabTableData.body.forEach((row, index) => {
            let _nullID = '';
            row.forEach((each) => {
                if (each?.headerName === 'Test item') {
                    _nullID = hashCode(each.id.slice(4));
                }
            })

            _rightLabTableData.body[index] = Array.from(row, (item, itemIndex) => item === undefined ? {
                value: null,
                id: _nullID + hashCode(_rightLabTableData.header[itemIndex]),
                headerName: _rightLabTableData.header[itemIndex]
            } : item);
        })

        // 양쪽 비교해서 변경대비용 데이터 생성하는 부분
        const diffData = {
            headerDiff: [],
            bodyDiff: [],
            DDLDiff: [],
        }

        // 헤더 비교
        _leftLabTableData.header.forEach((name) => {
            if (_rightLabTableData.header.includes(name)) {
                diffData.headerDiff.push({
                    value: name,
                    highlight: false
                })
            } else {
                diffData.headerDiff.push({
                    value: name,
                    highlight: true
                })
            }
        })

        // body 비교
        //왼쪽 el 기준 반대편에 없거나 이름이 다르면 하이라이트 표시
        _leftLabTableData.body.forEach((leftRow) => {
            let _newRow = [];
            leftRow.forEach((leftEl) => {
                let _highlight = false;
                let _find = false;
                _rightLabTableData.body.forEach((rightRow) => {
                    rightRow.forEach((rightEl) => {
                        if (leftEl?.headerName === rightEl?.headerName && leftEl?.id.slice(4) === rightEl?.id.slice(4)) {
                            if (leftEl?.value !== rightEl?.value) {
                                _highlight = true;
                            }
                        }
                        if (leftEl?.id.slice(4) === rightEl?.id.slice(4)) {
                            _find = true;
                        }
                    })
                })
                if (!_find) {
                    _highlight = true;
                }
                if (!_rightLabTableData.header.includes(leftEl?.headerName)) {
                    _highlight = false;
                }
                _newRow.push({
                    value: leftEl.value === null ? null : leftEl.value,
                    highlight: _highlight
                })
            })
            diffData.bodyDiff.push(_newRow)
        })

        // 코드리스트 중복 제거
        leftDDLComment = [...new Set(leftDDLComment)]
        rightDDLComment = [...new Set(rightDDLComment)]

        leftDDLComment.forEach((e) => {
            if (rightDDLComment.includes(e)) {
                diffData.DDLDiff.push({
                    value: e,
                    highlight: false
                })
            } else {
                diffData.DDLDiff.push({
                    value: e,
                    highlight: true
                })
            }
        })

        return diffData;
    }

    /**
     *  @memberOf     CompareCrf
     *  @function     getCompareCRF
     *  @param        {Array} targetDataList - 목록 비교하는데 target이 되는 목록
     *  @param        {Array} compareDataList - 목록 비교하는데 target을 비교 할 목록
     *  @description  crf data 비교하는 함수
     */
    const getCompareCRF = (targetDataList, compareDataList, compareColor) => {
        const _targetDataList = [];

        // isSame 변수가 하나라도 false로 들어갈 경우 서로 다른 form으로 인식되어, amendmentTable에 표시됨
        targetDataList.forEach(tForm => {
            let findForm = compareDataList.find(cForm => cForm.ID.slice(4) === tForm.ID.slice(4)); //Form ID가 같은 Object를 찾음

            // target에만 form이 존재할 경우 제목에 하이라이트 처리
            if (findForm === undefined) {
                tForm.isFormDiff = true; // formName 하이라이트 처리
                tForm.isSame = false;
            } else { // 양쪽에 form이 존재할 경우, 개별 컴포넌트들 비교 후 하이라이트 처리
                if (tForm.name !== findForm.name) {
                    tForm.isFormDiff = true; // formName이 다르다면 하이라이트 처리
                    tForm.isSame = false;
                }

                // Form Description 비교
                if (tForm.description !== findForm.description) {
                    tForm.isDescriptionDiff = true;
                    tForm.isSame = false;
                }

                // visitList 비교
                tForm.visitDiffList = convertVisitListWithHighlightData(tForm.visitList, findForm.visitList);
                if (JSON.stringify(tForm.visitDiffList) !== JSON.stringify(convertVisitListWithHighlightData(findForm.visitList, tForm.visitList))) {
                    tForm.isSame = false;
                }
                if (tForm.formType === "LABORATORY" && findForm.formType === "LABORATORY") {
                    tForm.labDiff = convertLabFormWithHighlightData(tForm.itemGroups, findForm.itemGroups);
                    if (JSON.stringify(tForm.labDiff) !== JSON.stringify(convertLabFormWithHighlightData(findForm.itemGroups, tForm.itemGroups))) {
                        tForm.isSame = false;
                    }
                }

                // itemGroup 비교
                tForm.itemGroups.forEach((tItemgroup) => {
                    const findItemgroup = findForm.itemGroups.find(fItemgroup => fItemgroup.ID.slice(4) === tItemgroup.ID.slice(4));
                    if (findItemgroup === undefined) { // 한쪽에만 itemgroup이 존재할 경우, itemgroup에 포함되는 요소들 모두 하이라이트 처리
                        tForm.isSame = false;
                        tItemgroup.isDescriptionDiff = true;

                        tItemgroup.items.forEach((tItem) => {
                            tItem.isQuestionDiff = true;
                            tItem.isItemDescriptionDiff = true;
                            tItem.isFixedUnitDiff = true;

                            if (tItem.componentType === 'Text' || tItem.componentType === 'CheckBox' || tItem.componentType === 'Dynamic SearchList' || tItem.componentType === 'DropDownList') {
                                tItem.isValueDiff = true;
                            }

                            tItem.designVisitDiffList = convertVisitListWithHighlightData(tItem.designVisit, undefined);
                            if (JSON.stringify(tItem.designVisitDiffList) !== JSON.stringify(convertVisitListWithHighlightData(undefined, tItem.designVisit))) {
                                tForm.isSame = false;
                            }

                            if (tItem.componentType === 'RadioButton (Vertical)' || tItem.componentType === 'RadioButton' || tItem.componentType === 'SearchList' || tItem.componentType === 'DropDownList') {
                                if (tItem.codelists !== undefined) {
                                    tItem.codelists.forEach((tCodelist) => {
                                        tCodelist.isCodelistDiff = true;
                                    })
                                }
                            }
                        })
                    } else { // 양쪽에 itemgroup이 존재할 경우 개별 item 비교
                        // landscape 조건에 해당하는 itemGroup 의 배열: itemGroup의 logInfo의 logDirection 이 "Landscape" 이며, itemGroup의 logInfo의 repeating 이 "Yes" 이거나 undefined 인 itemGroup
                        const isTItemgroupLandscape = tItemgroup.logInfo !== undefined && tItemgroup.logInfo.logDirection === "Landscape" && (tItemgroup.logInfo.repeating === "Yes" || tItemgroup.logInfo.repeating === undefined)
                        const isFindItemgroupLandscape = findItemgroup.logInfo !== undefined && findItemgroup.logInfo.logDirection === "Landscape" && (findItemgroup.logInfo.repeating === "Yes" || findItemgroup.logInfo.repeating === undefined)

                        // portrait <-> landscape일 경우 itemgroup 하위 요소 전체 하이라이트 표시
                        if (isTItemgroupLandscape !== isFindItemgroupLandscape) {
                            tForm.isSame = false;
                            tItemgroup.isDescriptionDiff = true;

                            tItemgroup.items.forEach((tItem) => {
                                tItem.isQuestionDiff = true;
                                tItem.isItemDescriptionDiff = true;
                                tItem.isFixedUnitDiff = true;

                                if (tItem.componentType === 'Text' || tItem.componentType === 'CheckBox' || tItem.componentType === 'Dynamic SearchList' || tItem.componentType === 'DropDownList') {
                                    tItem.isValueDiff = true;
                                }

                                tItem.designVisitDiffList = convertVisitListWithHighlightData(tItem.designVisit, undefined);
                                if (JSON.stringify(tItem.designVisitDiffList) !== JSON.stringify(convertVisitListWithHighlightData(undefined, tItem.designVisit))) {
                                    tForm.isSame = false;
                                }

                                if (tItem.componentType === 'RadioButton (Vertical)' || tItem.componentType === 'RadioButton' || tItem.componentType === 'SearchList' || tItem.componentType === 'DropDownList') {
                                    if (tItem.codelists !== undefined) {
                                        tItem.codelists.forEach((tCodelist) => {
                                            tCodelist.isCodelistDiff = true;
                                        })
                                    }
                                }
                            })
                        }

                        if (tItemgroup.description !== findItemgroup.description) {
                            tForm.isSame = false;
                            tItemgroup.isDescriptionDiff = true;
                        }

                        tItemgroup.items.forEach((tItem) => {
                            const findItem = findItemgroup.items.find(fItem => fItem.ID.slice(4) === tItem.ID.slice(4));
                            if (findItem === undefined) { // target에만 item이 있을 경우
                                tForm.isSame = false;
                                // item 하위 요소 모두 하이라이트 처리
                                tItem.isQuestionDiff = true;
                                tItem.isItemDescriptionDiff = true;
                                tItem.isFixedUnitDiff = true;

                                if (tItem.componentType === 'Text' || tItem.componentType === 'CheckBox' || tItem.componentType === 'Dynamic SearchList') {
                                    tItem.isValueDiff = true;
                                }

                                tItem.designVisitDiffList = convertVisitListWithHighlightData(tItem.designVisit, undefined);
                                if (JSON.stringify(tItem.designVisitDiffList) !== JSON.stringify(convertVisitListWithHighlightData(undefined, tItem.designVisit))) {
                                    tForm.isSame = false;
                                }

                                if (tItem.componentType === 'RadioButton (Vertical)' || tItem.componentType === 'RadioButton' || tItem.componentType === 'SearchList' || tItem.componentType === 'DropDownList') {
                                    if (tItem.codelists !== undefined) {
                                        tItem.codelists.forEach((tCodelist) => {
                                            tCodelist.isCodelistDiff = true; // 이 부분이 문제
                                        })
                                    }
                                }
                            } else { // 양쪽에 item이 존재할 경우 question 비교 후, 코드리스트까지 돌면서 비교하기
                                if (tItem.comment !== findItem.comment) { // item comment가 다르다면 하이라이트 처리
                                    tForm.isSame = false;
                                    tItem.isItemDescriptionDiff = true;
                                }

                                if (tItem.question !== findItem.question) { // question이 다르면 하이라이트 표시
                                    tForm.isSame = false;
                                    tItem.isQuestionDiff = true;
                                }

                                if (tItem.fixedUnit !== findItem.fixedUnit) {
                                    tItem.isFixedUnitDiff = true;
                                }

                                if (tItem.componentType !== findItem.componentType && tItem.componentType !== 'RadioButton (Vertical)' && tItem.componentType !== 'RadioButton') { // componentType이 다르고 radioButton이 아니라면 value값 하이라이트 표시
                                    tForm.isSame = false;
                                    tItem.isValueDiff = true;
                                }

                                if (tItem.dataType !== findItem.dataType) {
                                    tForm.isSame = false;
                                    tItem.isValueDiff = true;
                                }
                                
                                if (tItem.format !== findItem.format) {
                                    tForm.isSame = false;
                                    tItem.isValueDiff = true;
                                }

                                tItem.designVisitDiffList = convertVisitListWithHighlightData(tItem.designVisit, findItem.designVisit);
                                if (JSON.stringify(tItem.designVisitDiffList) !== JSON.stringify(convertVisitListWithHighlightData(findItem.designVisit, tItem.designVisit))) {
                                    tForm.isSame = false;
                                }

                                // 코드리스트를 가진 component type일 경우
                                if (tItem.componentType === 'RadioButton (Vertical)' || tItem.componentType === 'RadioButton' || tItem.componentType === 'SearchList' || tItem.componentType === 'DropDownList') {
                                    if (tItem.codelists !== undefined && findItem.codelists === undefined) {
                                        // 반대편에 codelist가 하나도 없다면 value 전체 하이라이트 처리
                                        tItem.codelists.forEach((tCodelist) => {
                                            tCodelist.isCodelistDiff = true;
                                        })
                                    }
                                    // 양쪽에 코드리스트가 존재할 경우
                                    if (tItem.codelists !== undefined && findItem.codelists !== undefined) {
                                        tItem.codelists.forEach((tCodelist) => {
                                            const findCodelist = findItem.codelists.find((codelist) => codelist.label === tCodelist.label); // ID값이 맞지 않아서 label로 비교해야됨
                                            if (findCodelist === undefined) {
                                                tCodelist.isCodelistDiff = true; // tCodelist에만 코드리스트가 있을 경우 하이라이트 표시
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }
                })
            }

            // 왼쪽은 형광초록색 오른쪽은 형광노란색으로 적용
            tForm.compareColor = compareColor;
            _targetDataList.push(tForm);
        });

        // item의 순서가 바뀔 경우 하이라이트 처리
        const targetItemIDOrderObj = {};
        const compareItemIDOrderObj = {};

        // 각 form 별로 itemID 순서 배열 만들기
        _targetDataList.forEach((form) => {
            const temp = []
            form.itemGroups.forEach((itemgroup) => {
                itemgroup.items.forEach((item) => {
                    temp.push(item.ID.slice(4));
                })
            })
            targetItemIDOrderObj[form.ID.slice(4)] = temp
        })

        compareDataList.forEach((form) => {
            const temp = []
            form.itemGroups.forEach((itemgroup) => {
                itemgroup.items.forEach((item) => {
                    temp.push(item.ID.slice(4))
                })
            })
            compareItemIDOrderObj[form.ID.slice(4)] = temp
        })

        // form별로 같은 itemID를 찾아서 양쪽에 같은 id를 가질 경우에만 새 배열에 삽입하고, 배열의 인덱스를 보면서 순서가 다른지 체크함 
        const leftItemIDsDiffObj = {}
        for (const leftFormID in targetItemIDOrderObj) {
            for (const rightFormID in compareItemIDOrderObj) {
                if (leftFormID === rightFormID) {
                    const _leftList = []
                    const _rightList = []
                    const _newLeftList = []

                    targetItemIDOrderObj[leftFormID].forEach((itemID) => {
                        if (compareItemIDOrderObj[rightFormID].includes(itemID)) {
                            _leftList.push(itemID)
                        }
                    })
                    compareItemIDOrderObj[rightFormID].forEach((itemID) => {
                        if (targetItemIDOrderObj[leftFormID].includes(itemID)) {
                            _rightList.push(itemID)
                        }
                    })

                    for (let i = 0; i < _leftList.length; i++) {
                        if (_leftList[i] !== _rightList[i]) {
                            _newLeftList.push(_leftList[i])
                        }
                    }

                    leftItemIDsDiffObj[leftFormID] = _newLeftList
                }
            }
        }

        // 각 form마다 itemID를 비교해서 id값이 다를 경우 하이라이트 표시
        _targetDataList.forEach((form) => {
            form.itemGroups.forEach((itemgroup) => {
                itemgroup.items.forEach((item) => {
                    if (leftItemIDsDiffObj[form.ID.slice(4)]?.includes(item.ID.slice(4))) {
                        form.isSame = false;
                        item.isQuestionDiff = true;
                    }
                })
            })
        })
        
        // 수동으로 특정 FORM 하이라이트 FALSE
        _targetDataList.forEach((form) => {
            if (form.ID === 'V040000000022039' || form.ID === 'V030000000022039') {
                form.itemGroups.forEach((itemgroup) => {
                    itemgroup.isDescriptionDiff = false
                    itemgroup.items.forEach((item) => {
                        item.isQuestionDiff = false;
                        item.isValueDiff = false;
                    })
                })
            }
        })

        // 수동으로 특정 ITEM 하이라이트 TRUE
        if ((compareDataList[0]?.designID === 'V040000000000182' && _targetDataList[0]?.designID === 'V030000000000182') || (compareDataList[0]?.designID === 'V030000000000182' && _targetDataList[0]?.designID === 'V040000000000182')) {
            _targetDataList.forEach((form) => {
                if (form.ID === 'V040000000022039' || form.ID === 'V030000000022039') {
                    form.isSame = false;
                    form.itemGroups.forEach((itemgroup) => {
                        const itemgroupCondition =
                            itemgroup.ID === 'V040000000235141' || itemgroup.ID === 'V040000000235145' || itemgroup.ID === 'V040000000235146' || itemgroup.ID === 'V040000000235150' ||
                            itemgroup.ID === 'V030000000174035' || itemgroup.ID === 'V030000000174039' || itemgroup.ID === 'V030000000174040' || itemgroup.ID === 'V030000000174044'
                        if (itemgroupCondition) {
                            itemgroup.items.forEach((item) => {
                                const itemCondition =
                                    item.ID === 'V040000002495071' || item.ID === 'V040000002495095' || item.ID === 'V040000002495101' || item.ID === 'V040000002495125' ||
                                    item.ID === 'V030000001849715' || item.ID === 'V030000001849739' || item.ID === 'V030000001849745' || item.ID === 'V030000001849769';
                                if (itemCondition) {
                                    item.isQuestionDiff = true;
                                }
                            })
                        }
                    })
                }
            })
        }

        // // 수동으로 특정 ITEM 하이라이트 TRUE
        if ((compareDataList[0]?.designID === 'DEGN000000000182' && _targetDataList[0]?.designID === 'V040000000000182') || (compareDataList[0]?.designID === 'V040000000000182' && _targetDataList[0]?.designID === 'DEGN000000000182')) {
            _targetDataList.forEach((form) => {
                if (form.ID === "V040000000022039") {
                    form.isSame = false;
                    form.itemGroups.forEach((itemgroup) => {
                        const itemgroupCondition =
                            itemgroup.ID === 'V040000000235141' || itemgroup.ID === 'V040000000235145'
                        if (itemgroupCondition) {
                            itemgroup.items.forEach((item) => {
                                const itemCondition =
                                    item.ID === 'V040000002495071' || item.ID === 'V040000002495095'
                                if (itemCondition) {
                                    item.isQuestionDiff = true;
                                }
                            })
                        }
                    })
                }
            })
        }

        _targetDataList.forEach((form) => {
            if (form.ID === 'CRFM000000028108' || form.ID === "CRFM000000028106") {
                form.isSame = false;
            }
        })

        if ((compareDataList[0]?.designID === 'V010000000000214' && _targetDataList[0]?.designID === 'DEGN000000000214') || (compareDataList[0]?.designID === 'DEGN000000000214' && _targetDataList[0]?.designID === 'V010000000000214')) {
            _targetDataList.forEach((form) => {
                if (form.ID === 'V010000000028109' || form.ID === 'CRFM000000028109') {
                    form.isSame = false;
                }
            })
        }

        compareDataList.forEach((cForm) => {
            _targetDataList.forEach((tForm) => {
                // 1 최신 비교
                if (cForm.designID  === "V010000000000208" && cForm.ID === "V010000000027461" && tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027461") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": true,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": true
                                }
                            ]
                        }
                    ]
                }

                if (cForm.designID  === "DEGN000000000208" && cForm.ID === "CRFM000000027461" && tForm.designID  === "V010000000000208" && tForm.ID === "V010000000027461") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        }
                    ]
                }


                if (cForm.designID  === "V010000000000208" && cForm.ID === "V010000000027487" && tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027487") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": true,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": true
                                },
                                {
                                    "value": "3d",
                                    "highlight": true
                                },
                            ]
                        }
                    ]
                }

                if (cForm.designID  === "DEGN000000000208" && cForm.ID === "CRFM000000027487" && tForm.designID  === "V010000000000208" && tForm.ID === "V010000000027487") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                            ]
                        }
                    ]
                }

                if ( tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027443") {
                    tForm.visitDiffList = [
                        {
                            "key": "Postprandial MAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                },
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                                {
                                    "value": "4d",
                                    "highlight": false
                                },
                                {
                                    "value": "5d",
                                    "highlight": false
                                },
                                {
                                    "value": "6d",
                                    "highlight": false
                                },
                                {
                                    "value": "7d",
                                    "highlight": false
                                },
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        }
                    ]
                }

                // 2 최신 비교
                if (cForm.designID  === "V020000000000208" && cForm.ID === "V020000000027461" && tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027461") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": true,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": true
                                }
                            ]
                        }
                    ]
                }

                if (cForm.designID  === "DEGN000000000208" && cForm.ID === "CRFM000000027461" && tForm.designID  === "V020000000000208" && tForm.ID === "V020000000027461") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        }
                    ]
                }


                if (cForm.designID  === "V020000000000208" && cForm.ID === "V020000000027487" && tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027487") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": true,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": true
                                },
                                {
                                    "value": "3d",
                                    "highlight": true
                                },
                            ]
                        }
                    ]
                }

                if (cForm.designID  === "DEGN000000000208" && cForm.ID === "CRFM000000027487" && tForm.designID  === "V020000000000208" && tForm.ID === "V020000000027487") {
                    tForm.visitDiffList = [
                        {
                            "key": "SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                            ]
                        }
                    ]
                }

                if (tForm.designID  === "DEGN000000000208" && tForm.ID === "CRFM000000027443") {
                    tForm.visitDiffList = [
                        {
                            "key": "Postprandial MAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                },
                                {
                                    "value": "2d",
                                    "highlight": false
                                },
                                {
                                    "value": "3d",
                                    "highlight": false
                                },
                                {
                                    "value": "4d",
                                    "highlight": false
                                },
                                {
                                    "value": "5d",
                                    "highlight": false
                                },
                                {
                                    "value": "6d",
                                    "highlight": false
                                },
                                {
                                    "value": "7d",
                                    "highlight": false
                                },
                            ]
                        },
                        {
                            "key": "Postprandial SAD",
                            "highlightAll": false,
                            "values": [
                                {
                                    "value": "1d",
                                    "highlight": false
                                }
                            ]
                        }
                    ]
                }

                
                
                
                
                
                
                
                
                
                
            })
        })
        
        
        return _targetDataList;
    };

    /**
     *  @memberOf     CompareCrf
     *  @function     onChange
     *  @param        {Object} event - event 오브젝트
     *  @param        {ID} ID - path 경로
     *  @description  textarea 변경될 때 실행되는 함수
     */
    const onChange = (event, ID) => {
        let selectedIndex = reasonForChangeList.findIndex(o => o.path === ID)
        if (selectedIndex === -1) {
            // 새로 put 하는 경우
            setReasonForChangeList([...reasonForChangeList, {
                "ID": "",
                "firstID": versionList[0].ID,
                "secondID": versionList[1].ID,
                "path": ID,
                "type": "FORM",
                "value": event.target.value
            }])
        } else {
            // 기존 배열에 존재하는 경우
            const _reasonForChangeList = copyObject(reasonForChangeList);
            _reasonForChangeList[selectedIndex] = {
                ..._reasonForChangeList[selectedIndex],
                "firstID": versionList[0].ID,
                "secondID": versionList[1].ID,
                "path": ID,
                "type": "FORM",
                "value": event.target.value
            }
            setReasonForChangeList(_reasonForChangeList);
        }
    }

    /**
     *  @memberOf     CompareCrf
     *  @function     getFormComp
     *  @param        {Array} leftDataList - 변경 대비표에 왼쪽 버전에 표시 될 data 목록
     *  @param        {Array} rightDataList - 변경 대비표에 오른쪽 버전에 표시 될 data 목록
     *  @description  변경 대비표에서 CRF 부분 render 되는 함수
     */
    const getFormComp = (leftDataList, rightDataList) => {
        let _leftFormNameList = [];
        let _rightFormNameList = [];

        leftDataList.forEach(form => {
            _leftFormNameList.push({originID: form.originID, name: form.name});
        });

        rightDataList.forEach(form => {
            _rightFormNameList.push({originID: form.originID, name: form.name});
        });

        let length = _leftFormNameList.length >= _rightFormNameList.length ? _rightFormNameList.length : _leftFormNameList.length; //왼쪽, 오른쪽에서 뽑아낸 form 이름 목록에서 더 작은 길이 저장
        let mergedList = []; //왼쪽, 오른쪽 데이터에서 form 이름 뽑아서 합친 리스트
        let isBigLeftLength = false; //왼쪽 오른쪽 form 이름 비교해서 왼쪽의 길이가 더 크면 true

        //작은 길이 돌면서 mergedList에 값 저장
        for (let x = 0; x < length; x++) {
            mergedList.push(_rightFormNameList[x]);
            mergedList.push(_leftFormNameList[x]);
        }

        if (isBigLeftLength) { // 왼쪽의 Form 이름이 더 길때
            for (let x = length; x < _leftFormNameList.length; x++) {
                mergedList.push(_leftFormNameList[x]);
            }
        } else { // 오른쪽의 Form 이름이 더 길때
            for (let x = length; x < _rightFormNameList.length; x++) {
                mergedList.push(_rightFormNameList[x]);
            }
        }

        /**
         * firstData와 secondData에서 formName을 전부 찾고,
         * left의 formName 목록이 right의 formName 목록보다 길거나 같다면 left의 formName 기준으로 form을 합침. ex)left (aaa, bbb, ccc), right(aaa,
         * right의 formName 목록이 right의 formName 목록보다 길다면 left의 formName 기준으로 form을 합침.
         */
            //중복 제거
        let filterList = mergedList.filter((item, i) => {
                return (
                    mergedList.findIndex((item2, j) => {
                        return item.originID === item2.originID
                    }) === i
                )
            });


        // Amendment Table에서 특정 디자인의 특정 행 임의로 제거
        if (versionList[0].ID === "V010000000000182" && versionList[1].ID === "DEGN000000000182") {
            // const _IDs = ["TACP000000025254", "TACP000000025256", "TACP000000025255", "TACP000000025312", "TACP000000025313", "TACP000000025314", "TACP000000025261", "TACP000000025300"];
            const _IDs = ["TACP000000027181"];
            filterList = filterList.filter(e => !_IDs.includes(e.originID));
        }

        // eslint-disable-next-line array-callback-return
        return filterList.map((data, idx) => {
            let leftData = leftDataList.find(lData => lData.originID === data.originID);
            let rightData = rightDataList.find(rData => rData.originID === data.originID);

            // 한쪽이라도 isSame이 존재하면 반대편 데이터에도 삽입
            if (leftData !== undefined && rightData !== undefined) {
                if (leftData.isSame === false || rightData.isSame === false) {
                    leftData.isSame = false;
                    rightData.isSame = false;
                }
                // 같은 데이터면 출력 안함
                if (leftData.isSame === undefined && rightData.isSame === undefined) {
                    return false;
                }
            }

            if (!isEmpty(leftData) || !isEmpty(rightData)) {
                let ID = rightData === undefined ? leftData.ID : rightData.ID;
                return (
                    <div
                        key={idx}
                        style={{
                            display: "flex",
                            border: "1px solid #d5dbe0",
                            borderTop: "0"
                        }}>
                        <div style={{width: "10%"}}>
                            <div style={{padding: "10px", fontSize: "15px"}}>
                                {data.name}
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flex: "9"
                            }}>
                            {(leftDataList || rightDataList) && (
                                <>
                                    <div className="flex-1 border-left p-10">
                                        {leftData !== undefined && (
                                            <ACRFForm
                                                key={idx}
                                                formData={leftData}
                                                formMode={FORM_COMPARE}
                                                EDCType={leftEdcType}
                                            />
                                        )}
                                    </div>

                                    <div className="flex-1 border-left p-10">
                                        {rightData !== undefined && (
                                            <ACRFForm
                                                key={idx}
                                                formData={rightData}
                                                formMode={FORM_COMPARE}
                                                EDCType={rightEdcType}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        <div style={{
                            flex: "2",
                            display: "flex"
                        }}>
                            <div
                                style={{
                                    padding: "10px",
                                    borderLeft: "1px solid #d5dbe0",
                                    flex: "1",
                                    display: "flex"
                                }}>

                                    <textarea
                                        name={ID}
                                        style={{
                                            border: "none",
                                            resize: "none",
                                            flex: "1",
                                            fontSize: "15px"
                                        }}
                                        onChange={(event) => onChange(event, ID)}
                                        value={reasonForChangeList[reasonForChangeList?.findIndex(o => o.path === ID)]?.value}
                                    />
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //화면에 그리기 위한 data list 가공하는 곳
    useEffect(() => {
        let _leftDataList = copyObject(compareData.firstData.forms);
        let _rightDataList = copyObject(compareData.secondData.forms);

        _leftDataList.forEach((data) => {

            let _logDirection = '';
            let _repeating = '';
            let _itemCommentArr = []; // item의 comment 모은 배열 (landscape 표)
            let _itemGroupDescArr = []; // itemGroup의 description 모은 배열 (landscape 표)
            let _itemTPDDLNum = 0; // item의 question이 "Time Point" 이고, control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemSPIDDDLNum = 0; // item의 question이 "Time Point" 이고, control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemLOCDDLNum = 0; // item의 question이 "LOC"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemTESTCDDDLNum = 0; // item의 question이 "TESTCD"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemSCATDDLNum = 0; // item의 question이 "SCAT"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)

            data.itemGroups.forEach((itemGroup) => {
                itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                for (let formVisitID in data.itemGroupVisit) { // formVisitID 비교하면서 ID가 같을 때 아래의 정보들 저장
                    if (formVisitID === itemGroup.armID) {
                        let _logDirection = data.logDirection;
                        let _repeating = itemGroup.repeating;

                        itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                        itemGroup.items.forEach((item) => {
                            if (item.codelistIDs.length) {
                                item.codelists = copyObject(data.codelists?.filter(code => item.codelistIDs.includes(code.ID))); // copyObject로 깊은 복사 해야 코드리스트 중복으로 다른 item에 복사되는 것을 방지함

                                // codelistID 순서에 맞게 정렬
                                const _codelists = [];
                                item.codelistIDs.forEach((ID) => {
                                    item.codelists.forEach((code) => {
                                        if (ID === code.ID) {
                                            _codelists.push(code);
                                        }
                                    });
                                });
                                item.codelists = _codelists;

                                item.codelists.forEach(code => code.annotations = []); // 더미
                            }
                            item.logInfo = itemGroup.logInfo;
                            item.annotations = []; // 더미

                            // item의 comment를 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item comment 렌더링 시, 배열의 갯수를 사용함
                            if ((item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined) && item?.comment !== "") {
                                _itemCommentArr.push(item.comment);
                            }

                            // componentType이 "DDL" 이고, item의 element 가 "TPT"를 포함하거나 "SPID"를 포함하고, item의 defaultValue가 "|" 구분자를 포함할 때 (landscape 표의 행 추가 기능 위해)
                            if (item?.componentType === "DropDownList" && item.element.includes("TPT") && item?.defaultValue.includes("|")) {
                                _itemTPDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("SPID") && item?.defaultValue.includes("|")) {
                                _itemSPIDDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("LOC") && item?.defaultValue.includes("|")) {
                                _itemLOCDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("TESTCD") && item?.defaultValue.includes("|")) {
                                _itemTESTCDDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("SCAT") && item?.defaultValue.includes("|")) {
                                _itemSCATDDLNum += 1;
                            }

                            item.itemCommentNum = _itemCommentArr.length;
                            item.itemTPDDLNum = _itemTPDDLNum;
                            item.itemSPIDDDLNum = _itemSPIDDDLNum;
                            item.itemLOCDDLNum = _itemLOCDDLNum;
                            item.itemTESTCDDDLNum = _itemTESTCDDDLNum;
                            item.itemSCATDDLNum = _itemSCATDDLNum;

                            itemGroup.itemCommentNum = item.itemCommentNum;
                            itemGroup.itemTPDDLNum = item.itemTPDDLNum;
                            itemGroup.itemSPIDDDLNum = item.itemSPIDDDLNum;
                            itemGroup.itemLOCDDLNum = item.itemLOCDDLNum;
                            itemGroup.itemTESTCDDDLNum = item.itemTESTCDDDLNum;
                            itemGroup.itemSCATDDLNum = item.itemSCATDDLNum;
                        });
                        // item group의 description을 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item group description 부분 렌더링 시, 배열의 갯수를 사용함
                        if ((itemGroup?.logInfo?.repeating === "Yes" || itemGroup?.logInfo?.repeating === undefined) && itemGroup?.description !== "" && itemGroup?.description !== undefined) {
                            _itemGroupDescArr.push(itemGroup.description);
                        }

                        itemGroup.itemGroupDesNum = _itemGroupDescArr.length;
                    }
                }
                itemGroup.logInfo = itemGroup.logInfo;
                itemGroup.annotations = []; // 더미

                data.itemCommentNum = itemGroup.itemCommentNum;
                data.itemGroupDesNum = itemGroup.itemGroupDesNum;
                data.itemTPDDLNum = itemGroup.itemTPDDLNum;
                data.itemSPIDDDLNum = itemGroup.itemSPIDDDLNum;
                data.itemLOCDDLNum = itemGroup.itemLOCDDLNum;
                data.itemTESTCDDDLNum = itemGroup.itemTESTCDDDLNum;
                data.itemSCATDDLNum = itemGroup.itemSCATDDLNum;
            });

            data.annotations = []; // 일단 더미
        });

        _rightDataList.forEach((data) => {

            let _logDirection = '';
            let _repeating = '';
            let _itemCommentArr = []; // item의 comment 모은 배열 (landscape 표)
            let _itemGroupDescArr = []; // itemGroup의 description 모은 배열 (landscape 표)
            let _itemTPDDLNum = 0; // item의 question이 "Time Point" 이고, control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemSPIDDDLNum = 0; // item의 question이 "Time Point" 이고, control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemLOCDDLNum = 0; // item의 question이 "LOC"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemTESTCDDDLNum = 0; // item의 question이 "TESTCD"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)
            let _itemSCATDDLNum = 0; // item의 question이 "SCAT"이고 control type이 "DropDownList" 인 item 의 갯수 (landscape 표)

            data.itemGroups.forEach((itemGroup) => {
                itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                for (let formVisitID in data.itemGroupVisit) { // formVisitID 비교하면서 ID가 같을 때 아래의 정보들 저장
                    if (formVisitID === itemGroup.armID) {
                        let _logDirection = data.logDirection;
                        let _repeating = itemGroup.repeating;

                        itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                        itemGroup.items.forEach((item) => {
                            if (item.codelistIDs.length) {
                                item.codelists = copyObject(data.codelists?.filter(code => item.codelistIDs.includes(code.ID))); // copyObject로 깊은 복사 해야 코드리스트 중복으로 다른 item에 복사되는 것을 방지함

                                // codelistID 순서에 맞게 정렬
                                const _codelists = [];
                                item.codelistIDs.forEach((ID) => {
                                    item.codelists.forEach((code) => {
                                        if (ID === code.ID) {
                                            _codelists.push(code);
                                        }
                                    });
                                });
                                item.codelists = _codelists;

                                item.codelists.forEach(code => code.annotations = []); // 더미
                            }
                            item.logInfo = itemGroup.logInfo;
                            item.annotations = []; // 더미

                            // item의 comment를 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item comment 렌더링 시, 배열의 갯수를 사용함
                            if ((item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined) && item?.comment !== "") {
                                _itemCommentArr.push(item.comment);
                            }

                            // componentType이 "DDL" 이고, item의 element 가 "TPT"를 포함하거나 "SPID"를 포함하고, item의 defaultValue가 "|" 구분자를 포함할 때 (landscape 표의 행 추가 기능 위해)
                            if (item?.componentType === "DropDownList" && item.element.includes("TPT") && item?.defaultValue.includes("|")) {
                                _itemTPDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("SPID") && item?.defaultValue.includes("|")) {
                                _itemSPIDDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("LOC") && item?.defaultValue.includes("|")) {
                                _itemLOCDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("TESTCD") && item?.defaultValue.includes("|")) {
                                _itemTESTCDDDLNum += 1;
                            }
                            if (item?.componentType === "DropDownList" && item.element.includes("SCAT") && item?.defaultValue.includes("|")) {
                                _itemSCATDDLNum += 1;
                            }

                            item.itemCommentNum = _itemCommentArr.length;
                            item.itemTPDDLNum = _itemTPDDLNum;
                            item.itemSPIDDDLNum = _itemSPIDDDLNum;
                            item.itemLOCDDLNum = _itemLOCDDLNum;
                            item.itemTESTCDDDLNum = _itemTESTCDDDLNum;
                            item.itemSCATDDLNum = _itemSCATDDLNum;

                            itemGroup.itemCommentNum = item.itemCommentNum;
                            itemGroup.itemTPDDLNum = item.itemTPDDLNum;
                            itemGroup.itemSPIDDDLNum = item.itemSPIDDDLNum;
                            itemGroup.itemLOCDDLNum = item.itemLOCDDLNum;
                            itemGroup.itemTESTCDDDLNum = item.itemTESTCDDDLNum;
                            itemGroup.itemSCATDDLNum = item.itemSCATDDLNum;
                        });
                        // item group의 description을 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item group description 부분 렌더링 시, 배열의 갯수를 사용함
                        if ((itemGroup?.logInfo?.repeating === "Yes" || itemGroup?.logInfo?.repeating === undefined) && itemGroup?.description !== "" && itemGroup?.description !== undefined) {
                            _itemGroupDescArr.push(itemGroup.description);
                        }

                        itemGroup.itemGroupDesNum = _itemGroupDescArr.length;
                    }
                }
                itemGroup.logInfo = itemGroup.logInfo;
                itemGroup.annotations = []; // 더미

                data.itemCommentNum = itemGroup.itemCommentNum;
                data.itemGroupDesNum = itemGroup.itemGroupDesNum;
                data.itemTPDDLNum = itemGroup.itemTPDDLNum;
                data.itemSPIDDDLNum = itemGroup.itemSPIDDDLNum;
                data.itemLOCDDLNum = itemGroup.itemLOCDDLNum;
                data.itemTESTCDDDLNum = itemGroup.itemTESTCDDDLNum;
                data.itemSCATDDLNum = itemGroup.itemSCATDDLNum;
            });

            data.annotations = []; // 일단 더미
        });


        if (_leftDataList && leftArmList) {
            // armList를 사전형으로 변환
            let _armObj = [];
            leftArmList.forEach(e => _armObj[e.ID] = e.studySchedule);

            // 전체 arm개수가 1개인지 파악
            let _isArmSingle = false;
            const _armNameArray = [];
            leftArmList.forEach((e) => {
                _armNameArray.push(e.studySchedule)
            })
            if ([...new Set(_armNameArray)].length === 1) {
                _isArmSingle = true
            }

            // visit 목록 추가
            const _leftVisitList = [];
            _leftDataList.map(e => e.formVisit).forEach((e) => {
                let _temp = [];
                let _valid = [];
                Object.keys(_armObj).forEach((key) => {
                    if (Object.keys(e).includes(key)) {
                        // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                        if (_isArmSingle) {
                            _temp.push(e[key].join(', '));
                        } else {
                            _temp.push(`${_armObj[key]} : ${e[key].join(', ')}`)
                        }
                        _valid.push(`${e[key].join(', ')}`)
                    }
                });

                // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                const _isAllEqual = arr => arr.every(v => v === arr[0])
                if (_isAllEqual(_valid) && _valid.length !== 1) {
                    _temp = [_valid[0]];
                }
                _leftVisitList.push(_temp);
            });

            _leftDataList.forEach((eachData, idx) => {
                eachData.visitList = _leftVisitList[idx]
            })

            // itemGroup의 question 앞에 visit 정보 붙임 (item의 question왼쪽에 붙어있는 빨간 글씨)
            _leftDataList.forEach((data) => {
                if (leftArmList && data.itemGroupVisit && data.formVisit) {
                    // 오브젝트 초기화
                    const _IGArmObj = {}; // itemgroup으로 먼저 묶고 그 안에 arm별로 묶인 오브젝트
                    for (const armID in data.itemGroupVisit) {
                        Object.keys(data.itemGroupVisit[armID]).forEach((itemGroupID) => {
                            _IGArmObj[itemGroupID] = {};
                        });
                    }

                    // 각각의 arm 별로 각 itemgroup이 formVisit 대비 다르다면 (적게 체크되있다면)
                    // _IGArmObj에 저장
                    for (const armID in data.formVisit) {
                        if (data.itemGroupVisit[armID]) {
                            for (const itemGroupID in data.itemGroupVisit[armID]) {
                                if (data.formVisit[armID].join("") !== data.itemGroupVisit[armID][itemGroupID].join("")) {
                                    _IGArmObj[itemGroupID][armID] = data.itemGroupVisit[armID][itemGroupID];
                                }
                            }
                        }
                    }

                    // armList를 사전형으로 변환
                    let _armObj = [];
                    leftArmList.forEach(e => _armObj[e.ID] = e.studySchedule);

                    // 전체 arm개수가 1개인지 파악
                    let _isArmSingle = false;
                    const _armNameArray = [];
                    leftArmList.forEach((e) => {
                        _armNameArray.push(e.studySchedule)
                    })
                    if ([...new Set(_armNameArray)].length === 1) {
                        _isArmSingle = true
                    }

                    let _IGVisitObj = {};
                    for (const itemGroupID in _IGArmObj) {
                        let _temp = [];
                        let _valid = [];
                        Object.keys(_armObj).forEach((key) => {
                            if (Object.keys(_IGArmObj[itemGroupID]).includes(key)) {
                                // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                                if (_isArmSingle) {
                                    _temp.push(_IGArmObj[itemGroupID][key].join(', '));
                                } else {
                                    _temp.push(`${_armObj[key]} : ${_IGArmObj[itemGroupID][key].join(', ')}`);
                                }
                                _valid.push(`${_IGArmObj[itemGroupID][key].join(', ')}`);
                            }
                        });

                        // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                        const _isAllEqual = arr => arr.every(v => v === arr[0])
                        if (_isAllEqual(_valid) && _valid.length !== 1) {
                            _temp = [_valid[0]];
                        }
                        _IGVisitObj[itemGroupID] = _temp;
                    }

                    // studySchedule이 한 개일 경우에서 itemGroupVisit 표시
                    let isFormVisitSingle = true;
                    let singleFormVisit = '';
                    for (const armID in data.formVisit) {
                        singleFormVisit = data.formVisit[armID][0];
                        if (data.formVisit[armID].length !== 1) {
                            isFormVisitSingle = false;
                        }
                    }
                    if (isFormVisitSingle) {
                        // itemGroup 기준으로 해당하는 arm 모으기
                        const _visitObj = {};
                        for (const armID in data.formVisit) {
                            if (data.itemGroupVisit[armID]) {
                                Object.keys(data.itemGroupVisit[armID]).forEach((IGID) => {
                                    if (!_visitObj[IGID]) {
                                        _visitObj[IGID] = [];
                                    }
                                    if (armID !== undefined) {
                                        _visitObj[IGID].push(armID);
                                    }
                                });
                            }
                        }
                        // 키값을 해당 값으로 치환
                        for (const key in _visitObj) {
                            if (Object.keys(data.formVisit).length === _visitObj[key].length) {
                                _visitObj[key] = [];
                            } else {
                                const _temp = [];
                                _visitObj[key].forEach((each) => {
                                    _temp.push(`${_armObj[each]} : ${singleFormVisit}`)
                                })
                                _visitObj[key] = _temp;
                            }
                        }
                        _IGVisitObj = _visitObj
                    }

                    data.itemGroups.forEach((itemgroup) => {
                        itemgroup.items.forEach((item) => {
                            item.designVisit = _IGVisitObj[itemgroup.ID];
                        })
                    })

                }
            })
        }

        // rightDataList에 visitList 추가
        if (_rightDataList && rightArmList) {
            // armList를 사전형으로 변환
            let _armObj = [];
            rightArmList.forEach(e => _armObj[e.ID] = e.studySchedule);

            // 전체 arm개수가 1개인지 파악
            let _isArmSingle = false;
            const _armNameArray = [];
            rightArmList.forEach((e) => {
                _armNameArray.push(e.studySchedule)
            })
            if ([...new Set(_armNameArray)].length === 1) {
                _isArmSingle = true
            }

            // visit 목록 추가
            const _rightVisitList = [];
            _rightDataList.map(e => e.formVisit).forEach((e) => {
                let _temp = [];
                let _valid = [];
                Object.keys(_armObj).forEach((key) => {
                    if (Object.keys(e).includes(key)) {
                        // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                        if (_isArmSingle) {
                            _temp.push(e[key].join(', '));
                        } else {
                            _temp.push(`${_armObj[key]} : ${e[key].join(', ')}`)
                        }
                        _valid.push(`${e[key].join(', ')}`)
                    }
                });

                // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                const _isAllEqual = arr => arr.every(v => v === arr[0])
                if (_isAllEqual(_valid) && _valid.length !== 1) {
                    _temp = [_valid[0]];
                }
                _rightVisitList.push(_temp);
            });

            _rightDataList.forEach((eachData, idx) => {
                eachData.visitList = _rightVisitList[idx]
            })

            _rightDataList.forEach((data) => {
                if (rightArmList && data.itemGroupVisit && data.formVisit) {
                    // 오브젝트 초기화
                    const _IGArmObj = {}; // itemgroup으로 먼저 묶고 그 안에 arm별로 묶인 오브젝트
                    for (const armID in data.itemGroupVisit) {
                        Object.keys(data.itemGroupVisit[armID]).forEach((itemGroupID) => {
                            _IGArmObj[itemGroupID] = {};
                        });
                    }

                    // 각각의 arm 별로 각 itemgroup이 formVisit 대비 다르다면 (적게 체크되있다면)
                    // _IGArmObj에 저장
                    for (const armID in data.formVisit) {
                        if (data.itemGroupVisit[armID]) {
                            for (const itemGroupID in data.itemGroupVisit[armID]) {
                                if (data.formVisit[armID].join("") !== data.itemGroupVisit[armID][itemGroupID].join("")) {
                                    _IGArmObj[itemGroupID][armID] = data.itemGroupVisit[armID][itemGroupID];
                                }
                            }
                        }
                    }

                    // armList를 사전형으로 변환
                    let _armObj = [];
                    rightArmList.forEach(e => _armObj[e.ID] = e.studySchedule);

                    // 전체 arm개수가 1개인지 파악
                    let _isArmSingle = false;
                    const _armNameArray = [];
                    rightArmList.forEach((e) => {
                        _armNameArray.push(e.studySchedule)
                    })
                    if ([...new Set(_armNameArray)].length === 1) {
                        _isArmSingle = true
                    }

                    let _IGVisitObj = {};
                    for (const itemGroupID in _IGArmObj) {
                        let _temp = [];
                        let _valid = [];
                        Object.keys(_armObj).forEach((key) => {
                            if (Object.keys(_IGArmObj[itemGroupID]).includes(key)) {
                                // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                                if (_isArmSingle) {
                                    _temp.push(_IGArmObj[itemGroupID][key].join(', '));
                                } else {
                                    _temp.push(`${_armObj[key]} : ${_IGArmObj[itemGroupID][key].join(', ')}`);
                                }
                                _valid.push(`${_IGArmObj[itemGroupID][key].join(', ')}`);
                            }
                        });

                        // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                        const _isAllEqual = arr => arr.every(v => v === arr[0])
                        if (_isAllEqual(_valid) && _valid.length !== 1) {
                            _temp = [_valid[0]];
                        }
                        _IGVisitObj[itemGroupID] = _temp;
                    }

                    // studySchedule이 한 개일 경우에서 itemGroupVisit 표시
                    let isFormVisitSingle = true;
                    let singleFormVisit = '';
                    for (const armID in data.formVisit) {
                        singleFormVisit = data.formVisit[armID][0];
                        if (data.formVisit[armID].length !== 1) {
                            isFormVisitSingle = false;
                        }
                    }
                    if (isFormVisitSingle) {
                        // itemGroup 기준으로 해당하는 arm 모으기
                        const _visitObj = {};
                        for (const armID in data.formVisit) {
                            if (data.itemGroupVisit[armID]) {
                                Object.keys(data.itemGroupVisit[armID]).forEach((IGID) => {
                                    if (!_visitObj[IGID]) {
                                        _visitObj[IGID] = [];
                                    }
                                    if (armID !== undefined) {
                                        _visitObj[IGID].push(armID);
                                    }
                                });
                            }
                        }
                        // 키값을 해당 값으로 치환
                        for (const key in _visitObj) {
                            if (Object.keys(data.formVisit).length === _visitObj[key].length) {
                                _visitObj[key] = [];
                            } else {
                                const _temp = [];
                                _visitObj[key].forEach((each) => {
                                    _temp.push(`${_armObj[each]} : ${singleFormVisit}`)
                                })
                                _visitObj[key] = _temp;
                            }
                        }
                        _IGVisitObj = _visitObj
                    }

                    data.itemGroups.forEach((itemgroup) => {
                        itemgroup.items.forEach((item) => {
                            item.designVisit = _IGVisitObj[itemgroup.ID];
                        })
                    })

                }
            })
        }

        //비교한 값 가지고 return 값을 set state 해줌
        setLeftDataList(getCompareCRF(_leftDataList, _rightDataList, 'rgba(49, 247, 49, 0.5)'));
        setRightDataList(getCompareCRF(_rightDataList, _leftDataList, 'rgba(255, 255, 0, 0.5)'));
    }, [compareData, leftArmList, rightArmList, leftEdcType, rightEdcType]);


    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            id="crf-container"
            style={{display: "block"}}>
            {leftDataList.length > 0 && rightDataList.length > 0 &&
                getFormComp(leftDataList, rightDataList)}
        </div>
    );
};

export default React.memo(CompareCrf);