import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    PackageDefineForm
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Define iframe 화면 컴포넌트
 */
const PackageDefineForm = (props) => {
    /**
     *  @memberOf     PackageDefineForm
     *  @type         {Object} props
     *  @property     {String} urlLink - iframe 화면에 표시할 define.xml link
     *  @property     {Function} onLoad - iframe 로딩 시 실행될 상위 이벤트 함수
     *  @property     {HTMLIFrameElement} iframeRef - iframe ref 영역
     *  @property     {Number} count - iframe 업데이트를 위한 key number
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {urlLink, onLoad, iframeRef, count} = props;

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div className="m-t-20">
            <PerfectScrollbar options={{suppressScrollX: true}} style={{height: 'calc(100vh - 305px'}}>
                <div className="row m-l-10 height-full border-top">
                    <iframe
                        key={count}           // define 내용 저장 후 화면 강제 렌더링 시켜 저장 내용 표시 - 참고 : https://awesomezero.com/development/react-key/#key%EB%A5%BC-%ED%86%B5%ED%95%B4%EC%84%9C-%EB%A0%8C%EB%8D%94%EB%A7%81-%EC%BB%A8%ED%8A%B8%EB%A1%A4%ED%95%98%EA%B8%B0
                        ref={iframeRef}
                        title="Define View"
                        style={{paddingTop: "1em", width: "99%", height: "height-full"}}
                        allowFullScreen
                        srcDoc={urlLink}
                        onLoad={onLoad}/>
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default PackageDefineForm;



