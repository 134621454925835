import React, {useCallback} from 'react';
import deleteIcon from "../../assets/icons/close.svg";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    AnnotationComponent
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Annotation 컴포넌트
 */
const AnnotationComponent = (props) => {
    const {annotationList, onClick, onDelete, isEdit, item} = props;

    /**
     *  @memberOf     AnnotationComponent
     *  @function     handleClick
     *  @param        {Object} annotation - 선택 된 annotation의 정보
     *  @param        {Object} annotation - 선택 된 annotation의 Item정보
     *  @description  Annotation 클릭 되면 호출 되는 함수
     */
    const handleClick = useCallback((annotation, itemData) => {
        if (onClick !== undefined && isEdit && !annotation.ID.includes("NOTS")) {
            onClick(annotation, itemData);
        }
    }, [isEdit, onClick]);

    /**
     *  @memberOf     AnnotationComponent
     *  @function     handleDelete
     *  @param        {Number} idx - 리스트에서 선택 된 index
     *  @param        {String} type - Annotation의 type
     *  @param        {Object} annotation - 선택 된 annotation의 Item정보
     *  @description  Annotation Delete 하는 함수
     */
    const handleDelete = useCallback((idx, type, itemData) => {
        if (onDelete !== undefined) {
            onDelete(idx, type, itemData);
        }
    }, [onDelete]);

    /**
     *  @memberOf     AnnotationComponent
     *  @function     renderAnnotationComp
     *  @return       {Element} Annotation Box render
     *  @description  각각의 정보에 맞게 Annotation 그려주는 함수
     */
    const renderAnnotationComp = useCallback(() => {
        return annotationList.map((annotation, idx) => {
            if (annotation.value !== "") {
                return <div
                    key={idx}
                    name={annotation.ID}
                    style={{
                        display: "flex",
                        marginRight: "5px",
                        padding: "5px",
                        margin: "2px 4px",
                        background: annotation.background,
                        fontWeight: annotation.type === "FORM" ? "800" : "0",
                        border: `2px ${annotation.borderType} #000000`,
                        cursor: isEdit ? "pointer" : "default",
                        fontSize: "12pt"
                    }}>
                    <div style={{wordBreak: "break-word", fontFamily: "Arial, sans-serif"}}
                         onClick={() => handleClick(annotation, item)}>
                        {annotation.value.replaceAll('\\"', '')}
                    </div>

                    {isEdit && (
                        <img
                            src={deleteIcon}
                            onClick={() => {
                                handleDelete(idx, annotation.type, item)
                            }}
                            style={{marginLeft: "5px"}}
                            alt=""/>
                    )}
                </div>
            } else {
                return <div key={idx}/>
            }
        });
    }, [annotationList, handleClick, handleDelete, isEdit, item]);

    return (
        <>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {renderAnnotationComp()}
            </div>
        </>
    );
};

export default AnnotationComponent;