import React, {useEffect, useState} from "react";
import produce from "immer";

/**
 *  @author       김성연
 *  @version      1.0
 *  @component    ECRFSchedule
 *  @param        {Object} dataList - eCRF Schedule 데이터
 *  @description  Design Form의 eCRF Schedule 컴포넌트
 */
const ECRFSchedule = ({dataList}) => {
    /**
     *  @memberOf     ECRFSchedule
     *  @var          {Object} data
     *  @description  화면에 표시할 data
     */
    const [data, setData] = useState(dataList);

    /**
     *  @memberOf     ECRFSchedule
     *  @var          {Boolean} isWindowEmpty
     *  @description  window 항목이 비어있으면 표시 안하기 위함
     */
    const [isWindowEmpty, setIsWindowEmpty] = useState(true);

    // 데이터에 Unschduled와 All 컬럼 붙여줌
    useEffect(() => {
        if (dataList?.epochList) {
            const _dataList = produce(dataList, draft => {
                draft.epochList.push(
                    {
                        "ID": "Unscheduled_0",
                        "name": "UV",
                        "studyEvent": [
                            {
                                "ID": "Unscheduled_1",
                                "name": "UV"
                            }
                        ]
                    },
                    {
                        "ID": "All_0",
                        "name": "All",
                        "studyEvent": [
                            {
                                "ID": "All_1",
                                "name": "All"
                            }
                        ]
                    }
                );
            })

            // window 항목이 비어있으면 화면에 표시 안함
            let _isEmpty = true;
            dataList.epochList.map((col) => {
                col.studyEvent.map((each) => {
                    if (each.window) {
                        _isEmpty = false;
                    }
                })
            })

            setIsWindowEmpty(_isEmpty);
            setData(_dataList);
        }
    }, [dataList])

    return (
        <>
            <h1
                style={{
                    fontSize: "18.67px",
                    fontWeight: "800",
                    alignSelf: "center",
                    paddingTop: "15px",
                    fontFamily: "Arial, sans-serif"
                }}>
                <span>{"■ Schedule of Activities"}</span>
            </h1>
            <table
                border='1'
                style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                    marginBottom: '50px',
                    tableLayout: 'fixed',
                    width: '100%',
                    fontSize: '10px',
                }}>
                <thead style={{textAlign: 'center'}}>
                <tr>
                    <th
                        rowSpan={2}
                        width={'130px'}
                        style={{fontWeight: '500', background: '#f0f0f0'}}>
                        Form
                    </th>
                    {data?.epochList?.map((col) => (
                        <th
                            key={col.ID}
                            colSpan={col.studyEvent.length}
                            style={{
                                overflowWrap: 'anywhere',
                                fontWeight: '500',
                                padding: "5px 0",
                                background: '#f0f0f0'
                            }}>
                            <span style={{
                                backgroundColor: col.isEpochDiff ? data.compareColor : ""
                            }}>{col.name}</span>
                        </th>
                    ))}
                </tr>
                <tr>
                    {data?.epochList?.map((col) => (
                        col.studyEvent.map((each) => (
                            <th
                                key={each.ID}
                                style={{
                                    overflowWrap: 'anywhere',
                                    fontWeight: '500',
                                    padding: "5px 0",
                                    background: '#f0f0f0'
                                }}>
                                <span
                                    style={{backgroundColor: each.isStudyEventDiff ? data.compareColor : ""}}>
                                    {each.name}
                                </span>
                            </th>
                        ))
                    ))}
                </tr>

                {!isWindowEmpty &&
                    <tr>
                        <th style={{
                            background: '#f0f0f0',
                            fontWeight: '500',
                        }}>Visit window(days)
                        </th>
                        {data?.epochList?.map((col) => (
                            col.studyEvent.map((each) => (
                                <th
                                    key={each.ID}
                                    style={{
                                        overflowWrap: 'anywhere',
                                        fontWeight: '500',
                                        padding: "5px 0",
                                        background: '#f0f0f0'
                                    }}>
                                    <span style={{
                                        backgroundColor: each.isWindowDiff ? data.compareColor : ""
                                    }}>{each.window}</span>
                                </th>
                            ))
                        ))}
                    </tr>
                }
                </thead>
                <tbody>
                {data?.formList?.map((row) => (
                    <tr key={row.ID}>
                        <td style={{padding: '5px'}}>
                            <span
                                style={{backgroundColor: row.isFormDiff ? data.compareColor : ""}}>{row.name}</span>
                        </td>
                        {row.eventList?.map((event) => (
                            <td key={event.ID} style={{textAlign: 'center'}}>
                                <span
                                    style={{backgroundColor: event.isEventDiff ? data.compareColor : ""}}>{event.eventCheck ? '√' : ''}</span>
                            </td>
                        ))}
                        <td style={{textAlign: 'center'}}>
                            <span style={{
                                backgroundColor: row.isUVDiff ? data.compareColor : ""
                            }}>{row.unscheduledVisit ? '√' : ''}</span>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <span style={{
                                backgroundColor: row.isAllVisitDiff ? data.compareColor : ""
                            }}>{row.allVisit ? '√' : ''}</span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
};

export default React.memo(ECRFSchedule);