import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import cn from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT, copyObject, getSessionState} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import useWindowSize from "../../../../../common/useWindowSize";
import {FILL_REQUIRED_FIELD} from "../../../../../../constant/ConstantMsg";
import WhereClauseEditModal from "./WhereClauseEditModal";
import deleteIcon from "../../../../../../assets/icons/delete.svg";
import VariableCodeListModal from "./VariableCodeListModal";
import VariableMetadataMethodModal from "./VariableMetadataMethodModal";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     VariableMetadataList
 *  @constant     {Array} YES_OR_NO_LIST
 *  @description  mandatory 항목에 표시할 카테고리 옵션 리스트
 */
const YES_OR_NO_LIST = [
    {
        text: 1,
        value: "Yes"
    },
    {
        text: 0,
        value: "No"
    }
];

/**
 *  @memberOf     VariableMetadataList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/${ID}?domain=${domain}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_INFO:
            url = requestUrl
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    VariableMetadataList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 컴포넌트
 */
const VariableMetadataList = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     VariableMetadataList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} domain - 선택된 Variable domain Name
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, domain} = props;

    /**
     *  @memberOf     VariableMetadataList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock, defineVersion} = getSessionState();

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {HTMLDivElement} gridRef
     *  @description  AUI grid ref 영역
     */
    const gridRef = useRef(null);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Boolean} isWhereClauseEditModal
     *  @description   Where Clause edit 팝업 오픈 여부
     */
    const [isWhereClauseEditModal, setIsWhereClauseEditModal] = useState(false);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Boolean} isVariableCodeListModal
     *  @description   codelist edit 팝업 오픈 여부
     */
    const [isVariableCodeListModal, setIsVariableCodeListModal] = useState(false);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {String} targetVariable
     *  @description  선택한 whereClause의 Variable 데이터
     */
    const [targetVariable, setTargetVariable] = useState("");

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} variableList
     *  @description  whereClause edit modal variable dropDown 리스트
     */
    const [variableList, setVariableList] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} whereClauseList
     *  @description  전체 데이터에서의 origin whereClause 데이터
     */
    const [whereClauseList, setWhereClauseList] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} modalDataList
     *  @description  선택한 modal의 whereClause 데이터
     */
    const [modalDataList, setModalDataList] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} targetDataSet
     *  @description  선택한 whereClause 의  dataSet 데이터
     */
    const [targetDataSet, setTargetDataSet] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Array} originDataList
     *  @description  원본데이터
     */
    const [originDataList, setOriginDataList] = useState([]);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {String} targetSubID
     *  @description  선택한 whereClause 의  subID 데이터
     */
    const [targetSubID, setTargetSubID] = useState("");

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Number} dataOrder
     *  @description  선택한 item order
     */
    const [dataOrder, setDataOrder] = useState(0);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Number} whereDataOrder
     *  @description  선택한 item의 whereClause order
     */
    const [whereDataOrder, setWhereDataOrder] = useState(0);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Object} currentEditCodeList
     *  @description  변경할 codelist 값
     */
    const [currentEditCodeList, setCurrentEditCodeList] = useState({});

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {Boolean} isMethodModal
     *  @description  Method 컬럼 눌렀을때 팝업 오픈 여부
     */
    const [isMethodModal, setIsMethodModal] = useState(false);

    /**
     *  @memberOf     VariableMetadataList
     *  @var          {String} currentMethod
     *  @description  Method 컬럼에서 선택된 Method의 정보 값
     */
    const [currentMethod, setCurrentMethod] = useState("");

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     VariableMetadataList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/specification`,
            ID: ID,
            domain: domain
        };

        netWorkAgent.current.request(command, params);
    }, [ID, domain]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleGridChange
     *  @param        {Event} e - 클릭한 column의 이벤트 객체
     *  @description  컬럼 데이터 변경 시 실행되는 함수
     */
    const handleGridChange = useCallback((e) => {
        const {item, value, dataField} = e;
        const rowIndex = dataList.findIndex(e => e.ID === item.ID);

        let _dataInfo = copyObject(dataList); //원본 데이터 복사
        if (dataField === "origin" && value === "Predecessor") {
            _dataInfo[rowIndex].source = "";
            _dataInfo[rowIndex][dataField] = value;
        } else if (dataField === "origin" && value === "") {
            _dataInfo[rowIndex].source = "";
            _dataInfo[rowIndex].predecessor = "";
            _dataInfo[rowIndex][dataField] = value;
        } else if (dataField === "origin") {
            _dataInfo[rowIndex].predecessor = "";
            _dataInfo[rowIndex][dataField] = value;
        } else {
            _dataInfo[rowIndex][dataField] = value;
        }
        setDataList(_dataInfo);
    }, [dataList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleGridRowChange
     *  @param        {Array}  list - 순서 변경 된 dataList
     *  @description  whereClause 데이터 row 순서 변경 시 실행되는 함수
     */
    const handleWhereClauseRowChange = useCallback((list) => {
        const changeDataList = list.filter((item) => !`${item.order}`.includes("-")); //whereClause 제외한 데이터
        const whereClauseArr = list.filter((item) => `${item.order}`.includes("-")); //whereClause 데이터

        whereClauseArr.forEach((data) => {
            let grid_order = `${data.order}`.split('-');
            let compare_order = grid_order[1]; //dataList에서 whereClause의 order값
            let addID = []; //원본에 있는 같은 whereClause의 ID 리스트

            //whereClauseList : 원본에 있는 whereClause 리스트
            whereClauseList.forEach((item) => {
                if (parseInt(compare_order) === item.order && data.mappingVariable === item.mappingVariable && data.dataSet === item.domain) {
                    addID.push(item.ID);
                    data.addRowID = addID;
                }
            });
        });

        let before_order = 0; //이전 order
        let now_order = 0; //현재 order
        let change_order = 0;
        whereClauseArr.forEach((data) => {
            now_order = data.order.split("-")[0];
            if (before_order === now_order) {
                change_order = change_order + 1
                before_order = now_order;
                data.order = `${data.order.split("-")[0]}-${change_order}`
            } else {
                change_order = 1
                before_order = now_order;
                data.order = `${data.order.split("-")[0]}-${change_order}`
            }
        });

        //whereClauseList = origin whereClause 리스트
        //whereClauseArr = 변경한 whereClause 리스트
        whereClauseList.forEach((whereClause) => {
            whereClauseArr.forEach((data) => {
                let split_order = `${data.order}`.split('-');
                let changeOrder = split_order[1];
                data.addRowID.forEach((item) => {
                    if (whereClause.ID === item) {
                        whereClause.order = changeOrder;
                        whereClause.assignedValue = data.assignedValue;
                        whereClause.codelist = data.codelist;
                        whereClause.comment = data.comment;
                        whereClause.dataType = data.dataType;
                        whereClause.developerNotes = data.developerNotes;
                        whereClause.domain = data.domain;
                        whereClause.format = data.format;
                        whereClause.keySequence = data.keySequence;
                        whereClause.label = data.label;
                        whereClause.length = data.length;
                        whereClause.mandatory = data.mandatory;
                        whereClause.mappingVariable = data.mappingVariable;
                        whereClause.method = data.method;
                        whereClause.origin = data.origin;
                        whereClause.pages = data.pages;
                        whereClause.predecessor = data.predecessor;
                        whereClause.role = data.role;
                        whereClause.significantDigits = data.significantDigits;
                        whereClause.source = data.source;
                    }
                });
            });
        });

        changeDataList.forEach((item) => {
            whereClauseList.forEach((data) => {
                if (data.mappingVariable === item.mappingVariable && item.dataSet && data.domain) {
                    item.whereClauseList.push(data);
                }
            });
        });

        const command = COMMAND.DATA_UPDATE;
        let params = {};
        let sendObj = {
            domain: domain,
            list: changeDataList
        };

        // 데이터 수정에 필요한 parameter
        params = {
            requestUrl: `${SDTM_MAPPING_URL}/specification`,
            ID: ID,
            sendObject: JSON.stringify(sendObj)
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID, domain, whereClauseList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleGridRowChange
     *  @param        {Array}  list - 순서 변경 된 dataList
     *  @description  grid row 순서 변경 시 실행되는 함수
     */
    const handleGridRowChange = useCallback((list) => {
        let changeOrderWhereArr = []; // 순서 변경 된 whereClause 데이터 리스트
        let excludedWhereClauseList = []; // 순서가 변경된 whereClause를 제외한 데이터 리스트

        list.forEach((data) => {
            if (`${data.order}`.includes("-")) {
                changeOrderWhereArr.push(data);
            } else {
                excludedWhereClauseList.push(data);
            }
        });

        //supp 데이터
        let suppData = [];
        let noSuppData = [];
        excludedWhereClauseList.forEach((data, index) => {
            if (!data.dataSet.includes("SUPP")) {
                noSuppData.push(data);
                noSuppData.forEach((item, idx, array) => {
                    //supp 아래로 순서 변경 했을 시 supp 제외한 데이터 마지막 order 값으로 수정
                    if (idx === array.length - 1) {
                        data.order = array.length
                    } else {
                        data.order = index + 1
                    }
                });
            } else {
                suppData.push(data);
                suppData.forEach((item, idx) => {
                    item.order = idx + 1
                });
            }
        });

        changeOrderWhereArr.forEach((data) => {
            let grid_order = `${data.order}`.split('-');
            let compare_order = grid_order[1]; //dataList에서 whereClause의 order값
            let addID = []; //원본에 있는 같은 whereClause의 ID 리스트

            //whereClauseList : 원본에 있는 whereClause 리스트
            whereClauseList.forEach((item) => {
                if (parseInt(compare_order) === item.order && data.mappingVariable === item.mappingVariable && data.dataSet === item.domain) {
                    addID.push(item.ID);
                    data.addRowID = addID;
                }
            });
        });

        whereClauseList.forEach(whereClause => {
            changeOrderWhereArr.forEach(data => {
                let split_order = `${data.order}`.split('-');
                let changeOrder = split_order[1];
                data.addRowID.forEach((item) => {
                    if (whereClause.ID === item) {
                        whereClause.order = changeOrder;
                        whereClause.assignedValue = data.assignedValue;
                        whereClause.codelist = data.codelist;
                        whereClause.comment = data.comment;
                        whereClause.dataType = data.dataType;
                        whereClause.developerNotes = data.developerNotes;
                        whereClause.domain = data.domain;
                        whereClause.format = data.format;
                        whereClause.keySequence = data.keySequence;
                        whereClause.label = data.label;
                        whereClause.length = data.length;
                        whereClause.mandatory = data.mandatory;
                        whereClause.mappingVariable = data.mappingVariable;
                        whereClause.method = data.method;
                        whereClause.origin = data.origin;
                        whereClause.pages = data.pages;
                        whereClause.predecessor = data.predecessor;
                        whereClause.role = data.role;
                        whereClause.significantDigits = data.significantDigits;
                        whereClause.source = data.source;
                    }
                });
            });
        });

        let copyNewChangeOrderList = copyObject(excludedWhereClauseList); //순서변경 된 dataList에서 whereClause를 제외한 데이터
        copyNewChangeOrderList.forEach((item) => {
            whereClauseList.forEach((data) => {
                if (data.mappingVariable === item.mappingVariable && item.dataSet && data.domain) {
                    item.whereClauseList.push(data);
                }
            });
        });

        const command = COMMAND.DATA_UPDATE;
        let params = {};
        let sendObj = {
            domain: domain,
            list: copyNewChangeOrderList
        };

        // 데이터 수정에 필요한 parameter
        params = {
            requestUrl: `${SDTM_MAPPING_URL}/specification`,
            ID: ID,
            sendObject: JSON.stringify(sendObj)
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID, domain, whereClauseList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     getSubIdData
     *  @description  whereClause modal에서 subID 호출하는 함수
     */
    const getSubIdData = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/whereClause/subID`,
        };

        netWorkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleColumnClick
     *  @param        {Event} e - 클릭한 column의 이벤트 객체
     *  @description  컬럼 클릭 시 실행되는 함수
     */
    const handleColumnClick = useCallback((e) => {
        const {dataField, item} = e;
        if (dataField === 'whereClauseList') {
            let modalList = [];
            //whereClause 에 값이 있을 때
            if (item.whereClauseList.length > 0) {
                let checkOrder = item.order.split('-');
                let whereClauseNum = parseInt(checkOrder[1]);
                let girdOrderdNum = parseInt(checkOrder[0]);
                setDataOrder(girdOrderdNum);
                setWhereDataOrder(whereClauseNum);

                whereClauseList.forEach((data) => {
                    if (item.mappingVariable === data.mappingVariable && data.order === whereClauseNum && data.domain === item.dataSet) {
                        modalList.push(data);
                    }
                });
            } else {
                //whereClause 데이터 가 없는  whereClause를 클릭 했을 경우
                let addOrder = 0;
                originDataList.forEach((data) => {
                    if (data.ID === item.ID) {
                        if (data.whereClauseList.length > 0) {
                            data.whereClauseList.forEach((item) => {
                                addOrder = item.order + 1;
                            });
                        } else {
                            addOrder = 1;
                        }
                    }
                });
                setDataOrder(item.order);
                setWhereDataOrder(addOrder);
            }
            //해당 Variable 데이터
            if (`${item.order}`.includes("-")) {
                setTargetVariable(item.mappingVariable);
            } else {
                setTargetVariable(item.variable);
            }
            // subId 데이터 호출
            getSubIdData();
            setModalDataList(modalList); //해당 WhereClause 데이터
            setTargetDataSet(item.dataSet);
            setIsWhereClauseEditModal(!isWhereClauseEditModal);
        } else if (dataField === 'codelist') {
            setCurrentEditCodeList(item);
            setIsVariableCodeListModal(!isVariableCodeListModal);
        } else if (dataField === 'method') {
            setCurrentMethod(e);
            setIsMethodModal(true);
        }
    }, [getSubIdData, isVariableCodeListModal, isWhereClauseEditModal, originDataList, whereClauseList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(gridRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleDeleteChange
     *  @param        {Array}  list  -  변경 된 List
     *  @description  grid 삭제 실행되는 함수
     */
    const handleDeleteChange = useCallback((event, list) => {
        let deleteOrder = event.items[0].order.split('-');
        let deleteWhereNum = parseInt(deleteOrder[1]);
        let matchingVariable = event.items[0].mappingVariable;
        let matchDataSet = event.items[0].dataSet;

        const changeDataArr = list.filter((item) => !`${item.order}`.includes("-")); //whereClause를 제외한 데이터

        let newDeleteArr = [];
        changeDataArr.forEach((item) => {
            originDataList.forEach((data) => {
                if (item.ID === data.ID) {
                    newDeleteArr.push({...item, whereClauseList: data.whereClauseList});
                }
            });
        });

        let deleteSendList = [];
        newDeleteArr.forEach((item) => {
            if (item.mappingVariable === matchingVariable && item.dataSet === matchDataSet) {
                let deleteWhereList = item.whereClauseList.filter((data) => data.order !== deleteWhereNum);
                deleteSendList.push({...item, whereClauseList: deleteWhereList});
            } else {
                deleteSendList.push({...item});
            }
        });

        //order 값 정렬
        deleteSendList.forEach((data) => {
            if (data.whereClauseList.length > 0) {
                let b_subID = "" //이전 subID
                let a_subID = "" // 현재 subID
                let order = 0;
                data.whereClauseList.forEach((item) => {
                    a_subID = item.subID;

                    if (item.subID === "") {
                        order = order + 1
                        item.order = order;
                    } else if (a_subID === b_subID) {
                        item.order = order;
                    } else {
                        order = order + 1
                        item.order = order;
                    }
                    b_subID = a_subID;
                });
            }
        });

        const command = COMMAND.DATA_UPDATE;
        let params = {};
        let sendObj = {
            domain: domain,
            list: deleteSendList
        };

        // 데이터 수정에 필요한 parameter
        params = {
            requestUrl: `${SDTM_MAPPING_URL}/specification`,
            ID: ID,
            sendObject: JSON.stringify(sendObj)
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID, domain, originDataList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleModalOk
     *  @return       {Array} modalList - whereClauseEdit modal에서 수정 된 whereClause 값
     *  @description  whereClause Edit Modal 에서 ok를 눌렀을 때 실행되는 함수
     */
    const handleModalOk = useCallback((modalList) => {
        let newWhereClauseArr = [];
        originDataList.forEach((data) => {
            if (data.order === dataOrder && data.whereClauseList.length > 0 && modalList[0].domain === data.dataSet) {
                const newWhereClauseList = data.whereClauseList.filter((whereItem) => whereItem.order !== modalList[0].order);
                newWhereClauseList.forEach((item) => {
                    newWhereClauseArr.push(item);
                });

                modalList.forEach((item) => {
                    newWhereClauseArr.push(item);
                });
            } else {
                //원본에 whereClauseList가 없을 때 추가
                modalList.forEach((item) => {
                    if (data.variable === item.mappingVariable && data.order === dataOrder && data.dataSet === item.domain) {
                        newWhereClauseArr.push(item);
                    }
                });
            }
        });

        //수정 된 whereClause 리스트를 정렬함
        let sortWhereClauseArr = newWhereClauseArr.sort((a, b) => a.order - b.order);
        const changeDataList = dataList.filter((item) => !`${item.order}`.includes("-"));

        let newSendArr = [];
        changeDataList.forEach((data, idx) => {
            sortWhereClauseArr.forEach((item) => {
                if (data.variable === item.mappingVariable && data.dataSet === item.domain) {
                    data.whereClauseList = sortWhereClauseArr;
                } else {
                    data.whereClauseList = originDataList[idx].whereClauseList;
                }
            });

            newSendArr.push(data);
        });

        const command = COMMAND.DATA_UPDATE;
        let params = {};
        let sendObj = {
            domain: domain,
            list: newSendArr
        };

        // 데이터 수정에 필요한 parameter
        params = {
            requestUrl: `${SDTM_MAPPING_URL}/specification`,
            ID: ID,
            sendObject: JSON.stringify(sendObj)
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        setIsWhereClauseEditModal(!whereClauseList);
    }, [ID, dataList, dataOrder, domain, originDataList, whereClauseList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleOk
     *  @description  Method 컬럼 클릭하면 팝업되는 Modal 에서 ok를 눌렀을 때 실행되는 함수
     */
    const handleOk = useCallback((rowIdx, value) => {
        let _dataList = copyObject(dataList);
        _dataList[rowIdx]["method"] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleCodeListSave
     *  @param        {Object} data - 변경한 데이터
     *  @description  codeList 팝업 OK 눌렀을때 호출 되는 함수
     */
    const handleCodeListSave = useCallback((data) => {
        let _dataInfo = copyObject(dataList); //원본 데이터 복사
        const findIdx = _dataInfo.findIndex(item => item.ID === data.ID);
        _dataInfo[findIdx] = data;
        setDataList(_dataInfo);
        setIsVariableCodeListModal(!isVariableCodeListModal);
    }, [dataList, isVariableCodeListModal]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     validateData
     *  @return       {Boolean} validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        return window.AUIGrid.validateGridData(`#variableMetadata_grid`, ["dataSet", "variable", "dataType", "mandatory"], FILL_REQUIRED_FIELD);
    }, []);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            // 데이터 validation
            const valid = validateData();
            if (valid === false) {
                return;
            }

            //whereClause가 없는 dataList
            const changeDataList = dataList.filter((item) => !`${item.order}`.includes("-"));

            //whereClause가 없는 dataList에 whereClause 넣기
            let originWhereClauseList = []; //dataList + origin의 whereClauseList 데이터
            changeDataList.forEach((data, idx) => {
                data.whereClauseList = originDataList[idx].whereClauseList;
                originWhereClauseList.push(data);
            });

            let whereClauseList = [];
            //whereClause 데이터
            dataList.forEach((item) => {
                if (`${item.order}`.includes("-")) {
                    whereClauseList.push(item);
                }
            });

            let whereClauseArr = [];
            //originWhereClauseList : dataList + origin의 whereClauseList 데이터
            originWhereClauseList.forEach((data) => {
                data.whereClauseList.forEach((whereClause) => {
                    // whereClause: origin의 whereClauseList
                    whereClauseList.forEach((item) => {
                        if (parseInt(item.order.split("-")[1]) === whereClause.order && item.mappingVariable === whereClause.mappingVariable) {
                            whereClauseArr.push({
                                ...whereClause,
                                assignedValue: item.assignedValue,
                                codelist: item.codelist,
                                comment: item.comment,
                                dataType: item.dataType,
                                developerNotes: item.developerNotes,
                                domain: item.domain,
                                format: item.format,
                                keySequence: item.keySequence,
                                label: item.label,
                                length: item.length,
                                mandatory: item.mandatory,
                                mappingVariable: item.mappingVariable,
                                method: item.method,
                                origin: item.origin,
                                pages: item.pages,
                                predecessor: item.predecessor,
                                role: item.role,
                                significantDigits: item.significantDigits,
                                source: item.source,
                            });
                        }
                    });
                });
            });

            const insertObject = (a, b) => {
                // b 오브젝트 안에 a 오브젝트를 삽입하는 함수
                const a_dict = {}
                a.forEach((e, i) => {
                    a_dict[e.ID] = i;
                });

                b.forEach((el, idx) => {
                    if (el.whereClauseList.length !== 0) {
                        el.whereClauseList.forEach((nestedEl, nestedIdx) => {
                            if (Object.keys(a_dict).includes(nestedEl.ID)) {
                                b[idx]['whereClauseList'][nestedIdx] = a[a_dict[nestedEl.ID]];
                            }
                        });
                    }
                });
                return b;
            };

            const command = COMMAND.DATA_UPDATE;
            let params = {};
            let sendObj = {
                domain: domain,
                list: insertObject(whereClauseArr, changeDataList)
            };

            // 데이터 수정에 필요한 parameter
            params = {
                requestUrl: `${SDTM_MAPPING_URL}/specification`,
                ID: ID,
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, domain, lock, originDataList, validateData]);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     VariableMetadataList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    if (data.hasOwnProperty('data')) {
                        //원본데이터 저장
                        setOriginDataList(data.data);

                        const changeDataType = data.data.map((list) => {
                            if (list.dataType === "") {
                                return {
                                    ...list,
                                    dataType: "Text"
                                };
                            } else {
                                return {
                                    ...list
                                };
                            }
                        });

                        let newGridList = [];
                        // list 하나씩 반복

                        let hasWhereClause = []; // 모든 whereClause
                        changeDataType.forEach((list) => {
                            // 데이터 지정
                            const {whereClauseList, order} = list;
                            whereClauseList.forEach((data) => {
                                hasWhereClause.push(data);
                            });

                            // whereClause에 item이 있으면 로직 실행 whereClauseList에 반복 실행은 2번째
                            if (whereClauseList.length > 0) {
                                // 원래리스트 넣어주기 빈값으로
                                let oldList = {...list, mappingVariable: list.variable};
                                oldList.whereClauseList = [];
                                newGridList.push(oldList);

                                // 순수하게 겹치지않는 whereClauseList order들
                                let existedOrder = [];
                                // 겹치지 않은 order를 키값으로 value로 AND 이렇게 연결할 것을 만들어줌
                                let objectGroup = {};
                                // WhereClause 반복 item = ex) whereClauseList[0]
                                let whereClauseOrderList = {};
                                //whereClause order가 동일한 whereClasue 데이터 묶기
                                whereClauseList.forEach((whereClause) => {
                                    if (!whereClauseOrderList[whereClause.order]) {
                                        whereClauseOrderList[whereClause.order] = [whereClause];
                                    } else {
                                        whereClauseOrderList[whereClause.order].push(whereClause);
                                    }
                                });

                                // whereClauseOrderList 안에서
                                Object.keys(whereClauseOrderList).forEach((_whereClauseList) => {
                                    //  같은 variable를 가진 whereClause들끼리 묶어 variableObject 만들기
                                    let variableComparatorObject = {};
                                    whereClauseOrderList[_whereClauseList].forEach((whereClause => {
                                        if (!variableComparatorObject[`${whereClause.variable} ${whereClause.comparator}`]) {
                                            variableComparatorObject[`${whereClause.variable} ${whereClause.comparator}`] = [whereClause.value];
                                        } else {
                                            variableComparatorObject[`${whereClause.variable} ${whereClause.comparator}`].push(whereClause.value);
                                        }
                                    }));

                                    Object.keys(variableComparatorObject).forEach((variableComparator) => {
                                        let comparator = variableComparator.split(' ')[1];
                                        if (objectGroup[_whereClauseList] === undefined) {
                                            existedOrder.push(_whereClauseList);
                                            let value = variableComparatorObject[variableComparator];
                                            if (comparator === 'IN' || comparator === 'NOTIN') {
                                                value = value.length === 1 ? `${value}` : `(${value})`;
                                                objectGroup[_whereClauseList] = `${variableComparator} ${value}`;
                                            } else {
                                                value.forEach((val) => {
                                                    if (objectGroup[_whereClauseList] === undefined) {
                                                        objectGroup[_whereClauseList] = `${variableComparator} ${val}`;
                                                    } else {
                                                        objectGroup[_whereClauseList] += ` AND ${variableComparator} ${val}`;
                                                    }
                                                });
                                            }
                                        } else {
                                            let value = variableComparatorObject[variableComparator];
                                            if (comparator === 'IN' || comparator === 'NOTIN') {
                                                value = value.length === 1 ? `${value}` : `(${value})`;
                                                objectGroup[_whereClauseList] += ` AND ${variableComparator} ${value}`;
                                            } else {
                                                value.forEach((val) => {
                                                    objectGroup[_whereClauseList] += ` AND ${variableComparator} ${val}`;
                                                });
                                            }
                                        }
                                    });
                                });

                                existedOrder.forEach((subOrder) => {
                                    // 새로운 한줄 만듬
                                    let newList = {...whereClauseList[subOrder - 1]};
                                    newList.order = `${order}-${subOrder}`;
                                    newList.dataSet = whereClauseList[subOrder - 1].domain;
                                    newList.whereClauseList = objectGroup[subOrder];
                                    // 새로운 한 줄을 리스트에 넣어줌
                                    newGridList.push(newList);
                                });

                                // 아닌 경우 빈 Array 값을 할당하고 newGridList 에 추가
                            } else {
                                newGridList.push({...list, whereClauseList: "", mappingVariable: list.variable});
                            }
                        });

                        let sendGridList = [];
                        newGridList.forEach((item) => {
                            if (`${item.order}`.includes("-")) {
                                let gridOrder = `${item.order}`.split('-');
                                let compareOder = gridOrder[1];
                                let isInserted = false;
                                hasWhereClause.forEach((data) => {
                                        if (isInserted === false && data.domain === item.domain && data.mappingVariable === item.mappingVariable && parseInt(compareOder) === data.order) {
                                            //     업데이트....
                                            sendGridList.push({
                                                ...item, assignedValue: data.assignedValue,
                                                codelist: data.codelist,
                                                comment: data.comment,
                                                dataType: data.dataType,
                                                developerNotes: data.developerNotes,
                                                domain: data.domain,
                                                format: data.format,
                                                keySequence: data.keySequence,
                                                label: data.label,
                                                length: data.length,
                                                mandatory: data.mandatory,
                                                mappingVariable: data.mappingVariable,
                                                method: data.method,
                                                origin: data.origin,
                                                pages: data.pages,
                                                predecessor: data.predecessor,
                                                role: data.role,
                                                significantDigits: data.significantDigits,
                                                source: data.source
                                            });
                                            isInserted = true;
                                        }
                                    }
                                );
                            } else {
                                sendGridList.push({...item});
                            }
                        });
                        setDataList(sendGridList);

                        let whereClauseArr = [];
                        data.data.forEach((allList) => {
                            allList.whereClauseList.forEach((whereClauseList) => {
                                whereClauseArr.push(whereClauseList);
                            });
                        });
                        setWhereClauseList(whereClauseArr);

                        let variableArr = [];
                        data.data.forEach((list) => {
                                variableArr.push({
                                        value: list.variable,
                                        label: list.variable
                                    }
                                );
                            }
                        );
                        setVariableList(variableArr);
                    }
                    break;

                case
                COMMAND.DATA_INFO :
                    if (data.hasOwnProperty('data')) {
                        setTargetSubID(data.data);
                    }
                    break;
                case
                COMMAND.DATA_UPDATE:
                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();

        vertical = 0;
        horizontal = 0;
    }, [getDataList]);

    // AUIGrid 생성
    useLayoutEffect(() => {
        const auiGridProps = {
            rowHeight: 34,
            showRowNumColumn: false,  // no 표시
            enableSorting: false,
            headerHeights: [45],
            editable: true,
            wordWrap: true,
            height: 500,
            tooltipSensitivity: 0,
            noDataMessage: "No data.",
            enableCellMerge: true,
            showSelectionBorder: true,
            selectionMode: "multipleCells",
            enableDrag: lock !== 2,
            enableDragByCellDrag: lock !== 2,
            enableDrop: lock !== 2,
            enableFilter: true, //filter

            //고정 열
            fixedColumnCount: 5,
            triggerSelectionChangeOnCell: true,
            softRemovePolicy: "exceptNew",
            simplifySelectionEvent: true,
            rowStyleFunction: function (rowIndex, item) {
                if (`${item.order}`.includes("-")) {
                    return "gridRow-font-style";
                }
                return null;
            },

            //고정 행
            // fixedRowCount: 1,
            //자동 높이 조절
            // autoGridHeight : true
        };

        gridRef.current = window.AUIGrid.create("#variableMetadata_grid", columnLayout, auiGridProps);

        return () => {
            window.AUIGrid.destroy("#variableMetadata_grid");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // AUI GRID 데이터 생성 및 이벤트 핸들러 연결
    useEffect(() => {
        if (dataList.length > 0) {
            window.AUIGrid.setGridData(gridRef.current, dataList);
        } else {
            window.AUIGrid.showInfoMessage(gridRef.current, "<div class='data-center'><div><i class=\"fas fa-exclamation-circle\"></i><span>No Data</span></div></div>");
        }

        if ({vertical: vertical, horizontal: horizontal} !== undefined && ({
            vertical: vertical,
            horizontal: horizontal
        }.vertical > 0 || {vertical: vertical, horizontal: horizontal}.horizontal > 0)) {
            window.AUIGrid.setRowPosition(gridRef.current, {vertical: vertical, horizontal: horizontal}.vertical); //세로 스크롤 위치 설정
            window.AUIGrid.setHScrollPositionByPx(gridRef.current, {
                vertical: vertical,
                horizontal: horizontal
            }.horizontal); //가로 스크롤 위치 설정
        }
    }, [dataList]);

    // AUI Grid 이벤트
    useEffect(() => {
        window.AUIGrid.bind(gridRef.current, "cellEditBegin", (event) => {
            if (handleGridChange !== undefined) {
                if (event.dataField === "pages") {
                    if (event.item.origin === "Collected" && event.item.source === "Investigator") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (event.dataField === "source") {
                    if (event.item.origin === "Predecessor" || event.item.origin === "Not Available" || event.item.origin === "") {
                        return false;
                    }
                } else if (event.dataField === 'predecessor') {
                    if (event.item.origin !== "Predecessor") {
                        return false;
                    } else if (`${event.item.order}`.includes("-")) {
                        return false;
                    }
                }
            }
        });
    }, [getScrollInfo, handleGridChange]);

    // Cell 수정 후 발생 되는 이벤트
    useEffect(() => {
        // 이벤트 연결
        window.AUIGrid.bind(gridRef.current, ["cellEditEnd"], handleGridChange);

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(gridRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(gridRef.current);// 실제로 그리드에서 삭제 함.

            if (handleDeleteChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(gridRef.current); // 변경된 grid 데이터 가져오기

                handleDeleteChange(event, changeGridData);
            }
        });
    }, [handleDeleteChange, handleGridChange]);

    useEffect(() => {
        //세로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "vScrollChange", (event) => {
            if (getScrollInfo !== undefined) {
                getScrollInfo(event);
            }
        });

        //가로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "hScrollChange", (event) => {
            if (getScrollInfo !== undefined) {
                getScrollInfo(event);
            }
        });
    }, [getScrollInfo]);

    // Cell 클릭 할 때 발생 되는 이벤트
    useEffect(() => {
        // 이벤트 연결
        window.AUIGrid.bind(gridRef.current, ["cellClick"], handleColumnClick);

        //드랍 시작 이벤트 바인딩
        window.AUIGrid.bind(gridRef.current, "dragBegin", (event) => {
            //whereClause 와 sdtm에 ig가 없는 변수 (org_order가 888) 인 데이터만 순서 변경 가능
            if (event.items[0].org_order !== 888 && !`${event.items[0].order}`.includes('-')) {
                return false;
            }
        });

        let beforeChangeGridData = [];
        //dropEndBefore : 행(Row) 드래깅 이 후 드랍 종료 바로 직전에 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "dropEndBefore", (event) => {
            beforeChangeGridData = window.AUIGrid.getGridData(gridRef.current); // 변경된 grid 데이터 가져오기
        });

        //dropEnd : 행(Row) 드래깅 이 후 드랍 종료에 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "dropEnd", (event) => {
            if (handleGridRowChange !== undefined && handleWhereClauseRowChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(gridRef.current); // 변경된 grid 데이터 가져오기
                if (!`${event.items[0].order}`.includes('-')) {
                    handleGridRowChange(changeGridData);
                } else {
                    let noWhereClauseList = [];
                    let result = [];
                    let copyChangeGridData = copyObject(changeGridData);
                    copyChangeGridData.forEach((item) => {
                        if (!`${item.order}`.includes("-")) {
                            noWhereClauseList.push(item);
                        } else {
                            let lastData = noWhereClauseList[noWhereClauseList.length - 1];
                            let whereClauseData = item.order.split("-");
                            let compareWhereOrder = parseInt(whereClauseData[0]);
                            if (lastData.order === compareWhereOrder) {
                                result.push("true");
                            } else {
                                result.push("false");
                            }
                        }
                    });
                    if (result.includes("false")) {
                        //기존 whereClause 에서 다른 whereClause 로 이동 막기
                        setDataList(beforeChangeGridData);
                    } else {
                        handleWhereClauseRowChange(changeGridData);
                    }
                }
            }
        });
    }, [handleColumnClick, handleGridChange, handleGridRowChange, handleWhereClauseRowChange]);

    // AUIGrid 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(gridRef.current, '100%');
    }, [width, height]);

    /**
     *  @memberOf     VariableMetadataList
     *  @constant     {Array} columnLayout
     *  @description  AUIGrid Column 정보
     */
    const columnLayout = [
        {
            dataField: "",
            headerText: "",
            width: "3%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconWidth: 30, // icon 가로 사이즈, 지정하지 않으면 24로 기본값 적용됨
                iconHeight: 30,
                onClick(event) {
                    handleDeleteTableRow(event);
                },
                iconFunction: function (rowIndex, columnIndex, value, item) {
                    if (`${item.order}`.includes("-")) {
                        return deleteIcon;
                    } else {
                        return null;
                    }
                }
            }
        },
        {
            dataField: "order",
            headerText: "No",
            width: "3%",
            editable: false
        },
        {
            dataField: "dataSet",
            headerText: "Dataset",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "mappingVariable",
            headerText: "Variable",
            headerStyle: "aui-grid-header-red",
            width: "8%",
            editable: false,
            style: "text-left",
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "whereClauseList",
            headerText: "Where Clause",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Value Level Metadata Only"
            },
            width: "10%",
            editable: false,
            style: "text-left",
            
        },
        {
            dataField: "label",
            headerText: "Label",
            headerStyle: "aui-grid-header-green",
            width: "10%",
            style: "text-left",
        },
        {
            dataField: "keySequence",
            headerText: "Key</br>Sequence",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "In the context of a regulatory submission, a key must be provided for each data set (ODM/@def:Context=\"Submission\").</br> Variable Metadata Only"
            },
            width: "5%"
        },
        {
            dataField: "dataType",
            headerText: "Data</br>Type",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editRenderer: {
                type: "DropDownListRenderer",
                list: ["Text", "Integer", "Float", "Datetime", "Date", "Time"],
                showEditorBtnOver: true
            },
            filter: {
                showIcon: true,
            },
            showEditorBtnOver: true
        },
        {
            dataField: "length",
            headerText: "Length",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Conditional required if Data Type is \"Text\", \"Integer\" or \"Float\""
            },
            width: "5%",
        },
        {
            dataField: "significantDigits",
            headerText: "Significant</br>Digits",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Conditional required if Data Type is \"Float\"."
            },
            width: "7%",
        },
        {
            dataField: "format",
            headerText: "Format",
            headerStyle: "aui-grid-header-green",
            width: "5%",
        },
        {
            dataField: "mandatory",
            headerText: "Mandatory",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editRenderer: {
                type: "DropDownListRenderer",
                list: YES_OR_NO_LIST,
                keyField: "text", // key 에 해당되는 필드명
                valueField: "value", // value 에 해당되는 필드명
                showEditorBtnOver: true
            },
            labelFunction(rowIndex, columnIndex, value, headerText, item, dataField) {
                //value를 한번 다시 선택하게 되면 string으로 선택이 됨
                if (Number.parseInt(value) === 1) {
                    return "Yes";
                } else {
                    return "No";
                }
            }
        },
        // {
        //     dataField: "assignedValue",
        //     headerText: "Assigned</br>Value",
        //     headerStyle: "aui-grid-header-green",
        //     width: "5%",
        // },
        {
            dataField: "codelist",
            headerText: "Codelist",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "If a variable or value definition includes CDISC Controlled Terminology, a Codelist should be provided."
            },
            width: "7%",
            editable: false
        },
        {
            dataField: "origin",
            headerText: "Origin",
            headerStyle: "aui-grid-header-red",
            width: "7%",
            editRenderer: {
                type: "DropDownListRenderer",
                list: defineVersion == "2.0" ? ["", "CRF", "Assigned", "Derived", "Protocol", "Predecessor"] : ["", "Collected", "Assigned", "Derived", "Protocol", "Predecessor"],
                showEditorBtnOver: true
            },
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "source",
            headerText: "Source",
            headerStyle: "aui-grid-header-blue",
            width: "8%",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Conditional required if Origin is not \"Predecessor\" and \"Not Available\". "
            },
            editRenderer: {
                type: "ConditionRenderer",
                conditionFunction: function (rowIndex, columnIndex, value, item, dataField) {
                    // 특정 조건에 따라 미리 정의한 editRenderer 반환.
                    if (item.origin === "Collected") {
                        return origin_collected;
                    } else if (item.origin === "Derived" || item.origin === "Assigned") {
                        return origin_derived_assigned;
                    } else if (item.origin === "Protocol") {
                        return origin_protocol;
                    } else if (item.origin === "") {
                        return origin_empty;
                    } else {
                        return origin_default;
                    }
                },
                showEditorBtnOver: true
            },
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "pages",
            headerText: "Pages",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Conditional required if Origin is \"Collected\" and Source is \"Investigator\" or \"Subject\"."
            },
            width: "5%",
        },
        {
            dataField: "method",
            headerText: "Method",
            headerStyle: "aui-grid-header-blue",
            headerTooltip: { // 헤더 툴팁 표시 HTML 양식
                show: true,
                tooltipHtml: "Conditional required if Origin is \"Derived\"."
            },
            width: "10%",
            styleFunction : function(rowIndex, columnIndex, value, headerText, item, dataField) {
                if (item.origin !== 'Derived') {
                    return 'gray-bg';
                }
                return null;
            }
        },
        {
            dataField: "predecessor",
            headerText: "Predecessor",
            headerStyle: "aui-grid-header-green",
            width: "10%"
        },
        {
            dataField: "role",
            headerText: "Role",
            headerStyle: "aui-grid-header-green",
            width: "8%",
            editRenderer: {
                type: "ConditionRenderer",
                conditionFunction: function (rowIndex, columnIndex, value, item) {
                    // 특정 조건에 따라 미리 정의한 editRenderer 반환.
                    if (`${item.order}`.includes("-")) {
                        return whereClause_role;
                    } else {
                        return role_dropDown;
                    }
                },
                showEditorBtnOver: true
            },
        },
        {
            dataField: "hasNoData",
            headerText: "Has No</br>Data",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0,
                visibleFunction: function (rowIndex, columnIndex, value, isChecked, item, dataField) {
                    if (`${item.order}`.includes("-"))
                        return false;
                    return true;
                }
            }
        },
        {
            dataField: "comment",
            headerText: "Comment",
            headerStyle: "aui-grid-header-green",
            width: "10%"
        },
        {
            dataField: "developerNotes",
            headerText: "Developer Notes",
            headerStyle: "aui-grid-header-green",
            width: "10%"
        }
    ];

    let collectedList = ["Subject", "Investigator", "Vendor"];
    let derived_assigned_List = ["Vendor", "Sponsor"];
    let protocol_List = ["Sponsor"];
    let all_defaultList = ["Subject", "Investigator", "Vendor", "Sponsor"];
    let empty_List = [""];
    let role_list = ["Identifier", "Topic", "Timing", "Grouping Qualifier", "Result Qualifier", "Synonym Qualifier", "Record Qualifier", "Variable Qualifier", "Rules"];

    let origin_collected = {
        type: "DropDownListRenderer",
        list: collectedList
    };

    let origin_derived_assigned = {
        type: "DropDownListRenderer",
        list: derived_assigned_List
    };

    let origin_protocol = {
        type: "DropDownListRenderer",
        list: protocol_List
    };

    let origin_default = {
        type: "DropDownListRenderer",
        list: all_defaultList
    };

    let origin_empty = {
        type: "DropDownListRenderer",
        list: empty_List
    }

    let whereClause_role = {
        type: "DropDownListRenderer",
        list: empty_List
    }

    let role_dropDown = {
        type: "DropDownListRenderer",
        list: role_list
    }

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>Mapping Table</h2>
                                    <PerfectScrollbar className="height-full"
                                                      options={{suppressScrollX: true}}
                                                      style={{padding: "0 30px 0 30px"}}>
                                        ※ The column name color is Core value(
                                        <font style={{color: "#FFDDDD", fontSize: "30px"}}>■</font> = Required,
                                        <font style={{color: "#DDFFFF", fontSize: "30px"}}>■</font> = Conditional
                                        Required,
                                        <font style={{color: "#EEFFDD", fontSize: "30px"}}>■</font> = Optional)
                                        <div className='grid-style' id="variableMetadata_grid"/>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>

                        {isWhereClauseEditModal && (
                            <WhereClauseEditModal
                                ID={ID}
                                headerText={"Where Clause Edit"}
                                onClose={() => setIsWhereClauseEditModal(!isWhereClauseEditModal)}
                                onModalOk={handleModalOk}
                                readOnly={lock === 2}
                                onScrollChange={getScrollInfo}
                                variableMataDataGridList={modalDataList}
                                variableList={variableList}
                                targetVariable={targetVariable}
                                whereClauseList={whereClauseList}
                                targetDataSet={targetDataSet}
                                targetSubID={targetSubID}
                                whereDataOrder={whereDataOrder}
                            />
                        )}

                        {isVariableCodeListModal && (
                            <VariableCodeListModal
                                ID={ID}
                                headerText={"Codelist Edit"}
                                onClose={() => setIsVariableCodeListModal(!isVariableCodeListModal)}
                                data={currentEditCodeList}
                                onCodeListSave={handleCodeListSave}
                                readOnly={lock === 2}
                                onScrollChange={getScrollInfo}
                            />
                        )}
                    </div>
                </div>
            </div>

            {isMethodModal && (
                <VariableMetadataMethodModal
                    onClose={() => setIsMethodModal(!isMethodModal)}
                    onOk={handleOk}
                    selectedData={currentMethod}
                    parentDataList={dataList}/>
            )}
        </>
    );
};

export default React.memo(VariableMetadataList);