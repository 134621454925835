import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {COMMAND} from "../../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import {AXIOS_GET} from "../../../../../../common/commonFunction";
import AUIGrid from "../../../../../components/AUIGrid";
import CategorySelectBox from "../../CodeLists/CategorySelectBox";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     VariableCodeListModal
 *  @constant     {String} DEFAULT_VALUE
 *  @description  기본 빈 값
 */
const DEFAULT_VALUE = '';

/**
 *  @memberOf     VariableCodeListModal
 *  @constant     {String} CODELIST_NAME
 *  @description  select box field 명 정의
 */
const CODELIST_NAME = {
    CODE_LIST: "codelist"
}

/**
 *  @memberOf     VariableCodeListModal
 *  @constant     {Array} COLUMN_LIST
 *  @description  AUIGrid codeList Column 정보
 */
const COLUMN_LIST = [
    {
        dataField: "codeValue",
        headerText: "Code Value",
        editable: false
    },
    {
        dataField: "NCITermCode",
        headerText: "NCI Term<br/>Code",
        editable: false
    },
    {
        dataField: "decodedValue",
        headerText: "Decoded Value",
        width: "40%",
        editable: false
    },
    {
        dataField: "comment",
        headerText: "Comment",
        width: "30%",
        editable: false
    }
];

/**
 *  @memberOf     VariableCodeListModal
 *  @constant     {Array} column_list
 *  @description  AUIGrid external codeList Column 정보
 */
const column_list = [
    {
        dataField: "externalID",
        headerText: "ID",
        editable: false,
        wrapText: true,
    },
    {
        dataField: "name",
        headerText: "Name",
        editable: false,
        wrapText: true,
    },
    {
        dataField: "dataType",
        headerText: "Data Type",
        editable: false

    },
    {
        dataField: "dictionary",
        headerText: "Dictionary",
        editable: false

    },
    {
        dataField: "version",
        headerText: "Version",
        editable: false
    },
];


/**
 *  @author       김한나
 *  @version      1.0
 *  @component    VariableCodeListModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  codeList 항목의 클릭 시 실행되는 모달
 */
const VariableCodeListModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     VariableCodeListModal
     *  @type         {Object} props
     *  @property     {String} ID -ID 값
     *  @property     {Function} onClose - WhereClauseEdit Modal의 No 버튼 상태
     *  @property     {Object} onCodeListSave - 상위로 선택 된 Function 전달 하기 위한 함수
     *  @property     {Array} data - 선택 된 줄의 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        onClose,
        onCodeListSave,
        data
    } = props;

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Array} allCodeList
     *  @description  서버에서 조회한 전체 CodeList 목록
     */
    const [allCodeList, setAllCodeList] = useState([]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Array} codeListID
     *  @description  dropdown 리스트에 보여줄 codelist 목록
     */
    const [codeListID, setCodeListID] = useState([]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Array} dataList
     *  @description  grid 화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Array} externalDataList
     *  @description  grid 화면에 표시 될  external codeList 리스트
     */
    const [externalDataList, setExternalDataList] = useState([]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Array} currentType
     *  @description  grid 화면에 표시 될  현재 선택된 External CodeList / CodeList 여부
     */
    const [currentType, setCurrentType] = useState("");

    /**
     *  @memberOf     VariableCodeListModal
     *  @var          {Object} dataInfo
     *  @description  수정 될 데이터
     */
    const [dataInfo, setDataInfo] = useState(data);

    /**
     *  @memberOf       VariableCodeListModal
     *  @async          dataProcess
     *  @param          {String} command - 통신 데이터 처리 action type
     *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
     *  @return         {Object} response.data - 서버 응답 데이터
     *  @description    command 에 따른 통신 데이터 처리
     */
    async function dataProcess(command, params) {
        const {requestUrl, ID} = params;
        let url = null;
        let response = null;

        switch (command) {
            // 데이터 상세 정보 요청
            case COMMAND.DATA_LIST:
                url = `${requestUrl}/codelist/${ID}`;
                response = await AXIOS_GET(url);
                break;

            default:
                return null;
        }
        return response.data;
    }

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     VariableCodeListModal
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID
        };
        params.requestUrl = SDTM_MAPPING_URL;

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @function     handleChange
     *  @param        {String} name   -  element name
     *  @param        {String} value  -  element value
     *  @description  select box 변경 시 실행되는 함수
     */
    const handleChange = useCallback((name, value) => {
        if (value !== "") {
            //전체 코드리스트 정보의 ID Tree 메뉴에서 선택한 ID와 같은 것만 filter
            let _dataList = allCodeList.codeList.filter(code => code.terminologyID === value);//codeList
            let _externalDataList = allCodeList.externalCodeList.filter(code => code.externalID === value); //external codeList

            codeListID.forEach((el) => {
                if (el.text === value) {
                    setCurrentType(el.type);
                    if (el.type === "normal") {
                        setDataInfo({...dataInfo, codelist: _dataList[0].terminologyID});
                        setDataList(_dataList);
                    } else if (el.type === "external") {
                        setDataInfo({...dataInfo, codelist: _externalDataList[0].externalID});
                        setExternalDataList(_externalDataList);
                    }
                }
            });


        } else {
            setDataList([]);
            setDataInfo({...dataInfo, codelist: ""});
        }
    }, [allCodeList.codeList, allCodeList.externalCodeList, codeListID, dataInfo]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @function     handleSave
     *  @description  OK 버튼 클릭 시 실행되는 함수
     */
    const handleSave = useCallback(() => {
        if (onCodeListSave !== undefined) {
            onCodeListSave(dataInfo);
        }
    }, [dataInfo, onCodeListSave]);

    /**
     *  @memberOf     VariableCodeListModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;
            switch (command) {
                case COMMAND.DATA_LIST :
                    if (data.code === 1) { // 결과가 성공인 경우
                        let _dataList = []; //Grid에 뿌려질 리스트 변수

                        let existedCodeID = [];
                        let _externalDataList = [];


                        data.data.externalCodeList
                            .filter(item => item.used === 1) // select box에서 해당 item 옵션 제외 (used:0 사용 안 함/used:1 사용함)
                            .forEach(item => {
                                if (!existedCodeID.includes(item.externalID)) {
                                    existedCodeID.push({data: "External CodeList", type: "External CodeList"})
                                    existedCodeID.push({data: item.externalID, type: "external"});
                                } else {
                                    return;
                                }
                            });

                        data.data.codeList
                            .filter(item => item.used === 1) // select box에서 해당 item 옵션 제외 (used:0 사용 안 함/used:1 사용함)
                            .forEach(item => {
                                if (!existedCodeID.includes(item.terminologyID)) {
                                    existedCodeID.push({data: "CodeList", type: "CodeList"})
                                    existedCodeID.push({data: item.terminologyID, type: "normal"});
                                } else {
                                    return;
                                }
                            });

                        let codeListID = existedCodeID.map((data) => {
                            return {
                                text: data.data,
                                value: data.data,
                                type: data.type
                            }
                        });

                        //중복 값 제거
                        let uniqueCodeList = codeListID.filter((v, i) => codeListID.findIndex(x => x.text === v.text) === i);

                        //빈값 추가
                        uniqueCodeList.push({text: "", value: "", type: ""});

                        setCodeListID(uniqueCodeList);
                        setAllCodeList(data.data);

                        //current Type
                        codeListID.forEach((data) => {
                            if (dataInfo[CODELIST_NAME.CODE_LIST] !== "" && dataInfo[CODELIST_NAME.CODE_LIST] === data.text) {
                                setCurrentType(data.type);
                            }
                        });

                        //전체 코드리스트 정보의 ID Tree 메뉴에서 선택한 ID와 같은 것만 filter
                        _dataList = data.data.codeList.filter(code => code.terminologyID === dataInfo[CODELIST_NAME.CODE_LIST]);
                        _externalDataList = data.data.externalCodeList.filter(code => code.externalID === dataInfo[CODELIST_NAME.CODE_LIST]);
                        setDataList(_dataList);
                        setExternalDataList(_externalDataList);
                    } else {
                        setAllCodeList([]);
                        setDataList([]);
                    }
                    break;

                default:
                    break;
            }
        }
    }, [dataInfo]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [ID, getDataList]);
    /*#################################################################
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse}/>
            <Modal isOpen className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    Codelist Edit
                </ModalHeader>

                <ModalBody>
                    <div className="m-b-20">
                        <CategorySelectBox
                            dataList={codeListID}
                            onChange={handleChange}
                            defaultValue={DEFAULT_VALUE}
                            currentValue={dataInfo[CODELIST_NAME.CODE_LIST]}/>
                    </div>

                    {currentType === "external" &&
                    <AUIGrid
                        ref={auiGrid}
                        id="variable-modal-grid"
                        columnList={column_list}
                        height={"40"}
                        minusContentHeight={236}
                        isWordWrap={true}
                        dataList={externalDataList}
                        isShowRowNum={true}/>
                    }

                    {currentType === "normal" &&
                    <AUIGrid
                        ref={auiGrid}
                        id="variable-modal-grid"
                        columnList={COLUMN_LIST}
                        height={"40"}
                        minusContentHeight={236}
                        isWordWrap={true}
                        dataList={dataList}
                        isShowRowNum={true}/>
                    }
                </ModalBody>

                <ModalFooter>
                    <button
                        className="btn btn-new btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleSave}>
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(VariableCodeListModal);