import React from "react";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFCover
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Cover 컴포넌트
 */
const ACRFCover = (props) => {
    /**
     *  @memberOf     ACRFCover
     *  @type         {Object} props
     *  @property     {Object} coverInfo - cover에 표시 될 상위에서 전달받은 데이터
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {coverInfo, display, isCompareCover} = props;

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            style={{
                display: `${display || 'none'}`,
                pageBreakBefore: "always",
                fontFamily: "Arial, sans-serif",
                textAlign: "center",
            }}>
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: isCompareCover ? "80px 200px 180px 50px 50px 50px 55px 150px" : "80px 250px 250px 60px 60px 60px 80px 150px"
                }}>
                {/*상단*/}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: "1px solid #000",
                        padding: "20px 10px"
                    }}>
                    <div style={{flex: 1}}/>

                    <div
                        style={{
                            flex: 1,
                            fontSize: "15pt",
                            fontWeight: "800",
                            textAlign: "center",
                            alignSelf: "center",
                            padding: "20px"
                        }}>
                        CRF Modules
                    </div>

                    <div
                        style={{
                            flex: 1,
                            fontSize: "9pt",
                            textAlign: "right",
                            alignSelf: "end"
                        }}>
                        Protocol No : <span
                        style={{backgroundColor: coverInfo.diffObj?.protocolNo ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.protocolNo}</span>
                    </div>
                </div>

                <div
                    style={{
                        fontSize: "20pt",
                        fontWeight: "800",
                        alignSelf: "center"
                    }}>
                    <span
                        style={{backgroundColor: coverInfo.diffObj?.protocolNo ? coverInfo?.diffObj?.compareColor : ""}}>CRF Modules</span>
                </div>

                <div style={{fontSize: "13pt", lineHeight: "180%", padding: "0 30px"}}>
                    <span
                        style={{backgroundColor: coverInfo.diffObj?.studyTitle ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.language === "en" ? coverInfo.studyTitleEN : coverInfo.studyTitleKR}</span>
                </div>

                <div style={{fontSize: "13pt", alignSelf: "center"}}>
                    Protocol No : <span
                    style={{backgroundColor: coverInfo.diffObj?.protocolNo ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.protocolNo}</span>
                </div>

                <div style={{fontSize: "13pt", alignSelf: "center"}}>
                    Protocol Ver : <span
                    style={{backgroundColor: coverInfo.diffObj?.protocolVersion ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.protocolVersion}</span>
                </div>

                <div style={{fontSize: "13pt", alignSelf: "center"}}>
                    CRF Modules ver. : <span style={{
                    color: coverInfo.version === 'draft' ? 'red' : ''
                }}><span
                    style={{backgroundColor: coverInfo.diffObj?.version ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.version}</span></span>
                </div>
                <div style={{fontSize: "13pt", alignSelf: "center"}}>
                    Effective date : <span style={{
                    color: coverInfo.effectiveDate === 'Date the final approver signed' ? 'red' : ''
                }}><span
                    style={{backgroundColor: coverInfo.diffObj?.effectiveDate ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.effectiveDate}</span></span>
                </div>

                <div
                    style={{
                        fontSize: "18pt",
                        fontWeight: "700",
                        alignSelf: "center",
                        paddingTop: "50px"
                    }}>
                    <span
                        style={{backgroundColor: coverInfo.diffObj?.protocolNo ? coverInfo?.diffObj?.compareColor : ""}}>{coverInfo.sponsor}</span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ACRFCover);