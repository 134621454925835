import React, { useCallback, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { AXIOS_GET } from "common/commonFunction";
import { COMMAND } from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import { SEARCH_TYPE } from "constant/CommonConstant";
import { LOCK_STATE_OPTION, PHASE_OPTION, TA_OPTION } from "constant/ConstantList";
import { DESIGN_SYNOPSIS_URL } from "constant/ConstantURL";
import AUIGrid from "../../../components/AUIGrid";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ReferencePopupTab
 *  @constant     {String} SEARCH_SHARE_FIELD_NAME
 *  @description  일반, 상세 검색에 공용적으로 사용되는 필드 이름
 */
const SEARCH_SHARE_FIELD_NAME = 'name';

/**
 *  @memberOf     ReferencePopupTab
 *  @constant     {String} SEARCH_PLACEHOLDER
 *  @description  일반 검색에 사용되는 PLACEHOLDER
 */
const SEARCH_PLACEHOLDER = 'Brief Study Title';

/**
 *  @memberOf     ReferencePopupTab
 *  @constant     {Array} SEARCH_OBJECT
 *  @description  검색 필터에 사용되는 필터 데이터들
 */
const SEARCH_OBJECT = [
    {
        object: [
            {
                type: SEARCH_TYPE.SELECT_BOX,
                name: 'phase',
                label: 'Trial Phase',
                value: '',
                option: PHASE_OPTION
            },
            {
                type: SEARCH_TYPE.TEXT_FIELD,
                name: SEARCH_SHARE_FIELD_NAME,
                label: SEARCH_PLACEHOLDER,
                value: ''
            },
            {
                type: SEARCH_TYPE.TEXT_FIELD,
                name: 'sponsor',
                label: 'Sponsor',
                value: ''
            },
            {
                type: SEARCH_TYPE.TEXT_FIELD,
                name: 'designName',
                label: 'Design Name',
                value: ''
            },
        ]
    },
    {
        object: [
            {
                type: SEARCH_TYPE.SELECT_BOX,
                name: 'ta',
                label: 'Therapeutic Areas',
                value: '',
                option: TA_OPTION
            },
            {
                type: SEARCH_TYPE.TEXT_FIELD,
                name: 'pno',
                label: 'Protocol Number',
                value: '',
            },
            {
                type: SEARCH_TYPE.TEXT_FIELD,
                name: 'version',
                label: 'Version',
                value: ''
            },
            {
                type: SEARCH_TYPE.SELECT_BOX,
                name: 'state',
                label: 'Lock',
                value: '',
                option: LOCK_STATE_OPTION
            }
        ]
    },
    {
        object: [
            {
                type: SEARCH_TYPE.DATE_PICKER,
                name: 'cdate',
                label: 'Created Time',
                value: '',
            },
            {
                type: SEARCH_TYPE.DATE_PICKER,
                name: 'ldate',
                label: 'Lock Time',
                value: '',
            }
        ]
    },
];

/**
 *  @memberOf     ReferencePopupTab
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, dataID} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 목록 요청
        case COMMAND.DATA_LIST :
            url = `${requestUrl}/reference/${dataID}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.FILE_DOWNLOAD :
            url = `${requestUrl}/reference/download/${dataID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @component    ReferencePopupTab
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  reference 팝업 내 reference tab 화면 컴포넌트
 */
const ReferencePopupTab = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     ReferencePopupTab
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} studyID - study 고유 ID
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, studyID} = props;

    /**
     *  @memberOf     ReferencePopupTab
     *  @var          {*} networkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    ////////////////////////////////////////////////////////////////
    // list 관련
    ////////////////////////////////////////////////////////////////
    /**
     *  @memberOf     ReferencePopupTab
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ReferencePopupTab
     *  @function     handleDownload
     *  @param        {String} referenceID - 클릭한 리스트 id
     *  @description  다운로드 버튼 클릭 시 실행되어 다운로드 api 호출
     */
    const handleDownload = useCallback((referenceID) => {
        // 데이터 리스트 요청 command
        const command = COMMAND.FILE_DOWNLOAD;
        // 페이지 이동시 필요한 parameter
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            dataID: referenceID,
        };

        // back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     ReferencePopupTab
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "category",
            headerText: "Category",
            width: "45%",
            editable: false
        },
        {
            dataField: "name",
            headerText: "User Guide Name",
            width: "45%",
            editable: false
        },
        {
            dataField: "file", headerText: "File", width: "10%",
            renderer: {
                type: "ButtonRenderer",
                labelText: "Download",
                onClick: (e) => {
                    handleDownload(e.item.ID)
                }
            }
        },
    ];

    /**
     *  @memberOf     Concept
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;
            // dataList 응답에 대한 처리
            if (command === COMMAND.DATA_LIST) {
                // dataList 데이터가 있는 경우
                if (data.hasOwnProperty('data')) {
                    setDataList(data.data);
                } else {
                    setDataList([]);
                }
            } else if (command === COMMAND.FILE_DOWNLOAD) {
                if (data.data.hasOwnProperty('file')) {
                    const url = window.URL.createObjectURL(new Blob([new Uint8Array(data.data.file.data)]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${data.data.fileName}`);
                    link.style.cssText = 'display:none';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // back end 데이터 리스트 호출
    useEffect(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            dataID: studyID,
            inPage: 1,
            searchObject: undefined,
        };
        //  back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, [studyID]);

    // 검색 오브젝트 데이터 셋팅
    useEffect(() => {
        const searchDataTable = {};
        if (SEARCH_OBJECT !== undefined) {
            for (const item of SEARCH_OBJECT) {
                for (const obj of item.object) {
                    searchDataTable[obj.name] = obj.value;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light m-t-30" style={{height: "calc(100vh - 85px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div className="vertical-box-cell">
                            <div className="vertical-box-inner-cell">
                                <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                                    <AUIGrid columnList={COLUMN_LIST} dataList={dataList}/>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(ReferencePopupTab);
