import React, {useCallback, useEffect, useRef, useState} from 'react';
import {COMMAND} from "../../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import {AXIOS_GET, copyObject, getSessionState} from "../../../../../../common/commonFunction";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AUIGrid from "../../../../../components/AUIGrid";
import SelectBox from "../../../../../components/SelectBox";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/**
 *  @memberOf     VariableMetadataMethodModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = requestUrl;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    VariableMetadataMethodModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  Mapping의 Variable 탭 안에 Variable Metadata의 Method 편집 Modal 컴포넌트
 */
const VariableMetadataMethodModal = props => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Function} onClose - 닫기 버튼 클릭 시 실행 될 상위 이벤트 함수
     *  @property     {Function} onOk - OK 버튼 클릭 시 실행 될 상위 이벤트 함수
     *  @property     {String} selectedData - 선택된 Method 컬럼에있는 정보 값
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, onClose, onOk, selectedData, parentDataList} = props;

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {Array} selectedMethodList
     *  @description  선택된 Method 컬럼에있는 value 값 Array로 변경하여 저장한 목록
     */
    const [selectedMethodList] = useState(selectedData.value === "" ? [] : selectedData.value.split(", "));

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {Array} selectBoxData
     *  @description  실렉트 박스에 표시될 전체 데이터
     */
    const [selectBoxData, setSelectBoxData] = useState();

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {String} currData
     *  @description  선택한 실렉트 박스 항목
     */
    const [currData, setCurrData] = useState(selectedData.item.method);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @var          {Array} postData
     *  @description  실렉트 박스 선택한 뒤, 표시될 데이터
     */
    const [postData, setPostData] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     getDataList
     *  @param        {Object} params - API 통신에 필요한 parameter 값들
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/functionList/${ID}?used=1`
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value, event) => {
        let _dataList = dataList; //grid 데이터 가지고 옴
        const newRowIndex = _dataList.findIndex(e => e.OID === event.item.OID)
        _dataList[newRowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     handleOk
     *  @description  OK 버튼 클릭 실행되는 함수
     */
    const handleOk = useCallback(() => {
        if (onOk !== undefined) {
            const rowIndex = parentDataList.findIndex(e => e.ID === selectedData.item.ID);
            onOk(rowIndex, currData)
            handleClose();
        }
    }, [dataList, handleClose, onOk, selectedData.rowIndex, currData]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let _dataList = [];

                    data.data.forEach(data => {
                        let findObj = selectedMethodList.find(method => method === data.OID); //selected 된 method 이름을 비교하여 데이터를 찾음

                        _dataList.push({
                            checked: findObj !== undefined ? 1 : 0, //찾은 데이터가 있다면 check시킴
                            OID: data.OID,
                            name: data.name,
                            description: data.description
                        });
                    });

                    setPostData(_dataList.filter(e => e.OID === currData));
                    setDataList(_dataList);
                    break;

                //no default
            }
        }
    }, [selectedMethodList]);

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @function     handleChange
     *  @param        {string} name   -  element name
     *  @param        {string} value  -  element value
     *  @return       none
     *  @description  selectBox 변경시 실행
     */
    const handleChange = useCallback((name, value) => {
        setCurrData(value);
    }, [dataList]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    // 실렉트 박스 선택시 표시할 데이터 넣기
    useEffect(() => {
        setPostData(dataList.filter(e => e.OID === currData))
    }, [currData])

    // 실렉트 박스 데이터 생성
    useEffect(() => {
        const _dataList = copyObject(dataList);
        const _selectBoxData = [];
        _selectBoxData.push({
            text: "",
            value: "",
        })
        _dataList.forEach((e) => {
            const _temp = {
                text: e.OID,
                value: e.OID,
            }
            _selectBoxData.push(_temp)
        })

        _selectBoxData.sort((a, b) => a.text.localeCompare(b.text))
        setSelectBoxData(_selectBoxData)
    }, [dataList])

    /**
     *  @memberOf     VariableMetadataMethodModal
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "OID",
            headerText: "ID",
            editable: false
        },
        {
            dataField: "name",
            headerText: "Name",
            editable: false,
            width: "10%"
        },
        {
            dataField: "description",
            headerText: "Description",
            editable: false,
            width: "70%",
            style: "cell-left-align",
            renderer: {
                type: "TemplateRenderer"  // HTML 템플릿 렌더러 사용
            },
            labelFunction(rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
                if (!value) {
                    return "";
                }

                return `<div style='font-size: 12px; margin: 0; white-space: pre-wrap'>${value}</div>`;
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen className="modal-md" style={{maxWidth: "900px"}}>
                <ModalHeader
                    className={'header-title bg-main'}
                    toggle={handleClose}>
                    Method
                </ModalHeader>

                <ModalBody>
                    <div className={'pb-3'}>
                        <SelectBox
                            onChange={handleChange}
                            currentValue={currData}
                            dataList={selectBoxData}
                        />
                    </div>
                    <AUIGrid
                        columnList={COLUMN_LIST}
                        dataList={postData}
                        isWordWrap={true}
                        scrollInfo={{vertical: vertical, horizontal: horizontal}}
                        onEdit={handleGridChange}
                        onScrollChange={getScrollInfo}/>
                </ModalBody>

                <ModalFooter className="p-3">
                    <button
                        className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    {lock !== 2 && (
                        <button
                            className="btn btn-new btn-inner-shadow text-white px-3 px-md-5 mx-1"
                            onClick={handleOk}>
                            Ok
                        </button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default VariableMetadataMethodModal;