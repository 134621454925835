import {useAlert} from "react-alert";
import {ALERT_TIME, COMPLETE_MSG} from "constant/CommonConstant";
import {ALERT_TYPE} from "constant/ConstantName";

/**
 *  @type      {function(*): *}
 *  @function  useToast toast 메시지 표사
 *  @param     {Object}  callback   - 처리되는 함수
 *  @param     {Object}  history    - 페이지 이동을 위한 history
 *  @return    {Object}  응답 state
 *  @return    {function} 요청 함수
 */
function useToast() {

  // alert 창 구현
  const alertPopup = useAlert();

  /**
   *  @function     showToast
   *  @description  Toast 메시지 표시
   */
  const showToast = ( callback = null, type = ALERT_TYPE.SUCCESS, msg = COMPLETE_MSG ) => {

    switch (type) {
      case ALERT_TYPE.SUCCESS:
        alertPopup.success( msg ,{
          timeout: ALERT_TIME ,  // custom timeout just for this one alert
          onClose: () => {
            if(callback !== null){
              callback();
            }
          }
        });
        break;

      case ALERT_TYPE.ERROR:
        alertPopup.error( msg ,{
          timeout: ALERT_TIME ,  // custom timeout just for this one alert
          onClose: () => {
            if(callback !== null){
              callback();
            }
          }
        });
        break;

      default:
        alertPopup.success( msg ,{
          timeout: ALERT_TIME ,  // custom timeout just for this one alert
          onClose: () => {
            if(callback !== null){
              callback();
            }
          }
        });
        break;
    }
  };
  return [showToast];
}

export default useToast;

