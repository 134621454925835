import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT, getSessionState, handleExamplePopup} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import deleteIcon from "../../../../../../assets/icons/close.svg"
import AUIGrid from "../../../../../components/AUIGrid";
import DomainExplain from "../../../../../components/DomainExplain";
import {showSimpleAlert} from "../../../../../components/alertModal";

/* 전역 변수 */
// 스크롤 위치 조정 변수
let vertical = 0;
let horizontal = 0;
// 행 추가 모드(Add 버튼 or 행 우클릭) 구분 변수
let addMode = ''; // Add 버튼 : BUTTON, 행 우클릭 : RIGHT_CLICK

// =============================== //
//  constant 관련
// =============================== //
/**
 *  @memberOf     TSDomainList
 *  @constant     {Object} ADD_MODE
 *  @description  행 추가 모드(Add 버튼 or 행 우클릭) 구분 변수
 */
const ADD_MODE = {
    BUTTON: 'BUTTON', // Add 버튼으로 행 추가
    RIGHT_CLICK: 'RIGHT_CLICK', // 행 우클릭으로 행 삽입
};

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    TSDomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TS 도메인 컴포넌트
 */
const TSDomainList = (props) => {
    /**
     *  @memberOf     TSDomainList
     *  @async        dataProcess
     *  @param        {String} command - 통신 데이터 처리 action type
     *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
     *  @return       {Object} response.data - 서버 응답 데이터
     *  @description  command 에 따른 통신 데이터 처리
     */
    async function dataProcess(command, params) {
        const {requestUrl, sendObject} = params;
        let url = requestUrl || `${SDTM_MAPPING_URL}/tdomain`;
        let response = null;
        let domain = 'TS'; // TS 도메인 리스트 컴포넌트이기 때문에 domain 은 TS 로 고정

        switch (command) {
            // TS 도메인 목록 가져오기
            // 포스트맨 경로 : 2021.Dev.4.SDTM>Mapping>Tx Domain>Tx 도메인 목록 가져오기
            case COMMAND.DATA_LIST:
                url = `${url}/${ID}?domain=${domain}`;
                response = await AXIOS_GET(url);
                break;

            // TSPARMCD 목록 가져오기
            // 포스트맨 경로 : 2021.Dev.4.SDTM>Mapping>Tx Domain>TSPARMCD 목록 가져오기
            case 'TSPARMCD_LIST':
                url = `${url}/TSPARMCD/${ID}`;
                response = await AXIOS_GET(url);
                break;

            // TS 도메인 수정하기
            // 포스트맨 경로 : 2021.Dev.4.SDTM>Mapping>Tx Domain>TS 도메인 수정하기
            case COMMAND.DATA_UPDATE:
                url = `${url}/${domain}/${ID}`;
                response = await AXIOS_PUT(url, sendObject);
                break;

            default:
                return null;
        }

        return response.data;
    }

    // =============================== //
    //  data 영역
    //  - props, state
    // =============================== //
    /**
     *  @memberOf     TSDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     TSDomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - Mapping ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     TSDomainList
     *  @var          {*} networkAgent
     *  @description  통신 관련된 처리를 해주는 agent 컴포넌트
     */
    const networkAgent = useRef(null);

    /**
     *  @memberOf     TSDomainList
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     TSDomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     TSDomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     TSDomainList
     *  @var          {Array} TSPARMObjectArray
     *  @description  TSPARMCD 오브젝트 배열
     */
    const [TSPARMObjectArray, setTSPARMObjectArray] = useState({});

    /**
     *  @memberOf     TSDomainList
     *  @var          {Array} TSPARMCDList
     *  @description  화면에 표시 될 TSPARMCD 리스트
     */
    const [TSPARMCDList, setTSPARMCDList] = useState([]);

    /**
     *  @memberOf     TSDomainList
     *  @var          {Array} TSPARMList
     *  @description  화면에 표시 될 TSPARM 리스트
     */
    const [TSPARMList, setTSPARMList] = useState([]);

    /**
     *  @memberOf     TSDomainList
     *  @var          {String} studyID
     *  @description  STUDYID 컬럼에 표시하기 위한 값
     */
    const [studyID, setStudyID] = useState('');

    /**
     *  @memberOf     TSDomainList
     *  @var          {String} description
     *  @description  Description for SDRG 입력창에 입력된 값
     */
    const [description, setDescription] = useState('');

    /**
     *  @memberOf     TSDomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "5%",
            editable: false
        },
        {
            dataField: "FDADesired",
            headerText: "FDA Desired",
            width: "7%",
            // editable: false
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: ['Y', 'Conditional'],
                showEditorBtnOver: true
            },
        },
        {
            dataField: "STUDYID",
            headerText: "STUDYID",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "domain",
            headerText: "DOMAIN",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "TSSEQ",
            headerText: "TSSEQ",
            headerStyle: "aui-grid-header-red",
            width: "5%"
        },
        {
            dataField: "TSGRPID",
            headerText: "TSGRPID",
            headerStyle: "aui-grid-header-green",
            width: "5%",
            editable: lock !== 2
        },
        {
            dataField: "TSPARMCD",
            headerText: "TSPARMCD",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: TSPARMCDList,
                showEditorBtnOver: true,
                autoCompleteMode: true
            },
        },
        {
            dataField: "TSPARM",
            headerText: "TSPARM",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: TSPARMList,
                showEditorBtnOver: true,
                autoCompleteMode: true
            },
        },
        {
            dataField: "TSVAL",
            headerText: "TSVAL",
            headerStyle: "aui-grid-header-blue",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "TSVALNF",
            headerText: "TSVALNF",
            headerStyle: "aui-grid-header-green",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "TSVALCD",
            headerText: "TSVALCD",
            headerStyle: "aui-grid-header-blue",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "TSVCDREF",
            headerText: "TSVCDREF",
            headerStyle: "aui-grid-header-blue",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "TSVCDVER",
            headerText: "TSVCDVER",
            headerStyle: "aui-grid-header-blue",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "",
            headerText: "",
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
                iconHeight: 16,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteRow(event);
                    }
                }
            }
        }
    ];

    // =============================== //
    //  function define 영역
    //  - useCallback
    // =============================== //
    /**
     *  @memberOf     TSDomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {};
        networkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     getTSPARMCDDataList
     *  @description  TSPARMCD 리스트 요청 함수
     */
    const getTSPARMCDDataList = useCallback(() => {
        const command = 'TSPARMCD_LIST';
        const params = {};
        networkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     handleAddRow
     *  @description  Add 버튼 클릭시 호출 되는 함수(그리드 마지막에 행 추가)
     */
    const handleAddRow = useCallback(() => {
        addMode = ADD_MODE.BUTTON; // Add 버튼으로 추가된 경우

        if (lock !== 2) {
            let item = {};
            COLUMN_LIST.forEach((column) => {
                item[column.dataField] = '';
            });
            item.STUDYID = studyID;
            item.TSSEQ = 1;

            const params = {
                num: 1,
                item: item,
                dataList: dataList
            };

            auiGrid.current.request(params);
        }
    }, [COLUMN_LIST, dataList, lock, studyID]);

    /**
     *  @memberOf     TSDomainList
     *  @constant     {Array} handleContextMenu
     *  @description  행 우클릭 시 호출 되는 함수(특정 위치에 행 삽입)
     */
    const handleContextMenu = useCallback((event) => {
        addMode = ADD_MODE.RIGHT_CLICK; // 우클릭으로 추가된 경우

        /* TSPARMCD 필드에서만 상호작용 가능 */
        if (event.dataField === 'TSPARMCD' && !!event.item.TSPARMCD) {
            let _contextMenu = ['Add a row with the same TSPARMCD'];
            let contextMenu = [];
            _contextMenu.forEach((item) => {
                contextMenu.push({
                    label: `${item} ($value)`,
                    callback: (event) => handleContextItem(event) // label 에 $value 를 설정하면 마우스가 있는 곳의 value 값으로 대체
                });
            });

            const handleContextItem = (event) => {
                let _maxTSSEQ = 0;
                let _dataList = dataList.filter((item) => item.TSPARMCD === event.item.TSPARMCD);
                _dataList.forEach((item) => {
                    if (parseInt(item.TSSEQ) > _maxTSSEQ) {
                        _maxTSSEQ = parseInt(item.TSSEQ);
                    }
                });

                const {STUDYID, TSPARMCD, TSPARM} = event.item;
                let item = {};
                COLUMN_LIST.forEach((column) => {
                    item[column.dataField] = '';
                });
                item.STUDYID = STUDYID;
                item.TSPARMCD = TSPARMCD;
                item.TSPARM = TSPARM;
                item.TSSEQ = _maxTSSEQ + 1;

                const params = {
                    num: 7,
                    e: event,
                    item: item,
                    dataList: dataList
                };

                auiGrid.current.request(params);
            }

            return contextMenu;
        } else {
            return false;
        }
    }, [COLUMN_LIST, dataList]);

    /**
     *  @memberOf     TSDomainList
     *  @function     handleDeleteRow
     *  @description  X 버튼 클릭시 호출 되는 함수. TS 도메인 한줄 삭제.
     */
    const handleDeleteRow = useCallback((e) => {
        let _dataList = window.AUIGrid.getOrgGridData('#grid_wrap'); //grid 데이터 가지고 옴
        _dataList.splice(e.rowIndex, 1); //선택 된 index 삭제

        setDataList(_dataList);
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     gridResponse
     *  @param        {Array} list - 그리드 목록
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((list) => {
        if (list) {
            let _dataList = [];

            list.forEach((row, idx) => {
                _dataList.push({
                    ...row,
                    row: (idx + 1),
                    domain: "TS"
                });
            });

            setDataList(_dataList);
        }
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescription(e.target.value);
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     TSDomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
                if (lock !== 2) {

                    let alertRedundantTSSEQ = [];
                    let alertMoreTSSEQ = [];

                    let _TSPARMCDObject = {};
                    dataList && dataList.forEach((item) => {
                        if (_TSPARMCDObject[item.TSPARMCD]) {
                            _TSPARMCDObject[item.TSPARMCD].push(item.TSSEQ);
                        } else {
                            _TSPARMCDObject[item.TSPARMCD] = [item.TSSEQ];
                        }
                    });

                    let _TSPARMCDList = Object.keys(_TSPARMCDObject);
                    _TSPARMCDList.forEach((TSPARMCD) => {
                        let _TSSEQList = [];
                        _TSPARMCDObject[TSPARMCD].forEach((item) => {
                            if (_TSSEQList.includes(item)) {
                                alertRedundantTSSEQ.push(TSPARMCD);
                            }
                            _TSSEQList.push(item);
                        });
                        if (_TSSEQList.length < Math.max(..._TSSEQList)) {
                            alertMoreTSSEQ.push(TSPARMCD);
                        }
                    })

                    if (alertRedundantTSSEQ.length > 0) {
                        alertRedundantTSSEQ.forEach((item, index) => {
                            if (item === '') {
                                alertRedundantTSSEQ[index] = 'empty TSPARMCD';
                            }
                        });
                        showSimpleAlert(`Duplicated TSSEQ value exists in ${alertRedundantTSSEQ}.`);
                    } else if (alertMoreTSSEQ.length > 0) {
                        alertMoreTSSEQ.forEach((item, index) => {
                            if (item === '') {
                                alertMoreTSSEQ[index] = 'empty TSPARMCD';
                            }
                        });
                        showSimpleAlert(`A TSSEQ value greater than the number of same TSPARMCD exists in ${alertMoreTSSEQ}.`);
                    }

                    const command = COMMAND.DATA_UPDATE;
                    let params;
                    let _dataList = [...dataList];

                    _dataList.forEach((data, idx) => {
                        // data.STUDYID = designID;
                        data.order = idx;
                    });

                    let sendObj = {
                        data: _dataList,
                        description: description
                    };

                    // 데이터 생성에 필요한 parameter
                    params = {
                        sendObject: JSON.stringify(sendObj)
                    };

                    networkAgent.current.request(command, params); // back-end 데이터 처리 요청
                }
            }
            ,
            [dataList, description, lock]
        )
    ;

    /**
     *  @memberOf     TSDomainList
     *  @function     handleCellEditEnd
     *  @param        {Object} event - 이벤트
     *  @description  셀 편집 처리 함수
     */
    const handleCellEditEnd = useCallback((event) => {
        if (lock !== 2) {
            let _dataList = dataList;

            if (event.dataField === 'TSPARMCD') {
                let item = event.item;
                item.TSPARMCD = event.item.TSPARMCD.toUpperCase();
                let _maxTSSEQ = 0;
                let filteredDataList = dataList.filter((item) => item.TSPARMCD === event.item.TSPARMCD);
                filteredDataList.forEach((item) => {
                    if (parseInt(item.TSSEQ) > _maxTSSEQ) {
                        _maxTSSEQ = parseInt(item.TSSEQ);
                    }
                });
                item.TSSEQ = _maxTSSEQ + 1;
                item.TSPARM = TSPARMObjectArray[item.TSPARMCD] || '';
                const params = {
                    num: 8,
                    e: event,
                    item: item,
                    dataList: dataList
                };

                auiGrid.current.request(params);
                _dataList[event.rowIndex] = event.item;

            } else if (event.dataField === 'TSPARM') {
                let item = event.item;
                let TSPARMCDObjectArray = Object.fromEntries(Object.entries(TSPARMObjectArray).map(([key, value]) => [value, key]));
                item.TSPARMCD = TSPARMCDObjectArray[item.TSPARM] || '';
                const params = {
                    num: 8,
                    e: event,
                    item: item,
                    dataList: dataList
                };

                auiGrid.current.request(params);
                _dataList[event.rowIndex] = event.item;

            } else {
                _dataList[event.rowIndex][event.dataField] = event.value;
            }

            setDataList(_dataList);

        }
    }, [TSPARMObjectArray, dataList, lock]);

    /**
     *  @memberOf     TSDomainList
     *  @function     handleDragEvent
     *  @param        {Object} event - 이벤트
     *  @description  드래그 이벤트 처리 함수
     */
    const handleDragEvent = useCallback((event) => {
        if (lock !== 2) {
            const params = {
                num: 9,
                e: event
            };

            let _dataList = auiGrid.current.request(params);
            _dataList && _dataList.forEach((item, index) => {
                item.row = index + 1;
            });

            setDataList(_dataList);
        }
    }, [lock]);

    /**
     *  @memberOf     TSDomainList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            if (addMode === ADD_MODE.BUTTON) {
                /* Add 버튼 클릭 시 */
                vertical = event.position;
            } else if (addMode === ADD_MODE.RIGHT_CLICK) {
                /* 우클릭으로 행 삽입 시 */
                vertical = event.oldPosition;
            } else {
                vertical = event.position;
            }
        } else if (event.type === "hScrollChange") {
            if (addMode === ADD_MODE.BUTTON) {
                /* Add 버튼 클릭 시 */
                horizontal = event.position;
            } else if (addMode === ADD_MODE.RIGHT_CLICK) {
                /* 우클릭으로 행 삽입 시 */
                horizontal = event.oldPosition;
            } else {
                horizontal = event.position;
            }
        }
    }, []);

    /**
     *  @memberOf     TSDomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let _dataList = [];

                    data.data.forEach((row, idx) => {
                        _dataList.push({
                            ...row,
                            row: (idx + 1),
                            domain: "TS"
                        });
                    });

                    setDataList(_dataList);
                    setDescription(data.description);
                    setStudyID(data.data[0]?.STUDYID || '');
                    break;

                case 'TSPARMCD_LIST':
                    let _data = data.data;
                    let _TSPARMObjectArray = {};
                    let _TSPARMCDList = [];
                    let _TSPARMList = [];

                    _data && _data.forEach((item) => {
                        _TSPARMObjectArray[item.TSPARMCD] = item.TSPARM;
                        _TSPARMCDList.push(item.TSPARMCD);
                        _TSPARMList.push(item.TSPARM);
                    });

                    setTSPARMObjectArray(_TSPARMObjectArray);
                    setTSPARMCDList(_TSPARMCDList);
                    setTSPARMList(_TSPARMList);
                    break;

                case COMMAND.DATA_UPDATE:
                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    // =============================== //
    //  rerender effect 영역
    //  - useEffect
    // =============================== //
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [getDataList]);

    useEffect(() => {
        getTSPARMCDDataList();
    }, [getTSPARMCDDataList]);

    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    // =============================== //
    //  component view 영역
    //  - JSX return
    // =============================== //
    return (
        <>
            <NetworkLayout ref={networkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "0 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">
                                            <div className="align-self-end">
                                                <DomainExplain/>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                <div className="d-flex justify-content-end m-b-10">
                                                    <button
                                                        className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                        onClick={handleAddRow}>
                                                        Add
                                                    </button>

                                                    <button
                                                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                                                        onClick={() => handleExamplePopup("TS")}>
                                                        Example
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            ref={auiGrid}
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            isDrag
                                            getDragEvent={handleDragEvent}
                                            onCellEditEnd={handleCellEditEnd}
                                            response={gridResponse}
                                            minusContentHeight={236}
                                            onContextMenu={lock !== 2 && handleContextMenu}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}
                                        />

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={description}
                                                style={{resize: "none", height: "80px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(TSDomainList);
