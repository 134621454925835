export const USER_INFO_DEFINE = {
    ACCOUNT      : 'accountID',
    NAME         : 'name',
    PASSWORD     : 'password',
    PARTNER_CODE : 'partnerCode',
    PARTNER      : 'partner',
    ROLE         : 'role',
    BIRTH        : 'birth',
    DEPARTMENT   : 'department',
    PHONE        : 'phone',
    TIMEZONE     : 'timeZone',
    LANGUAGE     : 'language',
    DESCRIPTION  : 'description',
    LOCK_REASON  : 'lockReason',
    STATE        : 'state',
    ID           : 'ID',
    LAST_UPDATED : 'lastUpdated',
    CREATED_TIME : 'createdTime',
};

