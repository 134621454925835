import React from 'react';
import { Redirect } from "react-router-dom";
import { ROLE } from "../constant/CommonConstant";

const AdminUser = React.lazy(() => import("imtrial/views/admin/user/User"));
const AdminUserDetail = React.lazy(() => import("imtrial/views/admin/user/UserDetail"));
const Log = React.lazy(() => import("imtrial/views/admin/log/Log"));
const LogDetail = React.lazy(() => import("imtrial/views/admin/log/LogDetail"));
const Sponsor = React.lazy(() => import("imtrial/views/admin/sponsor/Sponsor"));
const SponsorDetail = React.lazy(() => import("imtrial/views/admin/sponsor/SponsorDetail"));

const AdminRoutes = [
    {
        path: "/admin",
        name: "Admin",
        role: ROLE.ADMIN,
        exact: true,
        component: () => <Redirect to="/admin/user"/>,
    },
    {
        path: "/admin/user",
        name: "Admin User",
        role: ROLE.ADMIN,
        exact: true,
        component: AdminUser,
    },
    {
        path: "/admin/user/detail",
        name: "Admin User Detail",
        role: ROLE.ADMIN,
        exact: true,
        component: AdminUserDetail,
    },
    {
        path: "/admin/user/new",
        name: "New User",
        role: ROLE.ADMIN,
        exact: true,
        component: AdminUserDetail,
    },
    {
        path: "/admin/log",
        name: "Log",
        role: ROLE.ADMIN,
        exact: true,
        component: Log,
    },
    {
        path: "/admin/log/detail",
        name: "Detail",
        role: ROLE.ADMIN,
        exact: true,
        component: LogDetail,
    },
    {
        path: "/admin/sponsor",
        name: "Sponsor",
        role: ROLE.ADMIN,
        exact: true,
        component: Sponsor,
    },
    {
        path: "/admin/sponsor/detail",
        name: "Sponsor Detail",
        role: ROLE.ADMIN,
        exact: true,
        component: SponsorDetail,
    },
    {
        path: "/admin/sponsor/new",
        name: "New Sponsor",
        role: ROLE.ADMIN,
        exact: true,
        component: SponsorDetail,
    },
];

export default AdminRoutes