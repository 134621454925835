import React, {useCallback, useEffect, useRef, useState} from "react";
import cn from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, copyObject, getSessionState, setSessionState} from "../../../../../common/commonFunction";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import NetworkLayout from "../../../../../common/NetworkLayout";
import {PAGE, VIEW_USE_MODE} from "../../../../../constant/CommonConstant";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import useToast from "../../../../../hooks/useToast";
import {TAB_OPTION} from "../SdtmMappingDetail";
import AUIGrid from "../../../../components/AUIGrid";
import PagingUtil from "../../../../components/PagingUtil";
import moreIcon from "../../../../../assets/icons/more.svg";
import DataSetsModal from "./DataSetsModal";
import DataSetsNewModal from "./DataSetsNewModal";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/**
 *  @memberOf     DataSets
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, inPage, pageSize} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/${ID}?page=${inPage}&pageSize=${pageSize}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DOMAIN_DATA_LIST:
            url = `${requestUrl}/domain/menu/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    DataSets
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 DataSets Tab 컴포넌트
 */
const DataSets = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DataSets
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, setRelrec} = props;

    /**
     *  @memberOf     DataSets
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {String} name - 왼쪽 상단에 표시 될 name
     *  @property     {String} designID - TS, TM 도메인 Add할때 STUDYID에 표시될 Design ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, name, designID, lock, version} = getSessionState();

    /**
     *  @memberOf     DataSets
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     DataSets
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     DataSets
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     DataSets
     *  @var          {Object} datasetCount
     *  @description  Domain, SUPP, RELREC 개수를 담기 위한 오브젝트
     */
    const [datasetCount, setDatasetCount] = useState();

    /**
     *  @memberOf     DataSets
     *  @var          {Number} totalCount
     *  @description  리스트 전체 갯수
     */
    const [totalCount, setTotalCount] = useState(0);

    /**
     *  @memberOf     DataSets
     *  @var          {Number} pageNo
     *  @description  현재 페이지 번호
     */
    const [pageNo, setPageNo] = useState(1);

    /**
     *  @memberOf     DataSets
     *  @var          {Number} pageSize
     *  @description  화면에 표시 될 리스트 갯수(10/25/50)
     */
    const [pageSize, setPageSize] = useState(25);

    /**
     *  @memberOf     DataSets
     *  @var          {Boolean} isNewModal
     *  @description  DataSets 탭의 New Modal 의 Open/Close 상태
     */
    const [isNewModal, setIsNewModal] = useState(false);

    /**
     *  @memberOf     DataSets
     *  @var          {Boolean} isEditModal
     *  @description  DataSets 탭의 Edit Modal 의 Open/Close 상태
     */
    const [isEditModal, setIsEditModal] = useState(false);

    /**
     *  @memberOf     DataSets
     *  @var          {Object} rowEvent
     *  @description  선택 된 row의 정보
     */
    const [rowEvent, setRowEvent] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     DataSets
     *  @function     getDataList
     *  @param        {Object} params - API 통신에 필요한 parameter 값들
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback((params) => {
        const command = COMMAND.DATA_LIST;
        params.requestUrl = SDTM_MAPPING_URL;

        netWorkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     DataSets
     *  @function     handleAdd
     *  @description  Add 버튼 클릭시 호출 되는 함수.
     */
    const handleAdd = useCallback(() => {
        if (lock !== 2) {
            setIsNewModal(true);
        }
    }, [lock]);

    /**
     *  @memberOf     DataSets
     *  @function     handleRelrec
     *  @description  RELREC 버튼 클릭시 호출 되는 함수.
     */
    const handleRelrec = useCallback(() => {
        setRelrec(true);
    }, [setRelrec]);

    /**
     *  @memberOf     DataSets
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     DataSets
     *  @function     handleClose
     *  @description  Modal 창 닫기
     */
    const handleClose = useCallback(() => {
        setIsNewModal(false);
        setIsEditModal(false);
    }, []);

    /**
     *  @memberOf     DataSets
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     DataSets
     *  @function     handleMove
     *  @param        {Number} page - 이동할 페이지 번호
     *  @description  페이지 이동시 실행되어 data list 조회 api 호출
     */
    const handleMove = useCallback((page) => {
        // 페이지 이동시 필요한 parameter
        const params = {
            ID: ID,
            inPage: page,
            pageSize: pageSize
        };

        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList(params);
    }, [ID, getDataList, pageSize]);

    /**
     *  @memberOf     DataSets
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {Number} targetValue - 리스트 표시 될 갯수 (10/25/50)
     *  @description  화면에 보여질 리스트 갯수 변경 시 실행되어 data list 조회 api 호출
     */
    const handleChange = useCallback((name, targetValue) => {
        const params = {
            ID: ID,
            inPage: 1,
            pageSize: targetValue
        };

        setPageSize(targetValue);

        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList(params);
    }, [ID, getDataList]);

    /**
     *  @memberOf     DataSets
     *  @function     handleSave
     *  @param        {Array} list - 저장 될 list
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback((list) => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let sendObj = {
                page: pageNo,
                pageSize: pageSize,
                data: list
            };
            let params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, lock, pageNo, pageSize]);

    /**
     *  @memberOf     DataSets
     *  @function     getChangeData
     *  @param        {Object} obj - 수정된 rowEvent
     *  @description  Edit DataSets 창에서 변경 된 값 받는 함수
     */
    const getChangeData = useCallback((obj) => {
        let _dataList = copyObject(dataList);

        if (obj.hasOwnProperty("rowIndex")) { //Edit 일때
            _dataList[obj.rowIndex] = obj.item;
            //DataSets Description, Standard 컬럼에 값 채워주기 위한 작업
            _dataList[obj.rowIndex].description = obj.item.domainInfo.description === "" ? " " : obj.item.domainInfo.description; //domainInfo에서 description 값이 있으면 넣어줌
            _dataList[obj.rowIndex].standard = obj.item.domainInfo.standard === "" || obj.item.domainInfo.nonStandard === 1 ? " " : obj.item.domainInfo.standard; //domainInfo에서 standard 값이 있으면 넣어줌
        } else { //Add 일때
            let _dataInfo = {
                domain: !obj.domain ? "" : obj.domain,
                description: !obj.description ? "" : obj.description,
                class: !obj.class ? "" : obj.class,
                efficacy: 0,
                safety: 0,
                other: 0,
                isSUPP: 0,
                RELREC: "",
                standard: !obj.standard ? "" : obj.standard,
                domainInfo: {
                    ID: "",
                    mappingDomainID: "",
                    repeating: !obj.repeating ? "" : obj.repeating,
                    nonStandard: !obj.nonStandard ? 0 : obj.nonStandard,
                    standard: !obj.standard ? "" : obj.standard,
                    name: !obj.name ? "" : obj.name,
                    structure: !obj.structure ? "" : obj.structure,
                    referenceData: !obj.referenceData ? "" : obj.referenceData,
                    comment: !obj.comment ? "" : obj.comment,
                    description: !obj.description ? "" : obj.description,
                    keyVariable: !obj.keyVariable ? "" : obj.keyVariable,
                    SASDatasetName: !obj.SASDatasetName ? "" : obj.SASDatasetName,
                    developerNote: !obj.developerNote ? "" : obj.developerNote,
                    type: "DOMAIN"
                },
                suppInfo: "",
                keys: !obj.keys ? "" : obj.keys,
                SUPP: "",
                maxID: ""
            };

            _dataList.push(_dataInfo); //domain list에 추가 domain push

            setIsNewModal(false); //Add Domain Modal Close
        }

        handleSave(_dataList); //save api

        setRowEvent(obj);
    }, [dataList, handleSave]);

    /**
     *  @memberOf     DataSets
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     DataSets
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    setDataList(data.data.slice(1)); // 데이터 배열의 첫번째 값에 개수 정보가 들어있어서 1번 인덱스부터 슬라이싱 함
                    setDatasetCount({
                        ...data.data[0],
                        TOTALCNT: data.data[0].DOMAINCNT + data.data[0].SUPPCNT + data.data[0].RELRECCNT
                    })
                    setPageNo(data.page);
                    setTotalCount(data.total);
                    break;

                case COMMAND.DATA_UPDATE:
                    const params = {
                        ID: ID,
                        inPage: pageNo,
                        pageSize: pageSize
                    };

                    showToast(getDataList(params));
                    break;

                //no default
            }
        }
    }, [ID, getDataList, pageNo, pageSize, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        const params = {
            ID: ID,
            inPage: 1,
            pageSize: 25
        };

        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList(params);
    }, [ID, getDataList]);

    /**
     *  @memberOf     DataSets
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            width: "2%",
            headerText: "ⓘ",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 17,
                iconHeight: 17,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": moreIcon
                },
                onClick(event) {
                    if (event.item.used === 1) {
                        setRowEvent(event);
                        setIsEditModal(true);
                    }
                }
            }
        },
        {
            width: "3%",
            dataField: "used",
            headerText: "",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "domain",
            headerText: "Domain",
            editable: false,
            renderer: {
                type: "LinkRenderer",
                baseUrl: "javascript",
                jsCallback(rowIndex, columnIndex, value, item) {
                    const pathname = '/sdtm/mapping/info';
                    let state = {};
                    let domain = value;

                    switch (value) {
                        case "TS":
                        case "TA":
                        case "TE":
                        case "TV":
                        case "TM":
                            break;

                        default:
                            break;
                    }

                    state = {
                        mode: VIEW_USE_MODE.EDIT,
                        ID: ID,
                        name: name,
                        type: TAB_OPTION[1].text,
                        domain: domain,
                        designID: designID,
                        lock: lock,
                        version: version
                    };

                    setSessionState(state);

                    /**
                     * "/sdtm/mapping" 이거 쓴이유 - push 된 후에 뒤로가기 하면 경로가 지워져버림.
                     * history.push할때 같은 path로 push하면 스택에 추가되지 않음. 그래서 replace를 해줘야함.
                     */
                    history.replace("/sdtm/mapping", null);

                    history.push(pathname);
                }
            }
        },
        {
            dataField: "description",
            headerText: "Description",
            width: "15%",
            editable: false
        },
        {
            dataField: "class",
            headerText: "Class",
            width: "10%",
            editable: false
        },
        {
            dataField: "efficacy",
            headerText: "Efficacy",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "safety",
            headerText: "Safety",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "other",
            headerText: "Other",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "isSUPP",
            headerText: "SUPP",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "RELREC",
            headerText: "RELREC",
            editable: false
        },
        {
            dataField: "standard",
            headerText: "Standard",
            editable: false
        },
        {
            dataField: "domainInfo",
            width: "0%"
        },
        {
            dataField: "suppInfo",
            width: "0%"
        },
        {
            dataField: "keys",
            width: "0%"
        },
        {
            dataField: "maxID",
            width: "0%"
        },
        {
            dataField: "SUPP",
            width: "0%"
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="content" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar className="height-full p-20" options={{suppressScrollX: true}}>
                                        <div className="d-flex align-items-center m-b-10">
                                            <h5>
                                                The Number of Datasets : {datasetCount?.TOTALCNT} (Domain
                                                : {datasetCount?.DOMAINCNT} / SUPP : {datasetCount?.SUPPCNT} / RELREC
                                                : {datasetCount?.RELRECCNT})
                                            </h5>
                                            <div className="flex-1"></div>
                                            <div>
                                                <button
                                                    className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                    onClick={handleRelrec}>
                                                    RELREC
                                                </button>
                                                <button
                                                    className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                    onClick={handleAdd}>
                                                    Add
                                                </button>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}/>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between border-top bg-light">
                            <div className='p-10'>
                                <button
                                    className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                    onClick={goToList}>
                                    List
                                </button>
                            </div>

                            <PagingUtil
                                rowCount={totalCount}
                                currentPage={pageNo}
                                pageSize={pageSize}
                                blockSize={PAGE.COUNT_PER_BLOCK}
                                onMove={handleMove}
                                dataList={dataList}
                                onChange={handleChange}/>

                            <div className='p-10'>
                                <button
                                    className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                    onClick={() => handleSave(dataList)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isNewModal && (
                <DataSetsNewModal
                    history={history}
                    mappingID={ID}
                    onSave={getChangeData}
                    onClose={handleClose}/>
            )}

            {isEditModal && (
                <DataSetsModal
                    mappingID={ID}
                    lock={lock}
                    rowEvent={rowEvent}
                    onSave={getChangeData}
                    onClose={handleClose}/>
            )}
        </>
    );
};

export default React.memo(DataSets);