import React, {useCallback, useEffect, useRef, useState} from "react";
import cn from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, getListToString, getSessionState, setSessionState} from "../../../../../common/commonFunction";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import NetworkLayout from "../../../../../common/NetworkLayout";
import {VIEW_USE_MODE} from "../../../../../constant/CommonConstant";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import AUIGrid from "../../../../components/AUIGrid";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     RelrecList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/RELREC/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    RelrecList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 RELREC Tab 컴포넌트
 */
const RelrecList = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     RelrecList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, setRelrec} = props;

    /**
     *  @memberOf     RelrecList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {String} name - 왼쪽 상단에 표시 될 name
     *  @property     {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} version - SdtmMappingDetail 에서 필요한 version 정보 저장 
     *  @description  session 에서 받아오는 정보
     */
    const {ID, name, relrecID, lock, mode, version} = getSessionState();

    /**
     *  @memberOf     RelrecList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     RelrecList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     RelrecList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     RelrecList
     *  @function     handleNew
     *  @description  New 버튼 클릭 시 생성 화면으로 이동
     */
    const handleNew = useCallback(() => {
        if (lock !== 2) {
            const pathname = '/sdtm/mapping/info/relrec/new';
            const state = {
                mode: mode,
                relrecMode: VIEW_USE_MODE.NEW,  //생성 모드
                ID: ID,
                name: name,
                lock: lock,
                relrecID: relrecID,
                version: version
            };

            setSessionState(state);

            history.push({
                pathname: pathname,
                searchParams: history.location.searchParams
            });
        }
    }, [lock, mode, ID, name, relrecID, version, history]);

    /**
     *  @memberOf     RelrecList
     *  @function     handleRowClick
     *  @param        {String} rowID   - 리스트 ID
     *  @param        {String} rowName - 상세보기 했을때 왼쪽 상단에 표시될 이름
     *  @description  리스트 클릭 시 실행되는 함수, session 에 정보 저장 후 리스트 상세 정보로 넘어가기 기능
     */
    const handleRowClick = useCallback((rowID, rowName) => {
        const pathname = '/sdtm/mapping/info/relrec/detail';
        const state = {
            mode: mode,
            relrecMode: VIEW_USE_MODE.EDIT,  //  수정 모드
            ID: ID,
            name: name,
            relrecID: rowID,           // 데이터 구분 아이디
            lock: lock
        };

        setSessionState(state);

        history.push(pathname);
    }, [ID, history, lock, name, mode]);

    /**
     *  @memberOf     RelrecList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        setRelrec(false);
    }, [setRelrec]);

    /**
     *  @memberOf     RelrecList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let dataList = [];

                    data.data.forEach((data, idx) => {
                        let conditionArr = [];

                        let item;
                        for (item of data.condition) {
                            conditionArr.push("(" + item.type + ") " + item.variable + " " + item.operator + " " + item.value);
                        }

                        dataList.push({
                            ...data,
                            row: idx + 1,
                            condition: getListToString(conditionArr),
                            btn: data.RELREC + "add"
                        });
                    });

                    setDataList(dataList);
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     Summary
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "5%",
            cellMerge: true
        },
        {
            dataField: "RELREC",
            headerText: "RELREC",
            width: "20%",
            cellMerge: true
        },
        {
            dataField: "domain",
            headerText: "RDOMAIN"
        },
        {
            dataField: "IDVAR",
            headerText: "IDVAR"
        },
        {
            dataField: "REFKEY",
            headerText: "REFKEY"
        },
        {
            dataField: "condition",
            headerText: "CONDITION",
            width: "40%",
            tooltip: {
                useNativeTip: true // HTML 내장 툴팁 기능 사용
            },
        },
        {
            dataField: "btn",
            headerText: "",
            width: "5%",
            cellMerge: true,
            renderer: {
                type: "buttonRenderer",
                labelText: "Add",
                onClick(e) {
                    if (lock !== 2) {
                        const pathname = '/sdtm/mapping/info/relrec/new';
                        const state = {
                            ID: ID,
                            relrec: e.item.RELREC,
                            lock: lock,
                            mode: mode,
                            relrecMode: VIEW_USE_MODE.CUSTOM,
                            name: name,
                            relrecID: relrecID
                        };

                        setSessionState(state);
                        
                        history.push(pathname);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box height-50 p-l-20 p-r-20">
                <div className="vertical-box-column">
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex justify-content-end flex-column flex-sm-row">
                            <div className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                <div className="d-flex justify-content-end m-b-10">
                                    <button
                                        className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                        onClick={handleNew}>
                                        New RELREC
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 132px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="merge" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                                        <AUIGrid
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onClick={handleRowClick}/>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between border-top bg-light p-10">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                DataSets List
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(RelrecList);