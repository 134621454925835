import {ROLE} from "../../constant/CommonConstant";

const Menu = [
    {
        title: "Study",
        path: "/study",
        icon: 'far fa-folder',
        children: [
            {
                title: "Study",
                path: "/study",
                icon: 'far fa-file'
            },
        ]
    },
    {
        title: "Design",
        path: "/design",
        icon: 'far fa-object-group',
        children: [
            {
                title: "Design",
                path: "/design",
                icon: "far fa-list-alt"
            },
        ]
    },
    {
        title: "DCI",
        path: "/dci",
        icon: 'fas fa-share-alt-square',
        children: [
            {
                title: "EDC",
                path: "/dci/edc",
                icon: 'fas fa-cubes'
            },
        ]
    },
    {
        title: "SDTM",
        path: "/sdtm",
        icon: 'fas fa-briefcase',
        children: [
            {
                title: "Mapping",
                path: "/sdtm/mapping",
                icon: 'fas fa-map-signs'
            },
            {
                title: "Tabulation",
                path: "/sdtm/tabulation",
                icon: 'fas fa-database'
            },
            {
                title: "SDTM Package",
                path: "/sdtm/sdtm-list",
                icon: 'fas fa-book'
            }
        ]
    },
    {
        title: "Master",
        path: "/master",
        role: ROLE.MASTER,
        icon: 'fas fa-user-secret',
        children: [
            {
                title: "Partner",
                path: "/master/partner",
                icon: 'fas fa-users',
            },
            {
                title: "Country",
                path: "/master/country",
                icon: 'fas fa-globe',
            },
            {
                title: "User",
                path: "/master/user",
                icon: 'fas fa-user-circle',
            },
            {
                title: "Support",
                path: "/master/support",
                icon: 'fas fa-bullseye',
                children: [
                    {
                        title: "Notice",
                        path: "/master/support/notice",
                        icon: false,
                    },
                ],
            },
            {
                title: "Log",
                path: "/master/log",
                icon: 'fas icon-notebook',
            },
            {
                title: "Training",
                path: "/master/training",
                icon: 'fas icon-graduation',
            },
            {
                title: "Map",
                path: "/master/map",
                icon: 'fas fa-map-signs',
                children: [
                    {
                        title: "TA Map",
                        path: "/master/map/tamap",
                        icon: false,
                    },
                    {
                        title: "Concept",
                        path: "/master/map/concept",
                        icon: false,
                    },
                    {
                        title: "Design Map",
                        path: "/master/map/designmap",
                        icon: false,
                    }
                ]
            },
            {
                title: "Meta Data",
                path: "/master/metadata",
                icon: 'fas fa-table',
                children: [
                    {
                        title: "TA",
                        path: "/master/metadata/ta",
                        icon: false,
                    },
                    {
                        title: "Indication",
                        path: "/master/metadata/indication",
                        icon: false,
                    },
                    {
                        title: "Activity",
                        path: "/master/metadata/activity",
                        icon: false,
                    },
                    {
                        title: "Assessment",
                        path: "/master/metadata/assessment",
                        icon: false,
                    },
                    {
                        title: "SDTM Function",
                        path: "/master/metadata/sdtmfunction",
                        icon: false,
                    },

                    {
                        title: "Objective",
                        path: "/master/metadata/objective",
                        icon: false,
                    },
                    {
                        title: "Endpoint",
                        path: "/master/metadata/endpoint",
                        icon: false,
                    },
                    {
                        title: "Criteria",
                        path: "/master/metadata/criteria",
                        icon: false,
                    },
                ]
            },
            {
                title: "Standard",
                path: "/master/standard",
                icon: 'fas fa-tag',
                children: [
                    {
                        title: "CDASH IG",
                        path: "/master/standard/ig",
                        icon: false
                    },
                    {
                        title: "SDTM IG",
                        path: "/master/standard/sdtm-ig",
                        icon: false
                    },
                    {
                        title: "Terminology",
                        path: "/master/standard/terminology",
                        icon: false
                    },
                    {
                        title: "CodeList",
                        path: "/master/standard/codelist",
                        icon: false
                    },
                    {
                        title: "Custom CodeList",
                        path: "/master/standard/custom",
                        icon: false
                    },
                ]
            },
            {
                title: "Reference",
                path: "/master/reference",
                icon: 'fas fa-window-restore',
                children: [
                    {
                        title: "User Guide",
                        path: "/master/reference/userguide",
                        icon: false
                    }
                ]
            },
        ],

    },

    {
        title: "Admin",
        path: "/admin",
        role: ROLE.ADMIN,
        icon: 'fas fa-user-md ',
        children: [
            {
                title: "User",
                path: "/admin/user",
                icon: 'fas fa-users',
            },
            {
                title: "Log",
                path: "/admin/log",
                icon: 'fas fa-list-ol',
            },
            {
                title: "Sponsor",
                path: "/admin/sponsor",
                icon: 'fas fa-user-tie',
            },
        ]
    },

    // study: {
    //     items: [
    //         {
    //             // title: true,
    //             // name: "Study NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "Study",
    //             path: "/study",
    //         },
    //     ],
    // },
    // design: {
    //     items: [
    //         {
    //             // title: true,
    //             // name: "Design NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "Design",
    //             path: "/design",
    //         },
    //     ],
    // },
    // support: {
    //     items: [
    //         {
    //             title: true,
    //             name: "Support NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "Notice",
    //             path: "/support/notice",
    //             icon: false,
    //         },
    //     ],
    // },
    // user: {
    //     items: [
    //         {
    //             // title: true,
    //             // name: "USER NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "My Information",
    //             path: "/user/my-info",
    //             icon: false,
    //         },
    //         {
    //             title: "My Password",
    //             path: "/user/my-password",
    //             icon: false,
    //         },
    //         {
    //             title: "Training",
    //             path: "/user/training",
    //         },
    //     ],
    // },
    // prm: {
    //     items: [
    //         {
    //             title: true,
    //             name: "PRM NAVIGATION",
    //             class: "pt-4"
    //         },
    //         {
    //             title: "My PRM",
    //             path: "/prm/my-prm",
    //             icon: false
    //         },
    //     ]
    // },
    // sdtm: {
    //     items: [
    //         {
    //             // title: true,
    //             // name: "SDTM NAVIGATION",
    //             class: "pt-4"
    //         },
    //         {
    //             title: "MY SDTM Package",
    //             icon: false,
    //             path: "/sdtm/sdtm-list",
    //         },
    //         {
    //             title: "tabulation",
    //             path: "/sdtm/tabulation"
    //         },
    //         {
    //             title: "Mapping",
    //             path: "/sdtm/mapping"
    //         },
    //     ]
    // },
    // crf: {
    //     items: [
    //         {
    //             title: true,
    //             name: "CRF NAVIGATION",
    //             class: "pt-4"
    //         },
    //         {
    //             title: "My CRF",
    //             path: "/crf/my-crf",
    //             icon: false
    //         },
    //         {
    //             title: "EDC Build",
    //             path: "/crf/build",
    //             icon: false
    //         },
    //     ]
    // },
    // master: {
    //     title: "Master",
    //     items: [
    //         {
    //             title: true,
    //             name: "Master NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "Partner",
    //             path: "/master",
    //             icon: false,
    //         },
    //         {
    //             title: "user",
    //             path: "/master/user",
    //             icon: false,
    //         },
    //         {
    //             title: "Support",
    //             path: "/master/support",
    //             icon: false,
    //             children: [
    //                 {
    //                     title: "Notice",
    //                     path: "/master/support/notice",
    //                     icon: false,
    //                 },
    //             ],
    //         },
    //         {
    //             title: "log",
    //             path: "/master/log",
    //             icon: false,
    //         },
    //         {
    //             title: "Map",
    //             path: "/master/map",
    //             icon: false,
    //             children: [
    //                 {
    //                     title: "TA Map",
    //                     path: "/master/map/tamap",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Concept",
    //                     path: "/master/map/concept",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Design Map",
    //                     path: "/master/map/design",
    //                     icon: false,
    //                 }
    //             ]
    //         },
    //         {
    //             title: "Meta Data",
    //             path: "/master/mdr/metadata",
    //             icon: false,
    //             children: [
    //                 {
    //                     title: "TA",
    //                     path: "/master/mdr/metadata/ta",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Indication",
    //                     path: "/master/mdr/metadata/indication",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Activity",
    //                     path: "/master/mdr/metadata/activity",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Assessment",
    //                     path: "/master/mdr/metadata/assessment",
    //                     icon: false,
    //                 },
    //
    //                 {
    //                     title: "Objective",
    //                     path: "/master/mdr/metadata/objective",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Endpoint",
    //                     path: "/master/mdr/metadata/endpoint",
    //                     icon: false,
    //                 },
    //                 {
    //                     title: "Criteria",
    //                     path: "/master/mdr/metadata/criteria",
    //                     icon: false,
    //                 },
    //             ]
    //         },
    //         {
    //             title: "Standard",
    //             path: "/master/standard",
    //             icon: false,
    //             children: [
    //                 {
    //                     title: "IG",
    //                     path: "/master/standard/ig",
    //                     icon: false
    //                 },
    //                 {
    //                     title: "Terminology",
    //                     path: "/master/standard/terminology",
    //                     icon: false
    //                 },
    //                 {
    //                     title: "Codelist",
    //                     path: "/master/standard/codelist",
    //                     icon: false
    //                 },
    //                 {
    //                     title: "Custom Codelist",
    //                     path: "/master/standard/custom",
    //                     icon: false
    //                 },
    //             ]
    //         },
    //         {
    //             title: "Reference",
    //             path: "/master/mdr/reference",
    //             icon: false,
    //             children: [
    //                 {
    //                     title: "user Guide",
    //                     path: "/master/mdr/reference/userguide",
    //                     icon: false
    //                 }
    //             ]
    //         },
    //     ],
    // },
    // "admin": {
    //     items: [
    //         {
    //             // title: true,
    //             // title: "Partner Admin NAVIGATION",
    //             class: "pt-4",
    //         },
    //         {
    //             title: "user",
    //             path: "/admin/user",
    //             icon: false,
    //         },
    //         {
    //             title: "log",
    //             path: "/admin/log",
    //             icon: false,
    //         },
    //     ],
    // },


    // 	path: '/', icon: 'fa fa-th', title: 'Home'
    // },
    // { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    //   children: [
    //     { path: '/menu/menu-1-1', title: 'Menu 1.1',
    //       children: [
    //         { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
    //           children: [
    //             { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
    //             { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
    //           ]
    //         },
    //         { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
    //         { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
    //       ]
    //     },
    //     { path: '/menu/menu-1-2', title: 'Menu 1.2' },
    //     { path: '/menu/menu-1-3', title: 'Menu 1.3' },
    //   ]
    // }
]

export default Menu;
