import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT, copyObject, getSessionState, handleExamplePopup} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import AUIGrid from "../../../../../components/AUIGrid";
import DomainExplain from "../../../../../components/DomainExplain";
import deleteIcon from "../../../../../../assets/icons/close.svg";
import IETESTEditModal from "./IETESTEditModal";
import CriteriaVersionModal from "./CriteriaVersionModal";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     TIDomainList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/tdomain/${ID}?domain=${domain}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/tdomain/${domain}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    TIDomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TI 도메인 컴포넌트
 */
const TIDomainList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     TIDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;


    /**
     *  @memberOf     TIDomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} name - study name
     *  @property     {String} designID - design ID
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock, name, designID} = getSessionState();

    /**
     *  @memberOf     TIDomainList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     TIDomainList
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     TIDomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     TIDomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     TIDomainList
     *  @var          {String} descriptionValue
     *  @description  Description 입력 된 값
     */
    const [descriptionValue, setDescriptionValue] = useState('');

    /**
     *  @memberOf     TIDomainList
     *  @var          {Object} currentCriteria
     *  @description  변경 할 Criteria 의 값
     */
    const [currentCriteria, setCurrentCriteria] = useState({});

    /**
     *  @memberOf     TIDomainList
     *  @var          {Number} criteriaIndex
     *  @description  변경 할 Criteria 의 Index
     */
    const [criteriaIndex, setCriteriaIndex] = useState();

    /**
     *  @memberOf     TIDomainList
     *  @var          {Object} originCriteria
     *  @description  변경 전 Criteria 의 값
     */
    const [originCriteria, setOriginCriteria] = useState({});

    /**
     *  @memberOf     TIDomainList
     *  @var          {Boolean} isEditModal
     *  @description  Criteria edit 팝업 오픈 여부
     */
    const [isEditModal, setIsEditModal] = useState(false);

    /**
     *  @memberOf     TIDomainList
     *  @var          {Boolean} isCriteriaModal
     *  @description  another version of criteria 팝업 오픈 여부
     */
    const [isCriteriaModal, setIsCriteriaModal] = useState(false);

    /**
     *  @memberOf     TIDomainList
     *  @var          {Boolean} dropdownOpen
     *  @description  Add 버튼 클릭시 표시되는 드롭다운 메뉴 오픈 여부
     */
    const [dropdownOpen, setDropdownOpen] = useState(false);

    /**
     *  @memberOf     TIDomainList
     *  @var          {Array} newDataList
     *  @description  dataList에 덧붙일 새 데이터 (Criterial Modal에서 새 데이터 가져옴)
     */
    const [newDataList, setNewDataList] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     TIDomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
            domain: "TI"
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleDropdownToggle
     *  @description  Add 버튼 클릭시 표시되는 드롭다운 메뉴 토글
     */
    const handleDropdownToggle = useCallback(() => {
        setDropdownOpen(!dropdownOpen)
    }, [dropdownOpen]);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleAddRow
     *  @description  Add 버튼 클릭시 호출 되는 함수. TI 도메인 한줄 추가.
     */
    const handleAddRow = useCallback((list) => {
        setDataList(list);
        if (lock !== 2) {
            const params = {
                num: 1,
                item: {
                    "ID": "",
                    "DEGNID": designID,
                    "row": "",
                    "STUDYID": name.split(" ")[0],
                    "domain": "",
                    "IETESTCD": "",
                    "IETEST": "",
                    "IECAT": "",
                    "IESCAT": "",
                    "TIRL": "",
                    "TIVERS": ""
                },

                dataList: dataList
            };
            auiGrid.current.request(params);
        }
    }, [dataList, designID, lock, name]);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleDeleteRow
     *  @description  X 버튼 클릭시 호출 되는 함수. TI 도메인 한줄 삭제.
     */
    const handleDeleteRow = useCallback((e) => {
        let _dataList = window.AUIGrid.getOrgGridData('#grid_wrap'); //grid 데이터 가지고 옴
        _dataList.splice(e.rowIndex, 1); //선택 된 index 삭제

        setDataList(_dataList);
    }, []);

    /**
     *  @memberOf     TIDomainList
     *  @function     gridResponse
     *  @param        {Array} list - 그리드 목록
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((list) => {
        if (list) {
            let dataList = [];

            list.forEach((row, idx) => {
                dataList.push({
                    ...row,
                    row: (idx + 1),
                    domain: "TI"
                });
            });

            setDataList(dataList);
        }
    }, []);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleDrag
     *  @param        {Array} list - Grid에서 순서 변경 된 목록
     *  @description  Grid에서 row drag & drop 시 호출되는 함수
     */
    const handleDrag = useCallback((list) => {
        setDataList(list);
    }, []);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescriptionValue(e.target.value);
    }, []);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleOpenPopup
     *  @param        {String} event - 클릭한 cell의 row 정보
     *  @description  테이블 cell 클릭하면 실행되어 Modal 열기 기능
     */
    const handleOpenPopup = useCallback((event) => {

        event.item.IETEST = event.item.IETEST.replaceAll('<br/>', '\n'); //Modal의 TextArea에 표시되는 값이기 때문에 <br/>을 \n 으로 변경 작업

        let _dataList = copyObject(dataList);
        let newDataList = {};

        _dataList.forEach((data) => {
            if (data.ID === event.item.ID) {
                newDataList = {
                    ...event.item,
                    DEGNID: designID
                };
            }
        });

        setCurrentCriteria(newDataList); //선택 된 Criteria 값 저장
        setCriteriaIndex(event.rowIndex); //선택 된 Criteria Index 저장
        setOriginCriteria(newDataList); // 선택된 Criteria 값 저장(수정 전 정보 위해 사용)
        setIsEditModal(!isEditModal); //Criteria 수정하기 위한 Modal Open
    }, [dataList, designID, isEditModal]);

    /**
     *  @memberOf     SetupConfig
     *  @function     handleCriteriaSave
     *  @param        {Object} data - 변경한 데이터
     *  @param        {Number} idx - 변경한 Criteria Index
     *  @description  Criteria Edit 팝업 OK 눌렀을때 호출 되는 함수
     */
    const handleCriteriaSave = useCallback((data, idx) => {
        let _dataList = dataList;

        _dataList[idx] = copyObject(data);

        setDataList(_dataList);
        // const _dataInfo = copyObject(dataInfo);
        //
        // _dataInfo[data.type][idx] = data; //수정 된 값 저장
        //
        // setDataInfo(_dataInfo);
    }, [dataList]);

    /**
     *  @memberOf     TIDomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';
        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     TIDomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let params = {};
            let _dataList = [...dataList];

            _dataList.forEach((data, idx) => {
                data.order = idx + 1;
            });

            let sendObj = {
                data: dataList,
                description: descriptionValue
            };

            // 데이터 생성에 필요한 parameter
            params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                domain: "TI",
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, descriptionValue, lock]);

    /**
     *  @memberOf     TIDomainList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     TIDomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let dataList = [];

                    data.data.forEach((row, idx) => {
                        dataList.push({
                            ...row,
                            row: (idx + 1),
                            domain: "TI"
                        });
                    });
                    setDataList(dataList);
                    setDescriptionValue(data.description);
                    break;

                case COMMAND.DATA_UPDATE:
                    //스크롤위치 초기화
                    vertical = 0;
                    horizontal = 0;

                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    // CriterialVersionModal에서 가져온 newDataList를 기존 dataList 앞쪽에 추가함
    useEffect(() => {
        const _newArray = [];
        if (dataList && newDataList) {
            newDataList.forEach((newData) => {
                _newArray.push({
                    "ID": "",
                    "DEGNID": designID,
                    "row": "",
                    "STUDYID": name.split(" ")[0],
                    "domain": newData.DOMAIN,
                    "IETESTCD": newData.IETESTCD,
                    "IETEST": newData.IETEST,
                    "IECAT": newData.IECAT,
                    "IESCAT": "",
                    "TIRL": "",
                    "TIVERS": newData.TIVERS
                })
            })
            _newArray.push(...dataList); // 기존 데이터 추가
        }
        setDataList(_newArray);
        // eslint-disable-next-line
    }, [newDataList])

    /**
     *  @memberOf     TIDomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "3%",
            editable: false
        },
        {
            dataField: "STUDYID",
            headerText: "STUDYID",
            headerStyle: "aui-grid-header-red",
            width: "15%"
        },
        {
            dataField: "domain",
            headerText: "DOMAIN",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "IETESTCD",
            headerText: "IETESTCD",
            headerStyle: "aui-grid-header-red"
        },
        {
            dataField: "IETEST",
            headerText: "IETEST",
            headerStyle: "aui-grid-header-red",
            width: "40%",
            style: "cell-left-align",
            editable: false,
            renderer: {
                type: "TemplateRenderer"  // HTML 템플릿 렌더러 사용
            },
            labelFunction(rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
                if (!value) {
                    return "";
                } else if (value.length > 200) {
                    return `<div style="color:#ff5b57">${value}</div>`;
                } else {
                    return `<div>${value}</div>`;
                }
            }
        },
        {
            dataField: "IECAT",
            headerText: "IECAT",
            headerStyle: "aui-grid-header-red"
        },
        {
            dataField: "IESCAT",
            headerText: "IESCAT",
            headerStyle: "aui-grid-header-green"
        },
        {
            dataField: "TIRL",
            headerText: "TIRL",
            headerStyle: "aui-grid-header-green"
        },
        {
            dataField: "TIVERS",
            headerText: "TIVERS",
            headerStyle: "aui-grid-header-green",
            editable: lock !== 2
        },
        {
            dataField: "",
            headerText: "",
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
                iconHeight: 16,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "0 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">
                                            <div className="align-self-end">
                                                <DomainExplain/>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                <div className="d-flex justify-content-end m-b-10">
                                                    <Dropdown
                                                        isOpen={dropdownOpen}
                                                        toggle={handleDropdownToggle}
                                                    >
                                                        <DropdownToggle tag="a">
                                                            <div
                                                                className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1 tool", {'disabled': lock === 2})}
                                                            >
                                                                Add
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu dropdown" tag="ul">
                                                            <DropdownItem onClick={handleAddRow}>a row</DropdownItem>
                                                            <DropdownItem onClick={() => setIsCriteriaModal(true)}>another
                                                                version of criteria</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>

                                                    <button
                                                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                                                        onClick={() => handleExamplePopup("TI")}>
                                                        Example
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            ref={auiGrid}
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            response={gridResponse}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}
                                            minusContentHeight={236}
                                            onCellClick={handleOpenPopup}
                                            isDrag={true}
                                            onDrag={handleDrag}
                                            // isWordWrap={true}
                                            // rowHeight={50}
                                        />

                                        {/*//다른 컬럼 수정할때 이제 새로 GRID 추가될 예정*/}
                                        {/*{dataList !== undefined && dataList.length > 0 && (*/}
                                        {/*    <TIDomainGrid*/}
                                        {/*        gridData={dataList}*/}
                                        {/*        onClick={handleOpenPopup}*/}
                                        {/*        onChange={handleGridChange}*/}
                                        {/*        scrollInfo={{vertical: vertical, horizontal: horizontal}}*/}
                                        {/*        onScrollChange={getScrollInfo}*/}
                                        {/*        onAddRow={handleAddRow}*/}
                                        {/*        onDeleteRow={handleDeleteRow}*/}
                                        {/*        // onDrag={handleDrag}*/}
                                        {/*    />*/}
                                        {/*)}*/}

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={descriptionValue}
                                                style={{resize: "none", height: "80px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isEditModal && (
                <IETESTEditModal
                    data={currentCriteria}
                    originData={originCriteria}
                    criteriaIndex={criteriaIndex}
                    onClose={() => setIsEditModal(!isEditModal)}
                    onSave={handleCriteriaSave}
                    readOnly={lock === 2}/>
            )}
            {isCriteriaModal && (
                <CriteriaVersionModal
                    history={history}
                    onClose={() => setIsCriteriaModal(false)}
                    setNewDataList={setNewDataList}
                />
            )}
        </>
    );
};

export default React.memo(TIDomainList);
