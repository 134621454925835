import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import produce from "immer";
import {AXIOS_GET, AXIOS_PUT, getSessionState, isDataExist} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import {DESIGN_URL, SDTM_MAPPING_URL} from "constant/ConstantURL";
import NetworkLayout from "common/NetworkLayout";
import cn from "classnames";
import useToast from "hooks/useToast";
import deleteIcon from "../../../../../../assets/icons/delete.svg";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import {FILL_REQUIRED_FIELD} from "../../../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SdmStudyEpochs
 *  @constant     {Object} DEFAULT_DATA_EPOCHS
 *  @description  DEFAULT EPOCHS 데이터 리스트
 */
const DEFAULT_DATA_EPOCHS = {
    ID: "",
    order: "",
    periodName: "",
    periodID: "",
    epochName: "",
    epochType: "",
    epochSubType: "",
    numberOfElements: "",
    epochOther: ""
};

/**
 *  @memberOf       SdmStudyEpochs
 *  @constant       {Array} EPOCH_NAME_LIST
 *  @description    Epoch Name 리스트
 */
const EPOCH_NAME_LIST = [
    {
        text: 'BASELINE',
        value: 'BASELINE'
    },
    {
        text: 'BLIND TREATMENT',
        value: 'BLIND TREATMENT'
    },
    {
        text: 'CONTINUATION TREATMENT',
        value: 'CONTINUATION TREATMENT'
    },
    {
        text: 'FOLLOW-UP',
        value: 'FOLLOW-UP'
    },
    {
        text: 'INDUCTION TREATMENT',
        value: 'INDUCTION TREATMENT'
    },
    {
        text: 'LONG-TERM FOLLOW-UP',
        value: 'LONG-TERM FOLLOW-UP'
    },
    {
        text: 'OBSERVATION',
        value: 'OBSERVATION'
    },
    {
        text: 'OPEN LABEL TREATMENT',
        value: 'OPEN LABEL TREATMENT'
    },
    {
        text: 'RUN-IN',
        value: 'RUN-IN'
    },
    {
        text: 'SCREENING',
        value: 'SCREENING'
    },
    {
        text: 'TREATMENT',
        value: 'TREATMENT'
    },
    {
        text: 'WASHOUT',
        value: 'WASHOUT'
    },
    {
        text: 'OTHER',
        value: 'OTHER'
    },
];

/**
 *  @memberOf       SdmStudyEpochs
 *  @constant       {Array} EPOCH_TYPE_LIST
 *  @description    Epoch Type 리스트
 */
const EPOCH_TYPE_LIST = [
    {
        text: 'Post-treatment',
        value: 'Post-treatment'
    },
    {
        text: 'No Treatment',
        value: 'No Treatment'
    },
    {
        text: 'Treatment',
        value: 'Treatment'
    },
    {
        text: 'Pre-treatment',
        value: 'Pre-treatment'
    }];

/**
 *  @memberOf       SdmStudyEpochs
 *  @constant       {Array} EPOCH_SUBTYPE_LIST
 *  @description    Epoch SubType 리스트
 */
const EPOCH_SUBTYPE_LIST = [
    {
        text: 'Follow-up',
        value: 'Follow-up'
    },
    {
        text: 'Elimination',
        value: 'Elimination'
    },
    {
        text: 'Wash-out',
        value: 'Wash-out'
    },
    {
        text: 'Observation',
        value: 'Observation'
    },
    {
        text: 'Extension',
        value: 'Extension',
    },
    {
        text: 'Maintenance',
        value: 'Maintenance',
    },
    {
        text: 'Treatment',
        value: 'Treatment',
    },
    {
        text: 'Dose Escalation',
        value: 'Dose Escalation',
    },
    {
        text: 'Titration',
        value: 'Titration',
    },
    {
        text: 'Run-in',
        value: 'Run-in',
    },
    {
        text: 'Screening',
        value: 'Screening',
    }
];

/**
 *  @memberOf       SdmStudyEpochs
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {ID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO :
            url = `${DESIGN_URL}/sdm/epoch/${ID}`
            response = await AXIOS_GET(url);

            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${SDTM_MAPPING_URL}/SDM/studyEpochs/${ID}`
            response = await AXIOS_PUT(url, sendObject);

            break;

        default:
            return null;
    }
    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmStudyEpochs
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal에서 Study Epochs 탭 편집 컴포넌트
 */
const SdmStudyEpochs = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyEpochs
     *  @type         {Object} props
     *  @property     {String} ID - 리스트 ID
     *  @property     {Object} history -  url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {ID, history, onClose} = props;

    /**
     *  @memberOf     SdmStudyEpochs
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} designID - design ID
     *  @description  session 에서 받아오는 정보
     */
    const {lock, designID} = getSessionState();

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @var          {Array} periodNameList
     *  @description  periodName 리스트 정보
     */
    const [periodNameList, setPeriodNameList] = useState([]);

    /**
     *  @memberOf       SdmStudyEpochs
     *  @var            {Array} epochsInputFieldData
     *  @description    현재 입력된 epochs input 데이터
     */
    const [epochsInputFieldData, setEpochsInputFieldData] = useState(DEFAULT_DATA_EPOCHS);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        showRowNumColumn: false,  // no 표시
        wrapSelectionMove: false,
        enableSorting: false,
        editable: lock !== 2,
        height: 620,
        enableDrag: lock !== 2,
        enableDragByCellDrag: lock !== 2,
        enableDrop: lock !== 2,
        blankToNullOnEditing: true
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_LIST;

        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/SDM/studyEpochs/${ID}`,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     getPeriodName
     *  @description  study period Name 정보 api 호출
     */
    const getPeriodName = useCallback(() => {
        const command = COMMAND.DATA_INFO;

        const params = {
            requestUrl: `${DESIGN_URL}/sdm/epoch`,
            ID: designID
        };

        netWorkAgent.current.request(command, params);
    }, [designID]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     handleAdd
     *  @description  추가 버튼 클릭 시 실행되는 함수
     */
    const handleAdd = useCallback(() => {
        if (lock !== 2) {
            const inputField = epochsInputFieldData;

            const lastIndex = dataList.length;

            // dataTable에 추가할 데이터 필드 정의
            let addData = {
                ID: "",
                order: lastIndex + 1,
                periodID: inputField.periodID,
                periodName: inputField.periodName,
                epochName: inputField.epochName,
                epochType: inputField.epochType,
                epochSubType: inputField.epochSubType,
                numberOfElements: inputField.numberOfElements,
            };

            // 데이터 info에 default 값 추가
            setDataList(
                produce(dataList, draft => {
                    draft.push(addData);
                })
            );

            // add 후 입력 필드 초기화
            setEpochsInputFieldData(DEFAULT_DATA_EPOCHS);
        }
    }, [dataList, epochsInputFieldData, lock]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     handleGridChange
     *  @param        {Array}  list  - Epochs 변경 된 List
     *  @description  grid 변경 시(수정, Drag & Drop, 삭제) 실행되는 함수
     */
    const handleGridChange = useCallback((list) => {
        if (isDataExist(periodNameList)) {
            list.forEach((el) => {
                periodNameList.forEach((item) => {
                    if (item.value === el.periodName) {
                        el.periodID = item.ID
                    }
                });
            });
        }
        setDataList(list);
    }, [periodNameList]);

    /**
     *  @memberOf     FormModuleEditPopup
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     validateData
     *  @return       {Boolean} validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        return window.AUIGrid.validateGridData(`#sdmStudyEpochs-grid`, ["periodName", "epochName", "numberOfElements"], FILL_REQUIRED_FIELD);
    }, []);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            // 데이터 validation
            const valid = validateData();

            if (valid === false) {
                return;
            }

            dataList.map((data, index) => {
                data.order = index + 1
                return data;
            });

            // 데이터 수정 요청
            const command = COMMAND.DATA_UPDATE;
            const sendObject = {
                list: dataList
            };

            const params = {
                requestUrl: `${SDTM_MAPPING_URL}/SDM/studyEpochs`,
                ID: ID,
                sendObject: JSON.stringify(sendObject)
            };
            // back-end 데이터 처리 요청
            netWorkAgent.current.request(command, params);
        }
    }, [ID, dataList, lock, validateData]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;
            switch (command) {
                case COMMAND.DATA_LIST :
                    if (data.hasOwnProperty('data')) {
                        setDataList(data.data);
                    }
                    break;

                case COMMAND.DATA_INFO:
                    if (data.hasOwnProperty('data')) {
                        const periodNameInfo = data.data.map((item) => {
                            return {
                                text: item.name,
                                value: item.name,
                                ID: item.ID
                            }
                        });
                        setPeriodNameList(periodNameInfo);
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                default:
                    break;
            }
        }
    }, [getDataInfo, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    useEffect(() => {
        getPeriodName();
    }, [getPeriodName]);

    // grid 데이터 setting 작업
    useEffect(() => {
        if (isDataExist(periodNameList)) {
            tableRef.current = window.AUIGrid.create(`#sdmStudyEpochs-grid`, columnLayout, auiGridProps);

            if (dataList.length > 0) {
                window.AUIGrid.setGridData(tableRef.current, dataList);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList, periodNameList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#sdmStudyEpochs-grid`);
        };
    }, [ID]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell edit 이벤트
        window.AUIGrid.bind(tableRef.current, "cellEditEnd", (event) => {
            if (handleGridChange !== undefined) {
                window.AUIGrid.updateRow(tableRef.current, event.rowIndex);
                const changeGridData = window.AUIGrid.getGridData(tableRef.current);

                handleGridChange(changeGridData);
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "dropEnd", (event) => {
            if (handleGridChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                handleGridChange(changeGridData);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (handleGridChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                handleGridChange(changeGridData);
            }
        });
    }, [handleGridChange]);

    /**
     *  @memberOf     SdmStudyEpochs
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'order',
            headerText: 'No.',
            width: '5%',
            editable: false,
        },
        {
            dataField: 'periodName',
            headerText: 'Period Name<span class=\"ml-1 text-danger\">*</span>',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: periodNameList,
                keyField: "text", // key 에 해당되는 필드명
                valueField: "value", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: 'epochName',
            headerText: 'Epoch Name<span class=\"ml-1 text-danger\">*</span>',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: EPOCH_NAME_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: 'epochType',
            headerText: 'Epoch Type',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: EPOCH_TYPE_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: 'epochSubType',
            headerText: 'Epoch Subtype',
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: EPOCH_SUBTYPE_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            },
        },
        {
            dataField: 'numberOfElements',
            headerText: 'Number Of Element<span class=\"ml-1 text-danger\">*</span>',
            editable: lock !== 2,
            editRenderer: {
                type: "InputEditRenderer",
                onlyNumeric: true, // 0~9 까지만 허용
                allowPoint: false // onlyNumeric 인 경우 소수점(.) 도 허용
            }
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteTableRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="d-flex justify-content-end m-b-10">
                <button className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleAdd}>Add
                </button>
            </div>
            <div className='grid-style' id="sdmStudyEpochs-grid"/>
            <div className="modal-button">
                <div className="d-flex justify-content-end p-10 border-top">
                    <button
                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(SdmStudyEpochs);


