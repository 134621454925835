import React, {useCallback, useEffect, useState} from 'react';
import classnames from "classnames";
import produce from 'immer';
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";
import IPConfigGrid from "./IPConfigGrid";
import {getSessionState} from "../../../../common/commonFunction";

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {Array} IP_HEADER_LIST
 *  @description  IP 테이블 head 에 표시할 리스트
 */
const IP_HEADER_LIST = [
    {
        title: 'Shape',
        value: 'shape'
    },
    {
        title: 'Name',
        value: 'name'
    },
    {
        title: 'Dose',
        value: 'dose'
    },
    {
        title: 'Unit',
        value: 'unit'
    }
];

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_UNIT
 *  @description  unit field 이름 정의
 */
export const FIELD_UNIT = 'unit';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_SHAPE
 *  @description  shape field 이름 정의
 */
export const FIELD_SHAPE = 'shape';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_NAME
 *  @description  name field 이름 정의
 */
export const FIELD_NAME = 'name';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_DOSE
 *  @description  dose field 이름 정의
 */
export const FIELD_DOSE = 'dose';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_UNIT_OTHER
 *  @description  unit_other field 이름 정의
 */
export const FIELD_UNIT_OTHER = 'unit_other';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {String} FIELD_SHAPE_OTHER
 *  @description  shape_other field 이름 정의
 */
export const FIELD_SHAPE_OTHER = 'shape_other';

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {Array} SHAPE_LIST
 *  @description  shape 카테고리에 표시할 옵션 리스트
 */
const SHAPE_LIST = [
    {
        text: 'Tablet',
        value: 'Tablet'
    },
    {
        text: 'Capsule',
        value: 'Capsule'
    },
    {
        text: 'Cream',
        value: 'Cream'
    },
    {
        text: 'Gel',
        value: 'Gel'
    },
    {
        text: 'Ointment',
        value: 'Ointment'
    },
    {
        text: 'Patch',
        value: 'Patch'
    },
    {
        text: 'Powder',
        value: 'Powder'
    },
    {
        text: 'Spray',
        value: 'Spray'
    },
    {
        text: 'Suppository',
        value: 'Suppository'
    },
    {
        text: 'Suspension',
        value: 'Suspension'
    },
    {
        text: 'Gas',
        value: 'Gas'
    },
    {
        text: 'Aerosol',
        value: 'Aerosol'
    },
    {
        text: 'Other(specify)',
        value: 'Other'
    }
];

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {Array} UNIT_LIST
 *  @description  unit 카테고리에 표시할 옵션 리스트
 */
const UNIT_LIST = [
    {
        text: 'g',
        value: 'g'
    },
    {
        text: 'mg',
        value: 'mg'
    },
    {
        text: 'L',
        value: 'L'
    },
    {
        text: 'mL',
        value: 'mL'
    },
    {
        text: 'Tablet',
        value: 'Tablet'
    },
    {
        text: 'Capsule',
        value: 'Capsule'
    },
    {
        text: 'Pack',
        value: 'Pack'
    },
    {
        text: 'IU',
        value: 'IU'
    },
    {
        text: 'PUFF',
        value: 'PUFF'
    },
    {
        text: 'ug',
        value: 'ug'
    },
    {
        text: 'Other(specify)',
        value: 'Other'
    }
];

/**
 *  @memberOf     IpConfigTableList
 *  @constant     {Object} LIST_INDEX
 *  @description  select box 데이터에 접근하기 위한 key field 정의
 */
const LIST_INDEX = {
    TEXT: 'title',
    VALUE: 'value'
};

/**
 *  @author         주예리나
 *  @version        1.0
 *  @component      IpConfigTableList
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    design information 의 IP Config 리스트 편집 컴포넌트
 */
const IpConfigTableList = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      IpConfigTableList
     *  @type          {Object} props
     *  @property      {String} name - ip 데이터 field name
     *  @property      {Array} dataList - 데이터 테이블에 표시하기 위한 데이터 리스트
     *  @property      {Function} onAddItem - 행 추가 버튼 클릭 시 실행 될 상위 이벤트 함수
     *  @property      {Function} onChange - input 내용 변경 시 실행 될 상위 이벤트 함수
     *  @property      {Object} currentValue - input 요소에 표시하기 위한 현재 입력된 데이터
     *  @property      {Object} validation - 데이터 invalid 처리 여부
     *  @property      {Function} onFocus - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Boolean} disabled - 읽기 전용 모드 여부
     *  @property      {Function} onGridChange - grid 변경 시(수정, Drag & Drop, 삭제) 실행될 상위 이벤트 함수
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {name, dataList, onAddItem, onChange, currentValue, validation, onFocus, disabled, onGridChange} = props;

    /**
     *  @memberOf      IpConfigTableList
     *  @type          {Object} getSessionState
     *  @property      {String} language - study 화면 언어 정보
     *  @description   session 에서 받아오는 정보
     */
    const {language} = getSessionState();

    /**
     *  @memberOf IpConfigTableList
     *  @var {Object} isSelectingOther
     *  @description  옵션 에서 other 값 선택 여부
     */
    const [isSelectingOther, setIsSelectingOther] = useState({
        shape: false,
        unit: false
    });

    /**
     *  @memberOf     IpConfigTableList
     *  @function     handleChange
     *  @param        {String} inputName - 입력 된 요소의 tag name
     *  @param        {String} value - 입력 값
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((inputName, value) => {
        if (onChange) {
            onChange(name, inputName, value); // table name, cell name, value
        }
    }, [name, onChange]);

    /**
     *  @memberOf     IpConfigTableList
     *  @function     handleAddItem
     *  @description  행 추가 버튼 클릭 시 실행되는 함수
     */
    const handleAddItem = useCallback(() => {
        if (onAddItem) {
            onAddItem(name);
        }
    }, [name, onAddItem]);

    /**
     *  @memberOf     IpConfigTableList
     *  @function     handleFocus
     *  @param        {String} inputName - Focus 된 요소의 tag name
     *  @param        {String} value - Focus 된 요소의 입력 값
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((inputName, value) => {
        if (onFocus) {
            onFocus(name, inputName, value);
        }
    }, [name, onFocus]);

    /**
     *  @memberOf     IpConfigTableList
     *  @function     getInputFieldLists
     *  @return       {ReactElement} ip 테이블 리스트 요소
     *  @description  항목별 ip 데이터 테이블 요소 반환
     */
    const getInputFieldLists = useCallback(() => {
        // table head 기준으로 항목 생성
        return IP_HEADER_LIST.map((headerData, headerIndex) => {
            return (
                <React.Fragment>
                    <td
                        className={classnames("with-form-control px-0", {"pl-2": headerIndex !== 0})}
                        key={`${name} - ${headerIndex}`}>
                        {headerData[LIST_INDEX.VALUE] === FIELD_SHAPE || headerData[LIST_INDEX.VALUE] === FIELD_UNIT ? (
                            <>
                                <SelectBox
                                    name={headerData[LIST_INDEX.VALUE]}
                                    onChange={handleChange}
                                    currentValue={currentValue && currentValue[headerData[LIST_INDEX.VALUE]]}
                                    dataList={headerData[LIST_INDEX.VALUE] === FIELD_SHAPE ? SHAPE_LIST : UNIT_LIST}
                                    readOnly={disabled}
                                    validation={validation && validation[headerData[LIST_INDEX.VALUE]]}
                                    onFocus={handleFocus}/>

                                { /* option Other 선택 시 text box를 함께 표시 */}
                                {isSelectingOther[FIELD_UNIT] && headerData[LIST_INDEX.VALUE] === FIELD_UNIT && (
                                    <TextField
                                        name={FIELD_UNIT_OTHER}
                                        onChange={handleChange}
                                        currentValue={currentValue && currentValue[FIELD_UNIT_OTHER]}
                                        disabled={disabled}
                                        validation={validation && validation[FIELD_UNIT_OTHER]}
                                        onFocus={handleFocus}/>
                                )}

                                {isSelectingOther[FIELD_SHAPE] && headerData[LIST_INDEX.VALUE] === FIELD_SHAPE && (
                                    <TextField
                                        name={FIELD_SHAPE_OTHER}
                                        onChange={handleChange}
                                        currentValue={currentValue && currentValue[FIELD_SHAPE_OTHER]}
                                        disabled={disabled}
                                        validation={validation && validation[FIELD_SHAPE_OTHER]}
                                        onFocus={handleFocus}/>
                                )}
                            </>
                        ) : (
                            <>
                                {headerData[LIST_INDEX.VALUE] === FIELD_DOSE ? (
                                    <TextField
                                        name={headerData[LIST_INDEX.VALUE]}
                                        onChange={handleChange}
                                        currentValue={currentValue && currentValue[headerData[LIST_INDEX.VALUE]]}
                                        disabled={disabled}
                                        validation={validation && validation[headerData[LIST_INDEX.VALUE]]}
                                        onFocus={handleFocus}/>
                                ) : (
                                    <TextField
                                        name={headerData[LIST_INDEX.VALUE]}
                                        onChange={handleChange}
                                        currentValue={currentValue && currentValue[headerData[LIST_INDEX.VALUE]]}
                                        disabled={disabled}
                                        validation={validation && validation[headerData[LIST_INDEX.VALUE]]}
                                        onFocus={handleFocus}
                                        type={'text'}/>
                                )}
                            </>
                        )}
                    </td>

                    {/* 마지막 요소 뒤에만 삭제 버튼 표시 */}
                    {!disabled && headerIndex === IP_HEADER_LIST.length - 1 && (
                        <td key={headerIndex} className="with-btn text-right">
                            <button className="btn btn-blue m-r-2" onClick={handleAddItem}>+</button>
                        </td>
                    )}
                </React.Fragment>
            );
        });
    }, [name, handleChange, currentValue, disabled, validation, handleFocus, isSelectingOther, handleAddItem]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // isSelectingOther state 값 변경
    useEffect(() => {
        if (currentValue) {
            setIsSelectingOther(
                produce(isSelectingOther, draft => {
                    draft[FIELD_SHAPE] = (currentValue[FIELD_SHAPE] === 'Other');
                    draft[FIELD_UNIT] = (currentValue[FIELD_UNIT] === 'Other');
                })
            );
        }
    }, [currentValue, isSelectingOther]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {(dataList && dataList.length > 0) && (
                <IPConfigGrid
                    ID={`${name}-grid`}
                    name={name}
                    dataList={dataList}
                    onChange={onGridChange}/>
            )}

            <table className="table border-0">
                <thead>
                <tr>
                    <th>{language === 'en' ? 'Shape' : '제형'}</th>
                    <th>{language === 'en' ? 'Name' : '이름'}</th>
                    <th>{language === 'en' ? 'Dose' : '용량'}</th>
                    <th>{language === 'en' ? 'Unit' : '단위'}</th>
                    <th width={"8%"}/>
                </tr>
                </thead>

                <tbody>
                <tr>
                    {getInputFieldLists()}
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default React.memo(IpConfigTableList);
