import React, { Suspense } from 'react';
import Pubsub from "pubsub-js";
import { getSessionUserData } from "./common/commonFunction";
import { REFRESH_SESSION, SESSION_INFO, SESSION_LOGIN } from "./constant/CommonConstant";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageSettings } from './routes/page-settings.js';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import ReferencePopup from "./imtrial/views/design/reference/ReferencePopup";
import FindPassword from "./imtrial/views/extra/account/FindPassword";
import MappingExamplePopup from "./imtrial/views/sdtm/Mapping/Variables/MappingExamplePopup";
// Pages
const Login = React.lazy(() => import("imtrial/views/extra/auth/Login"));

class App extends React.Component {
    constructor(props) {
        super(props);

        /**
         *  @memberOf  App
         *  @function  subscribe
         *  @param  {String} msg  - 이벤트 종류
         *  @param  {Function} func - 이벤트에 사용하는 정보 객체
         *  @description  이벤트 수신 처리기 - 로그인 , 인증 갱신 시 세션 정보 불러오기
         */
        this.subscribe = (msg, func) => {
            switch (msg) {
                case REFRESH_SESSION :
                    this.setState({
                        sessionUserData: getSessionUserData() // session 정보 업데이트
                    });
                    break;

                case SESSION_LOGIN : // 로그인 시
                    this.setState({
                        sessionUserData: getSessionUserData(), // session 정보 업데이트 
                        isAuthenticated: true // 인증 true
                    });
                    func();
                    break;

                // no default
            }
        };

        this.toggleSidebarMinify = (e) => {
            e.preventDefault();
            if (this.state.pageSidebarMinify) {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }
            this.setState(state => ({
                pageSidebarMinify: !this.state.pageSidebarMinify
            }));
        }
        this.toggleMobileSidebar = (e) => {
            this.setState(state => ({
                pageSidebarToggled: !this.state.pageSidebarToggled
            }));
        }
        this.handleSetPageSidebar = (value) => {
            this.setState(state => ({
                pageSidebar: value
            }));
        }
        this.handleSetPageSidebarMinified = (value) => {
            this.setState(state => ({
                pageSidebarMinify: value
            }));
        }
        this.handleSetPageSidebarWide = (value) => {
            this.setState(state => ({
                pageSidebarWide: value
            }));
        }
        this.handleSetPageSidebarLight = (value) => {
            this.setState(state => ({
                pageSidebarLight: value
            }));
        }
        this.handleSetPageSidebarTransparent = (value) => {
            this.setState(state => ({
                pageSidebarTransparent: value
            }));
        }
        this.handleSetPageSidebarSearch = (value) => {
            this.setState(state => ({
                pageSidebarSearch: value
            }));
        }

        this.toggleRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed
            }));
        }
        this.toggleMobileRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileRightSidebarToggled: !this.state.pageMobileRightSidebarToggled
            }));
        }
        this.handleSetPageRightSidebar = (value) => {
            this.setState(state => ({
                pageRightSidebar: value
            }));
        }

        var floatSubMenuRemove;
        var floatSubMenuCalculate;
        var floatSubMenuRemoveTime = 250;
        this.handleFloatSubMenuOnMouseOver = (e) => {
            clearTimeout(floatSubMenuRemove);
            clearTimeout(floatSubMenuCalculate);
        }
        this.handleFloatSubMenuOnMouseOut = (e) => {
            floatSubMenuRemove = setTimeout(() => {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }, floatSubMenuRemoveTime);
        }
        this.handleSidebarOnMouseOver = (e, menu) => {
            if (this.state.pageSidebarMinify) {
                if (menu.children) {
                    var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                    clearTimeout(floatSubMenuRemove);
                    clearTimeout(floatSubMenuCalculate);

                    this.setState(state => ({
                        pageFloatSubMenu: menu,
                        pageFloatSubMenuActive: true,
                        pageFloatSubMenuLeft: left
                    }));

                    var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                    floatSubMenuCalculate = setTimeout(() => {
                        var targetTop = offset.top;
                        var windowHeight = window.innerHeight;
                        var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                        var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                        if ((windowHeight - targetTop) > targetHeight) {
                            top = offset.top + 'px';
                            bottom = 'auto';
                            arrowTop = '20px';
                            arrowBottom = 'auto';
                            lineTop = '20px';
                            lineBottom = 'auto';
                        } else {
                            var aBottom = (windowHeight - targetTop) - 21;
                            top = 'auto';
                            bottom = '0';
                            arrowTop = 'auto';
                            arrowBottom = aBottom + 'px';
                            lineTop = '20px';
                            lineBottom = aBottom + 'px';
                        }

                        this.setState(state => ({
                            pageFloatSubMenuTop: top,
                            pageFloatSubMenuBottom: bottom,
                            pageFloatSubMenuLineTop: lineTop,
                            pageFloatSubMenuLineBottom: lineBottom,
                            pageFloatSubMenuArrowTop: arrowTop,
                            pageFloatSubMenuArrowBottom: arrowBottom,
                            pageFloatSubMenuOffset: offset
                        }));
                    }, 0);

                } else {
                    floatSubMenuRemove = setTimeout(() => {
                        this.setState(state => ({
                            pageFloatSubMenu: '',
                            pageFloatSubMenuActive: false
                        }));
                    }, floatSubMenuRemoveTime);
                }
            }
        }
        this.handleSidebarOnMouseOut = (e) => {
            if (this.state.pageSidebarMinify) {
                floatSubMenuRemove = setTimeout(() => {
                    this.setState(state => ({
                        pageFloatSubMenuActive: false
                    }));
                }, floatSubMenuRemoveTime);
            }
        }
        this.handleFloatSubMenuClick = () => {
            if (this.state.pageSidebarMinify) {
                const windowHeight = window.innerHeight;
                const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
                const targetTop = this.state.pageFloatSubMenuOffset.top;
                const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
                const left = (this.state.pageFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth) + 'px';
                const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
                const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
                const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';

                this.setState(state => ({
                    pageFloatSubMenuTop: top,
                    pageFloatSubMenuLeft: left,
                    pageFloatSubMenuBottom: bottom,
                    pageFloatSubMenuLineTop: lineTop,
                    pageFloatSubMenuLineBottom: lineBottom,
                    pageFloatSubMenuArrowTop: arrowTop,
                    pageFloatSubMenuArrowBottom: arrowBottom
                }));
            }
        }

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }

        this.handleSetPageHeader = (value) => {
            this.setState(state => ({
                pageHeader: value
            }));
        }
        this.handleSetPageHeaderMegaMenu = (value) => {
            this.setState(state => ({
                pageHeaderMegaMenu: value
            }));
        }
        this.handleSetPageHeaderLanguageBar = (value) => {
            this.setState(state => ({
                pageHeaderLanguageBar: value
            }));
        }

        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetPageTopMenu = (value) => {
            this.setState(state => ({
                pageTopMenu: value
            }));
        }
        this.toggleMobileTopMenu = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileTopMenu: !this.state.pageMobileTopMenu
            }));
        }
        this.handleSetPageTwoSidebar = (value) => {
            this.setState(state => ({
                pageTwoSidebar: value
            }));
        }
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        this.state = {
            sessionUserData: getSessionUserData(),  // 세션 유저 정보
            isAuthenticated: getSessionUserData() !== null && getSessionUserData()[SESSION_INFO.ACCESS_TOKEN] !== null,  // 토큰 인증 정보 존재 여부

            pageHeader: true,
            pageheaderMegaMenu: false,
            pageHeaderLanguageBar: false,
            hasScroll: true,
            handleSetPageHeader: this.handleSetPageHeader,
            handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
            handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

            pageSidebar: true,
            pageSidebarWide: true,
            pageSidebarLight: false,
            pageSidebarMinify: false,
            pageSidebarToggled: false,
            pageSidebarTransparent: false,
            pageSidebarSearch: false,
            handleSetPageSidebar: this.handleSetPageSidebar,
            handleSetPageSidebarWide: this.handleSetPageSidebarWide,
            handleSetPageSidebarLight: this.handleSetPageSidebarLight,
            handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
            handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
            handleSetPageSidebarSearch: this.handleSetPageSidebarSearch,
            handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
            handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
            toggleSidebarMinify: this.toggleSidebarMinify,
            toggleMobileSidebar: this.toggleMobileSidebar,

            pageFloatSubMenuActive: false,
            pageFloatSubMenu: '',
            pageFloatSubMenuTop: 'auto',
            pageFloatSubMenuLeft: 'auto',
            pageFloatSubMenuBottom: 'auto',
            pageFloatSubMenuLineTop: 'auto',
            pageFloatSubMenuLineBottom: 'auto',
            pageFloatSubMenuArrowTop: 'auto',
            pageFloatSubMenuArrowBottom: 'auto',
            pageFloatSubMenuOffset: '',
            handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
            handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
            handleFloatSubMenuClick: this.handleFloatSubMenuClick,

            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: true,
            pageContentFullWidth: true,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: true,
            handleSetPageFooter: this.handleSetPageFooter,

            pageTopMenu: false,
            pageMobileTopMenu: false,
            toggleMobileTopMenu: this.toggleMobileTopMenu,
            handleSetPageTopMenu: this.handleSetPageTopMenu,

            pageTwoSidebar: false,
            handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,

            pageRightSidebar: false,
            pageRightSidebarToggled: true,
            pageMobileRightSidebarToggled: false,
            toggleRightSidebar: this.toggleRightSidebar,
            toggleMobileRightSidebar: this.toggleMobileRightSidebar,
            handleSetPageRightSidebar: this.handleSetPageRightSidebar,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        // 메시지 수신기 세팅
        Pubsub.subscribe(SESSION_LOGIN, this.subscribe);
        Pubsub.subscribe(REFRESH_SESSION, this.subscribe);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)

        // subscribe 수신기 해제
        Pubsub.unsubscribe(SESSION_LOGIN);
        Pubsub.unsubscribe(REFRESH_SESSION);
    }

    handleScroll = () => {
        if (window.scrollY > 0) {
            this.setState(state => ({
                hasScroll: true
            }));
        } else {
            this.setState(state => ({
                hasScroll: false
            }));
        }
        var elm = document.getElementsByClassName('nvtooltip');
        for (var i = 0; i < elm.length; i++) {
            elm[i].classList.add('d-none');
        }
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback>
                    <Switch>
                        <Route path="/account/login" render={(props) => <Login {...props} />}/>
                        <Route path="/account/find_password" render={(props) => <FindPassword {...props}/>}/>
                        <Route exact path="/referencePopup" render={(props) => <ReferencePopup {...props}/>}/>
                        <Route path="/mapping/example/:domain" render={(props) => <MappingExamplePopup {...props}/>}/>
                        <Route path="/"
                               render={props => {
                                   // 인증 여부 확인되면 다른 페이지 접근 가능
                                   if (this.state.isAuthenticated) {
                                       // if (this.state.sessionUserData[SESSION_INFO.PASSWORD_EXPIRE] === 1 || this.state.sessionUserData[SESSION_INFO.FIRST_LOGIN] === true) {
                                       //     return <Redirect
                                       //         to={{pathname: "/user/my-password", state: {from: props.location}}}/>
                                       // }
                                       // // 트레이닝 미수료 목록이 있을 경우 트레이닝 목록으로 이동
                                       // else if (this.state.sessionUserData[SESSION_INFO.TRAINING_COMPLATE] === 0) {
                                       //     return <Redirect
                                       //         to={{pathname: "/user/training", state: {from: props.location}}}/>
                                       // } else {
                                       return <LoggedInRoutes {...this.state}/>
                                       // }
                                   } else {
                                       return ( // 인증 X면 로그인 페이지로 강제 리다이렉트
                                           <Redirect
                                               to={{pathname: "/account/login", state: {from: props.location}}}/>
                                       )
                                   }
                               }}/>
                    </Switch>
                </Suspense>
            </React.Fragment>
        )
    }
}

// login 성공 시 접속 가능한 router 컴포넌트
class LoggedInRoutes extends React.Component {
    render(props) {
        return (
            <PageSettings.Provider value={this.props}>
                <div className={
                    'fade page-sidebar-fixed show page-container ' +
                    (this.props.pageHeader ? 'page-header-fixed ' : '') +
                    (this.props.pageSidebar ? '' : 'page-without-sidebar ') +
                    (this.props.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
                    (this.props.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
                    (this.props.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
                    (this.props.pageSidebarMinify ? 'page-sidebar-minified ' : '') +
                    (this.props.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                    (this.props.pageTopMenu ? 'page-with-top-menu ' : '') +
                    (this.props.pageContentFullHeight ? 'page-content-full-height ' : '') +
                    (this.props.pageTwoSidebar ? 'page-with-two-sidebar ' : '') +
                    (this.props.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') +
                    (this.props.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') +
                    (this.props.hasScroll ? 'has-scroll ' : '')
                }>
                    {this.props.pageHeader && (<Header/>)}
                    {this.props.pageSidebar && (<Sidebar/>)}
                    {this.props.pageTwoSidebar && (<SidebarRight/>)}
                    {this.props.pageTopMenu && (<TopMenu/>)}
                    {this.props.pageContent && (<Content/>)}
                </div>
            </PageSettings.Provider>
        )
    }
}

export default App;