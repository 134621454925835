import React, {forwardRef, useCallback, useImperativeHandle, useReducer} from 'react';
import LoadingIndicator from "imtrial/components/LoadingIndicator";
import ErrorModal from "imtrial/components/ErrorModal";
import {ERROR_CODE, LOGIN_URL} from "constant/CommonConstant";
import {processAgent} from "./dataProcessAgent";
import PubSub from "pubsub-js";
import {TIMER_EVENT} from "imtrial/components/UserTimer";
import ReFreshToken from "imtrial/components/ReFreshToken";
import {showConfirmAlert} from "../imtrial/components/alertModal";

/**
 *  @summary
 *  back-end 통신 관련 공통 처리(통신, 에러 팝업, 처리 alert) layout
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @constant
 *  @type {Object}
 *  @description  상태 정보 타입
 */
const STATE_CODE = {
    LOADING: 'LOADING',     // 로딩 상태
    SUCCESS: 'SUCCESS',     // 응답 받은 상태
    ERROR: 'ERROR',     // 에러 상태
    EXPIRED: 'EXPIRED',     // token expired
    ERROR_CHECK: 'ERROR_CHECK',     // 에러를 체크한 경우
};

/**
 *  @type      {function(*): *}
 *  @function  reducer - 상태 처리하는 reducer
 *  @param     {Object}  state   - 상태 값
 *  @param     {Object}  action  - 처리에 필요한 데이터 들(command, params)
 *  @return    {Object}  응답 state
 */
function reducer(state, action) {

    switch (action.type) {

        // 로딩 상태 일 경우
        case STATE_CODE.LOADING:
            return {
                loading: true,
                data: null,
                error: null,
                action: null,
                errorCode: ERROR_CODE.NO_ERROR,
            };

        // todo 응답을 성공적으로 받았을 경우 //
        // 응답을 받은 경우
        case STATE_CODE.SUCCESS:
            return {
                loading: false,
                data: action.data,
                error: null,
                errorCode: ERROR_CODE.NO_ERROR,
                action: {command: action.command, params: action.params}
            };

        // 에러가 발생한 경우
        case STATE_CODE.ERROR:
            return {
                loading: false,
                data: null,
                error: action.error,
                errorCode: action.errorCode,
                action: {command: action.command, params: action.params}
            };

        // token expired 된 경우
        case STATE_CODE.EXPIRED:
            return {
                loading: false,
                data: null,
                error: action.error,
                errorCode: action.errorCode,
                action: {command: action.command, params: action.params}
            };

        // 에러를 확인 한 경우
        case STATE_CODE.ERROR_CHECK:
            return {
                ...state,
                error: null,
                errorCode: ERROR_CODE.NO_ERROR,
                action: null,
            };

        // 응답을 준 상태
        case STATE_CODE.RESPONSE:
            return {
                loading: false,
                data: null,
                error: null,
                action: null,
                errorCode: ERROR_CODE.NO_ERROR,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

/**
 *  @component  NetworkLayout
 *  @function  network 처리를 할 수 있는 함수형 컴포넌트
 *  @param     {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @param     {Object} ref - 상위 컴포넌트에서 전달 받은 property
 */
const NetworkLayout = forwardRef((props, ref) => {

        window.displayName = 'networkLayout';
        /*################################################################################*/
        //## data 영역
        //##  - props, state
        /*################################################################################*/
        /*
        *   상위 컴포넌트에서 전달 받은 props
        *   1. process      : back-end 에 요청 처리 하는 로직 함수
        *   2. response     : 응답을 전달하기 위한 함수
        *   3. history      : 페이지 이동을 위한 history 객체
        *   4. onErrorCheck : 에러 확인시 처리 함수
        */
        const {process, response, history, onErrorCheck} = props;

        /*
        *   back-end 통신 관련 data
        *   1. loading    : 로딩 상태 여부
        *   2. data       : 통신 응답 데이터
        *   3. error      : 에러 내용
        *   4. action     : 전송시 사용했던 정보 (command, params)
        *   5. errorCode  : 에러 코드
        */
        const [state, dispatch] = useReducer(reducer, {
            loading: false,
            data: null,
            error: null,
            action: null,
            errorCode: 0,
        });

        const {loading, error, errorCode} = state;

        /*################################################################################*/
        //## function define 영역
        //## - useCallback
        /*################################################################################*/
        /**
         *  @function  processData
         *  @param  {String} command  - 데이터 처리 함수에게 전달할 처리 구분 코드
         *  @param  {Object} params   - 데이터 처리 함수에게 전달할 parameter
         *  @description  데이터 통신 처리 framework
         */
        const processData = useCallback(async (command, params) => {

            dispatch({type: STATE_CODE.LOADING});
            try {
                console.log(`request command : ${command}`);
                console.log(params);

                let data = null;

                /*
                 * 1. props로 전달받은 process(dataProcess)가 있을 경우
                 *   1) dataProcess에 요청할 command 내용 존재 : process 그대로 실행
                 *   2) dataProcess에 요청할 command 내용 X   : 공통 모듈 command 실행
                 * 2. props로 전달받은 process(dataProcess)가 없을 경우
                 *     : 공통 모듈 command 실행
                */

                // process 있을 시,
                if (process !== undefined) {
                    // 데이터 처리 요청
                    data = await process(command, params);
                }

                if (data === null) {
                    // props로 넘겨받은 process에 요청할 command 내용이 없다면 공통 모듈 사용
                    data = await processAgent(command, params);
                }

                // 응답코드가 음수인 경우 error
                if (data.hasOwnProperty("code") && data.code < 0) {
                    response("", data);
                    console.log(data);

                    const errorString = data.msg;

                    //My SDTM Package메뉴 Define.xml processing
                    if (data.code === ERROR_CODE.SDTM_PACKAGE_PROCESSING) {
                        showConfirmAlert(errorString);
                    }
                    //Tabulation 메뉴에서 SDTM Conversion 버튼 클릭 후 Clinical Data 클릭을 안했을 경우
                    if (data.code === ERROR_CODE.TABULATION_CLINICAL_DATAlIST) {
                        showConfirmAlert(errorString);
                    }
                    // token expired
                    if (data.code === ERROR_CODE.TOKEN_EXPIRED) {
                        dispatch({type: STATE_CODE.EXPIRED, error: errorString, errorCode: data.code, command, params});
                    } else {
                        if (data.code !== ERROR_CODE.TABULATION_CLINICAL_DATAlIST) {
                            dispatch({type: STATE_CODE.ERROR, error: errorString, errorCode: data.code, command, params});
                        }
                    }
                } else {
                    dispatch({type: STATE_CODE.SUCCESS, data, command, params});

                    // 응답 데이터
                    const actionData = {command: command, params: params};

                    response(actionData, data);

                    console.log(`## response data ##`);
                    console.log(actionData);
                    console.log(`### 받아오는 데이터 확인 ###`) //데이터를 좀 더 가독성있게 확인하기 위해 사용
                    console.log(data);

                    // 타이머 연장
                    PubSub.publish(TIMER_EVENT.AUTO);
                }
            } catch (e) {
                dispatch({type: STATE_CODE.ERROR, error: e.toString(), command, params});
            }
        }, [process, response]);


        /**
         *  @function   errorCheck
         *  @param      {number} code  - 에러코드
         *  @description  에러 확인 버튼을 클릭시 처리
         */
        const errorCheck = useCallback((code = ERROR_CODE.NO_ERROR) => {

            switch (code) {
                case ERROR_CODE.AUTH_ERROR:
                case ERROR_CODE.NO_TOKEN:
                case ERROR_CODE.TOKEN_EXPIRED:
                case ERROR_CODE.VERIFY_FAIL:
                    history.push(LOGIN_URL);
                    break;

                case ERROR_CODE.NO_USER:
                    dispatch({type: STATE_CODE.ERROR_CHECK});
                    break;

                default:
                    dispatch({type: STATE_CODE.ERROR_CHECK});
                    break;
            }
        }, [history]);


        /**
         *  @function  handleErrorRetry
         *  @param  none
         *  @description  에러창에서 재시도(retry) 버튼 클릭 시 처리
         */
        const handleErrorRetry = useCallback(() => {

            // action state 에서 이전 호출했던 정보 get
            const {command, params} = state.action;

            // back-end 데이터 처리 요청
            processData(command, params);

        }, [processData, state.action]);

        /**
         *  @function  handleErrorClose
         *  @param  {number}  code - 에러코드
         *  @description  에러창에서 닫기 버튼 클릭 시 처리
         */
        const handleErrorClose = useCallback((code) => {
            if (onErrorCheck !== undefined) {
                const {command, params} = state.action;
                onErrorCheck(code, command, params);
                dispatch({type: STATE_CODE.ERROR_CHECK});

            } else {
                // error 확인 처리 요청
                errorCheck(code);
            }
        }, [errorCheck, onErrorCheck, state.action]);


        /**
         *  @function  handleRefreshTokenError
         *  @param  {number}  code - 에러코드
         *  @param  {string}  errorString - 에러내용
         *  @description  refresh token 실행 시 에러
         */
        const handleRefreshTokenError = useCallback((code, errorString) => {

            // action state 에서 이전 호출했던 정보 get
            const {command, params} = state.action;

            dispatch({type: STATE_CODE.ERROR, error: errorString, errorCode: code, command, params});

        }, [state.action]);

        // NetworkLayout 을 reference 를 가지고 호출 할 수 있도록 사용하는 hook
        useImperativeHandle(ref, () => ({
                request(command, params) {
                    processData(command, params);
                }
            })
        );

        /*################################################################################*/
        //## component view 영역
        //## - JSX return
        /*################################################################################*/
        return (
            <>
                {/* loading indicator */}
                <LoadingIndicator loading={loading}/>

                {/* error popup */}
                {(errorCode !== ERROR_CODE.NO_ERROR && errorCode !== ERROR_CODE.TOKEN_EXPIRED && errorCode !== ERROR_CODE.SDTM_PACKAGE_PROCESSING) && (
                    <ErrorModal onRetry={handleErrorRetry}
                                onClose={handleErrorClose}
                                errorInfo={error}
                                errorCode={errorCode}
                                history={history}
                    />
                )}

                {/* expired process */}
                {(errorCode === ERROR_CODE.TOKEN_EXPIRED) &&
                    <ReFreshToken onSuccess={handleErrorRetry}
                                  onFail={handleRefreshTokenError}
                    />
                }
            </>
        );
    }
);

export default React.memo(NetworkLayout);
