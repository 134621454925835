import React, {useCallback, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {copyObject} from "../../../../common/commonFunction";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    CriteriaEditModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  Criteria 편집 팝업 컴포넌트
 */
const CriteriaEditModal = (props) => {
    /**
     *  @memberOf     CriteriaEditModal
     *  @type         {Object} props
     *  @property     {Function} onClose - 닫기 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Boolean} readOnly - 편집 모드 여부 (true - 편집 불가)
     *  @property     {Object} data - 수정할 데이터의 Object
     *  @property     {Function} onSave - 저장 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Number} criteriaIndex - 선택된 criteria index
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {onClose, readOnly, data, onSave, criteriaIndex} = props;

    /**
     *  @memberOf     CriteriaEditModal
     *  @var          {Object} dataInfo
     *  @description  수정 될 데이터
     */
    const [dataInfo, setDataInfo] = useState(data);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  값 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDataInfo({...dataInfo, name: e.target.value});
    }, [dataInfo]);

    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 실행되는 함수
     */
    const handleSave = useCallback(() => {
        if (onSave !== undefined) {
            let _dataInfo = copyObject(dataInfo);
            _dataInfo.name = _dataInfo.name.replaceAll('\n', '<br/>'); //gird에 전달 되는 값이기 때문에 \n을 <br/> 로 변경 작업

            onSave(_dataInfo, criteriaIndex);
            handleClose();
        }
    }, [criteriaIndex, dataInfo, handleClose, onSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <Modal isOpen style={{maxWidth: "1000px", minHeight: '90vh'}}>
            <ModalHeader
                className={'header-title bg-main'}
                toggle={handleClose}>
                Criteria Edit
            </ModalHeader>

            <ModalBody>
                <textarea
                    rows={15}
                    className="form-control m-t-10"
                    onChange={handleChange}
                    value={dataInfo.name.replaceAll('<br/>', '\n')}
                    style={{resize: "none"}}
                    disabled={readOnly}/>
            </ModalBody>

            <ModalFooter className="p-3">
                <button
                    className="btn btn-blue"
                    onClick={handleClose}>
                    Cancel
                </button>

                {!readOnly && (
                    <button
                        className="btn btn-new"
                        onClick={handleSave}>
                        Ok
                    </button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default CriteriaEditModal;