import React, {useCallback, useState, useEffect} from 'react';
import {getSessionState, isDataExist, isFieldDataExist, setSessionState} from "common/commonFunction";
import DesignMenu from "./DesignMenu";
import DesignConfig from "./synopsis/DesignConfig";
import IPConfig from "./synopsis/IPConfig";
import Schema from "./synopsis/Schema";
import Objective from "./synopsis/Objective";
import Criteria from "./synopsis/Criteria";
import CustomModal from "../../components/CustomModal";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     DesignTab
 *  @constant     {String} STEP_STATE
 *  @description  step 기본 field define
 */
const STEP_STATE = 'step';

/**
 *  @memberOf     DesignTab
 *  @constant     {Object} DESIGN_STEP
 *  @description  메뉴 별 구분 index
 */
const DESIGN_STEP = {
    NONE: -1,
    OBJECTIVE: 0,
    DESIGN: 1,
    IP: 2,
    SCHEMA: 3,
    CRITERIA: 4,
};

/**
 *  @memberOf     DesignTab
 *  @constant     {Array} YES_NO_MODAL_BUTTON
 *  @description  modal 에 표시할 Yes, No 버튼 리스트
 */
const YES_NO_MODAL_BUTTON = [
    {
        name: "Yes",
        color: "btn-lock"
    },
    {
        name: "No",
        color: "btn-lock"
    }
];

/**
 *  @memberOf     DesignTab
 *  @constant     {Array} MENU_TITLE
 *  @description  Design Concept menu information 에서 사용되는 왼쪽 메뉴에 표시할 목록 정보
 */
export const MENU_TITLE = [
    {
        MENU: "1. Objective & Endpoint",
        TITLE: "Objective & Endpoint Configuration",
        MENU_KR : "1. 목적 & 평가 변수"
    },
    {
        MENU: "2. Design",
        TITLE: "Design Configuration",
        MENU_KR : "2. 연구 설계"
    },
    {
        MENU: "3. Investigational Product",
        TITLE: "IP Configuration",
        MENU_KR : "3. 임상시험용의약품"
    },
    {
        MENU: "4. Schema",
        TITLE: "Schema Configuration",
        MENU_KR : "4. 연구 모식도"
    },
    {
        MENU: "5. Criteria",
        TITLE: "Criteria Configuration",
        MENU_KR : "5. 선정/제외기준"
    }
];

/**
 *  @memberOf     DesignTab
 *  @constant     {Array} PRM_MENU_TITLE
 *  @description  Design PRM menu information 에서 사용되는 왼쪽 메뉴에 표시할 목록 정보
 */
export const PRM_MENU_TITLE = [
    {
        MENU: "1. Study ID",
        TITLE: "Study ID",
        MENU_KR: "1. Study ID"
    },
    {
        MENU: "2. Study Arms",
        TITLE: "Study Arms",
        MENU_KR: "2. Study Arms"
    },
    {
        MENU: "3. Study Epochs",
        TITLE: "Study Epochs",
        MENU_KR: "3. Study Epochs"
    },
    {
        MENU: "4. Study Elements",
        TITLE: "Study Elements",
        MENU_KR: "4. Study Elements"
    },
    {
        MENU: "5. Design Matrix",
        TITLE: "Design Matrix",
        MENU_KR: "5. Design Matrix"
    }
];

/**
 *  @author        주예리나
 *  @version       1.0
 *  @component     DesignTab
 *  @param         {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description   Design 의 상세화면 내 Information Tab 컴포넌트
 */
const DesignTab = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DesignTab
     *  @type         {Object} props
     *  @property     {String} viewMode - 화면 생성 및 수정 구분 값 (NEW | EDIT | VIEW)
     *  @property     {String} ID - 리스트 ID
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} language - study 화면 언어 정보
     *  @property     {Function} onSendData - 데이터 전달 함수
     *  @property     {Boolean} onTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 실행 상태
     *  @property     {Boolean} onTabClose - isDataCheckModal의 No버튼 클릭 시 Close 실행 상태
     *  @property     {Boolean} setTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property     {Boolean} setTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property     {Boolean} isTabDataCheckModal - isDataCheckModal 상태
     *  @property     {Boolean} setTabDataCheckModal - isDataCheckModal 상태 저장
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        viewMode,
        ID,
        history,
        language,
        onSendData,
        onTabSave,
        onTabClose,
        setTabSave,
        setTabClose,
        isTabDataCheckModal,
        setTabDataCheckModal
    } = props;

    /**
     *  @memberOf     DesignTab
     *  @var          {Number} nowStep
     *  @description  현재 활성화 된 menu 의 index 번호
     */
    const [nowStep, setNowStep] = useState(isFieldDataExist(getSessionState(), STEP_STATE) ? getSessionState()[STEP_STATE] : DESIGN_STEP.OBJECTIVE);

    /**
     *  @memberOf       DesignTab
     *  @var            {Object} dataInfo
     *  @description    화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState({});

    /**
     *  @memberOf       DesignTab
     *  @var            {Object} originDataInfo
     *  @description    원본 데이터 정보
     */
    const [originDataInfo, setOriginDataInfo] = useState({});

    /**
     *  @memberOf       DesignTab
     *  @var            {Boolean} isDataCheckModal
     *  @description    Data 변경 후 save를 누르지 않고 Tab 이동 시 나오는 Modal
     */
    const [isDataCheckModal, setDataCheckModal] = useState(false);

    /**
     *  @memberOf       DesignTab
     *  @var            {Boolean} onSave
     *  @description    isDataCheckModal의 Yes 버튼 클릭 상태
     */
    const [onSave, setSave] = useState(false);

    /**
     *  @memberOf       DesignTab
     *  @var            {Boolean} onClose
     *  @description    isDataCheckModal의 NO 버튼 클릭 상태
     */
    const [onClose, setClose] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     DesignTab
     *  @function     StepContents
     *  @param        {Object} props - 상위 컴포넌트로 부터 받아온 props 객체
     *  @return       {ReactElement}
     *  @description  메뉴 선택 시 실행되어 메뉴에 해당하는 컴포넌트 반환
     */
    const StepContents = useCallback((props) => {
        switch (nowStep) {
            case DESIGN_STEP.OBJECTIVE:
                return <Objective {...props} />;
            case DESIGN_STEP.DESIGN:
                return <DesignConfig {...props}   />;
            case DESIGN_STEP.IP:
                return <IPConfig    {...props}    />;
            case DESIGN_STEP.SCHEMA:
                return <Schema {...props} />;
            case DESIGN_STEP.CRITERIA:
                return <Criteria {...props} />;
            default:
                return null;
        }
    }, [nowStep]);

    /**
     *  @memberOf     DesignTab
     *  @function     handleStep
     *  @param        {Number} step - 이동하려는 메뉴의 index 번호
     *  @description  메뉴 클릭 시 실행하는 함수
     */
    const handleStep = useCallback((step) => {
        //변경 데이터 비교
        if (JSON.stringify(dataInfo) !== JSON.stringify(originDataInfo)) {
            setDataCheckModal(!isDataCheckModal);
            return;
        }

        // session data 에 셋팅
        let sessionData = getSessionState();
        sessionData[STEP_STATE] = step;

        setSessionState(sessionData);
        setNowStep(step);
    }, [dataInfo, isDataCheckModal, originDataInfo]);

    /**
     *  @memberOf     DesignTab
     *  @function     goToList
     *  @description  리스트 버튼 클릭 시 실행하여 목록으로 돌아가는 기능
     */
    const goToList = useCallback(() => {
        const pathname = '/design/design';
        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     DesignTab
     *  @function     handleReferencePopup
     *  @description  ref 버튼 클릭시 실행되어 window 팝업 오픈
     */
    const handleReferencePopup = useCallback(() => {
        // 듀얼 모니터 기준
        let left = (window.screen.availWidth - 800) / 2;
        let top = (window.screen.availHeight - 640) / 2 - 10;

        if (window.screenLeft < 0) {
            left += window.screen.width * -1;
        } else if (window.screenLeft > window.screen.width) {
            left += window.screen.width;
        }

        window.open('#/referencePopup', '_ref_popup', `width=800, height=640, left=${left}, top=${top}, menubar=no, status=no, toolbar=no`);
    }, []);

    /**
     *  @memberOf     DesignTab
     *  @function     handleChangeData
     *  @param        {Objective} dataInfo - 화면에 보이는 데이터
     *  @param        {Objective} originData - 원본데이터
     *  @description  원본 데이터와 화면에 보이는 dataInfo 데이터 state 저장
     */
    const handleChangeData = useCallback((dataInfo, originData) => {
        setDataInfo(dataInfo);
        setOriginDataInfo(originData);
    }, []);

    /**
     *  @memberOf     DesignTab
     *  @function     handleSendData
     *  @param        {Objective} dataInfo - 화면에 보이는 데이터
     *  @param        {Objective} originData - 원본데이터
     *  @description  원본 데이터와 화면에 보이는 dataInfo 데이터 전달
     */
    const handleSendData = useCallback((dataInfo, originDataInfo) => {
        onSendData(dataInfo, originDataInfo);
    }, [onSendData]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    useEffect(() => {
        if (isDataExist(dataInfo) && isDataExist(originDataInfo)) {
            handleSendData(dataInfo, originDataInfo);
        }
    }, [dataInfo, handleSendData, originDataInfo]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>

                {/*  왼쪽 메뉴 부분 */}
                <DesignMenu
                    nowStep={nowStep}
                    onSelect={handleStep}
                    menuList={MENU_TITLE}/>

                <div className="vertical-box-column bg-white">
                    {/*  오른쪽 컨텐츠 부분 */}
                    <div className="vertical-box">
                        <StepContents
                            viewMode={viewMode}
                            language={language}
                            ID={ID}
                            history={history}
                            referencePopup={handleReferencePopup}
                            onList={goToList}
                            onChangeData={handleChangeData}
                            isSaved={onSave}
                            onSetSave={setSave}
                            isClosed={onClose}
                            onSetClose={setClose}
                            isDataCheckModal={isDataCheckModal}
                            onDataChangeModal={setDataCheckModal}
                            isChangeTabSave={onTabSave}
                            isChangeTabClose={onTabClose}
                            onChangeTabSave={setTabSave}
                            onChangeTabClose={setTabClose}
                            isTabDataCheckModal={isTabDataCheckModal}
                            onChangeDataCheckModal={setTabDataCheckModal}
                        />
                    </div>
                </div>
            </div>

            {isDataCheckModal && (
                <CustomModal
                    title="Confirm"
                    content={"Your changes could not be saved. Do you want to save?"}
                    btnInfoList={YES_NO_MODAL_BUTTON}
                    onYes={() => {
                        setSave(true)
                    }}
                    onClose={() => {
                        setClose(true)
                    }}
                />
            )}
        </>
    );
};

export default React.memo(DesignTab);

