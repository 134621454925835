import React, {useCallback} from 'react';
import classNames from "classnames";
import {
    FILL_REQUIRED_FIELD,
    INCORRECT_EMAIL,
    INCORRECT_DUNSCODE_CHARACTER_NUMBER
} from "constant/ConstantMsg";
import {isDataExist} from "common/commonFunction";

/**
 *  @summary 입력 폼에 사용되는 input text
 *  @author  최종 수정자
 *  @version 1.0, 작업 내용
 *  @see     None
 */

/**
 *  @component    TextField
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  입력 폼에 사용되는 input text
 */
const TextField = (props) => {

    // props
    /*
      name          : 컴포넌트를 구별 하기 위한 이름
      className     : 스타일 클래스
      onChange      : 변경 처리시 호출 할 함수
      currentValue  : 현재 value에 표시될 값
      placeholder   : 값이 없을때 표시하기 위한 값 (현재는 들어오는 파라미터 없음)
      onBlur        : onblur 이벤트 발생 시 호출 할 함수
      className     : 크기 지정
      type          : input type 지정 (ex. password)
      onKeyPress    : key를 눌렀을 때 처리
      validation    : validation 한 값(true, false)
      onFocus       : focus 가 왔을 때 처리
      maxlength     :글자 수 제한
    */
    const {
        name,
        className,
        onChange,
        currentValue,
        placeholder,
        onBlur,
        disabled = false,
        type,
        onKeyPress,
        validation,
        onFocus,
        emailCheck,
        onKeyUp,
        min,
        compare,
        passwordCheck,
        maxlength,
        characterNumberCheck
    } = props;

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     TextField
     *  @function     handleChange
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  props로 전달 된 이벤트 처리 함수 호출
     */
    const handleChange = useCallback((e) => {
        e.preventDefault();
        const targetValue = e.target.value;
        if (onChange !== undefined) {
            onChange(name, targetValue);
        }
    }, [name, onChange]);

    /**
     *  @memberOf     TextField
     *  @function     handleKeyDown
     *  @param        none
     *  @return       {element} e.keyCode가 69일 때는 이벤트 방지
     *  @description  type이 number 일 때 input에 "e" 입력 방지
     */
    const handleKeyDown = useCallback((e) => {
        if (type === 'number') {
            return e.keyCode === 69 ? e.preventDefault() : true;
        }
    }, [type]);


    /**
     *  @memberOf     TextField
     *  @function     handleBlur
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  focus 잃었을 때 처리
     */
    const handleBlur = useCallback((e) => {
        e.preventDefault();
        const targetValue = e.target.value;
        if (onBlur !== undefined) {
            onBlur(name, targetValue);
        }
    }, [name, onBlur]);

    /**
     *  @memberOf     TextField
     *  @function     handleFocus
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  focus가 왔을 때 처리
     */
    const handleFocus = useCallback((e) => {
        e.preventDefault();
        if (onFocus !== undefined) {
            onFocus(name);
        }
    }, [name, onFocus]);

    /**
     *  @memberOf     TextField
     *  @function     getInValidText
     *  @param        none
     *  @return       {element} invaild element
     *  @description  invalid 일 때 출력할 text 내용
     */
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false && isDataExist(currentValue) === false) {
            return (
                <div className="invalid-feedback">{FILL_REQUIRED_FIELD}</div>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [currentValue, validation]);

    /**
     *  @memberOf     TextField
     *  @function     getIncorrectEmailText
     *  @param        none
     *  @return       {element} email invaild element
     *  @description  emailCheck가 incorrect일 때 출력할 text 내용
     */
    const getIncorrectEmailText = useCallback(() => {

        // incorrect 할 때
        if (emailCheck !== undefined && emailCheck === false) {
            return (
                <>
                    <div className="invalid-feedback">{INCORRECT_EMAIL}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [emailCheck]);

    /**
     *  @memberOf     TextField
     *  @function     getIncorrectCharacterNumber
     *  @param        none
     *  @return       {element} character number invaild element
     *  @description  글자 수가 유효하지 않을 때 출력할 text 내용
     */
    const getIncorrectCharacterNumber = useCallback(() => {
        // incorrect 할 때
        if (characterNumberCheck !== undefined && !characterNumberCheck) {

            let incorrectCharacterText;
            switch (name) {
                // DUNS Code 경우
                case 'duns_code':
                    incorrectCharacterText = INCORRECT_DUNSCODE_CHARACTER_NUMBER;
                    break;
                default:
                    incorrectCharacterText = '';
                    break;
            }
            return (
                <>
                    <div className="invalid-feedback">{incorrectCharacterText}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [name, characterNumberCheck]);

    /**
     *  @memberOf     TextField
     *  @function     getIncorrectEmailText
     *  @param        none
     *  @return       {element} email invaild element
     *  @description  confirm password가 incorrect일 때 출력할 text 내용
     */
    const getIncorrectPassword = useCallback(() => {
        // incorrect 할 때
        if (passwordCheck !== undefined && passwordCheck === false) {
            return (
                <>
                    <div className="invalid-feedback">The password confirmation does not match.</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [passwordCheck]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <input autoComplete="new-password" type={type !== undefined ? type : 'text'}
                   className={classNames('form-control', className, {
                       'is-invalid': ((validation !== undefined && validation === false && isDataExist(currentValue) === false) === true
                               || (emailCheck !== undefined && emailCheck === false)) || (passwordCheck !== undefined && passwordCheck === false)
                           || (characterNumberCheck !== undefined && !characterNumberCheck) || compare
                   })}
                   onChange={handleChange}
                   value={currentValue !== undefined ? currentValue : ''}
                   onKeyDown={handleKeyDown}
                   placeholder={placeholder}
                   onBlur={handleBlur}
                   disabled={disabled}
                   onKeyPress={onKeyPress}
                   onFocus={handleFocus}
                   onKeyUp={onKeyUp}
                   min={min ? min : undefined}
                   maxLength={maxlength}/>
            {getInValidText()}
            {getIncorrectEmailText()}
            {getIncorrectPassword()}
            {getIncorrectCharacterNumber()}
        </>
    );
}

export default React.memo(TextField);
