import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import WhereClauseEditGrid from "./WhereClauseEditGrid";
import produce from "immer";
import SelectBox from "../../../../../components/SelectBox";
import cn from "classnames";
import {COMMAND} from "../../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import {AXIOS_GET, isDataExist} from "../../../../../../common/commonFunction";
import Select from "react-select";
import {isEmpty} from "codemirror/src/util/misc";
import {showConfirmAlert} from "../../../../../components/alertModal";
import {DATA_AT_LEAST_ONE} from "../../../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       WhereClauseEditModal
 *  @constant       {Object} WHERE_CLAUSE_NAME
 *  @description    WhereClauseEditModal field 명 정의
 */
const WHERE_CLAUSE_NAME = {
    VARIABLE: 'variable',
    COMPARATOR: 'comparator',
    VALUE: 'value',
    TYPE: 'type'
};

/**
 *  @memberOf       WhereClauseEditModal
 *  @constant       {Object} VALIDATION_FIELD
 *  @description    WhereClauseEdit input 항목 validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    variable: true,
    comparator: true,
    value: true,
    type: true
};

/**
 *  @memberOf       WhereClauseEditModal
 *  @constant       {Array} SOFT_HARD_LIST
 *  @description    softHard dropDown 리스트
 */
const SOFT_HARD_LIST = [{
    text: "Soft",
    value: "Soft"
}, {
    text: "Hard",
    value: "Hard"
}];

/**
 *  @memberOf       WhereClauseEditModal
 *  @constant       {Array} COMPARATOR_LIST
 *  @description    comparator dropDown 리스트
 */
const COMPARATOR_LIST = [{
    text: "LT",
    value: "LT"
}, {
    text: "LE",
    value: "LE"
}, {
    text: "GT",
    value: "GT"
}, {
    text: "GE",
    value: "GE"
}, {
    text: "EQ",
    value: "EQ"
}, {
    text: "NE",
    value: "NE"
}, {
    text: "IN",
    value: "IN"
}, {
    text: "NOTIN",
    value: "NOTIN"
}];

/**
 *  @memberOf     WhereClauseEditModal
 *  @constant     {String} DEFAULT_VALUE
 *  @description  기본 빈 값
 */
const DEFAULT_VALUE = '';

/**
 *  @memberOf     WhereClauseEditModal
 *  @constant     {Object} DEFAULT_DATA_WHERE_CLAUSE
 *  @description  DEFAULT WHERE_CLAUSE 데이터
 */
const DEFAULT_DATA_WHERE_CLAUSE = {
    variable: "",
    comparator: "",
    value: "",
    type: "Soft"
};

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    WhereClauseEditModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  whereClause 항목의 클릭 시 실행되는 모달
 */
const WhereClauseEditModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     WhereClauseEditModal
     *  @type         {Object} props
     *  @property     {String} ID -ID 값
     *  @property     {Function} onClose - WhereClauseEdit Modal의 No 버튼 상태
     *  @property     {Object} onModalOk - WhereClauseEdit Modal의 Ok 버튼 상태
     *  @property     {Array} variableMataDataGridList - WhereClauseEdit Modal의 gridList
     *  @property     {Array} variableList -  input variable 리스트
     *  @property     {String} targetVariable -  선택 한 Variable 데이터
     *  @property     {String} targetDataSet -  선택한 DataSet 데이터
     *  @property     {String} targetSubID -  호출받은 subID 데이터
     *  @property     {Number} dataOrder - 선택한 whereClause order
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        onClose,
        onModalOk,
        headerText,
        variableMataDataGridList,
        variableList,
        targetVariable,
        targetDataSet,
        targetSubID,
        whereDataOrder
    } = props;

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Array} gridModalDataList
     *  @description  whereClause Modal Grid에 표시 될 리스트
     */
    const [gridModalDataList, setGridModalDataList] = useState([]);

    /**
     *  @memberOf       WhereClauseEditModal
     *  @var            {Array} inputInfo
     *  @description    whereClause 입력 필드 default 값
     */
    const [inputInfo, setInputInfo] = useState(DEFAULT_DATA_WHERE_CLAUSE);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Array} searchVariable
     *  @description  dropDown Variable 필드 입력 값
     */
    const [searchVariable, setSearchVariable] = useState('');

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Array} valueList
     *  @description  dropDown Value dropdown 리스트
     */
    const [valueList, setValueList] = useState([]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Array} gridValueList
     *  @description  WhereClause Edit Grid 에 전달될 Value dropdown 리스트
     */
    const [gridValueList, setGridValueList] = useState({});

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Object} validation
     *  @description  validation default 값
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @var          {Object} selected
     *  @description  select 컴포넌트 선택 여부
     */
    const [selected, setSelected] = useState({
        [WHERE_CLAUSE_NAME.VARIABLE]: false,
        [WHERE_CLAUSE_NAME.VALUE]: false
    });

    /**
     *  @memberOf       WhereClauseEditModal
     *  @async          dataProcess
     *  @param          {String} command - 통신 데이터 처리 action type
     *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
     *  @return         {Object} response.data - 서버 응답 데이터
     *  @description    command 에 따른 통신 데이터 처리
     */
    async function dataProcess(command, params) {
        const {requestUrl, ID, variable} = params;
        let url = null;
        let response = null;

        switch (command) {
            // 데이터 상세 정보 요청
            case COMMAND.DATA_LIST:
            case 'GRID_VALUE_LIST':
                url = `${requestUrl}/${ID}?variable=${variable}`;
                response = await AXIOS_GET(url);
                break;

            case COMMAND.DATA_INFO:
                url = requestUrl
                response = await AXIOS_GET(url);
                break;

            default:
                return null;
        }
        return response.data;
    }

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     handleChange
     *  @param        {String} name -  입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @param        {String} action - react-select action 타입 값 ('menu-close' | 'input-blur' - ReactSelect 내부 액션 값)
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((name, value, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            if (name === 'variable') {
                if (typeof value === "object") {
                    setInputInfo(
                        produce(inputInfo, draft => {
                            draft[name] = value.value
                        }));

                    setSearchVariable(value.label);
                    setValueList([]);
                } else {
                    setInputInfo(
                        produce(inputInfo, draft => {
                            draft[name] = value;
                        }));
                }

                setValueList([]);
            } else if (name === 'value') {
                if (typeof value === "object") {
                    setInputInfo(
                        produce(inputInfo, draft => {
                            draft[name] = value.label
                        }));
                } else {
                    setInputInfo(
                        produce(inputInfo, draft => {
                            draft[name] = value;
                        }));
                }
            } else {
                setInputInfo(
                    produce(inputInfo, draft => {
                        draft[name] = value
                    })
                );
            }
        }
    }, [inputInfo]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     handleSelect
     *  @param        {String} name -  입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleSelect = useCallback((name, value) => {
        if (name === 'variable') {
            if (typeof value === "object") {
                setInputInfo(
                    produce(inputInfo, draft => {
                        draft[name] = value.label
                        draft.value = ""
                    }));

                setSearchVariable(value.label);
                setValueList([]);
            } else {
                setInputInfo(
                    produce(inputInfo, draft => {
                        draft[name] = value;
                    }));
            }

            setValueList([]);
        } else if (name === 'value') {
            if (typeof value === "object") {
                setInputInfo(
                    produce(inputInfo, draft => {
                        draft[name] = value.label
                    }));
            } else {
                setInputInfo(
                    produce(inputInfo, draft => {
                        draft[name] = value;
                    }));
            }
        } else {
            setInputInfo(
                produce(inputInfo, draft => {
                    draft[name] = value
                }));
        }

        // select 여부 체크
        setSelected(prevState => ({
            [name]: !prevState[name]
        }));
    }, [inputInfo]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     getValueList
     *  @description  value 리스트 호출하는 함수
     */
    const getValueList = useCallback(() => {
        const command = COMMAND.DATA_LIST; // 데이터 리스트 요청 command

        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/whereClause/codelist`,
            ID: ID,
            variable: searchVariable
        };

        netWorkAgent.current.request(command, params);
    }, [ID, searchVariable]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     validateData
     *  @return       {Boolean} true - success, false - fail
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        let returnBool;

        // validation 결과 객체
        let validateData = {};

        // validation 할 key
        const validateKeys = Object.keys(VALIDATION_FIELD);

        // data validate 체크
        for (const key of validateKeys) {
            validateData[key] = isDataExist(inputInfo[key]);
        }

        // 결과 값 setting
        setValidation(validateData);

        // 모든 validate 결과가 true일 때 true
        returnBool = Object.values(validateData).every(item => {
            return item === true;
        });

        return returnBool;
    }, [inputInfo]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     handleAdd
     *  @description  추가 버튼 클릭 시 실행되는 함수
     */
    const handleAdd = useCallback(() => {
        const valid = validateData();
        if (valid === false) {
            return;
        }

        const inputField = inputInfo;
        let addData = {
            ID: "",
            order: 0,
            mappingVariable: targetVariable,
            subID: "",
            domain: targetDataSet,
            variable: inputField.variable,
            comparator: inputField.comparator,
            value: inputField.value,
            type: inputField.type,
            label: "",
            keySequence: "",
            dataType: "",
            length: "",
            significantDigits: "",
            format: "",
            mandatory: 0,
            assignedValue: "",
            codelist: "",
            origin: "",
            source: "",
            pages: "",
            method: "",
            predecessor: "",
            role: "",
            comment: "",
            developerNotes: ""
        };

        let addSubIDArr = {}
        if (gridModalDataList.length > 1) {
            gridModalDataList.forEach((item) => {
                if (item.subID !== "") {
                    addSubIDArr = {...addData, subID: item.subID, order: item.order};
                }
            });
        } else if (gridModalDataList.length === 0) {
            addSubIDArr = {...addData, subID: "", order: whereDataOrder};
        } else if (gridModalDataList.length === 1) {
            gridModalDataList.forEach((item) => {
                addSubIDArr = {...addData, subID: targetSubID, order: item.order};
            });
        }

        setGridModalDataList(
            produce(gridModalDataList, draft => {
                draft.push(addSubIDArr);
            })
        );
        setInputInfo(DEFAULT_DATA_WHERE_CLAUSE);
    }, [gridModalDataList, inputInfo, targetDataSet, targetSubID, targetVariable, validateData, whereDataOrder]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     handleOk
     *  @description  OK 버튼 클릭 시 실행되는 함수
     */
    const handleOk = useCallback(() => {
        if (isEmpty(gridModalDataList)) {
            showConfirmAlert(DATA_AT_LEAST_ONE);
            return;
        }

        let modalOkList = [];
        //whereClause order가 같을 경우 subID가 동일 해야한다.
        gridModalDataList.forEach((data) => {
            if (gridModalDataList.length > 0) {
                let pushSubID = data.subID;
                modalOkList.push({...data, subID: data.subID});
                modalOkList.forEach((item) => {
                    if (item.subID === "") {
                        item.subID = pushSubID;
                    }
                });
            } else {
                modalOkList.push({...data, subID: targetSubID});
            }
        });

        if (onModalOk) {
            onModalOk(modalOkList);
        }
    }, [gridModalDataList, onModalOk, targetSubID]);

    /**
     *  @function  WhereClauseEditModal
     *  @param     {Array} handleGridChange
     *  @description  whereClause modal의 grid 데이터 저장
     */
    const handleGridChange = useCallback((changeGrid) => {
        setGridModalDataList(changeGrid);
    }, []);

    /**
     *  @function  WhereClauseEditModal
     *  @param     {Array} handleGridValueList
     *  @description  whereClause modal의 grid 데이터 저장
     */
    const handleGridValueList = useCallback((variableList) => {
        variableList.forEach((variable) => {
            const command = 'GRID_VALUE_LIST';
            const params = {
                requestUrl: `${SDTM_MAPPING_URL}/whereClause/codelist`,
                ID: ID,
                variable: variable
            };
            netWorkAgent.current.request(command, params);
        });
    }, [ID]);

    /**
     *  @memberOf     WhereClauseEditModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;
            switch (command) {
                case COMMAND.DATA_LIST :
                    if (data.hasOwnProperty('data')) {
                        const valueArr = data.data.map((item) => {
                            return {
                                value: item.value,
                                label: item.value
                            }
                        });
                        setValueList(valueArr);
                    }
                    break;

                case 'GRID_VALUE_LIST' :
                    if (data.hasOwnProperty('data')) {
                        let _data = data.data;
                        let _gridValueList = {};
                        _data.forEach((item) => {
                            if (!_gridValueList[item.terminologyID]) {
                                _gridValueList[item.terminologyID] = [item.value];
                            } else {
                                _gridValueList[item.terminologyID].push(item.value);
                            }
                        });
                        setGridValueList(Object.assign(gridValueList, _gridValueList))
                    }
                    break;

                default:
                    break;
            }
        }
    }, [gridValueList]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    useEffect(() => {
        if (inputInfo.variable !== '' && searchVariable !== '') {
            getValueList();
        }
    }, [getValueList, inputInfo.variable, searchVariable]);

    useEffect(() => {
        setGridModalDataList(variableMataDataGridList);
    }, [variableMataDataGridList]);

// handleSelect 한 후 입력칸 hidden 처리 없애기
    React.useLayoutEffect(() => {
        const inputEl = document.getElementById(WHERE_CLAUSE_NAME.VALUE);
        if (!inputEl) {
            return;
        }

        // prevent input from being hidden after selecting
        inputEl.style.opacity = "1";
    }, [inputInfo.value, selected.value]);

    // handleSelect 한 후 입력칸 hidden 처리 없애기
    React.useLayoutEffect(() => {
        const inputEl = document.getElementById(WHERE_CLAUSE_NAME.VARIABLE);
        if (!inputEl) {
            return;
        }

        // prevent input from being hidden after selecting
        inputEl.style.opacity = "1";
    }, [inputInfo.variable, selected.variable]);

    /*#################################################################
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse}/>
            <Modal isOpen style={{maxWidth: "1000px", minHeight: '90vh'}}>
                <ModalHeader
                    className={'header-title bg-main'}
                    toggle={handleClose}>
                    {headerText}
                </ModalHeader>

                <ModalBody>
                    <div className="form-group row align-items-top m-b-20">
                        <div className=" d-flex justify-content-between">
                            <div className="w-100">
                                <WhereClauseEditGrid
                                    ID={`whereClause-grid`}
                                    dataList={gridModalDataList}
                                    variableList={variableList.map(variable => variable.label)}
                                    comparatorList={COMPARATOR_LIST.map(comparator => comparator.text)}
                                    gridValueList={gridValueList}
                                    setGridValueList={handleGridValueList}
                                    onGridChange={handleGridChange}
                                    isWordWrap={true}/>
                                <table className="table table-valign-middle table-striped"
                                       style={{tableLayout: "fixed"}}>
                                    <thead>
                                    <tr>
                                        <th width={"23%"}>Variable
                                            <span className='text-red p-l-2 p-r-2'>*</span></th>
                                        <th width={"23%"}>
                                            Comparator
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </th>
                                        <th width={"23%"}>Value
                                            <span className='text-red p-l-2 p-r-2'>*</span></th>
                                        <th width={"23%"}>SoftHard
                                            <span className='text-red p-l-2 p-r-2'>*</span></th>
                                        <th width={"8%"}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="px-1 p-l-30">
                                            <Select
                                                inputId={WHERE_CLAUSE_NAME.VARIABLE}
                                                name={WHERE_CLAUSE_NAME.VARIABLE}
                                                backspaceRemovesValue={false}
                                                isSearchable={true}
                                                isClearable={false}
                                                classNamePrefix={"react-select"}
                                                onSelectResetsInput={false}
                                                className={(validation.hasOwnProperty('value') && validation[WHERE_CLAUSE_NAME.VARIABLE] !== undefined && validation[WHERE_CLAUSE_NAME.VARIABLE] === false) &&
                                                    "is-invalid"
                                                }
                                                options={variableList}
                                                onInputChange={(value, action) => handleChange(WHERE_CLAUSE_NAME.VARIABLE, value, action)}
                                                inputValue={inputInfo[WHERE_CLAUSE_NAME.VARIABLE]}
                                                value={inputInfo && inputInfo[WHERE_CLAUSE_NAME.VARIABLE]}
                                                onChange={(value, action) => handleSelect(WHERE_CLAUSE_NAME.VARIABLE, value, action)}
                                                label="Single select"
                                                placeholder={"Input Value..."}
                                            />
                                        </td>

                                        <td className="px-1">
                                            <SelectBox
                                                name={WHERE_CLAUSE_NAME.COMPARATOR}
                                                dataList={COMPARATOR_LIST}
                                                currentValue={inputInfo && inputInfo[WHERE_CLAUSE_NAME.COMPARATOR]}
                                                onChange={(name, value) => handleSelect(name, value)}
                                                validation={validation[WHERE_CLAUSE_NAME.COMPARATOR]}
                                                defaultValue={DEFAULT_VALUE}
                                                isEmptyValue={true}
                                            />

                                        </td>

                                        <td className="px-1">
                                            <Select
                                                inputId={WHERE_CLAUSE_NAME.VALUE}
                                                name={WHERE_CLAUSE_NAME.VALUE}
                                                backspaceRemovesValue={false}
                                                isSearchable={true}
                                                isClearable={false}
                                                classNamePrefix={"react-select"}
                                                onSelectResetsInput={false}
                                                className={(validation.hasOwnProperty('value') && validation[WHERE_CLAUSE_NAME.VALUE] !== undefined && validation[WHERE_CLAUSE_NAME.VALUE] === false) &&
                                                    "is-invalid"
                                                }
                                                options={valueList}
                                                onInputChange={(value, action) => handleChange(WHERE_CLAUSE_NAME.VALUE, value, action)}
                                                inputValue={inputInfo[WHERE_CLAUSE_NAME.VALUE]}
                                                value={inputInfo && inputInfo[WHERE_CLAUSE_NAME.VALUE]}
                                                onChange={(value, action) => handleSelect(WHERE_CLAUSE_NAME.VALUE, value, action)}
                                                label="Single select"
                                                placeholder={"Input Value..."}
                                            />
                                        </td>

                                        <td className="px-1">
                                            <SelectBox
                                                name={WHERE_CLAUSE_NAME.TYPE}
                                                dataList={SOFT_HARD_LIST}
                                                currentValue={inputInfo && inputInfo[WHERE_CLAUSE_NAME.TYPE]}
                                                onChange={(name, value) => handleSelect(name, value)}
                                                validation={validation[WHERE_CLAUSE_NAME.TYPE]}
                                                defaultCaption={SOFT_HARD_LIST[0].value}
                                            />
                                        </td>

                                        <td className="px-1">
                                            <button
                                                className={cn("btn btn-blue")}
                                                onClick={handleAdd}>
                                                +
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter className="p-3">
                    <button
                        className="btn btn-blue px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className="btn btn-new px-md-5 mx-1"
                        onClick={handleOk}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default React.memo(WhereClauseEditModal);