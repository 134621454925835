import React, { useEffect } from "react";
import useToast from "hooks/useToast";
import {LOG_OUT_MESSAGE} from "constant/ConstantMsg";
import { clearSession } from "common/commonFunction";
import {ALERT_TYPE} from "../../constant/ConstantName";

const LogOutAlert = (props) => {

  const {onComplete} = props;

  // toast 창 구현
  const [showToast] = useToast();

  useEffect(() => {
    showToast(() => {
      clearSession();
      if (onComplete !== undefined) {
        onComplete();
      }
    }, ALERT_TYPE.SUCCESS, LOG_OUT_MESSAGE);

  }, [onComplete, showToast]);

  return (
    <>
    </>
  );

};

export default LogOutAlert;

