import React, {useCallback, useEffect, useRef, useState} from "react";
import {AXIOS_PUT, AXIOS_GET, getSessionState} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "constant/ConstantURL";
import NetworkLayout from "common/NetworkLayout";
import cn from "classnames";
import useToast from "hooks/useToast";
import TextField from "../../../../../components/TextField";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       SdmStudyID
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {ID, sendObject} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 수정 요청
        case COMMAND.DATA_LIST :
            url = `${SDTM_MAPPING_URL}/SDM/mappingInfo/${ID}`
            response = await AXIOS_GET(url);
            break;
            
        case COMMAND.DATA_UPDATE :
            url = `${SDTM_MAPPING_URL}/SDM/mappingInfo/${ID}`
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmStudyID
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal에서 Design Matrix 탭 편집 컴포넌트
 */
const SdmStudyID = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyID
     *  @type         {Object} props
     *  @property     {String} ID - 리스트 ID
     *  @property     {Object} history -  url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {ID, history, onClose} = props;

    /**
     *  @memberOf     SdmStudyID
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} designID - design ID
     *  @description  session 에서 받아오는 정보
     */
    const {lock, designID} = getSessionState();

    /**
     *  @memberOf     SdmStudyID
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmStudyID
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SdmStudyID
     *  @var          {String} studyID
     *  @description  서버에서 받아오는 원본 데이터
     */
    const [studyID, setStudyID] = useState('');

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmStudyID
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_LIST;

        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/SDM/mappingInfo/${ID}`,
            ID: ID
        };
        
        netWorkAgent.current.request(command, params);
    }, [ID, designID]);

    /**
     *  @memberOf     SdmStudyID
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SdmStudyID
     *  @function     handleSave
     *  @description  save 버튼 클릭 시 실행되어 데이터 저장 api 호출
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            // 데이터 수정 요청
            const sendObject = {
                STUDYID: studyID
            };

            const command = COMMAND.DATA_UPDATE;
            // 데이터 수정에 필요한 parameter
            let params = {
                requestUrl: `${SDTM_MAPPING_URL}/SDM/mappingInfo/${ID}`,
                ID: ID,
                sendObject: JSON.stringify(sendObject)
            };

            // back-end 데이터 처리 요청
            netWorkAgent.current.request(command, params);
        }
    }, [ID, lock, studyID]);

    /**
     *  @memberOf     SdmStudyID
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;
            switch (command) {
                
                case COMMAND.DATA_LIST :
                    if (data.hasOwnProperty('data') && data.data !== null) {
                        setStudyID(data.data.STUDYID);
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                default:
                    break;
            }
        }
    }, [getDataInfo, lock, showToast]);
    
    const handleChange = (event, value) => {
        if (value.length > 16) {
            return false;
        }
        setStudyID(value);
    }

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, []);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="form-group row align-items-center m-b-20 p-t-20">
                <label className="col-2 m-l-20">
                    Study ID
                </label>
                <div className="col-9">
                    <div className='w-75'>
                        <TextField
                            onChange={handleChange}
                            currentValue={studyID}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-button">
                <div className="d-flex justify-content-end p-10 border-top">
                    <button
                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(SdmStudyID);

