import React, {useCallback, useRef, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {getSessionState, setSessionState} from "../../../../../../common/commonFunction";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import {PRM_MENU_TITLE} from "../../../../design/DesignTab";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Link} from "react-router-dom";
import SdmArmEdit from "./SdmArmEdit";
import SdmStudyEpochs from "./SdmStudyEpochs";
import SdmStudyElements from "./SdmStudyElements";
import SdmDesignMatrix from "./SdmDesignMatrix";
import SdmStudyID from "./SdmStudyID";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SdmEditModal
 *  @constant     {String} STEP_STATE
 *  @description  tab 정보를 나타내는 define
 */
const STEP_STATE = 'step';
/**
 *  @memberOf     SdmEditModal
 *  @constant     {Object} DESIGN_STEP
 *  @description  SDM Edit 팝업의 왼쪽 메뉴 define
 */
const DESIGN_STEP = {
    NONE: -1,
    ARM: 0,
    CELL: 1,
    SEGMENT: 2,
    MATRIX: 3,
};

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmEditModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal 컴포넌트
 */
const SdmEditModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdmEditModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, onClose} = props;

    /**
     *  @memberOf     SdmEditModal
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, language} = getSessionState();

    /**
     *  @memberOf     SdmEditModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmEditModal
     *  @var          {String} nowStep
     *  @description  왼쪽 메뉴에서 선택 된 메뉴 정보
     */
    const [nowStep, setNowStep] = useState(DESIGN_STEP.ARM);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmEditModal
     *  @function     handleStep
     *  @param        {Number}  step - 이동하려는 step
     *  @description  Tab 이동 함수
     */
    const handleStep = useCallback((step) => {
        // session data 에 셋팅
        let sessionData = getSessionState();

        sessionData[STEP_STATE] = step;
        setSessionState(sessionData);

        setNowStep(step);
    }, []);

    /**
     *  @memberOf     SdmEditModal
     *  @function     handleSelect
     *  @param        {Number} menuIndex - 목록 배열의 index
     *  @description  메뉴 선택 시 실행되어 상위 이벤트 함수 호출
     */
    const handleSelect = useCallback((menuIndex) => {
        if (nowStep === menuIndex) {
            return;
        }

        if (handleStep !== undefined) {
            handleStep(menuIndex);
        }
    }, [nowStep, handleStep]);

    /**
     *  @memberOf     SdmEditModal
     *  @function     handleClose
     *  @description  Modal 창 닫는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} history={history}/>
            <Modal isOpen style={{maxWidth: "1800px", minHeight: '90vh'}}>
                <ModalHeader className={"header-title bg-main"} toggle={handleClose}>
                    SDM EDIT
                </ModalHeader>

                <ModalBody>
                    <div className="row">
                        <div className="col-2 px-4 border-right">
                            <div className="vertical-box with-grid inbox bg-light"
                                 style={{height: "calc(100% - 82px)"}}>
                                <PerfectScrollbar options={{suppressScrollX: true}}
                                                  style={{height: "calc(100vh - 208px)"}}>
                                    <div className="m-t-20">
                                        <div className="wrapper p-0">
                                            <div className="nav-title"></div>
                                            <ul className="nav nav-inbox">
                                                {PRM_MENU_TITLE.map((step, index) => {
                                                    let classNameData = 'cursor-pointer';

                                                    // 활성화된 현재 step 이 같으면 active
                                                    if (index === nowStep) {
                                                        classNameData = 'active';
                                                    }

                                                    return (
                                                        <li className={classNameData}
                                                            onClick={() => handleSelect(index)}
                                                            key={`design-menu-${index}`}>
                                                            <Link className="p-r-0 p-l-15">
                                                                {language === 'en' ? step.MENU : step.MENU_KR}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>

                        <div className="col-10 px-3">
                            <div className="property">
                                <PerfectScrollbar
                                    options={{suppressScrollX: true}}
                                    style={{height: 'calc(100vh - 200px)'}}>

                                    <div>
                                        {nowStep !== undefined && nowStep === 0 && (
                                            <SdmStudyID
                                                ID={ID}
                                                history={history}
                                                onClose={handleClose}/>
                                        )}
                                        
                                        {nowStep !== undefined && nowStep === 1 && (
                                            <SdmArmEdit
                                                ID={ID}
                                                history={history}
                                                onClose={handleClose}/>
                                        )}

                                        {nowStep !== undefined && nowStep === 2 && (
                                            <SdmStudyEpochs
                                                ID={ID}
                                                history={history}
                                                onClose={handleClose}/>
                                        )}

                                        {nowStep !== undefined && nowStep === 3 && (
                                            <SdmStudyElements
                                                ID={ID}
                                                history={history}
                                                onClose={handleClose}/>
                                        )}

                                        {nowStep !== undefined && nowStep === 4 && (
                                            <SdmDesignMatrix
                                                ID={ID}
                                                history={history}
                                                onClose={handleClose}/>
                                        )}
                                    </div>

                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(SdmEditModal);