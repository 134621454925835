import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {getSessionState} from "../../../../common/commonFunction";
import useWindowSize from "hooks/useWindowSize";
import deleteIcon from "../../../../assets/icons/delete.svg";
import {showConfirm} from "../../../components/alertModal";
import {DATA_CHANGE_CONTENT} from "../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     IPConfigGrid
 *  @constant     {Array} SHAPE_LIST
 *  @description  shape 카테고리에 표시할 옵션 리스트
 */
const SHAPE_LIST = [
    {
        text: 'Tablet',
        value: 'Tablet'
    },
    {
        text: 'Capsule',
        value: 'Capsule'
    },
    {
        text: 'Cream',
        value: 'Cream'
    },
    {
        text: 'Gel',
        value: 'Gel'
    },
    {
        text: 'Ointment',
        value: 'Ointment'
    },
    {
        text: 'Patch',
        value: 'Patch'
    },
    {
        text: 'Powder',
        value: 'Powder'
    },
    {
        text: 'Spray',
        value: 'Spray'
    },
    {
        text: 'Suppository',
        value: 'Suppository'
    },
    {
        text: 'Suspension',
        value: 'Suspension'
    },
    {
        text: 'Gas',
        value: 'Gas'
    },
    {
        text: 'Aerosol',
        value: 'Aerosol'
    }
];

/**
 *  @memberOf     IPConfigGrid
 *  @constant     {Array} UNIT_LIST
 *  @description  unit 카테고리에 표시할 옵션 리스트
 */
const UNIT_LIST = [
    {
        text: 'g',
        value: 'g'
    },
    {
        text: 'mg',
        value: 'mg'
    },
    {
        text: 'L',
        value: 'L'
    },
    {
        text: 'mL',
        value: 'mL'
    },
    {
        text: 'Tablet',
        value: 'Tablet'
    },
    {
        text: 'Capsule',
        value: 'Capsule'
    },
    {
        text: 'Pack',
        value: 'Pack'
    },
    {
        text: 'IU',
        value: 'IU'
    },
    {
        text: 'PUFF',
        value: 'PUFF'
    },
    {
        text: 'ug',
        value: 'ug'
    }
];

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    IPConfigGrid
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  concept의 IP에서  Ip 리스트를 grid로 표시하는 컴포넌트
 */
const IPConfigGrid = (props) => {
    /**
     *  @memberOf     IPConfigGrid
     *  @type         {Object} props
     *  @property     {Array} dataList - 화면에 표시할 데이터 리스트
     *  @property     {Number} itemKey - item Key
     *  @property     {String} ID - grid ID
     *  @property     {Function} onChange - grid 변경 시(수정, Drag & Drop, 삭제) 호출 될 상위 이벤트 함수
     *  @property     {String} name - ip data name 값
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {dataList, ID, onChange, name} = props;

    /**
     *  @memberOf      IPConfigGrid
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock, language} = getSessionState();

    /**
     *  @memberOf     IPConfigGrid
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     IPConfigGrid
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     IPConfigGrid
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        showRowNumColumn: true,  // no 표시
        wrapSelectionMove: false,
        enableSorting: false,
        editable: lock !== 2,
        wordWrap: false,
        autoGridHeight : true
        // height: 300,
        // enableDrag: lock !== 2,
        // enableDragByCellDrag: lock !== 2,
        // enableDrop: lock !== 2
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     IPConfigGrid
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // grid 데이터 setting 작업
    useEffect(() => {
        tableRef.current = window.AUIGrid.create(`#${ID}`, columnLayout, auiGridProps);

        if (dataList.length > 0) {
            window.AUIGrid.setGridData(tableRef.current, dataList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#${ID}`);
        }
    }, [ID]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell edit 이벤트
        window.AUIGrid.bind(tableRef.current, "cellEditEnd", (event) => {
            if (onChange !== undefined) {
                window.AUIGrid.updateRow(tableRef.current, event.rowIndex);

                const changeGridData = window.AUIGrid.getGridData(tableRef.current);

                onChange(changeGridData, name);
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "dropEnd", (event) => {
            if (onChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, name);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (onChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, name);
            }
        });
    }, [onChange, name]);

    /**
     *  @memberOf     IPConfigGrid
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'shape',
            headerText: (language === 'en'?'Shape':'제형'),
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: SHAPE_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: 'name',
            headerText:(language === 'en'? 'Name':'이름'),
            editable: lock !== 2
        },
        {
            dataField: 'dose',
            headerText:  (language === 'en'? 'Dose': '용량'),
            editable: lock !== 2
        },
        {
            dataField: 'unit',
            headerText: (language === 'en'? 'Unit' : '단위'),
            editable: lock !== 2,
            editRenderer: {
                type: "ComboBoxRenderer",
                list: UNIT_LIST,
                keyField: "value", // key 에 해당되는 필드명
                valueField: "text", // value 에 해당되는 필드명
                showEditorBtnOver: true
            }
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        showConfirm(DATA_CHANGE_CONTENT, () => {
                            handleDeleteTableRow(event);
                        });
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <div id={ID}/>
        </React.Fragment>
    );
};

export default React.memo(IPConfigGrid);