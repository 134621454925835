// TS 도메인 컬럼 정보
export const TS_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        width: "5%",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        width: "5%",
        editable: false
    },
    {
        dataField: "TSSEQ",
        headerText: "TSSEQ",
        width: "5%",
        editable: false
    },
    {
        dataField: "TSGRPID",
        headerText: "TSGRPID",
        editable: false
    },
    {
        dataField: "TSPARMCD",
        headerText: "TSPARMCD",
        editable: false
    },
    {
        dataField: "TSPARM",
        headerText: "TSPARM",
        editable: false
    },
    {
        dataField: "TSVAL",
        headerText: "TSVAL",
        editable: false
    },
    {
        dataField: "TSVALNF",
        headerText: "TSVALNF",
        editable: false
    },
    {
        dataField: "TSVALCD",
        headerText: "TSVALCD",
        editable: false
    },
    {
        dataField: "TSVCDREF",
        headerText: "TSVCDREF",
        editable: false
    },
    {
        dataField: "TSVCDVER",
        headerText: "TSVCDVER",
        editable: false
    }
];

// TA 도메인 컬럼 정보
export const TA_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        width: "15%",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        width: "5%",
        editable: false
    },
    {
        dataField: "ARMCD",
        headerText: "ARMCD",
        width: "5%",
        editable: false
    },
    {
        dataField: "ARM",
        headerText: "ARM",
        editable: false
    },
    {
        dataField: "TAETORD",
        headerText: "TAETORD",
        editable: false
    },
    {
        dataField: "ETCD",
        headerText: "ETCD",
        editable: false
    },
    {
        dataField: "ELEMENT",
        headerText: "ELEMENT",
        editable: false
    },
    {
        dataField: "TABRANCH",
        headerText: "TABRANCH",
        editable: false
    },
    {
        dataField: "TATRANS",
        headerText: "TATRANS",
        editable: false
    },
    {
        dataField: "EPOCH",
        headerText: "EPOCH",
        editable: false
    }
];

// TE 도메인 컬럼 정보
export const TE_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        width: "5%",
        editable: false
    },
    {
        dataField: "ETCD",
        headerText: "ETCD",
        editable: false
    },
    {
        dataField: "ELEMENT",
        headerText: "ELEMENT",
        editable: false
    },
    {
        dataField: "TESTRL",
        headerText: "TESTRL",
        width: "25%",
        editable: false
    },
    {
        dataField: "TEENRL",
        headerText: "TEENRL",
        width: "25%",
        editable: false
    },
    {
        dataField: "TEDUR",
        headerText: "TEDUR",
        editable: false
    }
];

// TV 도메인 컬럼 정보
export const TV_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        width: "5%",
        editable: false
    },
    {
        dataField: "VISITNUM",
        headerText: "VISITNUM",
        editable: false
    },
    {
        dataField: "TVSTRL",
        headerText: "TVSTRL",
        width: "35%",
        editable: false
    },
    {
        dataField: "TVENRL",
        headerText: "TVENRL",
        width: "35%",
        editable: false
    },
];

// TM 도메인 컬럼 정보
export const TM_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        editable: false
    },
    {
        dataField: "MIDSTYPE",
        headerText: "MIDSTYPE",
        width: "20%",
        editable: false
    },
    {
        dataField: "TMDEF",
        headerText: "TMDEF",
        width: "45%",
        editable: false
    },
    {
        dataField: "TMRPT",
        headerText: "TMRPT",
        editable: false
    }
];

// TI 도메인 컬럼 정보
export const TI_COLUMN_LIST = [
    {
        dataField: "row",
        headerText: "Row",
        width: "5%",
        editable: false
    },
    {
        dataField: "STUDYID",
        headerText: "STUDYID",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "DOMAIN",
        editable: false
    },
    {
        dataField: "IETESTCD",
        headerText: "IETESTCD",
        editable: false
    },
    {
        dataField: "IETEST",
        headerText: "IETEST",
        width: "45%",
        editable: false
    },
    {
        dataField: "IECAT",
        headerText: "IECAT",
        editable: false
    },
    {
        dataField: "TIVERS",
        headerText: "TIVERS",
        editable: false
    }
];

//TS 도메인 예제 데이터
export const TSExamDataList = [
    {
        row: 1,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "ADDON",
        TSPARM: "Added on to Existing Treatments",
        TSVAL: "Y",
        TSVALNF: "",
        TSVALCD: "C49488",
        TSVCDREF: "CDISC",
        TSVCDVER: "2011-06-10"
    },
    {
        row: 2,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "AGEMAX",
        TSPARM: "Planned Maximum Age of Subjects",
        TSVAL: "P70Y",
        TSVALNF: "",
        TSVALCD: "",
        TSVCDREF: "ISO 8601",
        TSVCDVER: ""
    },
    {
        row: 3,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "AGEMIN",
        TSPARM: "Planned Maximum Age of Subjects",
        TSVAL: "P18M",
        TSVALNF: "",
        TSVALCD: "",
        TSVCDREF: "ISO 8601",
        TSVCDVER: ""
    },
    {
        row: 4,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "LENGTH",
        TSPARM: "Trial Length",
        TSVAL: "P3M",
        TSVALNF: "",
        TSVALCD: "",
        TSVCDREF: "ISO 8601",
        TSVCDVER: ""
    },
    {
        row: 5,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "PLANSUB",
        TSPARM: "Planned Number of Subjects",
        TSVAL: "300",
        TSVALNF: "",
        TSVALCD: "",
        TSVCDREF: "",
        TSVCDVER: ""
    },
    {
        row: 6,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "RANDOM",
        TSPARM: "Trial is Randomized",
        TSVAL: "Y",
        TSVALNF: "",
        TSVALCD: "C49488",
        TSVCDREF: "CDISC",
        TSVCDVER: "2011-06-10"
    },
    {
        row: 7,
        STUDYID: "XYZ",
        domain: "TS",
        TSSEQ: "1",
        TSGRPID: "",
        TSPARMCD: "SEXPOP",
        TSPARM: "Sex of Participants",
        TSVAL: "BOTH",
        TSVALNF: "",
        TSVALCD: "C49488",
        TSVCDREF: "CDISC",
        TSVCDVER: "2011-06-10"
    }
];

//TA 도메인 예제 데이터
export const TAExamDataList = [
    {
        row: 1,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "P",
        ARM: "Placebo",
        TAETORD: "1",
        ETCD: "SCRN",
        ELEMENT: "Screen",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "SCREENING"
    },
    {
        row: 2,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "P",
        ARM: "Placebo",
        TAETORD: "2",
        ETCD: "RI",
        ELEMENT: "Run-In",
        TABRANCH: "Randomized to Placebo",
        TATRANS: "",
        EPOCH: "RUN-IN"
    },
    {
        row: 3,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "P",
        ARM: "Placebo",
        TAETORD: "3",
        ETCD: "P",
        ELEMENT: "Placebo",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "TREATMENT"
    },
    {
        row: 4,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "A",
        ARM: "A",
        TAETORD: "1",
        ETCD: "SCRN",
        ELEMENT: "Screen",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "SCREENING"
    },
    {
        row: 5,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "A",
        ARM: "A",
        TAETORD: "2",
        ETCD: "RI",
        ELEMENT: "Run-In",
        TABRANCH: "Randomized to Drug A",
        TATRANS: "",
        EPOCH: "RUN-IN"
    },
    {
        row: 6,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "A",
        ARM: "A",
        TAETORD: "3",
        ETCD: "A",
        ELEMENT: "Drug A",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "TREATMENT"
    },
    {
        row: 7,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "B",
        ARM: "B",
        TAETORD: "1",
        ETCD: "SCRN",
        ELEMENT: "Screen",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "SCREENING"
    },
    {
        row: 8,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "B",
        ARM: "B",
        TAETORD: "2",
        ETCD: "RI",
        ELEMENT: "Run-In",
        TABRANCH: "Randomized to Drug B",
        TATRANS: "",
        EPOCH: "RUN-IN"
    },
    {
        row: 9,
        STUDYID: "EX1",
        domain: "TA",
        ARMCD: "B",
        ARM: "B",
        TAETORD: "3",
        ETCD: "B",
        ELEMENT: "Drug B",
        TABRANCH: "",
        TATRANS: "",
        EPOCH: "TREATMENT"
    }
];

//TE 도메인 예제 데이터
export const TEExamDataList = [
    {
        row: 1,
        STUDYID: "EX1",
        domain: "TE",
        ETCD: "SCRN",
        ELEMENT: "Screen",
        TESTRL: "Informed consent",
        TEENRL: "1 week after start of Element",
        TEDUR: "P7D"
    },
    {
        row: 2,
        STUDYID: "EX1",
        domain: "TE",
        ETCD: "RI",
        ELEMENT: "Run-In",
        TESTRL: "Eligibility confirmed",
        TEENRL: "2 weeks after start of Element",
        TEDUR: "P14D"
    },
    {
        row: 3,
        STUDYID: "EX1",
        domain: "TE",
        ETCD: "P",
        ELEMENT: "Placebo",
        TESTRL: "First dose of study drug, where drug is placebo",
        TEENRL: "2 weeks after start of Element",
        TEDUR: "P14D"
    },
    {
        row: 4,
        STUDYID: "EX1",
        domain: "TE",
        ETCD: "A",
        ELEMENT: "Drug A",
        TESTRL: "First dose of study drug, where drug is Drug A",
        TEENRL: "2 weeks after start of Element",
        TEDUR: "P14D"
    },
    {
        row: 5,
        STUDYID: "EX1",
        domain: "TE",
        ETCD: "B",
        ELEMENT: "Drug B",
        TESTRL: "First dose of study drug, where drug is Drug B",
        TEENRL: "2 weeks after start of Element",
        TEDUR: "P14D"
    }
];

//TV 도메인 예제 데이터
export const TVExamDataList = [
    {
        row: 1,
        STUDYID: "EX1",
        domain: "TV",
        VISITNUM: 1,
        TVSTRL: "Start of Screen Epoch",
        TVENRL: "1 hour after start of Visit"
    },
    {
        row: 2,
        STUDYID: "EX1",
        domain: "TV",
        VISITNUM: 2,
        TVSTRL: "30 minutes before end of Screen Epoch",
        TVENRL: "30 minutes after start of Run-in Epoch"
    },
    {
        row: 3,
        STUDYID: "EX1",
        domain: "TV",
        VISITNUM: 3,
        TVSTRL: "30 minutes before end of Run-in Epoch",
        TVENRL: "1 hour after start of Treatment Epoch"
    },
    {
        row: 4,
        STUDYID: "EX1",
        domain: "TV",
        VISITNUM: 4,
        TVSTRL: "1 week after start of Treatment Epoch",
        TVENRL: "1 hour after start of Visit"
    },
    {
        row: 5,
        STUDYID: "EX1",
        domain: "TV",
        VISITNUM: 5,
        TVSTRL: "2 weeks after start of Treatment Epoch",
        TVENRL: "1 hour after start of Visit"
    }
];

//TM 도메인 예제 데이터
export const TMExamDataList = [
    {
        row: 1,
        STUDYID: "XYZ",
        domain: "TM",
        MIDSTYPE: "DIAGNOSIS",
        TMDEF: "Initial diagnosis of diabetes, the first time a physician told the subject they had diabetes",
        TMRPT: "N"
    },
    {
        row: 2,
        STUDYID: "XYZ",
        domain: "TM",
        MIDSTYPE: "HYPOGLYCEMIC EVENT",
        TMDEF: "Hypoglycemic Event, the occurrence of a glucose level below (threshold level)",
        TMRPT: "Y"
    }
];

//TI 도메인 예제 데이터
export const TIExamDataList = [
    {
        row: 1,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "INCL01",
        IETEST: "Has disease under study",
        IECAT: "INCLUSION",
        TIVERS: "1"
    },
    {
        row: 2,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "INCL02",
        IETEST: "Age 21 or greater",
        IECAT: "INCLUSION",
        TIVERS: "1"
    },{
        row: 3,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "EXCL01",
        IETEST: "Pregnant or lactating",
        IECAT: "EXCLUSION",
        TIVERS: "1"
    },{
        row: 4,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "INCL01",
        IETEST: "Has disease under study",
        IECAT: "INCLUSION",
        TIVERS: "2.2"
    },{
        row: 5,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "INCL02A",
        IETEST: "Age 18 or greater",
        IECAT: "INCLUSION",
        TIVERS: "2.2"
    },
    {
        row: 6,
        STUDYID: "XYZ",
        domain: "TI",
        IETESTCD: "EXCL01",
        IETEST: "Pregnant or lactating",
        IECAT: "EXCLUSION",
        TIVERS: "2.2"
    }
];