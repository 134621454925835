import React, {useCallback, useEffect, useRef, useState} from "react";
import cn from "classnames";
import html2canvas from "html2canvas";
import {produce} from "immer";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    AXIOS_GET,
    AXIOS_PUT_FORM,
    copyObject,
    getSessionState,
    isDataExist,
    isFieldDataExist
} from "common/commonFunction";
import NetworkLayout from "common/NetworkLayout";
import {COMMAND} from "common/dataProcessAgent";
import {DESIGN_SYNOPSIS_URL} from "constant/ConstantURL";
import useToast from "hooks/useToast";
import ObjectiveItem from "./ObjectiveItem";
import DesignBottom from "../DesignBottom";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} OBJECTIVE_NAME
 *  @description    Objective field 명 정의
 */
export const OBJECTIVE_NAME = {
    ID: 'ID',
    CATEGORY: 'category',
    OBJECTIVE: 'text',
    ENDPOINT: 'endpoint',
    NAME: 'name',
    TYPE: 'type',
    DESCRIPTION: 'description',
    ENDPOINT_CATEGORY: 'endpoint_category',
    ENDPOINT_NAME: 'endpoint_name',
    ENDPOINT_DESCRIPTION: 'endpoint_description',
    MAPDESIGN_OBJECTIVEID: 'mapDesignObjectiveID'
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} DEFAULT_ITEM_OBJECT
 *  @description    objective 아이템 추가 시 필요한 데이터 default 값
 */
const DEFAULT_ITEM_OBJECT = {
    ID: "",
    category: "",
    text: "",
    endpoint: [],
    mapDesignObjectiveID: ""
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} DEFAULT_OBJECTIVE_OBJECT
 *  @description    objective 아이템 비울 때 필요한 데이터 default 값
 */
const DEFAULT_OBJECTIVE_OBJECT = {
    text: ""
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} DEFAULT_ENDPOINT_OBJECT
 *  @description    endpoint 아이템 추가 시 필요한 데이터 default 값
 */
const DEFAULT_ENDPOINT_OBJECT = {
    category: "",
    name: "",
    description: ""
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} DEFAULT_ENDPOINT_OBJECT_VALIDATION
 *  @description    endpoint 항목 validation 체크에 사용 되는 필드
 */
const DEFAULT_ENDPOINT_OBJECT_VALIDATION = {
    name: true
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {Object} DEFAULT_ITEM_OBJECT_VALIDATION
 *  @description    objective 항목 추가 시 필요한 validation 필드
 */
const DEFAULT_ITEM_OBJECT_VALIDATION = {
    text: true,
    endpoint: DEFAULT_ENDPOINT_OBJECT_VALIDATION
};

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {String} FIELD_ITEM_DATA
 *  @description    ITEM 데이터 영역 구분 키
 */
export const FIELD_ITEM_DATA = 'ITEM';

/**
 *  @memberOf       ObjectiveConfig
 *  @constant       {String} FIELD_VALID_DATA
 *  @description    validation 영역 구분 키
 */
export const FIELD_VALID_DATA = 'VALID';

/**
 *  @memberOf     ObjectiveConfig
 *  @function     checkInputInfo
 *  @param        {Array} dataList - 화면에 표시할 전체 objective 데이터 리스트
 *  @param        {Array} inputInfoList - 입력 필드에 표시할 입력 데이터 리스트
 *  @return       {Array} returnList - inputInfo 추가한 전체 데이터 리스트
 *  @description  데이터 저장 시, 현재 입력된 값을 체크해서 data list에 추가시키는 기능
 */
const checkInputInfo = (dataList, inputInfoList) => {
    const inputList = []; //
    let returnList = copyObject(dataList); // 데이터 훼손을 막기 위해 copy

    dataList.forEach((item, itemIndex) => {
        const inputObject = {};
        const currentInputInfo = inputInfoList[itemIndex]; // objective 데이터 index 로 접근 필요
        const validInputKeys = Object.keys(DEFAULT_ENDPOINT_OBJECT_VALIDATION); // endpoint 에 필수 필드 값 key 빼내오기

        for (let validInputKey of validInputKeys) {
            inputObject[validInputKey] = isDataExist(currentInputInfo[validInputKey]); // endpoint 필수 필드 값 validation 체크
        }

        const stringObject = JSON.stringify(inputObject);
        inputList.push(!stringObject.includes('false')); // endpoint 필수 값이 전부 채워졌는지 확인 후 boolean 값 넣기
    });

    // inputInfo 데이터가 전부 채워졌다면 현재 dataInfo 리스트에 추가하기
    inputList.forEach((inputBool, index) => {
        if (inputBool) {
            if (dataList[index].hasOwnProperty('endpoint')) {
                returnList[index].endpoint.push(inputInfoList[index]);
            }
        }
    });

    return returnList;
};

/**
 *  @memberOf       ObjectiveConfig
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {dataID, requestUrl, sendObject, searchText, objectiveID = ''} = params;
    let url = null;
    let response = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO :
            url = `${DESIGN_SYNOPSIS_URL}/objective/${dataID}/`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${requestUrl}/objective/${dataID}`;
            response = await AXIOS_PUT_FORM(url, sendObject);
            break;

        // objective 검색
        case COMMAND.SEARCH_OBJECTIVE :
            url = `${DESIGN_SYNOPSIS_URL}/objective/search/${dataID}?text=${searchText}`;
            response = await AXIOS_GET(url);
            break;

        // endpoint 검색
        case COMMAND.SEARCH_ENDPOINT :
            url = `${DESIGN_SYNOPSIS_URL}/endpoint/search/${dataID}?text=${searchText}&objectiveID=${objectiveID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author         주예리나
 *  @version        1.0
 *  @see            html2canvas (https://github.com/niklasvh/html2canvas)
 *  @component      ObjectiveConfig
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    design information 의 Objective & Endpoint 편집 컴포넌트
 */
const ObjectiveConfig = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/

    /**
     *  @memberOf      ObjectiveConfig
     *  @type          {Object} props
     *  @property      {String} ID - 리스트 ID
     *  @property      {Object} history - url 이동을 위해 사용
     *  @property      {Function} referencePopup - reference 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onChangeData - 변경 전 데이터, 변경 후 데이터 호출하는 함수
     *  @property      {Boolean } isSaved - isDataChange Modal에서 Yes 버튼 상태
     *  @property      {Boolean} onSetSave - isDataChange Modal Yes 버튼 클릭 상태 처리
     *  @property      {Boolean} isClosed - isDataChange Modal에서 No 버튼 상태
     *  @property      {Boolean} onSetClose - isDataChange Modal No 버튼 클릭 상태 처리
     *  @property      {Boolean} onDataChangeModal - isDataChange Modal 상태 처리
     *  @property      {Boolean} isChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태
     *  @property      {Boolean} isChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태
     *  @property      {Boolean} onChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property      {Boolean} onChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property      {Boolean} onChangeDataCheckModal - Top Tab의 isDataChange Modal 상태 처리
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        history,
        referencePopup,
        onList,
        onChangeData,
        isSaved,
        onSetSave,
        isClosed,
        onSetClose,
        onDataChangeModal,
        isChangeTabSave,
        isChangeTabClose,
        onChangeTabSave,
        onChangeTabClose,
        onChangeDataCheckModal
    } = props;

    /**
     *  @memberOf      ObjectiveConfig
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf     ObjectiveConfig
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     ObjectiveConfig
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     ObjectiveConfig
     *  @var          {HTMLDivElement} captureRef
     *  @description  저장 시 file 객체로 전송할 이미지 캡쳐 영역 ref
     */
    const captureRef = useRef(null);

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Object} dataInfo
     *  @description    화면에 표시 될 상세 정보 및 validation 필드 table
     */
    const [dataInfo, setDataInfo] = useState({ITEM: [], VALID: []});

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Object} originDataInfo
     *  @description    원본 데이터
     */
    const [originDataInfo, setOriginDataInfo] = useState({ITEM: [], VALID: []});

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Array} objectiveDataList
     *  @description    object 검색 시 표시될 objective 데이터 목록
     */
    const [objectiveDataList, setObjectiveDataList] = useState([]);

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Array} endpointDataList
     *  @description    endpoint 검색 시 표시될 objective 데이터 목록
     */
    const [endpointDataList, setEndpointDataList] = useState([]);

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Array} inputInfo
     *  @description    endpoint 입력 필드 default 값
     */
    const [inputInfo, setInputInfo] = useState([DEFAULT_ENDPOINT_OBJECT]);

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Object} copyObjectiveData
     *  @description    objective 입력 데이터 저장 시 기존 데이터 혹은 새로 입력한 데이터 인지의 비교를 위한 복사본 데이터
     */
    const [copyObjectiveData, setCopyObjectiveData] = useState(
        [
            {
                ID: '',
                text: ''
            }
        ]
    );

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Object} isLoadingIndex
     *  @description    현재 검색 데이터 로딩 중인 항목의 index
     */
    const [isLoadingIndex, setIsLoadingIndex] = useState({
        [OBJECTIVE_NAME.OBJECTIVE]: -1,
        [OBJECTIVE_NAME.ENDPOINT]: -1
    });

    /**
     *  @memberOf       ObjectiveConfig
     *  @var            {Number} timer
     *  @description    검색 디바운싱 timer
     */
    const [timer, setTimer] = useState(0);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        const params = {
            dataID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleDeleteObjective
     *  @param        {Number} index - delete 할 항목의 index
     *  @description  object 삭제 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteObjective = useCallback((index) => {
        setDataInfo(
            produce(dataInfo, draft => {
                // 한 항목 남았을 때
                if (draft[FIELD_ITEM_DATA].length === 1) {
                    draft[FIELD_ITEM_DATA] = [DEFAULT_ITEM_OBJECT];
                    draft[FIELD_VALID_DATA] = [DEFAULT_ITEM_OBJECT_VALIDATION];
                } else {
                    draft[FIELD_ITEM_DATA].splice(index, 1);
                    draft[FIELD_VALID_DATA].splice(index, 1);
                }
            })
        );

        // 복사본에서도 삭제
        setCopyObjectiveData(
            produce(copyObjectiveData, draft => {
                // 한 항목 남았을 때
                if (draft.length === 1) {
                    draft[0] = DEFAULT_ITEM_OBJECT;
                } else {
                    draft.splice(index, 1);
                }
            })
        );

        // 해당 인덱스의 input 공간 삭제
        setInputInfo(
            produce(inputInfo, draft => {
                if (draft.length === 1) {
                    draft[index] = DEFAULT_ENDPOINT_OBJECT;
                } else {
                    draft.splice(index, 1);
                }
            })
        );

        // 로딩 중 field 공간도 같이 삭제
        setIsLoadingIndex(
            produce(isLoadingIndex, draft => {
                draft[OBJECTIVE_NAME.OBJECTIVE] = -1;
                draft[OBJECTIVE_NAME.ENDPOINT] = -1;
            })
        );
    }, [dataInfo, copyObjectiveData, inputInfo, isLoadingIndex]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     validateEndpointData
     *  @param        {Number} index - objective index 값
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  endpoint 추가 버튼 클릭 시 endpoint 입력 된 값들에 대한 validation 처리 함수
     */
    const validateEndpointData = useCallback((index) => {
        const endPointKeys = Object.keys(DEFAULT_ENDPOINT_OBJECT_VALIDATION);
        const newEndPointObject = Object.assign({}, DEFAULT_ENDPOINT_OBJECT_VALIDATION); // endpoint validate field 복사
        let returnBool = true;

        for (let endPointKey of endPointKeys) {
            newEndPointObject[endPointKey] = isDataExist(inputInfo[index][endPointKey]);
        }

        setDataInfo(
            produce(dataInfo, draft => {
                draft[FIELD_VALID_DATA][index]['endpoint'] = newEndPointObject;
            })
        );

        const stringObject = JSON.stringify(newEndPointObject); // 객체 값 비교용이를 위해 string 처리
        returnBool = !stringObject.includes('false'); // valid false가 있는 지 체크

        return returnBool;
    }, [dataInfo, inputInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleAddTableData
     *  @param        {Number} index - objective index
     *  @description  endpoint 테이블 목록 항목 추가 시 실행되는 함수
     */
    const handleAddTableData = useCallback((index) => {
        setObjectiveDataList([]);
        setEndpointDataList([]);

        let _dataInfo = copyObject(dataInfo);
        const inputData = inputInfo[index];
        const validData = validateEndpointData(index);

        if (validData === false) {
            return;
        }

        _dataInfo[FIELD_ITEM_DATA][index][OBJECTIVE_NAME.ENDPOINT].push(inputData);
        _dataInfo[FIELD_VALID_DATA][index][OBJECTIVE_NAME.ENDPOINT] = DEFAULT_ITEM_OBJECT_VALIDATION;

        setDataInfo(_dataInfo);
        // 입력 값 비우기
        setInputInfo(
            produce(inputInfo, draft => {
                draft[index] = DEFAULT_OBJECTIVE_OBJECT;
                draft[index] = DEFAULT_ENDPOINT_OBJECT;
            })
        );
    }, [dataInfo, inputInfo, validateEndpointData]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @param        {Number} mainIndex - objective index
     *  @param        {Number} subIndex - endpoint index
     *  @description  input 필드 변경시 호출되는 함수
     */
    const handleChange = useCallback((name, value, mainIndex, subIndex) => {
        if (name.includes('endpoint_')) { // endpoint 입력 필드일 경우
            const newName = name.split('_')[1]; // name 의 구분자 제거

            setInputInfo(
                produce(inputInfo, draft => {
                    if (typeof value === "object") {
                        draft[mainIndex][newName] = value.label;
                        draft[mainIndex][newName] = value.value;
                    } else {
                        draft[mainIndex][newName] = value;
                        draft[mainIndex].ID = '';
                    }
                })
            );

            // 검색어 입력 시 로딩 중 체크
            setIsLoadingIndex(
                produce(isLoadingIndex, draft => {
                    draft.endpoint = mainIndex;
                })
            );
        } else {
            // 검색어 입력 시 로딩 중 체크
            setIsLoadingIndex(produce(isLoadingIndex, draft => {
                draft.text = mainIndex;
            }));

            // 상위 정보 설정
            if (subIndex === undefined) {
                setDataInfo(
                    produce(dataInfo, draft => {
                        if (name === 'text') {
                            if (typeof value === "object") {
                                draft[FIELD_ITEM_DATA][mainIndex][OBJECTIVE_NAME.OBJECTIVE] = value.label;
                                draft[FIELD_ITEM_DATA][mainIndex][OBJECTIVE_NAME.MAPDESIGN_OBJECTIVEID] = value.value;
                            } else {
                                draft[FIELD_ITEM_DATA][mainIndex][name] = value;
                            }
                        } else {
                            draft[FIELD_ITEM_DATA][mainIndex][name] = value;
                        }
                    })
                );

                // objective 선택 시 ID와 text 값 복사본으로 가지고 있기
                if (name === 'text') {
                    if (typeof value === 'object') {
                        setCopyObjectiveData(produce(copyObjectiveData, draft => {
                            draft[mainIndex].ID = value.value;
                            draft[mainIndex].text = value.label;
                        }));
                    }
                }
            }
        }
    }, [copyObjectiveData, dataInfo, inputInfo, isLoadingIndex]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleSelect
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @param        {Number} mainIndex - objective index
     *  @description  검색 컴포넌트 내용 선택 시 실행되는 함수
     */
    const handleSelect = useCallback((name, value, mainIndex) => {
        if (name.includes('endpoint_')) { // endpoint 입력 필드일 경우
            const newName = name.split('_')[1]; // name 의 구분자 제거

            setInputInfo(
                produce(inputInfo, draft => {
                    if (typeof value === "object") {
                        draft[mainIndex][newName] = value.label;
                        draft[mainIndex][newName] = value.value;

                    } else {
                        draft[mainIndex][newName] = value;
                        draft[mainIndex].ID = '';
                    }
                })
            );
        } else {
            setDataInfo(
                produce(dataInfo, draft => {
                    if (name === 'text') {
                        if (typeof value === "object") {
                            draft[FIELD_ITEM_DATA][mainIndex][OBJECTIVE_NAME.OBJECTIVE] = value.label;
                            draft[FIELD_ITEM_DATA][mainIndex][OBJECTIVE_NAME.MAPDESIGN_OBJECTIVEID] = value.value;
                        } else {
                            draft[FIELD_ITEM_DATA][mainIndex][name] = value;
                        }
                    } else {
                        draft[FIELD_ITEM_DATA][mainIndex][name] = value;
                    }
                })
            );

            // objective 선택 시 ID와 text 값 복사본으로 가지고 있기
            if (name === 'text') {
                if (typeof value === 'object') {
                    setCopyObjectiveData(produce(copyObjectiveData, draft => {
                        draft[mainIndex].ID = value.value;
                        draft[mainIndex].text = value.label;
                    }));
                }
            }
        }
    }, [copyObjectiveData, dataInfo, inputInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     getObjectiveData
     *  @param        {Number} index - objective index
     *  @description  objective 검색 api 호출
     */
    const getObjectiveData = useCallback((index) => {
        if (dataInfo[FIELD_ITEM_DATA][index][OBJECTIVE_NAME.OBJECTIVE] !== '') {
            const command = COMMAND.SEARCH_OBJECTIVE; // 데이터 리스트 요청 command
            const params = {
                dataID: ID,
                searchText: dataInfo[FIELD_ITEM_DATA][index][OBJECTIVE_NAME.OBJECTIVE]
            };

            netWorkAgent.current.request(command, params);
        }
    }, [ID, dataInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     getEndpointData
     *  @param        {Number} index - objective index
     *  @description  endpoint 검색 api 호출
     */
    const getEndpointData = useCallback((index) => {
        // objective가 입력됐을 경우에만
        if (dataInfo[FIELD_ITEM_DATA][index][OBJECTIVE_NAME.OBJECTIVE] !== '') {
            const command = COMMAND.SEARCH_ENDPOINT; // 데이터 리스트 요청 command
            let mapDesignObjectiveID = '';

            // 복사본 objective 와 현재 objective 입력 값 비교해서 같으면 ID 값 넘기기, 다르면 빈 ID
            if (copyObjectiveData[index].text === dataInfo[FIELD_ITEM_DATA][index][OBJECTIVE_NAME.OBJECTIVE]) {
                mapDesignObjectiveID = copyObjectiveData[index].ID;
            }

            // parameter 설정
            const params = {
                dataID: ID,
                objectiveID: mapDesignObjectiveID,
                searchText: inputInfo[index][OBJECTIVE_NAME.NAME],
            };

            // back-end 데이터 처리 요청
            if (netWorkAgent.current !== null) {
                netWorkAgent.current.request(command, params);
            }
        }
    }, [ID, copyObjectiveData, dataInfo, inputInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleReference
     *  @description  ref 버튼 클릭시 실행되는 함수
     */
    const handleReference = useCallback(() => {
        if (referencePopup !== undefined) {
            referencePopup();
        }
    }, [referencePopup]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        let validateData = [];
        let returnBool = true;

        // 데이터 있는 지 없는 지 validation 체크 로직
        for (let mainIndex = 0; mainIndex < dataInfo[FIELD_VALID_DATA].length; mainIndex++) {
            const checkRowItem = dataInfo[FIELD_ITEM_DATA][mainIndex]; // for 문 돌면서 체크할 해당 objective
            const validateKeys = Object.keys(DEFAULT_ITEM_OBJECT_VALIDATION); // validation 할 key
            let newValidItem = {};

            for (let key of validateKeys) {
                switch (key) {
                    case OBJECTIVE_NAME.CATEGORY:
                    case OBJECTIVE_NAME.OBJECTIVE:
                        newValidItem[key] = isDataExist(checkRowItem[key]);
                        break;

                    // endpoint validation
                    case OBJECTIVE_NAME.ENDPOINT:
                        if (checkRowItem[key].length >= 1) { // 추가된 endpoint 목록이 아예 없을 때만 validation 체크함 (이미 목록 존재한다면 validation 할 필요 X)
                            break;
                        }

                        const endPointKeys = Object.keys(DEFAULT_ENDPOINT_OBJECT_VALIDATION);
                        const newEndPointObject = Object.assign({}, DEFAULT_ENDPOINT_OBJECT_VALIDATION); // 원본 데이터 훼손을 막기 위해 데이터 복사해서 사용함

                        for (let endPointKey of endPointKeys) {
                            newEndPointObject[endPointKey] = isDataExist(inputInfo[mainIndex][endPointKey]);
                        }

                        newValidItem[key] = newEndPointObject;
                        break;

                    // no default
                }
            }

            validateData.push(newValidItem); // 데이터 존재 유무에 따라 validation true, false 체크하여 새 배열 객체로 업데이트
        }

        // 새로 업데이트 한 데이터 통해서 validation return 값 체크
        for (let mainIndex2 = 0; mainIndex2 < validateData.length; mainIndex2++) {
            const validRowItem = validateData[mainIndex2];
            const validateKeys = Object.keys(validRowItem); // validation 할 key

            for (let key of validateKeys) {
                switch (key) {
                    case OBJECTIVE_NAME.CATEGORY:
                    case OBJECTIVE_NAME.OBJECTIVE:
                        returnBool = validRowItem[key];
                        break;

                    case OBJECTIVE_NAME.ENDPOINT:
                        const endPointRowValid = validRowItem[key];
                        const endPointKeys = Object.keys(DEFAULT_ENDPOINT_OBJECT_VALIDATION);

                        for (let endPointKey of endPointKeys) {
                            returnBool = endPointRowValid[endPointKey];

                            if (returnBool === false) {
                                break;
                            }
                        }

                        if (returnBool === false) {
                            break;
                        }

                    // no default
                }

                if (returnBool === false) {
                    break;
                }
            }

            if (returnBool === false) {
                break;
            }
        }

        if (returnBool === false) {
            setDataInfo(
                produce(dataInfo, draft => {
                    draft[FIELD_VALID_DATA] = validateData;
                })
            );
        }

        return returnBool;
    }, [dataInfo, inputInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleAddItem
     *  @description  항목 add 버튼 클릭 시 호출되는 함수
     */
    const handleAddItem = useCallback(() => {
        if (lock !== 2) {
            const valid = validateData(); // 데이터 validation
            if (valid === false) {
                return;
            }

            // 데이터 info에 default 값 추가
            setDataInfo(
                produce(dataInfo, draft => {
                    draft[FIELD_ITEM_DATA].push(DEFAULT_ITEM_OBJECT);
                    draft[FIELD_VALID_DATA].push(DEFAULT_ITEM_OBJECT_VALIDATION);
                })
            );

            // 복사본에도 함께 추가
            setCopyObjectiveData(
                produce(copyObjectiveData, draft => {
                    draft.push({ID: '', text: ''});
                })
            );

            // endpoint input 필드 공간 추가
            setInputInfo(
                produce(inputInfo, draft => {
                    draft.push(DEFAULT_ENDPOINT_OBJECT);
                })
            );
        }
    }, [copyObjectiveData, dataInfo, inputInfo, lock, validateData]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     getImageBlob
     *  @param        {Function} callback - image 저장 후 실행시킬 callback 함수
     *  @description  save 버튼 클릭 시 실행되어 현재 캡쳐 영역 image 생성 후 callback 함수 실행
     */
    const getImageBlob = useCallback((callback) => {
        //current에 html 요소가 잡힌다.
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0);
            const blobBin = window.atob(image.split(',')[1]);	// base64 데이터 디코딩
            const array = [];

            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }

            const blobImage = new Blob([new Uint8Array(array)], {type: 'image/png'});	// Blob 생성

            callback(blobImage); // saveDataInfo 실행
        });
    }, []);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     saveDataInfo
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  image 생성 시 callback 으로 실행되어 데이터 저장 api 호출
     */
    const saveDataInfo = useCallback((image) => {
        const command = COMMAND.DATA_UPDATE; // 데이터 저장 요청
        const sendList = checkInputInfo(dataInfo[FIELD_ITEM_DATA], inputInfo); // inputInfo 가 있으면 입력 데이터 추가
        const formData = new FormData();

        formData.append("data", JSON.stringify(sendList));
        formData.append("file", image, "objective.png");

        const params = {
            dataID: ID,
            requestUrl: DESIGN_SYNOPSIS_URL,
            sendObject: formData,
        };

        (netWorkAgent.current !== null && netWorkAgent.current.request(command, params)); // back-end 데이터 처리 요청
    }, [ID, dataInfo, inputInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleGridChange
     *  @param        {Array}  list  - Endpoint 변경 된 List
     *  @param        {Number} itemKey - item index 값
     *  @description  grid 변경 시(수정, Drag & Drop, 삭제) 실행되는 함수
     */
    const handleGridChange = useCallback((list, itemKey) => {
        let _dataInfo = copyObject(dataInfo);

        _dataInfo[FIELD_ITEM_DATA][itemKey][OBJECTIVE_NAME.ENDPOINT] = [...list];

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleChangeData
     *  @description  원본 데이터와 화면에 보이는 데이터를 전달하는 함수
     */
    const handleChangeData = useCallback(() => {
        onChangeData(dataInfo, originDataInfo);
    }, [dataInfo, onChangeData, originDataInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        const valid = validateData(); // 데이터 validation
        if (valid === false) {
            return;
        }

        getImageBlob(saveDataInfo);
    }, [validateData, getImageBlob, saveDataInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @param        {Number} mainIndex - objective 데이터 index
     *  @param        {Number} subIndex - endpoint 데이터 index
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name, mainIndex, subIndex) => {
        switch (name) {
            case OBJECTIVE_NAME.OBJECTIVE:
                if (subIndex === undefined) {
                    setDataInfo(
                        produce(dataInfo, draft => {
                            draft[FIELD_VALID_DATA][mainIndex][name] = true;
                        })
                    );
                }
                break;

            default:
                const newName = name.split('_')[1];

                setDataInfo(
                    produce(dataInfo, draft => {
                        if (draft[FIELD_VALID_DATA][mainIndex].hasOwnProperty('endpoint')) {
                            draft[FIELD_VALID_DATA][mainIndex]['endpoint'][newName] = true;
                        }
                    })
                );
                break;
        }
    }, [dataInfo]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     getDataList
     *  @return       {ReactElement} objective item 컴포넌트
     *  @description  서버로부터 받아온 dataInfo 를 통해 Objective 컴포넌트를 반환하는 함수
     */
    const getDataList = useCallback(() => {
        if (isFieldDataExist(dataInfo, FIELD_ITEM_DATA)) {
            const itemTable = dataInfo[FIELD_ITEM_DATA];
            const validTable = dataInfo[FIELD_VALID_DATA];

            return itemTable.map((item, index) => {
                return (
                    <ObjectiveItem
                        key={index}
                        data={item}
                        itemKey={index}
                        loadingObjectIndex={isLoadingIndex[OBJECTIVE_NAME.OBJECTIVE]}
                        loadingEndpointIndex={isLoadingIndex[OBJECTIVE_NAME.ENDPOINT]}
                        validation={validTable[index]}
                        onAddTableRow={handleAddTableData}
                        onDelete={handleDeleteObjective}
                        onDataChange={handleChange}
                        onFocus={handleFocus}
                        objectiveDataList={objectiveDataList}
                        endpointDataList={endpointDataList}
                        inputData={inputInfo[index]}
                        onDataSelect={handleSelect}
                        onGridChange={handleGridChange}/>
                );
            });
        } else {
            return null;
        }
    }, [dataInfo, endpointDataList, handleAddTableData, handleChange, handleDeleteObjective, handleFocus, handleGridChange, handleSelect, inputInfo, isLoadingIndex, objectiveDataList]);

    /**
     *  @memberOf     ObjectiveConfig
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 검색어
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_INFO : // 상세 데이터 불러오기
                    if (data.hasOwnProperty('data')) {
                        if (isDataExist(data.data) && Array.isArray(data.data)) {
                            let inputEndpointDataSet = [];
                            let copyObjectiveData = [];

                            for (let row of data.data) {
                                let copyObject = {
                                    ID: row.mapDesignObjectiveID,
                                    text: row.text
                                };

                                inputEndpointDataSet.push(DEFAULT_ENDPOINT_OBJECT);
                                copyObjectiveData.push(copyObject);
                            }

                            setDataInfo(
                                produce(dataInfo, draft => {
                                    // validation setting
                                    const itemDataList = data.data;
                                    let newValidDataList = [];

                                    // endpoint 목록에 따른 validation 구조체 생성
                                    for (let idx = 0; idx < itemDataList.length; idx++) {
                                        newValidDataList.push(DEFAULT_ITEM_OBJECT_VALIDATION);
                                    }

                                    draft[FIELD_ITEM_DATA] = data.data;
                                    draft[FIELD_VALID_DATA] = newValidDataList;
                                })
                            );

                            setCopyObjectiveData(copyObjectiveData); // 비교를 위한 데이터 복사본
                            setInputInfo(inputEndpointDataSet); // input 필드 공간도 같이 추가

                            setOriginDataInfo(
                                produce(originDataInfo, draft => {
                                    const itemDataList = data.data;
                                    let newValidDataList = [];

                                    // endpoint 목록에 따른 validation 구조체 생성
                                    for (let idx = 0; idx < itemDataList.length; idx++) {
                                        newValidDataList.push(DEFAULT_ITEM_OBJECT_VALIDATION);
                                    }
                                    draft[FIELD_ITEM_DATA] = data.data;
                                    draft[FIELD_VALID_DATA] = newValidDataList;
                                })
                            );

                        } else {
                            // default 데이터 입력
                            setDataInfo(
                                produce(dataInfo, draft => {
                                    draft[FIELD_ITEM_DATA] = [DEFAULT_ITEM_OBJECT];
                                    draft[FIELD_VALID_DATA] = [DEFAULT_ITEM_OBJECT_VALIDATION];
                                })
                            );

                            setInputInfo([DEFAULT_ENDPOINT_OBJECT]); // input 필드 공간도 같이 추가

                            setOriginDataInfo(
                                produce(dataInfo, draft => {
                                    draft[FIELD_ITEM_DATA] = [DEFAULT_ITEM_OBJECT];
                                    draft[FIELD_VALID_DATA] = [DEFAULT_ITEM_OBJECT_VALIDATION];
                                })
                            );
                        }
                    }
                    break;

                case COMMAND.DATA_UPDATE: // 데이터 수정에 대한 응답시
                    showToast(getDataInfo());
                    break;

                case COMMAND.SEARCH_OBJECTIVE: // Objective 검색 응답
                    if (data.hasOwnProperty('data')) {
                        const objectiveList = data.data.map((item => {
                            return {
                                label: item.text,
                                value: item.ID
                            };
                        }));

                        setObjectiveDataList(objectiveList);
                        setEndpointDataList([]);
                    }
                    break;

                case COMMAND.SEARCH_ENDPOINT : // Endpoint 검색 응답 시
                    if (data.hasOwnProperty('data')) {
                        const endpointList = data.data.map((item => {
                            return {
                                label: item.name,
                                value: item.name
                            };
                        }));

                        setEndpointDataList(endpointList);
                    }
                    break;

                //no default
            }
        }
    }, [dataInfo, getDataInfo, originDataInfo, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 수정 모드일 때 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    // objective 검색어 입력 시 디바운싱 처리로 검색 목록 불러오기
    useEffect(() => {
        if (isLoadingIndex.text !== -1) {
            if (timer) {
                clearTimeout(timer);
            }

            // debouncing 처리
            // 마지막 호출만 적용하기 위해 타이머 실행 전 기존 timer 가 입력됐을 시 clear 처리
            // timer 설정 -> 0.8초 뒤 검색 기능 실행
            const newTimer = setTimeout(() => {
                getObjectiveData(isLoadingIndex[OBJECTIVE_NAME.OBJECTIVE]);
            }, 100);

            setTimer(newTimer); // timer 설정
            setIsLoadingIndex(produce(isLoadingIndex, draft => {
                draft[OBJECTIVE_NAME.OBJECTIVE] = -1;
            }));
        }
    }, [isLoadingIndex.text, getObjectiveData, isLoadingIndex, timer]);

    // endpoint 검색어 입력 시 디바운싱 처리로 검색 목록 불러오기
    useEffect(() => {
        if (isLoadingIndex.endpoint !== -1) {
            if (timer) {
                clearTimeout(timer);
            }

            // debouncing 처리
            // 마지막 호출만 적용하기 위해 타이머 실행 전 기존 timer 가 입력됐을 시 clear 처리
            // timer 설정 -> 0.8초 뒤 검색 기능 실행
            const newTimer = setTimeout(() => {
                getEndpointData(isLoadingIndex[OBJECTIVE_NAME.ENDPOINT]);
            }, 800);

            setTimer(newTimer); // timer 설정
            setIsLoadingIndex(produce(isLoadingIndex, draft => {
                draft[OBJECTIVE_NAME.ENDPOINT] = -1;
            }));
        }
    }, [getEndpointData, isLoadingIndex, isLoadingIndex.endpoint, timer]);

    // origin data와 화면에 보이는 data를 전달하는 함수 호출
    useEffect(() => {
        handleChangeData();
    }, [handleChangeData]);

//데이터 변경 후 Tab 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isSaved === true) {
            onSetClose(false);
            handleSave();
            onSetSave(false);
        }

        if (isClosed === true && isSaved === false) {
            getDataInfo();
            onSetClose(false);
        }
        onDataChangeModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosed, isSaved, onDataChangeModal, onSetClose, onSetSave]);

    // Design Top Tab에서 데이터 변경 후 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isChangeTabSave === true) {
            onChangeTabClose(false);
            handleSave();
            onChangeTabSave(false);
        }

        if (isChangeTabClose === true && isChangeTabSave === false) {
            getDataInfo();
            onChangeTabClose(false);
        }
        onChangeDataCheckModal(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeTabClose, isChangeTabSave, onChangeDataCheckModal, onChangeTabClose, onChangeTabSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box-row">
                <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                        <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                            <div ref={captureRef}>
                                {/* 추가 버튼 구간  */}
                                {getDataList()}

                                <div className="pb-3 m-2 text-center d-flex justify-content-center align-items-center">
                                    <button
                                        className={cn("btn btn-sm btn-new", {'disabled': lock === 2})}
                                        onClick={handleAddItem}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>

            {/* 하단 처리 버튼 */}
            {/*ref 팝업은 design의 reference에 있다*/}
            <DesignBottom onList={goToList} onRef={handleReference} onSave={handleSave}/>
        </>
    );
};

export default React.memo(ObjectiveConfig);
