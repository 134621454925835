import React, { useCallback } from 'react';
import cn from 'classnames';

/**
 *  @summary    선택 박스
 *
 *  @author  최종 수정자
 *  @version 1.0, 작업 내용
 *  @see     None
 */

/**
 *  @component    Checkbox
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  component - check를 할 수 있는 component
 */
const Checkbox = (props) => {

    const {name, groupName, onChange, currentValue, label, disabled, onFocus, compare} = props;

    // 클릭 시 변경값을 state에 저장하기 위해 호출되는 함수
    const handleChange = useCallback((e) => {
        const value = e.target.checked ? 1 : 0;
        console.log(value);

        if (onChange) {
            console.log(value);
            onChange(name, value, label, e);
        }
    }, [label, name, onChange]);


    return (
        <div className="custom-control custom-checkbox p-t-0 m-t-0">
            <input
                className={cn({"custom-control-input": true, "is-invalid": compare})} type="checkbox" value=""
                checked={currentValue ? currentValue : false}
                disabled={disabled}
                id={name}
                name={groupName}
                onChange={handleChange}
                onFocus={onFocus}/>
            <label htmlFor={name} className="custom-control-label">
                {label && label}
            </label>
        </div>
    );
};

export default Checkbox;
