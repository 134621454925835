import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    AXIOS_GET,
    AXIOS_PUT,
    GenerateID, generateRandomId,
    getListToString,
    getSessionState,
    handleExamplePopup
} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import SelectElementDateModal from "../SelectElementDateModal";
import AUIGrid from "../../../../../components/AUIGrid";
import DomainExplain from "../../../../../components/DomainExplain";
import deleteIcon from "../../../../../../assets/icons/close.svg";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     TVDomainList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/tdomain/${ID}?domain=${domain}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/tdomain/${domain}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    TVDomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TV 도메인 컴포넌트
 */
const TVDomainList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     TVDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     TVDomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {String} designID - Add 버튼 클릭시 STUDYID 컬럼에 들어갈 Design ID
     *  @property     {String} name - mapping의 name
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, designID, name, lock} = getSessionState();

    /**
     *  @memberOf     TVDomainList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     TVDomainList
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     TVDomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     TVDomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     TVDomainList
     *  @var          {Object} selectVisitInfo
     *  @description  Start Visit, End Visit 칸에 표시될 정보
     */
    const [selectVisitInfo, setSelectVisitInfo] = useState({});

    /**
     *  @memberOf     TVDomainList
     *  @var          {String} visitType
     *  @description  Start Visit, End Visit 어떤 칸이 선택 되었는지 구분 짓는 값
     */
    const [visitType, setVisitType] = useState("");

    /**
     *  @memberOf     TVDomainList
     *  @var          {String} rowID
     *  @description  선택 줄 ID
     */
    const [rowID, setRowID] = useState("");

    /**
     *  @memberOf     TVDomainList
     *  @var          {String} descriptionValue
     *  @description  Description 입력 된 값
     */
    const [descriptionValue, setDescriptionValue] = useState('');

    /**
     *  @memberOf     TVDomainList
     *  @var          {Boolean} isVariableModal
     *  @description  Start of Element / End of Element Modal의 Open/Close 상태
     */
    const [isVariableModal, setVariableModal] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     TVDomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
            domain: "TV"
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleDelete
     *  @description  Example 알림창, Start of Visit / End of Visit 입력창 닫기
     */
    const handleClose = useCallback(() => {
        setVariableModal(false);
        setSelectVisitInfo({});
    }, []);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleAddRow
     *  @description  Add 버튼 클릭시 호출 되는 함수. TV 도메인 한줄 추가.
     */
    const handleAddRow = useCallback(() => {
        if (lock !== 2) {
            const params = {
                num: 1,
                item: {
                    "ID": generateRandomId(),
                    "row": "",
                    "STUDYID": name.split(" ")[0],
                    "domain": "",
                    "VISITNUM": "",
                    "VISIT": "",
                    "VISITDY": "",
                    "ARMCD": "",
                    "ARM": "",
                    "TVSTRL": "",
                    "startVisit": "",
                    "TVENRL": "",
                    "endVisit": "",
                    "visit": []
                },
                dataList: dataList
            };

            auiGrid.current.request(params);
        }
    }, [dataList, lock, name]);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleDeleteRow
     *  @description  X 버튼 클릭시 호출 되는 함수. TV 도메인 한줄 삭제.
     */
    const handleDeleteRow = useCallback((e) => {
        // let _dataList = window.AUIGrid.getOrgGridData('#grid_wrap'); //grid 데이터 가지고 옴
        let _dataList = [...dataList];
        _dataList.splice(e.rowIndex, 1); //선택 된 index 삭제

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     TVDomainList
     *  @function     gridResponse
     *  @param        {Array} list - 그리드 목록
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((list) => {
        if (list) {
            let dataList = [];

            list.forEach((row, idx) => {
                dataList.push({
                    ...row,
                    row: (idx + 1),
                    domain: "TV"
                });
            });

            setDataList(dataList);
        }
    }, []);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleDrag
     *  @param        {Array} list - Grid에서 순서 변경 된 목록
     *  @description  Grid에서 row drag & drop 시 호출되는 함수
     */
    const handleDrag = useCallback((list) => {
        setDataList(list);
    }, []);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescriptionValue(e.target.value);
    }, []);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleCellClick
     *  @param        {Object} event - 선택한 줄 정보
     *  @description  Start Visit, End Visit 칸 클릭시 호출되는 함수
     */
    const handleCellClick = useCallback((event) => {
        if (lock !== 2) {
            /**
             * 선택한 row에서
             * Start Visit을 선택했으면 visit List에서 type이 START인 obj 저장
             * End Visit을 선택했으면 visit List에서 type이 END인 obj 저장
             */
            dataList.forEach(data => {
                if (data.ID === event.item.ID) {
                    /**
                     * visit list에 type이 START, END가 있을때
                     * visit list에 type이 START나 END가 둘중 한개만 있을때
                     */
                    if (data.visit.length === 2 || data.visit.length === 1) {
                        data.visit.forEach(visit => {
                            if (event.dataField === "startVisit" && visit.type === "START") {
                                setSelectVisitInfo(visit);
                            } else if (event.dataField === "endVisit" && visit.type === "END") {
                                setSelectVisitInfo(visit);
                            }
                        });
                    }

                    /**
                     * visit list에 아무것도 없을때.
                     * visit list에 type이 START나 END가 둘중 한개만 있을때.
                     * Modal에 선택된 column에서 만들어져야할 type을 몰라 type 전달을 위한 처리.
                     */
                    if (data.visit.length === 0 || data.visit.length === 1) {
                        if (event.dataField === "startVisit") {
                            setVisitType("START");
                        } else if (event.dataField === "endVisit") {
                            setVisitType("END");
                        }
                    }
                }
            });

            setRowID(event.item.ID); //선택된 ID를 저장하여 Modal로 넘겨주기 위해
            setVariableModal(!isVariableModal);
        }
    }, [dataList, isVariableModal, lock]);

    /**
     *  @memberOf     TVDomainList
     *  @function     getVisitData
     *  @param        {String} ID - TV ID
     *  @param        {Object} visitInfo - 변경 된 visit 정보
     *  @description  Start of Visit / End of Visit 입력창에서 작성후 입력된 데이터 처리하는 함수
     */
    const getVisitData = useCallback((ID, visitInfo) => {
        let _dataList = dataList; //변화된 visit 정보를 저장
        let changeDataList = []; //변경된 visit 정보를 넣어주기 위한 변수 선언

        _dataList.forEach(data => {
            if (data.ID === visitInfo.TVID) { //visit 데이터가 있는 경우
                data.visit.splice(data.visit.findIndex(row => row.type === visitInfo.type), 1); //변경된 type을 잘라내고
                data.visit.push(visitInfo); //변경되어 받아온 visit info를 넣어줌
            } else if (visitInfo.TVID === undefined) { //datalist에 visit 데이터가 없는 경우
                //선택된 ID에 추가 하기 위해
                if (data.ID === ID) {
                    let visitID = GenerateID(); //임의 ID 생성
                    let conditionList = [];
                    let _visitInfo = visitInfo; //visitID 추가된 visitInfo를 만듬

                    //visit 안에 condition에 임의 ID 생성된거 적용 시켜줌
                    visitInfo.condition.forEach(condition => {
                        conditionList.push({...condition, visitID: visitID});
                    });

                    _visitInfo = {..._visitInfo, condition: conditionList};

                    data.visit.push({..._visitInfo, ID: visitID, TVID: data.ID});
                }
            }
        });

        //변경된 data list에서 다시 화면에 뿌려주기 위한 작업
        _dataList.forEach((row, idx) => {
            let startVisitArr = [];
            let endVisitArr = [];

            //Start Visit, End Visit 값 만드는 곳
            if (row.visit.length !== 0) {
                row.visit.forEach(item => {
                    let conditionList = [];

                    item.condition.forEach(condition => {
                        conditionList.push(condition.type + " " + condition.variable + " " + condition.operator + " " + condition.value);
                    });

                    if (item.type === "START") {
                        startVisitArr.push(item.studyEvent === "" && item.domain === "" && item.variable === "" ? "" : `${item.domain}.${item.variable}(${item.studyEvent})` + (conditionList.length !== 0 ? ` where = (${getListToString(conditionList)})` : ""));
                    } else if (item.type === "END") {
                        endVisitArr.push(item.studyEvent === "" && item.domain === "" && item.variable === "" ? "" : `${item.domain}.${item.variable}(${item.studyEvent})` + (conditionList.length !== 0 ? ` where = (${getListToString(conditionList)})` : ""));
                    }

                });
            }

            changeDataList.push({
                ...row,
                row: (idx + 1),
                domain: "TV",
                startVisit: startVisitArr,
                endVisit: endVisitArr,
            });
        });

        setDataList(changeDataList);

        handleClose();
    }, [dataList, handleClose]);

    /**
     *  @memberOf     TVDomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     TVDomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let params = {};
            let _dataList = [...dataList];

            _dataList.forEach((data, idx) => {
                data.STUDYID = designID;
                data.order = idx + 1;
            });

            let sendObj = {
                data: _dataList,
                description: descriptionValue
            };

            // 데이터 생성에 필요한 parameter
            params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                domain: "TV",
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, descriptionValue, designID, lock]);

    /**
     *  @memberOf     TVDomainList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     TVDomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let dataList = [];

                    data.data.forEach((row, idx) => {
                        let startVisitArr = [];
                        let endVisitArr = [];

                        //Start Visit, End Visit 컬럼에 들어갈 값 만드는 곳
                        if (row.visit.length !== 0) {
                            row.visit.forEach(item => {
                                let conditionList = [];

                                item.condition.forEach(condition => {
                                    conditionList.push(condition.type + " " + condition.variable + " " + condition.operator + " " + condition.value);
                                });

                                if (item.type === "START") {
                                    startVisitArr.push(item.studyEvent === "" && item.domain === "" && item.variable === "" ? "" : `${item.domain}.${item.variable}(${item.studyEvent})` + (conditionList.length !== 0 ? ` where = (${getListToString(conditionList)})` : ""));
                                } else if (item.type === "END") {
                                    endVisitArr.push(item.studyEvent === "" && item.domain === "" && item.variable === "" ? "" : `${item.domain}.${item.variable}(${item.studyEvent})` + (conditionList.length !== 0 ? ` where = (${getListToString(conditionList)})` : ""));
                                }
                            });
                        }

                        dataList.push({
                            ...row,
                            row: (idx + 1),
                            domain: "TV",
                            startVisit: startVisitArr,
                            endVisit: endVisitArr
                        });
                    });

                    setDataList(dataList);
                    setDescriptionValue(data.description);
                    break;

                case COMMAND.DATA_UPDATE:
                    //스크롤위치 초기화
                    vertical = 0;
                    horizontal = 0;

                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     TVDomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "3%",
            editable: false
        },
        {
            dataField: "STUDYID",
            headerText: "STUDYID",
            headerStyle: "aui-grid-header-red",
            editable: lock !== 2
        },
        {
            dataField: "domain",
            headerText: "DOMAIN",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "VISITNUM",
            headerText: "VISITNUM",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: lock !== 2
        },
        {
            dataField: "VISIT",
            headerText: "VISIT",
            headerStyle: "aui-grid-header-green",
            editable: lock !== 2
        },
        {
            dataField: "VISITDY",
            headerText: "VISITDY",
            headerStyle: "aui-grid-header-green",
            editable: lock !== 2
        },
        {
            dataField: "ARMCD",
            headerText: "ARMCD",
            headerStyle: "aui-grid-header-blue",
            editable: lock !== 2
        },
        {
            dataField: "ARM",
            headerText: "ARM",
            headerStyle: "aui-grid-header-green",
            editable: lock !== 2
        },
        {
            dataField: "TVSTRL",
            headerText: "TVSTRL",
            headerStyle: "aui-grid-header-red",
            editable: lock !== 2
        },
        {
            dataField: "startVisit",
            headerText: "Start Visit",
            width: "20%",
            editable: false
        },
        {
            dataField: "TVENRL",
            headerText: "TVENRL",
            headerStyle: "aui-grid-header-green",
            editable: lock !== 2
        },
        {
            dataField: "endVisit",
            headerText: "End Visit",
            width: "20%",
            editable: false
        },
        {
            dataField: "",
            headerText: "",
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
                iconHeight: 16,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "0 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">
                                            <div className="align-self-end">
                                                <DomainExplain/>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                <div className="d-flex justify-content-end m-b-10">
                                                    <button
                                                        className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                        onClick={handleAddRow}>
                                                        Add
                                                    </button>

                                                    <button
                                                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                                                        onClick={() => handleExamplePopup("TV")}>
                                                        Example
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            ref={auiGrid}
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            onCellClick={handleCellClick}
                                            isDrag={true}
                                            onDrag={handleDrag}
                                            response={gridResponse}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}
                                            minusContentHeight={236}/>

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={descriptionValue}
                                                style={{resize: "none", height: "80px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isVariableModal && (
                <SelectElementDateModal
                    title="Start of Visit / End of Visit"
                    history={history}
                    onOk={getVisitData}
                    onClose={handleClose}
                    data={selectVisitInfo}
                    dataList={dataList}
                    type={visitType}
                    rowID={rowID}/>
            )}
        </>
    );
};

export default React.memo(TVDomainList);
