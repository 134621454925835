import React from 'react';
import { Redirect } from "react-router-dom";
import { CONCEPT_TAB_OPTION } from "../constant/master/CONSTANT_LIST";
import { ROLE } from "../constant/CommonConstant";

// master menu
const Partner = React.lazy(()=> import("../imtrial/views/master/partner/Partner"));
const PartnerDetail = React.lazy(()=> import("../imtrial/views/master/partner/PartnerDetail"));
const Country = React.lazy(()=> import("../imtrial/views/master/country/Country"));
const User = React.lazy(()=> import("../imtrial/views/master/user/User"));
const UserDetail = React.lazy(()=> import("../imtrial/views/master/user/UserDetail"));
const SupportNotice = React.lazy(()=> import("../imtrial/views/master/support/notice/Notice"));
const SupportNoticeDetail = React.lazy(()=> import("../imtrial/views/master/support/notice/NoticeDetail"));
const Log = React.lazy(() => import("imtrial/views/master/log/Log"));
const MasterTraining = React.lazy(() => import("imtrial/views/master/training/Training"));
const MasterTrainingDetail = React.lazy(() => import("imtrial/views/master/training/TrainingDetail"));

// Map
const Map = React.lazy(() => import("imtrial/views/master/mdr_map/tamap/Map"));
const MapDetail = React.lazy(() => import("imtrial/views/master/mdr_map/tamap/MapDetail"));
const MapViewInfo = React.lazy(() => import("imtrial/views/master/mdr_map/tamap/MapViewInfo"));
const Concept = React.lazy(() => import("imtrial/views/master/mdr_map/concept/Concept"));
const ConceptDetail = React.lazy(() => import("imtrial/views/master/mdr_map/concept/ConceptDetail"));
const ConceptViewInfo = React.lazy(() => import("imtrial/views/master/mdr_map/concept/ConceptViewInfo"));
const ConceptMatrixInfo = React.lazy(() => import("imtrial/views/master/mdr_map/concept/ConceptMatrixInfo"));
const DesignMap = React.lazy(() => import("imtrial/views/master/mdr_map/designmap/DesignMap"));
const DesignMapDetail = React.lazy(() => import("imtrial/views/master/mdr_map/designmap/DesignMapDetail"));
const DesignMapViewInfo = React.lazy(() => import("imtrial/views/master/mdr_map/designmap/DesignMapViewInfo"));

// MDR
const TA = React.lazy(() => import("imtrial/views/master/mdr_metadata/ta/Ta"));
const TADetial = React.lazy(() => import("imtrial/views/master/mdr_metadata/ta/TaDetail"));
const Activity = React.lazy(() => import("imtrial/views/master/mdr_metadata/activity/Activty"));
const ActivityDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/activity/ActivityDetail"));
const Assessment = React.lazy(() => import("imtrial/views/master/mdr_metadata/assessment/Assessment"));
const AssessmentDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/assessment/AssessmentDetail"));
const Indication = React.lazy(() => import("imtrial/views/master/mdr_metadata/indication/Indication"));
const IndicationDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/indication/IndicationDetail"));
const Objective = React.lazy(() => import("imtrial/views/master/mdr_metadata/objective/Objective"));
const ObjectiveDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/objective/ObjectiveDetail"));
const Endpoint = React.lazy(() => import("imtrial/views/master/mdr_metadata/endpoint/Endpoint"));
const EndpointDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/endpoint/EndpointDetail"));
const Criteria = React.lazy(() => import("imtrial/views/master/mdr_metadata/criteria/Criteria"));
const CriteriaDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/criteria/CriteriaDetail"));
const SDTMFunctionDetail = React.lazy(() => import("imtrial/views/master/mdr_metadata/SDTMFunction/SdtmFunctionDetail"));
const SDTMFunction = React.lazy(() => import("imtrial/views/master/mdr_metadata/SDTMFunction/SdtmFunction"));

// Standard
const IG = React.lazy(() => import("imtrial/views/master/mdr_standard/ig/Ig"));
const IGDetail = React.lazy(() => import("imtrial/views/master/mdr_standard/ig/IgDetail"));
const Variable = React.lazy(() => import("imtrial/views/master/mdr_standard/ig/variable/Variable"));
const VariableInfo = React.lazy(() => import("imtrial/views/master/mdr_standard/ig/variable/VariableInfo"));
const SdtmIG = React.lazy(() => import("imtrial/views/master/mdr_standard/sdtm_ig/SdtmIg"));
const SdtmIGDetail = React.lazy(() => import("imtrial/views/master/mdr_standard/sdtm_ig/SdtmIgDetail"));
const SdtmVariable = React.lazy(() => import("imtrial/views/master/mdr_standard/sdtm_ig/variable/SdtmVariable"));
const SdtmVariableInfo = React.lazy(() => import("imtrial/views/master/mdr_standard/sdtm_ig/variable/SdtmVariableInfo"));
const Terminology = React.lazy(() => import("imtrial/views/master/mdr_standard/terminology/Terminology"));
const TerminologyDetail = React.lazy(() => import("imtrial/views/master/mdr_standard/terminology/TerminologyDetail"));
const Codelist = React.lazy(() => import("imtrial/views/master/mdr_standard/codelist/Codelist"));
const CodelistDetail = React.lazy(() => import("imtrial/views/master/mdr_standard/codelist/CodelistDetail"));
const CustomCodelist = React.lazy(() => import("imtrial/views/master/mdr_standard/custom_codelist/CustomCodelist"));
const CustomCodelistDetail = React.lazy(() => import("imtrial/views/master/mdr_standard/custom_codelist/CustomCodelistDetail"));
const UserGuide = React.lazy(() => import("imtrial/views/master/reference/userguide/UserGuide"));
const UserGuideDetail = React.lazy(() => import("imtrial/views/master/reference/userguide/UserGuideDetail"));


const MasterRoutes = [
    {
        path: '/master',
        name: "Master",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/partner"/>,
    },
    {
        path: "/master/partner",
        name: "Partner",
        role: ROLE.MASTER,
        exact: true,
        component: Partner,
    },
    {
        path: "/master/partner/detail",
        name: "Partner Detail",
        role: ROLE.MASTER,
        exact: true,
        component: PartnerDetail,
    },
    {
        path: "/master/partner/new",
        name: "New Partner",
        role: ROLE.MASTER,
        exact: true,
        component: PartnerDetail,
    },
    {
        path: "/master/country",
        name: "Country",
        role: ROLE.MASTER,
        exact: true,
        component: Country,
    },
    {
        path: "/master/user",
        name: "User",
        role: ROLE.MASTER,
        exact: true,
        component: User,
    },
    {
        path: "/master/user/detail",
        name: "User Detail",
        role: ROLE.MASTER,
        exact: true,
        component: UserDetail,
    },
    {
        path: "/master/user/new",
        name: "New User",
        role: ROLE.MASTER,
        exact: true,
        component: UserDetail,
    },
    {
        path: "/master/support",
        name: "Support",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/support/notice"/>,
    },
    {
        path: "/master/support/notice",
        name: "Notice",
        role: ROLE.MASTER,
        exact: true,
        component: SupportNotice,
    },
    {
        path: "/master/support/notice/detail",
        name: "Notice Detail",
        role: ROLE.MASTER,
        exact: true,
        component: SupportNoticeDetail,
    },
    {
        path: "/master/support/notice/new",
        name: "New Notice",
        role: ROLE.MASTER,
        exact: true,
        component: SupportNoticeDetail,
    },
    {
        path: "/master/log",
        name: "Log",
        role: ROLE.MASTER,
        exact: true,
        component: Log,
    },
    {
        path: "/master/training",
        name: "Master Training",
        role: ROLE.MASTER,
        exact: true,
        component: MasterTraining,
    },
    {
        path: "/master/training/detail",
        name: "Master Training Detail",
        role: ROLE.MASTER,
        exact: true,
        component: MasterTrainingDetail,
    },
    {
        path: "/master/training/new",
        name: "New Master Training",
        role: ROLE.MASTER,
        exact: true,
        component: MasterTrainingDetail,
    },
    {
        path: "/master/map",
        name: "Map",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/map/tamap"/>,
    },
    {
        path: "/master/map/tamap",
        name: "TA Map",
        role: ROLE.MASTER,
        exact: true,
        component: Map,
    },
    {
        path: "/master/map/tamap/new",
        name: "New TA Map",
        role: ROLE.MASTER,
        exact: true,
        component: MapDetail,
    },
    {
        path: "/master/map/tamap/detail",
        name: "TA Map Detail",
        role: ROLE.MASTER,
        exact: true,
        component: MapDetail,
    },
    {
        path: "/master/map/tamap/detail/view",
        name: "View",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to={{pathname: "/master/map/tamap/detail", state: {type: CONCEPT_TAB_OPTION[1].text}}}/>,
    },
    {
        path: "/master/map/tamap/detail/view/detail",
        name: "TA Map View Detail",
        role: ROLE.MASTER,
        exact: true,
        component: MapViewInfo,
    },
    {
        path: "/master/map/concept",
        name: "Concept",
        role: ROLE.MASTER,
        exact: true,
        component: Concept,
    },
    {
        path: "/master/map/concept/new",
        name: "New Concept",
        role: ROLE.MASTER,
        exact: true,
        component: ConceptDetail,
    },
    {
        path: "/master/map/concept/detail",
        name: "Concept Detail",
        role: ROLE.MASTER,
        exact: true,
        component: ConceptDetail,
    },
    {
        path: "/master/map/concept/detail/matrix",
        name: "Matrix",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect
            to={{pathname: "/master/map/concept/detail", state: {type: CONCEPT_TAB_OPTION[2].text}}}/>,
    },
    {
        path: "/master/map/concept/detail/matrix/detail",
        name: "Concept Matrix Detail",
        role: ROLE.MASTER,
        exact: true,
        component: ConceptMatrixInfo,
    },
    {
        path: "/master/map/concept/detail/view",
        name: "View",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect
            to={{pathname: "/master/map/concept/detail", state: {type: CONCEPT_TAB_OPTION[1].text}}}/>,
    },
    {
        path: "/master/map/concept/detail/view/detail",
        name: "Concept View Detail",
        role: ROLE.MASTER,
        exact: true,
        component: ConceptViewInfo,
    },
    {
        path: "/master/map/designmap",
        name: "Design Map",
        role: ROLE.MASTER,
        exact: true,
        component: DesignMap,
    },
    {
        path: "/master/map/designmap/new",
        name: "New Design Map",
        role: ROLE.MASTER,
        exact: true,
        component: DesignMapDetail,
    },
    {
        path: "/master/map/designmap/detail",
        name: "Design Map Detail",
        role: ROLE.MASTER,
        exact: true,
        component: DesignMapDetail,
    },
    {
        path: "/master/map/designmap/detail/view",
        name: "View",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to={{pathname: "/master/map/designmap/detail", state: {type: CONCEPT_TAB_OPTION[1].text}}}/>,
    },
    {
        path: "/master/map/designmap/detail/view/detail",
        name: "Design Map View Detail",
        role: ROLE.MASTER,
        exact: true,
        component: DesignMapViewInfo,
    },
    {
        path: "/master/metadata",
        name: "Meta Data",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/map/metadata/ta"/>,
    },
    {
        path: "/master/metadata/ta",
        name: "Therapeutic Area",
        role: ROLE.MASTER,
        exact: true,
        component: TA,
    },
    {
        path: "/master/metadata/ta/new",
        name: "New TA",
        role: ROLE.MASTER,
        exact: true,
        component: TADetial
    },
    {
        path: "/master/metadata/ta/detail",
        name: "TA Detail",
        role: ROLE.MASTER,
        exact: true,
        component: TADetial,
    },
    {
        path: "/master/metadata/activity",
        name: "Activity",
        role: ROLE.MASTER,
        exact: true,
        component: Activity,
    },
    {
        path: "/master/metadata/activity/new",
        name: "New Activity",
        role: ROLE.MASTER,
        exact: true,
        component: ActivityDetail,
    },
    {
        path: "/master/metadata/activity/detail",
        name: "Activity Detail",
        role: ROLE.MASTER,
        exact: true,
        component: ActivityDetail,
    },
    {
        path: "/master/metadata/assessment",
        name: "Assessment",
        role: ROLE.MASTER,
        exact: true,
        component: Assessment,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/assessment/new",
        name: "New Assessment",
        role: ROLE.MASTER,
        exact: true,
        component: AssessmentDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    }, {
        path: "/master/metadata/assessment/detail",
        name: "Assessment Detail",
        role: ROLE.MASTER,
        exact: true,
        component: AssessmentDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/indication",
        name: "Indication",
        role: ROLE.MASTER,
        exact: true,
        component: Indication,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/indication/new",
        name: "New Indication",
        role: ROLE.MASTER,
        exact: true,
        component: IndicationDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    }, {
        path: "/master/metadata/indication/detail",
        name: "Indication Detail",
        role: ROLE.MASTER,
        exact: true,
        component: IndicationDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/objective",
        name: "Objective",
        role: ROLE.MASTER,
        exact: true,
        component: Objective,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/objective/new",
        name: "New Objective",
        role: ROLE.MASTER,
        exact: true,
        component: ObjectiveDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/objective/detail",
        name: "Objective Detail",
        role: ROLE.MASTER,
        exact: true,
        component: ObjectiveDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/endpoint",
        name: "Endpoint",
        role: ROLE.MASTER,
        exact: true,
        component: Endpoint,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/endpoint/new",
        name: "New Endpoint",
        role: ROLE.MASTER,
        exact: true,
        component: EndpointDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/endpoint/detail",
        name: "Endpoint Detail",
        role: ROLE.MASTER,
        exact: true,
        component: EndpointDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/criteria",
        name: "Criteria",
        role: ROLE.MASTER,
        exact: true,
        component: Criteria,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/criteria/new",
        name: "New Criteria",
        role: ROLE.MASTER,
        exact: true,
        component: CriteriaDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/criteria/detail",
        name: "Criteria Detail",
        role: ROLE.MASTER,
        exact: true,
        component: CriteriaDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/sdtmfunction",
        name: "SDTM Function",
        role: ROLE.MASTER,
        exact: true,
        component: SDTMFunction,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/sdtmfunction/new",
        name: "New SDTM Function",
        role: ROLE.MASTER,
        exact: true,
        component: SDTMFunctionDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/metadata/sdtmfunction/detail",
        name: "SDTM Function Detail",
        role: ROLE.MASTER,
        exact: true,
        component: SDTMFunctionDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard",
        name: "Standard",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/standard/ig"/>,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/ig",
        name: "CDASH IG",
        role: ROLE.MASTER,
        exact: true,
        component: IG,
    },
    {
        path: "/master/standard/ig/new",
        name: "New CDASH IG",
        role: ROLE.MASTER,
        exact: true,
        component: IGDetail,
    },
    {
        path: "/master/standard/ig/detail",
        name: "CDASH IG Detail",
        role: ROLE.MASTER,
        exact: true,
        component: IGDetail,
    },

    {
        path: "/master/standard/ig/variable",
        name: "CDASH Variable",
        role: ROLE.MASTER,
        exact: true,
        component: Variable,
    },
    {
        path: "/master/standard/ig/variable/new",
        name: "New CDASH Variable",
        role: ROLE.MASTER,
        exact: true,
        component: VariableInfo,
    },
    {
        path: "/master/standard/ig/variable/detail",
        name: "CDASH Variable Detail",
        role: ROLE.MASTER,
        exact: true,
        component: VariableInfo,
    },
    {
        path: "/master/standard/sdtm-ig",
        name: "SDTM IG",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmIG,
    },
    {
        path: "/master/standard/sdtm-ig/new",
        name: "New SDTM IG",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmIGDetail,
    },
    {
        path: "/master/standard/sdtm-ig/detail",
        name: "SDTM IG Detail",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmIGDetail,
    },

    {
        path: "/master/standard/sdtm-ig/variable",
        name: "SDTM Variable",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmVariable,
    },
    {
        path: "/master/standard/sdtm-ig/variable/new",
        name: "New SDTM Variable",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmVariableInfo,
    },
    {
        path: "/master/standard/sdtm-ig/variable/detail",
        name: "SDTM Variable Detail",
        role: ROLE.MASTER,
        exact: true,
        component: SdtmVariableInfo,
    },
    {
        path: "/master/standard/terminology",
        name: "Terminology",
        role: ROLE.MASTER,
        exact: true,
        component: Terminology,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/terminology/new",
        name: "New Terminology",
        role: ROLE.MASTER,
        exact: true,
        component: TerminologyDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/terminology/detail",
        name: "Terminology Detail",
        role: ROLE.MASTER,
        exact: true,
        component: TerminologyDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/codelist",
        name: "Codelist",
        role: ROLE.MASTER,
        exact: true,
        component: Codelist
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/codelist/detail",
        name: "Codelist Detail",
        role: ROLE.MASTER,
        exact: true,
        component: CodelistDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/custom",
        name: "Custom Codelist",
        role: ROLE.MASTER,
        exact: true,
        component: CustomCodelist,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/custom/new",
        name: "New Custom Codelist",
        role: ROLE.MASTER,
        exact: true,
        component: CustomCodelistDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/standard/custom/detail",
        name: "Custom Codelist Detail",
        role: ROLE.MASTER,
        exact: true,
        component: CustomCodelistDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/reference",
        name: "Reference",
        role: ROLE.MASTER,
        exact: true,
        component: () => <Redirect to="/master/reference/userguide"/>,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/reference/userguide",
        name: "User Guide",
        role: ROLE.MASTER,
        exact: true,
        component: UserGuide,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/reference/userguide/new",
        name: "New User Guide",
        role: ROLE.MASTER,
        exact: true,
        component: UserGuideDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
    {
        path: "/master/reference/userguide/detail",
        name: "User Guide Detail",
        role: ROLE.MASTER,
        exact: true,
        component: UserGuideDetail,
        // component: () => <Redirect to="/master/support/notice" />,
    },
]

export default MasterRoutes;
