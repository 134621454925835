import React from "react";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";

/**
 *  @function  showConfirmSelect
 *  @param  {string} title        - 타이틀 표시
 *  @param  {string} msg          - message 표시
 *  @param  {string} list         - list 형 데이터의 표시
 *  @param  {function} onOk       - ok 버튼 클릭 시 처리 사항
 *  @param  {function} onCancel   - cancel 버튼 클릭 시 처리 사항
 *  @description  confirm 팝업 창 (oK, cancel 중 선택)
 */
export const showConfirmSelect= (title, msg, list, onOk, onCancel)=>{

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <>
          <Card className="popup_min_width border-1 shadow-lg rounded-lg mb-0" size="lg">
            <CardHeader className="bg-danger">
              <i className="fas fa-info" /><strong className="ml-2">Confirm</strong>
            </CardHeader>
            <CardBody className="popup_min_height py-4 mx-3">
              <Row>
                <h3 className="card-title">{title}</h3>
              </Row>
              <Row>
                <p className="card-text">{msg}</p>
              </Row>
              <div className='divider mt-3' />
              <Row className="mt-3">
                <Col sm="12">
                  <div className="prm-step">
                    {
                      list.map((item, index)=>{
                        return <div key={index} className={'step-item include mx-2'}>{item}</div>;
                      })
                    }
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <button
                  id="cancelButton"
                  className="btn btn-blue btn-inner-shadow text-white px-3 px-md-5"
                  onClick={() => {
                    if(onCancel !== undefined){
                      onCancel();
                    }
                    onClose();
                  }}
              >
                No
              </button>
              <button
                  className="btn btn-orange text-white px-5 mx-3"
                  onClick={() => {
                    onOk();
                    onClose();
                  }}
              >
                Yes
              </button>
            </CardFooter>
          </Card>
        </>
      );
    }
  });
};

/**
 *  @function  showConfirmAlert
 *  @param  {string} title        - 타이틀 표시
 *  @param  {string} msg          - message 표시
 *  @description  alert 팝업 창 표시
 */
export const showConfirmAlert = (title, msg) => {

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <>
          <Card className="popup_min_width border-1 shadow-lg rounded-lg mb-0" size="lg">
            <CardHeader>
              <i className="fas fa-info" /><strong className="ml-2">Warning</strong>
            </CardHeader>
            <CardBody className="popup_min_height py-4">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{msg}</p>
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <button
                  id="cancelButton"
                  className="btn btn-blue btn-inner-shadow text-white px-5"
                  onClick={onClose}
              >
                Close
              </button>
            </CardFooter>
          </Card>
        </>
      );
    }
  });
};


/**
 *  @function  showAlertList
 *  @param  {string} title        - 타이틀 표시
 *  @param  {string} msg          - message 표시
 *  @param  {array} list          - list 형 데이터의 표시
 *  @description  confirm 팝업 창 (oK, cancel 중 선택)
 */
export const showAlertList= (title, msg, list)=>{

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <>
          <Card className="popup_min_width border-1 shadow-lg rounded-lg mb-0" size="lg">
            <CardHeader>
              <i className="fas fa-info" /><strong className="ml-2">Notice</strong>
            </CardHeader>
            <CardBody className="popup_min_height py-4 mx-3">
              <Row>
                <h3 className="card-title">{title}</h3>
              </Row>
              <Row>
                <p className="card-text">{msg}</p>
              </Row>
              <div className='divider mt-3' />
              <Row className="mt-3">
                <Col sm="12">
                  <div className="prm-step">
                    {
                      list.map((item, index)=>{
                        return <div key={index} className={'step-item include mx-2'}>{item}</div>;
                      })
                    }
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <button
                  id="cancelButton"
                  className="btn btn-orange btn-inner-shadow text-white px-5"
                  onClick={onClose}
              >
                OK
              </button>
            </CardFooter>
          </Card>
        </>
      );
    }
  });
};


/**
 *  @function  showValidAlert
 *  @param  {string} msg          - message 표시
 *  @description  alert 팝업 창 표시
 */
export const showSimpleAlert = (msg) => {

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <>
          <Card className="popup_min_width border-1 shadow-lg rounded-lg mb-0" size="lg">
            <CardHeader>
              <i className="fas fa-info" /><strong className="ml-2">Alert</strong>
            </CardHeader>
            <CardBody className="popup_min_height py-4">
              <h5 className="card-title">{msg}</h5>
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <button
                  className="btn btn-blue btn-inner-shadow text-white px-3 px-md-5"
                  onClick={onClose}
              >
                Close
              </button>
            </CardFooter>
          </Card>
        </>
      );
    }
  });
};


/**
 *  @function  showConfirm
 *  @param  {string} msg          - message 표시
 *  @param  {function} onOk       - ok 버튼 클릭 시 처리 사항
 *  @param  {function} onCancel   - cancel 버튼 클릭 시 처리 사항
 *  @description  confirm 팝업 창 (oK, cancel 중 선택)
 */
export const showConfirm = ( msg, onOk, onCancel = undefined)=>{

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <>
          <Card className="border-1 shadow-lg rounded-lg mb-0" size="lg">
            <CardHeader className="popup_min_width">
              <i className="fas fa-info" /><strong className="ml-2">Confirm</strong>
            </CardHeader>
            <CardBody className="popup_min_height py-4 mx-3">
              <Row>
                <h5 className="card-title">{msg}</h5>
              </Row>
              {/*<Row>*/}
              {/*  <p className="card-text">{msg}</p>*/}
              {/*</Row>*/}
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <button
                  id="cancelButton"
                  className="btn btn-blue btn-inner-shadow text-white px-3 px-md-5"
                  onClick={() => {
                    if(onCancel !== undefined){
                      onCancel();
                    }
                    onClose();
                  }}
              >
                No
              </button>
              <button
                  className="btn btn-orange text-white px-5 mx-3"
                  onClick={() => {
                    onOk();
                    onClose();
                  }}
              >
                Yes
              </button>
            </CardFooter>
          </Card>
        </>
      );
    }
  });
};
