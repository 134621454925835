import React, {useCallback} from "react";
import classnames from "classnames";
import {getSessionState, isDataExist} from "common/commonFunction";
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";

/**
 *  @author       주예리나
 *  @version      1.0
 *  @component    TreatmentTableList
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  Schema 편집 팝업 내 Drug Information 리스트 컴포넌트
 */
const TreatmentTableList = (props) => {
    /**
     *  @memberOf      TreatmentTableList
     *  @type          {Object} props
     *  @property      {Array} dataList - 설정된 treatment 데이터 리스트
     *  @property      {Array} optionList - ip 카테고리에 표시할 옵션 리스트
     *  @property      {Object} checkValid - validation 객체
     *  @property      {Function} onDelete - 삭제 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onAdd - 추가 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Array} onChange - 데이터 변경 시 실행될 상위 이벤트 함수
     *  @property      {Object} currentValue - 화면에 표시할 현재 설정 value
     *  @property      {Function} onEditAmount - amount 값 변경 시 실행될 상위 이벤트 함수
     *  @property      {Function} onFocus - 입력 요소 focus 시 실행될 상위 이벤트 함수
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        dataList,
        optionList,
        checkValid,
        onDelete,
        onAdd,
        onChange,
        currentValue,
        onEditAmount,
        onFocus
    } = props;

    /**
     *  @memberOf     TreatmentTableList
     *  @type         {Object} getSessionState
     *  @property     {String} language - study 화면 언어 정보
     *  @description   session 에서 받아오는 정보
     */
    const {language} = getSessionState();

    /**
     *  @memberOf     TreatmentTableList
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 값
     *  @description  입력 데이터 변경 시 실행되는 함수
     */
    const handleChange = useCallback((name, value) => {
        if (onChange) {
            onChange(name, value);
        }
    }, [onChange]);

    /**
     *  @memberOf     TreatmentTableList
     *  @function     handleEditAmount
     *  @param        {String} value  - 입력 값
     *  @param        {Number} index  - 입력 요소의 데이터 index
     *  @description  amount 항목의 입력 요소 수정 시 실행되어 상위 이벤트 함수 호출
     */
    const handleEditAmount = useCallback((value, index) => {
        if (onEditAmount) {
            onEditAmount(value, index)
        }
    }, [onEditAmount]);

    /**
     *  @memberOf     TreatmentTableList
     *  @function     handleAdd
     *  @description  추가 버튼 클릭 시 실행되어 상위 이벤트 함수 호출
     */
    const handleAdd = useCallback(() => {
        if (onAdd) {
            onAdd();
        }
    }, [onAdd]);

    /**
     *  @memberOf     TreatmentTableList
     *  @function     handleDelete
     *  @param        {String} name  - 선택한 항목의 tag name
     *  @param        {Number} index  - 선택한 항목의 index
     *  @description  삭제 버튼 클릭 시 실행되어 상위 이벤트 함수 호출
     */
    const handleDelete = useCallback((name, index) => {
        if (onDelete) {
            onDelete(index)
        }
    }, [onDelete]);

    /**
     *  @memberOf     TreatmentTableList
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        if (onFocus) {
            onFocus(name);
        }
    }, [onFocus]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <table className="table table-striped table-td-valign-middle table-th-valign-middle m-b-20">
                <thead>
                <tr>
                    <th>{language === 'en' ? 'Intervention' : '약물 구분'}</th>
                    <th>{language === 'en' ? 'Treatment' : '약물 정보'}</th>
                    <th>
                        <div className="row">
                            <div className="col-2">
                                <div className={'pl-2 pt-2 font-weight-bold'}>X</div>
                            </div>
                            <div className="col">
                                {language === 'en' ? 'Amount' : '수량'}
                            </div>
                        </div>
                    </th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {Object.keys(dataList).length !== 0 && (
                    dataList.map((treatment, treatmentIdx) => {
                        let typeText = '';
                        let findType = optionList[0].find((typeData) => {
                            return typeData.value === treatment.type;
                        });

                        if (findType !== undefined) {
                            typeText = findType.text;
                        }

                        return (
                            <tr key={treatmentIdx}>
                                <td className="with-form-control px-0 pl-2">
                                    <div className="h-100" style={{verticalAlign: 'bottom'}}>
                                        <span>{typeText}</span>
                                    </div>
                                </td>

                                <td className="with-form-control px-0 pl-2">
                                    <div className=" h-100" style={{verticalAlign: 'bottom'}}>
                                        <span>{treatment.name} {treatment.shape} {treatment.dose} {treatment.unit}</span>
                                    </div>
                                </td>

                                <td className="with-form-control px-0 pl-2">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className={'pl-2 pt-2 font-weight-bold'}>X</div>
                                        </div>
                                        <div className="col">
                                            <TextField
                                                name={'amount'}
                                                currentValue={treatment.amount}
                                                type='number'
                                                min='1'
                                                onChange={(name, value) => {
                                                    handleEditAmount(value, treatmentIdx)
                                                }}/>
                                        </div>
                                    </div>
                                </td>
                                {
                                    <td className="with-btn text-right">
                                        <button className="btn btn-orange m-r-2"
                                                onClick={() => handleDelete('ip', treatmentIdx)}>-
                                        </button>
                                    </td>
                                }
                            </tr>
                        )
                    })
                )}
                <tr>
                    <td className="with-form-control px-0 pl-2">
                        <SelectBox className={'mr-1 auto-height'} name={'intervention'}
                                   dataList={optionList[0]} //setIpOptionList()
                                   currentValue={isDataExist(currentValue) && currentValue.type} //
                                   onChange={handleChange}
                                   validation={checkValid}
                                   onFocus={handleFocus}/>
                    </td>

                    <td className="with-form-control px-0 pl-2">
                        <SelectBox className={'mr-1'} name={'treatment'}
                                   dataList={optionList[1]} // currentTreatmentList
                                   currentValue={currentValue?.ipID}
                                   onChange={handleChange}
                                   validation={checkValid}
                                   onFocus={handleFocus}/>
                    </td>

                    <td className={classnames("with-form-control px-0", {"pl-2": true})}>
                        <div className="row">
                            <div className={"col-2"}>
                                <span className={'pl-2 pt-2 font-weight-bold'}>X</span>
                            </div>
                            <div className={"col"}>
                                <TextField className={'mx-1'} name={'amount'} type='number' min={1}
                                           currentValue={isDataExist(currentValue) && currentValue.amount}
                                           validation={checkValid}
                                           onFocus={handleFocus}
                                           onChange={handleChange}/>
                            </div>
                        </div>
                    </td>

                    <td className="with-btn text-right">
                        <button className="btn btn-blue m-r-2" onClick={handleAdd}>+</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </React.Fragment>
    )
}


export default React.memo(TreatmentTableList)
