import React, {useCallback, useEffect, useRef, useState} from "react";
import {AXIOS_PUT, copyObject, getSessionState} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "constant/ConstantURL";
import NetworkLayout from "common/NetworkLayout";
import cn from "classnames";
import useToast from "hooks/useToast";
import AUIGrid from "../../../../../components/AUIGrid";

let globalDataList = {};
/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       SdmDesignMatrix
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {ID, sendObject} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${SDTM_MAPPING_URL}/SDM/studyMatrix/${ID}`
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmDesignMatrix
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal에서 Design Matrix 탭 편집 컴포넌트
 */
const SdmDesignMatrix = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SdmDesignMatrix
     *  @type         {Object} props
     *  @property     {String} ID - 리스트 ID
     *  @property     {Object} history -  url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {ID, history, onClose} = props;

    /**
     *  @memberOf     SdmDesignMatrix
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} designID - design ID
     *  @description  session 에서 받아오는 정보
     */
    const {lock, designID} = getSessionState();

    /**
     *  @memberOf     SdmDesignMatrix
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SdmDesignMatrix
     *  @var          {Array} columnList
     *  @description  화면에 표시 될 그리드 컬럼 목록
     */
    const [columnList, setColumnList] = useState();

    /**
     *  @memberOf     SdmDesignMatrix
     *  @var          {Array} originDataInfo
     *  @description  서버에서 받아오는 원본 데이터
     */
    const [originDataInfo, setOriginDataInfo] = useState([]);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @var          {Array} gridList
     *  @description  grid data List
     */
    const [gridList, setGridList] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmDesignMatrix
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_LIST;

        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/sdm/studyMatrix/${ID}/${designID}`,
        };

        netWorkAgent.current.request(command, params);
    }, [ID, designID]);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        globalDataList[rowIndex][dataField] = value;
    }, []);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @function     handleSave
     *  @description  save 버튼 클릭 시 실행되어 데이터 저장 api 호출
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const _originDataInfo = copyObject(originDataInfo);
            const _globalDataList = copyObject(globalDataList);
            const reqDatas = [];

            _globalDataList.forEach(dataObj => {
                _originDataInfo.studyEpochs.forEach(epoch => {
                    for (let data in dataObj) {
                        for (let i = 0; i < epoch.numberOfElements; i++) {
                            if (data === epoch.ID + "_" + [i + 1] && dataObj[data] !== "") {
                                const newEpochID = data.split('_')[0];
                                const orderNumber = data.split('_')[1];

                                reqDatas.push({
                                    ID: "",
                                    armID: dataObj.armID,
                                    periodID: epoch.periodID,
                                    epochID: newEpochID,
                                    elementID: dataObj[epoch.ID + "_" + [i + 1]],
                                    order: parseInt(orderNumber)
                                });
                            }
                        }
                    }
                });
            });

            let sendList = [];
            let addedList = reqDatas;

            reqDatas.forEach((data) => {
                _originDataInfo.studyMatrix.forEach((matrix) => {
                    if (data.order === matrix.order && data.armID === matrix.armID && data.periodID === matrix.periodID && data.epochID === matrix.epochID) {
                        addedList = addedList.filter((item) => item !== data);
                        sendList.push({...data, ID: matrix.ID});
                    }
                });
            });

            addedList.forEach((item) => {
                sendList.push(item);
            });

            // 데이터 수정 요청
            const sendObject = {
                list: sendList
            };

            const command = COMMAND.DATA_UPDATE;
            // 데이터 수정에 필요한 parameter
            let params = {
                requestUrl: `${SDTM_MAPPING_URL}/SDM/studyMatrix/${ID}`,
                ID: ID,
                sendObject: JSON.stringify(sendObject)
            };

            // back-end 데이터 처리 요청
            netWorkAgent.current.request(command, params);
        }
    }, [ID, lock, originDataInfo]);

    /**
     *  @memberOf     SdmDesignMatrix
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;
            switch (command) {
                case COMMAND.DATA_LIST :
                    if (data.hasOwnProperty('data') && data.data !== null) {
                        // 원본데이터 저장
                        setOriginDataInfo(data.data);

                        let columnList = []; //컬럼 정보
                        let studyEpochsList = [];
                        let studyElementList = [];

                        // StudyElement dropdown 리스트
                        data.data.studyElements.forEach((element) => {
                            studyElementList.push({key: element.ID, value: element.elementName});
                        });
                        studyElementList.push({key: "", value: ""});

                        columnList = [
                            {
                                dataField: "armName",
                                headerText: "",
                                width: "10%",
                                style: "cell-left-align",
                                editable: false,
                                wrapText: true, // 워드랩(word-wrap)으로 출력
                                tooltip: {
                                    useNativeTip: true // HTML 내장 툴팁 기능 사용
                                },
                            },
                        ];

                        if (data.data.hasOwnProperty('period')) {
                            for (const period of data.data.period) {
                                if (data.data.hasOwnProperty('studyEpochs')) {
                                    //Study Epochs 리스트 생성
                                    studyEpochsList = [];
                                    for (const studyEpoch of data.data.studyEpochs) {
                                        for (let i = 0; i < studyEpoch.numberOfElements; i++) {
                                            if (period.ID === studyEpoch.periodID) {
                                                studyEpochsList.push({
                                                    dataField: studyEpoch.ID + "_" + [i + 1],
                                                    headerText: studyEpoch.epochName,
                                                    editable: lock !== 2,
                                                    labelFunction: function (rowIndex, columnIndex, value) {
                                                        let retStr = value;
                                                        for (let i = 0; i < studyElementList.length; i++) {
                                                            if (studyElementList[i]["key"] === value) {
                                                                retStr = studyElementList[i]["value"];
                                                                break;
                                                            }
                                                        }
                                                        return retStr;
                                                    },
                                                    editRenderer: {
                                                        type: "DropDownListRenderer",
                                                        list: studyElementList, //key-value Object 로 구성된 리스트
                                                        keyField: "key", // key 에 해당되는 필드명
                                                        valueField: "value", // value 에 해당되는 필드명
                                                        showEditorBtnOver: true
                                                    },
                                                    colSpan: studyEpoch.numberOfElements
                                                });
                                            }
                                        }
                                    }
                                }
                                if (studyEpochsList.length > 0) {
                                    columnList.push({
                                        dataField: period.ID,
                                        headerText: period.name,
                                        children: studyEpochsList
                                    });
                                } else {
                                    columnList.push({
                                        dataField: period.ID,
                                        headerText: period.name
                                    });
                                }
                            }
                        }
                        setColumnList(columnList);

                        let dataList = [];
                        if (data.data.studyMatrix.length === 0) {
                            data.data.arm.forEach(arm => {
                                let gridObj = {armName: arm.armName, armID: arm.ID};

                                data.data.studyEpochs.forEach((epoch) => {
                                    for (let i = 0; i < epoch.numberOfElements; i++) {
                                        gridObj = {...gridObj, [epoch.ID + "_" + [i + 1]]: ""};
                                    }
                                });
                                dataList.push(gridObj);
                            });
                        } else {
                            data.data.arm.forEach(arm => {
                                let gridObj = {armName: arm.armName, armID: arm.ID};
                                data.data.studyEpochs.forEach((epoch) => {
                                    let elementID = "";
                                    data.data.studyMatrix.forEach((matrix) => {
                                        for (let i = 0; i < epoch.numberOfElements; i++) {
                                            if (arm.ID === matrix.armID && epoch.ID + "_" + [i + 1] === matrix.epochID + "_" + matrix.order) {
                                                elementID = matrix.elementID;
                                                gridObj = {...gridObj, [epoch.ID + "_" + [i + 1]]: elementID};
                                            }
                                        }
                                    });
                                });
                                dataList.push(gridObj);
                            });
                        }
                        setGridList(dataList);
                        globalDataList = dataList;
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                default:
                    break;
            }
        }
    }, [getDataInfo, lock, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <AUIGrid
                id={"sdmDesignMatrix-grid"}
                columnList={columnList}
                dataList={gridList}
                onEdit={handleGridChange}
            />
            <div className="modal-button">
                <div className="d-flex justify-content-end p-10 border-top">
                    <button
                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(SdmDesignMatrix);

