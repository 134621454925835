import React, {useCallback, useEffect, useState, useRef} from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import AUIGrid from "../../../components/AUIGrid";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    SchemaPreviewModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Design list 의 상세화면 내 SoA Tab 의 Assessment 메뉴 SoA Preview Modal 컴포넌트
 */
const SchemaPreviewModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SchemaPreviewModal
     *  @type         {Object} props
     *  @property     {Function} onClose - 상위 handleClose 함수
     *  @property     {Object} dataInfo - 상위 dataInfo 정보(arm, epoch, ip, cell 데이터 포함)
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {onClose, dataInfo} = props;

    /**
     *  @memberOf     SchemaPreviewModal
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);


    /**
     *  @memberOf     SchemaPreviewModal
     *  @var          {Array} columnList
     *  @description  화면에 표시 될 그리드 컬럼 목록
     */
    const [columnList, setColumnList] = useState();

    /**
     *  @memberOf     SchemaPreviewModal
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 data 목록
     */
    const [dataList, setDataList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SchemaPreviewModal
     *  @function     handleExcel
     *  @description  Excel 버튼 클릭시 호출 되는 함수. Excel 다운로드.
     */
    const handleExcel = useCallback(() => {
        const params = {
            num: 3,
            sheetName: "Schema",
            fileName: "Schema"
        };

        auiGrid.current.request(params);
    }, []);

    /**
     *  @memberOf     SchemaPreviewModal
     *  @function     handleClose
     *  @description  SoA Preview 창 닫기
     */
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // props로 받은 dataInfo 가동
    useEffect(() => {
        let _columnList = [{
            headerText: "",
            dataField: "arm"
        }];
        let _dataList = [];

        //columnList 가공하는 과정
        dataInfo.epoch.forEach(epoch => {
            _columnList.push({
                headerText: epoch.name,
                dataField: epoch.ID,
                cellMerge: true
            });
        });

        //dataList 가동하는 과정
        dataInfo.arm.forEach(arm => {
            let data = {arm: arm.groupName};

            dataInfo.epoch.forEach(epoch => {
                let ipNames = []; //ip 있을때
                let emptyMerge = "" //ip 없고 병합 됐을 때
                // epoch name이 동일한 경우가 있으면 안됨(없다고 했음)
                let spaceVal = " "; //grid 에 merge 하려면 값이 동일해야하는데 빈 string ("")은 merge가 안되어 space 값으로 구분

                dataInfo.cell.forEach((cell, cellIdx) => {
                    //cell의 epochID와 epoch의 ID와 같을때
                    if (epoch.ID === cell.epochID) {
                        if (cell.armIDs.includes(arm.ID)) { //cell의 armIDs에 현재 arm의 ID가 포함되어있는지
                            let ipList = dataInfo.ip.filter(ip => ip.cellID === cell.ID);

                            //같은 epoch에 merge 된게 두개 이상 있을 경우 space 갯수 다르게 하여 처리(같으면 동일한 값 으로 인식하여 전체가 다 merge 됨)
                            //idx 갯수대로 space를 넣어버리면 엑셀에서 표기될때 띄어쓰기가 다 들어가서 나머지 값으로 번갈아 가면서 0, 1 넣어주면서 스페이스 되도록
                            for (let i = 0; i < cellIdx; i++) {
                                spaceVal = spaceVal + " ";
                            }

                            if (ipList.length === 0 && cell.armIDs.length > 1) { //cell에 해당하는 ip가 없고 cell의 armIDs가 2개 이상일 때
                                emptyMerge = spaceVal;
                            } else { //cell에 해당하는 ip가 있을 때
                                for (let ip of ipList) {
                                    ipNames.push(`${ip.name} ${ip.shape} ${ip.dose * ip.amount} ${ip.unit}`);
                                }
                            }
                        }
                    }
                });

                data[epoch.ID] = ipNames.length > 0 ? ipNames.join(", ") + spaceVal : emptyMerge; //ipNames 에 값이 있으면 ip 뿌려주고(ip가 같은 값이면 merge 되어서 나와서 스페이스 넣어줌), 없으면 빈칸
            });

            _dataList.push(data);
        });

        setColumnList(_columnList);
        setDataList(_dataList);
    }, [dataInfo]);

    /*################################################################################*/
    //## component view 영역
    // ## - JSX return
    /*################################################################################*/
    return (
        <>
            <Modal isOpen style={{maxWidth: "1500px", minHeight: '90vh'}}>
                <ModalHeader toggle={handleClose}>Schema Preview</ModalHeader>

                <ModalBody id="content">
                    <div className="d-flex justify-content-end m-b-10">
                        <button
                            className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                            onClick={handleExcel}>
                            Excel
                        </button>
                    </div>

                    <AUIGrid
                        ref={auiGrid}
                        id="schema_preview_grid"
                        columnList={columnList}
                        dataList={dataList}
                        isAutoGridHeight={true}/>
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(SchemaPreviewModal);
