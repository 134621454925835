import React, {useCallback, useState} from 'react';
import TextField from "../../../../components/TextField";
import Checkbox from "../../../../components/Checkbox";
import {copyObject, isDataExist} from "../../../../../common/commonFunction";
import produce from "immer";
import RadioButton from "../../../../components/RadioButton";
import cn from "classnames";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     DataSetsModalContent
 *  @constant     {Object} DATASETS_DEFINE
 *  @description  field 명 정의
 */
const DATASETS_DEFINE = {
    DOMAIN: 'domain',
    CLASS: 'class',
    REPEATING: 'repeating',
    NON_STANDARD: 'nonStandard',
    STANDARD: 'standard',
    NAME: 'name',
    STRUCTURE: 'structure',
    REFERENCE_DATA: 'referenceData',
    COMMENT: 'comment',
    DESCRIPTION: 'description',
    KEY_VARIABLE: 'keyVariable',
    SAS_DATASET_NAME: 'SASDatasetName',
    DEVELOPER_NOTE: 'developerNote'
};

/**
 *  @memberOf     DataSetsModalContent
 *  @constant     {Object} VALIDATION_FIELD
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    repeating: true,
    standard: true,
    name: true,
    structure: true,
    SASDatasetName: true
};

/**
 *  @memberOf     DataSetsModalContent
 *  @constant     {Array} YES_NO_OPTION
 *  @description  Radio Button에 사용되는 option 값
 */
const YES_NO_OPTION = [
    {
        text: "Yes",
        value: "Yes"
    },
    {
        text: "No",
        value: "No"
    }
];

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    DataSetsModalContent
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Datasets Edit 팝업 내 Domain 화면 컴포넌트
 */
const DataSetsModalContent = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DataSetsModalContent
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} domainName - List에서 선택 된 row의 domain 값
     *  @property     {String} className - List에서 선택 된 row의 class 값
     *  @property     {Object} dataObj - List에서 선택 된 row의 data 정보
     *  @property     {Boolean} isSUPP - List에서 선택 된 row의 SUPP 정보(SUPP 탭 활성화, 비활성화 여부)
     *  @property     {Function} onSave - 현재 Modal 저장 위한 함수
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {lock, domainName, className, dataObj, isSUPP, onSave, onClose} = props;

    /**
     *  @memberOf     DataSetsModalContent
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(dataObj);

    /**
     *  @memberOf     DataSetsModalContent
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     DataSetsModalContent
     *  @function     handleChange
     *  @param        {string} name   -  element name
     *  @param        {string} value  -  element value
     *  @description  html element 데이터 입력할 경우 실행
     */
    const handleChange = useCallback((name, value) => {
        let _dataInfo = copyObject(dataInfo);
        _dataInfo[name] = value;

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     DataSetsModalContent
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     DataSetsModalContent
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        for (let key of validateKeys) {
            if (dataInfo.nonStandard === 1) { //Non Standard가 선택 되어있다면
                validateData[DATASETS_DEFINE.STANDARD] = true; //Standard는 validation 통과
            } else {
                validateData[key] = isDataExist(dataInfo[key]);
            }
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, validation]);

    /**
     *  @memberOf     DataSetsModalContent
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2 && !isSUPP) {
            const valid = validateData(); // 데이터 validation
            if (!valid) {
                return;
            }

            if (onSave !== undefined) {
                onSave(dataInfo);
            }
        }
    }, [dataInfo, isSUPP, lock, onSave, validateData]);

    /**
     *  @memberOf     DataSetsModalContent
     *  @function     handleClose
     *  @description  Modal 창 닫는 함수
     */
    const handleClose = useCallback((num) => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {/*Domain*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Domain</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.DOMAIN}
                            currentValue={domainName}
                            disabled={true}/>
                    </div>
                </div>
            </div>

            {/*Class*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Class</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.CLASS}
                            currentValue={className}
                            disabled={true}/>
                    </div>
                </div>
            </div>

            {/*Repeating*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Repeating</span>
                    <span className="ml-3 text-danger">*</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <RadioButton
                            name={DATASETS_DEFINE.REPEATING}
                            onChange={handleChange}
                            currentValue={dataInfo[DATASETS_DEFINE.REPEATING]}
                            dataList={YES_NO_OPTION}
                            onFocus={handleFocus}
                            validation={validation[DATASETS_DEFINE.REPEATING]}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Non Standard*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Non Standard</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <Checkbox
                            name={DATASETS_DEFINE.NON_STANDARD}
                            currentValue={dataInfo[DATASETS_DEFINE.NON_STANDARD]}
                            onChange={handleChange}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Standard*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Standard</span>
                    <span className="ml-3 text-danger">*</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.STANDARD}
                            currentValue={dataInfo[DATASETS_DEFINE.STANDARD]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            validation={validation[DATASETS_DEFINE.STANDARD]}
                            disabled={lock === 2 || dataInfo.nonStandard === 1 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Name*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Name</span>
                    <span className="ml-3 text-danger">*</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.NAME}
                            currentValue={dataInfo[DATASETS_DEFINE.NAME]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            validation={validation[DATASETS_DEFINE.NAME]}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Structure*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Structure</span>
                    <span className="ml-3 text-danger">*</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.STRUCTURE}
                            currentValue={dataInfo[DATASETS_DEFINE.STRUCTURE]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            validation={validation[DATASETS_DEFINE.STRUCTURE]}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Reference Data*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Reference Data</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <RadioButton
                            name={DATASETS_DEFINE.REFERENCE_DATA}
                            onChange={handleChange}
                            currentValue={dataInfo[DATASETS_DEFINE.REFERENCE_DATA]}
                            dataList={YES_NO_OPTION}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Comment*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Comment</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.COMMENT}
                            currentValue={dataInfo[DATASETS_DEFINE.COMMENT]}
                            onChange={handleChange}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Description*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Description</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.DESCRIPTION}
                            currentValue={dataInfo[DATASETS_DEFINE.DESCRIPTION]}
                            onChange={handleChange}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Key Variable*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Key Variable</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.KEY_VARIABLE}
                            currentValue={dataInfo[DATASETS_DEFINE.KEY_VARIABLE]}
                            onChange={handleChange}
                            disabled={false}/>
                    </div>
                </div>
            </div>

            {/*SAS Dataset Name*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>SAS Dataset Name</span>
                    <span className="ml-3 text-danger">*</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.SAS_DATASET_NAME}
                            currentValue={dataInfo[DATASETS_DEFINE.SAS_DATASET_NAME]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            validation={validation[DATASETS_DEFINE.SAS_DATASET_NAME]}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            {/*Developer Note*/}
            <div className="row align-items-center m-b-20">
                <label className="col-md-3">
                    <span>Developer Note</span>
                </label>

                <div className="col-md-9">
                    <div className="w-75">
                        <TextField
                            name={DATASETS_DEFINE.DEVELOPER_NOTE}
                            currentValue={dataInfo[DATASETS_DEFINE.DEVELOPER_NOTE]}
                            onChange={handleChange}
                            disabled={lock === 2 || isSUPP}/>
                    </div>
                </div>
            </div>

            <div className={'d-flex justify-content-end'}>
                <button
                    className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                    onClick={handleClose}>
                    Close
                </button>

                <button
                    className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': isSUPP})}
                    onClick={handleSave}>
                    Save
                </button>
            </div>
        </>
    );
};

export default React.memo(DataSetsModalContent);