import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import routes from 'routes/page-route.jsx';
import menus from '../sidebar/menu';
import {PageSettings} from 'routes/page-settings.js';
import ContentHeader from "./contentHeader";
import {SESSION_INFO} from "constant/CommonConstant";

function setTitle(path, routeArray) {

    var pageTitle;
    for (var i = 0; i < routeArray.length; i++) {
        if (routeArray[i].path === path) {
            pageTitle = 'imtrial | ' + routeArray[i].name;
        }
    }
    document.title = (pageTitle) ? pageTitle : 'imtrial';
}

class Content extends React.Component {

    componentDidMount() {
        setTitle(this.props.history.location.pathname, routes);
    }

    componentWillMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes);
        });
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({pageContentFullWidth, pageContentClass, pageContentInverseMode, sessionUserData}) => (
                    <div
                        className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass + " clearfix"}>
                        <ContentHeader routes={routes} menus={menus}/>

                        <React.Fragment>
                            <Switch>
                                {routes.map((route, index) => {
                                    return <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.role && route.role !== sessionUserData[SESSION_INFO.ROLE] ? // master 혹은 admin
                                            () => {
                                                return <Redirect to="/study/study" {...this.props.children}/>
                                            } : route.component}
                                    />
                                })}

                                {/*not found page 일때*/}
                                <Route component={() => {
                                    return <Redirect to="/study/study" {...this.props.children}/>
                                }}/>
                            </Switch>
                        </React.Fragment>
                        {/*</React.Fragment>*/}
                    </div>
                )
                }
            </PageSettings.Consumer>
        )
    }
}

export default withRouter(Content);
