/**
 *  @constant SUMMARY
 *  @type {object}
 *  @description  summary 에서 사용되는 데이터 필드 명
 */
export const SUMMARY = {

    STUDYID: 'StudyID',
    STUDYNAME: 'StudyName',
    TITLE: 'TITLE',

    // General information
    SPONSOR: 'SPONSOR',
    PHASE: 'TPHASE',
    INDICATION: 'INDIC',
    MULTICENTER: 'Multicenter',
    COUNTRIES: 'FCNTRY',

    // TrialObjective
    TRIALPURPOSE: 'TrialPurpose',
    PRIMARYOBJECTIVE: 'OBJPRIM',
    OTHEROBJECTIVE: 'OBJSEC',

    // Trial Design
    STUDYDESCRIPTION: 'StudyDesign',
    INTERVENTIONS: 'TRT',
    RANDOMIZED: 'RANDOM',
    BLINDING: 'TBLIND',
    CONTROL: 'TCNTRL',
    CONFIGURATION: 'STYPE',
    DURATION: 'LENGTH',
    PRIMARYENDPOINT: 'EndPoint',
    OTHERENDPOINT: 'OUTMSSEC',

    // Selection of Subject
    AGEMIN: 'AGEMIN',
    AGEMAX: 'AGEMAX',
    USEHEALTHY: 'HTLSUBJI',
    GENDER: 'SEXPOP',
    INCLUSIONCRITERIA: 'InclusionCriteria',
    EXCLUSIONCRITERIA: 'ExclusionCriteria',

    // Statistics
    SUBJECTNUMBER: 'PLANSUB',
    METHODDESCRIPTIONS: 'StatisticalMethod',
    PROPOSEDSTATISTICA: 'ProposedStatisticalModel',
    INTERIMANALYSES: 'InterimAnalyses'
};

/**
 *  @constant TEMPLATE
 *  @type {object}
 *  @description  master template 에서 사용되는 element name
 */
export const TEMPLATE = {
    NAME: 'Name',
    TA: 'TA',
    PHASE: 'Phase',
    INDICATION: 'Indication',
    ENDPOINT: 'EndPoint',
    PARTNER_TYPE: 'PartnerType',
    DESCRIPTION: 'Description',
    CDASH_Version: 'CDASH_Version',

    ACTIVITY: 'Activity',
    STUDY_EVENT: 'StudyEvent',
    ARM: 'Arm',
    EPOCH: 'Epoch',
};

/**
 *  @constant  ELEMENT_TYPE
 *  @type {object}
 *  @description  element type(back-end 호출 시 사용)
 */
export const ELEMENT_TYPE = {
    ALL: 'all',
    BASIC: 'basic',
    MAIN: 'main',
    VERSION: 'version',
    DOMAIN: 'domain',
    ACTIVITY: 'activity',
    OBJECT_GROUPS: 'objectgroup',
    EPOCH: 'epoch',
    ARM: 'arm',
    CELL: 'cell',
    SEGMENT: 'segment',
    SEGMENT_ACTIVITY: 'segment-activity',
    STUDY_EVENT: 'studyevent',
    SOA: 'soa',
    STRUCTURE: 'structure',
    PRM: 'prm',
    CRF: 'crf',
    CODE_LIST: 'codelist',
};


/**
 *  @constant  SOURCE_TYPE
 *  @type {object}
 *  @description  prm structure 화면을 표시할 때 필요한 데이터 추철(template, my prm, my model, model library, popup view)
 */
export const SOURCE_TYPE = {
    TEMPLATE: 'TEMPLATE',
    MY_PRM: 'MY_PRM',
    MY_MODEL: 'MY_MODEL',
    MODEL_LIBRARY: 'MODEL_LIBRARY',
    POPUP_VIEW: 'POPUP_VIEW',
};


/**
 *  @constant  ELEMENT_NAME
 *  @type {object}
 *  @description  prm structure 에 사용하는 element name
 */
export const ELEMENT_NAME = {
    CDASH_CT_Version: 'CDASH_CT_Version',
    CDASH_IG_Version: 'CDASH_IG_Version',
    SDTM_CT_Version: 'SDTM_CT_Version',

    SUMMARY: 'Summary',

    CDASH_VERSION: 'CDASH_Version',
    DOMAINS: 'Domains',
    ACTIVITY: 'Activity',
    OBJECT_GROUP: 'ObjectGroup',
    OBJECT_GROUP_OIDS: 'ObjectGroupOIDs',
    CODE_LIST: 'CodeList',
    TIME_POINT: 'TimePoint',

    STRUCTURE: 'Structure',
    EPOCH: 'Epoch',
    STUDYEVENT: 'StudyEvent',
    ARM: 'Arm',
    CELL: 'Cell',
    SEGMENT: 'Segment',

    USER_DEFINE: 'UserDefine',  // 사용자 지정
};

/**
 *  @constant  USER_DEFINE_ACTION
 *  @type {object}
 *  @description  사용자 정의 액션
 */
export const USER_DEFINE_ACTION = {
    TIME_POINT_SETTING_OK: 'TIME_POINT_SETTING_OK',
    TIME_POINT_SETTING_NO: 'TIME_POINT_SETTING_NO',
};


/**
 *  @constant  BASIC_INFORMATION_FIELD
 *  @type {object}
 *  @description  study basic INFORMATION 에 사용되는 name
 */
export const BASIC_INFORMATION_FIELD = {
    STUDY_ID: 'studyOID',
    MANAGEMENT_NO: 'managementNo',
    TITLE: 'briefStudyTitle',
    BRIEF_STUDY_TITLE_KR: 'briefStudyTitleKR',
    BRIEF_STUDY_TITLE_EN: 'briefStudyTitleEN',
    NO: 'protocolNo',
    NAME: 'studyTitle',
    STUDY_TITLE_KR: 'studyTitleKR',
    STUDY_TITLE_EN: 'studyTitleEN',
    PHASE: 'phase',
    edcType: 'edcType',
    INDICATION: 'indication',
    HEALTHY: 'healthyIndication',
    TA: 'ta',
    SPONSOR: 'sponsor',
    COUNTRY: 'country',
    LANGUAGE: 'language',
    // PRIMARY_ENDPOINT  : 'endpoint'      ,
    // PARTNER           : 'partner'       ,
    // PARTNER_CODE      : 'partnerCode'   ,
    // LOCK              : 'lock'          ,
    // LOCK_TIME         : 'lockTime'      ,
    // CREATED_TIME      : 'createdTime'   ,
    STATE: 'state'
};


/**
 *  @constant  SERVICE_NAME
 *  @type {object}
 *  @description  서비스 이름
 */
export const SERVICE_NAME = {
    PRM: 'PRM',
    CRF: 'CRF',
    SDTM: 'SDTM',
};

/**
 *  @constant  PRM_MODEL_TYPE
 *  @type {object}
 *  @description  prm model 의 type
 */
export const PRM_MODEL_TYPE = {
    MY_PRM: 'myprm',
    MY_MODEL: 'mymodel',
    MODEL_LIBRARY: 'library',
};

/**
 *  @constant  TARGET_INFO
 *  @type {object}
 *  @description  target 정보
 */
export const TARGET_INFO = {
    TARGET: 'Target',
    PRM: 'prm',
    MODEL: 'model',
};

/**
 *  @constant  TAB_PRM
 *  @type {object}
 *  @description  prm tab 정보
 */
export const TAB_PRM = {
    SUMMARY: 'Summary',   // SUMMARY TAB
    STRUCTURE: 'Structure',   // STRUCTURE TAB
    HISTORY: 'Version History',   // HISTORY TAB
};

/**
 *  @constant  YES_OR_NO
 *  @type {object}
 *  @description  yes or no 정보
 */
export const YES_OR_NO = {
    YES: 'Yes',
    NO: 'No',
};


/**
 *  @constant  Y_OR_N
 *  @type {object}
 *  @description  Y or N 정보
 */
export const Y_OR_N = {
    Y: 'Y',
    N: 'N',
};

/**
 *  @constant  NONE
 *  @type {string}
 *  @description  none 값을 갖는 상수
 */
export const NONE = 'None'

/**
 *  @constant SELECT_DEFAULT_CAPTION
 *  @type {string}
 *  @description  select box default caption
 */
export const SELECT_DEFAULT_CAPTION = '－－－－【 Select an item 】－－－－';

/**
 *  @constant MASTER_MENU_NAME
 *  @type {object}
 *  @description  MASTER MENU 이름 상수
 */
export const MASTER_MENU_NAME = {
    SERVICE: 'Service',
    PARTNER: 'Partner',
    COUNTRY: 'Country',
    USER: 'user',
    USER_GROUP: 'user Group',
    ROLE: 'Role',
    TRAINING: 'New Training',
    ALARM: 'Alarm',
    NOTICE: 'Notice',
    HELP: 'Help',
    QUESTION: 'Question',
    REQUEST_LIBRARY: 'Request Library',

    LOG: 'Log',
    SESSION: 'Session',

    OBJECT: 'Object',
    OBJECT_GROUP: 'Object Group',
    ACTIVITY: 'Activity',

    TA: 'Therapeutic Area',
    ASSESSMENT: 'Assessment',
    SDTM_FUNCTION: 'SDTM Function',
    IG: 'CDASH IG',
    VARIABLE: 'Variable',
    BC_MAP: 'New BC Map',
    TERMINOLOGY: "Terminology",
    CODELIST: "CodeList",
    CUSTOM_CODELIST: "Custom CodeList",
    INDICATION: "Indication",
    OBJECTIVE: "Objective",
    ENDPOINT: "Endpoint",
    CRITERIA: "Criteria",
    USER_GUIDE: "User Guide",

};

/**
 *  @constant DESIGN_MENU_NAME
 *  @type {object}
 *  @description  DESIGN MENU 이름 상수
 */
export const DESIGN_MENU_NAME = {
    DESIGN: "New Study Design",
};


/**
 *  @constant ADMIN_MENU_NAME
 *  @type {object}
 *  @description  ADMIN MENU 이름 상수
 */
export const ADMIN_MENU_NAME = {
    USER: 'User',
    SPONSOR: 'Sponsor'
};

/**
 *  @constant ALERT_TYPE
 *  @type {object}
 *  @description alert type
 */
export const ALERT_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
};

/**
 *  @constant NEXT_BUTTON
 *  @type {object}
 *  @description 다음 버튼을 나타내는 값
 */
export const NEXT_BUTTON = {
    NONE: 'NONE',
    SAVE: 'SAVE',
    NEXT: 'NEXT',
};

/**
 *  @constant VALIDATION_EVENT
 *  @type {string}
 *  @description validation check event
 */
export const VALIDATION_EVENT = 'VALID_EVENT';

/**
 *  @constant TAB_CRF
 *  @type {object}
 *  @description crf 에서 사용한 tab 정보
 */
export const TAB_CRF = {
    FORM: 'Form',
    SOF: 'SOF',
    VERSION_HISTORY: 'Version History'
};


/**
 *  @constant LANGUAGE_INDEX
 *  @type {number}
 *  @description 언어 필드에 선택 INDEX
 */
export const LANGUAGE_INDEX = 0;


/**
 *  @constant LANGUAGE_FIELD
 *  @type {object}
 *  @description 언어 필드에 사용된 필드 정보
 */
export const LANGUAGE_FIELD = {
    PROTOCOL_TITLE: 'protocolTitle',
    NAME: 'name',
    LANGUAGE: 'language',
    DESCRIPTION: 'description',
    TEXT: 'text',
};


export const TERMS = {

    NAME: 'name',
    TITLE: 'title',
    TEXT: 'text',
    VALUE: 'value',
    OBJECT: 'object',
    OPTION: 'option',

    TA: 'ta',
    PHASE: 'phase',
    SPONSOR: 'sponsor',

    INCLUSION: 'inclusion',
    EXCLUSION: 'exclusion',

};
