import React, {useCallback} from "react";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFTableOfContents
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Table Of Contents(목차) 컴포넌트
 */
const ACRFTableOfContents = (props) => {
    /**
     *  @memberOf     ACRFTableOfContents
     *  @type         {Object} props
     *  @property     {String} formContentsList - Form 목차를 만들기 위한 상위에서 받아온 Form 이름 목록
     *  @property     {String} visitContentsList - Visit 목차를 만들기 위한 상위에서 받아온 Visit 이름 목록
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {formContentsList, visitContentsList, display} = props;

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFTableOfContents
     *  @function     makeFormsContents
     *  @return       {Element} Table of Contents render
     *  @description  ACRF의 Forms Table of Contents(목차) 그려주는 함수
     */
    const makeFormsContents = useCallback(() => {
        if (formContentsList.length > 0) {
            return formContentsList.map((form, idx) => (
                <div
                    key={idx}
                    style={{margin: "10px"}}>
                    <div style={{marginLeft: "10px", fontSize: "12pt", fontFamily: "Arial, sans-serif"}}>
                        {form.name}
                    </div>

                    {form.children.map(studyEvent => (
                        <a
                            key={studyEvent.name + idx}
                            href={`#${form.name}`.trim()}
                            style={{
                                display: "flex",
                                textDecoration: "none",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "9pt",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            {studyEvent.name}
                        </a>
                    ))}
                </div>
            ));
        }
    }, [formContentsList]);

    /**
     *  @memberOf     ACRFTableOfContents
     *  @function     makeVisitsContents
     *  @return       {Element} Table of Contents render
     *  @description  ACRF의 Visits Table of Contents(목차) 그려주는 함수
     */
    const makeVisitsContents = useCallback(() => {
        if (visitContentsList.length > 0) {
            return visitContentsList.map((studyEvent, idx) => (
                <div
                    key={idx}
                    style={{margin: "10px"}}>
                    <div style={{marginLeft: "10px", fontSize: "12pt", fontFamily: "Arial, sans-serif"}}>
                        {studyEvent.name}
                    </div>

                    {studyEvent.children.map(form => (
                        <a
                            key={form.name + idx}
                            href={`#${form.name}`.trim()}
                            style={{
                                display: "flex",
                                textDecoration: "none",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "9pt",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            {form.name}
                        </a>
                    ))}
                </div>
            ));
        }
    }, [visitContentsList]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            style={{
                display: `${display || 'none'}`,
                pageBreakBefore: "always"
            }}>
            <div
                style={{
                    fontSize: "12pt",
                    fontWeight: "800",
                    textAlign: "center"
                }}>
                Table of Contents
            </div>

            {/*Form 기준 목차*/}
            <div
                style={{
                    fontSize: "11pt",
                    fontWeight: "800",
                    margin: "10px 0",
                    fontFamily: "Arial, sans-serif"
                }}>
                Forms

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "2px solid #000000",
                        marginTop: "10px"
                    }}>
                    {makeFormsContents()}
                </div>
            </div>

            {/*Visit 기준 목차*/}
            <div
                style={{
                    fontSize: "11pt",
                    fontWeight: "800",
                    margin: "10px 0",
                    pageBreakBefore: "always",
                    fontFamily: "Arial, sans-serif"
                }}>
                Visits

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "2px solid #000000",
                        marginTop: "10px"
                    }}>
                    {makeVisitsContents()}
                </div>
            </div>
        </div>
    );
};

export default React.memo(ACRFTableOfContents);