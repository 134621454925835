import React, {useCallback, useEffect, useRef, useState} from "react";
import NetworkLayout from "../../../../../common/NetworkLayout";
import PerfectScrollbar from "react-perfect-scrollbar";
import AUIGrid from "../../../../components/AUIGrid";
import cn from "classnames";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import {AXIOS_GET, AXIOS_PUT, copyObject, getSessionState} from "../../../../../common/commonFunction";
import useToast from "../../../../../hooks/useToast";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import {DownOutlined} from "@ant-design/icons";
import {Tree} from "antd";
import Checkbox from "../../../../components/Checkbox";
import deleteIcon from "../../../../../assets/icons/close.svg";
import {showConfirmAlert} from "../../../../components/alertModal";
import {FILL_REQUIRED_FIELD} from "../../../../../constant/ConstantMsg";
import TextSearch from "../../../../components/TextSearch";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     CodeLists
 *  @constant     {Object} DATA_TYPE_LIST
 *  @description  data type 항목에 표시할 카테고리 옵션 리스트
 */
const DATA_TYPE_LIST = [
    "Text",
    "Integer",
    "Float",
    "Datetime"
];

/**
 *  @memberOf     CodeLists
 *  @constant     {Object} EXTERNAL_DATA_TYPE_LIST
 *  @description  External codeList data type 항목에 표시할 카테고리 옵션 리스트
 */
const EXTERNAL_DATA_TYPE_LIST = [
    "Text",
    "Integer",
    "Float"
];

/**
 *  @memberOf     CodeLists
 *  @constant     {Object} MedDRA_VERSION_LIST
 *  @description  External codeList MedDRA version 항목에 표시할 카테고리 옵션 리스트
 */
const MedDRA_VERSION_LIST = [
    {
        text: "25.1",
        value: "25.1"
    },
    {
        text: "25",
        value: "25"
    },
    {
        text: "24.1",
        value: "24.1"
    },
    {
        text: "24",
        value: "24"
    },
    {
        text: "23.1",
        value: "23.1"
    },
    {
        text: "23",
        value: "23"
    },
    {
        text: "Other",
        value: "Other"
    },
];

/**
 *  @memberOf     CodeLists
 *  @constant     {Object} WHODrug_VERSION_LIST
 *  @description External codeList WHODrug version 항목에 표시할 카테고리 옵션 리스트
 */
const WHODrug_VERSION_LIST = [
    {
        text: "202209",
        value: "202209"
    },
    {
        text: "202203",
        value: "202203"
    },
    {
        text: "202109",
        value: "202109"
    },
    {
        text: "202103",
        value: "202103"
    },
    {
        text: "202009",
        value: "202009"
    },
    {
        text: "202003",
        value: "202003"
    },
    {
        text: "Other",
        value: "Others"
    },
];

/**
 *  @memberOf     CodeLists
 *  @constant     {Object} DICTIONAYR_LIST
 *  @description  External codeList Dictionary 항목에 표시할 카테고리 옵션 리스트
 */
const DICTIONAYR_LIST = [{
    text: "LOINC",
    value: "LOINC"
}, {
    text: "Other",
    value: "Other"
},
];

/**
 *  @memberOf     CodeLists
 *  @constant     {Object} DICTIONAYR_LIST
 *  @description  External codeList Dictionary 항목에 표시할 카테고리 옵션 리스트
 */
const DICTIONAYR_LOINC_LIST = [
    {
        text: "2.73 [2022-08-08]",
        value: "2.73 [2022-08-08]"
    }, {
        text: "2.72 [2022-02-16]",
        value: "2.72 [2022-02-16]"
    }, {
        text: "2.71 [2021-08-23]",
        value: "2.71 [2021-08-23]"
    }, {
        text: "2.70 [2021-06-10]",
        value: "2.70 [2021-06-10]"
    }, {
        text: "Other",
        value: "Other"
    }
];

/**
 *  @memberOf     CodeLists
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/codelist/${ID}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/codelist/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @see          antd(https://ant.design/components/tree/)
 *  @component    CodeLists
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 CodeLists Tab 컴포넌트
 */
const CodeLists = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     CodeLists
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     CodeLists
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     CodeLists
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     CodeLists
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} treeDataList
     *  @description  왼쪽 Tree codeList 메뉴에 표시될 CodeList ID 목록
     */
    const [treeDataList, setTreeDataList] = useState();

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} treeDataList
     *  @description  왼쪽 Tree 메뉴에 표시될 external externalCodeList ID 목록
     */
    const [externalTreeList, setExternalTreeList] = useState();

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} currentID
     *  @description  Tree 메뉴에서 현재 선택 된 ID
     */
    const [currentID, setCurrentID] = useState();

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 선택된 codeList
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} selectExList
     *  @description  화면에 표시 될 선택된 external codeList
     */
    const [selectExList, setSelectExList] = useState([]);

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} selectExList
     *  @description  화면에 표시 될 선택된 external codeList
     */
    const [addTypeExList, setAddTypeExList] = useState([]);

    /**
     *  @memberOf     CodeLists
     *  @var          {Array} selectExList
     *  @description  화면에 표시 될 선택된 codeList
     */
    const [addTypeCoList, setAddTypeCoList] = useState([]);

    /**
     *  @memberOf     CodeLists
     *  @var          {Boolean} isUsed
     *  @description  현재 선택된 CodeList 사용 여부
     */
    const [isUsed, setIsUsed] = useState(false);

    /**
     *  @memberOf     CodeLists
     *  @var          {Boolean} currentType
     *  @description  현재 선택된 External CodeList / CodeList 여부
     */
    const [currentType, setCurrentType] = useState("normal");

    const [filteredTreeDataList, setFilteredTreeDataList] = useState('');

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CodeLists
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID
        };
        params.requestUrl = SDTM_MAPPING_URL;

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     CodeLists
     *  @function     handleSelect
     *  @param        {Object | Array} ID - Tree 메뉴 에서 선택된 ID
     *  @param        {Object} e - 선택 된 메뉴의 정보
     *  @description  Tree 메뉴에서 선택하면 호출되는 함수
     */
    const handleSelect = useCallback((ID, e) => {
        //전체 코드리스트 정보의 ID Tree 메뉴에서 선택한 ID와 같은 것만 filter
        let codeDataList = addTypeCoList.filter(code => code.terminologyID === e.node.key);
        let exDataList = addTypeExList.filter(code => code.externalID === e.node.key);

        setCurrentType(e.node.codeListType);// Tree메뉴에서 선택한 값이 codeList 메뉴인지 External 메뉴인지
        setSelectExList(exDataList);
        setDataList(codeDataList);
        setCurrentID(e.node.key);

        setIsUsed(codeDataList.length > 0 ? codeDataList[0].used !== 1 : false);
        setIsUsed(exDataList.length > 0 ? exDataList[0].used !== 1 : false);
    }, [addTypeCoList, addTypeExList]);

    /**
     *  @memberOf     CodeLists
     *  @function     renderTreeMenu
     *  @return       {Element} 왼쪽 Tree 메뉴 render
     *  @description  Tree 메뉴 codeList 그려주는 함수
     */
    const renderTreeMenu = useCallback(() => {
        return (
            <>
                {currentID !== undefined && treeDataList !== undefined && (
                    <Tree
                        showIcon
                        className="bg-light"
                        defaultSelectedKeys={[currentID]}
                        selectedKeys={[currentID]}
                        switcherIcon={<DownOutlined/>}
                        treeData={filteredTreeDataList}
                        onSelect={handleSelect}/>
                )}
            </>
        );
    }, [currentID, handleSelect, filteredTreeDataList]);

    /**
     *  @memberOf     CodeLists
     *  @function     renderExternalTreeMenu
     *  @return       {Element} 왼쪽 Tree 메뉴 render
     *  @description  Tree 메뉴 external codeList codeList 그려주는 함수
     */
    const renderExternalTreeMenu = useCallback(() => {
        return (
            <>
                {currentID !== undefined && externalTreeList !== undefined && (
                    <Tree
                        showIcon
                        className="bg-light"
                        defaultSelectedKeys={[currentID]}
                        selectedKeys={[currentID]}
                        switcherIcon={<DownOutlined/>}
                        treeData={externalTreeList}
                        onSelect={handleSelect}/>
                )}
            </>
        );
    }, [currentID, externalTreeList, handleSelect]);

    /**
     *  @memberOf     CodeLists
     *  @function     addCodeListID
     *  @description  Tree 메뉴의 codeList 리스트 추가하는 함수
     */
    const addCodeListID = useCallback(() => {
        let _treeDataList = [...treeDataList];
        let _treeData = {
            key: "NEW" + (_treeDataList.length + 1),
            title: "NEW" + (_treeDataList.length + 1),
            icon: <i className={"fas fa-genderless"}/>,
            codeListType: "normal"
        };
        let _dataList = [{
            terminologyID: _treeData.key,
            name: "",
            NCICodelistCode: "",
            dataType: "",
            standardVersion: "",
            codeType: "",
            codeValue: "",
            NCITermCode: "",
            decodedValue: "",
            comment: "",
            used: 1,
            order: 1
        }];

        _treeDataList.push(_treeData);

        setTreeDataList(_treeDataList);
        setCurrentID(_treeData.key);
        setCurrentType(_treeData.codeListType);
        setDataList(_dataList);
    }, [treeDataList]);

    /**
     *  @memberOf     CodeLists
     *  @function     addExCodeListID
     *  @description  Tree 메뉴의 external codeList 리스트 추가하는 함수
     */
    const addExCodeListID = useCallback(() => {
        let _externalTreeList = [...externalTreeList];
        let _externalTreeData = {
            key: "NEW" + (_externalTreeList.length + 1),
            title: "NEW" + (_externalTreeList.length + 1),
            icon: <i className={"fas fa-genderless"}
            />,
            codeListType: "external"
        };

        let _externalDataList = [{
            ID: "",
            externalID: _externalTreeData.key,
            name: "",
            dataType: "",
            dictionary: "",
            version: "",
            used: 1,
        }];

        _externalTreeList.push(_externalTreeData);
        setExternalTreeList(_externalTreeList);
        setCurrentID(_externalTreeData.key);
        setCurrentType(_externalTreeData.codeListType);
        setSelectExList(_externalDataList);

    }, [externalTreeList]);

    /**
     *  @memberOf     CodeLists
     *  @function     handleChange
     *  @description  annotated CRF 체크 변경시 실행되는 함수
     */
    const handleChange = useCallback(() => {
        if (currentType === "normal") {
            let _dataList = [...dataList];

            //체크 값에 따라 used의 값을 전체 데이터에서 다 바꿔주는 작업
            _dataList.forEach(data => {
                data.used = isUsed ? 1 : 0; //1이면 체크 x, 0이면 체크
            });

            setIsUsed(!isUsed);
        } else if (currentType === "external") {
            let _selectExList = [...selectExList];
            _selectExList.forEach((data) => {
                data.used = isUsed ? 1 : 0;
            });
            setIsUsed(!isUsed);
        }
    }, [currentType, dataList, isUsed, selectExList]);

    /**
     *  @memberOf     CodeLists
     *  @function     handleAddRow
     *  @description  Add 버튼 클릭시 호출 되는 함수
     */
    const handleAddRow = useCallback(() => {
        if (lock !== 2) {
            // let _dataList = window.AUIGrid.getOrgGridData('#grid_wrap'); //grid 데이터 가지고 옴
            let _dataList = [...dataList];

            _dataList.push({
                terminologyID: _dataList.length > 0 && _dataList[_dataList.length - 1].terminologyID !== "" ? _dataList[_dataList.length - 1].terminologyID : "", //data 목록이 있고, data 목록의 마지막의 terminologyID가 비어있지 않다면 data 목록의 마지막 terminologyID를 사용 아니면 빈칸
                name: _dataList.length > 0 && _dataList[_dataList.length - 1].name !== "" ? _dataList[_dataList.length - 1].name : "", //data 목록이 있고, data 목록의 마지막의 name이 비어있지 않다면 data 목록의 마지막 name을 사용 아니면 빈칸
                NCICodelistCode: _dataList.length > 0 && _dataList[_dataList.length - 1].NCICodelistCode !== "" ? _dataList[_dataList.length - 1].NCICodelistCode : "", //data 목록이 있고, data 목록의 마지막의 NCICodelistCode가 비어있지 않다면 data 목록의 마지막 NCICodelistCode를 사용 아니면 빈칸
                dataType: _dataList.length > 0 && _dataList[_dataList.length - 1].dataType !== "" ? _dataList[_dataList.length - 1].dataType : "", //data 목록이 있고, data 목록의 마지막의 dataType이 비어있지 않다면 data 목록의 마지막 dataType을 사용 아니면 빈칸
                standardVersion: "", //standardVersion 빈칸
                codeType: "", //codeType 빈칸
                codeValue: "", //codeValue 빈칸
                NCITermCode: "", //NCITermCode 빈칸
                decodedValue: "", //decodedValue 빈칸
                comment: "", //comment 빈칸
                used: isUsed ? 0 : 1, //used는 Don't use this CodeList의 체크박스를 보고 값 저장(0: 체크, 1: 체크 x)
                order: _dataList.length + 1 //order는 길이 + 1한 값
            }); //추가 시킬 데이터 array에 추가

            setDataList(_dataList);
        }
    }, [dataList, isUsed, lock]);

    /**
     *  @memberOf     CodeLists
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        if (currentType === "normal") {
            let _dataList = dataList; //grid 데이터 가지고 옴

            //컬럼이 Standard Version, Code Value, NCI Term Code, Decoded Value, Comment 일때
            if (dataField === "codeValue" || dataField === "NCITermCode" ||
                dataField === "decodedValue" || dataField === "comment" ||
                dataField === "standardVersion") {
                _dataList[rowIndex][dataField] = value; //해당 index 찾아서 값 변경
            } else { // 나머지 컬럼
                //모든 값 같이 변경 되도록
                _dataList.forEach(data => {
                    data[dataField] = value;
                });
            }
            setDataList(_dataList);
        } else if ((currentType === "external")) {
            let _selectExList = copyObject(selectExList);
            _selectExList[rowIndex][dataField] = value;
            setSelectExList(_selectExList);
        }
    }, [currentType, dataList, selectExList]);

    /**
     *  @memberOf     CodeLists
     *  @function     handleDrag
     *  @param        {Array} list - ItemGroup List에서 순서 변경 된 Data
     *  @description  Grid에서 Drag시 호출 되는 함수
     */
    const handleDrag = useCallback((list) => {
        let _dataList = [...list];

        //data의 order 다시 설정
        _dataList.forEach((data, idx) => {
            data.order = idx + 1;
        });

        setDataList(_dataList);
    }, []);

    /**
     *  @memberOf     CodeLists
     *  @function     handleDeleteRow
     *  @param        {Object} event - 선택 된 row의 정보
     *  @description  X 버튼 클릭시 호출 되는 함수.
     */
    const handleDeleteRow = useCallback((event) => {
        if (lock !== 2) {
            let _dataList = [...dataList];
            // let _dataList = window.AUIGrid.getOrgGridData('#grid_wrap'); //grid 데이터 가지고 옴

            _dataList.splice(event.rowIndex, 1); //선택 된 index 삭제

            setDataList(_dataList);
        }
    }, [lock, dataList]);

    /**
     *  @memberOf     CodeLists
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     CodeLists
     *  @function     validateData
     *  @return       {Boolean} validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        return window.AUIGrid.validateGridData(`#external_codeList`, ["externalID", "name", "dataType", "dictionary", "version"], FILL_REQUIRED_FIELD);
    }, []);

    /**
     *  @memberOf     CodeLists
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            if (currentType === "normal") {
                if (dataList.length === 0) { //data 목록의 길이가 0개면 경고창
                    showConfirmAlert("Please enter at least one line.");
                } else {
                    const command = COMMAND.DATA_UPDATE;
                    let _dataList = [...dataList];

                    //data 목록에서 standardVersion의 입력된 값을 확인하여 codeType에 값 넣어주기
                    _dataList.forEach(data => {
                        if (data.standardVersion === "") { //standardVersion이 비어있으면
                            data.codeType = "CUSTOM"; //codeType에 CUSTOM 저장
                        } else { //standardVersion이 비어있지 않다면
                            data.codeType = "STANDARD"; //codeType에 STANDARD 저장
                        }
                    });

                    let sendObject = {
                        element: currentID,
                        type: currentType,
                        list: _dataList
                    };
                    const params = {
                        requestUrl: SDTM_MAPPING_URL,
                        ID: ID,
                        sendObject: sendObject
                    };

                    netWorkAgent.current.request(command, params);
                }
            } else if (currentType === "external") {
                const valid = validateData();
                if (valid === false) {
                    return;
                }
                const command = COMMAND.DATA_UPDATE;
                let _selectExList = [...selectExList];

                let sendObject = {
                    element: currentID,
                    type: currentType,
                    list: _selectExList
                };

                const params = {
                    requestUrl: SDTM_MAPPING_URL,
                    ID: ID,
                    sendObject: sendObject
                };
                netWorkAgent.current.request(command, params);
            }
        }
    }, [ID, currentID, currentType, dataList, lock, selectExList, validateData]);

    /**
     *  @memberOf     CodeLists
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     CodeLists
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // if (data) {
        const {command} = action;

        switch (command) {
            case COMMAND.DATA_LIST:
                if (data.code === 1) { // 결과가 성공인 경우
                    let setID = new Set(); //ID 중복 제거 후 저장하기 위한 변수
                    let setExternalID = new Set();  //external ID 중복 제거 후 저장하기 위한 변수
                    let _treeDataList = []; //Tree 메뉴에 저장할 변수
                    let _dataList = []; //Grid에 뿌려질 리스트 변수
                    let _currentID; //Tree 메뉴에서 선택 되어진 ID 값
                    let _externalList = [];
                    let _selectExCodeList = [];


                    //ID 중복 제거
                    data.data.codeList.forEach(data => {
                        setID.add(data.terminologyID);
                    });

                    //Tree 메뉴에 뿌려주기 위해 JSON 가공
                    Array.from(setID).forEach(ID => {
                        let findObj = data.data.codeList.find(data => data.terminologyID === ID); //중복 제거한 ID와 같은 terminologyID를 찾아 codelist data 정보를 찾음

                        //찾은 codelist data 정보가 있을때
                        if (findObj !== undefined) {
                            _treeDataList.push({
                                key: ID,
                                title: ID,
                                icon: findObj.used === 0 ? <i className={"fas fa-times"}/> :
                                    <i className={"fas fa-genderless"}/>, //icon에 used가 0이면 x 아이콘 표시, 1이면 기본 아이콘 표시
                                codeListType: "normal"
                            });
                        }
                    });

                    data.data.externalCodeList.forEach(data => {
                        setExternalID.add(data.externalID);
                    });

                    //Tree 메뉴에 뿌려주기 위해 JSON 가공
                    Array.from(setExternalID).forEach(item => {
                        let findObj = data.data.externalCodeList.find(data => data.externalID === item);
                        //찾은 codelist data 정보가 있을때
                        if (findObj !== undefined) {
                            _externalList.push({
                                key: item,
                                title: item,
                                icon: findObj.used === 0 ? <i className={"fas fa-times"}/> :
                                    <i className={"fas fa-genderless"}/>, //icon에 used가 0이면 x 아이콘 표시, 1이면 기본 아이콘 표시
                                codeListType: "external"
                            });
                        }
                    });

                    //external인지 codelist인지 구분하기
                    let addedExTypeList = []; // codeListType  : external 추가
                    data.data.externalCodeList.forEach((item) => {
                        addedExTypeList.push({...item, codeListType: "external"});
                    });

                    let addedCoTypeList = []; // codeListType :  codeList 추가
                    data.data.codeList.forEach((item) => {
                        addedCoTypeList.push({...item, codeListType: "normal"});
                    });

                    //Tree메뉴에 해당하는 data 목록 가져오기
                    _currentID = currentID === undefined ? _treeDataList[0].key : currentID; //state에 currentID가 undefined 라면 Tree 메뉴의 첫번째 ID 선택하고 undefined가 아니라면 state에 저장된 currentID 사용
                    _dataList = addedCoTypeList.filter(data => data.terminologyID === _currentID); //서버에서 받아온 데이터에서 화면에 보여줄 list filter
                    _selectExCodeList = addedExTypeList.filter(data => data.externalID === _currentID); //서버에서 받아온 데이터에서 화면에 보여줄 list filter

                    setTreeDataList(_treeDataList); //Tree 메뉴에 뿌려질 list 저장
                    setCurrentID(_currentID); //Tree 메뉴에 선택된 ID 값 저장
                    setDataList(_dataList); //Tree 메뉴에 선택 된 값에 대한 codeList Data 목록 저장
                    setSelectExList(_selectExCodeList); //Tree 메뉴에 선택 된 값에 대한 external codeList Data 목록 저장

                    setAddTypeCoList(addedCoTypeList);
                    setAddTypeExList(addedExTypeList); //원본 external codeList 값에 type 을 추가한 리스트
                    setExternalTreeList(_externalList); //Tree 메뉴에 뿌려질 external list 저장
                    if (currentType === "external") {
                        setIsUsed(selectExList[0].used !== 1); //used 값을 보고 Don't use this CodeList 의 체크 값 확인
                    } else if (currentType === "normal") {
                        setIsUsed(_dataList[0].used !== 1); //used 값을 보고 Don't use this CodeList 의 체크 값 확인
                    }
                } else {
                    setTreeDataList([]);
                    setDataList([]);
                }
                break;

            case COMMAND.DATA_UPDATE:
                if (data.hasOwnProperty('data')) {
                    showToast(getDataList);
                    if (currentType === "external") {
                        setCurrentID(selectExList[0].externalID);
                    } else if (currentType === "normal") {
                        setCurrentID(dataList[0].terminologyID);
                    }
                }
                break;

            //no default
        }
    }, [currentID, currentType, dataList, getDataList, selectExList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [ID, getDataList]);

    useEffect(() => {
        setFilteredTreeDataList(treeDataList);
    }, [treeDataList])

    // useEffect(() => {
    //     if (treeDataList !== undefined) {
    //         let treeContainer = document.getElementById("tree-container");
    //
    //         console.log("변경 된 전 111 treeContainer.scrollTop === ", treeContainer.scrollTop);
    //         console.log("treeContainer.scrollHeight === ", treeContainer.scrollHeight);
    //         treeContainer.scrollTop = treeContainer.scrollHeight;
    //         console.log("변경 된 후 222 treeContainer.scrollTop === ", treeContainer.scrollTop);
    //     }
    //
    // }, [treeDataList]);

    /**
     *  @memberOf     CodeLists
     *  @constant     {Array} column_list
     *  @description  external codeList AUIGrid Column 정보
     */
    const column_list = [
        {
            dataField: "externalID",
            headerText: "ID",
            editable: lock !== 2,
            wrapText: true,
        },
        {
            dataField: "name",
            headerText: "Name",
            editable: lock !== 2,
            wrapText: true,
        },
        {
            dataField: "dataType",
            headerText: "Data Type",
            editable: lock !== 2 && (currentID !== "MedDRA" && currentID !== "WHODrug Global"),
            editRenderer: {
                type: "DropDownListRenderer",
                list: EXTERNAL_DATA_TYPE_LIST,
                showEditorBtnOver: true
            }
        },
        {
            dataField: "dictionary",
            headerText: "Dictionary",
            editable: lock !== 2 && (currentID !== "MedDRA" && currentID !== "WHODrug Global"),
            editRenderer: {
                type: "ComboBoxRenderer",
                list: DICTIONAYR_LIST,
                keyField: "text", // key 에 해당되는 필드명
                valueField: "value", // value 에 해당되는 필드명
            },
            showEditorBtnOver: true,
        },
        {
            dataField: "version",
            headerText: "Version",
            editable: lock !== 2,
            editRenderer: {
                type: "ConditionRenderer",
                conditionFunction: function (rowIndex, columnIndex, value, item) {
                    // 특정 조건에 따라 미리 정의한 editRenderer 반환.
                    if (currentID === "MedDRA") {
                        return medDRAVersion;
                    } else if (currentID === "WHODrug Global") {
                        return WHODrugGlobalVersion;
                    } else if (item.dictionary === "LOINC") {
                        return DictionaryLoinc;
                    }
                },
                showEditorBtnOver: true
            },
        },
        {
            dataField: "used",
            width: "0%"
        }
    ];

    let medDRAVersion = {
        type: "ComboBoxRenderer",
        list: MedDRA_VERSION_LIST,
        keyField: "text", // key 에 해당되는 필드명
        valueField: "value", // value 에 해당되는 필드명
        showEditorBtnOver: true
    }

    let WHODrugGlobalVersion = {
        type: "ComboBoxRenderer",
        list: WHODrug_VERSION_LIST,
        keyField: "text", // key 에 해당되는 필드명
        valueField: "value", // value 에 해당되는 필드명
        showEditorBtnOver: true
    }

    let DictionaryLoinc = {
        type: "ComboBoxRenderer",
        list: DICTIONAYR_LOINC_LIST,
        keyField: "text", // key 에 해당되는 필드명
        valueField: "value", // value 에 해당되는 필드명
        showEditorBtnOver: true
    }


    /**
     *  @memberOf     CodeLists
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "terminologyID",
            headerText: "ID",
            editable: lock !== 2,
            wrapText: true,
            cellMerge: true
        },
        {
            dataField: "name",
            headerText: "Name",
            editable: lock !== 2,
            wrapText: true,
            cellMerge: true,
            style: "cell-left-align"
        },
        {
            dataField: "dataType",
            headerText: "Data<br/>Type",
            editable: lock !== 2,
            editRenderer: {
                type: "DropDownListRenderer",
                list: DATA_TYPE_LIST,
                showEditorBtnOver: true
            },
            width: "6%",
            cellMerge: true
        },
        {
            dataField: "standardVersion",
            headerText: "Standard Version",
            editable: lock !== 2,
            editRenderer: {
                type: "DropDownListRenderer",
                list: ["", "sdtmct-2021-03-26"],
                showEditorBtnOver: true
            },
        },
        {
            dataField: "NCITermCode",
            headerText: "NCI Term<br/>Code",
            editable: lock !== 2,
            width: "7%",
            filter: {
                showIcon: true,
            },
        },
        {
            dataField: "NCICodelistCode",
            headerText: "NCI CodeList<br/>Code",
            editable: lock !== 2,
            width: "7%",
            cellMerge: true
        },
        {
            dataField: "codeValue",
            headerText: "Code Value",
            editable: lock !== 2,
            wrapText: true,
            style: "cell-left-align",
        },
        {
            dataField: "decodedValue",
            headerText: "Decoded Value",
            editable: lock !== 2,
            wrapText: true,
            style: "cell-left-align",
        },
        {
            dataField: "comment",
            headerText: "Comment",
            editable: lock !== 2,
            wrapText: true
        },
        {
            dataField: "",
            headerText: "",
            width: lock !== 2 ? "5%" : "0%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
                iconHeight: 16,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteRow(event);
                    }
                }
            }
        },
        {
            dataField: "codeType",
            width: "0%"
        },
        {
            dataField: "used",
            width: "0%"
        },
        {
            dataField: "order",
            width: "0%"
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>
                <div className="vertical-box-column width-200 bg-light">
                    <div className="vertical-box">
                        <div className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar id="tree-container" className="height-full"
                                                      options={{suppressScrollXY: true}}>
                                        <div className="p-l-10 p-t-10">
                                            <h5>
                                                External CodeList
                                                {lock !== 2 && (
                                                    <i className="fa fa-plus m-l-5" onClick={addExCodeListID}/>
                                                )}
                                            </h5>
                                        </div>

                                        <div className="p-10 p-b-40">
                                            {treeDataList !== undefined && renderExternalTreeMenu()}
                                        </div>

                                        <div className="p-l-10 p-t-10">
                                            <h5>
                                                CodeList
                                                {lock !== 2 && (
                                                    <i className="fa fa-plus m-l-5" onClick={addCodeListID}/>
                                                )}
                                            </h5>
                                        </div>

                                        <div
                                            className="d-flex align-items-center justify-content-between mt-2 mt-sm-0 ml-2">
                                            <TextSearch
                                                name={"codeListSearch"}
                                                onClick={(name, search) => {
                                                    if (search === "") {
                                                        setFilteredTreeDataList(treeDataList);
                                                    } else {
                                                        setFilteredTreeDataList(treeDataList.filter(e => e.title.includes(search.toUpperCase())))
                                                    }
                                                }}/>
                                        </div>

                                        <div className="p-10 p-b-40">
                                            {filteredTreeDataList !== undefined && renderTreeMenu()}
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="content" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar className="height-full p-20" options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">
                                            <div className="align-self-center">
                                                <Checkbox
                                                    onChange={handleChange}
                                                    name={"isUseCodelist"}
                                                    currentValue={isUsed}
                                                    label={"Don't use this CodeList"}
                                                    disabled={lock === 2}/>
                                            </div>

                                            {currentType === "normal" &&
                                                <div
                                                    className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                    <div className="d-flex justify-content-end m-b-10">
                                                        <button
                                                            className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                            onClick={handleAddRow}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {currentType === "external" &&
                                            <div className="p-t-10">
                                                <AUIGrid
                                                    id="external_codeList"
                                                    columnList={column_list}
                                                    dataList={selectExList}
                                                    onEdit={handleGridChange}
                                                    scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                                    onScrollChange={getScrollInfo}
                                                    isDrag={true}
                                                    onDrag={handleDrag}
                                                    isShowRowNum={true}
                                                    rowHeight={40}
                                                />
                                            </div>
                                        }

                                        {currentType === "normal" &&
                                            <AUIGrid
                                                id="normal_codeList"
                                                columnList={COLUMN_LIST}
                                                dataList={dataList}
                                                onEdit={handleGridChange}
                                                scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                                onScrollChange={getScrollInfo}
                                                isDrag={true}
                                                onDrag={handleDrag}
                                                isShowRowNum={true}
                                                rowHeight={40}
                                            />
                                        }
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between border-top bg-light">
                            <div className='p-10'>
                                <button
                                    className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                    onClick={goToList}>
                                    List
                                </button>
                            </div>

                            <div className='p-10'>
                                <button
                                    className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                    onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(CodeLists);