import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import produce from "immer";
import {COMMAND} from "common/dataProcessAgent";
import {AXIOS_GET, GenerateID, getSessionState, isDataExist} from "common/commonFunction";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import TextField from "../../../../components/TextField";
import TextArea from "../../../../components/TextArea";
import SelectBox from "../../../../components/SelectBox";
import {isEmpty} from "codemirror/src/util/misc";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     SelectElementDateModal
 *  @constant     {Object} MODAL_DEFINEINE
 *  @description  field 명 정의
 */
const MODAL_DEFINE = {
    STUDY_EVENT: 'studyEvent',
    DOMAIN: 'domain',
    DATE_VARIABLE: 'variable',
    VARIABLE: 'variable',
    OPERATOR: 'operator',
    VALUE: 'value',
    DESCRIPTION: 'description'
};

/**
 *  @memberOf     SelectElementDateModal
 *  @constant     {Object} VALIDATION_FIELD
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    studyEvent: true,
    domain: true,
    variable: true
};

/**
 *  @memberOf     SelectElementDateModal
 *  @constant     {Array} OPERATOR_NAME
 *  @description  Condition 필드의 Operator 표시 목록
 */
const OPERATOR_NAME = [
    {
        text: '>',
        value: '>',
    },
    {
        text: '>=',
        value: '>=',
    },
    {
        text: '=',
        value: '=',
    },
    {
        text: '<',
        value: '<',
    },
    {
        text: '<=',
        value: '<=',
    },
    {
        text: '!=',
        value: '!=',
    },
    {
        text: 'IN',
        value: 'IN',
    }
];

/**
 *  @memberOf     SelectElementDateModal
 *  @constant     {Object} CONDITION_TYPE
 *  @description  Condition 필드 Type의 AND, OR 구분 값
 */
const CONDITION_TYPE = {
    AND: 'AND',
    OR: 'OR'
};

/**
 *  @memberOf     SelectElementDateModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.STUDY_EVENT_DATA_LIST:
            url = `${requestUrl}/tdomain/studyEvent/${ID}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DOMAIN_DATA_LIST:
            url = `${requestUrl}/tdomain/domain/${ID}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.VARIABLE_DATA_LIST:
            url = `${requestUrl}/tdomain/variable/${domain}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    SelectElementDateModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TE, TV, TM 도메인 에서 사용되는 공통 컴포넌트
 */
const SelectElementDateModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SelectElementDateModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} title - 창 상단에 표시되는 타이틀
     *  @property     {String} type - TE, TV에서 사용 되는 타입(Start, End)
     *  @property     {Object} data - 화면에 표시 될 정보
     *  @property     {String} rowID - 선택 된 줄 ID
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @property     {Function} onOk - 상위로 선택 내용 전달 하기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, title, type, data, rowID, onClose, onOk, dataList} = props;//

    /**
     *  @memberOf     SelectElementDateModal
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @description  session 에서 받아오는 정보
     */
    const {ID} = getSessionState();

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(isEmpty(data) ? {
        type: type !== "DEF" ? type : "START",
        condition: []
    } : {
        ...data,
        description: dataList.find(x => x.ID === rowID).visit.find(x => x.type === data.type).description
    });

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Array} studyEventList
     *  @description  StudyEvent 항목에서 선택 될 리스트 목록
     */
    const [studyEventList, setStudyEventList] = useState([]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Array} domainList
     *  @description  Domain 항목에서 선택 될 리스트 목록
     */
    const [domainList, setDomainList] = useState([]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {String} domain
     *  @description  Domain 항목에 선택 된 값(dataInfo 에서 값 가져다 쓰면 api 계속 호출 되어 사용)
     */
    const [domain, setDomain] = useState(isEmpty(data) ? "" : data.domain);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Array} variableList
     *  @description  Date Variable 항목, Condition 항목 Variable에 선택 될 리스트 목록
     */
    const [variableList, setVariableList] = useState([]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /**
     *  @memberOf     SelectElementDateModal
     *  @var          {Number} order
     *  @description  condition의 테이블에 순서 값
     */
    const [order, setOrder] = useState(0);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SelectElementDateModal
     *  @function     getStudyEventList
     *  @description  StudyEvent 리스트 요청 함수. StudyEvent list 조회 api 호출.
     */
    const getStudyEventList = useCallback(() => {
        const command = COMMAND.STUDY_EVENT_DATA_LIST;

        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     getDomainList
     *  @description  Domain 리스트 요청 함수. Domain list 조회 api 호출.
     */
    const getDomainList = useCallback(() => {
        const command = COMMAND.DOMAIN_DATA_LIST;

        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     getVariableList
     *  @description  Variable 리스트 요청 함수. Variable list 조회 api 호출.
     */
    const getVariableList = useCallback(() => {
        const command = COMMAND.VARIABLE_DATA_LIST;

        const params = {
            requestUrl: SDTM_MAPPING_URL,
            domain: domain,
        };

        netWorkAgent.current.request(command, params);
    }, [domain]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  input 필드 변경시 호출되는 함수
     */
    const handleChange = useCallback((name, value) => {
        setDataInfo(produce(dataInfo, draft => {
            draft[name] = value;

            //Oomain이 변경되면 Date Variable과 condition의 Variable 값 비워줌
            if (name === MODAL_DEFINE.DOMAIN) {
                draft[MODAL_DEFINE.DATE_VARIABLE] = '';

                let idx;
                for (idx = 0; idx < dataInfo.condition.length; idx++) {
                    draft.condition[idx][MODAL_DEFINE.VARIABLE] = '';
                }

                setDomain(value);
            }
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleConditionChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @param        {Number} index - 입력 요소의 줄 번호
     *  @description  Condition 필드의 row의 값 변경시 호출되는 함수
     */
    const handleConditionChange = useCallback((name, value, index) => {
        setDataInfo(produce(dataInfo, draft => {
            draft.condition[index][name] = value;
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleAddRow
     *  @param        {String} conType - AND 인지 OR 인지
     *  @description  Condition 필드애서 Add AND, Add OR 버튼 클릭했을 때 호출되는 함수
     */
    const handleAddRow = useCallback((conType) => {
        let _order = order;
        
        if (type !== "DEF") {
            setDataInfo(produce(dataInfo, draft => {
                draft.condition.push({
                    ID: GenerateID(),
                    visitID: data.ID,
                    type: conType,
                    variable: '',
                    operator: '',
                    value: '',
                    order: ++_order
                });
            }));
        } else {
            setDataInfo(produce(dataInfo, draft => {
                draft.condition.push({
                    ID: GenerateID(),
                    defID: data.ID,
                    type: conType,
                    variable: '',
                    operator: '',
                    value: '',
                    order: ++_order
                });
            }));
        }

        setOrder(_order);
    }, [order, type, dataInfo, data.ID]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleDeleteRow
     *  @param        {Number} index - 삭제할 줄 index
     *  @description  Condition 필드에서 휴지통 버튼 클릭했을 호출되는 함수
     */
    const handleDeleteRow = useCallback((index) => {
        setDataInfo(produce(dataInfo, draft => {
            draft.condition.splice(index, 1);
        }));
    }, [dataInfo]);

    /**
     * @memberOf      SelectElementDateModal
     *  @function     renderConditionTr
     *  @return       {Element} Table Tr render
     *  @description  Add AND, Add OR 버튼 클릭시 Condition 항목의 표에 줄 그려주는 함수
     */
    const renderConditionTr = useCallback(() => {
        if (isDataExist(dataInfo.condition)) {
            return dataInfo.condition.map((item, idx) => {
                return (
                    <tr key={idx}>
                        <td>{item.type}</td>

                        <td>
                            <SelectBox
                                name={MODAL_DEFINE.VARIABLE}
                                onChange={(name, value) => handleConditionChange(name, value, idx)}
                                dataList={variableList}
                                currentValue={item.variable}/>
                        </td>

                        <td>
                            <SelectBox
                                name={MODAL_DEFINE.OPERATOR}
                                onChange={(name, value) => handleConditionChange(name, value, idx)}
                                dataList={OPERATOR_NAME}
                                currentValue={item.operator}/>
                        </td>

                        <td>
                            <TextField
                                name={MODAL_DEFINE.VALUE}
                                currentValue={item.value}
                                onChange={(name, value) => handleConditionChange(name, value, idx)}/>
                        </td>

                        <td>
                            <button
                                className="btn btn-orange"
                                onClick={() => handleDeleteRow(idx)}>
                                <i className="far fa-lg fa-trash-alt"/>
                            </button>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={5}>No data</td>
                </tr>
            );
        }
    }, [dataInfo.condition, handleDeleteRow, handleConditionChange, variableList]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleClose
     *  @description  창 닫기
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        let key;
        for (key of validateKeys) {
            validateData[key] = isDataExist(dataInfo[key]);
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, validation]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     handleOk
     *  @description  OK 버튼 클릭시 호출 되는 함수
     */
    const handleOk = useCallback(() => {
        if (onOk) {
            onOk(rowID, dataInfo);
        }
    }, [dataInfo, onOk, rowID, validateData]);

    /**
     *  @memberOf     SelectElementDateModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.STUDY_EVENT_DATA_LIST:
                    let studyEventList = [];

                    data.data.forEach(data => {
                        studyEventList.push({
                            text: data.name,
                            value: data.name
                        });
                    });

                    studyEventList.unshift({
                        text: "",
                        value: ""
                    })
                    setStudyEventList(studyEventList);
                    break;

                case COMMAND.DOMAIN_DATA_LIST:
                    let domainList = [];

                    data.data.forEach(data => {
                        domainList.push({
                            text: data.domain,
                            value: data.domain
                        });
                    });
                    domainList.sort((a, b) => a.text.localeCompare(b.text))

                    domainList.unshift({
                        text: "",
                        value: ""
                    })
                    setDomainList(domainList);
                    break;

                case COMMAND.VARIABLE_DATA_LIST:
                    let variableList = [];

                    data.data.forEach(data => {
                        variableList.push({
                            text: data.variable,
                            value: data.variable
                        });
                    });

                    variableList.unshift({
                        text: "",
                        value: ""
                    })
                    setVariableList(variableList);
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // StudyEvent 필드에 표시될 리스트 요청
    useEffect(() => {
        getStudyEventList();
    }, [getStudyEventList]);

    // Domain 필드에 표시될 리스트 요청
    useEffect(() => {
        getDomainList();
    }, [getDomainList]);

    // Domain 필드 값 변경 될때 마다 Condition 필드 Variable 리스트 요청
    useEffect(() => {
        if (domain) {
            getVariableList();
        }
    }, [domain, getVariableList]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/

    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen className="modal-xl">
                <ModalHeader toggle={handleClose}>{title}</ModalHeader>

                <ModalBody className="px-3 mx-3 pt-3 mt-2 h-100">
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>StudyEvent (SoA Name)</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <SelectBox
                                    name={MODAL_DEFINE.STUDY_EVENT}
                                    onChange={handleChange}
                                    dataList={studyEventList}
                                    currentValue={dataInfo[MODAL_DEFINE.STUDY_EVENT]}/>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Domain</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <SelectBox
                                    name={MODAL_DEFINE.DOMAIN}
                                    onChange={handleChange}
                                    dataList={domainList}
                                    currentValue={dataInfo[MODAL_DEFINE.DOMAIN]}/>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Variable</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <SelectBox
                                    name={MODAL_DEFINE.DATE_VARIABLE}
                                    onChange={handleChange}
                                    dataList={variableList}
                                    currentValue={dataInfo[MODAL_DEFINE.DATE_VARIABLE]}/>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Condition</span>
                        </label>

                        <div className="col-md-7">
                            <div className="w-100">
                                <div className='card-table'>
                                    <table className="log-table">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Variable</th>
                                            <th>Operator</th>
                                            <th>Value</th>
                                            <th/>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {renderConditionTr()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='d-flex'>
                                <button
                                    className="btn btn-blue m-r-10"
                                    onClick={() => handleAddRow(CONDITION_TYPE.AND)}>
                                    Add AND
                                </button>

                                <button
                                    className="btn btn-blue"
                                    onClick={() => handleAddRow(CONDITION_TYPE.OR)}>
                                    Add OR
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Description</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextArea
                                    name={MODAL_DEFINE.DESCRIPTION}
                                    onChange={handleChange}
                                    currentValue={dataInfo[MODAL_DEFINE.DESCRIPTION]}/>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <button
                        className="btn btn-new btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleOk}>
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(SelectElementDateModal);
