import React, {useCallback, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {copyObject} from "../../../../../../common/commonFunction";
import cn from "classnames";

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    IETESTEditModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  Criteria 편집 팝업 컴포넌트
 */
const IETESTEditModal = (props) => {
    /**
     *  @memberOf     CriteriaEditModal
     *  @type         {Object} props
     *  @property     {Function} onClose - 닫기 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Boolean} readOnly - 편집 모드 여부 (true - 편집 불가)
     *  @property     {Object} data - 수정할 데이터의 Object
     *  @property     {Function} onSave - 저장 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Number} criteriaIndex - 선택된 criteria index
     *  @property     {Object} originData - 수정 전 데이터의 Object
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {onClose, readOnly, data, onSave, criteriaIndex, originData} = props;

    /**
     *  @memberOf     CriteriaEditModal
     *  @var          {Object} dataInfo
     *  @description  수정 될 데이터
     */
    const [dataInfo, setDataInfo] = useState(data);

    /**
     *  @memberOf     CriteriaEditModal
     *  @var          {Object} numOfLetters
     *  @description  수정 전 데이터의 길이
     */
    const [originNumOfLetters] = useState(originData.IETEST.replaceAll('<br/>', '\n').length);

    /**
     *  @memberOf     CriteriaEditModal
     *  @var          {Object} numOfLetters
     *  @description  수정 후 데이터의 길이
     */
    const [numOfLetters, setNumOfLetters] = useState(dataInfo.IETEST.replaceAll('<br/>', '\n').length);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  값 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDataInfo({...dataInfo, IETEST: e.target.value});
        setNumOfLetters(e.target.value.length);
    }, [dataInfo]);

    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     CriteriaEditModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 실행되는 함수
     */
    const handleSave = useCallback(() => {
        if (onSave !== undefined) {
            let _dataInfo = copyObject(dataInfo);
            _dataInfo.IETEST = _dataInfo.IETEST.replaceAll('\n', '<br/>'); //gird에 전달 되는 값이기 때문에 \n을 <br/> 로 변경 작업

            onSave(_dataInfo, criteriaIndex);
            handleClose();
        }
    }, [criteriaIndex, dataInfo, handleClose, onSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <Modal isOpen style={{maxWidth: "1000px", minHeight: '90vh'}}>
            <ModalHeader
                className={'header-title bg-main'}
                toggle={handleClose}>
                Criteria Edit
            </ModalHeader>

            <ModalBody>
                { originNumOfLetters > 200 ?
                    // 수정 전 데이터 길이가 200자 이상인 경우
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="col px-1 h-100 m-r-20">
                            <div className="col-2">Original</div>
                            <textarea
                                rows={15}
                                className="form-control m-t-10"
                                placeholder={originData.IETEST.replaceAll('<br/>', '\n')}
                                style={{resize: "none"}}
                                disabled={readOnly}/>
                            <div className="col-4">
                                <span className="text-danger">{originNumOfLetters} / 200</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <button
                                className={cn("btn btn-sm btn-blue arrow-button mt-2 p-0")}>
                                <i className="fas fa-fw m-5 fa-angle-right icon-font text-white"/>
                            </button>
                        </div>
                        <div className="col px-1 h-100 m-l-20">
                            <div className="col-2">Modified</div>
                            <textarea
                                rows={15}
                                className="form-control m-t-10"
                                onChange={handleChange}
                                value={dataInfo.IETEST.replaceAll('<br/>', '\n')}
                                style={{resize: "none"}}/>
                            <div className="col-4">
                                { numOfLetters > 200 ?
                                    <span className="text-danger">{numOfLetters} / 200</span> :
                                    <span >{numOfLetters} / 200</span> }
                            </div>
                        </div>
                    </div>
                    :
                    // 수정 전 데이터 길이가 200자 미만인 경우
                    <textarea
                        rows={15}
                        className="form-control m-t-10"
                        onChange={handleChange}
                        value={dataInfo.IETEST.replaceAll('<br/>', '\n')}
                        style={{resize: "none"}}
                        disabled={readOnly}/>
                }

                {/* 오른쪽 하단 글자 수 */}
                <div className="m-t-3 w-100 d-flex justify-content-end">
                    { originNumOfLetters > 200 ?
                        <span></span> :
                        ( numOfLetters > 200 ?
                            <span className="text-danger">{numOfLetters} / 200</span> :
                            <span>{numOfLetters} / 200</span>
                        )
                    }
                </div>
            </ModalBody>

            <ModalFooter className="p-3">
                <button
                    className="btn btn-blue"
                    onClick={handleClose}>
                    Cancel
                </button>

                {!readOnly && (
                    <button
                        className="btn btn-new"
                        onClick={handleSave}>
                        Ok
                    </button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default IETESTEditModal;