/**
 *  @constant
 *  @type {array}
 *  @description  yes or no 사용하는 옵션 값
 */
export const YESNO_OPTION = [
    {text: "YES", value: 'Yes'},
    {text: "NO", value: 'No'},
];

export const YN_OPTION = [
    {text: "YES", value: 'Y'},
    {text: "NO", value: 'N'},
];

export const STATE_OPTION = [
    {
        text: 'Open',
        value: 1,
    },
    {
        text: 'Close',
        value: 0
    }
];
/**
 *  @constant
 *  @type {object}
 *  @description  prm primary endpoint 이름
 */
export const PRIMARY_ENDPOINT_NAME = {
    OUTMSPRI: 'OUTMSPRI',
    TTYPE: 'TTYPE',
};

/**
 *  @constant
 *  @type {array}
 *  @description  검색할 때 사용되는 Lock Option
 */
export const LOCK_STATE_OPTION = [
    {
        text: 'Unlocked',
        value: 1
    },
    {
        text: 'Locked',
        value: 2,
    },
];

/**
 *  @constant
 *  @type {array}
 *  @description  검색할 때 사용되는 Model Option
 */
export const MODEL_OPTION = [
    {
        text: 'CDASH',
        value: 'CDASH'
    },
    {
        text: 'CUSTOM',
        value: 'CUSTOM',
    },
];

/**
 *  @constant
 *  @type {array}
 *  @description  TA 선택에 사용되는 옵션 값
 */
export const TA_OPTION = [
    {
        text: 'Autoimmune',
        value: 'Autoimmune',
    },
    {
        text: 'Cardiovascular',
        value: 'Cardiovascular',
    },
    {
        text: 'Endocrine',
        value: 'Endocrine',
    },
    {
        text: 'Gastrointestinal',
        value: 'Gastrointestinal',
    },
    {
        text: 'Cardiovascular',
        value: 'Cardiovascular',
    },
    {
        text: 'Infectious',
        value: 'Infectious',
    },
    {
        text: 'Mental Health',
        value: 'Mental Health',
    },
    {
        text: 'Neurology',
        value: 'Neurology',
    },
    {
        text: 'Oncology',
        value: 'Oncology',
    },
    {
        text: 'Rare Diseases',
        value: 'Rare Diseases',
    },
    {
        text: 'Respiratory',
        value: 'Respiratory',
    },
    {
        text: 'Treatments',
        value: 'Treatments',
    },
    {
        text: 'Other',
        value: 'Other',
    },
];

/**
 *  @constant
 *  @type {array}
 *  @description  CDASH VERSION 선택에 사용되는 옵션 값
 */
export const CDASH_VERSION = [
    {text: "1.0", value: '1.0'},
    {text: "1.1", value: '1.1'},
    {text: "2.0", value: '2.0'},
    {text: "2.1", value: '2.1'}
];

/**
 *  @constant
 *  @type {object}
 *  @description  phase icon list
 */
export const PHASE_ICON_LIST = {
    '0': 'icon-element-0',
    '1': 'icon-element-1',
    '2': 'icon-element-1-2',
    '3': 'icon-element-2',
    '4': 'icon-element-2-3',
    '5': 'icon-element-2a',
    '6': 'icon-element-2b',
    '7': 'icon-element-3',
    '8': 'icon-element-3a',
    '9': 'icon-element-3b',
    '10': 'icon-element-4',
    '11': 'icon-element-5',
};


/**
 *  @constant {array} MASTER_ROLE_OPTION
 *  @description  master role 권한 리스트
 */
export const MASTER_ROLE_OPTION = [
    {
        text: 'Master',
        value: 'Master'
    },
    {
        text: 'Admin',
        value: 'Admin'
    },
    {
        text: 'Medical',
        value: 'Medical'
    },
    {
        text: 'DM',
        value: 'DM'
    },
    {
        text: 'STAT',
        value: 'STAT'
    },
];


/**
 *  @constant {array} ADMIN_ROLE_OPTION
 *  @description  admin role 권한 리스트
 */
export const ADMIN_ROLE_OPTION = [
    {
        text: 'Admin',
        value: 'Admin',
    },
    {
        text: 'Medical',
        value: 'Medical',
    },
    {
        text: 'DM',
        value: 'DM',
    },
    {
        text: 'STAT',
        value: 'STAT',
    },
];


// category 목록
export const CATEGORY_OPTION = [
    {
        text: 'Common',
        value: 'Common'
    },
    {
        text: 'Study',
        value: 'Study'
    },
    {
        text: 'Design',
        value: 'Design'
    },
    {
        text: 'CRF',
        value: 'CRF'
    }, {
        text: 'SDTM',
        value: 'SDTM'
    }, {
        text: 'Admin',
        value: 'Admin'
    }, {
        text: 'imtrial',
        value: 'imtrial'
    }
];

/**
 *  @constant PHASE_OPTION
 *  @type {array}
 *  @description  PHASE에 사용하는 옵션 값
 */
export const PHASE_OPTION = [
    {text: "0", value: '0'},
    {text: "Ⅰ", value: '1'},
    {text: "Ⅰ/Ⅱ", value: '2'},
    {text: "Ⅱ", value: '3'},
    {text: "Ⅱ/Ⅲ", value: '4'},
    {text: "ⅡA", value: '5'},
    {text: "ⅡB", value: '6'},
    {text: "Ⅲ", value: '7'},
    {text: "ⅢA", value: '8'},
    {text: "ⅢB", value: '9'},
    {text: "Ⅳ", value: '10'},
    {text: "Ⅴ", value: '11'},
];

export const EDCTYPE_OPTION = [
    {text: "BOIM", value: 'boim'},
    {text: "RAVE", value: 'rave'},
]

/**
 *  @constant FLAG_LIST
 *  @type {array}
 *  @description  국가 리스트(코드)
 */
export const FLAG_LIST = [
    {
        text: 'Australia',
        value: 'AUS',
    },
    {
        text: 'Canada',
        value: 'CAN',
    },
    {
        text: 'China',
        value: 'CHN',
    },
    {
        text: 'France',
        value: 'FRA',
    },
    {
        text: 'Germany',
        value: 'DEU',
    },
    {
        text: 'India',
        value: 'IND',
    },

    {
        text: 'Japan',
        value: 'JPN',
    },
    {
        text: 'Korea (the Republic of)',
        value: 'KOR',
    },
    {
        text: 'Singapore',
        value: 'SGP',
    },
    {
        text: 'United Kingdom of Great Britain and Northern Ireland',
        value: 'GBR',
    },
    {
        text: 'United States of America',
        value: 'USA',
    },
];