import {
    AXIOS_DELETE,
    AXIOS_GET,
    AXIOS_POST,
    AXIOS_POST_FORM,
    AXIOS_PUT,
    AXIOS_PUT_FORM,
    isDataExist
} from "common/commonFunction";

import {PARTNER_URL, SDTM_URL} from "constant/ConstantURL";

/**
 *  @summary network 통신 호출 공통 로직 모음
 *  @author  주예리나
 *  @version 1.0, 작업 내용
 * */

/**
 *  @constant
 *  @type {Object}
 *  @description  데이터 처리 요청 구분 command
 *         DATA_LIST   :  데이터 리스트 요청
 *         DATA_INFO   :  데이터 정보 요청
 *         DATA_CREATE :  데이터 생성 요청
 *         DATA_UPDATE :  데이터 변경 요청
 *         DATA_DELETE :  데이터 삭제 요청
 *         FILE_UPLOAD :  파일 업로드 요청
 *
 *         GROUP_IN_LIST   : 권한 그룹에 포함되는 리스트 요청
 *         GROUP_OUT_LIST  : 권한 그룹에 포함되지 않는  리스트 요청
 *         GROUP_SAVE      : 그룹 정보 저장(add, delete)
 *
 *         DATA_COPY      : Data copy
 *
 *         TARGET_LIST  :  알람 전송 타겟 검색
 *
 *         FUNCTION_LIST     : function 데이터 리스트 요청
 *         FUNCTION_EDIT     : function 데이터 추가 혹은 삭제 요청
 *         VERSION_LOCK :  VERSION LOCK 처리 요청
 *
 *         SERVICE_DATA_LIST : 서비스 ID 목록 요청
 */
export const COMMAND = {
    DATA_LIST: 'DATA_LIST',
    DATA_INFO: 'DATA_INFO',
    DATA_CREATE: 'DATA_CREATE',
    DATA_UPDATE: 'DATA_UPDATE',
    DATA_DELETE: 'DATA_DELETE',
    DATA_LOCK: 'DATA_LOCK',
    DATA_ADD: 'DATA_ADD',
    FILE_UPLOAD: 'FILE_UPLOAD',
    FILE_DOWNLOAD: 'FILE_DOWNLOAD',
    FILE_DOWNLOAD_WITH_URL: 'FILE_DOWNLOAD_WITH_URL',
    DATA_SEARCH: 'DATA_SEARCH',
    DIAGRAM_UPLOAD: 'DIAGRAM_UPLOAD',
    DRAFT_VERSION: 'DRAFT_VERSION',


    TA_DATA_LIST: 'TA_DATA_LIST',
    DATA_CLASS_LIST: 'DATA_CLASS_LIST',
    DATA_DOMAIN_LIST: 'DATA_DOMAIN_LIST',
    DATA_VARIABLE_LIST: 'DATA_VARIABLE_LIST',
    DATE_VARIABLE: 'DATE_VARIABLE',
    DATA_FUNCTION_LIST: 'DATA_FUNCTION_LIST',
    DATA_MODAL_LIST: 'DATA_MODAL_LIST',
    STUDY_DATA_LIST: 'STUDY_DATA_LIST',
    PACKAGE_DATA_LIST: 'PACKAGE_DATA_LIST',
    STUDY_EVENT_DATA_LIST: "STUDY_EVENT_DATA_LIST",
    DOMAIN_DATA_LIST: "DOMAIN_DATA_LIST",
    VARIABLE_DATA_LIST: "VARIABLE_DATA_LIST",

    PARTNER_CODELIST: 'PARTNER_CODELIST',
    DOMAIN_CODELIST: 'DOMAIN_CODELIST',
    VARIABLE_CODELIST: 'VARIABLE_CODELIST',

    LOGIN: 'LOGIN',
    LOGIN_INFO: 'LOGIN_INFO',

    GROUP_OUT_LIST: 'GROUP_OUT_LIST',
    GROUP_IN_LIST: 'GROUP_IN_LIST',
    GROUP_SAVE: 'GROUP_SAVE',

    DATA_COPY: 'DATA_COPY',
    DATA_TX_STR: 'DATA_TX_STR',
    DATA_TX_END: 'DATA_TX_END',
    DATA_TX_TYPE: 'DATA_TX_END',
    DATA_PUBLISH: 'DATA_PUBLISH',

    TARGET_LIST: 'TARGET_LIST',

    FUNCTION_LIST: 'FUNCTION_LIST',
    FUNCTION_EDIT: 'FUNCTION_EDIT',
    VERSION_LOCK: 'VERSION_LOCK',

    SERVICE_ID_LIST: 'SERVICE_ID_LIST',

    DOMAIN_LIST: 'DOMAIN_LIST',
    ACTIVITY_LIST: 'ACTIVITY_LIST',
    ACTIVITY_META_LIST: 'ACTIVITY_META_LIST',
    EPOCH_LIST: 'EPOCH_LIST',

    STUDY_LIST: 'STUDY_LIST',
    SPONSOR_LIST: 'SPONSOR_LIST',
    COUNTRY_LIST: 'COUNTRY_LIST',
    FORM_DATA_INFO: 'FORM_DATA_INFO',
    PRM_LIST: 'PRM_LIST',
    SDTM_ACRF: 'SDTM_ACRF',
    CRF_ACRF: 'CRF_ACRF',

    // CRF EDC Build
    EDC_FORM_LIST: 'EDC_FORM_LIST',
    EDC_FORM_PREVIEW: 'EDC_FORM_PREVIEW',
    EDC_BUILD_HISTORY: 'EDC_BUILD_HISTORY',
    EDC_BUILD_STUDY: 'EDC_BUILD_STUDY',
    EDC_BUILD_VERSION: 'EDC_BUILD_VERSION',
    EDC_API_CONNECT: 'EDC_API_CONNECT',
    EDC_API_LIST: 'EDC_API_LIST',
    EDC_BUILD: 'EDC_BUILD',
    EDC_VERSION_LIST: 'EDC_VERSION_LIST',
    SPEC_DOWNLOAD: 'SPEC_DOWNLOAD',
    ODM_COMPACT: 'ODM_COMPACT',

    TEMPLATE_INFO: 'TEMPLATE_INFO',
    DATA_IMPORT: 'DATA_IMPORT',
    COMPACT_ID: 'COMPACT_ID',
    SIGNATURE: 'SIGNATURE',

    DESIGN_LIST: 'DESIGN_LIST',
    CLINICAL_LIST: 'CLINICAL_LIST',
    SDTM_IG_LIST: 'SDTM_IG_LIST',
    TABULATION_LIST: 'TABULATION_LIST',

    RAVE_STUDY_LIST: 'RAVE_STUDY_LIST',
    RAVE_DATA_INFO: 'RAVE_DATA_INFO',
    STUDY_SEQ_LIST: 'STUDY_SEQ_LIST',
    GROUP_ID_LIST: 'GROUP_ID_LIST',

    TEMPLATE_DOWNLOAD: 'TEMPLATE_DOWNLOAD',
    FILE_CHECK: 'FILE_CHECK',

    RESET_PASSWORD: 'RESET_PASSWORD',
    TRAINING_INFO: 'TRAINING_INFO',
    REFRESH_AUTH: 'REFRESH_AUTH',
    TIME_POINT_DATA: 'TIME_POINT_DATA',

    // mdr terminology
    SEARCH_CODENAME: 'SEARCH_CODENAME',
    SEARCH_CODE: 'SEARCH_CODE',

    //mdr map
    DATA_TA_LIST: 'DATA_TA_LIST',
    INDICATION_LIST: 'INDICATION_LIST',
    MY_INFO_DATA: 'MY_INFO_DATA',

    // version 리스트

    TM_LIST: 'TM_LIST',
    IG_LIST: 'IG_LIST',
    VERSION_LIST: 'VERSION_LIST',

    LOG_OUT: 'LOG_OUT',

    // Management No List & Protocol No 목록
    MANAGEMENT_NO_LIST: 'MANAGEMENT_NO_LIST',

    IP_LIST: 'IP_LIST',
    IP_INFO: 'IP_INFO',
    DESIGN_INFO: 'DESIGN_INFO',

    SEARCH_OBJECTIVE: 'SEARCH_OBJECTIVE',
    SEARCH_ENDPOINT: 'SEARCH_ENDPOINT',

    FORM_LIST: 'FORM_LIST',
    FORM_UPDATE: 'FORM_UPDATE',
    ADD_VERSION: 'ADD_VERSION',

    PROTOCOL_VERSION: 'PROTOCOL_VERSION',

    //SDTM > Mapping > DataSets
    SEARCH_DOMAIN: 'SEARCH_DOMAIN'
};


/**
 *  @type      {function(*): *}
 *  @function  processAgent - 공통 적인 통신 처리를 하는 함수(기본적인 CRUD 처리)
 *  @param     {string} command - 요청 command
 *  @param     {string} params - 요청에 필요한 params
 *  @return    {*} response.data - 서버로부터 받아온 응답 데이터
 */
export const processAgent = async (command, params) => {

    // 응답 데이터
    let response = null;

    switch (command) {
        // 데이터 리스트 요청
        case COMMAND.DATA_LIST : {
            // 요청 parameter 객체들
            const {requestUrl, inPage, searchObject, pageSize, sortType} = params;

            let url = `${requestUrl}`;

            // 검색 데이터가 있는 경우 추가
            if (isDataExist(inPage)) {
                url = `${requestUrl}?page=${inPage}`
            }

            // 목록 표시 갯수 지정할 경우 추가
            if (isDataExist(pageSize)) {
                url += `&pageSize=${pageSize}`;
            }

            if (isDataExist(searchObject)) {
                // 검색 단어 있을 경우
                const getSearchParam = () => {
                    let searchType = Object.keys(searchObject);

                    return searchType.map((type) => {
                        return `${type}=${searchObject[type].replace(/&/g, "%26").replace(/\+/g, "%2B").replace(/\\/i, "")}`;
                    })
                };
                url = `${url}&${getSearchParam().join('&')}`;
            }

            if (isDataExist(sortType) && sortType) {
                url += `&sortType=1`;
            }

            // 데이터 처리 응답
            response = await AXIOS_GET(url, 600000);
        }
            break;

        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO : {
            const {requestUrl, ID} = params;
            const url = `${requestUrl}/${ID}`;

            // 데이터 처리 응답
            response = await AXIOS_GET(url);
        }
            break;

        // 데이터 생성 요청
        case COMMAND.DATA_CREATE : {
            // 요청 parameter 객체들
            const {requestUrl, sendObject} = params;

            // sendObject가 formData 형식으로 들어왔을 시, POST_FORM으로 처리
            if (sendObject instanceof FormData) {
                // 데이터 처리 응답
                response = await AXIOS_POST_FORM(requestUrl, sendObject);
            }
            // string 형식이라면 일반 POST로 처리
            else {
                // 데이터 처리 응답
                response = await AXIOS_POST(requestUrl, sendObject);
                console.log(requestUrl, sendObject);
                console.log(response);
            }
        }
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE : {
            // 요청 parameter 객체들
            const {requestUrl, ID, sendObject} = params;

            const url = `${requestUrl}/${ID}`;

            // sendObject가 formData 형식으로 들어왔을 시, POST_FORM으로 처리
            if (sendObject instanceof FormData) {
                response = await AXIOS_PUT_FORM(url, sendObject);
            } else {
                if (isDataExist(sendObject)) {
                    response = await AXIOS_PUT(url, sendObject);
                } else {
                    response = await AXIOS_PUT(url);
                }
            }
        }
            break;

        // 데이터 삭제 요청
        case COMMAND.DATA_DELETE : {
            const {requestUrl, ID} = params;
            const url = `${requestUrl}/${ID}`;
            // 데이터 처리 응답
            response = await AXIOS_DELETE(url);
        }
            break;

        // 데이터 lock 요청
        case COMMAND.DATA_LOCK : {
            // 요청 parameter 객체들
            const {requestUrl, id, sendObject, type} = params;

            //  데이터 요청 url
            const url = `${requestUrl}/${id}?type=${type}`;

            // 데이터 처리 응답
            response = await AXIOS_PUT(url, sendObject);
            console.log(response);
        }
            break;

        // 파일 업로드 요청
        case COMMAND.FILE_UPLOAD : {
            // 요청 parameter 객체들
            const {requestUrl, sendObject} = params;

            //  데이터 요청 url
            const url = requestUrl;

            // 데이터 처리 응답
            response = await AXIOS_POST_FORM(url, sendObject);
        }
            break;

        case COMMAND.PARTNER_CODELIST : {
            const url = `${PARTNER_URL}?type=code`;
            response = await AXIOS_GET(url);

        }
            break;

        case COMMAND.DOMAIN_CODELIST : {
            const url = `${SDTM_URL}?type=domain`;
            response = await AXIOS_GET(url);
        }
            break;

        case COMMAND.VARIABLE_CODELIST : {
            const {requestUrl, Domain} = params;
            const url = `${requestUrl}?type=variable&domain=${Domain}`;
            // 데이터 처리 응답
            response = await AXIOS_GET(url);
        }
            break;

        // 데이터 copy 처리 요청
        case COMMAND.DATA_COPY : {
            // 요청 parameter 객체들
            const {requestUrl, ID} = params;

            //  데이터 요청 url
            const url = `${requestUrl}/${ID}`;

            // 데이터 처리 응답
            response = await AXIOS_POST(url);
        }
            break;

        // member list 요청
        case COMMAND.GROUP_IN_LIST : {
            // 요청 parameter 객체들
            const {requestUrl, ID} = params;

            //  데이터 요청 url 설정
            let url = `${requestUrl}/${ID}`;

            // 데이터 처리 응답
            response = await AXIOS_GET(url);
        }
            break;

        // out of member or group 요청
        case COMMAND.GROUP_OUT_LIST : {
            // 요청 parameter 객체들
            const {requestUrl, ID, searchName} = params;

            //  데이터 요청 url 설정
            let url = `${requestUrl}/${ID}/member?`;

            // 검색 데이터가 있는 경우 추가
            if (searchName !== undefined) {
                // 검색 단어 있을 경우
                const getSearchParam = () => {
                    let searchType = Object.keys(searchName);

                    return searchType.map((type) => {
                        return `${type}=${searchName[type]}`;
                    })
                };
                url = `${url}${getSearchParam().join('&')}`;
            }

            // 데이터 처리 응답
            response = await AXIOS_GET(url);
        }
            break;

        // member list 저장
        case COMMAND.GROUP_SAVE : {
            // 요청 parameter 객체들
            const {requestUrl, ID, sendObject} = params;

            const url = `${requestUrl}/${ID}/member`;

            // 데이터 처리 응답
            response = await AXIOS_PUT(url, sendObject);
        }
            break;

        default:
            return null;
    }

    return response.data;
};
