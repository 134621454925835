import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AXIOS_GET, copyObject, isDataExist} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import {DESIGN_SYNOPSIS_URL} from "constant/ConstantURL";
import NetworkLayout from "common/NetworkLayout";
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";
import Checkbox from "imtrial/components/Checkbox";
import InputMask from 'react-input-mask';
import {produce} from "immer";
import PerfectScrollbar from "react-perfect-scrollbar";
import {PAGE} from "../../../../constant/CommonConstant";
import AUIGrid from "../../../components/AUIGrid";
import PagingUtil from "../../../components/PagingUtil";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Array} STUDY_TYPE_LIST
 *  @description  study 항목 카테고리 옵션 리스트
 */
const STUDY_TYPE_LIST = [
    {
        text: 'Interventional Studies (Clinical Trials)',
        value: 'Intr'
    },
    {
        text: 'Observational Studies',
        value: 'Obsr'
    },
    {
        text: '-- Patient Registries',
        value: 'PReg'
    },
    {
        text: 'Expanded Access Studies',
        value: 'Expn'
    },
];

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Array} STUDY_RESULT_LIST
 *  @description  study result 항목 카테고리 옵션
 */
const STUDY_RESULT_LIST = [
    {
        text: 'Studies With Results',
        value: 'Studies With Results'
    },
    {
        text: 'Studies Without Results',
        value: 'Studies Without Results'
    },
];

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} NAME
 *  @description  데이터 field define
 */
export const NAME = {
    CONDITION_OR_DISEASE: 'condition_or_disease',
    OTHER_TERMS: 'other_terms',
    STUDY_TYPE: 'study_type',
    STUDY_RESULTS: 'study_results',

    ACCEPTS_HEALTHY_VOLUNTEERS: 'accepts_healthy_volunteers',

    INTERVENTION_TREATMENT: 'intervention_treatment',
    OUTCOME_MEASURE: 'outcome_measure',
    SPONSOR_COLLABORATOR: 'sponsor_collaborator',

    EARLY_PHASE_1: 'early_phase_1',
    PHASE_1: 'phase_1',
    PHASE_2: 'phase_2',
    PHASE_3: 'phase_3',
    PHASE_4: 'phase_4',
    NOT_APPLICABLE: 'not_applicable',

    STUDY_PROTOCOLS: 'study_protocols',
    STATISTICAL_ANALYSIS_PLANS: 'statistical_analysis_plans',
    INFORMED_CONSENT_FORMS: 'informed_consent_forms',

    STUDY_START_FROM: 'study_start_from',
    STUDY_START_TO: 'study_start_to',
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} DEFAULT_DATA_OBJECT
 *  @description  데이터 기본 default 값
 */
const DEFAULT_DATA_OBJECT = {
    condition_or_disease: '',
    other_terms: '',
    study_type: '',
    study_results: '',

    accepts_healthy_volunteers: false,

    intervention_treatment: '',
    outcome_measure: '',
    sponsor_collaborator: '',

    early_phase_1: false,
    phase_1: false,
    phase_2: false,
    phase_3: false,
    phase_4: false,
    not_applicable: false,

    study_protocols: false,
    statistical_analysis_plans: false,
    informed_consent_forms: false,

    study_start_from: '',
    study_start_to: '',
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} CLINICALTRIALS_KEYWORD
 *  @description  clinical trial gov 검색 시 검색조건 field define
 */
const CLINICALTRIALS_KEYWORD = {
    condition_or_disease: 'cond',
    conditionOrDisease: 'cond',
    other_terms: 'term',
    study_type: 'type',
    study_results: 'rslt',

    accepts_healthy_volunteers: 'hlth=Y',

    intervention_treatment: 'intr',
    outcome_measure: 'outc',
    sponsor_collaborator: 'spons',

    early_phase_1: 'phase=4',
    phase_1: 'phase=0',
    phase_2: 'phase=1',
    phase_3: 'phase=2',
    phase_4: 'phase=3',
    not_applicable: 'phase=5',

    study_protocols: 'u_prot=Y',
    statistical_analysis_plans: 'u_sap=Y',
    informed_consent_forms: 'u_icf=Y',

    study_start_from: 'strd_s',
    study_start_to: 'strd_e',
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} CHECKED_VALUE
 *  @description  항목에 필요한 선택 옵션 value define
 */
const CHECKED_VALUE = {
    accepts_healthy_volunteers: 'accept healthy volunteers',

    early_phase_1: 'Phase Early Phase 1',
    phase_1: 'Phase 1', // early_phase_1 선택이 없으면 Phase+1
    phase_2: 'Phase 2',
    phase_3: 'Phase 3',
    phase_4: 'Phase 4',
    not_applicable: 'Not Applicable',

    study_protocols: 'Study Protocols',
    statistical_analysis_plans: 'Statistical Analysis Plans (SAPs)',
    informed_consent_forms: 'Informed Consent Forms (ICFs)',
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {String} CLINICALTRIALS_GOV
 *  @description  clinicaltrials.gov 검색 url
 */
const CLINICALTRIALS_GOV = 'https://clinicaltrials.gov/ct2/';

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {String} DEFAULT_CAPTION
 *  @description  select box 에 표시할 caption 문구
 */
const DEFAULT_CAPTION = '－－－－【 All Studies 】－－－－';

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} META_TYPE
 *  @description  서버에 요청할 META 데이터 관련 정보
 */
const META_TYPE = {
    INDICATION: 'condition_or_disease',
    PHASE: 'phase',
    HEALTH: 'healthyIndication',
    SPONSOR: 'sponsor',
    OUTCOME_MEASURES: 'outcomeMeasures',
    INTERVENTION: 'intervention'
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} PHASE_MAPPING
 *  @description  서버에 요청할 META 데이터 관련 정보
 */
const PHASE_MAPPING = {
    '0': NAME.EARLY_PHASE_1,
    '1': NAME.PHASE_1,
    '2': NAME.PHASE_1,
    '3': NAME.PHASE_2,
    '4': NAME.PHASE_2,
    '5': NAME.PHASE_2,
    '6': NAME.PHASE_2,
    '7': NAME.PHASE_3,
    '8': NAME.PHASE_3,
    '9': NAME.PHASE_3,
    '10': NAME.PHASE_4,
    '11': NAME.NOT_APPLICABLE,
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} CHAIN
 *  @description  검색어 연결 표시
 */
const CHAIN = {
    AND: ' AND ',
    OR: ' OR ',
    SPACE: ' ',
}

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Object} DATE_EXPRESSION
 *  @description  날짜 검색어 지정시 사용
 */
const DATE_EXPRESSION = {
    FROM_REPLACE: '##FROM##',
    TO_REPLACE: '##TO##',
    FROM_ONLY: 'AREA[StartDate]RANGE[##FROM##, MAX]',
    TO_ONLY: 'AREA[StartDate]RANGE[MIN, ##TO##]',
    FROM_TO: 'AREA[StartDate]RANGE[##FROM##, ##TO##]'
};

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @constant     {Array} COLUMN_LIST
 *  @description  AUIGrid Column 정보
 */
const COLUMN_LIST = [
    {dataField: "no", headerText: "No.", width: "10%"},
    {dataField: "studyTitle", headerText: "Study Title", width: "20%"},
    {dataField: "conditions", headerText: "Conditions", width: "20%",},
    {dataField: "interventions", headerText: "Interventions", width: "20%",},
    {dataField: "phase", headerText: "Phase", width: "10%",},
    {dataField: "outcomeMeasures", headerText: "Outcome Measures", width: "20%",},
    {dataField: "studyStart", headerText: "Study Start", width: "20%",},
];

/**
 *  @memberOf     ClinicalTrialPopupTab
 *  @async        dataProcess
 *  @param        {Array} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param        {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return       {Object} response.data - back-end 응답
 *  @description  back-end 와 통신 하는 비지니스 로직 함수
 */
async function dataProcess(command, params) {
    const {requestUrl, dataID, inPage, expr, pageSize} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 검색 요청
        case COMMAND.DATA_LIST :
            url = `${requestUrl}/gov?page=${inPage}&expr=${expr}&pageSize=${pageSize}`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 메타 정보 요청
        case COMMAND.DATA_INFO :
            url = `${requestUrl}/meta/clinical/${dataID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @component    ClinicalTrialPopupTab
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Reference 팝업 내 Clinicai.gov 화면 컴포넌트
 */
const ClinicalTrialPopupTab = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} designID - 디자인 리스트 ID
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, designID} = props;

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {*} networkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    ////////////////////////////////////////////////////////////////
    // list 관련
    ////////////////////////////////////////////////////////////////
    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Array} endpoint
     *  @description  endpoint 항목에 표시 될 리스트
     */
    const [endpoint, setEndpoint] = useState([]);

    /**
     *  @memberOf     Concept
     *  @var          {Array} outcomeList
     *  @description  outcome 항목에 표시 될 리스트
     */
    const [outcomeList] = useState([]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Number} totalCount
     *  @description  리스트 전체 갯수
     */
    const [totalCount, setTotalCount] = useState(0);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Number} pageNo
     *  @description  현재 페이지 번호
     */
    const [pageNo, setPageNo] = useState(1);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Number} pageSize
     *  @description  화면에 표시 될 리스트 갯수(10/25/50)
     */
    const [pageSize, setPageSize] = useState(10);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @var          {Object} searchObject
     *  @description  검색 내용 저장
     */
    const [searchObject, setSearchObject] = useState(DEFAULT_DATA_OBJECT);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleChange
     *  @param        {String} name   - 입력 요소의 tag name
     *  @param        {String} value  - 입력 값
     *  @description  input 필드 변경시 실행되는 함수
     */
    const handleChange = useCallback((name, value) => {
        setSearchObject(
            produce(
                searchObject, draft => {
                    draft[name] = value
                }
            )
        );
    }, [searchObject]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleChangePageSize
     *  @param        {String} name   - 입력 요소의 tag name
     *  @param        {Number} targetValue - 리스트 표시 될 갯수 (10/25/50)
     *  @description  화면에 보여질 리스트 갯수 변경 시 실행되어 data list 조회 api 호출
     */
    const handleChangePageSize = useCallback((name, targetValue) => {
        setPageSize(targetValue);
    }, []);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     getSearchString
     *  @return       {String} searchString - 검색어
     *  @description  검색 입력 내용을 검색 uri 로 변경
     */
    const getSearchString = useCallback(() => {
        const dataKeys = Object.keys(searchObject);
        // 검색 데이터
        let searchDataList = [];
        // phase
        let phaseData = [];
        // study Document
        let studyDoc = [];
        // 날짜 데이터
        let studyDateData = {};
        // let conditionTempList = conditionList.map((condition => condition.value));
        let outcomeTempList = outcomeList.map((outcome => outcome.value));

        for (const fieldKey of dataKeys) {
            const fieldValue = searchObject[fieldKey];
            // 체크 박스 인 경우
            if (CHECKED_VALUE.hasOwnProperty(fieldKey)) {
                // 설정된 경우
                if (fieldValue === true || fieldValue === 1) {
                    switch (fieldKey) {
                        case NAME.EARLY_PHASE_1 :
                        case NAME.PHASE_1 :
                        case NAME.PHASE_2 :
                        case NAME.PHASE_3 :
                        case NAME.PHASE_4 :
                        case NAME.NOT_APPLICABLE :
                            phaseData.push(CHECKED_VALUE[fieldKey]);
                            break;

                        case NAME.STUDY_PROTOCOLS:
                        case NAME.STATISTICAL_ANALYSIS_PLANS:
                        case NAME.INFORMED_CONSENT_FORMS:
                            studyDoc.push(CHECKED_VALUE[fieldKey]);
                            break;

                        default:
                            searchDataList.push(CHECKED_VALUE[fieldKey]);
                            break;
                    }
                }
            }
            // 일반 값인 경우
            else {
                // 값이 있는 경우에만 데이터 push
                if (isDataExist(fieldValue) === true) {
                    switch (fieldKey) {
                        case NAME.STUDY_START_FROM:
                        case NAME.STUDY_START_TO:
                            studyDateData[fieldKey] = fieldValue;
                            break;

                        case NAME.OUTCOME_MEASURE:
                            outcomeTempList.push(fieldValue);
                            break;

                        default:
                            let transValue = fieldValue.replace(/&/g, "%26").replace(/\+/g, "%2B");
                            searchDataList.push(transValue);
                            break;
                    }
                }
            }
        }
        if (isDataExist(outcomeTempList)) {
            const fieldValueStr = outcomeTempList.join(CHAIN.AND);
            searchDataList.push(fieldValueStr);
        }
        //phase 값
        if (isDataExist(phaseData) === true) {
            const phaseStr = phaseData.join(CHAIN.OR);
            searchDataList.push(phaseStr);
        }
        // study Document
        if (isDataExist(studyDoc) === true) {
            const studyDocStr = studyDoc.join(CHAIN.OR);
            searchDataList.push(studyDocStr);
        }
        // date check
        // from to 모두 있는 경우
        if (studyDateData.hasOwnProperty(NAME.STUDY_START_FROM) && studyDateData.hasOwnProperty(NAME.STUDY_START_TO)) {
            let dateExp = DATE_EXPRESSION.FROM_TO;
            dateExp = dateExp.replace(DATE_EXPRESSION.FROM_REPLACE, studyDateData[NAME.STUDY_START_FROM]);
            dateExp = dateExp.replace(DATE_EXPRESSION.TO_REPLACE, studyDateData[NAME.STUDY_START_TO]);
            searchDataList.push(dateExp);
        }
        // from 만 있는 경우
        else if (studyDateData.hasOwnProperty(NAME.STUDY_START_FROM)) {
            let dateExp = DATE_EXPRESSION.FROM_ONLY;
            dateExp = dateExp.replace(DATE_EXPRESSION.FROM_REPLACE, studyDateData[NAME.STUDY_START_FROM]);
            searchDataList.push(dateExp);
        }
        // to 만 있는 경우
        else if (studyDateData.hasOwnProperty(NAME.STUDY_START_TO)) {
            let dateExp = DATE_EXPRESSION.TO_ONLY;
            dateExp = dateExp.replace(DATE_EXPRESSION.TO_REPLACE, studyDateData[NAME.STUDY_START_TO]);
            searchDataList.push(dateExp);
        }

        //  검색어 만들기
        const searchString = searchDataList.join(CHAIN.AND);

        return searchString;
    }, [outcomeList, searchObject]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     getQueryString
     *  @return       {String} searchString - 검색어
     *  @description  검색 입력 내용을 검색 쿼리 스트링으로 변경
     */
    const getQueryString = useCallback(() => {
        const dataKeys = Object.keys(searchObject);
        // 검색 데이터
        let searchDataList = [];

        for (const fieldKey of dataKeys) {
            const fieldValue = searchObject[fieldKey];
            // 체크 박스 인 경우
            if (CHECKED_VALUE.hasOwnProperty(fieldKey)) {
                // 설정된 경우
                if (fieldValue === true || fieldValue === 1) {
                    switch (fieldKey) {
                        case NAME.EARLY_PHASE_1 :
                        case NAME.PHASE_1 :
                        case NAME.PHASE_2 :
                        case NAME.PHASE_3 :
                        case NAME.PHASE_4 :
                        case NAME.NOT_APPLICABLE :
                        case NAME.STUDY_PROTOCOLS:
                        case NAME.STATISTICAL_ANALYSIS_PLANS:
                        case NAME.INFORMED_CONSENT_FORMS:
                        case NAME.ACCEPTS_HEALTHY_VOLUNTEERS:
                            searchDataList.push(CLINICALTRIALS_KEYWORD[fieldKey]);
                            break;

                        //no default
                    }
                }
            }
            // 일반 값인 경우
            else {
                // 값이 있는 경우에만 데이터 push
                if (isDataExist(fieldValue) === true) {
                    switch (fieldKey) {
                        case NAME.OUTCOME_MEASURE: {
                            let tempList = copyObject(outcomeList);
                            if (isDataExist(searchObject[NAME.OUTCOME_MEASURE]) === true) {
                                tempList.push(searchObject[NAME.OUTCOME_MEASURE]);
                            }
                            const fieldValueStr = tempList.join(CHAIN.AND);
                            let transValue = fieldValueStr.replace(/&/g, "%26").replace(/\+/g, "%2B");
                            const settingValue = `${CLINICALTRIALS_KEYWORD[fieldKey]}=${transValue}`;
                            searchDataList.push(settingValue);
                        }
                            break;

                        default: {
                            let transValue = fieldValue.replace(/&/g, "%26").replace(/\+/g, "%2B");
                            const settingValue = `${CLINICALTRIALS_KEYWORD[fieldKey]}=${transValue}`;
                            searchDataList.push(settingValue);
                        }
                            break;
                    }
                }
            }
        }
        //  검색어 만들기
        const searchString = searchDataList.join("&");

        return searchString;
    }, [outcomeList, searchObject]);


    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleClickListItem
     *  @param        {String} oid - 클릭한 항목의 ID
     *  @param        {String} link- 항목으로 이동할 수 있는 link
     *  @description  list item 클릭 시 처리 함수
     */
    const handleClickListItem = useCallback((oid, link) => {
        const popupWidth = window.innerWidth * 1.00;
        const popupHeight = window.innerHeight * 1.00;

        // 듀얼 모니터 기준
        let left = (window.screen.availWidth - popupWidth) / 2;
        if (window.screenLeft < 0) {
            left += window.screen.width * -1;
        } else if (window.screenLeft > window.screen.width) {
            left += window.screen.width;
        }

        let top = (window.screen.availHeight - popupHeight) / 2 - 10;

        let govlink = '';

        if (link.includes('undefined')) {
            const newLink = link.split('undefined')[1];
            govlink = `${CLINICALTRIALS_GOV}show/${newLink}`;
        } else {
            govlink = `${CLINICALTRIALS_GOV}show/${link}`;
        }

        window.open(govlink, '_gov_popup', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}, menubar=no, status=no, toolbar=no`);
    }, []);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleMoveClinicalTrials
     *  @description  ClinicalTrials 클릭 시 해당 사이트로 이동
     */
    const handleMoveClinicalTrials = useCallback(() => {
        const popupWidth = window.innerWidth * 1.00;
        const popupHeight = window.innerHeight * 1.00;

        // 듀얼 모니터 기준
        let left = (window.screen.availWidth - popupWidth) / 2;
        if (window.screenLeft < 0) {
            left += window.screen.width * -1;
        } else if (window.screenLeft > window.screen.width) {
            left += window.screen.width;
        }

        let top = (window.screen.availHeight - popupHeight) / 2 - 10;

        const queryString = getQueryString();
        const link = `${CLINICALTRIALS_GOV}results?${queryString}`;

        window.open(link, '_gov_popup', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}, menubar=no, status=no, toolbar=no`);
    }, [getQueryString]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleMove
     *  @param        {Number} page - 이동할 페이지 번호
     *  @description  페이지 이동시 실행되어 data list 조회 api 호출
     */
    const handleMove = useCallback((page) => {
        // 데이터 리스트 요청 command
        const command = COMMAND.DATA_LIST;
        // search 객체 형태로 보내주기 => subject로 찾기
        const searchString = getSearchString();
        // 검색에 필요한 parameter 셋팅
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            inPage: page,
            expr: searchString,
            pageSize: pageSize
        };

        // back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, [getSearchString, pageSize]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     handleSearch
     *  @description  일반 검색버튼 클릭 시 실행되어 data list 조회 api 호출
     */
    const handleSearch = useCallback(() => {
        // 데이터 리스트 요청 command
        const command = COMMAND.DATA_LIST;
        // search 객체 형태로 보내주기 => subject로 찾기
        const searchString = getSearchString();
        // 검색에 필요한 parameter 셋팅
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            inPage: 1,
            expr: searchString,
            pageSize: pageSize
        };

        // back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, [getSearchString, pageSize]);

    /**
     *  @memberOf     ClinicalTrialPopupTab
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    // 데이터가 없는 경우
                    if (data.hasOwnProperty('rows')) {
                        setDataList([]);
                        setPageNo(1);
                        setTotalCount(0);
                    }

                    // list 데이터가 있는 경우
                    if (Array.isArray(data.data)) {
                        setDataList(data.data);
                    } else {
                        setDataList([]);
                    }

                    // page 번호가 있는 경우
                    if (data.hasOwnProperty('page')) {
                        setPageNo(data.page);
                    }

                    // 총 레코드 수가 있는 경우
                    if (data.hasOwnProperty('total')) {
                        setTotalCount(data.total);
                    }
                    break;

                case COMMAND.DATA_INFO :
                    if (data.hasOwnProperty('data')) {
                        const responseData = data.data;
                        //  업데이트 되는 속성
                        let updateAttr = [];
                        for (const responseDataKey in responseData) {
                            switch (responseDataKey) {
                                case 'conditionOrDisease' : {
                                    let responseList = [];

                                    for (const row of responseData[responseDataKey]) {
                                        responseList.push(row.description);
                                    }

                                    let condiData = responseList.join(CHAIN.OR);
                                    updateAttr.push({
                                        name: NAME.CONDITION_OR_DISEASE,
                                        value: condiData
                                    });

                                }
                                    break;

                                case META_TYPE.OUTCOME_MEASURES: {
                                    let responseList = [];

                                    for (const row of responseData[responseDataKey]) {
                                        let responseUnit = {
                                            text: row,
                                            value: row,
                                        };
                                        responseList.push(responseUnit);
                                    }
                                    setEndpoint(responseList);
                                }
                                    break;

                                case META_TYPE.HEALTH:
                                    let healthyCheck = false;
                                    if (responseData[responseDataKey] === 1) {
                                        healthyCheck = true;
                                    }
                                    updateAttr.push({
                                        name: NAME.ACCEPTS_HEALTHY_VOLUNTEERS,
                                        value: healthyCheck
                                    });
                                    break;

                                case META_TYPE.PHASE:
                                    const phaseValue = responseData[responseDataKey];
                                    let phaseName = '';
                                    if (PHASE_MAPPING.hasOwnProperty(phaseValue)) {
                                        phaseName = PHASE_MAPPING[phaseValue];
                                    }
                                    if (isDataExist(phaseName)) {
                                        updateAttr.push({
                                            name: phaseName,
                                            value: true
                                        });
                                    }
                                    break;

                                case META_TYPE.SPONSOR: {
                                    let responseList = [];
                                    for (const row of responseData[responseDataKey]) {
                                        responseList.push(row.description);
                                    }
                                    let sponsorData = responseList.join(CHAIN.OR);
                                    updateAttr.push({
                                        name: NAME.SPONSOR_COLLABORATOR,
                                        value: sponsorData
                                    });
                                }
                                    break;

                                case META_TYPE.INTERVENTION : {
                                    let responseList = [];
                                    if (responseData[responseDataKey].includes("####")) {
                                        responseList = responseData[responseDataKey].split("####");
                                    } else {
                                        responseList.push(responseData[responseDataKey]);
                                    }
                                    let interventionData = responseList.join(CHAIN.OR);
                                    updateAttr.push({
                                        name: NAME.INTERVENTION_TREATMENT,
                                        value: interventionData
                                    });
                                }
                                    break;

                                //no default
                            }
                        } // end for

                        if (isDataExist(updateAttr)) {
                            setSearchObject(
                                produce(
                                    searchObject, draft => {
                                        for (const updateElement of updateAttr) {
                                            draft[updateElement.name] = updateElement.value;
                                        }
                                    }
                                )
                            );
                        }
                    } else {
                        setEndpoint([]);
                    }
                    break;

                // no default
            }
        }
    }, [searchObject]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // back end 데이터 리스트 호출
    useEffect(() => {
        // 데이터 리스트 요청 command
        const command = COMMAND.DATA_INFO;
        // parameter 설정
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            dataID: designID,
        };
        // back-end 데이터 처리 요청
        netWorkAgent.current.request(command, params);
    }, [designID]);

    useEffect(() => {
        if (isDataExist(endpoint)) {
            setSearchObject(prevState => ({...prevState, 'outcome_measure': endpoint[0].value}));
        }
    }, [endpoint]);
    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="d-flex justify-content-between m-t-20" style={{height: "calc(100vh - 129px)"}}>

                {/* left */}
                <div className="vertical-box with-grid inbox bg-light">
                    <div className="vertical-box-column bg-light">
                        <div className="vertical-box">
                            <div className="">
                                <div className="vertical-box-cell">
                                    <div className="vertical-box-inner-cell bg-white wrapper"
                                         style={{height: "calc(100vh - 129px)"}}>
                                        <PerfectScrollbar className="p-20" options={{suppressScrollX: true}}>
                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Condition or disease :</span>
                                                </label>

                                                <div className="col-9">
                                                    <TextField name={NAME.CONDITION_OR_DISEASE}
                                                               currentValue={searchObject[NAME.CONDITION_OR_DISEASE]}
                                                               onChange={handleChange}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Other terms :</span>
                                                </label>
                                                <div className="col-9">
                                                    <TextField
                                                        name={NAME.OTHER_TERMS}
                                                        onChange={handleChange}
                                                        currentValue={searchObject[NAME.OTHER_TERMS]}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Study type :</span>
                                                </label>
                                                <div className="col-9">
                                                    <SelectBox
                                                        name={NAME.STUDY_TYPE}
                                                        dataList={STUDY_TYPE_LIST}
                                                        currentValue={searchObject[NAME.STUDY_TYPE]}
                                                        onChange={handleChange}
                                                        defaultCaption={DEFAULT_CAPTION}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Study Results :</span>
                                                </label>
                                                <div className="col-9">
                                                    <SelectBox
                                                        name={NAME.STUDY_RESULTS}
                                                        dataList={STUDY_RESULT_LIST}
                                                        currentValue={searchObject[NAME.STUDY_RESULTS]}
                                                        onChange={handleChange}
                                                        defaultValue={STUDY_TYPE_LIST[0].value}
                                                        defaultCaption={DEFAULT_CAPTION}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Accepts Healthy Volunteers :</span>
                                                </label>
                                                <div className="col-9">
                                                    <Checkbox
                                                        name={NAME.ACCEPTS_HEALTHY_VOLUNTEERS}
                                                        onChange={handleChange}
                                                        disabled={false}
                                                        currentValue={searchObject[NAME.ACCEPTS_HEALTHY_VOLUNTEERS]}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Intervention/treatment :</span>
                                                </label>
                                                <div className="col-9">
                                                    <TextField
                                                        name={NAME.INTERVENTION_TREATMENT}
                                                        onChange={handleChange}
                                                        currentValue={searchObject[NAME.INTERVENTION_TREATMENT]}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Outcome Measure :</span>
                                                </label>
                                                <div className="col-9">
                                                    <SelectBox
                                                        name={NAME.OUTCOME_MEASURE}
                                                        dataList={endpoint}
                                                        currentValue={searchObject[NAME.OUTCOME_MEASURE]}
                                                        onChange={handleChange}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Sponsor / Collaborator :</span>
                                                </label>
                                                <div className="col-9">
                                                    <TextField
                                                        name={NAME.SPONSOR_COLLABORATOR}
                                                        onChange={handleChange}
                                                        currentValue={searchObject[NAME.SPONSOR_COLLABORATOR]}/>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Phase :</span>
                                                </label>
                                                <div className="col-9">
                                                    <div className="row">
                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.EARLY_PHASE_1}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.EARLY_PHASE_1]}
                                                                label={"Early Phase 1"}/>
                                                        </div>

                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.PHASE_1}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.PHASE_1]}
                                                                label={"Phase 1"}/>
                                                        </div>

                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.PHASE_2}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.PHASE_2]}
                                                                label={"Phase 2"}/>
                                                        </div>

                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.PHASE_3}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.PHASE_3]}
                                                                label={"Phase 3"}/>
                                                        </div>
                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.PHASE_4}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.PHASE_4]}
                                                                label={"Phase 4"}/>
                                                        </div>
                                                        <div className="col-4 m-b-10">
                                                            <Checkbox
                                                                name={NAME.NOT_APPLICABLE}
                                                                onChange={handleChange}
                                                                disabled={false}
                                                                currentValue={searchObject[NAME.NOT_APPLICABLE]}
                                                                label={"Not Applicable"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Study Documents :</span>
                                                </label>
                                                <div className="col-9">
                                                    <div className="m-b-10">
                                                        <Checkbox
                                                            name={NAME.STUDY_PROTOCOLS}
                                                            onChange={handleChange}
                                                            disabled={false}
                                                            currentValue={searchObject[NAME.STUDY_PROTOCOLS]}
                                                            label={"Study Protocols"}/>
                                                    </div>

                                                    <div className="m-b-10">
                                                        <Checkbox
                                                            name={NAME.STATISTICAL_ANALYSIS_PLANS}
                                                            onChange={handleChange}
                                                            disabled={false}
                                                            currentValue={searchObject[NAME.STATISTICAL_ANALYSIS_PLANS]}
                                                            label={"Statistical Analysis Plans(SAPs)"}/>
                                                    </div>
                                                    <div className="m-b-10">
                                                        <Checkbox
                                                            name={NAME.INFORMED_CONSENT_FORMS}
                                                            onChange={handleChange}
                                                            disabled={false}
                                                            currentValue={searchObject[NAME.INFORMED_CONSENT_FORMS]}
                                                            label={"Informed Consent Forms(ICFs)"}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center m-b-20">
                                                <label className="col-3">
                                                    <span>Study Start :</span>
                                                </label>
                                                <div className="col-9">
                                                    <div className="row">
                                                        <div
                                                            className="col-12 d-flex justify-content-around align-items-center">
                                                            <div className="pt-0">From</div>
                                                            <InputMask
                                                                name={NAME.STUDY_START_FROM}
                                                                value={searchObject[NAME.STUDY_START_FROM]}
                                                                onChange={(e) => {
                                                                    handleChange(NAME.STUDY_START_FROM, e.target.value)
                                                                }}
                                                                mask="99/99/9999"
                                                                placeholder="MM/DD/YYYY"/>
                                                            <div className="pt-0">To</div>
                                                            <InputMask
                                                                value={searchObject[NAME.STUDY_START_TO]}
                                                                onChange={(e) => {
                                                                    handleChange(NAME.STUDY_START_TO, e.target.value)
                                                                }}
                                                                mask="99/99/9999"
                                                                placeholder="MM/DD/YYYY"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* right */}
                <div className="vertical-box with-grid bg-light m-l-10">
                    <div className="vertical-box-column bg-light">
                        <div className="vertical-box">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell bg-white wrapper">
                                    <div className="my-2 text-right">
                                        <button
                                            className="btn btn-blue"
                                            onClick={handleMoveClinicalTrials}>
                                            Move to ClinicalTrials.gov
                                        </button>
                                    </div>
                                    <PerfectScrollbar
                                        style={{height: "calc(100vh - 260px)"}}
                                        options={{suppressScrollX: true}}>

                                        <AUIGrid
                                            dataList={dataList}
                                            columnList={COLUMN_LIST}
                                            onClick={handleClickListItem}/>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                            <div className="bg-light border-top p-t-10">
                                <PagingUtil
                                    rowCount={totalCount}
                                    currentPage={pageNo}
                                    pageSize={pageSize}
                                    blockSize={PAGE.COUNT_PER_BLOCK}
                                    onMove={handleMove}
                                    dataList={dataList}
                                    onChange={handleChangePageSize}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end p-10 border-top bg-light">
                <div>
                    {
                        <button
                            className="btn btn-new btn-inner-shadow text-white px-3 px-md-5 mx-1"
                            onClick={handleSearch}>
                            Search
                        </button>
                    }
                </div>
            </div>
        </>
    );
};

export default React.memo(ClinicalTrialPopupTab);
