import React, {useCallback, useState} from 'react';
import classNames from "classnames";
import {getNameByLanguage, isDataExist} from "common/commonFunction";

/**
 *  @summary   DnDListGroup 의 목록 표시 하위 컴포넌트
 *  @author    주예리나
 *  @version   1.0
 *  @see       none
 */

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @constant     {Object} initialDndState
 *  @description  dnd 상태 초기 값
 */
const initialDndState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
    itemDragged: null
};

/**
 *  @component     DnDList
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  drag and drop 리스트 컴포넌트
 */
const DnDList = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /*
    * state, props
    * 1. list             : 목록 창에 표시할 데이터 리스트
    * 2. onSetList        : 데이터 저장 시 실행될 상위 컴포넌트 이벤트 함수
    * 3. onClickItem      : 목록 아이템 클릭 시 실행될 상위 컴포넌트 이벤트 함수
    * 4. onDelete         : 삭제 버튼 클릭 시 실행 될 상위 컴포넌트 이벤트 함수
    * 5. selectedItem     : 현재 선택된 아이템 목록
    * 6. readOnly         : 읽기 전용 여부
    * 7. labelName        : 목록에 표시할 label field name
    * 8. showOptionText   : 목록 아이템에 추가로 표시할 option text 노출 여부
    * 9. showRoleText     : 목록 아이템에 추가로 표시할 role text 노출 여부
    * 10. showRoleText     : 목록 아이템에 추가로 표시할 sdtm variable text 노출 여부
    * */
    const {
        list,
        onSetList,
        onClickItem,
        onDelete,
        selectedItem,
        readOnly,
        labelName,
        showOptionText,
        showRoleText,
        showVariableText
    } = props;
    /**
     *  @memberOf     DnDList
     *  @var          {Object} dndOption
     *  @description  dnd 상태 값 설정
     */
    const [dndOption, setDndOption] = useState(initialDndState);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     DnDList
     *  @function     handleDragStart
     *  @param        {event} e -  이벤트 객체
     *  @return       none
     *  @description  drag and drop 이벤트, drag 시작 시 실행
     */
    const handleDragStart = useCallback((e) => {
        // 현재 drag 중인 요소의 position 넘버 저장...
        // e.currentTarget  = 현재 선택된 요소
        const initialPosition = Number(e.currentTarget.dataset.position);

        // dataTransfer 객체에 현재 데이터 저장
        // 사용은 안하지만 작동을 위해 넣어둠
        e.dataTransfer.setData("text/html", "");

        // dnd state 변경
        setDndOption({
            ...dndOption,
            // 시작은 drag 요소의 첫 인덱스 값
            draggedFrom: initialPosition,
            // drag 중
            isDragging: true,
            // 변경 전 초기 리스트 저장
            originalOrder: list
        });
    }, [dndOption, list]);

    /**
     *  @memberOf     DnDList
     *  @function     handleDragOver
     *  @param        {event} e -  이벤트 객체
     *  @return       none
     *  @description  drag 요소 over 시 작동
     */
    const handleDragOver = useCallback((e) => {
        e.preventDefault();

        let newList = dndOption.originalOrder;

        // drag 하는 요소의 시작 인덱스
        const draggedFrom = dndOption.draggedFrom;
        // drag 하는 요소가 새로 위치할 인덱스
        // e.currentTarget   = 현재 over 중인 요소
        const draggedTo = Number(e.currentTarget.dataset.position);

        // 현재 드래그 중인 리스트 아이템 가져오기
        const itemDragged = newList[draggedFrom];

        // 드래그 아이템 제외 나머지 리스트 아이템들 가져오기
        const remainedItems = newList.filter((item, index) => index !== draggedFrom);

        // drag 효과를 위해 list 재정렬
        newList = [
            ...remainedItems.slice(0, draggedTo), //
            itemDragged,
            ...remainedItems.slice(draggedTo)
        ];

        // 현재 over 중인 요소가 원위치와 다르다면 state 변경
        if (draggedTo !== dndOption.draggedTo) {
            setDndOption({
                ...dndOption,
                // 재정렬 된 list로 변경
                updatedOrder: newList,
                // 이동할 위치 저장
                draggedTo: draggedTo,
                itemDragged: itemDragged
            });
        }
    }, [dndOption]);

    /**
     *  @memberOf     DnDList
     *  @function     handleDrop
     *  @param        {event} e - event 객체
     *  @return       none
     *  @description  요소를 drop 했을 때 실행
     */
    const handleDrop = useCallback((e) => {
        e.preventDefault();
        // 재정렬 된 리스트 저장하기
        if (onSetList) {
            // type, 드래그 대상 아이템, 드래긓할 위치
            onSetList("CHANGE", dndOption.itemDragged, dndOption.draggedTo, dndOption.draggedFrom);
        }

        // 작동이 모두 끝났으므로 state는 다시 초기화
        setDndOption({
            ...dndOption,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false,
            itemDragged: null
        });

    }, [dndOption, onSetList]);

    /**
     *  @memberOf     DnDList
     *  @function     handleDragLeave
     *  @param        none
     *  @return       none
     *  @description  drag 지역을 벗어날 경우 실행
     */
    const handleDragLeave = useCallback(() => {
        // 지역 벗어나면 null 값 지정
        setDndOption({
            ...dndOption,
            draggedTo: null
        })
    }, [dndOption]);

    /**
     *  @memberOf     DnDList
     *  @function     handleClickItem
     *  @param        {event} e -  이벤트 객체
     *  @param        {string} OID - 클릭한 요소의 OID
     *  @param        {string} OID - 클릭한 요소의 form Name
     *  @return       none
     *  @description  버튼 클릭 시 상위 이벤트 함수 실행 후, 값 전달
     */
    const handleClickItem = useCallback((e, OID, formName) => {
        e.stopPropagation();

        if (onClickItem) {
            onClickItem(OID, formName);
        }
    }, [onClickItem]);

    /**
     *  @memberOf     DnDList
     *  @function     handleDelete
     *  @param        {event} e -  이벤트 객체
     *  @param        {string} OID - 클릭한 요소의 OID
     *  @return       none
     *  @description  삭제 버튼 눌렀을 때 실행
     */
    const handleDelete = useCallback((e, OID) => {
        e.stopPropagation();
        if (onDelete) {
            onDelete(OID);
        }
    }, [onDelete]);

    /**
     *  @memberOf     DnDList
     *  @function     isExistOptionText
     *  @param        {string} itemText - 목록 아이템 text
     *  @return       {string} option text 추가 한 아이템 text
     *  @description  showOptionText가 true 일 경우 목록 아이템에 option text 추가 표시
     */
    const isExistOptionText = useCallback((itemText) => {
        if (showOptionText !== undefined) {
            let text = itemText[showOptionText];

            if (text === "") {
                return "";
            }
            return `(${itemText[showOptionText]})`;
        }

        return "";
    }, [showOptionText]);


    /**
     *  @memberOf     DnDList
     *  @function     isExistRoleText
     *  @param        {string} itemText - 목록 아이템 text
     *  @return       {string} option text 추가 한 아이템 text
     *  @description  showRoleText true 일 경우 목록 아이템에 role text 추가 표시
     */
    const isExistRoleText = useCallback((itemText) => {
        if (showRoleText !== undefined) {
            let text = itemText[showRoleText];

            if (isDataExist(text)) {
                return `[${itemText[showRoleText]}]`;
            }
        }

        return "";
    }, [showRoleText]);

    /**
     *  @memberOf     DnDList
     *  @function     isExistVariableText
     *  @param        {string} itemText - 목록 아이템 text
     *  @return       {string} option text 추가 한 아이템 text
     *  @description  showVariable true 일 경우 목록 아이템에 sdtm variable text 추가 표시
     */
    const isExistVariableText = useCallback((itemText) => {
        if (showVariableText !== undefined) {
            let text = itemText[showVariableText];

            if (isDataExist(text)) {
                return `(${itemText[showVariableText]})`;
            }
        }

        return "";
    }, [showVariableText]);

    /**
     *  @memberOf     DnDList
     *  @function     getName
     *  @param        {string} itemText - 목록 아이템 text
     *  @return       {string} 아이템 목록에 표시할 text
     *  @description  목록 아이템에 표시할 text 값
     */
    const getName = useCallback((itemText) => {
        return `${getNameByLanguage(itemText, labelName)} ${isExistOptionText(itemText)} ${isExistRoleText(itemText)} ${isExistVariableText(itemText)}`;
    }, [isExistOptionText, isExistRoleText, isExistVariableText, labelName]);


    /**
     *  @memberOf     getFormModuleListBtn
     *  @function     getFormModuleListBtn
     *  @param        none
     *  @return       {React.Component} 버튼 컴포넌트
     *  @description  목록 리스트 반환
     */
    const getFormModuleListBtn = useCallback(() => {
        if (isDataExist(list)) {
            return list.map((item, index) => {
                return (
                    <button
                        key={index}
                        id={item.ID}
                        data-position={index}
                        style={{width: 'calc(100% - 10px)'}}
                        className={
                            classNames("btn btn-light text-break", {
                                "mx-1 rounded-lg pl-3 p-r-30 py-2 text-left mb-3  text-dark": true,
                                "cursor-move": !readOnly,
                                "active": selectedItem && selectedItem.includes(item.ID),
                                "placeholder": dndOption && dndOption.draggedTo === Number(index)
                            })}
                        onClick={(e) => handleClickItem(e, item.ID, item.name)}
                        draggable={!readOnly}
                        onDragStart={(e) => handleDragStart(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onDrop={(e) => handleDrop(e)}
                        onDragLeave={handleDragLeave}
                    >
                    <span className="d-flex align-items-center position-relative">
                        <i className="fas fa-bars p-r-15 text-dark-darker-transparent-7 h-100"/>
                        <span>{getName(item)}</span>
                        {
                            (!readOnly && onDelete !== undefined) && (
                                <i className="p-2 fas fa-times ml-1 cursor-pointer text-red-darker position-absolute f-s-14"
                                   style={{right: '-18px'}}
                                   onClick={(e) => handleDelete(e, item.ID)}/>
                            )
                        }
                    </span>

                    </button>
                )
            });
        }
    }, [list, readOnly, selectedItem, dndOption, getName, onDelete, handleClickItem, handleDragStart, handleDragOver, handleDrop, handleDragLeave, handleDelete]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div className="overflow-auto flex-grow-1 mt-2">
            <div className="d-block">
                {Array.isArray(list) && isDataExist(list) && getFormModuleListBtn()}
            </div>
        </div>
    );
};

export default React.memo(DnDList);
