import React, {useCallback, useEffect, useRef} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AUIGrid from "../../../../../components/AUIGrid";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     CTSetModal
 *  @constant     {Array} COLUMN_LIST
 *  @description  AUIGrid Column 정보
 */
const COLUMN_LIST = [
    {
        dataField: "value",
        headerText: "Value",
        editable: false
    },
    {
        dataField: "label",
        headerText: "Label",
        editable: false
    }
];

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    CTSetModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 Variable 도메인 에서 실행 되는 CT Setting 컴포넌트
 */
const CTSetModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     CTSetModal
     *  @type         {Object} props
     *  @property     {Object} event - 선택 된 줄 정보
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @property     {Function} onSave - 상위로 선택 된 Function 전달 하기 위한 함수
     *  @property     {Array} codeList - 상위에 선택 된 code list
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {event, onClose, onSave, codeList} = props;

    /**
     *  @memberOf     CTSetModal
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     CTSetModal
     *  @function     checkedValue
     *  @param        {String} ct - 선택 된 codelist 값
     *  @description  CT 칸에 값이 있다면 Codelist 리스트에서 해당 ID 체크 하는 함수
     */
    const checkedValue = useCallback((ct) => {
        if (codeList !== undefined) {
            let item = codeList.find(code => code.value === ct);

            if (item !== undefined && auiGrid.current !== null) {
                const params = {
                    num: 6,
                    value: item.value
                };

                auiGrid.current.request(params);
            }
        }
    }, [codeList]);

    /**
     *  @memberOf     CTSetModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        const params = {
            num: 4
        };

        auiGrid.current.request(params);
    }, []);

    /**
     *  @memberOf     CTSetModal
     *  @function     gridResponse
     *  @param        {Object} data - 그리드에서 선택 된 정보
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((data) => {
        //선택이 안됐을 경우는 data가 undefined
        if (data !== undefined) {
            onSave(event.dataField, event.rowIndex, data);
        }

        onClose();
    }, [event.dataField, event.rowIndex, onClose, onSave]);

    /**
     *  @memberOf     CTSetModal
     *  @function     handleClose
     *  @description  Codelist Edit 창 닫기
     */
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // ct 값이 있는지 없는지 체크 후, ct 체크 요청
    useEffect(() => {
        if (event.item.CT !== undefined && event.item.CT !== "" && event.item.CT !== null) {
            let splitCT = event.item.CT.split(",");

            checkedValue(splitCT[0]);
        }
    }, [checkedValue, event]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <Modal isOpen className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    Codelist Edit
                </ModalHeader>

                <ModalBody>
                    <AUIGrid
                        ref={auiGrid}
                        id="ct-modal-grid"
                        columnList={COLUMN_LIST}
                        dataList={codeList}
                        response={gridResponse}
                        isRadio={true}/>
                </ModalBody>

                <ModalFooter>
                    <button
                        className="btn btn-new btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleSave}>
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(CTSetModal);
