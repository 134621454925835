import React, {useCallback} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Link} from "react-router-dom";
import {getSessionState} from "../../../common/commonFunction";

/**
 *  @author         주예리나
 *  @version        1.0
 *  @component      DesignMenu
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    Design information 의 사이드 메뉴 패널
 */
const DesignMenu = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      DesignMenu
     *  @type          {Object} props
     *  @property      {Number} nowStep - 현재 활성화 된 메뉴 번호
     *  @property      {Function} onSelect - 메뉴 선택 시 실행 될 상위 이벤트 함수
     *  @property      {Array} menuList - 메뉴 리스트에 표시할 목록 데이터
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {nowStep, onSelect, menuList} = props;

    /**
     *  @memberOf     DesignMenu
     *  @type         {Object} getSessionState
     *  @property     {String} language - study 화면 언어 정보
     *  @description  session 에서 받아오는 정보
     */
    const {language} = getSessionState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     DesignMenu
     *  @function     handleSelect
     *  @param        {Number} menuIndex - 목록 배열의 index
     *  @description  메뉴 선택 시 실행되어 상위 이벤트 함수 호출
     */
    const handleSelect = useCallback((menuIndex) => {
        if (nowStep === menuIndex) {
            return;
        }

        if (onSelect !== undefined) {
            onSelect(menuIndex);
        }
    }, [nowStep, onSelect]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div className="vertical-box-column width-200 bg-light">
            <div className="vertical-box">
                <div className="vertical-box-row">
                    <div className="vertical-box-cell">
                        <div className="vertical-box-inner-cell">
                            <PerfectScrollbar className="height-full" options={{suppressScrollX: true}}>
                                <div className="wrapper p-0">
                                    <div className="nav-title"></div>
                                    <ul className="nav nav-inbox">
                                        {menuList.map((step, index) => {
                                            let classNameData = 'cursor-pointer';

                                            // 활성화된 현재 step 이 같으면 active
                                            if (index === nowStep) {
                                                classNameData = 'active';
                                            }

                                            return (
                                                <li className={classNameData} onClick={() => handleSelect(index)}
                                                    key={`design-menu-${index}`}>
                                                    <Link className="p-r-0 p-l-10">
                                                        {language === 'en' ? step.MENU : step.MENU_KR}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(DesignMenu);
