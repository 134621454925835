import React from 'react';
import {ScaleLoader} from "react-spinners";

//  로딩 표시 indicator
const LoadingIndicator = (props) => {

  const {loading} = props;

  const indicatorStyle = {
    textAlign : "center",
    padding : "padding: 1em 2em 0 0",
    left : "50%",
    top : "50%",
    transform : "translate(-50%)",
    position : "absolute",
    zIndex: "10000"
  };

  return(
      <div style={indicatorStyle}>
        <ScaleLoader
          size={15}
          color={"#4A90E2"}
          loading={loading} />
      </div>
  )
};

export default LoadingIndicator;
