import React, {useCallback, useState} from 'react';
import {Input, InputGroup, InputGroupButtonDropdown} from "reactstrap";

/**
 *  @summary    search text 컴포넌트
 *  @author     주예리나
 *  @version    버전??
 *  @see        none
 */
/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @constant {string} KEY_CODE_ENTER
 *  @description 키보드 입력 ENTER 코드 -> 키 코드 ENTER = 13
 */
const KEY_CODE_ENTER = 13;


/**
 *  @component    TextSearch
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  TextSearch main 함수형 컴포넌트
 */

const TextSearch = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/

    /*
    * name    : input tag name
    * onClick : 검색버튼 클릭 시 실행되는 상위 컴포넌트 함수
    * placeholder : input placeholder
    * */
    const {
        name,
        onClick,
        placeholder,
        onChange
    } = props;

    /**
     *  @memberOf     TextSearch
     *  @var          {string} search
     *  @description  검색어 state
     */
    const [search, setSearch] = useState('');

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     TextSearch
     *  @function     handleChange
     *  @param        {event} e - 이벤트 객체
     *  @return       none
     *  @description  입력값에 변화에 따른 Change함수
     */
    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setSearch(value);

        if (onChange) {
            onChange(name, value);
        }
    }, [onChange]);

    /**
     *  @memberOf     TextSearch
     *  @function     handleClick
     *  @param        none
     *  @return       none
     *  @description  검색 버튼 클릭 시 동작하는 함수
     */
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick(name, search);
        }
    }, [onClick, name, search]);

    /**
     *  @memberOf     TextSearch
     *  @function     handleKeyPress
     *  @param        {event} e - 이벤트 객체
     *  @return       none
     *  @description  input 창 enter 키 입력 시 동작하는 함수
     */
    const handleKeyPress = useCallback((e) => {
        // kye code 가 13 이면 enter
        if (e.charCode === KEY_CODE_ENTER) {
            handleClick();
        }
    }, [handleClick]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <InputGroup size="md" className="m-r-10">
                <Input name={name} placeholder={placeholder} type="text" onKeyPress={handleKeyPress}
                       onChange={handleChange}
                       value={search} className="input-white"/>
                <InputGroupButtonDropdown addonType="append">
                    <button
                        className="btn search-btn border-0"
                        onClick={handleClick}>
                        <i className="fa fa-search fa-fw text-dark"/>
                    </button>
                </InputGroupButtonDropdown>
            </InputGroup>
        </>
    );
};

export default React.memo(TextSearch);
