import React, {useCallback, useEffect, useState} from "react";
import AnnotationComponent from "../../../../components/AnnotationComponent";
import addIcon from "../../../../../assets/icons/add.svg";
import ACRFEditModal from "./ACRFEditModal";
import {MAXIMUM_ANNOTATION, SEND_ANNOTATION_INFO} from "./ACRFMain";
import {copyObject} from "../../../../../common/commonFunction";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";
import ACRFStudyEvent2 from "./ACRFStudyEvent2";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFForm2
 *  @constant     {Object} TYPE_DEFINE
 *  @description  FORM, FORMCOMMENT 정의
 */
const TYPE_DEFINE = {
    FORM: "FORM",
    COMMENT: "FORMCOMMENT"
};

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFForm2
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Form 컴포넌트
 */
const ACRFForm2 = (props) => {
    /**
     *  @memberOf     ACRFForm2
     *  @type         {Object} props
     *  @property     {String} formID - Form List에서 선택 된 Form의 ID
     *  @property     {Object} formData - Form 정보
     *  @property     {Boolean} isEdit - acrf의 edit 구분 값(false: view, true: edit)
     *  @property     {Function} onChangeDataList - annotation 추가, 수정, 삭제시 실행될 상위 이벤트 함수
     *  @property     {Array} allAnnotations - Form에 대한 전체 annotation 리스트
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @property     {Array} - studySchedule 정보
     *  @property     {Array} - arm 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {EDCType, formID, formData, isEdit, onChangeDataList, allAnnotations, formMode, visitList, armList} = props;

    /**
     *  @memberOf     ACRFForm2
     *  @var          {String} type
     *  @description  Annotation type(FORM, FORMCOMMENT)
     */
    const [type, setType] = useState();

    /**
     *  @memberOf     ACRFForm2
     *  @var          {Boolean} isVisible
     *  @description  화면에 표시 할지 말지 정해주는 값(true만 화면에 보여줌)
     */
    const [isVisible, setIsVisible] = useState(false);

    /**
     *  @memberOf     ACRFForm2
     *  @var          {Boolean} isModal
     *  @description  ACRF edit 팝업 오픈 여부
     */
    const [isModal, setIsModal] = useState(false);

    /**
     *  @memberOf     ACRFForm2
     *  @var          {Array} domainList
     *  @description  ACRF Edit Modal에 표시 될 Domain 목록
     */
    const [domainList, setDomainList] = useState();

    /**
     *  @memberOf     ACRFForm2
     *  @var          {Array} currentAnnotation
     *  @description  선택 된 Annotation 정보
     */
    const [currentAnnotation, setCurrentAnnotation] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFForm2
     *  @function     getStudyEventName
     *  @description  특정 디자인의 특정 폼의 study event name 가져오기 (서울대 MAD 과제에 해당하는 함수)
     */
    const getStudyEventName = useCallback(() => {
        if (formData.designID === "DEGN000000000100" && formData.ID === "CRFM000000011021") { // "Blood samplings for PD analysis" 폼의 study event name은 ACRFItemGroup2.js에서 가져옴
            return <></>
        } else if (formData.designID === "DEGN000000000100" && formData.ID === "CRFM000000011052") { // "Vital Signs [Log]" 폼의 study event name
            return (
                <h1
                    style={{
                        padding: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                    }}>
                    1d, 7d
                </h1>
            )
        } else {
            return (
                <h1
                    style={{
                        padding: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif"
                    }}>
                    {visitList?.map((visit, idx) => (
                        <div key={idx}>{visit}</div>
                    ))}
                </h1>
            )

        }
    }, [formData, visitList]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     handleAdd
     *  @param        {String} type - 추가 할 Annotation의 type(FORM, FORMCOMMENT)
     *  @description  Annotation Add 함수
     */
    const handleAdd = useCallback((type) => {
        let formAnnotationLength = formData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.FORM).length; //Form의 annotation 리스트에서 type이 FORM인 것만 필터하여 리스트 길이 저장
        let commentAnnotationLength = formData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.COMMENT).length; //Form의 annotation 리스트에서 type이 FORMCOMMENT인 것만 필터하여 리스트 길이 저장

        //type이 FORM이고 필터된 리스트 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.FORM && formAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
        }

        //type이 FORMCOMMENT이고 필터된 리스트 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.COMMENT && commentAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
        }

        setCurrentAnnotation({}); //add 일때는 선택됐던 annotation 정보 초기화
    }, [formData.annotations]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     handleClick
     *  @param        {Object} annotation - 선택 된 annotation의 정보
     *  @description  Annotation 클릭 되면 호출 되는 함수
     */
    const handleClick = useCallback((annotation) => {
        if (isEdit) {
            setCurrentAnnotation(annotation);
            setIsModal(true);
            setType(annotation.type);
        }
    }, [isEdit]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 상위로 annotation 정보 전달하는 함수
     */
    const changeDataList = useCallback((data) => {
        if (onChangeDataList !== undefined) {
            onChangeDataList(data);
        }
    }, [onChangeDataList]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     handleDelete
     *  @param        {Number} idx - 리스트에서 선택 된 index
     *  @param        {String} type - Annotation의 type
     *  @description  Annotation Delete 하는 함수
     */
    const handleDelete = useCallback((idx, type) => {
        if (formData.annotations !== undefined && formData.annotations.length > 0) {
            const annotationList = formData.annotations.filter(annotation => annotation.type === type); //type에 따라 annotations를 filter
            const data = annotationList[idx]; //삭제 될 data
            data.path = formData.ID; //삭제 될 data에 path key 추가
            data.dataType = "delete"; //dataType에 delete 추가

            changeDataList(data); //상위로 삭제 된 annotation 정보 전달

            if (type === "FORM") { //FOMR일때 domain에 포함된 annotation도 삭제 됐다는걸 알려주기 위해
                let deleteFilterList = allAnnotations.filter(annoataion => annoataion.domain === data.domain); //allAnnotations에서 삭제된 domain을 포함한 annotation filter(Form Annotation 삭제시 domain 포함하는 annotation은 전부 삭제 필요)

                //Form Annotation일때 해당하는 Domain의 annotation들을 같이 전역 변수로 넘겨줌
                if (deleteFilterList.length > 0) {
                    deleteFilterList.forEach(data => {
                        let _data = copyObject(data);

                        //path가 object인 값 일때만
                        if (typeof data.path === "object") {
                            let IDList = [];

                            //path의 key값들을 돌면서 IDList에 저장
                            for (let key in data.path) {
                                IDList.push(data.path[key]);
                            }

                            _data.path = IDList.join("/"); //'/'를 붙여서 string으로 path에 저장
                        }

                        SEND_ANNOTATION_INFO.delete.push(_data); //전역 변수로 지정 된 곳 delete에 데이터 추가
                    });
                }
            } else { //FORMCOMMENT 일때
                SEND_ANNOTATION_INFO.delete.push(data); //전역 변수로 지정 된 곳 delete에 데이터 추가
            }
        }
    }, [allAnnotations, changeDataList, formData.ID, formData.annotations]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     handleSave
     *  @param        {Object} data - Annotation 정보
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback((data) => {
        changeDataList(data); //상위로 추가, 수정 된 annotation 정보 전달

        SEND_ANNOTATION_INFO[data.dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가

        setIsModal(false);
        setCurrentAnnotation({});
    }, [changeDataList]);

    /**
     *  @memberOf     ACRFForm2
     *  @function     getStudyEventComp
     *  @return       {Element} StudyEvent Data render
     *  @description  ACRF의 해당하는 Study Event 그려주는 함수
     */
    const getStudyEventComp = useCallback(() => {
        return (
            <div style={{marginBottom: "20px"}}>
                <ACRFStudyEvent2
                    formData={formData}
                    isEdit={isEdit}
                    domainList={domainList}
                    onChangeDataList={changeDataList}
                    formID={formData.ID}
                    formMode={formMode}
                    itemGroupVisit={formData.itemGroupVisit}
                    formVisit={formData.formVisit}
                    armList={armList}
                    EDCType={EDCType}
                />
            </div>
        );
    }, [formData.studyEvents, formData.ID, formData.itemGroupVisit, formData.formVisit, isEdit, domainList, changeDataList, formMode, armList, EDCType]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //Form List에서 선택한 폼만 보여주기 위한 설정값 변경
    useEffect(() => {
        if (formID === formData.ID || formMode === FORM_COMPARE) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [formData.ID, formID, formMode]);

    //Domain List 생성
    useEffect(() => {
        if (formData !== undefined && formData.annotations.length > 0) {
            let _domainList = [];

            formData.annotations.forEach(annotation => {
                if (annotation.type === "FORM") {
                    _domainList.push({
                        value: annotation.domain,
                        text: annotation.domain
                    });
                }
            });

            setDomainList(_domainList);
        }
    }, [formData]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div style={isVisible ? {display: "block"} : {display: "none"}}>
            <div
                style={{
                    display: "flex",
                    pageBreakBefore: "always",
                    justifyContent: "space-between"
                }}>
                <div
                    style={{
                        display: "flex",
                        flex: "1"
                    }}>

                    {formMode !== FORM_COMPARE && (
                        <div>
                            {isEdit && (
                                <img
                                    src={addIcon}
                                    onClick={() => handleAdd("FORM")}
                                    style={{
                                        marginLeft: "5px",
                                        height: "1rem",
                                        alignSelf: "center"
                                    }}
                                    alt=""/>
                            )}

                            {/*form의 annotation 영역*/}
                            {formData.annotations.length > 0 && (
                                <div>
                                    <AnnotationComponent
                                        name="form-comment-annotation-area"
                                        annotationList={formData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.FORM)}
                                        onClick={handleClick}
                                        onDelete={handleDelete}
                                        isEdit={isEdit}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: "flex",
                        flex: "1"
                    }}>
                    {isEdit && (
                        <img
                            src={addIcon}
                            onClick={() => handleAdd("FORMCOMMENT")}
                            style={{
                                marginLeft: "5px",
                                height: "1rem",
                                alignSelf: "center"
                            }}
                            alt=""/>
                    )}

                    {/*form의 comment annotation 영역*/}
                    {formData.annotations.length > 0 && (
                        <div>
                            <AnnotationComponent
                                annotationList={formData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.COMMENT)}
                                onClick={handleClick}
                                onDelete={handleDelete}
                                isEdit={isEdit}/>
                        </div>
                    )}
                </div>
            </div>

            {/*form name 영역*/}
            <h1
                id={formData.name.trim()}
                style={{
                    fontSize: "14pt",
                    fontWeight: "800",
                    alignSelf: "center",
                    paddingTop: "15px",
                    fontFamily: "Arial, sans-serif"
                }}>
                <span
                    style={{border: formData.hasOwnProperty("isDiff") && formData.isDiff ? "1px solid #ff0000" : ""}}>
                    {formData.name}
                </span>
            </h1>

            {/*form의 description 영역*/}
            {formData.description !== "" && formData.description !== null && (
                <div
                    style={{
                        padding: "5px 10px",
                        border: "1px dotted #000",
                        wordBreak: "break-word"
                    }}>
                        <pre
                            style={{
                                fontSize: "9pt",
                                fontFamily: "Arial, sans-serif",
                                whiteSpace: "pre-wrap"
                            }}>
                            {formData?.description?.replaceAll('<br/>', '\n')}
                        </pre>
                </div>
            )}

            {/*Study Event 출력*/}
            {/*Study Event 출력 (과제 immediate건으로 해당하는 디자인의 두 개의 form ID 에만 적용*/}
            {getStudyEventName()}

            {getStudyEventComp()}

            {isModal && (
                <ACRFEditModal
                    onClose={() => setIsModal(false)}
                    onSave={handleSave}
                    type={type}
                    data={formData}
                    domainList={domainList}
                    annotationInfo={currentAnnotation}/>
            )}
        </div>
    );
};

export default React.memo(ACRFForm2);