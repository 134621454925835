import React, {useCallback, useLayoutEffect, useState} from "react";
import cn from "classnames";
import Select from "react-select";
import {copyObject, getSessionState, isDataExist} from "../../../../common/commonFunction";
import {FILL_REQUIRED_FIELD} from "constant/ConstantMsg";
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";
import {OBJECTIVE_NAME} from "./Objective";
import EndPointSearchList from "./EndPointSearchList";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ObjectiveItem
 *  @constant     {String} DEFAULT_VALUE
 *  @description  기본 빈 값
 */
const DEFAULT_VALUE = '';

/**
 *  @memberOf       ObjectiveItem
 *  @constant       {Object} OBJECTIVE_DICTIONARY
 *  @description    language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
export const OBJECTIVE_DICTIONARY = {
    'kr': {
        'Primary': '1차 목적',
        'Secondary': '2차 목적',
        'Efficacy': '유효성 목적',
        'Safety': '안정성 목적',
        'Exploratory': '탐색적 목적',
    }
};

/**
 *  @memberOf     ObjectiveItem
 *  @constant     {Array} OBJECTIVE_CATEGORY_LIST
 *  @description  Objective 카테고리 옵션 리스트
 */
const OBJECTIVE_CATEGORY_LIST = [
    {
        text: 'Primary',
        value: 'primary'
    },
    {
        text: 'Secondary',
        value: 'secondary'
    },
    {
        text: 'Efficacy',
        value: 'efficacy'
    },
    {
        text: 'Safety',
        value: 'safety'
    },
    {
        text: 'Exploratory',
        value: 'exploratory'
    },
];

/**
 *  @memberOf       ObjectiveItem
 *  @constant       {Object} ENDPOINT_DICTIONARY
 *  @description    language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
export const ENDPOINT_DICTIONARY = {
    'kr': {
        'Primary': '1차 평가 항목',
        'Secondary': '2차 평가 항목',
        'Efficacy': '유효성 평가 항목',
        'Safety': '안정성 평가 항목',
        'Pharmacokinetics': '약동학 평가 항목',
        'Pharmacodynamics': '약력학 평가 항목',
        'Exploratory': '탐색적 평가 항목',
        'Tolerability': '내약성 평가 항목',
    }
};

/**
 *  @memberOf     ObjectiveItem
 *  @constant     {Array} ENDPOINT_CATEGORY_LIST
 *  @description  Endpoint 카테고리 옵션 리스트
 */
const ENDPOINT_CATEGORY_LIST = [
    {
        text: 'Primary',
        value: 'Primary'
    },
    {
        text: 'Secondary',
        value: 'Secondary'
    },
    {
        text: 'Efficacy',
        value: 'Efficacy'
    },
    {
        text: 'Safety',
        value: 'Safety'
    },
    {
        text: 'Pharmacokinetics',
        value: 'Pharmacokinetics'
    },
    {
        text: 'Pharmacodynamics',
        value: 'Pharmacodynamics'
    },
    {
        text: 'Exploratory',
        value: 'Exploratory'
    },
    {
        text: 'Tolerability',
        value: 'Tolerability'
    }
];

/**
 *  @author       주예리나
 *  @version      1.0
 *  @see          react-select (https://react-select.com/home)
 *  @component    ObjectiveItem
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  design information 의 Objective & Endpoint 리스트 항목 표시 컴포넌트
 */
const ObjectiveItem = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      ObjectiveItem
     *  @type          {Object} props
     *  @property      {Object} data - 컴포넌트에 표시하기 위한 해당 데이터
     *  @property      {Number} itemKey - 현재 표시하고 있는 데이터의 index
     *  @property      {Number} loadingObjectIndex - 현재 검색 로딩 중은 objective 데이터 위치 index
     *  @property      {Number} loadingEndpointIndex - 현재 검색 로딩 중인 endpoint 데이터 위치 index
     *  @property      {Object} validation - 입력 값 validation
     *  @property      {Function} onAddTableRow - Endpoint table 행 추가 시 실행될 상위 이벤트 함수
     *  @property      {Function} onDataChange - input 데이터 입력 시 실행될 상위 이벤트 함수
     *  @property      {Function} onFocus - input 요소 focus 될 시 실행 될 상위 이벤트 함수
     *  @property      {Array} objectiveDataList - 검색 된 objective 데이터 리스트
     *  @property      {Array} endpointDataList - 검색 된 endpoint 데이터 리스트
     *  @property      {Object} inputData - Endpoint 입력 필드 default 값
     *  @property      {Function} onDataSelect - 검색 컴포넌트 내용 선택 시 실행될 상위 이벤트 함수
     *  @property      {Function} onGridChange - grid 변경 시(수정, Drag & Drop, 삭제) 실행될 상위 이벤트 함수
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        data,
        itemKey,
        loadingObjectIndex,
        loadingEndpointIndex,
        validation,
        onAddTableRow,
        onDelete,
        onDataChange,
        onFocus,
        objectiveDataList,
        endpointDataList,
        inputData,
        onDataSelect,
        onGridChange
    } = props;

    /**
     *  @memberOf      ObjectiveItem
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property      {String} language - study 화면 언어 정보
     *  @description   session 에서 받아오는 정보
     */
    const {lock, language} = getSessionState();

    /**
     *  @memberOf     ObjectiveItem
     *  @var          {Object} selected
     *  @description  select 컴포넌트 선택 여부
     */
    const [selected, setSelected] = useState({
        [OBJECTIVE_NAME.OBJECTIVE]: false,
        [OBJECTIVE_NAME.ENDPOINT_NAME]: false
    });

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf       ObjectiveItem
     *  @function       getConvertSelectList
     *  @param          {Array} selectList - 화면에 표시할 옵션 리스트
     *  @return         {String} name - name 정보
     *  @description    언어를 적용하여 데이터 리스트를 변경
     */
    const getConvertSelectList = useCallback((selectList, name) => {
        let originList = copyObject(selectList);
        let convertList = [];

        let item;
        for (item of originList) {
            if ((name === 'category' ? OBJECTIVE_DICTIONARY : ENDPOINT_DICTIONARY).hasOwnProperty(language)) {
                const dict = (name === 'category' ? OBJECTIVE_DICTIONARY[language] : ENDPOINT_DICTIONARY[language]);

                if (dict.hasOwnProperty(item.text)) {
                    let newItem = copyObject(item)
                    newItem['text'] = dict[item.text];
                    convertList.push(newItem);
                } else {
                    convertList.push(item);
                }
            } else {
                convertList.push(item);
            }
        }
        return convertList;
    }, [language]);

    /**
     *  @memberOf     ObjectiveItem
     *  @function     handleDelete
     *  @description  Objective 삭제 버튼 클릭 시 실행되어 상위 이벤트 함수 호출
     */
    const handleDelete = useCallback(() => {
        if (lock !== 2) {
            if (onDelete !== undefined) {
                onDelete(itemKey);
            }
        }
    }, [onDelete, itemKey, lock]);

    /**
     *  @memberOf     ObjectiveItem
     *  @function     handleTableRowAdd
     *  @description  endpoint 데이터 테이블에서 행 추가 버튼 클릭 시 실행되어 상위 이벤트 함수 호출
     */
    const handleTableRowAdd = useCallback(() => {
        if (lock !== 2) {
            if (onAddTableRow !== undefined) {
                onAddTableRow(itemKey);
            }
        }
    }, [onAddTableRow, itemKey, lock]);

    /**
     *  @memberOf     ObjectiveItem
     *  @function     handleChange
     *  @param        {String} name -  입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @param        {Number} subIndex - endpoint 위치 index
     *  @param        {String} action - react-select action 타입 값 ('menu-close' | 'input-blur' - ReactSelect 내부 액션 값)
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((name, value, subIndex = undefined, action) => {
        if (name === OBJECTIVE_NAME.OBJECTIVE || name === OBJECTIVE_NAME.ENDPOINT_NAME) {
            if (action.action !== "input-blur" && action.action !== "menu-close") {
                onDataChange(name, value, itemKey);
            }
        } else {
            if (onDataChange !== undefined) {
                onDataChange(name, value, itemKey);
            }
        }
    }, [onDataChange, itemKey]);

    /**
     *  @memberOf     ObjectiveItem
     *  @function     handleSelect
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  검색 컴포넌트 내용 선택 시 실행되는 함수
     */
    const handleSelect = useCallback((name, value) => {
        if (onDataSelect !== undefined) {
            onDataSelect(name, value, itemKey);

            // select 여부 체크
            setSelected(prevState => ({
                [name]: !prevState[name]
            }));
        }
    }, [onDataSelect, itemKey]);

    /**
     *  @memberOf     ObjectiveItem
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @param        {Number} subIndex - endpoint 위치 index
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name, subIndex = undefined) => {
        if (onFocus !== undefined) {
            onFocus(name, itemKey, subIndex);
        }
    }, [onFocus, itemKey]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // handleSelect 한 후 입력칸 hidden 처리 없애기
    useLayoutEffect(() => {
        if (data.text !== '') {
            const inputEl = document.getElementById(`${OBJECTIVE_NAME.OBJECTIVE}${itemKey}`);

            if (!inputEl) {
                return;
            }
            // prevent input from being hidden after selecting
            inputEl.style.opacity = "1";
        }
    }, [data.text, itemKey, selected.text]);

    // handleSelect 한 후 입력칸 hidden 처리 없애기
    useLayoutEffect(() => {
        if (inputData && inputData.name !== '') {
            const inputEl = document.getElementById(`${OBJECTIVE_NAME.ENDPOINT_NAME}${itemKey}`);

            if (!inputEl) {
                return;
            }
            // prevent input from being hidden after selecting
            inputEl.style.opacity = "1";
        }
    }, [inputData, itemKey, selected.endpoint_name]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className="form-group row align-items-top m-b-20">
                <div className="col-2">
                    {language === 'en' ? 'Category' : '분류'}
                </div>
                <div className="col-10 d-flex justify-content-between">
                    <div className="w-75">
                        <SelectBox
                            name={OBJECTIVE_NAME.CATEGORY}
                            dataList={getConvertSelectList(OBJECTIVE_CATEGORY_LIST, OBJECTIVE_NAME.CATEGORY)}
                            currentValue={data[OBJECTIVE_NAME.CATEGORY]}
                            onChange={handleChange}
                            defaultValue={DEFAULT_VALUE}
                            isEmptyValue={true}
                            readOnly={lock === 2}/>
                    </div>

                    <button
                        className={cn("btn btn-orange", {'disabled': lock === 2})}
                        onClick={handleDelete}>
                        ×
                    </button>
                </div>
            </div>

            <div className="form-group row align-items-center m-b-20">
                <div className="col-2">
                    {language === 'en' ? 'Objective' : '목적'}
                    <span className='text-red p-l-2 p-r-2'>*</span>
                </div>
                <div className="col-10">
                    <div className={"w-75"}>
                        <Select
                            inputId={`${OBJECTIVE_NAME.OBJECTIVE}${itemKey}`}// input 태그 id
                            backspaceRemovesValue={false} //
                            isSearchable={true}
                            isClearable={false}
                            classNamePrefix={"react-select"}
                            onSelectResetsInput={false}
                            className={validation[OBJECTIVE_NAME.OBJECTIVE] !== undefined && validation[OBJECTIVE_NAME.OBJECTIVE] === false && "is-invalid"}
                            isLoading={itemKey === loadingObjectIndex}
                            options={data[OBJECTIVE_NAME.OBJECTIVE] !== "" ? objectiveDataList : []}
                            onInputChange={(value, action) => handleChange(OBJECTIVE_NAME.OBJECTIVE, value, itemKey, action)}
                            onChange={(value, action) => handleSelect(OBJECTIVE_NAME.OBJECTIVE, value, itemKey, action)}
                            inputValue={data[OBJECTIVE_NAME.OBJECTIVE]}
                            value={data[OBJECTIVE_NAME.OBJECTIVE]}
                            onFocus={(e) => handleFocus(OBJECTIVE_NAME.OBJECTIVE)}
                            placeholder={"Input Objective..."}
                            isDisabled={lock === 2}/>
                    </div>

                    {!validation[OBJECTIVE_NAME.OBJECTIVE] && (
                        <div className="ml-2 text-danger">{FILL_REQUIRED_FIELD}</div>
                    )}
                </div>
            </div>

            <div className="form-group row align-items-top m-b-20">
                <div className="col-2"/>
                <div className="col-10 d-flex justify-content-between">
                    <div className="w-100">
                        {isDataExist(data[OBJECTIVE_NAME.ENDPOINT]) && (
                            <EndPointSearchList
                                itemKey={itemKey}
                                ID={`endpoint-grid${itemKey}`}
                                dataList={data[OBJECTIVE_NAME.ENDPOINT]}
                                onChange={onGridChange}
                                isWordWrap={true}/>
                        )}

                        <table className="table table-valign-middle table-striped" style={{tableLayout: "fixed"}}>
                            <thead>
                            <tr>
                                <th> {language === 'en' ? 'Category' : '분류'}</th>
                                <th width={"35%"}>
                                    {language === 'en' ? 'Endpoint' : '평가항목'}
                                    <span className='text-red p-l-2 p-r-2'>*</span>
                                </th>
                                <th>  {language === 'en' ? 'Details' : '비고'}</th>
                                <th width={"8%"}/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="px-1 p-l-30">
                                    <SelectBox
                                        name={OBJECTIVE_NAME.ENDPOINT_CATEGORY}
                                        dataList={getConvertSelectList(ENDPOINT_CATEGORY_LIST, OBJECTIVE_NAME.ENDPOINT_CATEGORY)}
                                        currentValue={inputData && inputData[OBJECTIVE_NAME.CATEGORY]}
                                        onChange={(name, value) => handleChange(name, value, itemKey)}
                                        onFocus={(name) => handleFocus(name, itemKey)}
                                        defaultValue={DEFAULT_VALUE}
                                        isEmptyValue={true}
                                        readOnly={lock === 2}/>
                                </td>

                                <td className="px-1">
                                    <Select
                                        inputId={`${OBJECTIVE_NAME.ENDPOINT_NAME}${itemKey}`} // input tag id
                                        backspaceRemovesValue={false}
                                        isSearchable={true}
                                        isClearable={false}
                                        classNamePrefix={"react-select"}
                                        onSelectResetsInput={false}
                                        className={(validation.hasOwnProperty('endpoint') && validation[OBJECTIVE_NAME.ENDPOINT][OBJECTIVE_NAME.NAME] !== undefined && validation[OBJECTIVE_NAME.ENDPOINT][OBJECTIVE_NAME.NAME] === false) &&
                                        "is-invalid"
                                        }
                                        isLoading={itemKey === loadingEndpointIndex}
                                        options={data[OBJECTIVE_NAME.OBJECTIVE] !== "" ? endpointDataList : []}
                                        onInputChange={(value, action) => handleChange(OBJECTIVE_NAME.ENDPOINT_NAME, value, itemKey, action)}
                                        inputValue={inputData && inputData[OBJECTIVE_NAME.NAME]}
                                        value={inputData && inputData[OBJECTIVE_NAME.NAME]}
                                        onFocus={() => handleFocus(OBJECTIVE_NAME.ENDPOINT_NAME, itemKey)}
                                        onChange={(value, action) => handleSelect(OBJECTIVE_NAME.ENDPOINT_NAME, value, itemKey, action)}
                                        placeholder={"Input Endpoint..."}
                                        isDisabled={data[OBJECTIVE_NAME.OBJECTIVE] === '' || lock === 2}/>
                                </td>

                                <td className="px-1">
                                    <TextField
                                        name={OBJECTIVE_NAME.ENDPOINT_DESCRIPTION}
                                        currentValue={inputData && inputData[OBJECTIVE_NAME.DESCRIPTION]}
                                        onChange={(name, value) => handleChange(name, value, itemKey)}
                                        disabled={lock === 2}/>
                                </td>

                                <td className="px-1">
                                    <button
                                        className={cn("btn btn-blue", {'disabled': lock === 2})}
                                        onClick={handleTableRowAdd}>
                                        +
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <hr/>
        </>
    );
};

export default React.memo(ObjectiveItem);