import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Checkbox from "./Checkbox";
import { isDataExist } from "common/commonFunction";
import { FILL_REQUIRED_FIELD } from "constant/ConstantMsg";

/**
 *  @summary
 *  전체 선택이 가능한 리스트
 *
 *  @author 주예리나
 *  @version 1.0, 작업 내용
 *  @see None
 */


/**
 *  @constant
 *  @type {object}
 *  @description  선택 리스트에 사용되는 필드 명칭
 */
export const SELECT_LIST_FIELD = {
    ID: "value",
    LABEL: "text",
}

/**
 *  @constant
 *  @type {object}
 *  @description  전체 선택에 사용되는 필드 값
 */
const CHECK_ALL = {
    NAME: 'ALL',
    LABEL: "Check All",
}


/**
 *  @type      {function(*): *}
 *  @function  component - check all을 할 수 있는 list component
 *  @param     {Object} props - 상위 컴포넌트에서 전달 받은 property
 */
const CheckAllList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /*
    *   상위 컴포넌트에서 전달 받은 props
    *   1. dataList     : list를 표시하기 위한 데이터 리스트
    *   2. onSelect     : 선택을 전달하기 위한 이벤트 함수
    *   3. onCheckAll   : 전체 선택을 선택하였을 때 처리하는 이벤트 함수
    *   4. selectedList : 현재 선택된 리스트
    *   5. validation   : 현재 선택된 리스트
    *   6. name         : 현재 선택된 리스트
    *   7. onFocus      : 현재 선택된 리스트
    */
    const {
        dataList,
        onSelect,
        onCheckAll,
        selectedList,
        validation,
        name,
        groupName,
        onFocus,
        labelName = 'text',
        valueName = 'value'
    } = props;

    //  check all 선택 여부
    const [checkAll, setCheckAll] = useState(false);

    /**
     *  @function  handleSelect
     *  @param  {String} id     - 선택을 한 항목의 ID
     *  @param  {String} value  - 선택을 한 항목의 값(true, false)
     *  @description  check box를 선택하였을 때 처리 함수
     */
    const handleSelect = useCallback((id, value, label, e) => {
        e.preventDefault();

        if (onSelect !== undefined) {
            onSelect(id, value, label, groupName);
        }

    }, [groupName, onSelect]);


    /**
     *  @function  handleCheckAll
     *  @description  check all을 선택하였을 경우
     */
    const handleCheckAll = useCallback(() => {

        if (onCheckAll !== undefined) {
            onCheckAll(!checkAll);
        }

    }, [checkAll, onCheckAll]);


    /**
     *  @function  handleFocus
     *  @param  {object} e - event 객체
     *  @description  focus가 왔을 때 처리
     */
    const handleFocus = useCallback((e) => {
        e.preventDefault();
        if (onFocus !== undefined) {
            onFocus(name);
        }
    }, [name, onFocus]);


    /**
     *  @function  handleCheckAllEvent
     *  @param  {Event}  e      - 이벤트 정보를 가지고 있는 오브젝트
     *  @description  check all을 선택하였을 경우(list를 선택했을 경우)
     */
    const handleCheckAllEvent = useCallback((e) => {
        e.preventDefault();

        handleCheckAll();
    }, [handleCheckAll]);


    /**
     *  @function  getDataList
     *  @description  선택 목록을 표시 하는 함수
     */
    const getDataList = useCallback(() => {
        // dataList가 있는지 체크
        const NoContents = (dataList === null || dataList === undefined || dataList.length === 0);

        if (NoContents === true) {
            return null;
        }

        return dataList.map((data, index) => {

            // 단일 배열인가?
            if (typeof data === "string") {
                data = {
                    [labelName]: data,
                    [valueName]: data,
                };
            }

            let findObject;

            if (isDataExist(selectedList)) {
                findObject = selectedList.find((element, index,) => {
                    if (typeof element === 'object') {
                        return element[valueName] === data[valueName];
                    } else {
                        return element === data[valueName]
                    }
                });
            }
            const active = findObject !== undefined;

            return (
                <li key={index}
                    className={classNames({
                        "list-group-item p-t-10 py-0 cursor-pointer": true,
                    })}
                    onClick={(e) => {
                        handleSelect(data[valueName], !active, data[labelName], e, )
                    }}
                    onFocus={handleFocus}
                >
                    <Checkbox name={data[valueName]}
                              onChange={handleSelect}
                              currentValue={active}
                              groupName={groupName}
                              label={data[labelName]}
                    />
                </li>
            )

        });

    }, [dataList, groupName, handleFocus, handleSelect, labelName, selectedList, valueName]);

    // in valid 일 때 출력할 text 내용
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false) {
            return (
                <>
                    <div className='d-flex justify-content-start mt-1'>
                        <div className="ml-2 text-danger">{FILL_REQUIRED_FIELD}</div>
                    </div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [validation]);


    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    /**
     *  @listens  [dataList]      - 선택할 데이터 리스트
     *  @listens  [selectedList]  - 선택된 데이터 리스트
     *  @description  - 모든 항목이 추가 되었는지 체크하여 check all 여부를 셋팅
     */
    useEffect(() => {

        if (isDataExist(dataList) === false) return;

        let checkAllBool = true;

        for (let data of dataList) {
            if (typeof data === "string") {
                data = {
                    [labelName]: data,
                    [valueName]: data,
                };
            }

            if (isDataExist(selectedList)) {
                const findObject = selectedList.find((element, index) => {
                    if (typeof element === 'object') {
                        return element[valueName] === data[valueName];
                    } else {
                        return element === data[valueName]
                    }
                });

                console.log(findObject);
                if (findObject === undefined) {
                    checkAllBool = false;
                    break;
                }
            } else {
                checkAllBool = false;
            }
        }

        console.log(checkAllBool);
        setCheckAll(checkAllBool);

    }, [dataList, labelName, selectedList, valueName]);


    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className="p-1 w-100 my-1">
                <ul className={classNames("list-group", {
                    'invalid_border': ((validation !== undefined && validation === false) === true),
                })}>
                    <li className={classNames({
                        "list-group-item p-t-10 py-0 cursor-pointer": true,
                    })}
                        onClick={(e) => {
                            handleCheckAllEvent(e)
                        }}
                        onFocus={handleFocus}
                    >
                        <Checkbox name={CHECK_ALL.NAME}
                                  onChange={(name, value) => {
                                      handleCheckAll()
                                  }}
                                  currentValue={checkAll}
                                  label={CHECK_ALL.LABEL}
                                  className={""}
                        />
                    </li>
                    {
                        getDataList()
                    }
                </ul>
            </div>
            {getInValidText()}

        </>
    );
};

export default React.memo(CheckAllList);
