import React, {useCallback, useEffect, useRef, useState} from "react";
import {Tree} from "antd";
import {DownOutlined} from "@ant-design/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, getSessionState} from "../../../../../common/commonFunction";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import NetworkLayout from "../../../../../common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import TSDomainList from "./TS/TSDomainList";
import TADomainList from "./TA/TADomainList";
import TEDomainList from "./TE/TEDomainList";
import TVDomainList from "./TV/TVDomainList";
import TMDomainList from "./TM/TMDomainList";
import VariableDomainList from "./Variable/VariableDomainList";
import TIDomainList from "./TI/TIDomainList";
import VariableMetadataList from "./Variable/VariableMetadataList";

/**
 *  @memberOf     Variables
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID} = params;
    let url = null;
    let response = null;

    switch (command) {
        // //ACRF 정보 요청
        // case COMMAND.DATA_INFO:
        //     url = `${requestUrl}/${ID}`;
        //     response = await AXIOS_GET(url);
        //     break;
        //
        // //ACRF pdf 생성 요청
        // case COMMAND.FILE_DOWNLOAD:
        //     url = `${requestUrl}/${ID}`;
        //     response = await AXIOS_POST_FORM(url, sendObject, 100000);
        //     break;

        case COMMAND.DATA_LIST:
            url = `${requestUrl}/domain/menu/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @see          antd(https://ant.design/components/tree/)
 *  @component    Variables
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 컴포넌트
 */
const Variables = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     Variables
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     Variables
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {String} domain - DataSets 탭에서 선택한 Domain 값
     *  @description  session 에서 받아오는 정보
     */
    const {ID, domain} = getSessionState();

    /**
     *  @memberOf     Variables
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     Variables
     *  @var          {Array} dataList
     *  @description  왼쪽 메뉴에 표시될 전체 Domain 목록
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     Variables
     *  @var          {String} currentDomain
     *  @description  현재 선택 되어진 도메인
     */
    const [currentDomain, setCurrentDomain] = useState(domain ? domain : "");

    /**
     *  @memberOf     Variables
     *  @var          {String} domainList
     *  @description  Trial Design 도메인을 제외한 도메인 리스트
     */
    const [domainList, setDomainList] = useState([]);

    // /**
    //  *  @memberOf     Variables
    //  *  @var          {Array} ACRFList
    //  *  @description  서버에서 받아온 ACRF 목록
    //  */
    // const [ACRFList, setACRFList] = useState([]);
    //
    // /**
    //  *  @memberOf     Variables
    //  *  @var          {Array} processedDataList
    //  *  @description  화면을 그리기 위해 가공한 data 리스트
    //  */
    // const [processedDataList, setProcessedDataList] = useState([]);
    //
    // /**
    //  *  @memberOf     Variables
    //  *  @var          {Array} formContentsList
    //  *  @description  ACRF의 Forms의 목차에 해당하는 목록
    //  */
    // const [formContentsList, setFormContentsList] = useState([]);
    //
    // /**
    //  *  @memberOf     Variables
    //  *  @var          {Array} visitContentsList
    //  *  @description  ACRF의 Forms의 목차에 해당하는 목록
    //  */
    // const [visitContentsList, setVisitContentsList] = useState([]);
    //
    // /**
    //  *  @memberOf     Variables
    //  *  @var          {Object} coverInfo
    //  *  @description  ACRF의 Cover 정보
    //  */
    // const [coverInfo, setCoverInfo] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    // /**
    //  *  @memberOf     Variables
    //  *  @function     getACRFInfo
    //  *  @description  ACRF 정보 가져오는 api 호출
    //  */
    // const getACRFInfo = useCallback(() => {
    //     const command = COMMAND.DATA_INFO; // 데이터 리스트 요청 command
    //     const params = {
    //         requestUrl: `${SDTM_MAPPING_URL}/crf-json`,
    //         ID: ID
    //     };
    //
    //     netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    // }, [ID]);

    // /**
    //  *  @memberOf     Variables
    //  *  @function     getFormComp
    //  *  @return       {Element} Form Data render
    //  *  @description  ACRF의 해당하는 Form 그려주는 함수
    //  */
    // const getFormComp = useCallback(() => {
    //     if (processedDataList.length > 0 && ACRFList.length > 0) {
    //         return processedDataList.map((data, idx) => (
    //             <ACRFForm
    //                 key={idx}
    //                 formData={data}
    //                 allAnnotations={ACRFList[idx].allAnnotations}/>
    //         ));
    //     }
    // }, [processedDataList, ACRFList]);
    //
    // /**
    //  *  @memberOf     Variables
    //  *  @function     handleDownload
    //  *  @description  aCRF pdf로 다운로드 받는 함수
    //  */
    // const handleDownload = useCallback(() => {
    //     const acrfMain = document.getElementById("acrf-main-area"); //acrf-main-area를 id로 가지고 있는 element 정보를 가지고옴
    //     let outerACRFMain = acrfMain.outerHTML; //acrf element를 string으로 변경
    //     outerACRFMain = outerACRFMain.replaceAll('display: none', 'display: block'); //string으로 변경된 acrf element에서 'display: none' 속성을 'display: block'로 변경 작업
    //     let sendHTML = `<html lang="en">
    //                         <head>
    //                             <meta charset="utf-8" />
    //                             <title></title>
    //                         </head>
    //                         <body>${outerACRFMain}</body>
    //                     </html>`; //서버로 전달할 html, body로 감싼 string 코드 생성
    //     const file = new Blob([sendHTML], {
    //         type: 'text/plain',
    //     });
    //     const command = COMMAND.FILE_DOWNLOAD;
    //     const formData = new FormData();
    //     formData.append('html', file);
    //     const params = {
    //         requestUrl: `${SDTM_MAPPING_URL}/crf-pdf`,
    //         ID: ID,
    //         sendObject: formData
    //     };
    //
    //     netWorkAgent.current.request(command, params);
    // }, [ID]);

    /**
     *  @memberOf     Variables
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     Variables
     *  @function     handleSelect
     *  @param        {Object | Array} ID - Tree 메뉴 에서 선택된 ID
     *  @param        {Object} e - 선택 된 메뉴의 정보
     *  @description  Tree 메뉴에서 선택하면 호출되는 함수
     */
    const handleSelect = useCallback((ID, e) => {
        //assessment 더블클릭 시 ID가 빈값으로 들어오면 선택 막기
        if (ID.length === 0) {
            return;
        }

        if (Array.isArray(ID) && ID.length > 0) {
            ID = ID[0];
        }

        switch (ID) {
            case "TS":
            case "TA":
            case "TE":
            case "TV":
            case "TM":
            case "TI":
                setCurrentDomain(ID);
                break;

            default:
                if (e.node.type !== undefined) {
                    setCurrentDomain(ID);
                }
                break;
        }

        if (domainList.includes(ID)) {
            setCurrentDomain(ID);
        }
    }, [domainList]);

    /**
     *  @memberOf     Variables
     *  @function     renderDomainList
     *  @return       {Element} 왼쪽 Domain 메뉴 render
     *  @description  Domain 메뉴 그려주는 함수
     */
    const renderDomainList = useCallback(() => {
        let expandedKeys = []; //펼칠 Key 값 리스트 저장 변수

        //Class, Domain 까지만 보여주기 위한 key 값 찾는 로직
        for (let i = 0; i < dataList.length; i++) {
            for (let j = 0; j < dataList[i].children.length; j++) {
                expandedKeys.push(dataList[i].key);
            }
        }

        return (
            <Tree
                showIcon
                className="bg-light"
                defaultExpandedKeys={expandedKeys}
                defaultSelectedKeys={[currentDomain]} //기본 선택되어있는 값
                switcherIcon={<DownOutlined/>}
                treeData={dataList}
                onSelect={handleSelect}/>
        );
    }, [currentDomain, dataList, handleSelect]);

    /**
     *  @memberOf     Variables
     *  @function     TreeContents
     *  @param        {Object} props - 각각의 컴포넌트에 들어갈 props 값들
     *  @return       {Element} Detail탭의 content에 표시될 컴포넌트 render
     *  @description  선택 Domain에 따른 컴포넌트 render 해주는 함수
     */
    const TreeContents = useCallback((props) => {
        if (currentDomain === "TS DataSet") {
            return <TSDomainList {...props} />;
        } else if (currentDomain === "TA DataSet") {
            return <TADomainList {...props} />;
        } else if (currentDomain === "TE DataSet") {
            return <TEDomainList {...props} />;
        } else if (currentDomain === "TV DataSet") {
            return <TVDomainList {...props} />;
        } else if (currentDomain === "TM DataSet") {
            return <TMDomainList {...props} />;
        } else if (currentDomain === "TI DataSet") {
            return <TIDomainList {...props} />;
        } else {
            if (currentDomain === "TS") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (currentDomain === "TA") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (currentDomain === "TE") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (currentDomain === "TV") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (currentDomain === "TM") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (currentDomain === "TI") {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else if (domainList.includes(currentDomain)) {
                return <VariableMetadataList {...props} domain={currentDomain}/>;
            } else {
                return <VariableDomainList {...props} igID={currentDomain}/>;
            }
        }
    }, [currentDomain, domainList]);

    /**
     *  @memberOf     Variables
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping/info';

        history.push(pathname);
    }, [history]);

    /**
     *  @memberOf     Variables
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                // case COMMAND.DATA_INFO:
                //     if (data.hasOwnProperty('data') && data.data !== null) {
                //         //Table of Contents를 위한 데이터 가공
                //         let _formContentsList = []; //Table of Contents(목차)에 표시 될 Form 목록
                //         let _visitContentsList = []; //Table of Contents(목차)에 표시 될 Visit 목록
                //
                //         //Forms의 목차에 뿌려질 데이터 구성
                //         if (data.data.forms.length > 0) {
                //             data.data.forms.forEach(form => {
                //                 let formObj = {
                //                     name: form.name
                //                 }; //form 정보 생성
                //                 let _studyEventList = []; //study event 목록 저장 변수
                //
                //                 form.studyEvents.forEach(studyEvent => {
                //                     if (studyEvent.itemGroupIDs.length > 0) {
                //                         let studyEventObj = {
                //                             name: studyEvent.name
                //                         }; //study event 정보 생성
                //
                //                         _studyEventList.push(studyEventObj); //study event 목록에 study event 정보 저장
                //                     }
                //                 });
                //
                //                 formObj.children = _studyEventList; //form 정보에 children으로 stduy event 목록 추가
                //
                //                 _formContentsList.push(formObj); //Table of Contents(목차)에 표시 될 Form 목록 추가
                //             });
                //
                //             //Visits의 목차에 뿌려질 데이터 구성
                //             if (data.data.forms.length > 0) {
                //                 let setVisitName = new Set(); //중복 된 study event 이름 제거하여 저장
                //
                //                 //study event 돌면서 study event 이름 저장
                //                 data.data.forms.forEach(form => {
                //                     form.studyEvents.forEach(studyEvent => {
                //                         if (studyEvent.itemGroupIDs.length > 0) {
                //                             setVisitName.add(studyEvent.name);
                //                         }
                //                     });
                //                 });
                //
                //                 //중복 제거 된 study event 이름으로 돌면서 visit 기준 목자 생성
                //                 Array.from(setVisitName).forEach(studyEventName => {
                //                     let _formList = [];
                //                     let studyEventObj = {
                //                         name: studyEventName
                //                     };
                //
                //                     data.data.forms.forEach(form => {
                //                         let findIndex = form.studyEvents.findIndex(studyEvent => studyEvent.name === studyEventName && studyEvent.itemGroupIDs.length > 0);
                //
                //                         if (findIndex > -1) {
                //                             _formList.push({
                //                                 name: form.name
                //                             });
                //                         }
                //                     });
                //
                //                     studyEventObj.children = _formList;
                //
                //                     _visitContentsList.push(studyEventObj);
                //                 });
                //             }
                //
                //             setFormContentsList(_formContentsList);
                //             setVisitContentsList(_visitContentsList);
                //         }
                //
                //         //화면에 그리기 위한 data list 가공
                //         let _processDataList = [];
                //
                //         if (data.data.forms.length > 0) {
                //             //Form 기준으로 화면 만듬
                //             data.data.forms.forEach(form => {
                //                 let _formObj = copyObject(form);
                //                 let _itemGroupIDs = []; //studyEvents에서 itemGroupIDs만 뽑아서 중복 제거 한 배열
                //                 let _studyEventList = []; //가공한 studyEvents 배열
                //
                //                 //Form의 Annotation에 색깔 지정
                //                 //Form에 해당하는 Form Annotation을 찾음
                //                 let filterAnnotations = []; //form에 해당하는 annotation을 저장
                //                 let boxColorObj; //Annotation의 색깔 도메인별로 지정하는 변수
                //
                //                 //form에 해당하는 annotation들을 추출
                //                 if (form.hasOwnProperty("annotationIDs")) {
                //                     form.annotationIDs.forEach(ID => {
                //                         form.allAnnotations.forEach(annotation => {
                //                             if (annotation.ID === ID && annotation.type === "FORM") {
                //                                 filterAnnotations.push(annotation);
                //                             }
                //                         });
                //                     });
                //                 }
                //
                //                 //추출한 annotation 리스트가지고 도메인별로 색깔 지정
                //                 filterAnnotations.forEach((annotation, idx) => {
                //                     boxColorObj = {
                //                         ...boxColorObj,
                //                         [annotation.domain]: BOX_COLOR[idx]
                //                     }
                //                 });
                //
                //                 //form에 annotations 추가
                //                 let formAnnotations = []; //form에 해당하는 annotation을 저장하기 위한 배열 변수
                //
                //                 if (_formObj.hasOwnProperty("annotationIDs")) {
                //                     if (_formObj.annotationIDs.length > 0) { //form에 annotationIDs의 길이가 0개면 추가 필요가 없음
                //                         //form 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                //                         _formObj.annotationIDs.forEach(ID => {
                //                             let findObj = form.allAnnotations.find(annotation => annotation.ID === ID);
                //
                //                             if (findObj !== undefined) {
                //                                 findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가
                //
                //                                 formAnnotations.push(findObj);
                //                             }
                //                         });
                //                     }
                //                 }
                //
                //                 _formObj.annotations = formAnnotations;
                //
                //                 //itemGroupIDs 만 안겹치게 뽑아내기
                //                 form.studyEvents.forEach(studyEvent => {
                //                     let findIdx = _itemGroupIDs.findIndex(data => JSON.stringify(studyEvent.itemGroupIDs) === JSON.stringify(data)); //없는 데이터 찾기 위함
                //
                //                     //찾는 인덱스가 없을 때만 추가
                //                     if (findIdx === -1) {
                //                         _itemGroupIDs.push(studyEvent.itemGroupIDs); //_itemGroupIDs에 itemGroupIDs 값을 넣는다.
                //                     }
                //                 });
                //
                //                 //안겹치게 뽑아낸 itemGroupIDs에 해당하는 study event 값들 넣기
                //                 _itemGroupIDs.forEach(itemGroupIDList => {
                //                     if (itemGroupIDList.length > 0) { //itemGroupID가 있는 경우에만
                //                         let studyEventObj; //studyEvents에서 Study Event 정보 저장해놓기 위한 변수
                //                         let studyEventIDs = []; //Study Event의 ID들 저장하기 위한 변수
                //                         let studyEventNames = []; //Study Event의 Name들 저장하기 위한 변수
                //
                //                         //같은 itemGroup을 가지고 있는 경우 Study Event의 Name을 합치기 위함
                //                         form.studyEvents.forEach(studyEvent => {
                //                             if (JSON.stringify(itemGroupIDList) === JSON.stringify(studyEvent.itemGroupIDs)) { //itemGroupID와 study event에 저장된 itemGroupIDs 와 같으면
                //                                 studyEventObj = copyObject(studyEvent); //Study Event 정보를 저장
                //                                 studyEventIDs.push(studyEvent.ID); //Study Event ID를 배열에 추가
                //                                 studyEventNames.push(studyEvent.name); //Study Event Name을 배열에 추가
                //                             }
                //                         });
                //
                //                         studyEventObj.ID = studyEventIDs.join(', '); //Study Event ID 배열에 ','로 join 하여 study event 정보에 추가
                //                         studyEventObj.name = studyEventNames.join(', '); //Study Event Name 배열에 ','로 join 하여 study event 정보에 추가
                //
                //                         _studyEventList.push(studyEventObj); //가공된 study event 정보를 배열에 추가
                //                     }
                //                 });
                //
                //                 //가공된 studyEvents에 itemGroup 정보 포함
                //                 _studyEventList.forEach(studyEvent => {
                //                     let _itemGroupList = []; //가공한 itemGroups 배열
                //
                //                     //itemGroups 배열을 반복
                //                     form.itemGroups.forEach(itemGroup => {
                //                         let _itemGroupObj = copyObject(itemGroup); //itemGroup을 저장
                //
                //                         //studyEvent 정보에 itemGroupIDs을 반복
                //                         for (let igID of studyEvent.itemGroupIDs) {
                //                             //Study Event와 ItemGroup의 ID를 비교하여 ID가 같으면 정보 저장
                //                             if (igID === itemGroup.ID) { //ID가 같다면
                //                                 let _itemList = []; //itemGroup에 포함된 item들 저장
                //
                //                                 //itemGroup에 포함된 items를 반복
                //                                 // eslint-disable-next-line no-loop-func
                //                                 _itemGroupObj.items.forEach(item => {
                //                                     let _itemObj = copyObject(item);
                //                                     let _codelistList = []; //item에 포함된 codeList ID를 보고 만들 codeList 배열
                //
                //                                     //item의 componentType이 CheckBox나 DropDownList나 RadioButton(RadioButton, RadioButton (Vertical))이 포함되어 있다면
                //                                     if (_itemObj.componentType === "CheckBox" || _itemObj.componentType === "DropDownList" || _itemObj.componentType.includes("RadioButton")) {
                //                                         //item에 포함된 codelistIDs를 돌면서 codelists 배열의 ID와 비교
                //                                         item.codelistIDs.forEach(ID => {
                //                                             form.codelists.forEach(code => {
                //                                                 //ID가 같다면 codelist 정보를 배열에 추가
                //                                                 if (ID === code.ID) {
                //                                                     let codeListAnnotation = [];
                //
                //                                                     if (code.hasOwnProperty("annotationIDs")) {
                //                                                         //CodeList 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                //                                                         code.annotationIDs.forEach(ID => {
                //                                                             const findObj = form.allAnnotations.find(annotation => annotation.ID === ID);
                //
                //                                                             if (findObj !== undefined) {
                //                                                                 findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가
                //
                //                                                                 codeListAnnotation.push(findObj);
                //                                                             }
                //                                                         });
                //                                                     }
                //
                //                                                     code.annotations = codeListAnnotation;
                //
                //                                                     _codelistList.push(code);
                //                                                 }
                //                                             });
                //                                         });
                //
                //                                         _itemObj.codelists = _codelistList; //item에 codelists키를 추가하여 codelist 리스트를 저장
                //                                     }
                //
                //                                     //item에 annotations 추가
                //                                     let itemAnnotations = []; //item에 해당하는 annotation을 저장하기 위한 배열 변수
                //
                //                                     if (_itemObj.hasOwnProperty("annotationIDs")) {
                //                                         if (_itemObj.annotationIDs.length > 0) { //item에 annotationIDs의 길이가 0개면 추가 필요가 없음
                //                                             //item 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                //                                             _itemObj.annotationIDs.forEach(ID => {
                //                                                 let findObj = form.allAnnotations.find(annotation => annotation.ID === ID);
                //
                //                                                 if (findObj !== undefined) {
                //                                                     findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가
                //
                //                                                     itemAnnotations.push(findObj);
                //                                                 }
                //                                             });
                //                                         }
                //                                     }
                //
                //                                     _itemObj.annotations = itemAnnotations;
                //
                //                                     _itemList.push(_itemObj); //가공한 item 정보를 item 리스트에 저장
                //                                 });
                //
                //                                 //itemGroup에 annotations 추가
                //                                 let igAnnotations = []; //itemGroup에 해당하는 annotation을 저장하기 위한 배열 변수
                //
                //                                 if (_itemGroupObj.hasOwnProperty("annotationIDs")) {
                //                                     if (_itemGroupObj.annotationIDs.length > 0) { //itemGroup에 annotationIDs의 길이가 0개면 추가 필요가 없음
                //                                         //itemGroup 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                //                                         // eslint-disable-next-line no-loop-func
                //                                         _itemGroupObj.annotationIDs.forEach(ID => {
                //                                             let findObj = form.allAnnotations.find(annotation => annotation.ID === ID);
                //
                //                                             if (findObj !== undefined) {
                //                                                 findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가
                //
                //                                                 igAnnotations.push(findObj);
                //                                             }
                //                                         });
                //                                     }
                //                                 }
                //
                //                                 _itemGroupObj.annotations = igAnnotations;
                //                                 _itemGroupObj.items = _itemList; //itemGroup의 items 키에 가공된 item 리스트를 저장
                //
                //                                 _itemGroupList.push(_itemGroupObj); //가공된 itemGroup 정보를 itmeGroup 리스트에 저장
                //                             }
                //                         }
                //                     });
                //
                //                     studyEvent.itemGroups = _itemGroupList; //studyEvent의 itemGroups 키에 가공된 itemGroup 리스트 저장
                //                 });
                //
                //                 //화면 그리기 위해 필요한 JSON 생성
                //                 _processDataList.push({
                //                     ..._formObj,
                //                     studyEvents: _studyEventList
                //                 });
                //             });
                //         }
                //
                //         setProcessedDataList(_processDataList);
                //         setACRFList(data.data.forms);
                //         setCoverInfo(data.data.coverInfo);
                //     }
                //     break;
                //
                // case COMMAND.FILE_DOWNLOAD:
                //     if (data.hasOwnProperty('data')) {
                //         getDataList(); //Variables 탭에 표시될 리스트 요청
                //     }
                //     break;

                case COMMAND.DATA_LIST:
                    let classNameList = [];
                    let setClassName = new Set(); //response data를 className으로 set한다.
                    let classNameArr = [];
                    let domainArr = [];

                    data.data.forEach(data => {
                        domainArr.push(data.domain);
                    });
                    setDomainList(domainArr);

                    data.data.forEach(data => {
                        setClassName.add(data.className);
                    });

                    classNameArr = Array.from(setClassName); //set 해온 데이터를 array로 만들어줌

                    //set 해온 className array 반복문으로
                    classNameArr.forEach(className => {
                        let setDomainName = new Set(); //domain을 set하기 위하여
                        let domainNameArr = [];
                        let childrenDomain = []; //children에 들어갈 domain list를 만듬
                        let _dataList = data.data.filter((data) => data.className === className); //className이 같은것만 찾는다

                        //className이 같은 리스트를 돌면서 domain을 set 한다.
                        _dataList.forEach(row => {
                            setDomainName.add(row.domain);
                        });

                        domainNameArr = Array.from(setDomainName);

                        domainNameArr.forEach(domainName => {
                            let setFormName = new Set();
                            let formNameArr = [];
                            let formNameList = data.data.filter((data) => data.domain === domainName);
                            let findObj = data.data.find(data => data.domain === domainName);
                            let childrenFormName = []; //children에 들어갈 formName list를 만듬

                            formNameList.forEach(row => {
                                setFormName.add(row.formName);
                            });

                            formNameArr = Array.from(setFormName);

                            formNameArr.forEach(formName => {
                                let igNameList = data.data.filter((data) => data.domain === domainName);
                                let childrenIGName = []; //children에 들어갈 itemgroup list를 만듬

                                igNameList.forEach(row => {
                                    //form name 같을 때만 추가
                                    if (row.formName === formName) {
                                        if (row.className === "Trial Design") {
                                            childrenIGName.push({
                                                key: row.itemGroupName,
                                                title: row.itemGroupName,
                                                type: "last",
                                                icon: <i className={"fas fa-genderless"}/>
                                            });
                                        } else {
                                            childrenIGName.push({
                                                key: row.itemGroupID,
                                                title: row.itemGroupName,
                                                type: "last",
                                                icon: <i className={"fas fa-genderless"}/>
                                            });
                                        }
                                    }
                                });

                                if (formName.includes("DataSet")) {
                                    childrenFormName.push({
                                        key: formName,
                                        title: formName,
                                        type: "last",
                                        icon: <i className={"fas fa-genderless"}/>
                                    });
                                } else {
                                    childrenFormName.push({
                                        key: domainName + formName,
                                        title: formName,
                                        children: childrenIGName
                                    });
                                }
                            });

                            childrenDomain.push({
                                key: domainName,
                                title: domainName,
                                children: findObj !== undefined && findObj.used === 0 ? [] : childrenFormName,
                                disabled: findObj !== undefined && findObj.used === 0
                            });
                        });

                        classNameList.push({
                            key: className,
                            title: className,
                            children: childrenDomain,
                        });
                    });
                    setDataList(classNameList);

                    let findDomainObj = data.data.find(data => data.used === 1);
                    setCurrentDomain(domain || findDomainObj.domain);
                    break;

                //no default
            }
        }
    }, [domain]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //ACRF 데이터 요청
    useEffect(() => {
        // getACRFInfo();
        getDataList();
    }, [getDataList]);

    // //ACRFList와 ACRF 가공 데이터가 있을때 acrf 생성 api 요청
    // useEffect(() => {
    //     if (ACRFList.length > 0 && processedDataList.length > 0) {
    //         handleDownload();
    //     }
    // }, [ACRFList.length, handleDownload, processedDataList.length]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>
                <div className="vertical-box-column width-200 bg-light">
                    <div className="vertical-box">
                        <div className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <PerfectScrollbar className="height-full" options={{suppressScrollXY: true}}>
                                        <div className="wrapper p-0">
                                            {/*Trial Design 밖에 없을때, 다른 Class들 있을때 같이 조건을 쓰면 Tree가 전부 닫혀있음 그래서 현재 이렇게 처리*/}
                                            {dataList !== undefined && renderDomainList()}
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TreeContents
                    history={history}
                    onList={goToList}/>
            </div>

            {/*<div id={"acrf-main-area"}>*/}
            {/*    <ACRFCover coverInfo={coverInfo}/>*/}

            {/*    <ACRFTableOfContents*/}
            {/*        formContentsList={formContentsList}*/}
            {/*        visitContentsList={visitContentsList}/>*/}

            {/*    {getFormComp()}*/}
            {/*</div>*/}
        </>
    );
};

export default React.memo(Variables);