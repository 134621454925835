import React, {useCallback, useEffect, useRef, useState} from "react";
import cn from "classnames";
import html2canvas from "html2canvas";
import {CSVLink} from "react-csv";
import XLSX from "xlsx"
import {produce} from "immer";
import {AXIOS_GET, AXIOS_PUT_FORM, copyObject, getSessionState, isDataExist} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {REPLACE_DATA} from "constant/ConstantMsg";
import {TERMS} from "constant/ConstantName";
import {DESIGN_SYNOPSIS_URL} from "constant/ConstantURL";
import useToast from "hooks/useToast";
import DesignBottom from "../DesignBottom";
import {showConfirm} from "../../../components/alertModal";
import TextAreaSearch from "../../../components/TextAreaSearch";
import CriteriaGrid from "./CriteriaGrid";
import CriteriaEditModal from "./CriteriaEditModal";
import {showSimpleAlert} from "../../../common/alertModal";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     CriteriaConfig
 *  @constant     {Object} NAME
 *  @description  데이터 입력 name
 */
export const NAME = {
    INCLUSION: TERMS.INCLUSION,
    EXCLUSION: TERMS.EXCLUSION
};

/**
 *  @memberOf     CriteriaConfig
 *  @constant     {Object} VALIDATION_FIELD
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    [NAME.INCLUSION]: true,
    [NAME.EXCLUSION]: true
};

/**
 *  @memberOf     CriteriaConfig
 *  @constant     {Object} DEFAULT_DATA_OBJECT
 *  @description  전체 데이터 default 값
 */
const DEFAULT_DATA_OBJECT = {
    [NAME.INCLUSION]: [],
    [NAME.EXCLUSION]: []
};

/**
 *  @memberOf     CriteriaConfig
 *  @constant     {Object} DEFAULT_INPUT_OBJECT
 *  @description  전체 데이터 입력란 default 값
 */
const DEFAULT_INPUT_OBJECT = {
    [NAME.INCLUSION]: {
        ID: '',
        name: '',
        type: ''
    },
    [NAME.EXCLUSION]: {
        ID: '',
        name: '',
        type: ''
    }
};

/**
 *  @memberOf     CriteriaConfig
 *  @constant     {Array} headers
 *  @description  excel download header 데이터
 */
const headers = [
    {
        label: "NO",
        key: "no"
    },
    {
        label: "TYPE",
        key: "type"
    },
    {
        label: "CRITERIA",
        key: "criteria"
    }
];

/**
 *  @memberOf     CriteriaConfig
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {dataID, sendObject, searchText, searchType} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO:
            url = `${DESIGN_SYNOPSIS_URL}/criteria/${dataID}`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE:
            url = `${DESIGN_SYNOPSIS_URL}/criteria/${dataID}`;
            response = await AXIOS_PUT_FORM(url, sendObject);
            break;

        case COMMAND.DATA_SEARCH:
            url = `${DESIGN_SYNOPSIS_URL}/criteria/search/${dataID}?type=${searchType}&text=${searchText}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @see          html2canvas (https://github.com/niklasvh/html2canvas)
 *  @see          xlsx (https://github.com/SheetJS/sheetjs)
 *  @see          CSVLink(https://github.com/react-csv/react-csv#readme)
 *  @component    CriteriaConfig
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  design information 의 Criteria 편집 컴포넌트
 */
const CriteriaConfig = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      CriteriaConfig
     *  @type          {Object} props
     *  @property      {String} ID - 리스트 ID
     *  @property      {Object} history - url 이동을 위해 사용
     *  @property      {Function} referencePopup - reference 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onChangeData - 변경 전 데이터, 변경 후 데이터 호출하는 함수
     *  @property      {Boolean } isSaved - isDataChange Modal에서 Yes 버튼 상태
     *  @property      {Boolean} onSetSave - isDataChange Modal Yes 버튼 클릭 상태 처리
     *  @property      {Boolean} isClosed - isDataChange Modal에서 No 버튼 상태
     *  @property      {Boolean} onSetClose - isDataChange Modal No 버튼 클릭 상태 처리
     *  @property      {Boolean} onDataChangeModal - isDataChange Modal 상태 처리
     *  @property      {Boolean} isChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태
     *  @property      {Boolean} isChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태
     *  @property      {Boolean} onChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property      {Boolean} onChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property      {Boolean} onChangeDataCheckModal - Top Tab의 isDataChange Modal 상태 처리
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        history,
        referencePopup,
        onList,
        onChangeData,
        isSaved,
        onSetSave,
        isClosed,
        onSetClose,
        onDataChangeModal,
        isChangeTabSave,
        isChangeTabClose,
        onChangeTabSave,
        onChangeTabClose,
        onChangeDataCheckModal
    } = props;

    /**
     *  @memberOf      CriteriaConfig
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock, language} = getSessionState();

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {HTMLDivElement} captureRef
     *  @description  저장 시 file 객체로 전송할 이미지 캡쳐 영역 ref
     */
    const captureRef = useRef(null);

    /**
     *  @memberOf       CriteriaConfig
     *  @var            {Object} dataInfo
     *  @description    화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(DEFAULT_DATA_OBJECT);

    /**
     *  @memberOf       CriteriaConfig
     *  @var            {Object} originDataInfo
     *  @description    원본데이터
     */
    const [originDataInfo, setOriginDataInfo] = useState(DEFAULT_DATA_OBJECT);

    /**
     *  @memberOf       CriteriaConfig
     *  @var            {Object} inputInfo
     *  @description    현재 입력된 criteria input 데이터
     */
    const [inputInfo, setInputInfo] = useState(DEFAULT_INPUT_OBJECT);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Array} inclusionSearchData
     *  @description  inclusion 검색 결과 리스트
     */
    const [inclusionSearchData, setInclusionSearchData] = useState([]);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Array} exclusionSearchData
     *  @description  exclusion 검색 결과 리스트
     */
    const [exclusionSearchData, setExclusionSearchData] = useState([]);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Number} timer
     *  @description  검색 디바운싱 timer
     */
    const [timer, setTimer] = useState(0);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Object} selected
     *  @description  select 컴포넌트 선택 여부
     */
    const [selected, setSelected] = useState({
        [NAME.INCLUSION]: false,
        [NAME.EXCLUSION]: false
    });

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Array} excelDownloadList
     *  @description  excel Download 리스트
     */
    const [excelDownloadList, setExcelDownloadList] = useState([]);

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Object} currentCriteria
     *  @description  변경 할 Criteria 의 값
     */
    const [currentCriteria, setCurrentCriteria] = useState({});

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Number} criteriaIndex
     *  @description  변경 할 Criteria 의 Index
     */
    const [criteriaIndex, setCriteriaIndex] = useState();

    /**
     *  @memberOf     CriteriaConfig
     *  @var          {Boolean} isEditModal
     *  @description  Criteria edit 팝업 오픈 여부
     */
    const [isEditModal, setIsEditModal] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CriteriaConfig
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        const params = {
            dataID: ID
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     getSearchDataList
     *  @param        {String} searchType - 검색 타입 ('inclusion' | 'exclusion')
     *  @param        {String} searchText - 검색어
     *  @description  검색 리스트 api 호출
     */
    const getSearchDataList = useCallback((searchType, searchValue) => {
        const command = COMMAND.DATA_SEARCH; // 데이터 리스트 요청 command
        const params = {
            dataID: ID,
            searchText: searchValue,
            searchType: searchType
        };

        if (netWorkAgent.current !== null) {
            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleChange
     *  @param        {String} name   - 입력 요소의 tag name
     *  @param        {String} value  - 입력 되거나 변경 된 값
     *  @param        {String} action  - action 타입 값 ('menu-close' | 'input-blur' - ReactSelect 내부 액션 값)
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((value, name, action) => {
        if (name === 'inclusion') {
            if (inputInfo[name].name === '') {
                setInclusionSearchData([]);
            }
        }

        if (name === 'exclusion') {
            if (inputInfo[name].name === '') {
                setExclusionSearchData([]);
            }
        }

        // select list 가 닫혀있다면 onchange 이벤트 차단 (자동으로 change 처리 됨)
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            // 입력란에 데이터 입력시 변경 처리
            setInputInfo(
                produce(inputInfo, draft => {
                    draft[name] = {
                        ID: "",
                        name: value,
                        type: name
                    };
                })
            );
        }
    }, [inputInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleSelect
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 되거나 변경 된 값
     *  @description  검색 컴포넌트 내용 선택 시 실행되는 함수
     */
    const handleSelect = useCallback((name, value) => {
        // selection 한 항목 저장
        setInputInfo(
            produce(inputInfo, draft => {
                draft[name] = {
                    name: value.target.textContent,
                    ID: value.target.textContent,
                    type: name
                };
            })
        );

        setSelected(prevState => ({
            [name]: !prevState[name]
        }));

        if (name === 'exclusion') {
            const searchExclusion = [
                {
                    label: value.target.textContent,
                    value: value.target.textContent
                }
            ];

            setExclusionSearchData(searchExclusion);
        }
        if (name === 'inclusion') {
            const searchInclusion = [
                {
                    label: value.target.textContent,
                    value: value.target.textContent
                }
            ];

            setExclusionSearchData(searchInclusion);
        }
    }, [inputInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleAdd
     *  @param        {String} name - 클릭한 요소의 tag name
     *  @description  추가 버튼 클릭 시 실행되는 함수
     */
    const handleAdd = useCallback((name) => {
        let _inputInfo = copyObject(inputInfo);
        _inputInfo[name].name = _inputInfo[name].name.replaceAll('\n', '<br/>'); //gird에 전달 되는 값이기 때문에 \n을 <br/> 로 변경 작업

        if (lock !== 2) {
            // 입력란에 데이터가 없는 경우 invalid 표 시
            if (isDataExist(inputInfo[name].name) === false) {
                setValidation(
                    produce(validation, draft => {
                        draft[name] = false;
                    })
                );

                return;
            }

            // data list 에 항목 추가하기
            setDataInfo(
                produce(dataInfo, draft => {
                    draft[name].push(_inputInfo[name]);
                })
            );

            // 추가한 후 입력창 내용 비우기
            setInputInfo(
                produce(inputInfo, draft => {
                    draft[name] = {
                        ID: "",
                        name: "",
                        type: ""
                    };
                })
            );
        }
    }, [dataInfo, inputInfo, lock, validation]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(VALIDATION_FIELD); // validation 할 key
        let checkValidData = {}; // validation 결과 객체
        let returnBool = true;

        // data validate 체크
        for (let key of validateKeys) {
            checkValidData[key] = isDataExist(dataInfo[key]);
        }

        // inclusion list 에 데이터가 없지만 입력란에 데이타가 있는 경우에는 통과
        if (checkValidData[NAME.INCLUSION] === false) {
            if (isDataExist(inputInfo[NAME.INCLUSION].name)) {
                checkValidData[NAME.INCLUSION] = true;
            }
        }

        // exclusion list 에 데이터가 없지만 입력란에 데이타가 있는 경우에는 통과
        if (checkValidData[NAME.EXCLUSION] === false) {
            if (isDataExist(inputInfo[NAME.EXCLUSION].name)) {
                checkValidData[NAME.EXCLUSION] = true;
            }
        }

        setValidation(checkValidData); // 결과 값 setting

        returnBool = Object.values(checkValidData).every(item => item === true); // 모든 validate 결과가 true 일 때 true

        return returnBool;
    }, [dataInfo, inputInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는 입력란이지 포함
        if (validation.hasOwnProperty(name)) {
            // 현재 invalid 일 때만 변경 처리
            if (validation[name] === false) {
                setValidation(
                    produce(validation, draft => {
                        draft[name] = true;
                    })
                );
            }
        }
    }, [validation]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleReference
     *  @description  ref 버튼 클릭시 실행되는 함수
     */
    const handleReference = useCallback(() => {
        if (referencePopup !== undefined) {
            referencePopup();
        }
    }, [referencePopup]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleGridChange
     *  @param        {Array}  list  - Endpoint 변경 된 List
     *  @param        {String} name - criteria 구분(inclusion, exclusion)
     *  @description  grid 변경 시(수정, Drag & Drop, 삭제) 실행되는 함수
     */
    const handleGridChange = useCallback((list, name) => {
        let _dataInfo = copyObject(dataInfo);

        _dataInfo[name] = [...list];

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     getImageBlob
     *  @param        {Function} callback - image 저장 후 실행시킬 callback 함수
     *  @description  save 버튼 클릭 시 실행되어 현재 캡쳐 영역 image 생성 후 callback 함수 실행
     */
    const getImageBlob = useCallback((callback) => {
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0);
            const blobBin = window.atob(image.split(',')[1]);	// base64 데이터 디코딩
            const array = [];

            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }

            const blobImage = new Blob([new Uint8Array(array)], {type: 'image/png'});	// Blob 생성

            callback(blobImage);
        });
    }, []);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     saveDataInfo
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  image 생성 시 callback 으로 실행되어 데이터 저장 api 호출
     */
    const saveDataInfo = useCallback((image) => {
        const command = COMMAND.DATA_UPDATE; // 데이터 수정 요청
        const formData = new FormData();
        let sendData = copyObject(DEFAULT_DATA_OBJECT); // 전송 하기 위한 오브젝트 setting

        // 화면에서 사용된 데이터 setting
        for (let nameKey in dataInfo) {
            for (let rowItem of dataInfo[nameKey]) {
                let addItem = {
                    ID: rowItem.ID,
                    name: rowItem.name.replaceAll('\n', '<br/>')
                };

                sendData[nameKey].push(addItem);


            }
        }

        //textarea에 값이 있을때 입력되어있는 값 추가되도록
        for (let name in dataInfo) {
            if (isDataExist(inputInfo[name].name)) {
                let addItem = {
                    ID: inputInfo[name].ID,
                    name: inputInfo[name].name.replaceAll('\n', '<br/>')
                };

                sendData[name].push(addItem);
            }
        }

        formData.append("data", JSON.stringify(sendData));
        formData.append("file", image, "criteria.png");

        const params = {
            dataID: ID,
            sendObject: formData
        };

        (netWorkAgent.current !== null && netWorkAgent.current.request(command, params)); // back-end 데이터 처리 요청
    }, [ID, dataInfo, inputInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleChangeData
     *  @description  원본 데이터와 화면에 보이는 데이터를 전달하는 함수
     */
    const handleChangeData = useCallback(() => {
        onChangeData(dataInfo, originDataInfo);
    }, [dataInfo, onChangeData, originDataInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        const valid = validateData();
        if (valid === false) {
            return;
        }

        getImageBlob(saveDataInfo);
    }, [saveDataInfo, getImageBlob, validateData]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     excelDownload
     *  @description  criteria list download 함수
     */
    const excelDownload = useCallback(() => {
        let _dataInfo = copyObject(dataInfo);
        let downloadList = [];

        dataInfo.inclusion.forEach((el, index) => {
            const findIndex = el.name.indexOf(`${index + 1}:`);

            if (findIndex < 0) {
                let inclusionList = {
                    "no": index + 1,
                    "type": el.type,
                    "criteria": el.name
                };

                downloadList.push(inclusionList);
            } else {
                const inclusionIndexOf = el.name.indexOf(`:`);
                const inclusionData = el.name.slice(inclusionIndexOf + 2, el.name.length);
                let inclusionList = {
                    "no": index + 1,
                    "type": el.type,
                    "criteria": inclusionData
                };

                downloadList.push(inclusionList);
            }
        });

        _dataInfo.exclusion.forEach((el, index) => {
            const findIndex = el.name.indexOf(`${index + 1}:`);

            if (findIndex < 0) {
                let exclusionList = {
                    "no": index + 1,
                    "type": el.type,
                    "criteria": el.name
                };

                downloadList.push(exclusionList);
            } else {
                const exclusionIndexOf = el.name.indexOf(`:`);
                const exclusionData = el.name.slice(exclusionIndexOf + 2, el.name.length);
                let exclusionList = {
                    "no": index + 1,
                    "type": el.type,
                    "criteria": exclusionData
                };

                downloadList.push(exclusionList);
            }
        });

        setExcelDownloadList(downloadList);
    }, [dataInfo])

    /**
     *  @memberOf     CriteriaConfig
     *  @function     excelExport
     *  @description  excel file upload 함수
     */
    const excelExport = useCallback((e) => {
        if (e.target.files[0].name !== 'Criteria_Template.csv') {
            showSimpleAlert("This file format is not supported.")
        } else {
            const input = e.target;
            let reader = new FileReader();
            if (dataInfo.inclusion.length === 0 && dataInfo.exclusion.length === 0) {
                reader.onload = () => {
                    const fileData = reader.result;
                    const wb = XLSX.read(fileData, {type: 'binary'});
                    const deleteAllList = {
                        ...dataInfo,
                        inclusion: [],
                        exclusion: []
                    }; //기존 Criteria list 전체 삭제
                    let _dataInfo = {...deleteAllList};

                    wb.SheetNames.forEach((sheetName) => {
                        const rowList = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);

                        rowList.forEach((data) => {
                            if (data.TYPE === "inclusion" || data.TYPE === "exclusion") {
                                if (data.CRITERIA !== undefined) {
                                    _dataInfo[data.TYPE] = Object.assign([], _dataInfo[data.TYPE]);

                                    _dataInfo[data.TYPE].push({
                                        ID: "",
                                        name: `${data.CRITERIA}`,
                                        name_id: "",
                                        type: data.TYPE
                                    });
                                }
                            }
                        });
                    });

                    setDataInfo(_dataInfo);
                };

                reader.readAsBinaryString(input.files[0]);
            } else {
                showConfirm(REPLACE_DATA, () => { // confirm 창 오픈 (yes 시에만 save 가능))
                    reader.onload = () => {
                        const fileData = reader.result;
                        const wb = XLSX.read(fileData, {type: 'binary'});
                        const deleteAllList = {
                            ...dataInfo,
                            inclusion: [],
                            exclusion: []
                        }; //기존 Criterian list 전체 삭제
                        let _dataInfo = {...deleteAllList};

                        wb.SheetNames.forEach((sheetName) => {
                            const rowList = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                            rowList.forEach((data) => {
                                if (data.TYPE === "inclusion" || data.TYPE === "exclusion") {
                                    if (data.CRITERIA !== undefined) {
                                        _dataInfo[data.TYPE] = Object.assign([], _dataInfo[data.TYPE]);

                                        _dataInfo[data.TYPE].push({
                                            ID: "",
                                            name: `${data.CRITERIA}`,
                                            name_id: "",
                                            type: data.TYPE
                                        });
                                    }
                                }
                            });
                        });

                        setDataInfo(_dataInfo);
                    };

                    reader.readAsBinaryString(input.files[0]);
                });
            }
        }
    }, [dataInfo]);

    /**
     *  @memberOf     SetupConfig
     *  @function     handleOpenPopup
     *  @param        {String} event - 클릭한 cell의 row 정보
     *  @description  테이블 cell 클릭하면 실행되어 Modal 열기 기능
     */
    const handleOpenPopup = useCallback((event) => {
        event.item.name = event.item.name.replaceAll('<br/>', '\n'); //Modal의 TextArea에 표시되는 값이기 때문에 <br/>을 \n 으로 변경 작업

        setCurrentCriteria(event.item); //선택 된 Criteria 값 저장
        setCriteriaIndex(event.rowIndex); //선택 된 Criteria Index 저장
        setIsEditModal(!isEditModal); //Criteria 수정하기 위한 Modal Open
    }, [isEditModal]);

    /**
     *  @memberOf     SetupConfig
     *  @function     handleCriteriaSave
     *  @param        {Object} data - 변경한 데이터
     *  @param        {Number} idx - 변경한 Criteria Index
     *  @description  Criteria Edit 팝업 OK 눌렀을때 호출 되는 함수
     */
    const handleCriteriaSave = useCallback((data, idx) => {
        const _dataInfo = copyObject(dataInfo);

        _dataInfo[data.type][idx] = data; //수정 된 값 저장

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     CriteriaConfig
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 검색어
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command, params} = action;

            switch (command) {
                // 상세 정보 응답
                case COMMAND.DATA_INFO :
                    // 데이터가 있는 경우
                    if (data.hasOwnProperty('data')) {
                        let _newData = copyObject(data.data);
                        let newCriteriaList = {
                            inclusion: [],
                            exclusion: []
                        };

                        _newData.inclusion.forEach(data => {
                            newCriteriaList.inclusion.push({
                                ID: data.ID,
                                type: data.type,
                                name_id: data.name_id,
                                name: data.name
                            });
                        });

                        _newData.exclusion.forEach(data => {
                            newCriteriaList.exclusion.push({
                                ID: data.ID,
                                type: data.type,
                                name_id: data.name_id,
                                name: data.name
                            });
                        });

                        setDataInfo(newCriteriaList);
                        setOriginDataInfo(newCriteriaList);
                    } else {
                        setDataInfo(DEFAULT_DATA_OBJECT);
                        setOriginDataInfo(DEFAULT_DATA_OBJECT);
                    }

                    // 입력란은 데이터 초기화
                    setInputInfo(DEFAULT_INPUT_OBJECT);
                    break;

                // 데이터 수정에 대한 응답시, 상세 정보 요청
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                // inclusion 검색 결과
                case COMMAND.DATA_SEARCH :
                    if (data.hasOwnProperty('data')) {
                        const list = data.data[params.searchType].map((object) => {
                            return {
                                label: object.name,
                                value: object.name
                            };
                        });

                        if (params.searchType === 'inclusion') {
                            setInclusionSearchData(list);
                        } else if (params.searchType === 'exclusion') {
                            setExclusionSearchData(list);
                        }
                    }
                    break;

                // no default
            }
        }
    }, [getDataInfo, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // inclusion 검색 시 디바운싱 처리 (검색 속도 늦추기)
    useEffect(() => {
        // 이전 등록된 timer가 있을 경우 clear
        if (timer) {
            clearTimeout(timer);
        }

        // input 입력 값이 있을 경우
        if (inputInfo[NAME.INCLUSION].name !== '') {
            // debouncing 처리
            // 마지막 호출만 적용하기 위해 타이머 실행 전 기존 timer 가 입력됐을 시 clear 처리
            // timer 설정 -> 0.8초 뒤 검색 기능 실행
            const newTimer = setTimeout(() => {
                getSearchDataList(NAME.INCLUSION, inputInfo[NAME.INCLUSION].name);
            }, 800);

            setTimer(newTimer); // timer 설정
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputInfo.inclusion, getSearchDataList]);

    // exclusion 검색 시 디바운싱 처리 (검색 속도 늦추기)
    useEffect(() => {
        // 이전 등록된 timer가 있을 경우 clear
        if (timer) {
            clearTimeout(timer);
        }

        if (inputInfo[NAME.EXCLUSION].name !== '') {
            // debouncing 처리
            // 마지막 호출만 적용하기 위해 타이머 실행 전 기존 timer 가 입력됐을 시 clear 처리
            // timer 설정 -> 0.8초 뒤 검색 기능 실행
            const newTimer = setTimeout(() => {
                getSearchDataList(NAME.EXCLUSION, inputInfo[NAME.EXCLUSION].name);
            }, 800);

            setTimer(newTimer); // timer 설정
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputInfo.exclusion, getSearchDataList]);

    // handleSelect 한 후 입력칸 hidden 처리 없애기
    React.useLayoutEffect(() => {
        const inputEl = document.getElementById(NAME.EXCLUSION);
        if (!inputEl) {
            return;
        }

        // prevent input from being hidden after selecting
        inputEl.style.opacity = "1";
    }, [selected.exclusion]);

    // handleSelect 한 후 입력칸 hidden 처리 없애기 (custom 기능 처리)
    React.useLayoutEffect(() => {
        const inputEl = document.getElementById(NAME.INCLUSION);
        if (!inputEl) {
            return;
        }

        // prevent input from being hidden after selecting
        inputEl.style.opacity = "1";
    }, [selected.inclusion]);

    // origin data와 화면에 보이는 data를 전달하는 함수 호출
    useEffect(() => {
        handleChangeData();
    }, [handleChangeData]);


    //데이터 변경 후 Tab 이동 시 저장하라고 나오는 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isSaved === true) {
            onSetClose(false);
            handleSave();
            onSetSave(false);
        }

        if (isClosed === true && isSaved === false) {
            getDataInfo();
            onSetClose(false);
        }

        onDataChangeModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosed, isSaved, onDataChangeModal, onSetClose, onSetSave]);

    // Design Top Tab에서 데이터 변경 후 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isChangeTabSave === true) {
            onChangeTabClose(false);
            handleSave();
            onChangeTabSave(false);
        }

        if (isChangeTabClose === true && isChangeTabSave === false) {
            getDataInfo();
            onChangeTabClose(false);
        }
        onChangeDataCheckModal(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeTabClose, isChangeTabSave, onChangeDataCheckModal, onChangeTabClose, onChangeTabSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box-row">
                <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                        <div className="scroll-design height-full p-30 overflow-auto">
                            <div ref={captureRef}>
                                {/*excel*/}
                                <div className="m-b-30">
                                    {/*<div className="d-flex justify-content-end align-content-center">*/}
                                    {/*    <button*/}
                                    {/*        className={cn('btn btn-secondary font-weight-bold text-white m-r-10 px-3 px-md-3 mr-2', {'disabled': lock === 2})}*/}
                                    {/*        onClick={excelDownload}>*/}
                                    {/*        {lock === 1 ? (*/}
                                    {/*            <CSVLink*/}
                                    {/*                className="text-white text-decoration-none"*/}
                                    {/*                data={excelDownloadList}*/}
                                    {/*                headers={headers}*/}
                                    {/*                filename="Criteria_Template.csv"*/}
                                    {/*                target="_blank">*/}
                                    {/*                Download*/}
                                    {/*            </CSVLink>*/}
                                    {/*        ) : (*/}
                                    {/*            <div className="text-white text-decoration-none">*/}
                                    {/*                Download*/}
                                    {/*            </div>*/}
                                    {/*        )}*/}
                                    {/*    </button>*/}
                                    {/*    {lock === 1 ? (*/}
                                    {/*        <div className="filebox">*/}
                                    {/*            <label for="ex_file">Upload</label>*/}

                                    {/*            <input type="file" id="ex_file"*/}
                                    {/*                   onChange={(e) => excelExport(e)}*/}
                                    {/*                   onClick={(event) => event.target.value = null}/>*/}
                                    {/*        </div>*/}
                                    {/*    ) : (*/}
                                    {/*        <div className="disabled_filebox">*/}
                                    {/*            <label htmlFor="ex_file">Upload</label>*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                </div>

                                {/* Inclusion Criteria */}
                                <div className="row align-items-center m-b-20">
                                    <label className="col-2">
                                        <span>{language === 'en' ? 'Inclusion Criteria' : '선정기준'}</span>
                                        <span className="ml-3 text-danger">*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            {dataInfo[NAME.INCLUSION] && dataInfo[NAME.INCLUSION].length > 0 && (
                                                <CriteriaGrid
                                                    ID={'inclusion-grid'}
                                                    name={NAME.INCLUSION}
                                                    columnName={language === 'en' ? 'Inclusion' : '선정기준'}
                                                    dataList={dataInfo[NAME.INCLUSION]}
                                                    onChange={handleGridChange}
                                                    onClick={handleOpenPopup}
                                                    gridHeight={300}/>
                                            )}

                                            <table
                                                className="table table-striped table-valign-middle"
                                                style={{tableLayout: "fixed"}}>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <TextAreaSearch
                                                            style={{resize: 'none'}}
                                                            name={NAME.INCLUSION}
                                                            onChange={(value, action) => handleChange(value, NAME.INCLUSION, action)}
                                                            currentValue={isDataExist(inputInfo) && inputInfo[NAME.INCLUSION].name}
                                                            onFocus={handleFocus}
                                                            placeholder={language === 'en' ? 'Input Inclusion Criteria...' : '선정기준 입력...'}
                                                            validation={validation[NAME.INCLUSION]}/>
                                                    </td>

                                                    <td className="text-right" style={{width: '10%'}}>
                                                        <div className=" d-flex justify-content-end">
                                                            <button
                                                                className={cn("btn btn-blue border-0 m-l-5", {'disabled': lock === 2})}
                                                                onClick={() => handleAdd(NAME.INCLUSION)}>
                                                                +
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                {isDataExist(inclusionSearchData) && (
                                                    inputInfo.inclusion.name !== '' && (
                                                        inclusionSearchData.length !== 1 ? (
                                                            <div className="dropdownBox">
                                                                <div
                                                                    className="p-t-5"
                                                                    name={NAME.INCLUSION}
                                                                    onClick={(value) => handleSelect(NAME.INCLUSION, value)}
                                                                    onChange={(value) => handleChange(value, NAME.INCLUSION)}>
                                                                    {inclusionSearchData.map((data) => {
                                                                        return (
                                                                            <div className="dropBoxSearchText">
                                                                                {data.value}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            inclusionSearchData[0].value !== inputInfo.inclusion.ID && (
                                                                <div
                                                                    className="dropdownBox2"
                                                                    name={NAME.INCLUSION}
                                                                    onClick={(value) => handleSelect(NAME.INCLUSION, value)}
                                                                    onChange={(value) => handleChange(value, NAME.INCLUSION)}>

                                                                    <div className="dropBoxSearchOneText">
                                                                        {inclusionSearchData.map((data) => {
                                                                            return (
                                                                                <div className="dropBoxSearchText">
                                                                                    {data.value}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* Exclusion Criteria */}
                                <div className={"row align-items-center"}>
                                    <label className="col-2">
                                        <span>{language === 'en' ? 'Exclusion Criteria' : '제외기준'}</span>
                                        <span className="ml-3 text-danger">*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            {dataInfo[NAME.EXCLUSION] && dataInfo[NAME.EXCLUSION].length > 0 && (
                                                <CriteriaGrid
                                                    ID={'exclusion-grid'}
                                                    name={NAME.EXCLUSION}
                                                    columnName={language === 'en' ? 'Exclusion' : '제외기준'}
                                                    dataList={dataInfo[NAME.EXCLUSION]}
                                                    onChange={handleGridChange}
                                                    onClick={handleOpenPopup}
                                                    gridHeight={600}
                                                />
                                            )}

                                            <table
                                                className="table table-striped table-valign-middle"
                                                style={{tableLayout: "fixed"}}>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <TextAreaSearch
                                                            style={{resize: 'none'}}
                                                            name={NAME.EXCLUSION}
                                                            onChange={(value, action) => handleChange(value, NAME.EXCLUSION, action)}
                                                            currentValue={isDataExist(inputInfo) && inputInfo[NAME.EXCLUSION].name}
                                                            onFocus={handleFocus}
                                                            placeholder={language === 'en' ? 'Input Exclusion Criteria...' : '제외기준 입력...'}
                                                            validation={validation[NAME.EXCLUSION]}/>
                                                    </td>

                                                    <td className="text-right" style={{width: '10%'}}>
                                                        <div className=" d-flex justify-content-end">
                                                            <button
                                                                className={cn("btn btn-blue border-0 m-l-5", {'disabled': lock === 2})}
                                                                onClick={() => handleAdd(NAME.EXCLUSION)}>
                                                                +
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>

                                                {isDataExist(exclusionSearchData) && (
                                                    inputInfo.exclusion.name !== '' && (
                                                        exclusionSearchData.length !== 1 ? (
                                                            <div className="dropdownBox">
                                                                <div
                                                                    className="p-t-5"
                                                                    name={NAME.EXCLUSION}
                                                                    onClick={(value) => handleSelect(NAME.EXCLUSION, value)}
                                                                    onChange={(value) => handleChange(value, NAME.EXCLUSION)}>
                                                                    {exclusionSearchData.map((data) => {
                                                                        return (
                                                                            <div className="dropBoxSearchText">
                                                                                {data.value}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            inputInfo.exclusion.name !== '' && (
                                                                exclusionSearchData[0].value !== inputInfo.exclusion.ID && (
                                                                    <div
                                                                        className="dropdownBox2"
                                                                        name={NAME.EXCLUSION}
                                                                        onClick={(value) => handleSelect(NAME.EXCLUSION, value)}
                                                                        onChange={(value) => handleChange(value, NAME.EXCLUSION)}>
                                                                        <div className="dropBoxSearchOneText">
                                                                            {exclusionSearchData.map((data) => {
                                                                                return (
                                                                                    <div className="dropBoxSearchText">
                                                                                        {data.value}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                        )))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 하단 처리 버튼 */}
            <DesignBottom onList={goToList} onRef={handleReference} onSave={handleSave}/>

            {isEditModal && (
                <CriteriaEditModal
                    data={currentCriteria}
                    criteriaIndex={criteriaIndex}
                    onClose={() => setIsEditModal(!isEditModal)}
                    onSave={handleCriteriaSave}
                    readOnly={lock === 2}/>
            )}
        </>
    );
};

export default React.memo(CriteriaConfig);
