import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AXIOS_GET} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "constant/ConstantURL";
import AUIGrid from "../../../../../components/AUIGrid";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     FunctionSetModal
 *  @constant     {Array} COLUMN_LIST
 *  @description  AUIGrid Column 정보
 */
const COLUMN_LIST = [
    {
        dataField: "class",
        headerText: "Category",
        editable: false
    },
    {
        dataField: "functionName",
        headerText: "Function",
        editable: false
    },
    {
        dataField: "domain",
        headerText: "Object Group",
        editable: false
    },
    {
        dataField: "description",
        headerText: "Description",
        width: "55%",
        editable: false
    },
    {
        dataField: "functionID",
        headerText: "Function ID",
        width: "15%",
        editable: false
    }
];

/**
 *  @memberOf     FunctionSetModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, variable} = params;
    let url = null
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/function/${variable}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    FunctionSetModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 Variable 도메인 에서 실행 되는 Function Setting 컴포넌트
 */
const FunctionSetModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     TEDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Object} event - 선택 된 줄 정보
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @property     {Function} onSave - 상위로 선택 된 Function 전달 하기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, event, onClose, onSave} = props;

    /**
     *  @memberOf     FunctionSetModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     FunctionSetModal
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     FunctionSetModal
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     FunctionSetModal
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST; // 데이터 리스트 요청 command
        // parameter 설정
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            variable: event.item.SDTMVariable
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [event.item.SDTMVariable]);

    /**
     *  @memberOf     FunctionSetModal
     *  @function     checkedFunction
     *  @param        {String} functionID - 선택 된 Function ID
     *  @description  Function 칸에 값이 있다면 Function Setting 창의 리스트에서 해당 ID 체크 하는 함수
     */
    const checkedFunction = useCallback((functionID) => {
        if (dataList !== undefined) {
            let item = dataList.find((data) => data.functionID === functionID);

            if (item !== undefined) {
                const params = {
                    num: 5,
                    functionID: item.functionID
                };

                auiGrid.current.request(params);
            }
        }
    }, [dataList]);

    /**
     *  @memberOf     FunctionSetModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        const params = {
            num: 4
        };

        auiGrid.current.request(params);
    }, []);

    /**
     *  @memberOf     FunctionSetModal
     *  @function     gridResponse
     *  @param        {Object} data - 그리드에서 선택 된 정보
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((data) => {
        //선택이 안됐을 경우는 data가 undefined
        if (data !== undefined) {
            onSave(event.dataField, event.rowIndex, data);
        }

        onClose();
    }, [event.dataField, event.rowIndex, onClose, onSave]);

    /**
     *  @memberOf     FunctionSetModal
     *  @function     handleClose
     *  @description  Function Select 창 닫기
     */
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    /**
     *  @memberOf     FunctionSetModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    if (data.hasOwnProperty('data')) {
                        setDataList(data.data);
                    }
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [getDataList]);

    // functionID의 값이 있는지 없는지 체크 후, Function 체크 요청
    useEffect(() => {
        if (event.item.functionID !== undefined) {
            checkedFunction(event.item.functionID);
        }
    }, [checkedFunction, event, event.item.functionID]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    Function Settings
                </ModalHeader>

                <ModalBody>
                    <AUIGrid
                        ref={auiGrid}
                        id="function-modal-grid"
                        columnList={COLUMN_LIST}
                        dataList={dataList}
                        response={gridResponse}
                        isRadio={true}/>
                </ModalBody>

                <ModalFooter>
                    <button
                        className="btn btn-new btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleSave}>
                        OK
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(FunctionSetModal);
