import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT, getSessionState, handleExamplePopup} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import AUIGrid from "../../../../../components/AUIGrid";
import DomainExplain from "../../../../../components/DomainExplain";
import SdmEditModal from "./SdmEditModal";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     TADomainList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/tdomain/${ID}?domain=${domain}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/tdomain/${domain}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    TADomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TA 도메인 컴포넌트
 */
const TADomainList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     TADomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     TADomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     TADomainList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     TADomainList
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     TADomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     TADomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     TADomainList
     *  @var          {String} descriptionValue
     *  @description  Description 입력 된 값
     */
    const [descriptionValue, setDescriptionValue] = useState('');

    /**
     *  @memberOf     TADomainList
     *  @var          {String} sdmEditPopupOpen
     *  @description  SDM Edit 팝업 상태
     */
    const [sdmEditPopupOpen, setSdmEditPopupOpen] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     TADomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
            domain: "TA"
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     TADomainList
     *  @function     gridResponse
     *  @param        {Array} list - 그리드 목록
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((list) => {
        if (list) {
            let dataList = [];

            list.forEach((row, idx) => {
                dataList.push({
                    ...row,
                    row: (idx + 1),
                    domain: "TA"
                });
            });

            setDataList(dataList);
        }
    }, []);

    /**
     *  @memberOf     TADomainList
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     TADomainList
     *  @function     handleDrag
     *  @param        {Array} list - Grid에서 순서 변경 된 목록
     *  @description  Grid에서 row drag & drop 시 호출되는 함수
     */
    const handleDrag = useCallback((list) => {
        setDataList(list);
    }, []);

    /**
     *  @memberOf     TADomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescriptionValue(e.target.value);
    }, []);

    /**
     *  @memberOf     TADomainList
     *  @function     handlePopupOpen
     *  @description  SDM EDIT 팝업 오픈 함수
     */
    const handlePopupOpen = useCallback(() => {
        setSdmEditPopupOpen(true);
    }, []);

    /**
     *  @memberOf     TADomainList
     *  @function     handleClose
     *  @description  Modal 창 닫기
     */
    const handleClose = useCallback(() => {
        setSdmEditPopupOpen(false);
        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     TADomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';
        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     TADomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let params = {};
            let _dataList = [...dataList];

            _dataList.forEach((data, idx) => {
                data.order = idx + 1;
            });

            let sendObj = {
                data: _dataList,
                description: descriptionValue
            };

            // 데이터 생성에 필요한 parameter
            params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                domain: "TA",
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, descriptionValue, lock]);

    /**
     *  @memberOf     TADomainList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     TADomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let dataList = [];

                    data.data.forEach((row, idx) => {
                        dataList.push({
                            ...row,
                            row: (idx + 1),
                            domain: "TA"
                        });
                    });

                    setDataList(dataList);
                    setDescriptionValue(data.description);
                    break;

                case COMMAND.DATA_UPDATE:
                    //스크롤위치 초기화
                    vertical = 0;
                    horizontal = 0;

                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        //스크롤위치 초기화
        vertical = 0;
        horizontal = 0;

        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     TADomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "3%",
            editable: false
        },
        {
            dataField: "STUDYID",
            headerText: "STUDYID",
            headerStyle: "aui-grid-header-red",
            width: "15%",
            editable: lock !== 2
        },
        {
            dataField: "domain",
            headerText: "DOMAIN",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "ARMCD",
            headerText: "ARMCD",
            headerStyle: "aui-grid-header-red",
            width: "5%",
            editable: false
        },
        {
            dataField: "ARM",
            headerText: "ARM",
            headerStyle: "aui-grid-header-red",
            editable: false
        },
        {
            dataField: "TAETORD",
            headerText: "TAETORD",
            headerStyle: "aui-grid-header-red"
        },
        {
            dataField: "ETCD",
            headerText: "ETCD",
            headerStyle: "aui-grid-header-red",
            editable: false
        },
        {
            dataField: "ELEMENT",
            headerText: "ELEMENT",
            headerStyle: "aui-grid-header-green",
            editable: false
        },
        {
            dataField: "TABRANCH",
            headerText: "TABRANCH",
            headerStyle: "aui-grid-header-blue",
            editable: lock !== 2
        },
        {
            dataField: "TATRANS",
            headerText: "TATRANS",
            headerStyle: "aui-grid-header-blue",
            editable: lock !== 2
        },
        {
            dataField: "EPOCH",
            headerText: "EPOCH",
            headerStyle: "aui-grid-header-red",
            editable: false
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "0 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">

                                            <div className="align-self-end">
                                                <DomainExplain/>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                <div className="d-flex justify-content-end m-b-10">
                                                    <button
                                                        className={cn("btn btn-blue px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                        onClick={handlePopupOpen}>
                                                        SDM EDIT
                                                    </button>

                                                    <button
                                                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                                                        onClick={() => handleExamplePopup("TA")}>
                                                        Example
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            ref={auiGrid}
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            isDrag={true}
                                            onDrag={handleDrag}
                                            response={gridResponse}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}
                                            minusContentHeight={236}/>

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={descriptionValue}
                                                style={{resize: "none", height: "80px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {sdmEditPopupOpen && (
                <SdmEditModal
                    ID={ID}
                    onClose={handleClose}
                />
            )}
        </>
    );
};

export default React.memo(TADomainList);
