import React, {useCallback} from "react";
import cn from "classnames";
import {getSessionState} from "../../../common/commonFunction";

/**
 *  @author        주예리나
 *  @version       1.0
 *  @component     DesignBottom
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  Design 상세 화면 내 하단 버튼 영역 컴포넌트
 */
const DesignBottom = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DesignBottom
     *  @type         {Object} props
     *  @property     {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Function} onOpen - Preview 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {String} previewBtnName - preview 버튼 이름
     *  @property     {Function} onRef - ref 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property     {Function} onSave - save 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {onList, onOpen, previewBtnName, onRef, onSave} = props;

    /**
     *  @memberOf      DesignBottom
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     DesignBottom
     *  @function     handleListButton
     *  @description  list 버튼 클릭 시 실행되는 함수
     */
    const handleListButton = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    /**
     *  @memberOf     DesignBottom
     *  @function     handleOpen
     *  @description  Preview 버튼 클릭 시 실행되는 함수
     */
    const handleOpen = useCallback(() => {
        if (onOpen !== undefined) {
            onOpen();
        }
    }, [onOpen]);

    /**
     *  @memberOf     DesignBottom
     *  @function     handleRefButton
     *  @description  ref 버튼 클릭 시 실행되는 함수
     */
    const handleRefButton = useCallback(() => {
        if (onRef !== undefined) {
            onRef();
        }
    }, [onRef]);

    /**
     *  @memberOf     DesignBottom
     *  @function     handleSaveButton
     *  @description  save 버튼 클릭 시 실행되는 함수
     */
    const handleSaveButton = useCallback(() => {
        if (lock !== 2) {
            if (onSave !== undefined) {
                onSave();
            }
        }
    }, [lock, onSave]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className="d-flex justify-content-between p-10 border-top bg-light">
                <div>
                    {/* list button */}
                    {onList !== undefined && (
                        <button
                            className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                            onClick={handleListButton}>
                            List
                        </button>
                    )}

                    {/* Preview Modal Open button */}
                    {onOpen !== undefined && (
                        <button
                            className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                            onClick={handleOpen}>
                            {previewBtnName}
                        </button>
                    )}
                </div>

                <div>
                    {/* ref button */}
                    {onRef !== undefined && (
                        <button
                            className="btn btn-blue btn-inner-shadow text-white px-3 px-md-5 mx-1"
                            onClick={handleRefButton}>
                            Ref.
                        </button>
                    )}

                    {/* save button */}
                    {onSave !== undefined && (
                        <button
                            className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                            onClick={handleSaveButton}>
                            Save
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default React.memo(DesignBottom);
