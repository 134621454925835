import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import {AXIOS_GET, copyObject, isDataExist} from "../../../../../common/commonFunction";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import produce from "immer";
import NetworkLayout from "../../../../../common/NetworkLayout";
import SearchDropdown from "../../../../components/SearchDropdown";
import TextField from "../../../../components/TextField";
import RadioButton from "../../../../components/RadioButton";
import cn from "classnames";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     DataSetsNewModal
 *  @constant     {Object} DATASETS_DEFINE
 *  @description  field 명 정의
 */
const DATASETS_DEFINE = {
    DOMAIN: 'domain',
    CLASS: 'class',
    REPEATING: 'repeating',
    NON_STANDARD: 'nonStandard',
    STANDARD: 'standard',
    NAME: 'name',
    STRUCTURE: 'structure',
    REFERENCE_DATA: 'referenceData',
    COMMENT: 'comment',
    DESCRIPTION: 'description',
    KEY_VARIABLE: 'keyVariable',
    SAS_DATASET_NAME: 'SASDatasetName',
    DEVELOPER_NOTE: 'developerNote'
};

/**
 *  @memberOf     DataSetsNewModal
 *  @constant     {Object} VALIDATION_FIELD
 *  @description  validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    domain: true,
    class: true,
    repeating: true,
    standard: true,
    name: true,
    structure: true,
    SASDatasetName: true
};

/**
 *  @memberOf     DataSetsNewModal
 *  @constant     {Array} YES_NO_OPTION
 *  @description  Radio Button에 사용되는 option 값
 */
const YES_NO_OPTION = [
    {
        text: "Yes",
        value: "Yes"
    },
    {
        text: "No",
        value: "No"
    }
];

/**
 *  @memberOf     DataSetsNewModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, value} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.SEARCH_DOMAIN:
            url = `${requestUrl}/search/${ID}?text=${value}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    DataSetsNewModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Datasets Tab의 Add Domain Modal 컴포넌트
 */
const DataSetsNewModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     DataSetsNewModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} mappingID - 리스트 ID
     *  @property     {Function} onSave - 현재 Modal 저장 위한 함수
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, mappingID, onSave, onClose} = props;

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState({});

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {Object} domainList
     *  @description  Domain 입력 항목에 검색시 출력 되는 리스트
     */
    const [domainList, setDomainList] = useState([]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {Boolean} isLoaded
     *  @description  검색 로딩 여부
     */
    const [isLoaded, setIsLoaded] = useState(false);

    /**
     *  @memberOf     DataSetsNewModal
     *  @var          {Number} timer
     *  @description  검색 컴포넌트 debouncing timer
     */
    const [timer, setTimer] = useState(0);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     getDomainList
     *  @param        {String} value - Domain 검색 값
     *  @description  입력 값에 따른 Domain 리스트 요청 함수. Domain list 조회 api 호출
     */
    const getDomainList = useCallback((value) => {
        const command = COMMAND.SEARCH_DOMAIN;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: mappingID,
            value: value
        };

        netWorkAgent.current.request(command, params);
    }, [mappingID]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleSearch
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} searchValue - 검색하기 위해 입력 된 값
     *  @description  Terminology 값 입력시 실행 되는 함수. Terminology List 요청 함수 호출.
     */
    const handleSearch = useCallback((name, searchValue) => {
        if (timer) {
            clearTimeout(timer);
        }

        if (searchValue !== '') {
            setIsLoaded(true);

            // timer 설정 -> 0.8초 뒤 검색 기능 실행
            const newTimer = setTimeout(() => {
                getDomainList(searchValue);
            }, 800);

            setTimer(newTimer);
        }
    }, [getDomainList, timer]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleSelect
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {Object} selectValue - 리스트에서 선택된 값
     *  @description  조회 된 목록에서 값 선택 하면 호출
     */
    const handleSelect = useCallback((name, selectValue) => {
        if (selectValue !== null) {
            let _dataInfo = copyObject(dataInfo);
            _dataInfo[DATASETS_DEFINE.DOMAIN] = selectValue.value.domain; //Domain 항목
            _dataInfo[DATASETS_DEFINE.NAME] = selectValue.value.domain; //Name 항목
            _dataInfo[DATASETS_DEFINE.SAS_DATASET_NAME] = selectValue.value.domain; //SAS Dataset Name 항목
            _dataInfo[DATASETS_DEFINE.CLASS] = selectValue.value.class; //Class 항목
            _dataInfo[DATASETS_DEFINE.STRUCTURE] = selectValue.value.structure; //Structure 항목
            _dataInfo[DATASETS_DEFINE.DESCRIPTION] = selectValue.value.description; //Description 항목
            _dataInfo[DATASETS_DEFINE.KEY_VARIABLE] = selectValue.value.keys; //Key Variable 항목
            _dataInfo[DATASETS_DEFINE.STANDARD] = selectValue.value.standard; //Key Variable 항목

            setDataInfo(_dataInfo);
            setIsLoaded(false);
        }
    }, [dataInfo]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 된 값
     *  @description  html element 데이터 입력할 경우 실행
     */
    const handleChange = useCallback((name, value) => {
        let _dataInfo = copyObject(dataInfo);
        _dataInfo[name] = value;

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleChangeCheckbox
     *  @param        {Object} event - 입력 요소의 event
     *  @description  checkbox 데이터 변경시 실행
     */
    const handleChangeCheckbox = useCallback((event) => {
        let _dataInfo = copyObject(dataInfo);
        _dataInfo[event.target.id] = event.target.checked ? 1 : 0;

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        for (let key of validateKeys) {
            if (dataInfo.nonStandard === 1) { //Non Standard가 선택 되어있다면
                validateData[DATASETS_DEFINE.STANDARD] = true; //Standard는 validation 통과
            }

            validateData[key] = isDataExist(dataInfo[key]);
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, validation]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        const valid = validateData(); // 데이터 validation
        if (!valid) {
            return;
        }

        if (onSave !== undefined) {
            onSave(dataInfo);
        }
    }, [dataInfo, onSave, validateData]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     handleClose
     *  @description  Modal 창 닫는 함수
     */
    const handleClose = useCallback((num) => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     DataSetsNewModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.SEARCH_DOMAIN:
                    if (isDataExist(data.data)) {
                        let _domainList = [];

                        //response 된 data로 search의 리스트 값 생성
                        data.data.forEach(data => {
                            _domainList.push({
                                label: data.domain,
                                value: data
                            });
                        });

                        setDomainList(_domainList);
                    }
                    break;

                //no default
            }
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 key 리스트 요청
    useEffect(() => {

    }, []);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen={true} className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    Add Domain
                </ModalHeader>

                <ModalBody style={{position: "relative"}}>
                    {/*Domain*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Domain</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <SearchDropdown
                                    name={DATASETS_DEFINE.DOMAIN}
                                    dataList={domainList}
                                    isLoading={isLoaded}
                                    currentValue={dataInfo && dataInfo[DATASETS_DEFINE.DOMAIN]}
                                    onSelect={handleSelect}
                                    onChange={handleSearch}
                                    placeHolder={"Search Domain..."}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.DOMAIN]}/>
                            </div>
                        </div>
                    </div>

                    {/*Class*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Class</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.CLASS}
                                    currentValue={dataInfo[DATASETS_DEFINE.CLASS]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.CLASS]}
                                    disabled={true}/>
                            </div>
                        </div>
                    </div>

                    {/*Repeating*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Repeating</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <RadioButton
                                    name={DATASETS_DEFINE.REPEATING}
                                    onChange={handleChange}
                                    currentValue={dataInfo[DATASETS_DEFINE.REPEATING]}
                                    dataList={YES_NO_OPTION}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.REPEATING]}/>
                            </div>
                        </div>
                    </div>

                    {/*Non Standard*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Non Standard</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <input
                                    type="checkbox"
                                    id={DATASETS_DEFINE.NON_STANDARD}
                                    checked={dataInfo[DATASETS_DEFINE.NON_STANDARD]}
                                    onChange={handleChangeCheckbox}/>
                            </div>
                        </div>
                    </div>

                    {/*Standard*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Standard</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.STANDARD}
                                    currentValue={dataInfo[DATASETS_DEFINE.STANDARD]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.STANDARD]}
                                    disabled={dataInfo.nonStandard === 1}/>
                            </div>
                        </div>
                    </div>

                    {/*Name*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Name</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.NAME}
                                    currentValue={dataInfo[DATASETS_DEFINE.NAME]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.NAME]}/>
                            </div>
                        </div>
                    </div>

                    {/*Structure*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Structure</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.STRUCTURE}
                                    currentValue={dataInfo[DATASETS_DEFINE.STRUCTURE]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.STRUCTURE]}/>
                            </div>
                        </div>
                    </div>

                    {/*Reference Data*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Reference Data</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <RadioButton
                                    name={DATASETS_DEFINE.REFERENCE_DATA}
                                    onChange={handleChange}
                                    currentValue={dataInfo[DATASETS_DEFINE.REFERENCE_DATA]}
                                    dataList={YES_NO_OPTION}/>
                            </div>
                        </div>
                    </div>

                    {/*Comment*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Comment</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.COMMENT}
                                    currentValue={dataInfo[DATASETS_DEFINE.COMMENT]}
                                    onChange={handleChange}/>
                            </div>
                        </div>
                    </div>

                    {/*Description*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Description</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.DESCRIPTION}
                                    currentValue={dataInfo[DATASETS_DEFINE.DESCRIPTION]}
                                    onChange={handleChange}/>
                            </div>
                        </div>
                    </div>

                    {/*Key Variable*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Key Variable</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.KEY_VARIABLE}
                                    currentValue={dataInfo[DATASETS_DEFINE.KEY_VARIABLE]}
                                    onChange={handleChange}
                                    disabled={false}/>
                            </div>
                        </div>
                    </div>

                    {/*SAS Dataset Name*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>SAS Dataset Name</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.SAS_DATASET_NAME}
                                    currentValue={dataInfo[DATASETS_DEFINE.SAS_DATASET_NAME]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[DATASETS_DEFINE.SAS_DATASET_NAME]}/>
                            </div>
                        </div>
                    </div>

                    {/*Developer Note*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Developer Note</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={DATASETS_DEFINE.DEVELOPER_NOTE}
                                    currentValue={dataInfo[DATASETS_DEFINE.DEVELOPER_NOTE]}
                                    onChange={handleChange}/>
                            </div>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-end'}>
                        <button
                            className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                            onClick={handleClose}>
                            Close
                        </button>

                        <button
                            className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1")}
                            onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(DataSetsNewModal);
