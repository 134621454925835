import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT, getSessionState} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import AUIGrid from "../../../../../components/AUIGrid";
import FunctionSetModal from "./FunctionSetModal";
import CTSetModal from "./CTSetModal";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     VariableDomainList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, itemGroupID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/domain/list/${ID}?itemGroupID=${itemGroupID}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/domain/list/${ID}?itemGroupID=${itemGroupID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    VariableDomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 Variable 도메인 컴포넌트
 */
const VariableDomainList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     VariableDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {String} igID - 선택된 Variable 도메인의 item Group ID
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, igID} = props;

    /**
     *  @memberOf     VariableDomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     VariableDomainList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /**
     *  @memberOf     VariableDomainList
     *  @var          {String} descriptionValue
     *  @description  Description 입력 된 값
     */
    const [descriptionValue, setDescriptionValue] = useState('');

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Boolean} isFunctionModal
     *  @description  Function Setting 창 Open/Close 상태
     */
    const [isFunctionModal, setFunctionModal] = useState(false);

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Object} event
     *  @description  선택된 줄 정보
     */
    const [event, setEvent] = useState();

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Boolean} isCTModal
     *  @description  CT Setting 창 Open/Close 상태
     */
    const [isCTModal, setIsCTModal] = useState(false);

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Array} codeList
     *  @description  서버에서 받아온 codelist 저장 값
     */
    const [codeList, setCodeList] = useState();

    /**
     *  @memberOf     VariableDomainList
     *  @var          {Array} currentCodeList
     *  @description  CT Modal에 넘겨줄 code list
     */
    const [currentCodeList, setCurrentCodeList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     VariableDomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
            itemGroupID: igID
        };

        netWorkAgent.current.request(command, params);
    }, [ID, igID]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     handleCellClick
     *  @param        {Object} event - 선택한 줄 정보
     *  @description  Function 칸 클릭시 호출되는 함수
     */
    const handleCellClick = useCallback((event) => {
        if (lock !== 2) {
            if (event.dataField === "functionName") {
                setEvent(event);
                setFunctionModal(!isFunctionModal);
            } else if (event.dataField === "CT") {
                setEvent(event);
                setIsCTModal(!isCTModal);

                //codeList를 response 받을때 넣어주면 value 값이 겹쳐서 갯수 만큼 list가 추가된다.
                //그래서 codelist 따로 state 에 저장해서 filter 하여 전달중.
                let filterCodeList = codeList.filter(code => code.codeGroupID === dataList[event.rowIndex]["codeGroupID"]);

                setCurrentCodeList(filterCodeList);
            }
        }
    }, [codeList, dataList, isCTModal, isFunctionModal, lock]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     handleDelete
     *  @param        {Number} num - modal 구분 값
     *  @description  Modal 창 닫기
     */
    const handleClose = useCallback((num) => {
        if (num === 1) {
            setFunctionModal(false);
        } else if (num === 2) {
            setIsCTModal(false);
        }
    }, []);

    /**
     *  @memberOf     VariableDomainList
     *  @function     getFunctionInfo
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {Object} functionInfo - 변경 된 값
     *  @description  Function Setting 창에서 값 수정 후 호출되는 함수
     */
    const getFunctionInfo = useCallback((dataField, rowIndex, functionInfo) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = functionInfo.functionName;
        _dataList[rowIndex]["functionID"] = functionInfo.functionID;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     getCTInfo
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {Object} ctInfo - 변경 된 값
     *  @description  Codelist Edit 창에서 값 수정 후 호출되는 함수
     */
    const getCTInfo = useCallback((dataField, rowIndex, ctInfo) => {
        let _dataList = dataList;

        _dataList[rowIndex][dataField] = ctInfo.value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        if (dataField !== "functionName") {
            _dataList[rowIndex][dataField] = value.toString(); //체크값을 string으로 받고있음
        }
        //이거 나중에 테스트 해봐야 할 코드 (Function 컬럼에 두개 이상 값이 있어서 선택 가능할때)
        // else if(dataField === "functionName") {
        //     _dataList[rowIndex][dataField] = value.functionName;
        //     _dataList[rowIndex]["functionID"] = value.functionID;
        // }

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescriptionValue(e.target.value);
    }, []);

    /**
     *  @memberOf     VariableDomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let params = {};
            let sendObj = {
                data: {list: dataList, codeList: codeList},
                description: descriptionValue
            };

            // 데이터 수정에 필요한 parameter
            params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                itemGroupID: igID,
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, codeList, dataList, descriptionValue, igID, lock]);

    /**
     *  @memberOf     VariableDomainList
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /**
     *  @memberOf     VariableDomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let _dataList = []; //where 에 \ 이 표시 제거한 리스트 저장 변수

                    data.data.list.forEach(data => {
                        if (data.condition !== "") { //where 컬럼에 표시될때 \" 이값이 표시되는데 제거하기 위해 추가
                            _dataList.push({...data, condition: data.condition.replaceAll('\\"', "")});
                        } else {
                            _dataList.push({...data});
                        }
                    });

                    setDataList(_dataList);
                    setDescriptionValue(data.description);
                    setCodeList(data.data.codeList);
                    break;

                case COMMAND.DATA_UPDATE:
                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        if (igID !== "" && igID !== undefined && igID !== null) {
            getDataList();

            vertical = 0;
            horizontal = 0;
        }
    }, [getDataList, igID]);

    /**
     *  @memberOf     VariableDomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "variable",
            headerText: "Variable",
            width: "10%",
            editable: false
        },
        {
            dataField: "fieldAlias",
            headerText: "FieldAlias",
            width: "10%"
        },
        {
            dataField: "collected",
            headerText: "Collected",
            width: "5%",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: false, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "enable",
            headerText: "Enable",
            width: "5%",
            renderer: {
                type: "CheckBoxEditRenderer",
                editable: lock !== 2, // 체크박스 편집 활성화 여부(기본값 : false)
                checkValue: 1, // true, false 인 경우가 기본
                unCheckValue: 0
            }
        },
        {
            dataField: "SDTMVariable",
            headerText: "SDTM Variable",
            width: "10%",
            editable: false
        },
        {
            dataField: "core",
            headerText: "Core",
            width: "10%",
            editable: false
        },
        {
            dataField: "SDTMDomain",
            headerText: "SDTM Domain",
            width: "10%",
            editable: false
        },
        {
            dataField: "QNAM",
            headerText: "QNAM",
            width: "10%",
            editable: false
        },
        {
            dataField: "functionName",
            headerText: "Function",
            width: "10%",
            editable: false
        },
        {
            dataField: "functionID",
            width: "0%",
            editable: false
        },
        {
            dataField: "value",
            headerText: "Value",
            width: "10%"
        },
        {
            dataField: "condition",
            headerText: "Where",
            width: "20%",
            editable: false
        },
        {
            dataField: "CT",
            headerText: "CT",
            width: "10%",
            editable: false
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "15px 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <AUIGrid
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            onCellClick={handleCellClick}
                                            minusContentHeight={236}
                                            scrollInfo={{vertical: vertical, horizontal: horizontal}}
                                            onScrollChange={getScrollInfo}/>

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={descriptionValue}
                                                style={{resize: "none", height: "100px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isFunctionModal && (
                <FunctionSetModal
                    onClose={() => handleClose(1)}
                    onSave={getFunctionInfo}
                    history={history}
                    event={event}/>
            )}

            {isCTModal && (
                <CTSetModal
                    onClose={() => handleClose(2)}
                    onSave={getCTInfo}
                    history={history}
                    codeList={currentCodeList}
                    event={event}/>
            )}
        </>
    );
};

export default React.memo(VariableDomainList);
