import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {produce} from "immer";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {AXIOS_GET, copyObject, getSessionState, isDataExist} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {YN_OPTION} from "constant/ConstantList";
import {DESIGN_SYNOPSIS_URL, DESIGN_URL} from "constant/ConstantURL";
import CheckAllList from "../../../components/CheckAllList";
import RadioButton from "imtrial/components/RadioButton";
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";
import TreatmentTableList from "./TreatmentTableList";
import {YES_NO_DICTIONARY} from "./IPConfig";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} IP_OPTION
 *  @description  Intervention 옵션 리스트의 IP 항목
 */
const IP_OPTION = {
    text: "IP",
    value: "investigation"
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} IP_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
const IP_OPTION_KR = {
    text: "임상시험용 의약품",
    value: "investigation"
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} ACTIVITY_CONTROL_OPTION
 *  @description  Intervention 옵션 리스트의 Active Control 항목
 */
const ACTIVITY_CONTROL_OPTION = {
    text: 'Active control',
    value: 'active',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} ACTIVITY_CONTROL_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블

 */
const ACTIVITY_CONTROL_OPTION_KR = {
    text: '활성대조약',
    value: 'active',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} PLACEBO_OPTION
 *  @description  Intervention 옵션 리스트의 Placebo 항목
 */
const PLACEBO_OPTION = {
    text: 'Placebo',
    value: 'placebo',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} PLACEBO_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
const PLACEBO_OPTION_KR = {
    text: '위약',
    value: 'placebo',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} DRUG_OPTION
 *  @description  Intervention 옵션 리스트의 Concomitant Drug 항목
 */
const DRUG_OPTION = {
    text: 'Concomitant Drug',
    value: 'concomitant',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} DRUG_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
const DRUG_OPTION_KR = {
    text: '병행 투여 약물',
    value: 'concomitant',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} PRE_TREATMENT_OPTION
 *  @description  Intervention 옵션 리스트의 Pre-treatment Drug 항목
 */
const PRE_TREATMENT_OPTION = {
    text: 'Pre-treatment Drug',
    value: 'pretreatment'
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} PRE_TREATMENT_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
const PRE_TREATMENT_OPTION_KR = {
    text: '전처지 약물',
    value: 'pretreatment'
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} RESCUE_OPTION
 *  @description  Intervention 옵션 리스트의 Rescue Drug 항목
 */
const RESCUE_OPTION = {
    text: 'Rescue Drug',
    value: 'others'
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} RESCUE_OPTION_KR
 *  @description  language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
const RESCUE_OPTION_KR = {
    text: '구제약',
    value: 'others'
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} VALIDATE_FILED_IP
 *  @description  개별 IP input validation 체크에 사용 되는 필드
 */
const VALIDATE_FILED_IP = {
    name: true,
    type: true,
    amount: true
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Array} EPOCH_OPTION_LIST
 *  @description  epoch 이름 선택 리스트
 */
const EPOCH_OPTION_LIST = [
    {
        text: 'Baseline',
        value: 'Baseline',
    },
    {
        text: 'Blinded Treatment',
        value: 'Blinded Treatment',
    },
    {
        text: 'Continuation Treatment',
        value: 'Continuation Treatment',
    },
    {
        text: 'Follow-Up',
        value: 'Follow-Up',
    },
    {
        text: 'Induction Treatment',
        value: 'Induction Treatment',
    },
    {
        text: 'Long-Term Follow-Up',
        value: 'Long-Term Follow-Up',
    },
    {
        text: 'Observation',
        value: 'Observation',
    },
    {
        text: 'Open Label Treatment',
        value: 'Open Label Treatment',
    },
    {
        text: 'Run-In',
        value: 'Run-In',
    },
    {
        text: 'Screening',
        value: 'Screening',
    },
    {
        text: 'Treatment',
        value: 'Treatment',
    },
    {
        text: 'Washout',
        value: 'Washout',
    },
    {
        text: 'Other (Specify)',
        value: 'Other',
    },
];

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Array} INIT_EDIT_FIELD
 *  @description  cell 아이템에 필요한 데이터 default 값
 */
const INIT_EDIT_FIELD = {
    ID: '',
    ipID: '',
    cellID: '',
    type: '',
    name: '',
    shape: '',
    amount: 1,
    dose: '',
    unit: '',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} CELL_DATA_FIELD
 *  @description  cell 에 필요한 field 명 정의
 */
export const CELL_DATA_FIELD = {
    ID: 'ID',
    ARMIDS: 'armIDs',
    EPOCHID: 'epochID',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @constant     {Object} VALIDATION_FIELD
 *  @description  validation 이 필요한 데이터 field 명 정의
 */
const VALIDATION_FIELD = {
    IP: 'ip',
    ADMINISTERED: 'administered',
    ARMIDS: 'armIDs',
    EPOCH_NAME: 'epochName',
};

/**
 *  @memberOf     SchemaEditPopup
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, type} = params;
    let response = null;
    let url = null;

    switch (command) {
        case COMMAND.IP_INFO :
            url = `${requestUrl}/ip/${ID}`;
            response = await AXIOS_GET(url);
            break;

        // IP 데이터 상세 정보 요청
        case COMMAND.IP_LIST :
            url = `${requestUrl}/synopsis/ip/meta/${ID}?type=${type}`;
            response = await AXIOS_GET(url);
            break;

        // Design 데이터 상세 정보 요청
        case COMMAND.DESIGN_INFO :
            url = `${requestUrl}/synopsis/design/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author         주예리나
 *  @version        1.0
 *  @component      SchemaEditPopup
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    design information Schema Config 내 편집 팝업 컴포넌트
 */
const SchemaEditPopup = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      SchemaEditPopup
     *  @type          {Object} props
     *  @property      {String} ID - 리스트 ID
     *  @property      {Object} history - url 이동을 위해 사용
     *  @property      {Function} onClose - 닫기 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onApply - apply 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Object} cellData - 현재 수정할 cell 데이터
     *  @property      {Array} ipList - 현재 수정할 cell의 ip 리스트
     *  @property      {String} epochName - 현재 수정할 cell 의 epoch 이름
     *  @property      {Array} armList - schema 에 세팅 된 arm list
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {history, ID, onClose, onApply, cellData, ipList, epochName, armList} = props;

    /**
     *  @memberOf      SchemaEditPopup
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {language} = getSessionState();

    /**
     *  @memberOf     SchemaEditPopup
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SchemaEditPopup
     *  @var          {Object} currentCellData
     *  @description  화면에 표시 될 cell 데이터 리스트
     */
    const [currentCellData, setCurrentCellData] = useState(copyObject(cellData));

    /**
     *  @memberOf     SchemaEditPopup
     *  @var          {Array} currentIpList
     *  @description  화면에 표시 될 ip 리스트
     */
    const [currentIpList, setCurrentIpList] = useState(copyObject(ipList));

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {String} currentSettingEpochName
     *  @description  화면에 표시 될 epoch 이름
     */
    const [currentSettingEpochName, setCurrentSettingEpochName] = useState(epochName);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {String} currentEditingIpData
     *  @description  화면에 표시 될 IP 데이터
     */
    const [currentEditingIpData, setCurrentEditingIpData] = useState(INIT_EDIT_FIELD);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Object} validation
     *  @description  validation 체크에 사용 되는 필드
     */
    const [validation, setValidation] = useState({
        armIDs: true,
        epochName: true,
        ip: true,
        administered: true
    });

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} isOtherEpoch
     *  @description  epoch 'Other' 옵션 선택 여부
     */
    const [isOtherEpoch, setIsOtherEpoch] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {String} otherEpochName
     *  @description  epoch 'Other' 옵션 선택 시 입력한 이름 값
     */
    const [otherEpochName, setOtherEpochName] = useState('');

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} isCheckingTreatment
     *  @description  epoch 'Treatment' 선택 여부
     */
    const [isCheckingTreatment, setIsCheckingTreatment] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {String} isCheckingAdminister
     *  @description  IP Config 에서 administer 옵션 선택 여부
     */
    const [isCheckingAdminister, setIsCheckingAdminister] = useState('Y');

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Array} ipTypeList
     *  @description  IP 항목 카테고리에 표시할 옵션 리스트
     */
    const [ipTypeList, setIpTypeList] = useState([]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Array} originIpList
     *  @description  원본 ip list
     */
    const [originIpList, setOriginIpList] = useState([]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} isActiveType
     *  @description  design control type 체크 여부
     */
    const [isActiveType, setIsActiveType] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} isPlaceboType
     *  @description  placebo 체크 여부
     */
    const [isPlaceboType, setIsPlaceboType] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} administeredIp
     *  @description  administered with IP 선택 여부
     */
    const [administeredIp, setAdministeredIp] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} otherCheck
     *  @description  IP 'Other' 옵션 선택 시 입력한 이름 값
     */
    const [otherCheck, setOtherCheck] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Boolean} pretreatmentCheck
     *  @description  IP 'pretreatment' 옵션 선택 시 입력한 이름 값
     */
    const [pretreatmentCheck, setPretreatmentCheck] = useState(false);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {Array} checkAllListArm
     *  @description  check all 클릭 시 필요한 전체 arm ID 리스트
     */
    const [checkAllListArm, setCheckAllListArm] = useState([]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @constant     {String} armName
     *  @description  클릭한 arm Name 정보
     */
    const [armName, setArmName] = useState("");

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SchemaEditPopup
     *  @function     getIpDataInfo
     *  @description  IP 데이터 상세 정보 api 호출
     */
    const getIpDataInfo = useCallback(() => {
        // 상세 데이터 요청
        const command = COMMAND.IP_INFO;

        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_SYNOPSIS_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     getIpList
     *  @param        {String} type
     *  @description  type 별 IP 리스트 api 호출
     */
    const getIpList = useCallback((type) => {
        const command = COMMAND.IP_LIST;

        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
            type: type
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     getDesignInfo
     *  @description  Design 상세 정보 api 호출
     */
    const getDesignInfo = useCallback(() => {
        // 상세 데이터 요청
        const command = COMMAND.DESIGN_INFO;

        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     setIpOptionList
     *  @return       {Array} selectTypeOption - IP에 표시할 옵션 목록
     *  @description  선택한 control type에 따른 IP에 표시할 옵션 목록 반환
     */
    const setIpOptionList = useCallback(() => {
        if (language === 'en') {
            const selectTypeOption = [{...IP_OPTION}];
            if (isActiveType) {
                selectTypeOption.push(ACTIVITY_CONTROL_OPTION);
            }
            if (isPlaceboType) {
                selectTypeOption.push(PLACEBO_OPTION);
            }

            if (administeredIp) {
                selectTypeOption.push(DRUG_OPTION)
            }

            if (pretreatmentCheck) {
                selectTypeOption.push(PRE_TREATMENT_OPTION);
            }

            if (otherCheck) {
                selectTypeOption.push(RESCUE_OPTION);
            }
            return selectTypeOption;
        } else {
            const selectTypeOption_kr = [{...IP_OPTION_KR}];
            if (isActiveType) {
                selectTypeOption_kr.push(ACTIVITY_CONTROL_OPTION_KR);
            }
            if (isPlaceboType) {
                selectTypeOption_kr.push(PLACEBO_OPTION_KR);
            }

            if (administeredIp) {
                selectTypeOption_kr.push(DRUG_OPTION_KR)
            }

            if (pretreatmentCheck) {
                selectTypeOption_kr.push(PRE_TREATMENT_OPTION_KR)
            }

            if (otherCheck) {
                selectTypeOption_kr.push(RESCUE_OPTION_KR);
            }
            return selectTypeOption_kr;
        }
    }, [administeredIp, isActiveType, isPlaceboType, language, otherCheck, pretreatmentCheck]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     getSelectedArmList
     *  @return       {Array} cellDataList
     *  @description  현재 선택된 arm ID 리스트 조회하기
     */
    const getSelectedArmList = useCallback(() => {
        if (isDataExist(currentCellData[CELL_DATA_FIELD.ARMIDS])) {
            return currentCellData[CELL_DATA_FIELD.ARMIDS].map((armID) => {
                return {
                    value: armID
                }
            });
        } else {
            return [];
        }
    }, [currentCellData]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @var          selectedArmList
     *  @description  checkbox list에 넘겨줄 arm ID 리스트 메모이제이션
     */
    const selectedArmList = useMemo(() => getSelectedArmList(), [getSelectedArmList]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleChangeTableList
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 값
     *  @description  ip 데이터 변경 시 실행되는 함수
     */
    const handleChangeTableList = useCallback((name, value) => {
        if (name === 'treatment') {
            const findIp = originIpList.find((ipData => ipData.ID === value));
            setCurrentEditingIpData(produce(currentEditingIpData, draft => {
                if (findIp && findIp.hasOwnProperty('ID')) {
                    draft['name'] = findIp['name'];
                    draft['shape'] = findIp['shape'];
                    draft['dose'] = findIp['dose'];
                    draft['unit'] = findIp['unit'];
                    draft['ipID'] = findIp['ID'];
                } else {  // value 빈 값일 경우
                    draft['name'] = '';
                    draft['shape'] = '';
                    draft['dose'] = '';
                    draft['unit'] = '';
                    draft['ipID'] = '';
                }
                draft['cellID'] = cellData['ID'];
            }));
        } else if (name === 'intervention') {
            setCurrentEditingIpData(produce(currentEditingIpData, draft => {
                draft['type'] = value;
                // type 바뀌면 treatment 데이터 모두 빈 값 처리
                draft['name'] = '';
                draft['shape'] = '';
                draft['unit'] = '';
                draft['dose'] = '';
                draft['ipID'] = '';
            }));

            // value 가 빈 값이 아닐 때만 api 호출
            if (value !== '') {
                getIpList(value);
            } else {
                // value 빈 값으로 지정 시 ip list 비우기
                setIpTypeList([]);
                setCurrentEditingIpData(produce(currentEditingIpData, draft => {
                    draft['type'] = '';
                }));
            }
        } else {
            setCurrentEditingIpData(produce(currentEditingIpData, draft => {
                draft['amount'] = value;
            }));
        }
    }, [originIpList, currentEditingIpData, cellData, getIpList]);

    /**
     *  @memberOf       SchemaEditPopup
     *  @function       getConvertSelectList
     *  @param          {Array} selectList - 화면에 표시할 옵션 리스트
     *  @return         {Array} convertList - 언어 정보에 따라 변경된 데이터 리스트
     *  @description    언어를 적용하여 데이터 리스트를 변경
     */
    const getConvertSelectList = useCallback((selectList) => {
        let originList = copyObject(selectList);
        let convertList = [];

        let item;
        for (item of originList) {
            if (YES_NO_DICTIONARY.hasOwnProperty(language)) {
                const dict = YES_NO_DICTIONARY[language];

                if (dict.hasOwnProperty(item.text)) {
                    let newItem = copyObject(item)
                    newItem['text'] = dict[item.text];
                    convertList.push(newItem);
                } else {
                    convertList.push(item);
                }
            } else {
                convertList.push(item);
            }
        }

        return convertList;
    }, [language]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 값
     *  @description  입력 데이터 변경 시 실행되는 함수
     */
    const handleChange = useCallback((name, value) => {
        switch (name) {
            // medications administered 가 있는지 여부
            case VALIDATION_FIELD.ADMINISTERED :
                setIsCheckingAdminister(value);
                setCurrentIpList([]);
                break;

            // epoch 선택 변경 시
            case VALIDATION_FIELD.EPOCH_NAME :
                setCurrentSettingEpochName(value);

                if (value === 'TREATMENT' || value === 'Treatment') {
                    setIsCheckingTreatment(true);
                } else {
                    setIsCheckingTreatment(false);
                }

                if (value === 'Other') {
                    setIsOtherEpoch(true);
                } else {
                    setIsOtherEpoch(false);
                    setOtherEpochName('');
                }
                break;

            case 'EPOCH_OTHER':
                setOtherEpochName(value);
                break;

            // no default
        }
    }, []);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleAdd
     *  @param        {Object} tempData
     *  @description  multi table add 버튼 클릭 시 실행되어 데이터 추가하는 함수
     */
    const handleAdd = useCallback((value) => {
        const validateKeys = Object.keys(VALIDATE_FILED_IP);
        let validateTempData = {};

        let key;
        for (key of validateKeys) {
            validateTempData[key] = isDataExist(currentEditingIpData[key]);
        }

        // tempData 값 있는 지 체크
        let checkIpList = Object.values(validateTempData).every(item => {
            return item === true;
        });

        setValidation(produce(validation, draft => {
            draft[VALIDATION_FIELD.IP] = checkIpList;
        }));

        // 값 입력 X 일 경우 바로 return 처리
        if (!checkIpList) return;

        setCurrentIpList(produce(currentIpList, draft => {
            draft.push(currentEditingIpData);
        }))

        // 입력값 초기화
        setCurrentEditingIpData(INIT_EDIT_FIELD);
        setIpTypeList([]);
    }, [currentEditingIpData, currentIpList, validation]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleDelete
     *  @param        {Number} index  - 선택한 항목의 index
     *  @description  ip 삭제 버튼 클릭 시 실행되어 데이터 삭제 기능
     */
    const handleDelete = useCallback((index) => {
        // ip 삭제 처리
        setCurrentIpList(
            produce(currentIpList, draft => {
                draft.splice(index, 1);
            })
        );
    }, [currentIpList]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleEditAmount
     *  @param        {String} value  - 입력 값
     *  @param        {Number} index  - 선택한 항목의 index
     *  @description  IP amount 변경 시 실행되는 함수
     */
    const handleEditAmount = useCallback((value, index) => {
        // amount 수정
        setCurrentIpList(
            produce(currentIpList, draft => {
                draft[index].amount = value;
            })
        );
    }, [currentIpList]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleCheckAll
     *  @param        {Boolean} value - checkbox 체크/해제 여부
     *  @description  Check All 클릭 시 실행되는 이벤트
     */
    const handleCheckAll = useCallback((value) => {
        // 체크 선택
        if (value) {
            setCurrentCellData(
                produce(
                    currentCellData, draft => {
                        let newArmArray = [];

                        let armObject;
                        for (armObject of armList) {
                            newArmArray.push(armObject.ID);
                        }
                        draft[CELL_DATA_FIELD.ARMIDS] = newArmArray;
                    }
                )
            );
        } else {
            // 반드시 하나 이상 array 가지고 있어야함
            if (currentCellData[CELL_DATA_FIELD.ARMIDS].length > 1) {
                setCurrentCellData(
                    produce(
                        currentCellData, draft => {
                            draft[CELL_DATA_FIELD.ARMIDS] = [];
                        }
                    )
                );
            }
        }
    }, [armList, currentCellData]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleSelectCheck
     *  @param        {String} ID - 클릭한 checkbox ID
     *  @param        {Boolean} value - checkbox 체크/해제 여부
     *  @description  check box 클릭 시 실행되는 함수
     */
    const handleSelectCheck = useCallback((ID, value, label) => {
        if (value) {
            setCurrentCellData(
                produce(
                    currentCellData, draft => {
                        let newArmArray = [];

                        let armObject;
                        for (armObject of armList) {
                            if (draft[CELL_DATA_FIELD.ARMIDS].includes(armObject.ID)) {
                                newArmArray.push(armObject.ID);
                            } else {
                                if (armObject.ID === ID) {
                                    newArmArray.push(armObject.ID);
                                }
                            }
                        }
                        draft[CELL_DATA_FIELD.ARMIDS] = newArmArray;
                    }
                )
            );
        } else {
            // 반드시 하나 이상 array 가지고 있어야함
            let findIndex = currentCellData[CELL_DATA_FIELD.ARMIDS].findIndex(armID => {
                return ID === armID;
            });

            if (findIndex !== -1) {
                setCurrentCellData(
                    produce(
                        currentCellData, draft => {
                            draft[CELL_DATA_FIELD.ARMIDS].splice(findIndex, 1);
                        }
                    )
                );
            }
        }
    }, [armList, currentCellData]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 실행되는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation);
        let returnBool = true;
        let checkValidData = {};

        // data validate 체크
        let key;
        for (key of validateKeys) {
            switch (key) {
                case VALIDATION_FIELD.ARMIDS :
                    // true라면 ip 리스트 validation
                    if (!isCheckingTreatment) {
                        // 현재 선택한 arm id들의 index 정보
                        let selectedArmIndex = [];

                        // armIds 순회하여 index 정보 넣기
                        currentCellData[CELL_DATA_FIELD.ARMIDS].forEach((currentArm, idx) => {
                            let currentArmIndex = armList.findIndex(armData => armData.ID === currentArm);
                            selectedArmIndex.push(currentArmIndex);
                        });

                        if (selectedArmIndex.length === 0) {
                            checkValidData[VALIDATION_FIELD.ARMIDS] = false;
                            continue;
                        }

                        // 그룹을 두 개 이상 선택 시 중간 빈 값 체크
                        if (selectedArmIndex.length > 1) {
                            // array 마지막 index
                            const LAST_VALUE_INDEX = selectedArmIndex.length - 1;
                            // array 첫번째 index
                            const FIRST_VALUE_INDEX = 0;
                            let iterateArr = [];

                            let i;
                            for (i = selectedArmIndex[FIRST_VALUE_INDEX]; i <= selectedArmIndex[LAST_VALUE_INDEX]; i++) {
                                iterateArr.push(i);
                            }

                            let difference = iterateArr.filter(num => {
                                return !selectedArmIndex.includes(num)
                            });

                            // 선택 안 한 빈 checkbox 있을 경우
                            if (isDataExist(difference)) {
                                checkValidData[VALIDATION_FIELD.ARMIDS] = false
                                continue;
                            }
                        }
                        checkValidData[VALIDATION_FIELD.ARMIDS] = true;
                    } else {
                        checkValidData[VALIDATION_FIELD.ARMIDS] = true;
                    }
                    break;

                case VALIDATION_FIELD.EPOCH_NAME :
                    if (isOtherEpoch) {
                        checkValidData[VALIDATION_FIELD.EPOCH_NAME] = isDataExist(otherEpochName)
                    } else {
                        checkValidData[VALIDATION_FIELD.EPOCH_NAME] = isDataExist(currentSettingEpochName)
                    }
                    break;

                case VALIDATION_FIELD.ADMINISTERED:
                    if (!isCheckingTreatment) {
                        checkValidData[VALIDATION_FIELD.ADMINISTERED] = isDataExist(isCheckingAdminister);
                    } else {
                        checkValidData[VALIDATION_FIELD.ADMINISTERED] = true;
                    }
                    break;

                case VALIDATION_FIELD.IP :
                    break;

                // no default
            }
        }
        // 결과 값 setting
        setValidation(checkValidData);

        // 모든 validate 결과가 true일 때 true
        returnBool = Object.values(checkValidData).every(item => {
            return item === true;
        });

        return returnBool;
    }, [armList, currentCellData, currentSettingEpochName, isCheckingAdminister, isCheckingTreatment, isOtherEpoch, otherEpochName, validation]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleFocus
     *  @param        {String} name - focus 된 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            // invalid
            if (validation[name] === false) {
                setValidation(
                    produce(
                        validation, draft => {
                            draft[name] = true;
                        }
                    )
                );
            }
        } else {
            if (name === 'intervention' || name === 'treatment') {
                setValidation(
                    produce(
                        validation, draft => {
                            draft['ip'] = true;
                        }
                    )
                )
            }
        }
    }, [validation]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     handleApply
     *  @description  apply 버튼을 클릭 시 처리 하는 함수
     */
    const handleApply = useCallback(() => {
        // 데이터 validation
        const valid = validateData();

        if (valid === false) {
            return;
        }

        if (onApply !== undefined) {
            const newCellData = copyObject(currentCellData);
            let newIpData = copyObject(currentIpList);
            let epochName = isOtherEpoch ? otherEpochName : currentSettingEpochName;

            //Yes, No 선택있는거에 Yes 선택했을때나 epoch 이름이 Treatment 일 때 + 안누른 IP 정보 추가되도록
            if (isCheckingAdminister === 'Y' || epochName === "Treatment") {
                newIpData.push(currentEditingIpData);
            }

            onApply(newCellData, newIpData, epochName);

            handleClose();
        }
    }, [validateData, onApply, currentCellData, currentIpList, isOtherEpoch, otherEpochName, currentSettingEpochName, isCheckingAdminister, handleClose, currentEditingIpData]);

    // treament 따라 보이는 팝업 화면 다르게 조회
    useEffect(() => {
        if (epochName) {
            setCurrentSettingEpochName(epochName);

            if (epochName === 'Treatment') {
                setIsCheckingTreatment(true);
                setCurrentSettingEpochName('Treatment');
            } else {
                setIsCheckingTreatment(false);
                setCurrentSettingEpochName(epochName);
            }
        }
    }, [epochName]);

    /**
     *  @memberOf     SchemaEditPopup
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command, params} = action;
            switch (command) {
                // design 상세 정보 가져오기
                case COMMAND.DESIGN_INFO :
                    if (data.hasOwnProperty('data')) {
                        let activeBool = data.data.controlType === 'Active';
                        let placeboBool = data.data.controlType === 'Placebo';

                        setIsActiveType(activeBool);
                        setIsPlaceboType(placeboBool);

                        if (ipList.length > 0) {
                            setIsCheckingAdminister('Y');
                        } else {
                            setIsCheckingAdminister('N');
                        }
                    }
                    break;

                // ip 상세 정보 가져오기
                case COMMAND.IP_INFO :
                    if (data.hasOwnProperty('data')) {
                        let administerBoolean = data.data.checkAdministration === 'Y';
                        let otherBoolean = data.data.checkOthers === 'Y';
                        let pretreatmentBoolean = data.data.checkPretreatment === 'Y';

                        setAdministeredIp(administerBoolean);
                        setOtherCheck(otherBoolean);
                        setPretreatmentCheck(pretreatmentBoolean);
                    }
                    break;

                // 타입별 IP 리스트 불러오기
                case COMMAND.IP_LIST :
                    const {type} = params;
                    if (data.data.hasOwnProperty(type)) {
                        const ipList = data.data[type].map((ipItem) => {
                            return {
                                text: `${ipItem.name} ${ipItem.shape} ${ipItem.dose} ${ipItem.unit}`,
                                value: ipItem.ID
                            }
                        });
                        setOriginIpList(data.data[type]);
                        setIpTypeList(ipList);
                    } else {
                        setOriginIpList([]);
                        setIpTypeList([]);
                    }
                    break;

                // no default
            }
        }
    }, [ipList.length]);

    // IP Tab 상세 정보 요청
    useEffect(() => {
        getIpDataInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // design Tab 상세 정보 요청
    useEffect(() => {
        getDesignInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // checkbox에 표시할 arm list 생성
    useEffect(() => {
        if (isDataExist(armList)) {
            let armCheckList = armList.map((arm, armIdx) => {
                return {
                    value: arm.ID,
                    text: arm.groupName
                }
            });

            setCheckAllListArm(armCheckList);
        }
    }, [armList]);

    //현재  arm Name
    useEffect(() => {
        if (isDataExist(armList) && isDataExist(cellData)) {
            armList.forEach((arm) => {
                if (arm.ID === cellData.armIDs[0]) {
                    setArmName(arm.groupName);
                }
            })
        }
    }, [armList, cellData]);

    // 현재 cell의 epoch name이 other일 경우 isOther Textbox 활성화
    useEffect(() => {
        // epoch 이름이 빈값이 아닌 경우에만 확인
        if (epochName !== '') {
            let findEpoch = EPOCH_OPTION_LIST.find((epoch) => {
                return epoch.value === epochName;
            });

            if (!findEpoch && epochName !== 'Epoch') {
                setIsOtherEpoch(true);
                setOtherEpochName(epochName);
                setCurrentSettingEpochName('Other')
            }
        }
        if (epochName === 'Epoch') {
            setCurrentSettingEpochName('Baseline')
        }
    }, [epochName]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen={true} className="modal-xl" toggle={handleClose}>
                <ModalHeader className="header-title" toggle={handleClose}>
                    {language === 'en' ? 'Study Period Information' : '연구 기간 정보'}
                </ModalHeader>
                <ModalBody>
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            {language === 'en' ? 'Study Period' : '연구 기간 구분'}
                        </label>
                        <div className="col-md-9">
                            <SelectBox
                                name={VALIDATION_FIELD.EPOCH_NAME}
                                dataList={EPOCH_OPTION_LIST}
                                currentValue={currentSettingEpochName}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                validation={validation[VALIDATION_FIELD.EPOCH_NAME]}/>

                            {isOtherEpoch && (
                                <TextField
                                    name={'EPOCH_OTHER'}
                                    currentValue={otherEpochName}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[VALIDATION_FIELD.EPOCH_NAME]}/>
                            )}
                        </div>
                    </div>

                    {isCheckingTreatment && (
                        <div className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                {language === 'en' ? 'Arm' : '투여군'}
                            </label>
                            <div className="col-md-9">
                                <TextField
                                    name={'ARM'}
                                    currentValue={armName}
                                    disabled={true}/>
                            </div>
                        </div>
                        )}

                    {!isCheckingTreatment ? (
                        <>
                            <div className="row align-items-center m-b-20">
                                <label className="col-md-3">
                                    {language === 'en' ? 'Are there any medications administered during the period?' : '해당 기간 동안 투여된 약물이 있습니까?'}
                                </label>

                                <div className="col-md-9">
                                    <RadioButton
                                        name={VALIDATION_FIELD.ADMINISTERED}
                                        dataList={getConvertSelectList(YN_OPTION)}
                                        onChange={handleChange}
                                        validation={validation[VALIDATION_FIELD.ADMINISTERED]}
                                        onFocus={handleFocus}
                                        currentValue={isCheckingAdminister}/>
                                </div>
                            </div>

                            {isCheckingAdminister === 'Y' && (
                                <div className="row align-items-center m-b-20">
                                    <label className="col-md-3">
                                        {language === 'en' ? 'Drug Information' : '약물 정보'}
                                    </label>

                                    <div className="col-md-9">
                                        <TreatmentTableList
                                            dataList={currentIpList}
                                            onDelete={handleDelete}
                                            onAdd={handleAdd}
                                            onFocus={handleFocus}
                                            onChange={handleChangeTableList}
                                            checkValid={validation[VALIDATION_FIELD.IP]}
                                            optionList={[setIpOptionList(), ipTypeList]}
                                            currentValue={currentEditingIpData}
                                            onEditAmount={handleEditAmount}/>
                                    </div>
                                </div>
                            )}
                            <div className="row align-items-center m-b-20">
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                    <CheckAllList
                                        name={VALIDATION_FIELD.ARMIDS}
                                        dataList={checkAllListArm}
                                        selectedList={selectedArmList}
                                        onSelect={handleSelectCheck}
                                        onFocus={handleFocus}
                                        onCheckAll={handleCheckAll}
                                        validation={validation[VALIDATION_FIELD.ARMIDS]}/>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="row align-items-center m-b-20">
                            <label className="col-3">
                                {language === 'en' ? 'Intervention / Treatment' : '임상시험용 의약품 / 치료'}
                            </label>
                            <div className="col-9">
                                <TreatmentTableList
                                    dataList={currentIpList}
                                    onDelete={handleDelete}
                                    onAdd={handleAdd}
                                    onChange={handleChangeTableList}
                                    optionList={[setIpOptionList(), ipTypeList]}
                                    checkValid={validation[VALIDATION_FIELD.IP]}
                                    onFocus={handleFocus}
                                    currentValue={currentEditingIpData}
                                    onEditAmount={handleEditAmount}/>
                            </div>
                        </div>
                    )}
                </ModalBody>

                <ModalFooter>
                    <button className="btn btn-new"
                            onClick={handleApply}>
                        Apply
                    </button>
                    <button className="btn btn-white"
                            onClick={handleClose}>
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(SchemaEditPopup);