import React, { useCallback, useRef, useState } from "react";
import { AXIOS_GET, getSessionState } from "common/commonFunction";
import { COMMAND } from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import { LOCK_STATE, VIEW_USE_MODE } from "constant/CommonConstant";
import { BASIC_INFORMATION_FIELD } from "constant/ConstantName";
import PagingTap from "../../../components/PagingTap";
import ReferencePopupTab from "./ReferencePopupTab";
import ClinicalTrialPopupTab from "./ClinicalTrialPopupTab";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ReferencePopup
 *  @constant     {Object} TAB
 *  @description  reference tab 버튼에 표시할 title
 */
export const TAB = {
    REFERENCE: 'Reference',   // UserGuide TAB
    CLINICALTRIALS: 'ClinicalTrials.gov',   // ClinicalTrials.gov TAB
};

/**
 *  @memberOf     ReferencePopup
 *  @constant     {Array} TAB_REFERENCE_LIST
 *  @description  reference tab 버튼에 사용할 옵션 리스트
 */
const TAB_REFERENCE_LIST = [
    {
        text: TAB.REFERENCE,
        value: TAB.REFERENCE
    },
    {
        text: TAB.CLINICALTRIALS,
        value: TAB.CLINICALTRIALS
    }
]

/**
 *  @memberOf     ReferencePopup
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID} = params;
    let response = null;
    let url = null;

    switch (command) {
        case COMMAND.DATA_INFO :
            url = `${requestUrl}/${ID}?type=one`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       주예리나
 *  @version      1.0
 *  @component    CompareModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 props
 *  @description  Design 상세 정보 내 Reference 팝업 컴포넌트
 */
const ReferencePopup = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     ReferencePopup
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     * @memberOf    ReferencePopup
     * @type        {Object} getSessionState
     * @property    {String} ID - 리스트 ID
     * @property    {String} StudyID - study ID
     * @property    {String} Tab - tab 정보
     * @description session 에서 받아오는 정보
     */
    const {ID, StudyID, Tab} = getSessionState();

    /**
     *  @memberOf     ReferencePopup
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
        // 통신 괸련된 처리를 해주는 agent 컴포넌트
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     ReferencePopup
     *  @var          {String} mode
     *  @description  호출한 페이지로부터 받은 정보로 state 세팅
     */
    const [mode] = useState(getSessionState().mode);

    /**
     *  @memberOf     ReferencePopup
     *  @var          {String} tabMenu
     *  @description  현재 선택된 tab 정보
     */
    const [tabMenu, setTabMenu] = useState(Tab === undefined ? TAB.REFERENCE : Tab);

    /**
     *  @memberOf     ReferencePopup
     *  @var          {Object} studyData
     *  @description  study 에 설정된 데이터 정보
     */
    const [studyData, setStudyData] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ReferencePopup
     *  @function     getMode
     *  @return       {String} mode - 팝업 변경 모드
     *  @description  lock 상태에 따른 팝업 수정 mode 변경하는 함수
     */
    const getMode = useCallback(() => {
        // prm이 lock 인 상태를 체크하여 lock 인 경우 view 모드 응답
        if (studyData.hasOwnProperty(BASIC_INFORMATION_FIELD.LOCK)) {
            const {PRM} = studyData[BASIC_INFORMATION_FIELD.LOCK];

            if (PRM === LOCK_STATE.LOCK) {
                return VIEW_USE_MODE.VIEW;
            }
        }
        return mode;
    }, [mode, studyData]);

    /**
     *  @memberOf     ReferencePopup
     *  @function     handleClickTab
     *  @param        {String} name - 이동 tab 값
     *  @param        {String} tabValue - 이동 tab 값
     *  @description  tab 버튼 클릭 시 실행되는 함수
     */
    const handleClickTab = useCallback((name, tabValue) => {
        setTabMenu(tabValue);
    }, []);

    /**
     *  @memberOf     ReferencePopup
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;
            switch (command) {
                case COMMAND.DATA_INFO :
                    // 데이터가 있는 경우
                    if (data.hasOwnProperty('Data')) {
                        setStudyData(data.Data);
                    }
                    break;

                // no default
            }
        }
    }, []);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box height-50 p-l-20 p-r-20">
                <div className="vertical-box-column">
                    <div className="d-flex m-t-20 justify-content-end align-items-center">
                        <PagingTap dataList={TAB_REFERENCE_LIST} currentValue={tabMenu} onChange={handleClickTab}/>
                    </div>
                </div>
            </div>

            {/*  tab에 따른 화면 처리 */}
            {tabMenu === TAB.REFERENCE && (
                <ReferencePopupTab
                    viewMode={getMode()}
                    studyID={StudyID}
                    history={history}/>
            )}

            {tabMenu === TAB.CLINICALTRIALS && (
                <ClinicalTrialPopupTab
                    viewMode={getMode()}
                    designID={ID}
                    history={history}/>
            )}
        </>
    );
};

export default React.memo(ReferencePopup);
