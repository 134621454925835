import React, {useCallback, useEffect, useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import TextField from "imtrial/components/TextField";
import TextArea from "./TextArea";
import RadioButton from "./RadioButton";
import SelectBox from "./SelectBox";
import AUIGrid from "./AUIGrid";
import {isDataExist} from "common/commonFunction";
import produce from "immer";
import classNames from "classnames";

/**
 *  @summary 공통 Modal
 *  @author 사용자 정의
 *  @version 1.0, 작업 내용
 *  @see None
 */

/**
 *  @component    CustomModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  공통으로 사용 되는 Modal
 */
const CustomModal = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/

    // 상위 컴포넌트로 부터 받아오는 props
    const {
        headerColor,
        title,
        content,
        onCancel,
        onOk,
        onClose,
        onSave,
        onRetry,
        btnInfoList,
        useData,
        onYes,
        widthSize
    } = props;

    /**
     *  @memberOf     CustomModal
     *  @var          {Object} data
     *  @description  data
     */
    const [data, setData] = useState({});

    /**
     *  @memberOf     CustomModal
     *  @var          {Object} validation
     *  @description  validation data
     */
    const [validation, setValidation] = useState({});

    /**
     *  @memberOf     CustomModal
     *  @var          {boolean} passwordCheck
     *  @description  password와 confirm password 필드와 비교해서 같은지 체크(같으면 true, 다르면 false)
     */
    const [passwordCheck, setPasswordCheck] = useState(true);

    /**
     *  @memberOf     CustomModal
     *  @var          {string} currentTab
     *  @description  현재 활성화 된 tab 정보
     */
    const [currentTab, setCurrentTab] = useState("");

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     CustomModal
     *  @function     validateData
     *  @param        none
     *  @return       {boolean} true - success, false - fail
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        let returnBool = true;

        // validation 결과 객체
        let validateData = {};

        // validation 할 key
        const validateKeys = Object.keys(validation);

        // data validate 체크
        for (const key of validateKeys) {
            validateData[key] = isDataExist(data[key]);
        }

        // 결과 값 setting
        setValidation(validateData);

        // 모든 validate 결과가 true일 때 true
        returnBool = Object.values(validateData).every(item => {
            return item === true;
        });

        return returnBool;

    }, [data, validation]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleChange
     *  @param        {string} name   -  element name
     *  @param        {string} value  -  element value
     *  @return       none
     *  @description  html element 데이터 입력할 경우 실행
     */
    const handleChange = useCallback((name, value) => {
        setData(produce(data, draft => {
            draft[name] = value;
        }));
    }, [data]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleChangeTab
     *  @param        {string} name   - 이벤트가 발생한 element name
     *  @param        {string} value  - 이동할 탭 value
     *  @return       none
     *  @description  탭 변경시 처리되는 함수
     */
    const handleChangeTab = useCallback((name, value) => {
        setCurrentTab(value);
        setData(produce(data, draft => {
            draft[name] = value;
        }));
    }, [data]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleChange
     *  @param        none
     *  @return       none
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onOk !== undefined) onOk();
        if (onClose !== undefined) onClose();
    }, [onClose, onOk]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleYes
     *  @param        none
     *  @return       none
     *  @description  yes 버튼 클릭 시 처리 사항
     */
    const handleYes = useCallback(() => {
        if (isDataExist(data)) {
            onYes(data);
        } else {
            onYes()
        }
        onClose();
    }, [data, onClose, onYes]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleOk
     *  @param        none
     *  @return       none
     *  @description  ok 버튼 클릭 시 처리 사항
     */
    const handleOk = useCallback(() => {
        onOk();
        onClose();
    }, [onClose, onOk])

    /**
     *  @memberOf     CustomModal
     *  @function     handleFocus
     *  @param        {string} name - focus 된 tag name
     *  @return       none
     *  @description  focus 된 tag 에 대한 처리(invalid 일 때의 처리)
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }

            //confirm password에서 밑에 빨간 경고 없어지도록
            if (name === "confirmPassword") {
                setPasswordCheck(true);
            }
        }
    }, [validation]);

    /**
     *  @memberOf     CustomModal
     *  @function     handleSave
     *  @param        none
     *  @return       none
     *  @description  save 버튼 눌렀을 때 실행
     */
    const handleSave = useCallback(() => {
        //데이터 validation
        const valid = validateData();
        if (valid === false) {
            return;
        }

        // 상위 이벤트 onSave 실행
        if (onSave) {
            //Save 버튼 눌렀을때 data에 confirmPassword 가 있을때 처리
            if (data.hasOwnProperty("confirmPassword")) {
                if (data.password !== data.confirmPassword) {
                    setPasswordCheck(false);
                } else {
                    setPasswordCheck(true);
                    onSave(data);
                }
            } else {
                onSave(data);
            }
        }
    }, [data, onSave, validateData]);

    /**
     *  @memberOf     CustomModal
     *  @function     showContent
     *  @param        none
     *  @return       {element} string 올때 string, Array 올때 element
     *  @description  Modal의 내용 구분하여 보여주는 부분
     */
    const showContent = useCallback(() => {
        if (typeof (content) === 'string') {
            return content;
        } else if (Array.isArray(content)) {
            // eslint-disable-next-line array-callback-return
            return content.map((elem, idx) => {
                const type = elem.type;

                if (type === "textfield") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span className="mt-2">{elem.label}</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                                <div className="w-75">
                                    {elem.ID !== "confirmPassword" ?
                                        <TextField
                                            name={elem.ID}
                                            type={elem.attrType ? elem.attrType : ''}
                                            onChange={handleChange}
                                            currentValue={data[elem.ID] ?? ""}
                                            onFocus={handleFocus}
                                            validation={validation[elem.ID]}/> :
                                        <TextField
                                            name={elem.ID}
                                            type={elem.attrType ? elem.attrType : ''}
                                            onChange={handleChange}
                                            currentValue={data[elem.ID] ?? ""}
                                            onFocus={handleFocus}
                                            validation={validation[elem.ID]}
                                            passwordCheck={passwordCheck}/>}
                                </div>
                            </div>
                        </div>
                    );
                } else if (type === "radio") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span className="mt-2">{elem.label}</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                                <div className="w-75">
                                    <RadioButton
                                        name={elem.ID}
                                        dataList={elem.tabOption}
                                        currentValue={currentTab}
                                        onChange={handleChangeTab}
                                        // validation={validation[elem.ID]}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                } else if (type === "textarea") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span className="mt-2">{elem.label}</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                                <div className="w-75">
                                    <TextArea
                                        name={elem.ID}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        currentValue={data[elem.ID] ?? ""}
                                        validation={validation[elem.ID]}
                                        passwordCheck={passwordCheck}/>
                                </div>
                            </div>
                        </div>
                    )
                } else if (type === "selectbox") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span className="mt-2">{elem.label}</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                                <div className="w-75">
                                    <SelectBox
                                        name={elem.ID}
                                        onChange={handleChange}
                                        currentValue={data[elem.ID] ?? ""}
                                        dataList={elem.selectOption}
                                        onFocus={handleFocus}
                                        validation={validation[elem.ID]}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                } else if (type === "searchDropDown") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <label className="col-md-3">
                                <span className="mt-2">{elem.label}</span>
                                <span className="ml-3 text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                                <div className="w-75">
                                    {/*<SearchDropdown name={elem.ID}*/}
                                    {/*                dataList={elem.selectOption}*/}
                                    {/*                onSelect={handleSelect}*/}
                                    {/*                currentValue={isDataExist(data) && data[elem.ID]}*/}
                                    {/*                onChange={handleChange}*/}
                                    {/*                onFocus={handleFocus}*/}
                                    {/*                validation={validation[elem.ID]}*/}
                                    {/*                placeHolder={elem.placeHolder}*/}
                                    {/*                disabled={checkingDomainValue()}/>*/}
                                </div>
                            </div>
                        </div>
                    )
                } else if (type === "image") {
                    return (
                        <div key={idx} className="row mx-n2">
                            <div className="col-sm-12 m-5">
                                <img src={elem.src} alt={'NoImage'} className='images'/>
                            </div>
                        </div>
                    )
                } else if (type === "grid") {
                    return (
                        <div key={idx} className="row mx-n2" style={{height: "50vh"}} id={"customModal"}>
                            <div className="col-sm-12 m-5">
                                <AUIGrid
                                    id={elem.ID}
                                    columnList={elem.columnList}
                                    dataList={elem.dataList}
                                    isAutoGridHeight={true}/>
                            </div>
                        </div>
                    )
                } else if (type === "title") {
                    return (
                        <div key={idx} className="row align-items-center m-b-20">
                            <b>{elem.label}</b>
                        </div>
                    )
                }
            })
        }
    }, [content, currentTab, data, handleChange, handleChangeTab, handleFocus, passwordCheck, validation])

    /**
     *  @memberOf     CustomModal
     *  @function     handleClick
     *  @param        {string} name - 버튼 이름
     *  @return       none
     *  @description  버튼 이름에 따라 실행 handler 구분
     */
    const handleClick = useCallback((name) => {
        switch (name) {
            case "Save":
                handleSave();
                break;
            case "No":
                if (onCancel !== undefined) onCancel();
                if (onClose !== undefined) onClose();
                break;
            case "Yes":
            case "Check for changes":
            case "Apply":
                if (onOk !== undefined) handleOk();
                if (onYes !== undefined) handleYes();
                break;
            case "Retry":
                onRetry();
                break;
            case "OK":
            case "Cancel":
                handleClose();
                break;
            default:
                break;
        }
    }, [handleClose, handleOk, handleSave, handleYes, onCancel, onClose, onOk, onRetry, onYes]);

    /**
     *  @memberOf     CustomModal
     *  @function     showBtn
     *  @param        none
     *  @return       {element} 모달 하단의 버튼 표시
     *  @description  버튼 정보 보여주는 메소드
     */
    const showBtn = useCallback(() => {
        if (isDataExist(btnInfoList)) {
            return btnInfoList.map((btn, idx) => {
                return (
                    <button
                        key={idx}
                        className={`btn ${btn.color} btn-inner-shadow px-3 px-md-5 mx-1`}
                        onClick={() => handleClick(btn.name)}>
                        {btn.name}
                    </button>
                )
            })
        }
    }, [btnInfoList, handleClick])

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    /**
     *  @listens      [useData] - useData 변경 시
     *  @listens      [content] - content 변경 시
     *  @description  useData가 있으면 data, validation 값을 정의 하지만,
     *  useData가 null이면 data, validation을 만들어서 정의
     */
    useEffect(() => {

        if (useData !== null) {
            //여기서 validation도 setValidation 해줘야함.
            //데이터가 아직 어떻게 오는지 몰라서 처리 안함.
            setData(useData);
        } else {
            let dataObj = {};
            let validationObj = {};

            content.forEach((row) => {
                //라디오 버튼일 때 초기값 넣어주고 validation 체크 빼기 위해
                if (row.type === "radio") {
                    setCurrentTab(row.tabOption[0].value);
                    dataObj = {...dataObj, [row.ID]: row.tabOption[0].value};
                } else {
                    validationObj = {...validationObj, [row.ID]: true};
                    dataObj = {...dataObj, [row.ID]: ''};
                }
            });

            setData(dataObj);
            setValidation(validationObj);
        }

        return () => {
            setData({});
            setValidation({});
        }
    }, [useData, content]);

    /*################################################################################*/
    //## component view 영역
    // ## - JSX return
    /*################################################################################*/
    return (
        <>
            <Modal isOpen className="modal-lg" style={widthSize !== undefined ? {maxWidth: widthSize} : {}}>
                <ModalHeader
                    className={classNames('header-title', {
                        'bg-main': !headerColor,
                        [`${headerColor}`]: headerColor
                    })}
                    toggle={handleClose}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {showContent()}
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex">
                        {showBtn()}
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default React.memo(CustomModal);
