import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {AXIOS_PUT, copyObject, getSessionState, isDataExist} from "../../../../../common/commonFunction";
import NetworkLayout from "../../../../../common/NetworkLayout";
import TextField from "../../../../components/TextField";
import SelectBox from "../../../../components/SelectBox";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import produce from "immer";
import Checkbox from "../../../../components/Checkbox";
import {VIEW_USE_MODE} from "../../../../../constant/CommonConstant";
import TextArea from "../../../../components/TextArea";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     MethodsModal
 *  @constant     {Object} METHODS_DEFINE
 *  @description  field 명 정의
 */
const METHODS_DEFINE = {
    USED: "used",
    OID: 'OID',
    NAME: 'name',
    TYPE: 'type',
    DESCRIPTION: 'description',
    EXPRESSION_CONTEXT: 'expressionContext',
    EXPRESSION_CODE: 'expressionCode',
    DOCUMENT: 'document',
    PAGES: 'pages'
};

/**
 *  @memberOf     MethodsModal
 *  @constant     {Object} VALIDATION_NEW
 *  @description  mode가 NEW 일때 validation 체크에 사용 되는 필드
 */
const VALIDATION_NEW = {
    OID: true,
    name: true,
    type: true,
    description: true
};

/**
 *  @memberOf     MethodsModal
 *  @constant     {Object} VALIDATION_EDIT
 *  @description  mode가 EDIT 일때 validation 체크에 사용 되는 필드
 */
const VALIDATION_EDIT = {
    type: true,
    description:true
};

/**
 *  @memberOf     MethodsModal
 *  @constant     {Array} TYPE_OPTION
 *  @description  Type 컬럼에 사용되는 옵션
 */
const TYPE_OPTION = [
    {
        text: "Computation",
        value: "Computation"
    },
    {
        text: "Imputation",
        value: "Imputation"
    }
];

/**
 *  @memberOf     MethodsModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    MethodsModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Methods Tab의 Methods 추가, 수정 Modal 컴포넌트
 */
const MethodsModal = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     MethodsModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Function} onSave - 현재 Modal 저장 위한 함수
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @property     {Array} dataList - Methods 탭에 보여지는 전체 list 정보
     *  @property     {Object} data - 상위에서 선택된 data 정보
     *  @property     {String} mode - Mehtods 생성 및 수정 구분 값 (NEW | EDIT)
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, onSave, onClose, dataList, data, mode} = props;

    /**
     *  @memberOf     MethodsModal
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     MethodsModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     MethodsModal
     *  @var          {Object} dataInfo
     *  @description  화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(data);

    /**
     *  @memberOf     MethodsModal
     *  @var          {Object} validation
     *  @description  입력 값 validation 체크
     */
    const [validation, setValidation] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     MethodsModal
     *  @function     handleChange
     *  @param        {String} name - 입력 요소의 tag name
     *  @param        {String} value - 입력 된 값
     *  @description  html element 데이터 입력할 경우 실행
     */
    const handleChange = useCallback((name, value) => {
        setDataInfo(produce(dataInfo, draft => {
            if (name !== METHODS_DEFINE.USED) { //Don't use this Method 항목이 아닌 것들
                draft[name] = value;
            } else { //Don't use this Method 항목
                draft[name] = value === 1 ? 0 : 1; //used가 1이면 check 박스에는 체크가 안되어있어야 해서 반대로 설정
            }
        }));
    }, [dataInfo]);

    /**
     *  @memberOf     ActivityDetail
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (!validation[name]) {
                setValidation(produce(validation, draft => {
                    draft[name] = true;
                }));
            }
        }
    }, [validation]);

    /**
     *  @memberOf     ActivityDetail
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(validation); // validation 할 key
        let returnBool = true;
        let validateData = {};

        let key;
        for (key of validateKeys) {
            validateData[key] = isDataExist(dataInfo[key]);
        }

        setValidation(validateData);
        returnBool = Object.values(validateData).every(item => item === true);

        return returnBool;
    }, [dataInfo, validation]);

    /**
     *  @memberOf     MethodsModal
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        const valid = validateData(); // 데이터 validation
        if (!valid) {
            return;
        }

        const command = COMMAND.DATA_UPDATE; //데이터 수정 요청
        let params = {};
        const _dataList = [...dataList];
        const findIdx = _dataList.findIndex(data => data.ID === dataInfo.ID); //같은 ID를 찾아 index 저장

        //찾은 index가 있다면
        if (findIdx > -1 && mode === VIEW_USE_MODE.EDIT) {
            _dataList[findIdx] = copyObject(dataInfo); //해당 인덱스에 수정된 data 값을 복사
            params = {
                requestUrl: `${SDTM_MAPPING_URL}/functionList`,
                ID: ID,
                sendObject: JSON.stringify({data: _dataList})
            };
        } else {
            _dataList.push(dataInfo);
            params = {
                requestUrl: `${SDTM_MAPPING_URL}/functionList`,
                ID: ID,
                sendObject: JSON.stringify({data: _dataList})
            };
        }

        netWorkAgent.current.request(command, params); //back-end 데이터 처리 요청
    }, [validateData, dataList, mode, dataInfo, ID]);

    /**
     *  @memberOf     MethodsModal
     *  @function     handleClose
     *  @description  Modal 창 닫는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     MethodsModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_UPDATE:
                    if (data.code === 1) {
                        if (onSave !== undefined) {
                            onSave();
                        }
                    }
                    break;

                //no default
            }
        }
    }, [onSave]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //mode 변경에 대한 상태 변경
    useEffect(() => {
        if (mode === VIEW_USE_MODE.EDIT) {
            setValidation(VALIDATION_EDIT);
        } else if (mode === VIEW_USE_MODE.NEW) {
            setValidation(VALIDATION_NEW);
        }
    }, [mode]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen={true} className="modal-xl">
                <ModalHeader toggle={handleClose}>
                    {mode === VIEW_USE_MODE.NEW ? "New" : "Edit"} Method
                </ModalHeader>

                <ModalBody style={{position: "relative"}}>
                    <div className="align-self-center m-b-20">
                        <Checkbox
                            name={METHODS_DEFINE.USED}
                            currentValue={dataInfo[METHODS_DEFINE.USED] !== 1}
                            label={"Don't use this Method"}
                            onChange={handleChange}
                            disabled={lock === 2}/>
                    </div>

                    {/*OID*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>OID</span>
                            {mode === VIEW_USE_MODE.NEW && (
                                <span className="ml-3 text-danger">*</span>
                            )}
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.OID}
                                    currentValue={dataInfo[METHODS_DEFINE.OID]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[METHODS_DEFINE.OID]}
                                    disabled={mode === VIEW_USE_MODE.EDIT}/>
                            </div>
                        </div>
                    </div>

                    {/*Name*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Name</span>
                            {mode === VIEW_USE_MODE.NEW && (
                                <span className="ml-3 text-danger">*</span>
                            )}
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.NAME}
                                    currentValue={dataInfo[METHODS_DEFINE.NAME]}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    validation={validation[METHODS_DEFINE.NAME]}
                                    disabled={mode === VIEW_USE_MODE.EDIT}/>
                            </div>
                        </div>
                    </div>

                    {/*Type*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Type</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <SelectBox
                                    name={METHODS_DEFINE.TYPE}
                                    dataList={TYPE_OPTION}
                                    currentValue={dataInfo[METHODS_DEFINE.TYPE]}
                                    onChange={handleChange}
                                    defaultValue={TYPE_OPTION[0].value}
                                    isEmptyValue={true}
                                    onFocus={handleFocus}
                                    validation={validation[METHODS_DEFINE.TYPE]}
                                    readOnly={lock === 2}/>
                            </div>
                        </div>
                    </div>

                    {/*Description*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Description</span>
                            <span className="ml-3 text-danger">*</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextArea
                                    name={METHODS_DEFINE.DESCRIPTION}
                                    rows={5}
                                    className="form-control m-t-10"
                                    onChange={handleChange}
                                    currentValue={dataInfo[METHODS_DEFINE.DESCRIPTION]}
                                    style={{resize: "none"}}
                                    onFocus={handleFocus}
                                    validation={validation[METHODS_DEFINE.DESCRIPTION]}/>
                            </div>
                        </div>
                    </div>

                    {/*Expression Context*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Expression Context</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.EXPRESSION_CONTEXT}
                                    currentValue={dataInfo[METHODS_DEFINE.EXPRESSION_CONTEXT]}
                                    onChange={handleChange}
                                    disabled={lock === 2}/>
                            </div>
                        </div>
                    </div>

                    {/*Expression Code*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Expression Code</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.EXPRESSION_CODE}
                                    currentValue={dataInfo[METHODS_DEFINE.EXPRESSION_CODE]}
                                    onChange={handleChange}
                                    disabled={lock === 2}/>
                            </div>
                        </div>
                    </div>

                    {/*Document*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Document</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.DOCUMENT}
                                    currentValue={dataInfo[METHODS_DEFINE.DOCUMENT]}
                                    onChange={handleChange}
                                    disabled={lock === 2}/>
                            </div>
                        </div>
                    </div>

                    {/*Pages*/}
                    <div className="row align-items-center m-b-20">
                        <label className="col-md-3">
                            <span>Pages</span>
                        </label>

                        <div className="col-md-9">
                            <div className="w-75">
                                <TextField
                                    name={METHODS_DEFINE.PAGES}
                                    currentValue={dataInfo[METHODS_DEFINE.PAGES]}
                                    onChange={handleChange}
                                    disabled={lock === 2}/>
                            </div>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-end'}>
                        <button
                            className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                            onClick={handleClose}>
                            Close
                        </button>

                        {lock !== 2 && (
                            <button
                                className="btn btn-new btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={handleSave}>
                                Save
                            </button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(MethodsModal);