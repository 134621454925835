import React, {useCallback} from "react";
import ACRFItem from "./ACRFItem";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFItemGroup
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Item Group 컴포넌트
 */
const ACRFItemGroup = (props) => {
    /**
     *  @memberOf     ACRFItemGroup
     *  @type         {Object} props
     *  @property     {Object} itemGroupData - Item Group 정보
     *  @property     {String} compareColor - 비교 색상
     *  @property     {String} formType - formType 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        itemGroupData,
        compareColor,
        formType
    } = props;

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     ACRFItemGroup
     *  @function     getItemComp
     *  @return       {Element} Item Data render
     *  @description  ACRF의 해당하는 Item 그려주는 함수
     */
    const getItemComp = useCallback(() => {
        return itemGroupData.items.map((data, idx) => (
            <ACRFItem
                key={idx}
                itemData={data}
                compareColor={compareColor}
                formType={formType}
            />
        ));
    }, [itemGroupData.items]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div>
            {/*item group의 description 영역*/}
            {itemGroupData.hasOwnProperty("description") && itemGroupData.description !== "" && itemGroupData.description !== null && (
                <div
                    style={{
                        border: "1px solid #000",
                        padding: "5px",
                        background: "#F2F2F2",
                        wordBreak: "break-word",
                        breakInside: "avoid",
                        marginTop: "-1.5px",
                        fontWeight: "bold"
                    }}>
                    <pre
                        style={{
                            fontSize: "9pt",
                            fontFamily: "Arial, sans-serif",
                            whiteSpace: "pre-wrap",
                            margin: "0px"
                        }}>
                        <span style={{backgroundColor: itemGroupData.isDescriptionDiff ? compareColor : ""}}>{itemGroupData?.description?.replaceAll('<br/>', '\n')}</span>
                    </pre>
                </div>
            )}

            {/*Item 출력*/}
            {getItemComp()}
        </div>
    );
}

export default React.memo(ACRFItemGroup);