import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {getSessionState} from "../../../../common/commonFunction";
import useWindowSize from "hooks/useWindowSize";
import deleteIcon from "../../../../assets/icons/delete.svg";

//전역 변수로 스크롤 위치 지정
let vertical = 0;
let horizontal = 0;
/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @author       백도형
 *  @version      1.0
 *  @component    CriteriaGrid
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  Criteria 리스트를 grid로 표시하는 컴포넌트
 */
const CriteriaGrid = (props) => {
    /**
     *  @memberOf     CriteriaGrid
     *  @type         {Object} props
     *  @property     {Array} dataList - 화면에 표시할 데이터 리스트
     *  @property     {Number} itemKey - item Key
     *  @property     {String} ID - grid ID
     *  @property     {Function} onChange - grid 변경 시(수정, Drag & Drop, 삭제) 호출 될 상위 이벤트 함수
     *  @property     {String} name - ip data name 값
     *  @property     {Function} onClick - 테이블 cell 클릭 시 실행될 상위 컴포넌트의 이벤트 함수
     *  @property     {String} columnName - Grid Column에 표시 될 명
     *  @property     {Number} gridHeight - Grid height
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {dataList, ID, onChange, name, onClick, columnName} = props;

    /**
     *  @memberOf      CriteriaGrid
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf     CriteriaGrid
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     CriteriaGrid
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     CriteriaGrid
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        rowHeight: 34,
        showRowNumColumn: true,  // no 표시
        wrapSelectionMove: true,
        enableSorting: false,
        editable: lock !== 2,
        wordWrap: true,

        enableDrag: lock !== 2,
        enableDragByCellDrag: lock !== 2,
        enableDrop: lock !== 2
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     CriteriaGrid
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /**
     *  @memberOf     CriteriaGrid
     *  @function     getScrollInfo
     *  @param        {Object} event - 스크롤 변경시 발생하는 이벤트 정보
     *  @description  AUIGrid의 스크롤 변경시 발생하는 이벤트를 받아 처리하는 함수
     */
    const getScrollInfo = useCallback((event) => {
        if (event.type === "vScrollChange") {
            vertical = event.position;
        } else if (event.type === "hScrollChange") {
            horizontal = event.position;
        }
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // grid 데이터 setting 작업
    useEffect(() => {
        tableRef.current = window.AUIGrid.create(`#${ID}`, columnLayout, auiGridProps);

        if (dataList.length > 0) {
            window.AUIGrid.setGridData(tableRef.current, dataList);
        }

        if ({vertical: vertical, horizontal: horizontal} !== undefined && ({
            vertical: vertical,
            horizontal: horizontal
        }.vertical > 0 || {vertical: vertical, horizontal: horizontal}.horizontal > 0)) {
            window.AUIGrid.setRowPosition(tableRef.current, {
                vertical: vertical + 20,
                horizontal: horizontal + 20
            }.vertical); //세로 스크롤 위치 설정
            window.AUIGrid.setHScrollPositionByPx(tableRef.current, {
                vertical: vertical,
                horizontal: horizontal
            }.horizontal); //가로 스크롤 위치 설정
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#${ID}`);
        }
    }, [ID]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell Crick 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, ["cellClick"], (event) => {
            if (onClick) {
                if (event.dataField === 'name') {
                    onClick(event, name);
                }
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "dropEnd", (event) => {
            if (onChange) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, name);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (onChange) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, name);
            }
        });

        //세로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(tableRef.current, "vScrollChange", (event) => {
            if (getScrollInfo !== undefined) {
                getScrollInfo(event);
            }
        });

        //가로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(tableRef.current, "hScrollChange", (event) => {
            if (getScrollInfo !== undefined) {
                getScrollInfo(event);
            }
        });
    }, [onChange, name, onClick, getScrollInfo]);

    /**
     *  @memberOf     CriteriaGrid
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'name',
            headerText: columnName,
            editable: lock !== 2,
            style: "cell-left-align",
            renderer: {
                type: "TemplateRenderer"  // HTML 템플릿 렌더러 사용
            },
            labelFunction(rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
                if (!value) {
                    return "";
                }

                return `<div style='font-size: 12px; margin: 0; white-space: pre-wrap'>${value}</div>`;
            }
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteTableRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <div className='grid-style' id={ID}/>
        </React.Fragment>
    );
};

export default React.memo(CriteriaGrid);