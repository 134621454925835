import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTimer} from 'react-timer-hook';
import PubSub from "pubsub-js";
import {SESSION_TIME_ALARM, SESSION_TIME_OUT} from "constant/ConstantMsg";
import {SESSION_INFO} from "constant/CommonConstant";
import {AXIOS_PUT, clearSession} from "common/commonFunction";
import {showConfirm, showSimpleAlert} from "../common/alertModal";
import {COMMAND} from "../../common/dataProcessAgent";
import {LOGIN_URL} from "../../constant/ConstantURL";
import NetworkLayout from "../../common/NetworkLayout";

/**
 *  @summary
 *  상단 navigation 에서 사용할 사용자 타이머 컴포넌트
 *  로그인 후 시스템을 사용할 수 있는 유효 시간을 관리하고 표시한다.
 *
 *  @author 최종 수정자
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @constant
 *  @type {object}
 *  @description  timer 이용을 위한 이벤트 정의
 *                EXTEND  - 사용자가 직접 시간 증가
 *                AUTO    - 시스템 이용할 때 자동 증가
 */
export const TIMER_EVENT = {
    EXTEND: 'extend',   // 증가 처리
    AUTO: 'auto',   // 자동 증가
};

/**
 *  @constant
 *  @type {number}
 *  @description  시스템 사용이 가능한 expire time (분)
 *                유효 시각 30분
 */
export const EXPIRED_SECONDS = 30 * 60 + 1;

/**
 *  @constant
 *  @type {number}
 *  @description  시스템 사용 expire 전에 사용자에게 알림을 위한 시각
 *                유효 시각 몇 분 전 (5분)
 */
const CHECK_REMAIN_MINUTES = 5;

/**
 *  @constant
 *  @type {object}
 *  @description  팝업창 코드(상황에 따른 팝업창 SHOW)
 *                NONE    - 팝업창 없음
 *                ALARM   - 만료 시간 전 증가 할지에 대한 alert 창
 *                EXPIRE  - 만료 시간이 되어 사용자에게 알림 창
 */
const POPUP_CODE = {
    NONE: 'NONE',
    ALARM: 'ALARM',
    EXPIRE: 'EXPIRE',
};

/**
 *  @memberOf     UserTimer
 *  @async        dataProcess
 *  @param        {string} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param        {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return       {Object} response.data - back-end 응답
 *  @description  back-end 와 통신 하는 비지니스 로직 함수
 */
async function dataProcess(command, params) {
    let response = null;

    switch (command) {

        case COMMAND.DATA_UPDATE :
            const {requestUrl} = params;
            const url = `${requestUrl}`;
            response = await AXIOS_PUT(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @type      {function(*): *}
 *  @function  상단 navigation 에서 사용할 사용자 타이머 컴포넌트
 *  @param     {Object} props - 상위 컴포넌트에서 전달 받은 property
 */
const UserTimer = (props) => {

        /*################################################################################*/
        //## data 영역
        //##  - props, state
        /*################################################################################*/
        /*
        *   상위 컴포넌트에서 전달 받은 props
        *   1. history      : 페이지 이동을 위한 history 객체
        */
        const {history} = props;

        /**
         *  @var          {RefObject} netWorkAgent
         *  @description  netWorkLayout 컴포넌트 Ref
         */
        const netWorkAgent = useRef(null);

        // timer 컴포넌트 사용
        // https://www.npmjs.com/package/react-timer-hook
        const {
            seconds,  // 현재 남은 초
            minutes,  // 현재 남은 분
            restart,  // 타이머 다시 시작
        } = useTimer({expiryTimestamp: 1, onExpire: () => onTimerExpired()});

        // popup 창 코드
        const [popupCode, setPopupCode] = useState(POPUP_CODE.NONE);

        /*################################################################################*/
        //## function define 영역
        //## - useCallback
        /*################################################################################*/
        /**
         *  @function  onTimerExpired
         *  @param  none
         *  @description  expired popup 의 yes 버튼 클릭 시 처리 함수
         */
        const onExpiredEvent = useCallback(() => {
            setPopupCode(POPUP_CODE.NONE);

            // sessionStorage 의 값들을 지움
            clearSession();
            // login 화면으로 이동
            history.push("/account/login");

        }, [history]);

        /**
         *  @function  onTimerExpired
         *  @param  none
         *  @description  expired 되었을 때  사용자에게 보여 줄 팝업 생성 함수
         */
        const onTimerExpired = useCallback(() => {

            // expire 팝업
            setPopupCode(POPUP_CODE.EXPIRE);
            onExpiredEvent();
        }, [onExpiredEvent]);

        /**
         *  @function  setExpiredTime
         *  @param  {date} - expire date 객체
         *  @description  parameter 로 전달된 date로 expired time을 셋팅 한다.
         */
        const setExpiredTime = useCallback((expiredTime) => {

            // local storage setting
            sessionStorage.setItem(SESSION_INFO.TIMER_EXPIRE_TIME, String(expiredTime.getTime()));

            // expired time 으로 timer 재 시작
            restart(expiredTime);

        }, [restart]);


        /**
         *  @function  setExpiredTime
         *  @param  none
         *  @description  timer을 시작 하는 함수
         *                local storage의 expired time을 read 하여 timer를 start 한다.
         */
        const startTimer = useCallback(() => {

            // sessionStorage 에 expired time이 있는 경우
            if (sessionStorage.getItem(SESSION_INFO.TIMER_EXPIRE_TIME) !== null) {

                const expireTime = Number(sessionStorage.getItem(SESSION_INFO.TIMER_EXPIRE_TIME));
                const time = new Date(expireTime);

                restart(time);
            } else {
                // 없는 경우 만료 처리(expired popup)
                setPopupCode(POPUP_CODE.EXPIRE);
            }

        }, [restart]);


        /**
         *  @function  resetTimer
         *  @param  none
         *  @description  timer 를 reset 하는 함수
         *                자동 연장일 때 주로 사용
         */
        const resetTimer = useCallback(() => {

            const nowTime = new Date();

            // 현재 시간 기준 + expired 시간 => 실제 expired
            nowTime.setSeconds(nowTime.getSeconds() + EXPIRED_SECONDS);

            // expired time setting
            setExpiredTime(nowTime);

        }, [setExpiredTime]);


        const resetSession = useCallback(() => {
            // 데이터 수정 요청
            const command = COMMAND.DATA_UPDATE;

            // 데이터 수정에 필요한 parameter
            const params = {
                requestUrl: `${LOGIN_URL}/session`,
            };

            // back-end 데이터 처리 요청
            netWorkAgent.current.request(command, params);
        }, [])

        /**
         *  @function  checkPopupYes
         *  @param  none
         *  @description  alarm popup 에서 yes를 선택하였을 때 처리 함수
         *                - backend로 데이터 통신
         */
        const checkPopupYes = useCallback(() => {

            setPopupCode(POPUP_CODE.NONE);

            // reset 처리
            resetTimer();
            resetSession();
        }, [resetSession, resetTimer]);


        /**
         *  @function  subscribe
         *  @param  {string} msg  - 이벤트 종류
         *  @param  {object} data - 이벤트에 사용하는 정보 객체
         *  @description  이벤트 수신 처리기
         *                - backend로 데이터 통신
         */
        const subscribe = useCallback((msg, data) => {

            switch (msg) {

                // 자동 연장
                case TIMER_EVENT.AUTO:
                    resetTimer();
                    break;

                // 시간 연장 액션 처리
                case TIMER_EVENT.EXTEND:
                    setPopupCode(POPUP_CODE.ALARM);
                    break;

                default :
                    break;
            }
        }, [resetTimer]);

        /*################################################################################*/
        //## rerender effect 영역
        //## - useEffect
        /*################################################################################*/
        /**
         *  @listens  [] - 최초 실행 시
         *  @description  - 이벤트 수신 처리 등록, 해제
         */
        useEffect(() => {
            // 메시지 수신기 셋팅
            PubSub.subscribe(TIMER_EVENT.EXTEND, subscribe);
            PubSub.subscribe(TIMER_EVENT.AUTO, subscribe);

            return () => {
                PubSub.unsubscribe(TIMER_EVENT.EXTEND);
                PubSub.unsubscribe(TIMER_EVENT.AUTO);
            }

        }, [subscribe]);

        /**
         *  @listens  [minutes, seconds] - 시간이 변경 될 때
         *  @description  - alarm 처리를 취한 시간 데이터 체크
         */
        useEffect(() => {

            if (minutes === CHECK_REMAIN_MINUTES && seconds === 0) {
                setPopupCode(POPUP_CODE.ALARM);
            }

        }, [minutes, seconds]);


        /**
         *  @listens  [] - 최초 실행 시
         *  @description  - 화면 렌더링 시 timer 시작
         */
        useEffect(() => {
            startTimer();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        /**
         *  @function  dataResponse
         *  @param  {Object} action - 요청시 보낸 정보(command, params)
         *  @param  {Object} data   - 검색어
         *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
         */
        const dataResponse = useCallback((action, data) => {
            // data 가 있는 경우
            if (data) {
                // action state 에서 이전 호출했던 정보 get
                const {command} = action;

                // list 응답에 대한 처리
                switch (command) {
                    default:
                        return null;
                }
            }
        }, []);

        /*################################################################################*/
        //## component view 영역
        //## - JSX return
        /*################################################################################*/
        return (
            <>
                <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse}/>
                <span>{String(minutes).length === 1 ? '0' + String(minutes) : minutes}</span>:<span>{String(seconds).length === 1 ? '0' + String(seconds) : String(seconds)}</span>
                {/* expired 되었을 때 popup */}
                {
                    popupCode === POPUP_CODE.EXPIRE && (
                        showSimpleAlert(SESSION_TIME_OUT)
                    )
                }
                {/* check alarm */}
                {
                    popupCode === POPUP_CODE.ALARM && (
                        showConfirm(SESSION_TIME_ALARM, checkPopupYes, onExpiredEvent)
                    )
                }
            </>
        );
    }
;

export default UserTimer;
