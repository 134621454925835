import React, {useCallback, useEffect, useRef, useState} from "react";
import {produce} from "immer";
import html2canvas from "html2canvas";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    AXIOS_GET,
    AXIOS_PUT_FORM,
    copyObject,
    getSessionState,
    getValueByLanguage,
    isDataExist
} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {DATA_CHANGE_CONTENT} from "constant/ConstantMsg";
import {LANGUAGE_FIELD, NONE, Y_OR_N} from "constant/ConstantName";
import {DESIGN_SYNOPSIS_URL} from "constant/ConstantURL";
import useToast from "hooks/useToast";
import {showConfirm} from "imtrial/components/alertModal";
import Checkbox from "imtrial/components/Checkbox";
import RadioButton from "imtrial/components/RadioButton";
import SelectBox from "imtrial/components/SelectBox";
import TextField from "imtrial/components/TextField";
import TextArea from "imtrial/components/TextArea";
import DesignBottom from "../DesignBottom";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       DesignConfig
 *  @constant       {String} DEFAULT_VALUE
 *  @description    기본 빈 값
 */
const DEFAULT_VALUE = '';

/**
 *  @memberOf       DesignConfig
 *  @constant       {String} DEFAULT_UNIT_VALUE
 *  @description    Unit 기본 값
 */
const DEFAULT_UNIT_VALUE = 'Year(s)';

/**
 *  @memberOf       DesignConfig
 *  @constant       {Object} _DICTIONARY
 *  @description    language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
export const _DICTIONARY = {
    'kr': {
        'CROSS-OVER': '교차 설계',
        'PARALLEL': '평행 설계',
        'SINGLE GROUP': '단일군',
        'Factorial': '요인 설계',

        'Single Center': '단일기관',
        'Multi Center': '다기관',

        'Year(s)': '일',
        'Month(s)': '주',
        'Week(s)': '개월',
        'Day(s)': '년',

        'Open Label': '공개',
        'Double Blind': '이중 눈가림',
        'Single Blind': '단일 눈가림',

        'Single Dose': '단회투여',
        'Multi Dose': '반복투여',
        'Single/Multiple Dose': '단회/반복 투여',
        'Single/Multi Dose': '단회/반복 투여',

        'Randomized': '무작위 배정',

        'None': '해당 없음',
        'Active': '활성 대조',
        'Placebo': '위약 대조',
        'Active/Placebo control': '활성/위약 대조',
        'Active/Placebo': '활성/위약 대조',

        'Dose escalation': '단계적 증량',
        'Dose de-escalation': '단계적 감량',
    }
};

/**
 *  @memberOf       DesignConfig
 *  @constant       {Object} NAME
 *  @description    Design field 명 정의
 */
export const NAME = {
    INTERVENTION: 'intervention',
    MULTI_CENTER: 'multiCenter',
    BLINDING: 'blinding',
    RANDOMIZATION: 'randomization',
    DOSE: 'dose',
    CONTROL_TYPE: 'controlType',
    DOSE_FINDING: 'doseFinding',
    DESCRIPTION: 'description',
    DURATION: 'duration',
    PERIOD: 'period',
    UNIT: 'unit',
};

/**
 *  @memberOf       DesignConfig
 *  @constant       {Object} VALIDATION_FIELD
 *  @description    입력 항목 validation 체크에 사용 되는 필드
 */
const VALIDATION_FIELD = {
    intervention: true,
    multiCenter: true,
    period: true,
    unit: true,
    blinding: true,
    dose: true,
    controlType: true,
    doseFinding: true,
    description: true,
};

/**
 *  @memberOf       DesignConfig
 *  @constant       {Object} DEFAULT_DATA_OBJECT
 *  @description    아이템 추가 시 필요한 데이터 default 값
 */
const DEFAULT_DATA_OBJECT = {
    intervention: "",
    multiCenter: "",
    blinding: "",
    randomization: false,
    dose: "",
    controlType: "",
    doseFinding: "",
    description: "",
    period: "",
    unit: ""
};

/**
 *  @memberOf       DesignConfig
 *  @constant       {String} SINGLE_GROUP
 *  @description    Intervention single group 구분 값
 */
export const SINGLE_GROUP = 'Single-group';

/**
 *  @memberOf       DesignConfig
 *  @constant       {Object} CONTROL_TYPE_NAME
 *  @description    Control type 항목 버튼에 표시할 label
 */
export const CONTROL_TYPE_NAME = {
    NONE: 'None',
    ACTIVE: 'Active',
    PLACEBO: 'Placebo',
};

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} INTERVENTION_LIST
 *  @description    Intervention 항목에 표시할 옵션 리스트
 */
export const INTERVENTION_LIST = [
    {
        text: 'Parallel',
        value: 'Parallel'
    },
    {
        text: 'Crossover',
        value: 'Crossover'
    },
    {
        text: SINGLE_GROUP,
        value: SINGLE_GROUP
    },
    {
        text: 'Factorial',
        value: 'Factorial'
    },
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} MULTICENTER_LIST
 *  @description    Multicenter 항목에 표시할 옵션 리스트
 */
export const MULTICENTER_LIST = [
    {
        text: 'Single Center',
        value: 'Single Center'
    },
    {
        text: 'Multi Center',
        value: 'Multi Center'
    },
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} UNIT_LIST
 *  @description    Unit 항목에 표시할 옵션 리스트
 */
export const UNIT_LIST = [
    {
        text: 'Year(s)',
        value: 'Year(s)'
    },
    {
        text: 'Month(s)',
        value: 'Month(s)'
    },
    {
        text: 'Week(s)',
        value: 'Week(s)'
    },
    {
        text: 'Day(s)',
        value: 'Day(s)'
    }
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} BLINDING_LIST
 *  @description    Blind 항목에 표시할 옵션 리스트
 */
export const BLINDING_LIST = [
    {
        text: 'Open Label',
        value: 'Open Label'
    },
    {
        text: 'Double Blind',
        value: 'Double Blind'
    },
    {
        text: 'Single Blind',
        value: 'Single Blind'
    },
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} DOSE_LIST
 *  @description    Dose 항목에 표시할 옵션 리스트
 */
export const DOSE_LIST = [
    {
        text: 'Single Dose',
        value: 'Single Dose'
    },
    {
        text: 'Multi Dose',
        value: 'Multi Dose'
    },
    {
        text: 'Single/Multi Dose',
        value: 'Single/Multiple Dose'
    }
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} CONTROLTYPE_LIST
 *  @description    Control type 항목에 표시할 옵션 리스트
 */
export const CONTROLTYPE_LIST = [
    {
        text: NONE,
        value: NONE
    },
    {
        text: 'Active',
        value: 'Active'
    },
    {
        text: 'Placebo',
        value: 'Placebo'
    },
    {
        text: 'Active/Placebo',
        value: 'Active/Placebo control'
    }
];

/**
 *  @memberOf       DesignConfig
 *  @constant       {Array} DOSEFINDING_LIST
 *  @description    Dose Finding 항목에 표시할 옵션 리스트
 */
export const DOSEFINDING_LIST = [
    {
        text: 'None',
        value: 'None'
    },
    {
        text: 'Dose escalation',
        value: 'Dose escalation'
    },
    {
        text: 'Dose de-escalation',
        value: 'Dose de-escalation'
    },
];

/**
 *  @memberOf       DesignConfig
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {dataID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO :
            url = `${DESIGN_SYNOPSIS_URL}/design/${dataID}`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${DESIGN_SYNOPSIS_URL}/design/${dataID}`;
            response = await AXIOS_PUT_FORM(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author         주예리나
 *  @version        1.0
 *  @see            html2canvas (https://github.com/niklasvh/html2canvas)
 *  @component      DesignConfig
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    design information 의 Design 편집 컴포넌트
 */
const DesignConfig = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      DesignConfig
     *  @type          {Object} props
     *  @property      {String} ID - 리스트 ID
     *  @property      {Object} history - url 이동을 위해 사용
     *  @property      {Function} referencePopup - reference 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {String} language - language 정보 ('kr' | 'en')
     *  @property      {Function} onChangeData - 변경 전 데이터, 변경 후 데이터 호출하는 함수
     *  @property      {Boolean } isSaved - isDataChange Modal에서 Yes 버튼 상태
     *  @property      {Boolean} onSetSave - isDataChange Modal Yes 버튼 클릭 상태 처리
     *  @property      {Boolean} isClosed - isDataChange Modal에서 No 버튼 상태
     *  @property      {Boolean} onSetClose - isDataChange Modal No 버튼 클릭 상태 처리
     *  @property      {Boolean} isDataCheckModal - isDataChange Modal 상태
     *  @property      {Boolean} onDataChangeModal - isDataChange Modal 상태 처리
     *  @property      {Boolean} isChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태
     *  @property      {Boolean} isChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태
     *  @property      {Boolean} onChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property      {Boolean} onChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property      {Boolean} isTabDataCheckModal - Top Tab의 isDataChange Modal 상태
     *  @property      {Boolean} onChangeDataCheckModal - Top Tab의 isDataChange Modal 상태 처리
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        history,
        referencePopup,
        onList,
        language,
        onChangeData,
        isSaved,
        onSetSave,
        isClosed,
        onSetClose,
        isDataCheckModal,
        onDataChangeModal,
        isChangeTabSave,
        isChangeTabClose,
        onChangeTabSave,
        onChangeTabClose,
        isTabDataCheckModal,
        onChangeDataCheckModal
    } = props;

    /**
     *  @memberOf       DesignConfig
     *  @type           {Object} getSessionState
     *  @property       {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description    netWorkLayout 컴포넌트 Ref
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf       DesignConfig
     *  @var            {*} netWorkAgent
     *  @description    netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf       DesignConfig
     *  @var            {Function} showToast
     *  @description    toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf       DesignConfig
     *  @var            {HTMLDivElement} captureRef
     *  @description    저장 시 file 객체로 전송할 이미지 캡쳐 영역 ref
     */
    const captureRef = useRef(null);

    /**
     *  @memberOf       DesignConfig
     *  @var            {Object} dataInfo 받아온 data table
     *  @description    화면에 표시 될 상세 정보
     */
    const [dataInfo, setDataInfo] = useState(DEFAULT_DATA_OBJECT);

    /**
     *  @memberOf       DesignConfig
     *  @var            {Array} originData
     *  @description    원본 데이터
     */
    const [originData, setOriginData] = useState(null);

    /**
     *  @memberOf       DesignConfig
     *  @var            {Object} validation
     *  @description    입력 값 validation 체크
     */
    const [validation, setValidation] = useState(VALIDATION_FIELD);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf       DesignConfig
     *  @function       getDataInfo
     *  @description    상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        const params = {
            dataID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf       DesignConfig
     *  @function       getConvertSelectList
     *  @param          {Array} selectList - 화면에 표시할 옵션 리스트
     *  @return         {Array} convertList - 언어 정보에 따라 변경된 데이터 리스트
     *  @description    언어를 적용하여 데이터 리스트를 변경
     */
    const getConvertSelectList = useCallback((selectList) => {
        let originList = copyObject(selectList);
        let convertList = [];

        let item;
        for (item of originList) {
            if (_DICTIONARY.hasOwnProperty(language)) {
                const dict = _DICTIONARY[language];

                if (dict.hasOwnProperty(item.text)) {
                    let newItem = copyObject(item)
                    newItem['text'] = dict[item.text];
                    convertList.push(newItem);
                } else {
                    convertList.push(item);
                }
            } else {
                convertList.push(item);
            }
        }

        return convertList;
    }, [language]);

    /**
     *  @memberOf       DesignConfig
     *  @function       getLanguageLabel
     *  @param          {String} origin - 변환하는 값
     *  @return         {String} 변환 된 값
     *  @description    study language 값에 따른 언어 변환 기능
     */
    const getLanguageLabel = useCallback((origin) => {
        let languageValue = origin;

        if (_DICTIONARY.hasOwnProperty(language)) {
            const dict = _DICTIONARY[language];
            if (dict.hasOwnProperty(origin)) {
                languageValue = dict[origin];
            }
        }

        return languageValue;
    }, [language]);

    /**
     *  @memberOf       DesignConfig
     *  @function       handleChange
     *  @param          {String} name - 입력 요소의 tag name
     *  @param          {String} value - 입력 되거나 변경 된 값
     *  @description    input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((name, value) => {
        let preData = copyObject(dataInfo); // 데이터 정보 테이블 복사 - 기존 정보를 활용
        let addAttr = {}; // 추가 속성
        let description = []; // 하단에 표시 할 description

        preData[name] = value;

        // single group 일때 random 은 N
        if (name === NAME.INTERVENTION && value === SINGLE_GROUP) {
            addAttr[NAME.RANDOMIZATION] = 0;
            preData[NAME.RANDOMIZATION] = 0;

            addAttr[NAME.CONTROL_TYPE] = NONE;
            preData[NAME.CONTROL_TYPE] = NONE;
        }

        // description 변화를 제외하고 작동
        if (name !== NAME.DESCRIPTION) {
            // intervention
            if (isDataExist(preData[NAME.INTERVENTION])) {
                description.push(getLanguageLabel(preData[NAME.INTERVENTION]));
            }
            // multi center
            if (isDataExist(preData[NAME.MULTI_CENTER])) {
                description.push(getLanguageLabel(preData[NAME.MULTI_CENTER]));
            }
            // 기간, 단위
            if (isDataExist(preData[NAME.PERIOD]) && isDataExist(preData[NAME.UNIT])) {
                let durationString = '';
                durationString = `${preData[NAME.PERIOD]} ${preData[NAME.UNIT]}`;
                description.push(durationString);
            }
            // blind
            if (isDataExist(preData[NAME.BLINDING])) {
                description.push(getLanguageLabel(preData[NAME.BLINDING]));
            }
            // 랜덤 여부
            if (isDataExist(preData[NAME.RANDOMIZATION])) {
                let randomYn = preData[NAME.RANDOMIZATION];

                // 랜덤일 경우에만 표시
                if (randomYn) {
                    // description.push('Randomized');
                    description.push(getLanguageLabel('Randomized'));
                }
            }
            // dose
            if (isDataExist(preData[NAME.DOSE])) {
                description.push(getLanguageLabel(preData[NAME.DOSE]));
            }
            // control type
            if (isDataExist(preData[NAME.CONTROL_TYPE])) {
                if (preData[NAME.CONTROL_TYPE] !== NONE) {
                    description.push(getLanguageLabel(preData[NAME.CONTROL_TYPE]));
                }
            }
            // dose finding
            if (isDataExist(preData[NAME.DOSE_FINDING])) {
                if (preData[NAME.DOSE_FINDING] !== NONE) {
                    description.push(getLanguageLabel(preData[NAME.DOSE_FINDING]));
                }
            }
            // 만들어진 description string make
            if (isDataExist(description)) {
                addAttr[NAME.DESCRIPTION] = description.join(', ');
            } else {
                addAttr[NAME.DESCRIPTION] = '';
            }
        }

        setDataInfo(
            produce(dataInfo, draft => {
                draft[name] = value;
                for (const draftKey in addAttr) {
                    draft[draftKey] = addAttr[draftKey];
                }
            })
        );
    }, [dataInfo, getLanguageLabel]);

    /**
     *  @memberOf       DesignConfig
     *  @function       goToList
     *  @description    목록으로 이동 요청하는 함수
     */
    const goToList = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    /**
     *  @memberOf       DesignConfig
     *  @function       handleReference
     *  @description    ref 버튼 클릭시 실행되는 함수
     */
    const handleReference = useCallback(() => {
        if (referencePopup !== undefined) {
            referencePopup();
        }
    }, [referencePopup]);

    /**
     *  @memberOf     DesignConfig
     *  @function     saveDataInfo
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  image 생성 시 callback 으로 실행되어 데이터 저장 api 호출
     */
    const saveDataInfo = useCallback((image) => {
        // 데이터 수정 요청
        const command = COMMAND.DATA_UPDATE;
        const formData = new FormData();
        let sendJsonObject = copyObject(dataInfo); // 전송 오브젝트 데이터

        if (sendJsonObject[NAME.RANDOMIZATION] === 1 || sendJsonObject[NAME.RANDOMIZATION] === true) {
            sendJsonObject[NAME.RANDOMIZATION] = Y_OR_N.Y;
        } else {
            sendJsonObject[NAME.RANDOMIZATION] = Y_OR_N.N;
        }

        formData.append("data", JSON.stringify(sendJsonObject));
        formData.append("file", image, "design_config.png");

        // 데이터 수정에 필요한 parameter
        const params = {
            dataID: ID,
            sendObject: formData
        };

        // back-end 데이터 처리 요청
        (netWorkAgent.current !== null && (
            netWorkAgent.current.request(command, params)));
    }, [ID, dataInfo]);

    /**
     *  @memberOf     DesignConfig
     *  @function     getImageBlob
     *  @param        {Function} callback - image 저장 후 실행시킬 callback 함수
     *  @description  save 버튼 클릭 시 실행되어 현재 캡쳐 영역 image 생성 후 callback 함수 실행
     */
    const getImageBlob = useCallback((callback) => {
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0);
            const blobBin = window.atob(image.split(',')[1]);	// base64 데이터 디코딩
            const array = [];

            let i;
            for (i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }

            const blobImage = new Blob([new Uint8Array(array)], {type: 'image/png'});	// Blob 생성

            callback(blobImage);
        });
    }, []);

    /**
     *  @memberOf     DesignConfig
     *  @function     callSaveAPI
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  control type 값에 따라 confirm 팝업창 띄운 후 saveDataInfo 호출
     */
    const callSaveAPI = useCallback((image) => {
        let showPopup = false;

        // 컨트롤 타입 설정 값이 존재하고 있는지 확인
        if (originData.hasOwnProperty(NAME.CONTROL_TYPE)) {
            const originType = originData[NAME.CONTROL_TYPE];

            // 데이터 바뀌기 이전 원본 타입이 active 거나 placebo 일 경우
            if (originType === CONTROL_TYPE_NAME.ACTIVE || originType === CONTROL_TYPE_NAME.PLACEBO) {
                if (dataInfo[NAME.CONTROL_TYPE] !== originType) { // active Type 값이 달라졌을 때 경고 창 오픈여부 true
                    showPopup = true; //
                }
            }
        }

        if (showPopup === true && isDataCheckModal === false && isTabDataCheckModal === false) {
            showConfirm(DATA_CHANGE_CONTENT, () => { // confirm 창 오픈 (yes 시에만 save 가능)
                saveDataInfo(image);
            });
        } else {
            saveDataInfo(image);
        }
    }, [dataInfo, isDataCheckModal, isTabDataCheckModal, originData, saveDataInfo]);

    /**
     *  @memberOf     DesignConfig
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        const validateKeys = Object.keys(VALIDATION_FIELD);  // validation 할 key
        let returnBool = true;
        let checkValidData = {}; // validation 결과 객체

        // data validate 체크
        for (const key of validateKeys) {
            checkValidData[key] = isDataExist(dataInfo[key]);
        }

        // 결과 값 setting
        setValidation(checkValidData);
        // 모든 validate 결과가 true일 때 true
        returnBool = Object.values(checkValidData).every(item => {
            return item === true;
        });

        return returnBool;
    }, [dataInfo]);

    /**
     *  @memberOf     DesignConfig
     *  @function     handleFocus
     *  @param        {String} name - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name) => {
        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (validation[name] === false) {
                setValidation(
                    produce(validation, draft => {
                        draft[name] = true;
                    })
                );
            }
        }
    }, [validation]);

    /**
     *  @memberOf     DesignConfig
     *  @function     handleChangeData
     *  @description  원본 데이터와 화면에 보이는 데이터를 전달하는 함수
     */
    const handleChangeData = useCallback(() => {
        onChangeData(dataInfo, originData);
    }, [dataInfo, onChangeData, originData]);

    /**
     *  @memberOf     DesignConfig
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        const valid = validateData();
        if (valid === false) {
            return;
        }
        getImageBlob(callSaveAPI);
    }, [callSaveAPI, getImageBlob, validateData]);

    /**
     *  @memberOf     DesignConfig
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 검색어
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        // data 가 있는 경우
        if (data) {
            // action state 에서 이전 호출했던 정보 get
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_INFO :
                    // 데이터가 있는 경우
                    if (data.hasOwnProperty('data')) {
                        let responseData = copyObject(data.data);

                        // randomization 데이터 'Y' | 'N' 으로 들어옴
                        if (responseData[NAME.RANDOMIZATION] === Y_OR_N.Y) {
                            responseData[NAME.RANDOMIZATION] = true;
                        } else {
                            responseData[NAME.RANDOMIZATION] = false;
                        }

                        // description 관련
                        const convertValue = getValueByLanguage(responseData, language, LANGUAGE_FIELD.DESCRIPTION);
                        responseData[LANGUAGE_FIELD.DESCRIPTION] = convertValue;

                        //생성모드 일 때 Unit의 Default 값 설정
                        if (Object.keys(data.data).length === 0) {
                            responseData[NAME.UNIT] = DEFAULT_UNIT_VALUE;
                        }

                        setDataInfo(responseData);
                        setOriginData(responseData);
                    } else {
                        setDataInfo(DEFAULT_DATA_OBJECT);
                        setOriginData(null);
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast(getDataInfo());
                    break;

                // no default
            }
        }
    }, [getDataInfo, language, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 상세 데이터 요청
    useEffect(() => {
        getDataInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // descripion validation 체크
    useEffect(() => {
        if (isDataExist(dataInfo[NAME.DESCRIPTION])) {
            if (validation[NAME.DESCRIPTION] === false) {
                setValidation(
                    produce(
                        validation, draft => {
                            draft[NAME.DESCRIPTION] = true;
                        })
                );
            }
        }
    }, [dataInfo, validation]);

    // origin data와 화면에 보이는 data를 전달하는 함수 호출
    useEffect(() => {
        handleChangeData();
    }, [handleChangeData]);

    //데이터 변경 후 Tab 이동 시 저장하라고 나오는 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isSaved === true) {
            onSetClose(false);
            handleSave();
            onSetSave(false);
        }

        if (isClosed === true && isSaved === false) {
            getDataInfo();
            onSetClose(false);

        }

        onDataChangeModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosed, isSaved, onDataChangeModal, onSetClose, onSetSave]);

    // Design Top Tab에서 데이터 변경 후 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isChangeTabSave === true) {
            onChangeTabClose(false);
            handleSave();
            onChangeTabSave(false);
        }

        if (isChangeTabClose === true && isChangeTabSave === false) {
            getDataInfo();
            onChangeTabClose(false);
        }
        onChangeDataCheckModal(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeTabClose, isChangeTabSave, onChangeDataCheckModal, onChangeTabClose, onChangeTabSave]);
    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box-row">
                <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                        <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                            <div ref={captureRef}>
                                {/* Intervention Model */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Intervention Model' : '연구 설계 모델'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>
                                    <div className="col-10">
                                        <div>
                                            <SelectBox
                                                className={'w-50'}
                                                name={NAME.INTERVENTION}
                                                dataList={getConvertSelectList(INTERVENTION_LIST)}
                                                currentValue={dataInfo[NAME.INTERVENTION]}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                defaultCaption={DEFAULT_VALUE}
                                                validation={validation[NAME.INTERVENTION]}
                                                readOnly={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Multicenter */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Multicenter' : '단일기관 / 다기관'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>
                                    <div className="col-10">
                                        <RadioButton
                                            name={NAME.MULTI_CENTER}
                                            dataList={getConvertSelectList(MULTICENTER_LIST)}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            currentValue={dataInfo[NAME.MULTI_CENTER]}
                                            validation={validation[NAME.MULTI_CENTER]}
                                            disabled={lock === 2}/>
                                    </div>
                                </div>

                                {/* Planned Treatment Duration */}
                                <div className="form-group row m-b-20">
                                    <label className="col-2">
                                        {language === 'en' ? 'Planned Study Duration' : '연구 기간'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>
                                    <div className="align-items-center col-10">
                                        <div className="row">
                                            <div className="col-5 px-1">
                                                <TextField
                                                    name={NAME.PERIOD}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    currentValue={dataInfo[NAME.PERIOD]}
                                                    validation={validation[NAME.PERIOD]}
                                                    className=' '
                                                    disabled={lock === 2}
                                                    maxlength={'16'}/>
                                            </div>

                                            <div className="d-flex col-md-5 align-items-center">
                                                <label
                                                    className="p-r-10 p-t-5 ">{language === 'en' ? 'Unit' : '단위'}</label>
                                                <SelectBox className={'w-50'}
                                                           name={NAME.UNIT}
                                                           dataList={getConvertSelectList(UNIT_LIST)}
                                                           currentValue={dataInfo[NAME.UNIT]}
                                                           onChange={handleChange}
                                                           onFocus={handleFocus}
                                                           validation={validation[NAME.UNIT]}
                                                           defaultCaption={DEFAULT_UNIT_VALUE}
                                                           readOnly={lock === 2}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Blinding */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Blinding' : '눈가림'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                dataList={getConvertSelectList(BLINDING_LIST)}
                                                name={NAME.BLINDING}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                currentValue={dataInfo[NAME.BLINDING]}
                                                validation={validation[NAME.BLINDING]}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Randomization */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Randomization' : '무작위배정'}
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <Checkbox
                                                name={NAME.RANDOMIZATION}
                                                onChange={handleChange}
                                                disabled={dataInfo[NAME.INTERVENTION] === SINGLE_GROUP || lock === 2}
                                                currentValue={dataInfo[NAME.RANDOMIZATION]}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Single/Multiple Dose(s) */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Single/Multiple Dose(s)' : '단회투여 / 반복투여'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={NAME.DOSE}
                                                dataList={getConvertSelectList(DOSE_LIST)}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                currentValue={dataInfo[NAME.DOSE]}
                                                validation={validation[NAME.DOSE]}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Control Type */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Control Type' : '대조군'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={NAME.CONTROL_TYPE}
                                                dataList={getConvertSelectList(CONTROLTYPE_LIST)}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                currentValue={dataInfo[NAME.CONTROL_TYPE]}
                                                validation={validation[NAME.CONTROL_TYPE]}
                                                disabled={dataInfo[NAME.INTERVENTION] === SINGLE_GROUP || lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Dose Finding */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Dose Finding' : '용량 결정(Dose Finding)'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={NAME.DOSE_FINDING}
                                                dataList={getConvertSelectList(DOSEFINDING_LIST)}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                currentValue={dataInfo[NAME.DOSE_FINDING]}
                                                validation={validation[NAME.DOSE_FINDING]}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Study Design Description */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Study Design Description' : '연구 디자인(상세)'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <TextArea
                                                name={NAME.DESCRIPTION}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                currentValue={`This study is ${dataInfo[NAME.DESCRIPTION]} Clinical Trial.`}
                                                validation={validation[NAME.DESCRIPTION]}
                                                readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
            {/* 하단 처리 버튼 */}
            <DesignBottom onList={goToList} onRef={handleReference} onSave={handleSave}/>
        </>
    );
};

export default React.memo(DesignConfig);

