import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    AXIOS_GET,
    AXIOS_PUT,
    GenerateID,
    getListToString,
    getSessionState,
    handleExamplePopup
} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import useToast from "../../../../../../hooks/useToast";
import SelectElementDateModal from "../SelectElementDateModal";
import deleteIcon from "../../../../../../assets/icons/close.svg"
import AUIGrid from "../../../../../components/AUIGrid";
import DomainExplain from "../../../../../components/DomainExplain";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @memberOf     TMDomainList
 *  @constant     {Array} TMRPT_OPTION
 *  @description  TMRPT 컬럼의 선택 옵션
 */
const TMRPT_OPTION = [
    {
        text: "N",
        value: "N"
    },
    {
        text: "Y",
        value: "Y"
    }
];

/**
 *  @memberOf     TMDomainList
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, domain, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = `${requestUrl}/tdomain/${ID}?domain=${domain}`;
            response = await AXIOS_GET(url);
            break;

        case COMMAND.DATA_UPDATE :
            url = `${requestUrl}/tdomain/${domain}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    TMDomainList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 Variables Tab 의 TM 도메인 컴포넌트
 */
const TMDomainList = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     TMDomainList
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     TMDomainList
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 리스트 ID
     *  @property     {String} designID - Add 버튼 클릭시 STUDYID 컬럼에 들어갈 Design ID
     *  @property     {String} name - mapping의 name
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, designID, name, lock} = getSessionState();

    /**
     *  @memberOf     TMDomainList
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     TMDomainList
     *  @var          {*} auiGrid
     *  @description  그리드 엘리먼트 접근
     */
    const auiGrid = useRef(null);

    /**
     *  @memberOf     TMDomainList
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     TMDomainList
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     TMDomainList
     *  @var          {Array} originDataList
     *  @description  화면에 표시 될 리스트의 가공전 리스트(내용은 같음)
     */
    const [originDataList, setOriginDataList] = useState([]);

    /**
     *  @memberOf     TMDomainList
     *  @var          {String} studyID
     *  @description  STUDYID 컬럼에 표시하기 위한 값
     */
    const [studyID, setStudyID] = useState();

    /**
     *  @memberOf     TMDomainList
     *  @var          {Object} selectDefInfo
     *  @description  Definition 칸에 표시될 정보
     */
    const [selectDefInfo, setSelectDefInfo] = useState({});

    /**
     *  @memberOf     TMDomainList
     *  @var          {String} rowID
     *  @description  선택 줄 ID
     */
    const [rowID, setRowID] = useState("");

    /**
     *  @memberOf     TMDomainList
     *  @var          {String} descriptionValue
     *  @description  Description 입력 된 값
     */
    const [descriptionValue, setDescriptionValue] = useState('');

    /**
     *  @memberOf     TMDomainList
     *  @var          {Boolean} isVariableModal
     *  @description  Disease of Definition Modal의 Open/Close 상태
     */
    const [isVariableModal, setVariableModal] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     TMDomainList
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: SDTM_MAPPING_URL,
            ID: ID,
            domain: "TM"
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleAddRow
     *  @description  Add 버튼 클릭시 호출 되는 함수. TM 도메인 한줄 추가.
     */
    const handleAddRow = useCallback(() => {
        if (lock !== 2) {
            let rowID = GenerateID();

            const params = {
                num: 1,
                item: {
                    "ID": rowID,
                    "row": "",
                    "STUDYID": name.split(" ")[0],
                    "domain": "",
                    "MIDSTYPE": "",
                    "definitionVal": "",
                    "TMDEF": "",
                    "TMRPT": "Y",
                    "definition": "", //definition 필드가 없으면 DB 에러남
                    "def": [] //def 필드가 없으면 definition 추가했을때 def가 없어 에러남
                },
                dataList: dataList
            }

            auiGrid.current.request(params);
        }
    }, [dataList, lock, name]);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleDelete
     *  @description  Example 알림창, Disease of Definition 입력창 닫기
     */
    const handleClose = useCallback(() => {
        setVariableModal(false);
        setSelectDefInfo({});
    }, []);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleDeleteRow
     *  @description  X 버튼 클릭시 호출 되는 함수. TM 도메인 한줄 삭제.
     */
    const handleDeleteRow = useCallback((e) => {
        const params = {
            num: 2,
            dataList: dataList,
            e: e
        };

        auiGrid.current.request(params);
    }, [dataList]);

    /**
     *  @memberOf     TMDomainList
     *  @function     gridResponse
     *  @param        {Array} list - 그리드 목록
     *  @description  그리드로 부터 응답 데이터가 왔을 때 처리 함수
     */
    const gridResponse = useCallback((list) => {
        if (list) {
            let dataList = [];

            list.forEach((row, idx) => {
                dataList.push({
                    ...row,
                    row: (idx + 1),
                    domain: "TM"
                });
            });
            setDataList(dataList);
        }
    }, []);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleGridChange
     *  @param        {String} dataField - 변경 된 컬럼의 dataField
     *  @param        {Number} rowIndex - 변경 된 줄의 Index
     *  @param        {String} value - 변경 된 값
     *  @description  Grid에서 값 변경시 호출되는 함수
     */
    const handleGridChange = useCallback((dataField, rowIndex, value) => {
        let _dataList = dataList;
        _dataList[rowIndex][dataField] = value;

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleChange
     *  @param        {Object} e - TextArea의 Event 정보
     *  @description  Description 변경시 호출되는 함수
     */
    const handleChange = useCallback((e) => {
        setDescriptionValue(e.target.value);
    }, []);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleCellClick
     *  @param        {Object} event - 선택한 줄 정보
     *  @description  Definition 칸 클릭시 호출되는 함수
     */
    const handleCellClick = useCallback((event) => {
        if (lock !== 2) {
            originDataList.forEach(data => {
                if (data.ID === event.item.ID) {
                    if (data.def.length === 1) {
                        for (let def of data.def) {
                            setSelectDefInfo(def);
                        }
                    }
                }
            });

            setRowID(event.item.ID);
            setVariableModal(!isVariableModal);
        }
    }, [isVariableModal, lock, originDataList]);

    /**
     *  @memberOf     TMDomainList
     *  @function     getVisitData
     *  @param        {String} ID - TM ID
     *  @param        {Object} defInfo - 변경 된 def 정보
     *  @description  Disease of Definition 입력창에서 작성후 입력된 데이터 처리하는 함수
     */
    const getDefData = useCallback((ID, defInfo) => {
        let _dataList = dataList; //변화된 def 정보를 저장
        let changeDataList = []; //변경된 def 정보를 넣어주기 위한 변수 선언

        _dataList.forEach(data => {
            if (data.ID === defInfo.TMID) { //def 데이터가 있었을때
                data.def.splice(0, 1); //무조건 한개 있으니깐 첫번째 index 자르고
                data.def.push(defInfo); //새로 받아온 def info 추가
            } else if (defInfo.TMID === undefined) { //def 데이터가 없었을 때
                //선택된 ID에 추가 하기 위해
                if (data.ID === ID) {
                    let defID = GenerateID(); //임의 ID 생성
                    let conditionList = [];
                    let _defInfo = defInfo; //defID 추가된 defInfo를 만듬

                    //def 안에 condition에 임의 ID 생성된거 적용 시켜줌
                    defInfo.condition.forEach(condition => {
                        conditionList.push({...condition, defID: defID});
                    });

                    _defInfo = {..._defInfo, condition: conditionList};

                    data.def.push({..._defInfo, ID: defID, TMID: data.ID});
                }
            }
        });

        //변경된 data list에서 다시 화면에 뿌려주기 위한 작업
        _dataList.forEach((row, idx) => {
            let definitionList = [];

            //Definition 값 만드는 곳
            if (row.def.length !== 0) {
                row.def.forEach(def => {
                    let conditionList = [];

                    def.condition.forEach(condition => {
                        conditionList.push(condition.type + " " + condition.variable + " " + condition.operator + " " + condition.value);
                    });

                    definitionList.push(def.domain + " " + def.variable + " where = (" + (conditionList.length !== 0 ? getListToString(conditionList) : "") + ")");
                });
            }

            changeDataList.push({
                ...row,
                ID: row.ID,
                row: (idx + 1),
                domain: "TM",
                definitionVal: definitionList
            });
        });

        setDataList(changeDataList);

        handleClose();
    }, [dataList, handleClose]);

    /**
     *  @memberOf     TMDomainList
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     TMDomainList
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수. 데이터 수정 Api 호출.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            const command = COMMAND.DATA_UPDATE;
            let params = {};
            //추후 수정 사항
            let _dataList = [...dataList];

            _dataList.forEach((data, idx) => {
                data.definition = JSON.stringify(data.definition);
                data.STUDYID = designID;
                data.order = idx + 1;
            });

            let sendObj = {
                data: _dataList,
                description: descriptionValue
            };

            // 데이터 생성에 필요한 parameter
            params = {
                requestUrl: SDTM_MAPPING_URL,
                ID: ID,
                domain: "TM",
                sendObject: JSON.stringify(sendObj)
            };

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, dataList, descriptionValue, designID, lock]);

    /**
     *  @memberOf     TMDomainList
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    let dataList = [];

                    data.data.forEach((row, idx) => {
                        let definitionList = [];

                        if (row.def.length !== 0) {
                            row.def.forEach(def => {
                                let conditionList = [];

                                def.condition.forEach(condition => {
                                    conditionList.push(condition.type + " " + condition.variable + " " + condition.operator + " " + condition.value);
                                });

                                definitionList.push(def.domain + " " + def.variable + " where = (" + (conditionList.length !== 0 ? getListToString(conditionList) : "") + ")");
                            });
                        }

                        dataList.push({
                            ...row,
                            row: (idx + 1),
                            domain: "TM",
                            definitionVal: definitionList,
                            definition: definitionList
                        });
                    })

                    setDataList(dataList);
                    setDescriptionValue(data.description);
                    setOriginDataList(data.data);

                    if (studyID === undefined && data.data.length > 0) {
                        setStudyID(data.data[0].STUDYID);
                    }
                    break;

                case COMMAND.DATA_UPDATE:
                    showToast(getDataList());
                    break;

                //no default
            }
        }
    }, [getDataList, showToast, studyID]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 화면에 표시될 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [getDataList]);

    /**
     *  @memberOf     TMDomainList
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "row",
            headerText: "Row",
            width: "5%",
            editable: false
        },
        {
            dataField: "STUDYID",
            headerText: "STUDYID",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: false
        },
        {
            dataField: "domain",
            headerText: "DOMAIN",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: false
        },
        {
            dataField: "MIDSTYPE",
            headerText: "MIDSTYPE",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "definitionVal",
            headerText: "Definition",
            width: "40%",
            editable: false
        },
        {
            dataField: "TMDEF",
            headerText: "TMDEF",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: lock !== 2
        },
        {
            dataField: "TMRPT",
            headerText: "TMRPT",
            headerStyle: "aui-grid-header-red",
            width: "10%",
            editable: lock !== 2,
            editRenderer: {
                type: "DropDownListRenderer",
                list: TMRPT_OPTION,
                keyField: "text",
                valueField: "value",
                showEditorBtnOver: true,
            }
        },
        {
            dataField: "",
            headerText: "",
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
                iconHeight: 16,
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    handleDeleteRow(event);
                }
            }
        },
        //추후 수정 사항
        {
            dataField: "def",
            width: "0%"
        },
        {
            dataField: "definition",
            width: "0%"
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box">
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div id="custom" className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <h2 style={{padding: '20px 0 0 30px'}}>SPEC Table</h2>
                                    <PerfectScrollbar className="height-full" style={{padding: "0 30px 0 30px"}}
                                                      options={{suppressScrollX: true}}>
                                        <div className="d-flex justify-content-between flex-column flex-sm-row">
                                            <div className="align-self-end">
                                                <DomainExplain/>
                                            </div>

                                            <div
                                                className="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                                <div className="d-flex justify-content-end m-b-10">
                                                    <button
                                                        className={cn("btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                                        onClick={handleAddRow}>
                                                        Add
                                                    </button>

                                                    <button
                                                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                                                        onClick={() => handleExamplePopup("TM")}>
                                                        Example
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <AUIGrid
                                            ref={auiGrid}
                                            columnList={COLUMN_LIST}
                                            dataList={dataList}
                                            onEdit={handleGridChange}
                                            response={gridResponse}
                                            onCellClick={handleCellClick}
                                            minusContentHeight={236}/>

                                        <div className="m-t-20">
                                            Description for SDRG

                                            <textarea
                                                rows={5}
                                                className="form-control m-t-10"
                                                onChange={handleChange}
                                                value={descriptionValue}
                                                style={{resize: "none", height: "80px"}}
                                                disabled={lock === 2}/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between p-10 bg-light border-top">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <button
                                className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isVariableModal && (
                <SelectElementDateModal
                    title={"Disease of Definition"}
                    history={history}
                    onOk={getDefData}
                    onClose={handleClose}
                    data={selectDefInfo}
                    type={"DEF"}
                    rowID={rowID}/>
            )}
        </>
    );
};

export default React.memo(TMDomainList);
