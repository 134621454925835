import React from 'react';
import {Route} from 'react-router-dom';
import {PageSettings} from '../../routes/page-settings.js';
import SidebarNavList from './sidebar-nav-list.jsx';
import menus from './menu.jsx';
import {SESSION_INFO} from "../../constant/CommonConstant";
import {getSessionUserData} from "../../common/commonFunction";

class SidebarNav extends React.Component {
    static contextType = PageSettings;

    constructor(props) {

        super(props);
        this.state = {
            active: -1,
            clicked: -1,
            menus: menus
        };

    }

    handleExpand(e, i, match) {
        e.preventDefault();

        if (this.state.clicked === -1 && match) {
            this.setState(state => ({
                active: -1,
                clicked: 1
            }));
        } else {
            this.setState(state => ({
                active: (this.state.active === i ? -1 : i),
                clicked: 1
            }));
        }
    }
    render() {
        return (
            <ul className="nav">
                {this.context.pageSidebarSearch && (
                    <li className="nav-search">
                        <input type="text" className="form-control" placeholder="Sidebar menu filter..."
                               onKeyUp={this.handleSidebarSearch}/>
                    </li>
                )}
                {this.state.menus.map((menu, i) => {
                    const sessionData = getSessionUserData();
                    if (sessionData.role === 'Master') {
                        return (menu.role && menu.role === sessionData[SESSION_INFO.ROLE]) &&
                            (
                                <Route path={menu.path} exact={menu.exact} key={i} children={({match}) => (
                                    <SidebarNavList
                                        data={menu}
                                        key={i}
                                        expand={(e) => this.handleExpand(e, i, match)}
                                        active={i === this.state.active}
                                        clicked={this.state.clicked}
                                    />
                                )}/>
                            )
                    } else {
                        return !(menu.role && menu.role !== sessionData[SESSION_INFO.ROLE]) &&
                            (
                                <Route path={menu.path} exact={menu.exact} key={i} children={({match}) => (
                                    <SidebarNavList
                                        data={menu}
                                        key={i}
                                        expand={(e) => this.handleExpand(e, i, match)}
                                        active={i === this.state.active}
                                        clicked={this.state.clicked}
                                    />
                                )}/>
                            )
                    }
                })
                }
            </ul>
        );
    }
}

export default SidebarNav;