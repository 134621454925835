/**
 *  @constant
 *  @type {boolean}
 *  @description  debug console 출력 여부
 *  Todo : "빌드" 시 false 로 설정하고 빌드 , "개발" 시 true로 설정
 */
export const DEBUG_CONSOLE = window.location.hostname === "app.imtrial.com";
//호스트가 app.imtrial.com 일때 console 안찍히도록 처리
if (DEBUG_CONSOLE) {
    console.log = function () {
    };
    console.warn = function () {
    };
    console.error = function () {
    };
}

// 페이지 COUNT 정보
export const PAGE = {
    COUNT_PER_PAGE: 10,
    COUNT_PER_BLOCK: 5,
};

// Login 정보
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const REFRESH_SESSION = 'REFRESH_SESSION';

/*
  PRM MENU CODE
  MY_PRM          : MY PRM 메뉴
  MY_MODEL        : MY MODEL 메뉴
  MODEL_LIBRARY   : 모델 library 메뉴
 */
export const PRM_MENU_CODE = {
    MY_PRM: 'MY_PRM',
    MY_MODEL: 'MY_MODEL',
    MODEL_LIBRARY: 'MODEL_LIBRARY',
};

/*
  화면 사용 모드
  NEW     : 신규 생성 모드
  EDIT    : 수정 모드
  VIEW    : 보기 모드(수정 불가)
  CDASH   : CDASH 모드
  CUSTOM  : CUSTOM 모드
 */
export const VIEW_USE_MODE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
    CDASH: 'CDASH',
    CUSTOM: 'CUSTOM'
};

// POPUP view 코드
/*
  view    : 일반 보기
  compare : 비교 보기
  lock    : lock 을 위한 보기
  new     : new 을 위한 보기
  edit    : edit 을 위한 보기
  value   : value 을 위한 보기
 */
export const POPUP_VIEW_CODE = {
    NONE: 'NONE',
    VIEW: 'VIEW',
    COMPARE: 'COMPARE',
    LOCK: 'LOCK',
    NEW: 'NEW',
    EDIT: 'EDIT',
    VALUE: 'VALUE',
    DM: 'DM',
    DETAIL: 'DETAIL',
    LIST: 'LIST',
};

// selectBox 컴포넌트에 Default 값 지정
export const SELECT_BOX_DEFAULT = {
    VALUE: '',
    CAPTION: '선택',
};

// 상세 검색에 쓰일 검색 타입 - 5 type
/*
  text select  : SearchTextSelect 컴포넌트
  select box   : SearchSelectBox 컴포넌트
  text field   : SearchTextControl 컴포넌트
  add list     : SearchTextListPlus 컴포넌트
  date picker  : DatePicker 컴포넌트
  TEXT_NUMBER  : input number 컴포넌트
* */
export const SEARCH_TYPE = {
    TEXT_SELECT: 'TEXT_SELECT',
    TEXT_FIELD: 'TEXT_FIELD',
    SELECT_BOX: 'SELECT_BOX',
    LIST_ADD: 'LIST_ADD',
    DATE_PICKER: 'DATE_PICKER',
    TEXT_NUMBER: 'TEXT_NUMBER'
};


// 서버 응답 에러 코드
// 서버 에러 코드
export const ERROR_CODE = {
    NO_ERROR: 0,
    AUTH_ERROR: -100,
    NO_USER: -101,
    NO_TOKEN: -103,
    TOKEN_EXPIRED: -104,
    VERIFY_FAIL: -105,
    NO_MORE_VALID: -108,
    EMAIL_AUTH_FAIL: -13,
    SERVER_ERROR: -999,
    OVERLAP_SESSION: -110,
    LOCK_AUTH_FAIL: -12,
    ANOTHER_ACCOUNT: -111,
    SDTM_PACKAGE_PROCESSING: -200,
    TABULATION_CLINICAL_DATAlIST: -141
};

export const LOGIN_URL = '/account/login';

export const COMPLETE_MSG = 'Processing complete';
export const ALERT_TIME = 1000;

// LOCK 상태
export const LOCK_STATE = {
    LOCK: 2,
    UNLOCK: 1,
};

// STATE 상태
export const STATE_OPTION = [
    {
        text: 'Open',
        value: 1,
    },
    {
        text: 'Close',
        value: 0
    }
];

// SESSION STATE
export const SESSION_STATE = {
    ON: 0,
    OFF: 1,
    KICK_OFF: -1
};

// SESSION STATE
export const SESSION_STATE_TABLE = {
    '0': 'ON',
    '1': 'OFF',
    '-1': 'KICK OUT',
};

// 유저의 SESSION 정보
export const SESSION_INFO = {
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    NAME: 'name',
    TIMER_EXPIRE_TIME: 'timerData',
    PASSWORD_EXPIRE: 'passwordExpire',
    TRAINING_COMPLATE: 'trainingComplete',
    TIME_ZONE: 'timeZone',
    ROLE: 'role',
    LANGUAGE: 'language',
    FIRST_LOGIN: 'firstLogIn',
    AGREEMENTMUST: 'agreementMust'
};

// ROLE 정보
export const ROLE = {
    MASTER: 'Master',
    ADMIN: 'Admin',
    MEDICAL: 'Medical',
    DM: 'DM',
    STAT: 'STAT',
};

// version type
export const VERSION_TYPE = {
    MAJOR: 'MAJOR',
    MINOR: 'MINOR',
};


export const COLUMN_TYPE = {
    TEXT: 'text',
    SELECT: 'select',
    SEARCH: 'search',
    CHECK: 'check',
}