import React from 'react';

//SDTM > Mapping > Detail에 Grid 위에 설명
const DomainExplain = (props) => {
    return (
        <>
            ※ The column name color is Core value(
            <font style={{color: "#FFDDDD", fontSize: "30px"}}>■</font> = Req,
            <font style={{color: "#DDFFFF", fontSize: "30px"}}>■</font> = Exp,
            <font style={{color: "#EEFFDD", fontSize: "30px"}}>■</font> = Perm)
        </>
    );
};

export default React.memo(DomainExplain);
