import React, {useCallback, useEffect, useState} from "react";
import ACRFItemGroup from "./ACRFItemGroup";
import ACRFItem from "./ACRFItem";
import compareCover from "../../../design/amendmentTable/CompareCover";
import {copyObject} from "../../../../../common/commonFunction";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFStudyEvent
 *  @constant     {Object} LANDSCAPE_TABLE_TYPE
 *  @description  HEADER_FIRST_ROW, HEADER_SECOND_ROW, BODY_FIRST_ROW, BODY_SECOND_ROW 정의
 */
const LANDSCAPE_TABLE_TYPE = {
    HEADER_FIRST_ROW: "headerFirstRow",
    HEADER_SECOND_ROW: "headerSecondRow",
    HEADER_THIRD_ROW: "headerThirdRow",
    HEADER_FOURTH_ROW: "headerFourthRow",

    BODY_FIRST_ROW: "bodyFirstRow",
    BODY_SECOND_ROW: "bodySecondRow",
    BODY_THIRD_ROW: "bodyThirdRow",
    BODY_FOURTH_ROW: "bodyFourthRow",
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFStudyEvent
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Study Event 컴포넌트
 */
const ACRFStudyEvent = (props) => {
    /**
     *  @memberOf     ACRFStudyEvent
     *  @type         {Object} props
     *  @property     {Object} formData - formData 정보
     *  @property     {Array} itemGroupVisit - itemGroupVisit 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        formData, EDCType
    } = props;

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {boolean} isDDLContentExist
     *  @description  form 하단 DDL 주석들이 존재하는지 여부를 알기 위함
     */
    const [isDDLContentExist, setIsDDLContentExist] = useState(false);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} commentList
     *  @description  form 하단 comment를 표시하기 위한 데이터
     */
    const [commentList, setCommentList] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labTableData
     *  @description  labForm 테이블 데이터
     */
    const [labTableData, setLabTableData] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labPortraitData
     *  @description  labform 안에서 portrait로 표시될 데이터
     */
    const [labPortraitData, setLabPortraitData] = useState([]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @var          {Array} labFormDDLComment
     *  @description  labform ddl comment 데이터
     */
    const [labFormDDLComment, setLabFormDDLComment] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    // landscape 조건에 해당하는 itemGroup 의 배열: itemGroup의 logInfo의 logDirection 이 "Landscape" 이며, itemGroup의 logInfo의 repeating 이 "Yes" 이거나 undefined 인 itemGroup
    let repeatingYesList = formData && formData.itemGroups.filter(itemGroup => itemGroup.logInfo !== undefined && itemGroup.logInfo.logDirection === "Landscape" && (itemGroup.logInfo.repeating === "Yes" || itemGroup.logInfo.repeating === undefined));

    /**
     *  @memberOf     ACRFStudyEvent
     *  @function     getFirstRepeatingYesIG
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 에 "firstYes" 라는 key 넣는 함수
     *  @description  해당 firstYes 키는 getLandscapeTable함수에서 landscape table을 한 번만 생성하기 위해 사용
     */
    const getFirstRepeatingYesIG = useCallback(() => {
        let itemGroups = formData.itemGroups;

        if (formData.logDirection === "Landscape") {
            let find = itemGroups.find(x => x.logInfo?.repeating === "Yes");
            if (find !== undefined) {
                find.firstYes = true;
            }
        }
    }, [formData.itemGroups, formData.logDirection]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @function     getLandscapeTableHeaderBody
     *  @param        {String} type - landscape 표의 type(headerFirstRow, headerSecondRow, bodyFirstRow, bodySecondRow)
     *  @param        {Number} columnIdx - item의 componentType 이 "DropDownList"이고, question이 "TimePoint" 인 codelist의 배열의 인덱스
     *  @return       {Element} Landscape 표에 해당하는 header, body 항목
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 의 header, body 데이터 뿌리는 함수
     */
    const getLandscapeTableHeaderBody = useCallback((type, columnIdx) => {
        /* ---------------------------------------------- landscape header ---------------------------------------------- */
        // landscape 표 헤더 첫 번째 row
        if (type === LANDSCAPE_TABLE_TYPE.HEADER_FIRST_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(0, 5);

            return sliced.map((item, idx) => (
                item.componentType === "DropDownList" ? // DDL 일 때 question에 * 붙임
                    <td
                        key={idx}
                        style={{
                            border: "1px solid #000000",
                            padding: "5px",
                            justifyContent: "center",
                            background: "#EAEAEC"
                        }}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </div>
                    </td> :
                    <td
                        key={idx}
                        style={{
                            border: "1px solid #000000",
                            padding: "5px",
                            justifyContent: "center",
                            background: "#EAEAEC"
                        }}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </div>
                    </td>
            ));

            // landscape 표 헤더 두 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.HEADER_SECOND_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(5, 10);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                ));
            } else {
                return sliced.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                ));
            }

            // landscape 표 헤더 세 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.HEADER_THIRD_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(10, 15);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                ));
            } else {
                return sliced.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                ));
            }
        }

        // landscape 표 헤더 네 번째 row
        else if (type === LANDSCAPE_TABLE_TYPE.HEADER_FOURTH_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(15, 20);
            let blankNum = 5 - sliced.length;
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <span
                                    style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                            </div>
                        </td>
                ));
            } else {
                return sliced.map((item, idx) => (
                    item.componentType === "DropDownList" ?
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            {/*{item.question?.replaceAll("/", "/ \n")} * 추후 되돌릴 예정*/}
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                        :
                        <td
                            key={idx}
                            style={{
                                border: "1px solid #000000",
                                padding: "5px",
                                justifyContent: "center",
                                background: "#EAEAEC"
                            }}>
                            <span
                                style={{backgroundColor: item.isQuestionDiff ? formData.compareColor : ""}}>{item.question?.replaceAll("/", "/ \n")}</span>
                        </td>
                ));
            }

            /* ---------------------------------------------- landscape body ---------------------------------------------- */
            // landscape 표 바디 첫 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(0, 5);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            })

            return _sliced.map((item, idx) => (
                <td style={{border: "1px solid #000000", padding: "3px"}}>
                    <ACRFItem
                        key={idx}
                        itemData={item}
                        columnIdx={columnIdx}
                        compareColor={formData.compareColor}
                    />
                </td>
            ));

            // landscape 표 바디 두 번째 row
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(5, 10);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px 3px"}}>
                        <ACRFItem
                            key={idx}
                            itemData={item}
                            columnIdx={columnIdx}
                            compareColor={formData.compareColor}
                        />
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우, border선 만들어주기 위해 추가
                    <td style={{}}>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(10, 15);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px 3px"}}>
                        <ACRFItem
                            key={idx}
                            itemData={item}
                            columnIdx={columnIdx}
                            compareColor={formData.compareColor}
                        />
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우, border선 만들어주기 위해 추가
                    <td>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }
        } else if (type === LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW) {
            let itemList = [];
            repeatingYesList.map(itemGroup => (
                itemGroup.items.map(item => itemList.push(item))
            ));
            let sliced = itemList.slice(15, 20);
            let _sliced = [...sliced];

            _sliced.forEach((item, idx) => {
                if (item.element.includes("TPT")) {
                    item.columnIdx = columnIdx;
                }
            });

            let blankNum = 5 - sliced.length
            if (blankNum < 5) { // 두번째 줄에 해당하는 item 의 갯수가 5개 미만일 때
                let nonArr = Array(blankNum).fill({logInfo: {repeating: "Yes", logDirection: "Landscape"}});
                let newArr = _sliced.concat(nonArr);

                return newArr.map((item, idx) => (
                    <td style={{border: "1px solid #000000", padding: "10px 3px"}}>
                        <ACRFItem
                            key={idx}
                            itemData={item}
                            columnIdx={columnIdx}
                            compareColor={formData.compareColor}/>
                    </td>
                ));
            } else {
                return _sliced.map((item, idx) => ( // 두번째 row 에 빈칸이 있는 경우, border선 만들어주기 위해 추가
                    <td style={{}}>
                        <div style={{borderBottom: "1px solid #000000"}}></div>
                    </td>
                ));
            }
        }
    }, [repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @function     getLandscapeTableBody
     *  @return       {Element} codelist의 갯수만큼 생성된 landscape표의 body의 행
     *  @description  Landscape 표에 해당하는 데이터의 body 부분을 codelist의 갯수만큼 행 추가하는 함수
     *  @description  question이 Time Point이고, item의 componentType인 item이 있는 경우, codelist의 갯수만큼 행이 추가됨
     *  @description  TPT itemData 찾기 : codelist의 codeListID 가 TPT/ itemData의 question이 Time Point
     */
    const getLandscapeTableBody = useCallback(() => {
        let codeListsArr = []; // item의 component type 이 "DropDownList"이고, element가 "TPT" 포함하거나 "SPID" 포함하는 codelist의 배열

        repeatingYesList.map((itemGroup) => (
            itemGroup.items.forEach(item => {
                const _condition =
                    (item.element.includes("TPT") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("SPID") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("LOC") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("TESTCD") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("SCAT") && item.componentType === "DropDownList" && item.defaultValue.includes("|"));

                if (_condition) {
                    item.codelists && item.codelists.forEach((codelist) => {
                        codeListsArr.push(codelist);
                    });
                }
            })
        ));

        const generateRowsInLandscapeTableBody =
            codeListsArr.map((codeList, idx) => (
                <>
                    <tr>
                        {repeatingYesList.length > 0 ? (
                            <td rowSpan={4} style={{border: "1px solid #000000", background: "#EAEAEC"}}>
                                <div style={{display: "flex", justifyContent: "center"}}>{idx + 1}</div>
                            </td>
                        ) : (
                            <></>
                        )}
                        {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW, idx)}
                    </tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW, idx)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW, idx)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW, idx)}</tr>
                </>
            ));

        return generateRowsInLandscapeTableBody;
    }, [getLandscapeTableHeaderBody, repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @function     getLandscapeTable
     *  @param        {Object} data - item group 객체
     *  @return       {Element} landscape 표
     *  @description  landscape 표 생성하는 함수
     *  @description  Item group의 logDirection이 landscape이고 repeating yes인 최초의 item group 에서만 1회 호출 (landscape 표는 form 당 최대 한 개)
     *  @description  item의 element 가 "TPT" 를 포함하거나 "SPID"를 포함하고, item의 component type이 "DDL"인 item이 있는 경우: landscape 표에 행 추가됨
     */
    const getLandscapeTable = useCallback((data) => {
        return data.firstYes === true ?
            <>
                {/* item group의 description */}
                {formData?.itemGroupDesNum && formData?.itemGroupDesNum > 0 ?
                    <div>
                        {repeatingYesList.length > 0 &&
                            repeatingYesList?.map(itemGroup =>
                                itemGroup?.description !== null && (
                                    <div
                                        style={itemGroup.description ? {
                                            padding: "5px",
                                            border: "1px solid #000000",
                                            background: "#F2F2F2",
                                            marginTop: "-1px",
                                            fontWeight: "bold",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif",
                                        } : {
                                            padding: "0px",
                                            border: "0px"
                                        }}>
                                        {itemGroup.description && itemGroup.description?.split('<br/>').map((description) => (
                                            <>
                                                <span style={{
                                                    backgroundColor: itemGroup.isDescriptionDiff ? formData.compareColor : ""
                                                }}>{description}</span>
                                                <br/>
                                            </>
                                        ))}
                                    </div>))}
                    </div> : <></>}
                {/* table 영역 */}
                <table
                    style={{
                        tableLayout: "fixed",
                        wordBreak: "keep-all",
                        borderCollapse: "collapse",
                        fontSize: "9pt",
                        width: "100%",
                        marginTop: "-1px",
                        border: "1px solid #000000",
                    }}>

                    {/* item의 question */}
                    <thead style={{padding: "5px", border: "1px solid #000000"}}>
                    <tr>
                        {repeatingYesList.length > 0 ? (
                            <td rowSpan={4} style={{border: "1px solid #000000", width: "7%", background: "#EAEAEC"}}>
                                <div style={{display: "flex", justifyContent: "center"}}>No</div>
                            </td>
                        ) : (
                            <></>
                        )}
                        {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_FIRST_ROW)}
                    </tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_SECOND_ROW)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_THIRD_ROW)}</tr>
                    <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.HEADER_FOURTH_ROW)}</tr>
                    </thead>

                    {/* item의 value */}
                    <tbody>
                    {/* item의 item의 component type이 "DDL"인 item이 있는 경우: landscape 표에 (codelist 갯수만큼)행 추가하는 함수 호출*/}
                    {(data.itemTPDDLNum > 0 || data.itemSPIDDDLNum > 0 || data.itemLOCDDLNum > 0 || data.itemTESTCDDDLNum > 0 || data.itemSCATDDLNum > 0) ?
                        getLandscapeTableBody() :

                        // 없는 경우: 일반 landscape body 형태로 표 생성
                        <>
                            <tr>
                                {repeatingYesList.length > 0 ? (
                                    <td rowSpan={4} style={{
                                        border: "1px solid #000000",
                                        paddingLeft: "5px",
                                        background: "#EAEAEC"
                                    }}>
                                        <div
                                            style={{display: "flex", justifyContent: "center", justifySelf: "center"}}>1
                                        </div>
                                        <div
                                            style={{display: "flex", justifyContent: "center", cursor: "pointer"}}>Add
                                        </div>
                                        <div
                                            style={{display: "flex", justifyContent: "center", cursor: "pointer"}}>row
                                        </div>
                                    </td>
                                ) : (
                                    <></>
                                )}
                                {getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FIRST_ROW)}
                            </tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_SECOND_ROW)}</tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_THIRD_ROW)}</tr>
                            <tr>{getLandscapeTableHeaderBody(LANDSCAPE_TABLE_TYPE.BODY_FOURTH_ROW)}</tr>
                        </>
                    }
                    </tbody>
                </table>
            </>
            : <></>
    }, [formData.itemGroupDesNum, getLandscapeTableBody, getLandscapeTableHeaderBody, repeatingYesList]);

    /**
     *  @memberOf     ACRFStudyEvent
     *  @function     getItemGroupComp
     *  @return       {Element} ItemGroup Data render
     *  @description  Item group의 LogDirection, repeating 여부에 따라 Item group 구성 그려주는 함수
     *  @description  뉴로 바이오젠 과제에 해당하는 ID의 디자인들은 (DEGN000000000093/ DEGN000000000100/ DEGN000000000112/) Landscape 표 형식을 적용하지 않음
     */
    const getItemGroupComp = useCallback(() => {
        /*
        - logDirection 정보는 form에, repeating 정보는 form이나 각 item group에 저장되어 있음
        - Form 의 logDirection의 종류: 'landscape', 'portrait', ''
        - item group의 repeating의 종류: 'Yes', 'No', undefined(COMMON일 때)

        -> landscape에 해당되는 조건: logDirection이 "landscape"이고 repeating이 "Yes" 이거나 "undefined"(COMMON)일 때
         */

        // 뉴로 바이오젠 ID 에 해당하는 디자인들은 현재 진행 중인 과제이므로 landscape 표를 적용하면 안 됨
        if (formData.designID === "DEGN000000000093" || formData.designID === "DEGN000000000100" || formData.designID === "DEGN000000000112") {
            return formData.itemGroups.map((data, idx) => {
                return (
                    <>
                        <div>
                            <div
                                key={idx}>
                                <ACRFItemGroup
                                    key={idx}
                                    itemGroupData={data}
                                />
                            </div>

                        </div>
                    </>
                );
            });
        } else { // 뉴로 바이오젠 외의 모든 디자인들
            // labform 화면 출력부
            if (formData.formType === 'LABORATORY') {
                return (
                    <>
                        {labPortraitData.map((itemgroup, idx) => {
                            return (
                                <>
                                    <div>
                                        <div
                                            key={idx}>
                                            <ACRFItemGroup
                                                key={idx}
                                                itemGroupData={itemgroup}
                                                compareColor={formData.compareColor}
                                                formType={formData.formType}
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                        {formData.labDiff === undefined ? (
                            <>
                                <table
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        border: "1px solid #000000",
                                        borderCollapse: "collapse",
                                        fontSize: "9pt",
                                        tableLayout: "fixed",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                    <thead>
                                    <tr style={{backgroundColor: "#eaeaec"}}>
                                        {labTableData.header?.map((data) => (
                                            <td style={{
                                                width:
                                                    data === 'Test item' ? "170px" :
                                                        data === 'Result' ? "100px" :
                                                            data === 'Normality' ? "70px" :
                                                                data === 'Clinical Significance' ? "70px" :
                                                                    data === 'Comment' ? "70px" : "",
                                                textAlign: "center",
                                                border: "1px solid #000000",
                                                fontWeight: data === 'Clinical Significance' || data === "Normality" ? "600" : "",
                                            }}>{data}</td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {labTableData.body?.map((data) => (
                                        <tr>
                                            {data.map((each, idx) => (
                                                <td style={{
                                                    background: each === null && '#d3d3d3',
                                                    textAlign: idx === 0 ? "left" : "center",
                                                    padding: "5px 0px",
                                                    paddingLeft: idx === 0 ? "10px" : "",
                                                    border: "1px solid #000000",
                                                }}>{each}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                {labFormDDLComment.length === 0 ?
                                    <></> :
                                    <div style={{fontSize: "8pt", fontFamily: "Arial, sans-serif", padding: "5px",}}>
                                        <span style={{fontWeight: "600"}}>Clinical Significance</span>=DDL
                                        [
                                        {labFormDDLComment?.map((comment, idx, arr) => (
                                            <span>{comment}{arr.length - 1 === idx ? "" : ", "}</span>
                                        ))}
                                        ]
                                    </div>
                                }

                            </>
                        ) : (
                            <>
                                <table
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        border: "1px solid #000000",
                                        borderCollapse: "collapse",
                                        fontSize: "9pt",
                                        tableLayout: "fixed",
                                        fontFamily: "Arial, sans-serif"
                                    }}>
                                    <thead>
                                    <tr style={{backgroundColor: "#eaeaec"}}>
                                        {formData.labDiff.headerDiff?.map((data) => (
                                            <td style={{
                                                width:
                                                    data === 'Test item' ? "170px" :
                                                        data === 'Result' ? "100px" :
                                                            data === 'Normality' ? "70px" :
                                                                data === 'Clinical Significance' ? "70px" :
                                                                    data === 'Comment' ? "70px" : "",
                                                textAlign: "center",
                                                border: "1px solid #000000",
                                                fontWeight: data.value === 'Clinical Significance' || data.value === "Normality" ? "600" : "",
                                            }}><span
                                                style={{backgroundColor: data.highlight ? formData.compareColor : ""}}>{data.value}</span>
                                            </td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {formData.labDiff.bodyDiff.map((data) => (
                                        <tr>
                                            {data?.map((each, idx) => (
                                                <td style={{
                                                    backgroundColor: each.value === null ? (each.highlight ? formData.compareColor : '#d3d3d3') : "",
                                                    textAlign: idx === 0 ? "left" : "center",
                                                    padding: "5px 0px",
                                                    paddingLeft: idx === 0 ? "10px" : "",
                                                    border: "1px solid #000000",

                                                }}>
                                                <span style={{
                                                    backgroundColor: each.highlight ? formData.compareColor : "",
                                                }}>
                                                    {each.value}
                                                </span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {formData.labDiff.DDLDiff.length === 0 ?
                                    <></> :
                                    <div style={{fontSize: "8pt", fontFamily: "Arial, sans-serif", padding: "5px",}}>
                                        <span style={{fontWeight: "600"}}>Clinical Significance</span>=DDL
                                        [
                                        {formData.labDiff.DDLDiff?.map((comment, idx, arr) => (
                                            <span style={{
                                                backgroundColor: comment.highlight ? formData.compareColor : ""
                                            }}>{comment.value}{arr.length - 1 === idx ? "" : ", "}</span>
                                        ))}
                                        ]
                                    </div>
                                }
                            </>
                        )
                        }
                    </>
                )
            }

            return formData.itemGroups.map((data, idx) => {
                if (formData?.logDirection !== "Landscape" || (data.logInfo?.logDirection === "Landscape" && data.logInfo?.repeating === "No")) {
                    // [일반 폼 형태] itemgroup의 logDirection이 Landscape 이 아닐 때 또는 Landscape 이고 repeating No일 때 원래 form 의 형태로 출력
                    return (
                        <>
                            <div>
                                <div
                                    key={idx}>
                                    <ACRFItemGroup
                                        key={idx}
                                        itemGroupData={data}
                                        compareColor={formData.compareColor}
                                        formType={formData.formType}
                                    />
                                </div>
                            </div>
                        </>
                    );
                } else if (data.logInfo?.logDirection === "Landscape" && (data?.logInfo?.repeating === "Yes" || data?.logInfo?.repeating === undefined)) {
                    // [Landscape 표 형태] itemgroup의 logDirection이 Landscape 이고 repeating이 Yes 이거나 undefined (COMMON) 일 때 landscape 표 생성하는 함수 호출
                    return getLandscapeTable(data);
                }
            });
        }
    }, [formData.designID, formData.itemGroups, formData.logDirection, getLandscapeTable, labTableData, labPortraitData, labFormDDLComment]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    useEffect(() => {
        getFirstRepeatingYesIG();
    }, [formData, getFirstRepeatingYesIG]);

    useEffect(() => {
        formData.itemGroups.forEach((itemgroup) => {
            itemgroup.items.forEach((item) => {
                const _condition =
                    (item.element.includes("TPT") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("SPID") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("LOC") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("TESTCD") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                    (item.element.includes("SCAT") && item.componentType === "DropDownList" && item.defaultValue.includes("|"));

                if (!_condition && item.componentType === 'DropDownList' || item.componentType === 'SearchList') {
                    if (item.codelists?.length > 0) {
                        setIsDDLContentExist(true);
                    }
                }
            })
        })
    }, [formData])

    // landscape일 경우에만 하단에 comment 처리
    useEffect(() => {
        if (repeatingYesList.length > 0) {
            const _commentList = [];
            repeatingYesList.forEach((data) => {
                data.items.forEach((itemData) => {
                    _commentList.push(itemData.comment)
                })
            })
            setCommentList(_commentList);
        }
    }, [formData])

    // labform 데이터 가공 부분
    useEffect(() => {
        const _data = copyObject(formData);
        const _labItemgroupIDs = [];

        _data.itemGroups.forEach((itemgroup) => {
            if (_data.formType === 'LABORATORY' && itemgroup.laboratory === 1) {
                _labItemgroupIDs.push(itemgroup.ID);
            }
        })

        const _labTableData = {
            header: [],
            body: []
        }

        // labform 헤더 추가
        _data.itemGroups.forEach((itemgroup) => {
            if (_labItemgroupIDs.includes(itemgroup.ID)) {
                itemgroup.items.forEach((item) => {
                    if (EDCType === 'boim' && item.element === "LBTEST") {
                        _labTableData.header.push("Test item")
                    } else if (EDCType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        _labTableData.header.push("Result")
                    } else if (EDCType !== 'boim' && item.element === "LBORRES") {
                        _labTableData.header.push("Test item")
                        _labTableData.header.push("Result")
                    } else if (item.element === 'LBNRIND') {
                        _labTableData.header.push("Normality")
                    } else if (item.element === 'LBCLSIG') {
                        _labTableData.header.push("Clinical Significance")
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        _labTableData.header.push("Comment")
                    }
                })
            }
        })

        _labTableData.header = [...new Set(_labTableData.header)];

        const DDLComment = [];

        // labform body 추가
        _data.itemGroups.forEach((itemgroup) => {
            if (_labItemgroupIDs.includes(itemgroup.ID)) {
                const temp = [];
                itemgroup.items.forEach((item) => {
                    let _content = '';
                    if (item.dataType === "Datetime" && item.format !== "") {
                        _content = item.format;
                    } else if (item.dataType === "Float") {
                        let num = '_ '.repeat(Math.floor(item.format) - item.format.slice(item.format.indexOf('.') + 1));
                        let primeNum = '_ '.repeat(item.format.slice(item.format.indexOf('.') + 1));
                        _content = num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
                    } else if (item.dataType === "Integer") {
                        _content = "_ ".repeat(item.format);
                    } else if (item.dataType === "Text") {
                        _content = "__________";
                    }

                    if (EDCType === 'boim' && item.element === "LBTEST") {
                        temp[_labTableData.header.indexOf("Test item")] = item.defaultValue;
                    } else if (EDCType === 'boim' && item.element === "LBORRES" && item.question.toLowerCase() === 'result') {
                        temp[_labTableData.header.indexOf("Result")] = _content
                    } else if (EDCType !== 'boim' && item.element === "LBORRES") {
                        temp[_labTableData.header.indexOf("Test item")] = item.question;
                        temp[_labTableData.header.indexOf("Result")] = _content;
                    } else if (item.element === 'LBNRIND') {
                        temp[_labTableData.header.indexOf("Normality")] = _content;
                    } else if (item.element === 'LBCLSIG') {
                        temp[_labTableData.header.indexOf("Clinical Significance")] = "DDL";
                        item.codelists.forEach((codelist) => {
                            DDLComment.push(codelist.label);
                        })
                    }
                    // comment 추가
                    if (item.element === 'COVAL' && item.enabled === 1 && item.type === "Collected" && item.source === 'CRF') {
                        temp[_labTableData.header.indexOf("Comment")] = "__________" // comment는 항상 text로 고정
                    }
                })
                _labTableData.body.push(temp);
            }
        })


        // 빈값이 들어가면 table이 깨지기 때문에 다른값 대입해주기
        _labTableData.body.forEach((row, index) => {
            _labTableData.body[index] = Array.from(row, item => item === undefined ? null : item);
        })

        // 상단 portrait 데이터 넣어주기
        const _labPortraitData = [];
        _data.itemGroups.forEach((itemgroup) => {
            if (_data.formType === 'LABORATORY' && itemgroup.laboratory === 0) {
                _labPortraitData.push(itemgroup);
            }
        })

        setLabTableData(_labTableData)
        setLabPortraitData(_labPortraitData)
        setLabFormDDLComment([...new Set(DDLComment)])

    }, [formData, EDCType])

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {/*Study Event Name 영역*/}
            {/*Item Group 출력*/}
            {getItemGroupComp()}

            {/*DDL 주석들 출력*/}
            {isDDLContentExist &&
                <div style={{
                    border: "1px solid #000",
                    marginTop: "-1px",
                    fontSize: "9pt",
                    padding: "5px 10px",
                    fontFamily: "Arial, sans-serif"
                }}>
                    {formData.itemGroups.map((itemgroup) => (
                        itemgroup.items.map((item) => {
                            const _condition =
                                (item.element.includes("TPT") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                                (item.element.includes("SPID") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                                (item.element.includes("LOC") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                                (item.element.includes("TESTCD") && item.componentType === "DropDownList" && item.defaultValue.includes("|")) ||
                                (item.element.includes("SCAT") && item.componentType === "DropDownList" && item.defaultValue.includes("|"));
                            if (!_condition) {
                                return (<div>
                                    {item.componentType === 'DropDownList' || item.componentType === 'SearchList' ?
                                        <div>
                                        <span style={{
                                            fontWeight: "600",
                                            backgroundColor: item.isQuestionDiff ? formData.compareColor : ""
                                        }}>{item.question}</span>
                                            =DDL [{item.codelists?.map((codelist, idx) => (
                                            <span
                                                style={{backgroundColor: codelist.isCodelistDiff ? formData.compareColor : ""}}>
                                                {codelist.label}{idx === item.codelists.length - 1 ? "" : ", "}
                                            </span>))}]
                                        </div> :
                                        <></>
                                    }
                                </div>);
                            }
                        })
                    ))}
                </div>
            }

            {
                <div style={{
                    fontSize: "9pt",
                    padding: "5px 10px",
                    fontFamily: "Arial, sans-serif",
                }}>
                    {commentList.map((comments) => (
                        comments.split('<br/>').map((comment) => (
                            <div>{comment}</div>
                        ))
                    ))}
                </div>
            }
        </>
    );
};

export default React.memo(ACRFStudyEvent);
