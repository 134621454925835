import React from "react";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFCodeList
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 CodeList 컴포넌트
 */
const ACRFCodeList = (props) => {
    /**
     *  @memberOf     ACRFCodeList
     *  @type         {Object} props
     *  @property     {Object} codeListData - CodeList 정보
     *  @property     {String} type - radio인지 checkbox인지 구분 값
     *  @property     {Boolean} isVertical - Vertical 표현 구분 값
     *  @property     {String} itemData - item 정보
     *  @property     {String} compareColor - 비교 색상
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        codeListData,
        type,
        isVertical = false,
        itemData,
        compareColor
    } = props;

    // console.log(codeListData)

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div
            style={isVertical ?
                {marginRight: "10px", display: "flex", flexDirection: "row"} :
                {}
            }>

            <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                padding: "5px 0px 5px 5px",
                wordBreak: 'break-all'
            }}>
                <input
                    type={type}
                    name="radio_default_inline"
                    style={type === "checkbox" ? (
                        {
                            marginRight: "5px",
                            alignSelf: "center",
                            height: "10px"
                        }
                    ) : (
                        {
                            marginRight: "5px",
                            alignSelf: "center",
                            height: "8px"
                        }
                    )}
                    readOnly/>

                <div
                    style={{
                        alignSelf: "center",
                        marginRight: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                        wordBreak: "break-all"
                    }}>
                    <span
                        style={{backgroundColor: codeListData.isCodelistDiff ? compareColor : ""}}>
                        {type === "checkbox" ? itemData.fixedUnit : (codeListData.label !== "" ? codeListData.label : codeListData.value)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ACRFCodeList);