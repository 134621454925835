import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { isDataExist } from "common/commonFunction";
import { FILL_REQUIRED_FILED_SELECT } from "constant/ConstantMsg";
import Select from "react-select";

/**
 *  @summary    검색 dropdown 목록 컴포넌트
 *  @author    주예리나
 *  @version   1.0, color admin 템플릿 ui 변경 작업
 *  @see       None
 */

/**
 *  @component    SearchDropdown
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  검색 dropdown 목록 컴포넌트
 */
const SearchDropdown = forwardRef(({
                                       name,          // input name
                                       dataList,      // dropdown 에 표시할 데이터 목록
                                       onSelect,      // dropdown 목록 항목 선택 시 실행
                                       onChange,      // 검색 값 입력 시 실행
                                       currentValue,  // 현재 dataInfo에 저장된 선택 데이터
                                       isClearable,   // input clear 버튼 활성화 여부
                                       setSearchList, //
                                       validation,    // validation 표시 여부
                                       onFocus,       // input onFocus 적용 됐을 시 실행
                                       isLoading,     // 검색 중일 때 loading 처리 여부
                                       placeHolder = '',  // input창 placeholder
                                       disabled,      // 입력창 disable 여부 (없으면 false 처리),
                                       isMulti,        //
                                   }, ref) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     SearchDropdown
     *  @function     handleChange
     *  @param        {string} value - 입력 값
     *  @return       none
     *  @description  검색어 입력 시 실행되는 함수
     */
    const [inputData, setInputData] = useState('');
    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SearchDropdown
     *  @function     handleChange
     *  @param        {String} value - 입력 값
     *  @param        {Object} action - React-select 에서 제공하는 action 객체 ('input-change' | 'menu-close' | 'input-blur')
     *  @description  검색어 입력 시 실행되는 함수
     */
    const handleChange = useCallback((value, action) => {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setInputData(value);

            if (onChange) {
                onChange(name, value);
            }
        }
    }, [name, onChange]);

    /**
     *  @memberOf     SearchDropdown
     *  @function     handleSelect
     *  @param        {object} selectValue - 선택한 항목의 value object
     *  @return       none
     *  @description  목록 아이템 클릭 시 실행되는 함수
     */
    const handleSelect = useCallback((selectValue) => {
        if (onSelect) {
            onSelect(name, selectValue);
        }
    }, [onSelect, name]);

    /**
     *  @memberOf     SearchDropdown
     *  @function     handleSelect
     *  @param        {object} selectValue - 선택한 항목의 value object
     *  @return       none
     *  @description  목록 아이템 클릭 시 실행되는 함수
     */
    const handleFocus = useCallback((e) => {
        e.preventDefault();
        if (onFocus !== undefined) {
            onFocus(name);
        }
    }, [name, onFocus]);

    /**
     *  @memberOf     SearchDropdown
     *  @function     getInValidText
     *  @param        none
     *  @return       {ReactElement} div
     *  @description  invalid 일 때 출력할 text 내용
     */
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false && isDataExist(currentValue) === false) {
            return (
                <>
                    <div className="ml-2 text-danger">{FILL_REQUIRED_FILED_SELECT}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [currentValue, validation]);

    /**
     *  @memberOf     SearchDropdown
     *  @function     getDataValue
     *  @param        none
     *  @return       {ReactElement} div
     *  @description  select element option 에 출력할 리스트
     */
    const getDataValue = useCallback((value) => {
        if (!isMulti && dataList.length > 0) {
            return dataList.find((list) => currentValue === list.value)
        } else {
            return currentValue;
        }
    }, [isMulti, dataList, currentValue]);

    /**
     *  @listens  [currentValue, name] - currentValue, name 변경 시
     *  @description  - handleSelect 한 후 입력칸 hidden 처리 없애기
     */
    React.useLayoutEffect(() => {
        if (currentValue !== '') {
            const inputEl = document.getElementById(name);
            if (!inputEl) return;
            // prevent input from being hidden after selecting

            inputEl.style.opacity = "1";
        }
    }, [currentValue, name]);

    /**
     *  @listens  [isMulti, currentValue] - isMulti, currentValue 변경 시
     *  @description  - select input data에 현재 값 적용하여 표시
     */
    useEffect(() => {
        if (!isMulti && currentValue !== '') {
            setInputData(currentValue);
        }
    }, [currentValue, isMulti]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <Select
                ref={ref}
                inputId={name}
                classNamePrefix={'react-select'}
                // backspaceRemovesValue={false}
                // defaultInputValue={!isMulti && currentValue}
                isMulti={isMulti}
                isSearchable={true}
                isClearable={isClearable ? isClearable : false}
                // onSelectResetsInput={false}
                isDisabled={disabled}
                className={validation !== undefined && validation === false && isDataExist(currentValue) === false && "is-invalid"}
                isLoading={isLoading}
                options={dataList}
                defaultOptions
                onChange={handleSelect}
                hideSelectedOptions={true}
                inputValue={!isMulti ? inputData : undefined}
                value={getDataValue()}
                onFocus={handleFocus}
                onInputChange={handleChange}
                placeholder={placeHolder}
            />
            <div className="invalid-feedback">{getInValidText()}</div>
        </>
    );
})

export default React.memo(SearchDropdown);
