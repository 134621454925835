import React, {useCallback, useEffect, useLayoutEffect, useRef} from "react";
import {getSessionState} from "common/commonFunction";
import deleteIcon from "../../../../../../assets/icons/delete.svg";
import useWindowSize from "../../../../../../hooks/useWindowSize";

/*################################################################################*/
//## constant 관련
/*################################################################################*
/**
 *  @author       김한나
 *  @version      1.0
 *  @component    WhereClauseEditGrid
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  design information 의 study Epochs 편집 컴포넌트
 */
const WhereClauseEditGrid = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     WhereClauseEditGrid
     *  @type         {Object} props
     *  @property     {Array} dataList - grid 리스트
     *  @property     {Function} onGridChange - 변경 된 gird 데이터
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {dataList, onGridChange, variableList, comparatorList, gridValueList, setGridValueList} = props;

    /**
     *  @memberOf     WhereClauseEditGrid
     *  @type         {Object} props
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf     WhereClauseEditGrid
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     WhereClauseEditGrid
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     WhereClauseEditGrid
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        showRowNumColumn: false,  // no 표시
        wrapSelectionMove: false,
        enableSorting: false,
        editable: lock !== 2,
        wordWrap: false

        // 추후 순서 조정 시 필요
        // enableDrag: lock !== 2,
        // enableDragByCellDrag: lock !== 2,
        // enableDrop: lock !== 2,
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     WhereClauseEditGrid
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // grid 데이터 setting 작업
    useEffect(() => {
        tableRef.current = window.AUIGrid.create(`#whereClause-grid`, columnLayout, auiGridProps);

        if (dataList.length > 0) {
            window.AUIGrid.setGridData(tableRef.current, dataList);
        }

        let _variableList = [];
        dataList.forEach((item => _variableList.push(item.variable)));
        setGridValueList(_variableList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#whereClause-grid`);
        };
    }, []);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell edit 이벤트
        window.AUIGrid.bind(tableRef.current, "cellEditEnd", (event) => {
            if (onGridChange !== undefined) {
                window.AUIGrid.updateRow(tableRef.current, event.rowIndex);
                const changeGridData = window.AUIGrid.getGridData(tableRef.current);

                onGridChange(changeGridData);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (onGridChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onGridChange(changeGridData);
            }
        });
    }, [onGridChange]);

    /**
     *  @memberOf     WhereClauseEditGrid
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'variable',
            headerText: 'Variable',
            editRenderer: {
                type: "ComboBoxRenderer",
                list: variableList,
                showEditorBtnOver: true
            },
        },
        {
            dataField: 'comparator',
            headerText: 'Comparator',
            editRenderer: {
                type: "ComboBoxRenderer",
                list: comparatorList,
                showEditorBtnOver: true
            }
        },
        {
            dataField: 'value',
            headerText: 'Value',
            editRenderer: {
                type: "ComboBoxRenderer",
                listFunction: function (rowIndex, columnIndex, item) {
                    return gridValueList[item.variable];
                },
                showEditorBtnOver: true
            },
        },
        {
            dataField: 'type',
            headerText: 'softHard',
            editRenderer: {
                type: "ComboBoxRenderer",
                list: ["Soft", "Hard"],
                showEditorBtnOver: true
            }
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteTableRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <div className='grid-style' id="whereClause-grid"/>
        </>
    );
};

export default React.memo(WhereClauseEditGrid);


