/**
 *  @constant
 *  @type {string}
 *  @description alert에 표시될 메시지
 */
export const WARNING_TITLE              = 'Can`t move the step';
export const NOT_MOVE_MSG               = 'Your changes could not be saved.';
export const NOT_MOVE_SAVE_MSG          = 'Your changes could not be saved. Do you want to save?';

export const DATA_INIT_TITLE            = 'initialization Message' ;
export const DATA_INIT_CONTENT          = 'The following menus may be initialized. Would you like to proceed anyway?';
export const DATA_CHANGE_CONTENT        = 'The following menus may be changed. Would you like to proceed anyway?';

export const DATA_NOT_SAVE_TITLE        = 'Warning, No data has been saved';
export const DATA_NOT_SAVE_CONTENT      = 'The data in the menu below has not been saved. Would you like to proceed anyway?' ;

// 데이터 삭제 confirm
export const DATA_DELETE_CONTENT        = 'Are you sure that you want to permanently delete this record?' ;
export const FORM_DELETE_CONTENT        = 'Are you sure that you want to delete this record?' ;

// study 에서 sdtm 생성 할 수 없을 때
export const NOT_CREATE_SDTM_TITLE      = 'Can`t create SDTM';
export const NOT_CREATE_SDTM_CONTENT    = 'You need data to create SDTM. Check out the menu below.';
export const NOT_CREATE_CRF_SDTM_MENU   = ['[CRF]-[My CRF]-[New CRF]','[SDTM]-[Mapping]-[New]'];
export const NOT_CREATE_SDTM_MENU       = ['[SDTM]-[Mapping]-[New]'];

// study 에서 crf 생성 할 수 없을 때
export const NOT_CREATE_CRF_TITLE      = 'Can`t create CRF';
export const NOT_CREATE_CRF_CONTENT    = 'You need data to create PRM. Check out the menu below.';
export const NOT_CREATE_CRF_MENU       = ['[PRM]-[My PRM]-[New PRM]','[CRF]-[My CRF]-[New CRF]'];

export const NOT_VIEW_CRF               = 'You have to create CRF';

export const FILE_CHECK                 = 'Please check file type';
// 필수 필드 입력
export const FILL_REQUIRED_FIELD        = 'Please fill the required field';
export const FILL_REQUIRED_FILED_SELECT = 'Please select an item in the list'

// 그룹 이동할 때 한명 이상 선택
export const SELECT_AT_LIST_ONE         = 'Please select at least one list item';

// compare시 두개 이상 선택
export const SELECT_AT_LIST_TWO         = 'Please select at least two list item';

export const CLOSE_BUILD_POPUP          = 'Please close the window after the build is complete';

// 이메일 도메인 확인
export const INCORRECT_EMAIL            = 'The Email Account is incorrect';

// 유효시간 초과
export const SESSION_TIME_OUT           ='The valid time to use the system has been exceeded.';

// 유효시간 경과 경고
export const SESSION_TIME_ALARM         = 'Would you like to extend the available time?';

export const UNABLE_DISCOVER            = "Unable to select 'Discover'";
// 로그아웃 메시지
export const LOG_OUT_MESSAGE            = 'You have successfully logged out';

export const CHANGE_PASSWORD            = 'Your password has been successfully changed.';

export const LOGIN_AGAIN                = 'Please log in again';

// 패스워드 변경 알림
export const PASSWORD_EXPIRED           = 'Your password has expired. Please change your password and use the system.';

// 트레이닝 이수
export const TRAINING_NOT_COMPLETE      = 'You must complete training before you can use the system.';

// 중복
export const DATA_ALREADY_EXISTS        = 'This item already exists';

// 최소한 한 항목
export const DATA_AT_LEAST_ONE          = 'Please select at least one list item';

// 입력 항목 필요
export const INPUT_DATA_NEED            = 'Please enter it in the field.';

// epoch 입력
export const EPOCH_DATA_NEED            = 'Please enter the Epoch';

// arm 입력
export const ARM_DATA_NEED              = 'Please enter the ARM';

// segment 입력
export const SEGMENT_DATA_NEED          = 'Please add the Segment';

// add activity
export const ITEM_NOT_CHECKED           = 'Some items are not checked';

// 필드 모두 입력
export const ENTER_ALL_FIELD             = 'Please enter all field.';

//My Password newPassword Confirm Password 비교
export const INCORRECT_PASSWORD ="The Confirm Password is incorrect"

// 관련 Objective 검색을 먼저 진행해 주세요.
export const SELECT_OBJECTIVE_DATA      = 'Objective 검색을 먼저 진행해 주세요';

// Assessment Reload Confirm Modal 문구
export const ASSESSMENT_CONFIRM_MODAL_CONTENT = "Are you sure that you want to reload assessment?";

//training의 동일한 문제를 생성 할 수 없습니다 문구
export const NOT_SAME_QUESTION = "Unable to create the same Question."

//criteria excel upload 전, 데이터를 바꾸시겠습니까? 문구
export const REPLACE_DATA = "There's already data here. Do you want to replace it?";

// 글자 수 확인
export const INCORRECT_DUNSCODE_CHARACTER_NUMBER = 'It must be 9 characters';

// CriteriaVersionModal.js 체크한 버전이 현재 버전과 동일한 경우 문구
export const SAME_CRITERIA_VERSION = 'This version is the same as the current version';

//SDTM Package 리스트에서 다운로드 버튼 눌러 SDTM Package 선택했을 때, 모든 항목이 최종본이 맞는지 확인하는 문구
export const FINAL_SDTM_PACKAGE = "Are datasets, define.xml, aCRF and SDRG finalized?";