import React, {useCallback} from "react";
import {ButtonGroup} from "reactstrap";
import classNames from 'classnames';
import { isDataExist } from "common/commonFunction";
import {FILL_REQUIRED_FIELD} from "constant/ConstantMsg";
import classnames from "classnames";

/*
* General.js - randomized study 에서 화면 테스용 코드
* <RadioButton dataList={options.randomizedStudy} currentValue={state.randomizedStudy}/>
* */

/**
 *  @summary  Tap 컴포넌트
 *  @author 최종 수정자
 *  @version 1.0, 작업 내용
 *  @see None
 */
const PagingTap = (props) => {
    // props
    /*
      name            : Input tag의 값들을 구별하기 위한 name
      className       : 스타일 클래스
      buttonClassName : 버튼 스타일 클래스
      dataList        : Select option에 사용 될 데이터 리스트
      onChange        : Select box 변경 시 이벤트 전달을 위해 사용(name, 변경 값)으로 호출
      currentValue    : 현재 설정된 값
      disabled        : 사용불가로 만들기 위해사용
      validation      : validation 한 값(true, false)
      onFocus         : invalid 해제 하기 위한 focus 이벤트
    */

    const {
        name      ,
        className ,
        buttonClassName,
        dataList  ,
        onChange  ,
        currentValue,
        disabled,
        validation,
        onFocus,
    } = props;

    /**
     *  @memberOf    PagingTap
     *  @function    handleChange
     *  @param       event
     *  @return      none
     *  @description  이벤트 처리 함수
     */
    const handleChange = useCallback((e) => {

        const targetValue = e.target.value;

        if(onChange !== undefined){
            onChange(name , targetValue);
        }

        // invalid 할 때
        if(validation !== undefined && validation === false){
            if(onFocus !== undefined){
                onFocus(name);
            }
        }

    },[name, onChange, onFocus, validation]);


    /**
     *  @memberOf    PagingTap
     *  @function    dataListItem
     *  @param       none
     *  @return
     *  @description dataList가  처리되는 함수
     */
    const dataListItem = useCallback(() => {

        if (isDataExist(dataList)) {

            return dataList.map((item , index) => {
                const {text , value} = item; //할당

                let styleObject = {
                    "px-4": true,
                    "active": value === currentValue,
                    "hover" : value === currentValue,
                    "radio_button" : true,
                    "btn" :true,
                    "btn-main" :true,
                    "font-weight-normal": true,
                }

                // 버튼에 스타일이 존재하면
                if(isDataExist(buttonClassName)){
                    const classArray = buttonClassName.split(" ");
                    for (const classUnit of classArray) {
                        styleObject[classUnit] = true;
                    }
                }

                return (
                    <button
                        value={value}
                        className={classNames(className, styleObject) }
                        key={index}
                        onClick={handleChange}
                        disabled={disabled}
                    >
                        {text}
                    </button>
                )
            })
        }
    },[buttonClassName, currentValue, dataList, disabled, handleChange, className]);

    // in valid 일 때 출력할 text 내용
    const getInValidText = useCallback(()=>{

        // invalid 할 때
        if(validation !== undefined && validation === false){
            return (
                <>
                    <div className='d-flex justify-content-start mt-1 '>
                        <div className="ml-2 " >{FILL_REQUIRED_FIELD}</div>
                    </div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null ;
        }
    },[validation]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/

    return (
        <>
            <ButtonGroup
                className={classnames({  className,
                    'radio-btn'       : true,
                    'head-default'    : true,
                    'rounded'         : true,
                    'bg-white'        : true,
                    'invalid_border': ((validation !== undefined && validation === false) === true),
                })}
            >
                {dataListItem()}
            </ButtonGroup>
            {getInValidText()}
        </>
    );
};

export default React.memo(PagingTap);
