import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import useWindowSize from "../common/useWindowSize";
import {isDataExist, parseDateTime} from "common/commonFunction";
import "thirdparty/AUIGrid/AUIGrid";
import "thirdparty/AUIGrid/AUIGridLicense";
import "thirdparty/AUIGrid/AUIGrid_style.css";
import 'file-saver';
import 'thirdparty/AUIGrid/messages/AUIGrid.messages.en';

/**
 *  @summary AUI Grid 공통 컴포넌트
 *  @author  최종 수정자
 *  @version 1.0, 작업 내용
 *  @see     None
 */

/**
 *  @component    AUIGrid
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  AUI Grid 공통 컴포넌트
 */
const AUIGrid = forwardRef((props, ref) => {

    const [gridHeight, setGridHeight] = useState();

    //props
    /*
        columnList      : grid column list
        dataList        : data list
        onClick         : click 이벤트 발생시 호출할 함수
        id              : gird ID(한화면에 두개이상 grid 뿌려줄때 필요)
        onEdit          : cell 수정시 호출 함수
        onCellClick     : 지정 cell 선택시 호출 함수
        isAutoGridHeight: 자동으로 grid 높이 지정되는거 안되도록
        columnDepth : 그리드 높이 때문에 컬럼이 갯수가 다 다름 SoA Preview 같은 경우 3줄이 컬럼
     */
    const {
        columnList,
        dataList,
        onClick,
        id = "grid_wrap",
        onEdit,
        onCellEditEnd,
        editBeginMode,
        onCellClick,
        isAutoGridHeight,
        response,
        onSetRef,
        isRadio,
        minusContentHeight = 60,
        columnDepth,
        onHeaderClick,
        scrollInfo,
        onScrollChange,
        isWordWrap = false,
        rowHeight = 35,
        isDrag = false,
        onDrag, //drag 완료 된 List 전달 받는 함수 props
        getDragEvent, //drag 관련 event 전달 받는 함수 props,
        isShowRowNum = false, //행번호 칼럼을 보여줄지 여부
        enableFilter,
        rowStyle,
        onRowClick,
        onContextMenu
    } = props;

    /**
     *  @var          {RefObject} gridRef
     *  @description  grid 컴포넌트
     */
    const gridRef = useRef(null);

    const [width, height] = useWindowSize();

    const auiGridProps = {
        // selectionMode: "singleRow", //선택모드(유효값 : singleCell, singleRow, multipleCells, multipleRows, none)
        showRowNumColumn: isShowRowNum, // 행번호 칼럼을 보여줄지 여부(no 표시)
        autoGridHeight: gridHeight === undefined ? !isAutoGridHeight : false,              // 동적으로 그리드의 높이는 맞게 변경
        // usePaging: false,
        // fixedRowCount: 5,               //고정 행의 개수 지정
        // pageRowCount:3,
        // pageRowSelectValues:[10, 20, 30, 40, 50]/
        editable: !!onEdit || !!onCellEditEnd,                 //수정 가능여부
        editBeginMode: editBeginMode,
        useContextMenu: !!onContextMenu,           //컨텍스트 메뉴 사용 여부
        // softRemoveRowMode: false,       //소프트 삭제 여부(행 삭제시 삭제된 표시가 된 채로 그리드에 남아있음)
        // enableMovingColumn: false,      //column 순서 바꾸기 가능 여부
        enableFilter: enableFilter,             //column 필터링 여부
        // enableSorting: false,               //column sorting 여부
        // showFooter: true,              //grid의 footer 표시
        // showTooltip: true,              //툴팁 표시 여부
        enableCellMerge: true,             //칼럼 셀 병합(cell merge) 가능 여부
        cellMergeRowSpan: true,            //셀 세로 병합을 하는 경우(enableCellMerge=true) 실제 rowspan 처리 하여 보일지 여부(default = true)
        enableHScrollByWheel: false,       //수평 스크롤에 반응할지 여부
        independentAllCheckBox: true,     //전체 선택 체크박스가 독립적인 역할 할지 여부
        showRowCheckColumn: isRadio,         //row 체크 기능
        rowCheckToRadio: isRadio,           //row 체크 기능에서 checkbox를 radio button으로 변경

        // 필터 레이어 기본 가로, 세로 지정(기본값 : 각각 260, 320)
        // filterLayerWidth : 280,
        // filterLayerHeight : 340,

        // 4계층의 헤더를 각각 20, 30, 20, 40 으로 적용시킴
        // 만약 지정하지 않는 경우 기본 headerHeight :24 가 적용됨
        // headerHeights: [30, 30, 40], 그룹형
        // headerHeights: [45]
        wordWrap: isWordWrap,
        height: id === "assessment-grid" || id === "timePointList-grid" || id === "sdmDesignMatrix-grid" || id === "designMatrix-grid" ? 622 : gridHeight,
        headerHeights: [45, 60, 45], // [Design > SDM > Design Matrix]에서 Study Epoch의 최대 길이에 맞춰 60으로 늘림
        rowHeight: rowHeight,
        enableDrag: isDrag, //drag 관련
        enableDragByCellDrag: isDrag, //drag 관련
        enableDrop: isDrag, //drag 관련
        rowStyleFunction: rowStyle
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     AUIGrid
     *  @function     getDataList
     *  @param        none
     *  @return       {Array} data list
     *  @description  grid에 맞춰 뿌려주기 위한 data list 만드는 함수
     */
    const getDataList = useCallback(() => {
        if (isDataExist((dataList)) && isDataExist((columnList))) {
            let arr = [];

            dataList.forEach((rowData) => {
                let obj = {ID: rowData.ID};

                /**
                 * Terminology 메뉴일 때
                 * code가 배열이고, 배열의 길이가 0보다 클때
                 * (배열의 길이가 0이면 else로 일반 grid로 돌아감)
                 */
                if (Array.isArray(rowData.codeList) && rowData.codeList.length > 0) {
                    rowData.codeList.forEach((codeData) => {
                        let terminologyObj = {ID: rowData.ID};
                        columnList.forEach((column) => {
                            let key = column.dataField;

                            if (key === 'code' || key === 'value' || key === 'label' || key === 'codeType') {
                                terminologyObj[key] = codeData[key];
                            } else if (key === 'state') {
                                if (rowData[key] === 0) terminologyObj[key] = 'Close';
                                else if (rowData[key] === 1) column.headerText !== "Lock" && column.headerText !== "Status" ? terminologyObj[key] = 'Open' : terminologyObj[key] = 'Unlocked';
                                else if (rowData[key] === 2) terminologyObj[key] = 'Lock';
                                else if (rowData[key] === 3) terminologyObj[key] = 'Delete';
                            } else {
                                if (Array.isArray(rowData[key]) && rowData[key].length > 0) terminologyObj[key] = rowData[key].toString();
                                else terminologyObj[key] = rowData[key];
                            }
                        });
                        arr.push(terminologyObj);
                    });
                } else { //일반 grid
                    columnList.forEach((column) => {
                        let key = column.dataField;

                        if (key === 'state') {
                            if (rowData[key] === 0) obj[key] = 'Close';
                            else if (rowData[key] === 1) column.headerText !== "Lock" && column.headerText !== "Status" ? obj[key] = 'Open' : obj[key] = 'Unlocked';
                            else if (rowData[key] === 2) obj[key] = 'Locked';
                            else if (rowData[key] === 3) obj[key] = 'Delete';
                        } else if (key === "endDate" || key === "lockTime") {
                            obj[key] = parseDateTime(rowData[key], 'YYYY-MM-DD')
                        } else if (key === "enabled" || key === "collect" || key === "common") {
                            if (rowData[key] === 0) obj[key] = 'No';
                            else obj[key] = 'Yes';
                        } else if (key === "useMap") {
                            obj[key] = rowData[key] !== 0;
                        } else if (key === "epoch") {
                            for (let epoch of column.children) {
                                for (let visit of epoch.children) {
                                    obj[visit.dataField] = rowData[visit.dataField];
                                }
                            }
                        } else if (key === "previewEpoch") {
                            for (let epoch of column.children) {
                                for (let visit of epoch.children) {
                                    obj[visit.dataField] = rowData[visit.dataField] ? "V" : "";
                                }
                            }
                        } else if (key === "createdTime" && column.headerText === 'Build Date') {
                            obj[key] = rowData[key];
                        } else if (key === "createdTime" && column.headerText === 'Date Created') {
                            obj[key] = parseDateTime(rowData[key], 'YYYY-MM-DD');
                        } else if (key !== undefined && key.includes("STEP") && column.children) {
                            for (let epoch of column.children) {
                                obj[epoch.dataField] = rowData[epoch.dataField];
                            }
                        } else if (key === 'status') {
                            if (rowData[key] === 1 || rowData[key] === 'Final') obj[key] = "성공";
                            else obj[key] = "실패";
                        } else {
                            if (Array.isArray(rowData[key]) && rowData[key].length > 0) obj[key] = rowData[key].toString();
                            else obj[key] = rowData[key];
                        }
                    });
                    arr.push(obj);
                }
            });

            return arr;
        }
    }, [columnList, dataList]);

    const handleSetRef = useCallback((ref) => {
        if (onSetRef) {
            onSetRef(ref);
        }
    }, [onSetRef]);

    /**
     *  @memberOf     AUIGrid
     *  @function     handleExportToExcel
     *  @param        none
     *  @return       none
     *  @description  export to excel
     */
    const handleExportToExcel = useCallback((sheetName, fileName) => {
        window.AUIGrid.exportToXlsx(gridRef.current, {
            sheetName: sheetName,
            fileName: fileName,
            progressBar: true,
            exportWithStyle: true
        });
    }, []);

    /**
     *  @memberOf     AUIGrid
     *  @function     processData
     *  @param        {Array} arr - dataList
     *  @return       none
     *  @description  row 추가, 삭제시 dataList 변경하여 전달
     */
    const processData = useCallback(arr => {
        response(arr);
    }, [response]);

    /**
     *  @memberOf     AUIGrid
     *  @function     getCheckedRowItems
     *  @param        none
     *  @return       none
     *  @description  라디오버튼에 체크가 되어진 row의 값을 전달
     */
    const getCheckedRowItems = useCallback(() => {
        let checkedItems = window.AUIGrid.getCheckedRowItems(gridRef.current);

        let rowItem;
        if (checkedItems.length > 0) {
            rowItem = checkedItems[0].item;
        }

        response(rowItem);
    }, [response]);

    //Mapping > Detail의 Function Setting 모달에서 라디오 버튼 클릭시 호출
    const setCheckedRowsByValue = useCallback((functionID) => {
        window.AUIGrid.setCheckedRowsByValue(gridRef.current, "functionID", functionID);
    }, []);

    //Mapping > Detail의 Codelist Edit 모달에서 라디오 버튼 클릭시 호출
    const setCheckedCodelist = useCallback((value) => {
        window.AUIGrid.setCheckedRowsByValue(gridRef.current, "value", value);
    }, []);

    // AUIGrid 을 reference 를 가지고 호출 할 수 있도록 사용하는 hook
    useImperativeHandle(ref, () => ({
        request(params) {
            const {num, item, dataList, e, sheetName, fileName, functionID, value} = params;

            switch (num) {
                //row 추가
                case 1:
                    window.AUIGrid.addRow(gridRef.current, item, "last");
                    dataList.push(item);
                    processData(dataList);
                    break;
                //row 삭제
                case 2:
                    window.AUIGrid.removeRow(gridRef.current, "selectedIndex");
                    window.AUIGrid.removeSoftRows(gridRef.current);// 실제로 그리드에서 삭제 함.
                    dataList.splice(e.rowIndex, 1);
                    processData(dataList);
                    break;
                //excel 다운로드
                case 3:
                    handleExportToExcel(sheetName, fileName);
                    break;
                //radio button에 체크된 row 값 추출
                case 4:
                    getCheckedRowItems();
                    break;
                //값 비교해서 radio button에 체크하기
                case 5:
                    setCheckedRowsByValue(functionID);
                    break;

                case 6:
                    setCheckedCodelist(value);
                    break;

                case 7:
                    window.AUIGrid.addRow(gridRef.current, item);
                    dataList.splice(e.rowIndex + 1, 0, item);
                    processData(dataList);
                    break;

                case 8:
                    window.AUIGrid.updateRow(gridRef.current, item, e.rowIndex);
                    break;

                case 9:
                    return window.AUIGrid.getGridData(gridRef.current);

                //no default
            }
        }
    }));

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/

    /**
     *  @listens      [columnList] - columnList 변경 시
     *  @listens      [auiGridProps] - auiGridProps 변경 시
     *  @listens      [onClick] - onClick 변경 시
     *  @listens      [dataList] - dataList 변경 시
     *  @listens      [getDataList] - getDataList 변경 시
     *  @description  AUIGrid 생성 및 데이터 생성
     */
    useEffect(() => {
        gridRef.current = window.AUIGrid.create(`#${id}`, columnList, auiGridProps);

        handleSetRef(gridRef);

        // 데이터 셋팅
        if (dataList !== undefined && dataList.length !== 0) {
            window.AUIGrid.setGridData(gridRef.current, getDataList());
        } else if (dataList !== undefined && dataList.length === 0) {
            window.AUIGrid.showInfoMessage(gridRef.current, "<div class='data-center'><div><i class='fas fa-exclamation-circle' /><span>No Data</span></div></div>");
        }

        //스크롤 정보가 세로나 가로나 0보다 클때만
        if (scrollInfo !== undefined && (scrollInfo.vertical > 0 || scrollInfo.horizontal > 0)) {
            window.AUIGrid.setRowPosition(gridRef.current, scrollInfo.vertical); //세로 스크롤 위치 설정
            window.AUIGrid.setHScrollPositionByPx(gridRef.current, scrollInfo.horizontal); //가로 스크롤 위치 설정
        }

        //Design - SoA - Assessment 쪽에서만 사용
        if (id === "assessment-grid") {
            //고정 칼럼
            window.AUIGrid.setFixedColumnCount(gridRef.current, 2);
        }

        // cell 클릭 이벤트
        if (onClick) {
            window.AUIGrid.bind(gridRef.current, "cellClick", (event) => {
                if (onClick !== undefined) {
                    // clinical trial 목록 팝업 일 때
                    if (event.item.hasOwnProperty('studyStart')) {
                        const link = dataList && dataList[event.rowIndex].link;
                        const ID = dataList && dataList[event.rowIndex].ID;
                        onClick(ID, link);
                    } else if (event.dataField === "btn") {
                        //이경우 상세보기로 안넘어가지도록
                        //Mapping - RELREC에서 Add 버튼 merge 할때 사용중인데 배경을 누르면 상세보기로 넘어가짐
                    } else {
                        onClick(event.item.ID, !event.item.name ?
                            (event.item.prompt ?
                                event.item.prompt : (event.item.subject ?
                                    event.item.subject : (event.item.functionName ?
                                        event.item.functionName : event.item.standard ?
                                            event.item.sponsor : event.item.sponsor))) : event.item.name, event.item.type);
                    }
                }
            });
        }

        if (onRowClick) {
            window.AUIGrid.bind(gridRef.current, "cellClick", (event) => {
                if (onRowClick !== undefined) {
                    onRowClick(event);
                }
            });
        }

        //특정 cell이 클릭 됐을때 발생하는 이벤트
        if (onCellClick) {
            window.AUIGrid.bind(gridRef.current, "cellClick", (event) => {
                if (event.dataField === "startVisit" || event.dataField === "endVisit" || event.dataField === "definitionVal" || event.dataField === "functionName" || event.dataField === "keys" || event.dataField === "CT" || event.dataField === "IETEST" || event.pid === '#importingGrid') {
                    //functionName 필드일때
                    //value가 비어있으면 cell click 이벤트 발생 안되도록
                    // if (event.dataField === "functionName") {
                    //     if (event.value !== "" && event.value !== null) {
                    //         onCellClick(event);
                    //     }
                    // } else if (event.dataField === "CT") {
                    if (event.dataField === "CT") {
                        if (event.value !== "" && event.value !== null && event.item.collected !== "1") {
                            onCellClick(event);
                        }
                    } else {
                        onCellClick(event);
                    }
                }
            });
        }

        //column 이 클릭 됐을때 발생하는 이벤트
        if (onHeaderClick) {
            window.AUIGrid.bind(gridRef.current, "headerClick", (event) => {
                onHeaderClick(event);
            });
        }

        // 컨텍스트 메뉴 이벤트 바인딩
        if (!!onContextMenu) {
            window.AUIGrid.bind(gridRef.current, "contextMenu", (event) => {
                return onContextMenu(event);
            });
        }

        //cell edit 이벤트
        window.AUIGrid.bind(gridRef.current, "cellEditEnd", (event) => {
            if (onEdit !== undefined) {
                //SDTM > Tabulation checkbox
                let tabulationList = window.AUIGrid.getGridData(gridRef.current);
                if (event.dataField === "draftCheck" && event.item.type === "Clinical Data") {
                    tabulationList.forEach((data) => {
                        if (data.ID === event.item.ID) {
                            if (event.item.draftCheck === 1) {
                                data.draftCheck = 1;
                            } else {
                                data.draftCheck = 0;
                            }
                        } else {
                            if (data.type === "Clinical Data") {
                                data.draftCheck = 0;
                            }
                        }
                    });
                    onEdit(event.dataField, event.rowIndex, event.value, event.item, tabulationList);
                } else if (event.dataField === "draftCheck" && event.item.type === "External Data") {
                    tabulationList.forEach((data) => {
                        if (data.ID === event.item.ID) {
                            if (event.item.draftCheck === 1) {
                                data.draftCheck = 1;
                            } else {
                                data.draftCheck = 0;
                            }
                        }
                    });
                    onEdit(event.dataField, event.rowIndex, event.value, event.item, tabulationList);
                    // onEdit(event.dataField, event.rowIndex, event.value);
                } else {
                    onEdit(event.dataField, event.rowIndex, event.value, event);
                }
            } else if (!!onCellEditEnd) {
                onCellEditEnd(event);
            }
        });

        //세로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "vScrollChange", (event) => {
            if (onScrollChange !== undefined) {
                onScrollChange(event);
            }
        });

        //가로 스크롤 변경뙬 때 마다 발생하는 이벤트
        window.AUIGrid.bind(gridRef.current, "hScrollChange", (event) => {
            if (onScrollChange !== undefined) {
                onScrollChange(event);
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(gridRef.current, "dropEnd", (e) => {
            if (onDrag !== undefined) {
                // const changeGridData = window.AUIGrid.getOrgGridData(gridRef.current); // 변경된 grid 데이터 가져오기
                const _dataList = [...dataList];
                const item = _dataList.splice(e.fromRowIndex, 1); //위치 변경할 데이터를 item에 저장하고 목록에서 삭제
                _dataList.splice(e.toRowIndex, 0, item[0]) //item에 저장된 데이터를 해당하는 위치에 넣어줌

                onDrag(_dataList);
            }

            if (getDragEvent !== undefined) {
                getDragEvent(e);
            }
        });

        return () => {
            window.AUIGrid.destroy(`#${id}`);
        }
    }, [columnList, auiGridProps, onClick, dataList, getDataList, id, onEdit, onCellClick, handleSetRef, onHeaderClick, scrollInfo, onScrollChange, onDrag, getDragEvent, onRowClick, onContextMenu, onCellEditEnd]);

    /**
     *  @listens      [width] - width 변경 시
     *  @listens      [height] - height 변경 시
     *  @description  AUIGrid 브라우저 창 크기 변화 시 grid resizing 필요
     */
    useEffect(() => {
        window.AUIGrid.resize(gridRef.current, '100%');
    }, [width, height]);

    useEffect(() => {
        let content = document.getElementById("content");
        let contentHeight;

        if (content !== null) {
            if (dataList !== undefined) {
                //auiGridProps.headerHeights 계산 하고
                if (dataList.length > 0) {
                    if (columnDepth === 3) { //컬럼이 3줄 일때
                        contentHeight = (dataList.length * auiGridProps.rowHeight) + 105 + 2;
                    } else if (columnDepth === 2) { //컬럼이 2줄 일때
                        contentHeight = (dataList.length * auiGridProps.rowHeight) + 75 + 2;
                    } else { //컬럼이 1줄 일때
                        contentHeight = (dataList.length * auiGridProps.rowHeight) + 45 + 2;
                    }
                } else {
                    contentHeight = 300;
                }

                setGridHeight(contentHeight);

                if (contentHeight > content.clientHeight) {
                    contentHeight = content.clientHeight - minusContentHeight;
                    setGridHeight(contentHeight);
                }
            }
        }

        // 목록 제외 mapping 에서 사용할 id
        let custom = document.getElementById("custom");

        if (custom !== null) {
            contentHeight = custom.clientHeight - minusContentHeight;
            setGridHeight(contentHeight);
        }

        // 목록 제외 customModal 에서 사용할 id
        let customModal = document.getElementById("customModal");

        if (customModal !== null) {
            contentHeight = customModal.clientHeight - minusContentHeight;
            setGridHeight(contentHeight);
        }

        // 목록에서 Merge 하는 grid에서 사용할 id
        let merge = document.getElementById("merge");

        if (merge !== null) {
            contentHeight = merge.clientHeight - 60;
            setGridHeight(contentHeight);
        }

        // Grid 위에 버튼이 있는 경우(Add, Example 버튼 같은 경우)
        let btnContent = document.getElementById("btnContent");

        if (btnContent !== null) {
            if (dataList.length > 0) {
                contentHeight = (18 * auiGridProps.rowHeight) + 45 + 2;
                setGridHeight(contentHeight);
            } else {
                contentHeight = 300;
            }
        }
    }, [auiGridProps.rowHeight, columnDepth, dataList, minusContentHeight]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div className='grid-style' id={`${id}`}/>
    );
});

export default React.memo(AUIGrid);