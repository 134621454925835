import React from "react";
import { Redirect } from "react-router-dom";

// account
const MyInfo = React.lazy(() => import("imtrial/views/extra/account/MyInfo"));
const MyPassword = React.lazy(() => import("imtrial/views/extra/account/MyPassword"));

// training
const Training = React.lazy(() => import("imtrial/views/extra/training/Training"));
const TrainingDetail = React.lazy(() => import("imtrial/views/extra/training/TrainingDetail"));
const TrainingQuestion = React.lazy(() => import("imtrial/views/extra/training/TrainingQuestion"));
const TrainingCertificateDetail = React.lazy(() => import("imtrial/views/extra/training/CertificateDetail"));

// Support
const SupportNotice = React.lazy(() => import("imtrial/views/extra/support/notice/Notice"));
const NoticeDetail = React.lazy(() => import("imtrial/views/master/support/notice/NoticeDetail"));


const ExtraRoutes = [
    {
        path: "/user",
        name: "User",
        exact: true,
        component: () => <Redirect to="/user/my-info"/>,
    },
    {
        path: "/user/my-info",
        name: "My Information",
        exact: true,
        component: MyInfo,
    },
    {
        path: "/user/my-password",
        name: "My Password",
        exact: true,
        component: MyPassword,
    },
    // support
    {
        path: "/support",
        name: "Support",
        exact: true,
        component: () => <Redirect to="/support/notice"/>,
    },
    {
        path: "/support/notice",
        name: "Notice",
        exact: true,
        component: SupportNotice,
    },
    {
        path: "/support/notice/detail",
        name: "Notice Detail",
        exact: true,
        component: NoticeDetail,
    },
    // training

    {
        path: "/user/training",
        name: "Training",
        exact: true,
        component: Training,
    },
    {
        path: "/user/training/detail",
        name: "Training Detail",
        exact: true,
        component: TrainingDetail,
    },
    {
        path: "/user/training/test",
        name: "Test",
        exact: true,
        component: TrainingQuestion,
    },
    {
        path: "/user/training/certificate",
        name: "EDC Certificate",
        exact: true,
        component: TrainingCertificateDetail,
    },
]

export default ExtraRoutes;