import React, {useCallback, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactConfirmAlert from "react-confirm-alert";
import {Card, CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import {ERROR_CODE} from "constant/CommonConstant";
import {clearSession} from "common/commonFunction";


const ErrorModal = (props) => {
    const {onClose, errorInfo, errorCode, history} = props;
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if (errorInfo === null) {
            setErrorMsg(null);
        } else {
            setErrorMsg(errorInfo);
        }
    }, [errorInfo]);

    // 닫기 버튼 클릭 시
    const handleCancel = useCallback(() => {
        if (onClose !== undefined) {
            onClose(errorCode);
        }
    }, [errorCode, onClose]);

    // enter 키 focus를 위한 함수
    const focusOnEnter = useCallback(() => {
        console.log(document.getElementById('cancelButton'));

        document.getElementById('cancelButton').focus();
    }, []);

    //다른 아이디로 로그인했을때 세션중복 -> 로그아웃처리
    const goLogin = useCallback(() => {
        clearSession();
        handleCancel();
        history.push('/account/login');
    }, [handleCancel, history]);


    // 하단 버튼 처리
    const getFooterButton = useCallback(() => {

        switch (errorCode) {
            // case ERROR_CODE.NO_USER:
            case ERROR_CODE.AUTH_ERROR:
            case ERROR_CODE.NO_TOKEN :
            case ERROR_CODE.TOKEN_EXPIRED :
            case ERROR_CODE.VERIFY_FAIL:
            case ERROR_CODE.OVERLAP_SESSION :
            case ERROR_CODE.ANOTHER_ACCOUNT :
                // case ERROR_CODE.NO_MORE_VALID :
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <button
                                id="cancelButton"
                                className="btn btn-orange btn-inner-shadow text-white px-5"
                                onClick={goLogin}
                            >
                                OK
                            </button>
                        </div>
                    </>);
            case ERROR_CODE.LOCK_AUTH_FAIL :
            case ERROR_CODE.EMAIL_AUTH_FAIL:
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <button
                                id="cancelButton"
                                className="btn btn-blue btn-inner-shadow text-white px-5"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </>);
            default:
                return (
                    <>
                        <div className='d-flex justify-content-end'>
                            <button
                                id="cancelButton"
                                className="btn btn-blue btn-inner-shadow text-white px-3 px-md-5"
                                onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </>
                );
        }
    }, [errorCode, goLogin, handleCancel]);

    // 팝업창이 동작하면 ok버튼에 자동 focus
    useEffect(() => {
        focusOnEnter();
    }, [focusOnEnter]);


    return (

        <ReactConfirmAlert
            customUI={() => {
                return (
                    <>
                        <Card className={"card"}>
                            <CardHeader>
                                <div className="d-flex align-items-center justify-content-start">
                                    <i className="fa fa-times-circle"/>
                                    <div className="mx-2">
                                        <strong>Error</strong>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="popup_min_height py-4 mx-3">
                                <Row>
                                    <h5 className="card-title">{errorMsg}</h5>
                                </Row>
                                {/*<Row>*/}
                                {/*  <p className="card-text">{msg}</p>*/}
                                {/*</Row>*/}
                            </CardBody>
                            <CardFooter>
                                {getFooterButton()}
                            </CardFooter>
                        </Card>
                    </>
                );
            }}
        />
    );
};

export default React.memo(ErrorModal);
