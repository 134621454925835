import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";

// function flatten(destArray, nodeList) {
//     nodeList.forEach((node) => {
//         let object = {
//             path: node.path,
//             title: node.title
//         }
//         destArray.push(object);
//         flatten(destArray, node.children || []);
//     });
// }

const getPathList = (arr) => {
    // console.log(arr);
    return arr.map((sum => value => {
        return sum += `/${value}`;
    })(""));
}

const ContentHeader = ({routes, menus, location, history, match}) => {

    const [currentRoute, setCurrentRoute] = useState('');

    const [urlList, setUrlList] = useState([]);

    const [pathList, setPathList] = useState([]);


    useEffect(() => {
        if (currentRoute) {
            let newList = currentRoute.path.split("/").splice(1)
            setUrlList(newList);
        }
    }, [currentRoute]);


    useEffect(() => {
        if (urlList.length > 0) {
            const newPathList = getPathList(urlList);
            setPathList(newPathList);
        }

    }, [urlList]);

    useEffect(() => {
        const findRoute = routes.find((route) => route.path === location.pathname);

        setCurrentRoute(findRoute);
    }, [routes, location]);


    const getBreadCrumbList = useCallback(() => {
        // eslint-disable-next-line array-callback-return
        return pathList.map((url, urlIdx) => {
            let findPath = routes.find((pathObject) => {
                return pathObject.path === url;
            });

            if (findPath) {
                return <li key={urlIdx} className="breadcrumb-item p-l-20"><Link to={findPath.path}>{findPath.name}</Link></li>
            }
        });

    }, [pathList, routes]);


    return (
        currentRoute ? (
            <div className="clearfix">
                <ol className="breadcrumb">
                    {
                        getBreadCrumbList()
                    }
                </ol>
            </div>
        ) : (
            <React.Fragment/>
        )

    );
};

export default withRouter(ContentHeader);