import React, {useCallback, useEffect, useState} from "react";
import ACRFCodeList from "./ACRFCodeList";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFItem
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Item 컴포넌트
 */
const ACRFItem = (props) => {
    /**
     *  @memberOf     ACRFItem
     *  @type         {Object} props
     *  @property     {Object} itemData - Item 정보
     *  @property     {Number} columnIdx - item의 componentType 이 "DropDownList"이고, question이 "TimePoint" 인 codelist의 배열의 인덱스 (landscape용)
     *  @property     {String} compareColor - 비교 색상
     *  @property     {String} formType - formType 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        itemData,
        columnIdx,
        compareColor,
        formType
    } = props;

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     ACRFItem
     *  @function     getDefaultValue
     *  @return       {Element} Item Input Default Value render
     *  @description  ACRF의 해당하는 Item의 input Default Value 그려주는 함수
     */
    const getDefaultValue = useCallback(() => {
        if (itemData.dataType === "Datetime" && itemData.format !== "") {
            return (
                <div style={{fontSize: "9pt"}}>
                    {itemData.format}
                </div>
            )
        } else if (itemData.dataType === "Float") {
            let num = '_ '.repeat(Math.floor(itemData.format) - itemData.format.slice(itemData.format.indexOf('.') + 1));
            let primeNum = '_ '.repeat(itemData.format.slice(itemData.format.indexOf('.') + 1));

            return num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
        } else if (itemData.dataType === "Integer") {
            return "_ ".repeat(itemData.format);
        } else if (itemData.dataType === "Text") {
            return "__________";
        }
    }, [itemData.dataType, itemData.format]);

    /**
     *  @memberOf     ACRFItem
     *  @function     getItemComp
     *  @return       {Element} Item Value render
     *  @description  ACRF의 해당하는 Item의 Value 그려주는 함수
     */
    const getItemComp = () => {
        // formType이 Laboratory이며 codlist가 있고, defaultValue값이 존재할 경우 defaultValue의 내용을 출력
        if (formType === "LABORATORY" && itemData.codelistIDs.length > 0 && itemData.defaultValue !== "") {
            return (
                <div
                    style={{
                        justifyContent: "space-between",
                        lineHeight: "2.5",
                        alignItems: "center"
                    }}>
                    <div style={{
                        display: "inline-block",
                        padding: "10px",
                        marginTop: "-2px",
                        fontSize: "10pt"
                    }}>
                        {itemData.defaultValue}
                    </div>
                </div>
            );
        }

        if (itemData.componentType === "Text") { //componentType이 Text 일때
            return (
                <div
                    style={{
                        justifyContent: "space-between",
                        lineHeight: "2.5",
                        alignItems: "center"
                    }}>
                    <div style={{
                        display: "inline-block",
                        padding: "10px",
                        marginTop: "-2px",
                        fontSize: "9pt",
                        backgroundColor: itemData.isValueDiff ? compareColor : ""
                    }}>
                        {getDefaultValue()}
                    </div>
                    {itemData.hasOwnProperty("fixedUnit") && itemData.fixedUnit !== "" && (
                        <div style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            fontSize: "9pt",
                            fontFamily: "Arial, sans-serif"
                        }}>
                            <span
                                style={{backgroundColor: itemData.isFixedUnitDiff ? compareColor : ""}}>{itemData.fixedUnit}</span>
                        </div>
                    )}
                </div>
            );
        } else if (itemData.componentType === "CheckBox" && itemData.codelists !== undefined) { //componentType이 CheckBox 일때
            return itemData.codelists.map((code, idx) => (
                <div style={{backgroundColor: itemData.isValueDiff ? compareColor : ""}}>
                    <ACRFCodeList
                        key={idx}
                        type={"checkbox"}
                        codeListData={code}
                        itemData={itemData}
                        compareColor={compareColor}/>
                </div>
            ));
        } else if (itemData.componentType === "DropDownList") { //componentType이 DropDownList 일때
            let defaultValueArr = []; // itemData의 element가 "TPT"를 포함하거나 "SPID"를 포함할 때 해당 item의 codelist label 배열

            if (itemData.element.includes("TPT") && itemData.defaultValue.includes("|")) {
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("LOC") && itemData.defaultValue.includes("|")) {
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("SPID") && itemData.defaultValue.includes("|")) { // element 별로 codelist의 label값이 백엔드에서 들어오는 item이 있고, 안 들어오는 item이 있음. SPID는 label값이 안 들어오기 때문에 label값 대신 defaultValue값을 사용.
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("TESTCD") && itemData.defaultValue.includes("|")) {
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("SCAT") && itemData.defaultValue.includes("|")) {
                console.log(itemData)
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }
            
            const manualCondition = (itemData.designID === "DEGN000000000214" && itemData.ID === "CRIT000002314911" && itemData.itemGroupID === "CRIG000000217908" && itemData.codelists.filter((code) => code.ID === 'CRCL000000216900')?.[0]?.label === defaultValueArr[columnIdx]) ||
                (itemData.designID === "V010000000000214" && itemData.ID === "V010000002314911" && itemData.itemGroupID === "V010000000217908" && itemData.codelists.filter((code) => code.ID === 'V010000000216900')?.[0]?.label === defaultValueArr[columnIdx]) ||
                (itemData.designID === "DEGN000000000214" && itemData.ID === "CRIT000002314932" && itemData.itemGroupID === "CRIG000000217911" && itemData.codelists.filter((code) => code.ID === "CRCL000000216950")?.[0]?.label === defaultValueArr[columnIdx]) ||
                (itemData.designID === "V010000000000214" && itemData.ID === "V010000002314932" && itemData.itemGroupID === "V010000000217911" && itemData.codelists.filter((code) => code.ID === "V010000000216950")?.[0]?.label === defaultValueArr[columnIdx])
            if (itemData.element.includes("TPT") || itemData.element.includes("SPID") || itemData.element.includes("LOC") || itemData.element.includes("TESTCD") || itemData.element.includes("SCAT")) { // item의 element가 "TPT"/ "SPID" / "LOC" /"TESTCD" 인 경우 : codelist의 label/ defaultValue 출력
                return (
                    <div
                        style={{
                            display: "flex",
                            lineHeight: "2.5",
                            flex: 1,
                            maxWidth: "160px",
                        }}>
                        <div style={{
                            padding: "10px",
                            width: "100%",
                            fontSize: "9pt",
                            textAlign: "center",
                        }}>
                            {defaultValueArr[columnIdx] ? <span style={{backgroundColor: manualCondition ? compareColor : ""}}>                                 
                                    {defaultValueArr[columnIdx]}
                            </span> :
                                <select
                                    style={{width: "100%", backgroundColor: itemData.isValueDiff ? compareColor : ""}}>
                                    <option value="">...</option>
                                </select>}
                        </div>
                    </div>
                )
            }
            return (
                <div
                    style={{
                        display: "flex",
                        lineHeight: "2.5",
                        flex: 1,
                        maxWidth: "160px",
                    }}>
                    <div style={{
                        padding: "10px",
                        width: "100%",
                        fontSize: "9pt"
                    }}>
                        <select style={{width: "100%", backgroundColor: itemData.isValueDiff ? compareColor : ""}}>
                            <option value="">...</option>
                        </select>
                    </div>
                </div>
            );

        } else if (itemData.question && itemData.componentType.includes("RadioButton")) { //componentType이 RadioButton이나 RadioButton (Vertical) 일때
            return (
                <div style={!itemData.componentType.includes("Vertical") ? {
                    display: "flex",
                    marginTop: "-1px",
                    padding: "10px 0px",
                } : {display: "block", marginTop: "-1px", padding: "10px 0px"}}>
                    {itemData?.codelists?.map((code, idx) => (
                        <ACRFCodeList
                            key={idx}
                            type={"radio"}
                            codeListData={code}
                            isVertical={itemData.componentType.includes("Vertical")}
                            itemData={itemData}
                            compareColor={compareColor}/>
                    ))}
                </div>
            );
        } else if (itemData.componentType === "SearchList" || itemData.componentType === "Dynamic SearchList") {
            return (
                <div style={{
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    padding: "0px 20px 0px 10px",
                    backgroundColor: itemData.isValueDiff ? compareColor : ""
                }}>
                    <input
                        type="text"
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRightWidth: "0px",
                            borderColor: "#a7a6aa",
                            paddingLeft: "2px",
                            paddingRight: "2px",
                            backgroundColor: "transparent",
                            height: "20px",
                            width: "100%"
                        }}
                        readOnly={true}/>
                    <input
                        style={{
                            backgroundImage: `url("data:image/png;base64,R0lGODlhCQAFAIABAKemqv///yH+FUNyZWF0ZWQgd2l0aCBUaGUgR0lNUAAh+QQBCgABACwAAAAACQAFAAACCoR/gRmKDRlsYBYAOw==")`,
                            backgroundColor: "transparent",
                            borderStyle: "solid",
                            borderColor: "#a7a6aa",
                            borderWidth: "1px",
                            backgroundPosition: "2px .5em",
                            backgroundRepeat: "no-repeat",
                            width: "15px",
                            height: "20px",
                            cursor: "pointer",
                        }}
                        readOnly={true}/>
                </div>
            )
        }
    };

    /**
     *  @memberOf     ACRFItem
     *  @function     getItemLayout
     *  @return       {Element} Item Data render
     *  @description  Item이 속한 form의 landscape 여부, Itemgroup의 Yes/No 여부에 따라 화면 그려주는 함수
     *  @description   뉴로 바이오젠 과제에 해당하는 ID의 디자인들은 (DEGN000000000093/ DEGN000000000100/ DEGN000000000112/) Landscape 표 형식을 적용하지 않음
     */
    const getItemLayout = useCallback(() => {
        // 뉴로 바이오젠 ID 에 해당하는 디자인들은 현재 진행 중인 과제이므로 landscape 표를 적용하면 안 됨
        if (itemData.designID === "DEGN000000000093" || itemData.designID === "DEGN000000000100" || itemData.designID === "DEGN000000000112") {
            return (
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        border: "1px solid #000000",
                        marginTop: '-1px',
                        borderTop: "0px",
                        fontSize: "9pt",
                        breakInside: "avoid",
                        overflowWrap: "anywhere",
                    }}>
                        {/*item의 question 영역*/}
                        <div style={{
                            display: "flex",
                            borderRight: "1px solid #000000",
                            flex: 1,
                        }}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                padding: "10px",
                                fontFamily: "Arial, sans-serif",
                            }}>
                                    <span>
                                        {itemData.question && itemData.question.includes('<br/>') ?
                                            <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                                <pre style={{
                                                    whiteSpace: "pre-wrap",
                                                    fontSize: "9pt",
                                                    fontFamily: "Arial, sans-serif"
                                                }}>
                                                    {itemData.designVisit?.map((each) => {
                                                        if (each) {
                                                            return (<span style={{color: 'red'}}>({each})&nbsp;</span>);
                                                        }
                                                    })}
                                                    <span
                                                        style={{backgroundColor: itemData.isQuestionDiff ? compareColor : ""}}>
                                                        {itemData.question.replaceAll('<br/>', '\n')}
                                                        {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                                    </span>
                                                </pre>
                                            </div> :
                                            <>
                                                {itemData.designVisit?.map((each) => {
                                                    if (each) {
                                                        return (<span style={{color: 'red'}}>({each})&nbsp;</span>);
                                                    }
                                                })}
                                                <span
                                                    style={{backgroundColor: itemData.isQuestionDiff ? compareColor : ""}}>
                                                    {itemData.question}
                                                    {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                                </span>
                                            </>
                                        }
                                    </span>
                            </div>
                        </div>

                        {/*item의 value 영역*/}
                        <div style={{
                            display: "flex",
                            borderBottom: "0px",
                            borderTop: "0px",
                            borderLeft: "0px",
                            borderRight: "0px",
                            flex: 1,
                        }}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                padding: "10px",
                            }}>
                                {getItemComp()}
                            </div>
                        </div>
                    </div>

                    {/*item의 description 영역*/}
                    {(itemData.hasOwnProperty("comment") && itemData.comment !== "" || (itemData.componentType === "DropDownList" || itemData.componentType === "SearchList")) && (
                        <div
                            style={{
                                border: "1px solid #000000",
                                marginTop: "-1px",
                                padding: "5px 5px",
                            }}>
                                <pre style={{
                                    fontSize: "9pt",
                                    fontFamily: "Arial, sans-serif",
                                    whiteSpace: "pre-wrap",
                                    margin: "0px",
                                    padding: "5px"
                                }}>
                                    {(itemData.componentType === "DropDownList" || itemData.componentType === "SearchList") ?
                                        <div
                                            style={{backgroundColor: itemData.isItemDescriptionDiff ? compareColor : ""}}>
                                            <span
                                                style={{fontWeight: 600,}}>{itemData.question}
                                            </span>{`=DDL [${itemData.codelists?.map(e => e.label).join([', '])}]`}
                                        </div> :
                                        <span
                                            style={{backgroundColor: itemData.isItemDescriptionDiff ? compareColor : ""}}>
                                            itemData.comment.replaceAll('<br/>', '\n')
                                        </span>}
                        </pre>
                        </div>
                    )}
                </div>
            )
        } else { // 뉴로 바이오젠 외의 모든 디자인들
            // [Landscape 표 형태] logDirection이 Landscape 이고 repeating Yes 일 때, item 의 question 없이 item 의 value값만 출력
            if (itemData?.logInfo?.logDirection === 'Landscape' && (itemData?.logInfo?.repeating === "Yes" || itemData?.logInfo?.repeating === undefined)) {
                return (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: itemData.enabled == 1 && itemData.type == 'Collected' && itemData.source == 'CRF' ? "bold" : "",
                    }}>
                        {getItemComp()}
                    </div>
                )
            } else { // [일반 폼 형태] landscape 조건에 해당하지 않는 나머지 경우에는 question, value 모두 출력
                return (
                    <div style={{breakInside: 'avoid'}}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            border: "1px solid #000000",
                            fontSize: "9pt",
                            marginTop: "-1px",
                            breakInside: "avoid",
                            overflowWrap: "anywhere",
                            fontWeight: itemData.enabled == 1 && itemData.type == 'Collected' && itemData.source == 'CRF' ? "bold" : "",
                        }}>
                            {/*item의 question 영역*/}
                            <div style={{
                                display: "flex",
                                borderRight: "1px solid #000000",
                                flex: 1,
                            }}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    padding: "10px",
                                    fontFamily: "Arial, sans-serif",
                                }}>
                                    <span>
                                        {itemData.question && itemData.question.includes('<br/>') ?
                                            <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                                <pre style={{
                                                    whiteSpace: "pre-wrap",
                                                    fontSize: "9pt",
                                                    fontFamily: "Arial, sans-serif"
                                                }}>
                                                    {/* compareCRF에서 출력 */}
                                                    {itemData.designVisitDiffList?.map((visitObj) => (
                                                        <span style={{color: 'red'}}>
                                                            <span style={{
                                                                backgroundColor: visitObj.highlightAll ? compareColor : ""
                                                            }}>
                                                                {visitObj.key === "" ? <></> :
                                                                    <span>({visitObj.key}&nbsp;:&nbsp;</span>}
                                                                {visitObj.highlightAll ?
                                                                    visitObj.values.map((value) => (
                                                                        <span style={{
                                                                            backgroundColor: compareColor
                                                                        }}>{value !== undefined ? <>({value})&nbsp;</> : <></>}</span>
                                                                    )) :
                                                                    <><>{visitObj.key === "" ? <>(</> : <></>}</>
                                                                        {visitObj.values.map((valueObj, index, {length}) => (
                                                                            <>
                                                                        <span style={{
                                                                            backgroundColor: !visitObj.highlightAll && valueObj.highlight ? compareColor : ""
                                                                        }}>{valueObj?.value}</span>
                                                                                <span>{index + 1 === length ? "" : ", "}</span>
                                                                            </>
                                                                        ))})</>
                                                                }
                                                            </span>
                                                    </span>
                                                    ))}

                                                    {/* crf form에서 출력 */}
                                                    {itemData.designVisitDiffList === undefined && itemData.designVisit?.map((each) => {
                                                        if (each) {
                                                            return (
                                                                <span style={{color: 'red'}}>({each})&nbsp;</span>);
                                                        }
                                                    })}
                                                    <span
                                                        style={{backgroundColor: itemData.isQuestionDiff ? compareColor : ""}}>
                                                        {itemData.question.replaceAll('<br/>', '\n')}
                                                        {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                                    </span>
                                                </pre>
                                            </div> :
                                            <>
                                                {/* compareCRF에서 출력 */}
                                                {itemData.designVisitDiffList?.map((visitObj) => (
                                                    <span style={{color: 'red'}}>
                                                            <span style={{
                                                                backgroundColor: visitObj.highlightAll ? compareColor : ""
                                                            }}>
                                                                {visitObj.key === "" ? <></> :
                                                                    <span>({visitObj.key}&nbsp;:&nbsp;</span>}
                                                                {visitObj.highlightAll ?
                                                                    visitObj.values.map((value) => (
                                                                        <span style={{
                                                                            backgroundColor: compareColor
                                                                        }}>{value !== undefined ? <>({typeof value === 'object' ? value.value : value})&nbsp;</> : <></>}</span>
                                                                    )) :
                                                                    <><>{visitObj.key === "" ? <>(</> : <></>}</>
                                                                        {visitObj.values.map((valueObj, index, {length}) => (
                                                                            <>
                                                                        <span style={{
                                                                            backgroundColor: !visitObj.highlightAll && valueObj.highlight ? compareColor : ""
                                                                        }}>{valueObj?.value}</span>
                                                                                <span>{index + 1 === length ? "" : ", "}</span>
                                                                            </>
                                                                        ))})</>
                                                                }
                                                            </span>
                                                    </span>
                                                ))}

                                                {/* crf form에서 출력 */}
                                                {itemData.designVisitDiffList === undefined && itemData.designVisit?.map((each) => {
                                                    if (each) {
                                                        return (<span style={{color: 'red'}}>({each})&nbsp;</span>);
                                                    }
                                                })}
                                                <span
                                                    style={{backgroundColor: itemData.isQuestionDiff ? compareColor : ""}}>
                                                    {itemData.question}
                                                    {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                                </span>
                                            </>
                                        }
                                    </span>
                                </div>
                            </div>

                            {/*item의 value 영역*/}
                            <div style={{
                                display: "flex",
                                borderBottom: "0px",
                                borderTop: "0px",
                                borderLeft: "0px",
                                borderRight: "0px",
                                flex: 1,
                            }}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                }}>
                                    {getItemComp()}
                                </div>
                            </div>
                        </div>

                        {/*item의 description 영역*/}
                        {(itemData.hasOwnProperty("comment") && itemData.comment !== "" && itemData.comment !== undefined && itemData.comment !== null) && (
                            <div
                                style={{
                                    border: "1px solid #000",
                                    marginTop: "-1px",
                                    backgroundColor: "#F2F2F2",
                                }}>
                                <div style={{
                                    fontSize: "9pt",
                                    fontFamily: "Arial, sans-serif",
                                    whiteSpace: "pre-wrap",
                                    marginTop: "-1px",
                                    padding: "10px",
                                }}>
                                    <span style={{
                                        fontWeight: 600,
                                        backgroundColor: itemData.isItemDescriptionDiff ? compareColor : "",
                                    }}>{itemData.comment?.replaceAll('<br/>', '\n')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }
    }, [getItemComp, itemData]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {getItemLayout()}
        </>
    );
};

export default React.memo(ACRFItem);