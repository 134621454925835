import React, {useCallback, useState} from "react";
import ACRFEditModal from "./ACRFEditModal";
import {SEND_ANNOTATION_INFO} from "./ACRFMain";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";
import ACRFItem2 from "./ACRFItem2";
import {getSessionState} from "../../../../../common/commonFunction";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFItemGroup2
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Item Group 컴포넌트
 */
const ACRFItemGroup2 = (props) => {
    /**
     *  @memberOf     ACRFItemGroup2
     *  @type         {Object} props
     *  @property     {Object} itemGroupData - Item Group 정보
     *  @property     {Boolean} isEdit - acrf의 edit 구분 값(false: view, true: edit)
     *  @property     {Array} domainList - Form에 해당하는 Domain 리스트
     *  @property     {Function} onChangeDataList - annotation 추가, 수정, 삭제시 실행될 상위 이벤트 함수
     *  @property     {String} formID - 포함 된 form의 ID
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @property     {Array} itemGroupVisit - itemGroupVisit 정보
     *  @property     {Array} armList - arm 정보
     *  @property     {Array} formVisit - formVisit 정보
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        itemGroupData,
        isEdit,
        domainList,
        onChangeDataList,
        formID,
        formMode,
        itemGroupVisit,
        armList,
        formVisit
    } = props;

    /**
     *  @memberOf     ACRFItemGroup2
     *  @type         Object} getSessionState
     *  @property     String} designID - design ID
     *  @description  session 에서 받아오는 정보
     */
    const {designID} = getSessionState();

    /**
     *  @memberOf     ACRFItemGroup2
     *  @var          {Boolean} isModal
     *  @description  ACRF edit 팝업 오픈 여부
     */
    const [isModal, setIsModal] = useState(false);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @var          {Array} currentAnnotation
     *  @description  선택 된 Annotation 정보
     */
    const [currentAnnotation, setCurrentAnnotation] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     getStudyEventName
     *  @description  특정 디자인의 특정 폼("Blood samplings for PD analysis")의 study event name 가져오기 (서울대 MAD 과제에만 해당하는 함수)
     */
    const getStudyEventName = useCallback(() => {
        if (itemGroupData.designID === "DEGN000000000100" && itemGroupData.formID === "CRFM000000011021" && itemGroupData.name === "Blood samplings for PD [Pre, Dosing 0 Hour]") {
            return (
                <h1
                    style={{
                        paddingLeft: "10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                    }}>
                    1d, 7d
                </h1>
            )
        } else if (itemGroupData.designID === "DEGN000000000100" && itemGroupData.formID === "CRFM000000011021" && itemGroupData.name === "Blood Collection (PD)") {
            return (
                <h1
                    style={{
                        padding: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                        marginTop: "32px"
                    }}>
                    2d, 8d
                </h1>
            )
        }
    }, [itemGroupData]);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     getStudyEventName2
     *  @description  특정 디자인의 특정 폼("Vital Signs [Log]")의 study event name 가져오기 (서울대 MAD 과제에만 해당하는 함수)
     *  @description  "Vital Signs [Pre, Dosing 0 Hour]"이라는 itemgroup의 중복으로 study event name 을 구분할 수 없어서 해당 폼에서는 study event name을 나눠서 가져옴
     */
    const getStudyEventName2 = useCallback(() => {
        if (itemGroupData.designID === "DEGN000000000100" && itemGroupData.formID === "CRFM000000011052" && itemGroupData.name === "Vital Signs Interpretation [Post, Dosing 12 Hours]") {
            return (
                <h1
                    style={{
                        padding: "10px",
                        fontSize: "9pt",
                        fontFamily: "Arial, sans-serif",
                        marginTop: "32px"
                    }}>
                    2d, 3d, 4d, 5d, 6d
                </h1>
            )
        }
    }, [itemGroupData]);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     getItemGroup
     *  @description  ACRF의 해당하는 ItemGroup 그려주는 함수
     */
    const getItemGroup = useCallback(() => {
        //특정 디자인만 assessment명 을 보여준다.
        if (designID === "DEGN000000000100" || designID === "DEGN000000000093") {
            return (<div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "0px solid #000000",
                    borderWidth: " 1px 1px 0px 1px",
                    background: "#E6E6E7",
                    padding: "5px 0"
                }}>

                <div
                    style={{
                        padding: "10px",
                        flexDirection: "column",
                    }}>
                    {/*itemGroup name 영역*/}
                    <h1
                        style={{
                            fontSize: "9pt",
                            flex: 1,
                            alignSelf: "center",
                            margin: "0 0 0 10px",
                            fontFamily: "Arial, sans-serif"
                        }}>
                <span
                    style={{border: itemGroupData.hasOwnProperty("isDiff") && itemGroupData.isDiff ? "1px solid #ff0000" : ""}}>
            {itemGroupData.name}
                </span>
                    </h1>

                    {formMode !== FORM_COMPARE && (
                        <div>
                            {/*{isEdit && (*/}
                            {/*    <img*/}
                            {/*        src={addIcon}*/}
                            {/*        onClick={handleAdd}*/}
                            {/*        style={{*/}
                            {/*            marginLeft: "5px",*/}
                            {/*            paddingRight: "15px",*/}
                            {/*            height: "1rem",*/}
                            {/*            alignSelf: "center"*/}
                            {/*        }}*/}
                            {/*        alt=""/>*/}
                            {/*)}*/}

                            {/*itemGroup의 annotation 영역*/}
                            {/*{itemGroupData.annotations.length > 0 && (*/}
                            {/*    <div style={{flex: 1, alignSelf: "center"}}>*/}
                            {/*        <AnnotationComponent*/}
                            {/*            annotationList={itemGroupData.annotations}*/}
                            {/*            onClick={handleClick}*/}
                            {/*            onDelete={handleDelete}*/}
                            {/*            isEdit={isEdit}/>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    )}
                </div>
            </div>)
        }
    }, [designID, formMode, itemGroupData]);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 상위로 annotation 정보 전달하는 함수
     */
    const changeDataList = useCallback((data) => {
        if (onChangeDataList !== undefined) {
            onChangeDataList(data);
        }
    }, [onChangeDataList]);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     handleSave
     *  @param        {Object} data - Annotation 정보
     *  @param        {String} dataType - 추가, 수정, 삭제 구분
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback((data, dataType) => {
        data.path = `${formID}/${itemGroupData.ID}`; //포함 된 Form ID, ItemGroup ID를 path key에 추가
        changeDataList(data); //상위로 추가, 수정 된 annotation 정보 전달

        if (dataType === "add") {
            SEND_ANNOTATION_INFO[dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가
        } else if (dataType === "update") {
            //annotaion ID가 CRAN이나 CRIT로 시작하지 않으면 저장하지 않은 상태의 annotation
            if (!data.ID.startsWith("CRAN") && !data.ID.startsWith("CRIT")) { //테이블에 저장 되어있지 않은 annotation 이라면
                let findIdx = SEND_ANNOTATION_INFO.add.findIndex(sData => sData.ID === data.ID); //서버에 데이터 보내는 전역변수의 add에서 ID를 비교하여 index 찾아 저장

                if (findIdx > -1) {
                    SEND_ANNOTATION_INFO.add[findIdx] = data; //add에 찾은 index로 data 저장
                }
            } else { //테이블에 저장 되어있던 annotation이라면
                SEND_ANNOTATION_INFO[dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가
            }
        }

        setIsModal(false);
        setCurrentAnnotation({}); //선택했던 annotation 정보 초기화
    }, [changeDataList, formID, itemGroupData.ID]);

    /**
     *  @memberOf     ACRFItemGroup2
     *  @function     getItemComp
     *  @return       {Element} Item Data render
     *  @description  ACRF의 해당하는 Item 그려주는 함수
     */
    const getItemComp = useCallback(() => {
        return itemGroupData.items.map((data, idx) => (
            <ACRFItem2
                key={idx}
                itemData={data}
                isEdit={isEdit}
                domainList={domainList}
                onChangeDataList={changeDataList}
                formID={formID}
                itemGroupID={itemGroupData.ID}
                formMode={formMode}
                itemGroupVisit={itemGroupVisit}
                armList={armList}
                formVisit={formVisit}/>
        ));
    }, [itemGroupData.items, itemGroupData.ID, isEdit, domainList, changeDataList, formID, formMode, itemGroupVisit, armList, formVisit]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div>
            {/*Study Event 출력 (서울대 MAD 과제: 해당하는 디자인의 특정 form의 ID 에만 적용*/}
            {getStudyEventName()}

            {/*특정 디자인 itemGroup 보이기*/}
            {getItemGroup()}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}/>

            {/*item group의 description 영역*/}
            {itemGroupData.hasOwnProperty("description") && itemGroupData.description !== "" && itemGroupData.description !== null && (
                <div
                    style={{
                        border: "1px solid #000000",
                        wordBreak: "break-word",
                        background: "#F2F2F2",
                        breakInside: 'avoid',
                        padding: "5px",
                        marginTop: "-1px",
                        fontWeight: "bold"
                    }}>
                    <div
                        style={{
                            fontSize: "9pt",
                            fontFamily: "Arial, sans-serif",
                            whiteSpace: "pre-wrap"
                        }}>
                        {itemGroupData?.description?.replaceAll('<br/>', '\n')}
                    </div>
                </div>
            )}

            {/*Item 출력*/}
            {getItemComp()}

            {/*Study Event 출력 (서울대 MAD 과제: 해당하는 디자인의 특정 form의 ID 에만 적용*/}
            {getStudyEventName2()}

            {isModal && (
                <ACRFEditModal
                    onClose={() => setIsModal(false)}
                    onSave={handleSave}
                    type={'ITEMGROUP'}
                    data={itemGroupData}
                    domainList={domainList}
                    annotationInfo={currentAnnotation}/>
            )}
        </div>
    );
};

export default React.memo(ACRFItemGroup2);