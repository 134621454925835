import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {AXIOS_PUT, getSessionState} from "../../../../../../common/commonFunction";
import useWindowSize from "hooks/useWindowSize";
import {COMMAND} from "../../../../../../common/dataProcessAgent";
import {SDTM_MAPPING_URL} from "../../../../../../constant/ConstantURL";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import useToast from "../../../../../../hooks/useToast";
import cn from "classnames";
import {FILL_REQUIRED_FIELD} from "../../../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       SdmArmEdit
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {ID, sendObject} = params;
    let url = null;
    let response = null;

    switch (command) {
        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${SDTM_MAPPING_URL}/SDM/studyArm/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    SdmArmEdit
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  [SDTM > Mapping > Variables] TA DataSet의 SDM Edit Modal에서 Arm 탭 편집 컴포넌트
 */
const SdmArmEdit = (props) => {
    /**
     *  @memberOf     SdmArmEdit
     *  @type         {Object} props
     * @property      {String} ID - 리스트 ID
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Function} onClose - 현재 Modal 닫기 위한 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {ID, history , onClose} = props;
    /**
     *  @memberOf      SdmArmEdit
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock} = getSessionState();

    /**
     *  @memberOf       SdmArmEdit
     *  @var            {*} netWorkAgent
     *  @description   통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     SdmArmEdit
     *  @var          {HTMLDivElement} gridRef
     *  @description  AUI grid ref 영역
     */
    const gridRef = useRef(null);

    /**
     *  @memberOf       SdmArmEdit
     *  @var            {Function} showToast
     *  @description    toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     SdmArmEdit
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     SdmArmEdit
     *  @var          {Array} dataList
     *  @description  화면에 표시 될 리스트
     */
    const [dataList, setDataList] = useState();

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     SdmArmEdit
     *  @function     getList
     *  @description  상세 정보 api 호출
     */
    const getList = useCallback(() => {
        const command = COMMAND.DATA_LIST; // 데이터 리스트 요청 command
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/SDM/studyArm/${ID}`
        };
        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID]);

    /**
     *  @memberOf     SdmArmEdit
     *  @function     handleChange
     *  @param        {String} dataField - 해당 field name
     *  @param        {Number} rowIndex - 해당 row index 값
     *  @param        {String} value - 입력 값
     *  @description  화면에 보여질 리스트 갯수 변경 시 실행되어 data list 조회 api 호출
     */
    const handleChange = useCallback((dataField, rowIndex, value) => {
        let changeCodeDataList = dataList;
        changeCodeDataList[rowIndex][dataField] = value;

        setDataList(changeCodeDataList);
    }, [dataList]);

    /**
     *  @memberOf     SdmArmEdit
     *  @function     handleClose
     *  @description  닫기 버튼 클릭 시 처리 사항
     */
    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     SdmArmEdit
     *  @function     validateData
     *  @return       {Boolean} validation 값 (true - validation success, false - validation fail)
     *  @description  입력 된 값들에 대한 validation 처리 함수
     */
    const validateData = useCallback(() => {
        return window.AUIGrid.validateGridData(gridRef.current, ["armName", "armCode"], FILL_REQUIRED_FIELD);
    }, []);

    /**
     *  @memberOf     SdmArmEdit
     *  @function     handleSave
     *  @description  저장 버튼 클릭 시 처리 되는 함수.
     */
    const handleSave = useCallback(() => {
        if (lock !== 2) {
            // 데이터 validation
            const valid = validateData();

            if (valid === false) {
                return;
            }

            const armObject = dataList.map(arm => {
                return {
                    ID: arm.ID,
                    groupName: arm.groupName,
                    armName: arm.armName,
                    armCode: arm.armCode,
                    type: arm.type,
                    randomizationGroup: arm.randomizationGroup
                };
            });
            const sendObject = {arm: armObject};

            const command = COMMAND.DATA_UPDATE; // 데이터 수정 요청
            const params = {
                requestUrl: `${SDTM_MAPPING_URL}/SDM/studyArm`,
                ID: ID,
                sendObject: JSON.stringify(sendObject)
            }; // 데이터 수정에 필요한 parameter

            netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
        }
    }, [ID, validateData, dataList, lock]);

    /**
     *  @memberOf     SdmArmEdit
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                case  COMMAND.DATA_LIST :
                    // list 데이터가 있는 경우
                    if (data.hasOwnProperty('data')) {
                        setDataList(data.data);
                    }
                    break;

                case COMMAND.DATA_UPDATE :
                    if (data.code === 1) {
                        showToast(getList());
                    }
                    break;

                //no default
            }
        }
    }, [getList, showToast]);


    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // grid 데이터 setting 작업
    useLayoutEffect(() => {
        const auiGridProps = {
            showRowNumColumn: true,           // 행번호 칼럼을 보여줄지 여부(no 표시)
            height: 665,
            enableCellMerge: true,             //칼럼 셀 병합(cell merge) 가능 여부
            cellMergeRowSpan: true,            //셀 세로 병합을 하는 경우(enableCellMerge=true) 실제 rowspan 처리 하여 보일지 여부(default = true)
            enableHScrollByWheel: false,       //수평 스크롤에 반응할지 여부
            editable: lock !== 2,                   //Edit 여부
            showEditorBtnOver: true,
            editBeginMode: "click",
            headerHeights: [45]
        };

        gridRef.current = window.AUIGrid.create("#sdmArmEdit_grid_wrap", COLUMN_LIST, auiGridProps);

        return () => {
            window.AUIGrid.destroy("#sdmArmEdit_grid_wrap");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        window.AUIGrid.resize(gridRef.current, '100%');
    }, [width, height]);

    // 상세 데이터 요청
    useEffect(() => {
        getList();
    }, [getList]);

    // AUIGrid 생성
    useEffect(() => {
        if (dataList !== undefined && dataList.length > 0) {
            window.AUIGrid.setGridData(gridRef.current, dataList);
        } else {
            window.AUIGrid.showInfoMessage(gridRef.current, "<div class='data-center'><div><i class=\"fas fa-exclamation-circle\"></i><span>No Data</span></div></div>");
        }
    }, [dataList]);

    useEffect(() => {
        //cell edit 이벤트
        window.AUIGrid.bind(gridRef.current, "cellEditEnd", (event) => {
            if (handleChange !== undefined) {
                handleChange(event.dataField, event.rowIndex, event.value);
            }
        });
    }, [handleChange]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(gridRef.current, '100%');
    }, [width, height, gridRef]);

    /**
     *  @memberOf     SdmArmEdit
     *  @constant     {Array} COLUMN_LIST
     *  @description  AUIGrid Column 정보
     */
    const COLUMN_LIST = [
        {
            dataField: "groupName",
            headerText: "Group Name",
            editable: false
        },
        {
            dataField: "type",
            headerText: "Type",
            editable: lock !== 2,
            editRenderer: { // 셀 자체에 드랍다운리스트 출력하고자 할 때
                type: "ComboBoxRenderer",
                list: ["", "Investigational Arm", "Comparator Arm", "Placebo Arm", "Observational Arm"]
            }
        },
        {
            dataField: "armName",
            headerText: "Arm Name",
            editable: lock !== 2,
            editRenderer: {
                type: " InputEditRenderer",
                maxlength: 40
            }
        },
        {
            dataField: "armCode",
            headerText: "Arm Code<span class=\"ml-1 text-danger\">*</span>",
            editable: lock !== 2,
            editRenderer: {
                type: " InputEditRenderer",
                maxlength: 20
            }
        },
        {
            dataField: "randomizationGroup",
            headerText: "Randomization group",
            editable: lock !== 2
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className='grid-style' id="sdmArmEdit_grid_wrap"/>
            <div className="modal-button">
                <div className="d-flex justify-content-end p-10 border-top">
                    <button
                        className="btn btn-blue btn-inner-shadow px-3 px-md-5 mx-1"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>

    );
};

export default React.memo(SdmArmEdit);