import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AUIGrid from "../../../../../../imtrial/components/AUIGrid";
import NetworkLayout from "../../../../../../common/NetworkLayout";
import {COMMAND} from "../../../../../../common/dataProcessAgent";
import {AXIOS_GET, getSessionState} from "../../../../../../common/commonFunction";
import {DESIGN_CRITERIA_URL, DESIGN_MAIN_URL} from "../../../../../../constant/ConstantURL";
import {showConfirmAlert} from "../../../../../components/alertModal";
import {SAME_CRITERIA_VERSION} from "../../../../../../constant/ConstantMsg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     CriteriaVersionModal
 *  @constant     {Array} COLUMN_LIST
 *  @description  AUIGrid Column 정보
 */
const COLUMN_LIST = {
    ID: "grid",
    type: "grid",
    columnList: [
        {
            dataField: "IETESTCD",
            headerText: "IETESTCD",
            width: "15%",
        },
        {
            dataField: "IETEST",
            headerText: "IETEST",
            renderer: {
                type: "TemplateRenderer"  // HTML 템플릿 렌더러 사용
            },
        },
        {
            dataField: "highlight",
            headerText: "highlight",
            width: "0%"
        },
    ],
}

/**
 *  @memberOf     CriteriaVersionModal
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl} = params;
    let url = null;
    let response = null;

    switch (command) {
        case COMMAND.DATA_LIST:
            url = requestUrl;
            response = await AXIOS_GET(url);
            break;
        case 'CRITERIA_DATA_LIST':
            url = requestUrl;
            response = await AXIOS_GET(url);
            break;
        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       김성연
 *  @version      1.0
 *  @component    CriteriaVersionModal
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Add -> another version of criteria 클릭시 표시되는 modal
 */
const CriteriaVersionModal = (props) => {

    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf     CriteriaVersionModal
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @property     {Object} onClose - 모달창을 닫기 위해 사용
     *  @property     {Object} setNewDataList - 부모 컴포넌트에 데이터를 보내주기 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history, onClose, setNewDataList} = props;

    /**
     *  @memberOf     CriteriaVersionModal
     *  @type         {Object} getSessionState
     *  @property     {String} designID - 디자인 ID
     *  @description  session 에서 받아오는 정보
     */
    const {designID} = getSessionState();

    /**
     *  @memberOf     CriteriaVersionModal
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @var          {Array} versionList
     *  @description  현재 디자인의 모든 버전 정보 리스트
     */
    const [versionList, setVersionList] = useState();

    /**
     *  @memberOf     CriteriaVersionModal
     *  @var          {Array} criteriaDataList
     *  @description  가져온 모든 데이터 저장하는 리스트 (한 번이라도 가져온 리스트는 여기에 저장해두고 꺼내서 씀)
     */
    const [criteriaDataList, setCriteriaDataList] = useState([]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @var          {Array} checkBoxArray
     *  @description  각 체크박스의 체크 상태를 기록하는 배열 
     */
    const [checkBoxArray, setCheckBoxArray] = useState([]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @var          {Array} criteriaPostData
     *  @description  AUIGrid에 렌더링할 배열
     */
    const [criteriaPostData, setCriteriaPostData] = useState([]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     getDataList
     *  @description  리스트 요청 함수. data list 조회 api 호출
     */
    const getDataList = useCallback(() => {
        const command = COMMAND.DATA_LIST;
        const params = {
            requestUrl: `${DESIGN_MAIN_URL}/version/${designID}`,
        };

        netWorkAgent.current.request(command, params);
    }, [designID]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     getCriteriaDataList
     *  @param        {String} ID - designID 값
     *  @param        {Number} idx - criteriaDataList에 삽입할 인덱스
     *  @description  특정 버전의 criteria 데이터 리스트 불러오기
     */
    const getCriteriaDataList = useCallback((ID, idx) => {
        const command = 'CRITERIA_DATA_LIST';
        const params = {
            requestUrl: `${DESIGN_CRITERIA_URL}/${ID}`,
            index: idx,
        };

        netWorkAgent.current.request(command, params);
    }, []);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command, params} = action;

            switch (command) {
                case COMMAND.DATA_LIST:
                    setVersionList(data.data.reverse()); // api가 버전이 내림차순으로 되어있어서 뒤집음
                    getCriteriaDataList(designID, data.data.length - 1); // 초기값으로 가장 최신 버전값을 criteriaList에 넣어줌
                    break;
                case 'CRITERIA_DATA_LIST':
                    const _criteriaDataList = criteriaDataList.slice();
                    _criteriaDataList[params.index] = data.data;
                    setCriteriaDataList(_criteriaDataList);
                    break;
                //no default
            }
        }
    }, [getCriteriaDataList, criteriaDataList, designID]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     handleClose
     *  @description  현재 모달창을 닫는 함수
     */
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose();
        }
    }, [onClose]);

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     getCriteriaDataList
     *  @param        {Object} event - event 리스너
     *  @param        {Number} idx - checkBoxArray를 변경하기 위한 인덱스 값
     *  @description  특정 버전의 criteria 데이터 리스트 불러오기
     */
    const handleCheckBoxClick = useCallback((event, idx) => {
        const _checkBoxArray = checkBoxArray.slice();
        _checkBoxArray[idx] = !_checkBoxArray[idx];

        // 이미 가져온 값이면 ajax call 생략
        if (_checkBoxArray[idx] && !criteriaDataList[idx]) {
            getCriteriaDataList(versionList[idx].ID, idx);
        }
        setCheckBoxArray(_checkBoxArray);
    }, [getCriteriaDataList, criteriaDataList, checkBoxArray, versionList])

    /**
     *  @memberOf     CriteriaVersionModal
     *  @function     handleClickOK
     *  @description  OK 버튼 클릭시 실행되는 함수, 부모 컴포넌트로 새로운 데이터값 전달함
     */
    const handleClickOK = useCallback(() => {
        setNewDataList(criteriaPostData);
        handleClose();
    }, [criteriaPostData, setNewDataList, handleClose]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 데이터 리스트 요청
    useEffect(() => {
        getDataList();
    }, [getDataList]);

    // 체크박스 상태 기록
    useEffect(() => {
        if (versionList) {
            const _checkBoxArray = checkBoxArray.slice();
            _checkBoxArray[versionList.length - 1] = true;
            setCheckBoxArray(_checkBoxArray);
        }
        // eslint-disable-next-line
    }, [versionList])


    useEffect(() => {
        const _inclusionArray = [];
        const _exclusionArray = [];
        let isFirst = true;

        // 최신버전과 체크된 버전의 원소를 비교해서 같은 값을 가지고 있다면 그대로 추가, 다른 값이면 하이라이트 표시
        criteriaDataList.forEach((parentEl, parentIdx) => {
            // 최신 버전일 경우 escape
            if (parentIdx === criteriaDataList.length - 1) {
                return false;
            }
            // 체크박스에 체크가 안된 경우 escape
            if (!checkBoxArray[parentIdx]) {
                return false;
            }

            parentEl.inclusion.forEach((childEl, childIdx) => {
                let _isDuplicate = false;
                criteriaDataList[criteriaDataList.length - 1].inclusion.forEach((latestVer) => {
                    if (latestVer.name === childEl.name) {
                        _isDuplicate = true;
                    }
                })
                _inclusionArray.push({
                    "STUDYID": "",
                    "DOMAIN": "TI",
                    "IETESTCD": `IN${childIdx + 1 < 10 ? `0${childIdx + 1}`: childIdx + 1}${isFirst ? '' : '_' + versionList[parentIdx].version}`,
                    "IETEST": childEl.name,
                    "IECAT": "INCLUSION",
                    "TIVERS": versionList[parentIdx].version,
                    "highlight": !_isDuplicate,
                })
            })

            parentEl.exclusion.forEach((childEl, childIdx) => {
                let _isDuplicate = false;
                criteriaDataList[criteriaDataList.length - 1].exclusion.forEach((latestVer) => {
                    if (latestVer.name === childEl.name) {
                        _isDuplicate = true;
                    }
                })
                _exclusionArray.push({
                    "STUDYID": "",
                    "DOMAIN": "TI",
                    "IETESTCD": `EX${childIdx + 1 < 10 ? `0${childIdx + 1}`: childIdx + 1}${isFirst ? '' : '_' + versionList[parentIdx].version}`,
                    "IETEST": childEl.name,
                    "IECAT": "EXCLUSION",
                    "TIVERS": versionList[parentIdx].version,
                    "highlight": !_isDuplicate,
                })
            })
            isFirst = false;
        })

        // 최신버전과 동일하다면 알림창 표시 및 해당 버전 제거
        let _mergedArray = _inclusionArray.concat(_exclusionArray);
        checkBoxArray.forEach((e, i) => {
            if (i === checkBoxArray.length - 1) {
                return false;
            }
            if (e){
                const _filter = _mergedArray.filter(e => e.TIVERS === versionList[i].version);
                if (_filter.length !== 0 && _filter.every(e => e.highlight === false)) {
                    const _checkBoxArray = checkBoxArray.slice();
                    _checkBoxArray[i] = false;
                    setCheckBoxArray(_checkBoxArray);
                    _mergedArray = _mergedArray.filter(e => e.TIVERS !== versionList[i].version);
                    showConfirmAlert(SAME_CRITERIA_VERSION);
                }
            }
        })
        setCriteriaPostData(_mergedArray);
    }, [criteriaDataList, checkBoxArray, versionList])

    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <Modal isOpen style={{maxWidth: "1000px"}}>

                <ModalHeader
                    className={'header-title bg-main'}
                    toggle={handleClose}>
                    Add another version of criteria
                </ModalHeader>

                <div className='row px-3'>
                    <div className='col-3 p-30' >
                        <h3 className='pb-2'>Version List</h3>
                        {versionList?.map((each, idx) => (
                            <div
                                key={each.version}
                                className="form-check"
                                onClick={(evt) => {
                                    if (idx + 1 !== versionList.length) {
                                        handleCheckBoxClick(evt, idx);
                                    }
                                }}
                                style={{userSelect: "none"}}
                            >
                                <input
                                    type="checkbox"
                                    checked={idx === versionList.length - 1 ? true : checkBoxArray[idx]} // Version List의 마지막 값은 항상 선택된 채로 비활성화
                                    disabled={idx === versionList.length - 1 ? true : false} // Version List의 마지막 값은 항상 선택된 채로 비활성화
                                    className="form-check-input"
                                />
                                <label className="form-check-label h4">Version {each.version}</label>
                            </div>
                        ))}
                    </div>

                    <ModalBody className='col-9 border-left'>
                        <div className="row mx-n2" style={{height: "50vh"}} id={"customModal"}>
                            <div className="col-sm-12 m-5">
                                <AUIGrid
                                    id={COLUMN_LIST.ID}
                                    columnList={COLUMN_LIST.columnList}
                                    dataList={criteriaPostData}
                                    isAutoGridHeight={true}
                                    rowStyle={
                                        (rowIndex, item) => {
                                            if (item.highlight) {
                                                return "aui-grid-header-green";
                                            }
                                            return null;
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </ModalBody>
                </div>

                <ModalFooter className="p-3">
                    <button
                        className="btn btn-blue"
                        onClick={handleClose}>
                        Cancel
                    </button>

                    <button
                        className="btn btn-new"
                        onClick={() => {
                            handleClose();
                            handleClickOK();
                        }}>
                        Ok
                    </button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default CriteriaVersionModal;