export let BASE_URL = 'http://dev.api.imtrial.com';

if (window.location.hostname === "localhost") {
    BASE_URL = 'http://dev.api.imtrial.com';
} else if (window.location.hostname === "qa.imtrial.com") {
    BASE_URL = 'https://qa-api.imtrial.com';
} else if (window.location.hostname === "app.imtrial.com") {
    BASE_URL = 'https://app-api.imtrial.com';
}

// login
export const LOGIN_URL = `${BASE_URL}/auth`;

// Master
export const SERVICE_URL = `${BASE_URL}/master/service`;
export const PARTNER_URL = `${BASE_URL}/master/partner`;
export const COUNTRY_URL = `${BASE_URL}/master/country`;
export const USER_URL = `${BASE_URL}/master/user`;
export const USER_GROUP_URL = `${BASE_URL}/master/usergroup`;
export const ROLE_URL = `${BASE_URL}/master/role`;
export const STANDARD_URL = `${BASE_URL}/master/standard`;
export const TRAINING_URL = `${BASE_URL}/master/training`;
export const ALARM_URL = `${BASE_URL}/master/alarm`;
export const SUPPORT_URL = `${BASE_URL}/master/support`;
export const LOG_URL = `${BASE_URL}/master/log`;
export const SESSION_URL = `${BASE_URL}/master/session`;
export const SDTM_URL = `${BASE_URL}/master/sdtm`;

export const CONCEPT_URL = `${BASE_URL}/mdr/concept`;
export const DESIGN_MAP_URL = `${BASE_URL}/mdr/design`;

export const TERMINOLOGY_URL = `${BASE_URL}/mdr/terminology`;
export const METADATA_URL = `${BASE_URL}/mdr/metadata`;
export const MAP_URL = `${BASE_URL}/mdr/ta`;
export const CODELIST_URL = `${BASE_URL}/mdr/codelist`;
export const CUSTOMCODELIST_URL = `${BASE_URL}/mdr/custom`;
export const CRITERIA_URL = `${BASE_URL}/mdr/ta/meta/ta`;


export const OBJECT_URL = `${BASE_URL}/am/object`;
export const OBJECT_GROUP_URL = `${BASE_URL}/am/objectgroup`;
export const ACTIVITY_URL = `${BASE_URL}/am/activity`;
export const TA_URL = `${BASE_URL}/am/ta`;
export const UPLOAD_URL = `${BASE_URL}/am/doc`;

export const IG_URL = `${BASE_URL}/mdr/ig`;
export const SDTMIG_URL = `${BASE_URL}/mdr/sdtmig`;

export const REFERENCE_GUIDE_URL = `${BASE_URL}/mdr/reference`;
export const SDTMFUNCTION_URL = `${BASE_URL}/mdr/metadata/mapping`;


// Admin
export const ADMIN_SERVICE_URL = `${BASE_URL}/admin/service`;
export const ADMIN_STANDARD_URL = `${BASE_URL}/admin/standard`;
export const ADMIN_USER_URL = `${BASE_URL}/admin/user`;
export const ADMIN_USERGROUP_URL = `${BASE_URL}/admin/usergroup`;
export const ADMIN_ROLE_URL = `${BASE_URL}/admin/role`;
export const ADMIN_LOG_URL = `${BASE_URL}/admin/log`;
export const ADMIN_SPONSOR_URL = `${BASE_URL}/admin/sponsor`;
export const ADMIN_EDC_URL = `${BASE_URL}/admin/edc`;


// template
export const TEMPLATE_URL = `${BASE_URL}/master/template`;
export const STUDY_INFO_URL = `${BASE_URL}/prm/study`;

export const MY_PRM_URL = `${BASE_URL}/prm/prm`;
export const PRM_MY_MODEL_URL = `${BASE_URL}/prm/model`;
export const PRM_MODEL_LIB_URL = `${BASE_URL}/prm/template`;

export const STRUCTURE_URL = `${BASE_URL}/prm/prm/:ID/model?type=prm`;

// Extra
export const EXTRA_SUPPORT_URL = `${BASE_URL}/user/support`;
export const EXTRA_ACCOUNT_URL = `${BASE_URL}/user/account`;
export const EXTRA_USERINFO_URL = `${BASE_URL}/user/account`;
export const EXTRA_ALARM_URL = `${BASE_URL}/extra/alarm`;
// export const EXTRA_TRAINING_URL = `${BASE_URL}/extra/training`;
export const EXTRA_TRAINING_URL = `${BASE_URL}/user/training`;

// study manager
export const STUDY_MANAGER_URL = `${BASE_URL}/study/study`;
export const STUDY_URL = `${BASE_URL}/study`;
export const DESIGN_URL = `${BASE_URL}/design`;
export const DESIGN_COMPARE_URL = `${BASE_URL}/design/design/compare`;
export const DESIGN_CRF_URL = `${BASE_URL}/design/crf`;
export const DESIGN_EXTENSION_URL = `${DESIGN_CRF_URL}/extension`
export const PRM_URL = `${BASE_URL}/design/prm`;

// Sdtm
export const SDTM_SDTM_URL = `${BASE_URL}/sdtm/sdtm`; //사용안함//
export const SDTM_PACKAGE = `${BASE_URL}/sdtm/package`;
export const SDTM_VERSION = `${BASE_URL}/sdtm/package`;
export const SDTM_POOLING_URL = `${BASE_URL}/sdtm/pooling`;
export const SDTM_CLINICAL_URL = `${BASE_URL}/sdtm/clinical`;
export const SDTM_MAPPING_URL = `${BASE_URL}/sdtm/mapping`;
export const SDTM_TABULATION_URL = `${BASE_URL}/sdtm/tabulation`;
export const SDTM_META_URL = `${BASE_URL}/sdtm/meta`;
export const SDTM_SDTM_2_URL = `${BASE_URL}/sdtm2/sdtm2`;
export const SDTM_2_ACRF_URL = `${BASE_URL}/sdtm2/acrf`;
export const SDTM_FILE_DOWN = `${BASE_URL}/sdtm2/sdtm2`;
export const SDTM_PDF_URL = `${BASE_URL}/sdtm/file`;
export const SDTM_DEFINE_URL = `${BASE_URL}/sdtm/define`;

// sdtm meta url
export const SDTM_META_STUDY = `${BASE_URL}/sdtm/meta/0/study`;

export const SDTM_META_CLINICAL = `${BASE_URL}/sdtm/meta/0/clinical`;
export const SDTM_META_SDTMIG = `${BASE_URL}/sdtm/meta/0/sdtmig`;

export const SDTM_CLINICAL_STUDY = `${BASE_URL}/sdtm/clinical`;
export const SDTM_CLINICAL_FILE = `${BASE_URL}/sdtm/file`;

// CRF
export const MY_CRF_URL = `${BASE_URL}/crf/crf`;
export const EDC_API_URL = `${BASE_URL}/crf/edc`;
export const CRF_PDF_URL = `${BASE_URL}/crf/pdf`;


// Design
export const DESIGN_MAIN_URL = `${BASE_URL}/design/design`;
export const DESIGN_SYNOPSIS_URL = `${BASE_URL}/design/synopsis`;
export const DESIGN_CRITERIA_URL = `${BASE_URL}/design/synopsis/criteria`;
export const DESIGN_SOA_URL = `${BASE_URL}/design/soa`;

//EDD
export const EDC_VERSIONLIST_URL = `${BASE_URL}/design/crf`;
export const EDC_CRFFORM_URL = `${BASE_URL}/design/crf/edc/rave/form`;
export const SDTM_EDC_STUDY = `${BASE_URL}/sdtm/clinical/rave`;
export const BOIM_PROJECTS = `${BASE_URL}/design/boim/projects`;
export const BOIM_BUILD = `${BASE_URL}/design/boim/build`;
export const BOIM_FORMLIST = `${BASE_URL}/design/boim/formList`;
export const BOIM_URL = `${BASE_URL}/design/boim`;
export const SDTM_EDC_BUILD = `${BASE_URL}/design/crf/rave/study`;
export const SDTM_EDC_FORM = `${BASE_URL}/design/crf/edc/rave/form`;
export const EDC_COMPARE_DOWNLOAD = `${BASE_URL}/design/crf/rave/compare`
