import React, {useCallback, useEffect, useState} from "react";
import ACRFStudyEvent from "./ACRFStudyEvent";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";

/*################################################################################*/
//## constant 관련
/*################################################################################*/

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFForm
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Form 컴포넌트
 */
const ACRFForm = (props) => {
    /**
     *  @memberOf     ACRFForm
     *  @type         {Object} props
     *  @property     {String} formID - Form List에서 선택 된 Form의 ID
     *  @property     {Object} formData - Form 정보
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {formID, formData, formMode, EDCType} = props;

    /**
     *  @memberOf     ACRFForm
     *  @var          {Boolean} isVisible
     *  @description  화면에 표시 할지 말지 정해주는 값(true만 화면에 보여줌)
     */
    const [isVisible, setIsVisible] = useState(false);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFForm
     *  @function     getStudyEventComp
     *  @return       {Element} StudyEvent Data render
     *  @description  ACRF의 해당하는 Study Event 그려주는 함수
     */
    const getStudyEventComp = useCallback(() => {
        return (
            <div style={{marginBottom: "20px"}}>
                <ACRFStudyEvent
                    formData={formData}
                    EDCType={EDCType}/>
            </div>
        );
    }, [formData, EDCType]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //Form List에서 선택한 폼만 보여주기 위한 설정값 변경
    useEffect(() => {
        if (formID === formData.ID || formMode === FORM_COMPARE) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [formData.ID, formID, formMode]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <div style={{
            pageBreakBefore: "always",
            display: isVisible ? "block" : "none"
        }}>
            {/*form name 영역*/}
            <h1
                id={formData.name.trim()}
                style={{
                    fontSize: "12pt",
                    fontWeight: "800",
                    alignSelf: "center",
                    paddingTop: "15px",
                    fontFamily: "Arial, sans-serif"
                }}>
                <span
                    style={{backgroundColor: formData.isFormDiff ? formData.compareColor : ""}}>
                    {formData.name}
                </span>
            </h1>

            {/*form의 description 영역*/}
            {formData.description !== "" && formData.description !== null && (
                <div
                    style={{
                        padding: "5px 10px",
                        border: "1px dotted #000",
                        wordBreak: "break-word",
                        backgroundColor: formData.isDescriptionDiff ? formData.compareColor : ""
                    }}>
                        <pre
                            style={{
                                fontSize: "9pt",
                                fontFamily: "Arial, sans-serif",
                                whiteSpace: "pre-wrap"
                            }}>
                            {formData?.description?.replaceAll('<br/>', '\n')}
                        </pre>
                </div>
            )}

            {/*Study Event 출력*/}
            <h1
                style={{
                    paddingTop: "5px",
                    fontSize: "9pt",
                    fontFamily: "Arial, sans-serif"
                }}>
                {/* compareCRF에서 출력 */}
                {formData.visitDiffList?.map((visitObj) => (
                    <div>
                        <span style={{
                            backgroundColor: visitObj.highlightAll ? formData.compareColor : ""
                        }}>
                            {visitObj.key === "" ? <></> : <span>{visitObj.key}&nbsp;:&nbsp;</span>}
                            {visitObj.values.map((valueObj, index, {length}) => (
                                <>
                                    <span style={{
                                        backgroundColor: !visitObj.highlightAll && valueObj.highlight ? formData.compareColor : ""
                                    }}>{valueObj?.value}</span>
                                    <span>{index + 1 === length ? "" : ", "}</span>
                                </>
                            ))}
                        </span>
                    </div>
                ))}

                {/* crf form에서 출력 */}
                {formData.visitDiffList === undefined && formData.visitList?.map((visit, idx) => (
                    <div key={idx}>{visit}</div>
                ))}
            </h1>

            {getStudyEventComp()}
        </div>
    );
};

export default React.memo(ACRFForm);