import React, {useCallback, useEffect} from 'react';
import {isDataExist} from "common/commonFunction";
import {SELECT_DEFAULT_CAPTION} from "constant/ConstantName";
import classNames from "classnames";
import {FILL_REQUIRED_FILED_SELECT} from "constant/ConstantMsg";

/**
 *  @author       김한나
 *  @version      1.0
 *  @component    CategorySelectBox
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  [SDTM > Mapping > variables] codelist 필드 팝업 입력 폼에 사용되는 select box
 */
const CategorySelectBox = (props) => {

    // props
    /*
      name          : Input tag의 값들을 구별하기 위한 name
      className     : 스타일 클래스
      dataList      : Select option에 사용 될 데이터 리스트
      defaultCaption: Select tag의 기본 caption - option
      defaultValue  : Select tag의 기본 값 - option
      currentValue  : 현재 설정된 값
      onChange      : Select box 변경 시 이벤트 전달을 위해 사용(name, 변경 값)으로 호출
      readOnly      : 읽기 전용
      validation    : validation 한 값(true, false)
      onFocus       : focus 가 왔을 때 처리
    */

    const {
        name,
        className,
        dataList,
        isEmptyValue,
        defaultValue = '',
        defaultCaption,
        currentValue,
        onChange,
        readOnly,
        validation,
        onFocus,
        ref,
        placeholder,
        onClick,
        compare
    } = props;

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     CategorySelectBox
     *  @function     handleChange
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  props로 전달 된 이벤트 처리 함수 호출
     */
    const handleChange = useCallback((e) => {
        e.preventDefault();
        // if(e.target.value ===)
        const targetValue = e.target.value;

        if(targetValue === "External CodeList" ||targetValue === "CodeList"){
            return;
        }

        // 네이티브 이벤트를 이용해서 label 값 조회
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].label;

        if (onChange !== undefined) {
            onChange(name, targetValue, label);
        }
    }, [name, onChange]);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        const targetValue = e.target.value;
        // if (onChange !== undefined) {
        if (onClick !== undefined) {
            onClick(name, targetValue);
        }
    }, [name, onClick]);

    /**
     *  @memberOf     CategorySelectBox
     *  @function     getInValidText
     *  @param        none
     *  @return       {element} invaild element
     *  @description  invalid 일 때 출력할 text 내용
     */
    const getInValidText = useCallback(() => {

        // invalid 할 때
        if (validation !== undefined && validation === false && isDataExist(currentValue) === false) {
            return (
                <>
                    <div className="invalid-feedback">{FILL_REQUIRED_FILED_SELECT}</div>
                </>
            );
        }
        // valid 할 때 출력 없음
        else {
            return null;
        }
    }, [currentValue, validation]);

    /**
     *  @memberOf     CategorySelectBox
     *  @function     handleFocus
     *  @param        {Object} e - event 객체
     *  @return       none
     *  @description  focus가 왔을 때 처리
     */
    const handleFocus = useCallback((e) => {
        // e.preventDefault();
        if (onFocus !== undefined) {
            onFocus(name);
        }
    }, [name, onFocus]);

    /**
     *  @memberOf     CategorySelectBox
     *  @function     selectList
     *  @param        none
     *  @return       {element} select list element
     *  @description  select box의 리스트 생성
     */
    const selectList = useCallback(() => {
        // dataList가 있는지 체크
        if (isDataExist(dataList)) {

            // let _dataList = copyObject(dataList);
            // // _dataList.push({text: "External CodeList", value: "External CodeList"});
            return dataList.map((row, index) => {

                    const {
                        text,
                        value,
                    } = row;
                 
                    if (text === "CodeList" || text === "External CodeList") {
                        return (
                            <option className="f-s-15 f-w-800" key={index} value={value} label={text}>
                                {text}
                            </option>
                        );
                    }else{
                        return (
                            <option key={index} value={value} label={text}>
                                {text}
                            </option>
                        );
                    }
                }
            )
        }
    }, [dataList]);

    useEffect(() => {
    }, [currentValue]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <select name={name}
                    className={classNames('form-control', className, {
                        'is-invalid': ((validation !== undefined && validation === false && isDataExist(currentValue) === false) === true || compare),
                    })}
                    defaultValue={defaultValue}
                    value={currentValue}
                    onChange={handleChange}
                    disabled={readOnly}
                    onFocus={handleFocus}
                    onClick={handleClick}
                    ref={ref}
                    placeholder={placeholder}>
                {isEmptyValue && <option
                    value={""}>{defaultCaption !== undefined ? defaultCaption : SELECT_DEFAULT_CAPTION}</option>}
                <option disabled hidden
                        value={""}>{defaultCaption !== undefined ? defaultCaption : SELECT_DEFAULT_CAPTION}</option>

                {selectList()}
            </select>
            {getInValidText()}
        </>
    );
};

export default React.memo(CategorySelectBox);
