import React, {useCallback, useEffect, useState} from "react";
import AnnotationComponent from "../../../../components/AnnotationComponent";
import ACRFEditModal from "./ACRFEditModal";
import {MAXIMUM_ANNOTATION, SEND_ANNOTATION_INFO} from "./ACRFMain";
import addIcon from "../../../../../assets/icons/add.svg";
import ACRFCodeList2 from "./ACRFCodeList2";
import {FORM_COMPARE} from "../../../design/amendmentTable/CompareCrf";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFItem2
 *  @constant     {Object} TYPE_DEFINE
 *  @description  QUESTION, RESULT 정의
 */
const TYPE_DEFINE = {
    QUESTION: "QUESTION",
    RESULT: "RESULT"
};

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFItem2
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  ACRF의 Item 컴포넌트
 */
const ACRFItem2 = (props) => {
    /**
     *  @memberOf     ACRFItem2
     *  @type         {Object} props
     *  @property     {Object} itemData - Item 정보
     *  @property     {Boolean} isEdit - acrf의 edit 구분 값(false: view, true: edit)
     *  @property     {Array} domainList - Form에 해당하는 Domain 리스트
     *  @property     {Function} onChangeDataList - annotation 추가, 수정, 삭제시 실행될 상위 이벤트 함수
     *  @property     {String} formID - 포함 된 form의 ID
     *  @property     {String} itemGroupID - 포함 된 item의 ID
     *  @property     {String} formMode - aCRF의 type(crf[기본] / compare[비교])
     *  @property     {Array} itemGroupVisit - itemGroupVisit 정보
     *  @property     {Array} armList - arm 정보
     *  @property     {Array} formVisit - formVisit 정보
     *  @property     {Number} columnIdx - item의 componentType 이 "DropDownList"이고, question이 "TimePoint" 인 codelist의 배열의 인덱스
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {
        itemData,
        isEdit,
        domainList,
        onChangeDataList,
        formID,
        itemGroupID,
        formMode,
        itemGroupVisit,
        armList,
        formVisit,
        columnIdx
    } = props;

    /**
     *  @memberOf     ACRFItem2
     *  @var          {Boolean} isModal
     *  @description  ACRF edit 팝업 오픈 여부
     */
    const [isModal, setIsModal] = useState(false);

    /**
     *  @memberOf     ACRFItem2
     *  @var          {String} type
     *  @description  Annotation type(QUESTION, RESULT)
     */
    const [type, setType] = useState();

    /**
     *  @memberOf     ACRFItem2
     *  @var          {Array} currentAnnotation
     *  @description  선택 된 Annotation 정보
     */
    const [currentAnnotation, setCurrentAnnotation] = useState({});

    /**
     *  @memberOf     ACRFItem2
     *  @var          {Object} designVisit
     *  @description  Item별 Design, Visit 표시하기 위한 정보 (question 앞에 붙임)
     */
    const [designVisit, setDesignVisit] = useState({});

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/

    /**
     *  @memberOf     ACRFItem2
     *  @function     handleAdd
     *  @param        {String} type - 추가 할 Annotation의 type(QUESTION, RESULT)
     *  @description  Annotation Add 함수
     */
    const handleAdd = useCallback((type) => {
        let questionAnnotationLength = itemData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length; //item의 annotation 리스트에서 type이 QUESTION인 것만 필터하여 리스트 길이 저장
        let resultAnnotationLength = itemData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length; //item의 annotation 리스트에서 type이 RESULT인 것만 필터하여 리스트 길이 저장

        //type이 QUESTION이고 필터된 리스트의 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.QUESTION && questionAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
        }

        //type이 QUESTION이고 필터된 리스트의 길이가 4 미만일 때만 추가 가능
        if (type === TYPE_DEFINE.RESULT && resultAnnotationLength < MAXIMUM_ANNOTATION) {
            setIsModal(true);
            setType(type);
        }

        setCurrentAnnotation({}); //add 일때는 선택됐던 annotation 정보 초기화
    }, [itemData.annotations]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     handleClick
     *  @param        {Object} annotation - 선택 된 annotation의 정보
     *  @description  Annotation 클릭 되면 호출 되는 함수
     */
    const handleClick = useCallback((annotation) => {
        if (isEdit) {
            setCurrentAnnotation(annotation);
            setIsModal(true);
            setType(annotation.type);
        }
    }, [isEdit]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 상위로 annotation 정보 전달하는 함수
     */
    const changeDataList = useCallback((data) => {
        if (onChangeDataList !== undefined) {
            onChangeDataList(data);
        }
    }, [onChangeDataList]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     handleDelete
     *  @param        {Number} idx - 리스트에서 선택 된 index
     *  @param        {String} type - Annotation의 type
     *  @description  Annotation Delete 하는 함수
     */
    const handleDelete = useCallback((idx, type) => {
        if (itemData.annotations !== undefined && itemData.annotations.length > 0) {
            const annotationList = itemData.annotations.filter(annotation => annotation.type === type); //type에 따라 annotations를 filter
            const data = annotationList[idx]; //삭제 될 data
            data.path = `${formID}/${itemGroupID}/${itemData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID를 path key에 추가
            data.dataType = "delete"; //dataType에 delete 추가

            changeDataList(data); //상위로 삭제 된 annotation 정보 전달

            SEND_ANNOTATION_INFO.delete.push(data); //전역 변수로 지정 된 곳 delete에 데이터 추가
        }
    }, [changeDataList, formID, itemData.ID, itemData.annotations, itemGroupID]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     handleSave
     *  @param        {Object} data - Annotation 정보
     *  @param        {String} dataType - 추가, 수정, 삭제 구분
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback((data, dataType) => {
        data.path = `${formID}/${itemGroupID}/${itemData.ID}`; //포함 된 Form ID, ItemGroup ID, Item ID를 path key에 추가

        changeDataList(data); //상위로 추가, 수정 된 annotation 정보 전달

        SEND_ANNOTATION_INFO[dataType].push(data); //전역 변수로 지정 된 곳 add, update에 데이터 추가

        setIsModal(false);
        setCurrentAnnotation({}); //선택했던 annotation 정보 초기화
    }, [changeDataList, formID, itemData.ID, itemGroupID]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     getDefaultValue
     *  @return       {Element} Item Input Default Value render
     *  @description  ACRF의 해당하는 Item의 input Default Value 그려주는 함수
     */
    const getDefaultValue = useCallback(() => {
        if (itemData.dataType === "Datetime" && itemData.format !== "") {
            return (
                <div style={{fontSize: "9pt"}}>
                    {itemData.format}
                </div>
            )
        } else if (itemData.dataType === "Float") {
            let num = '_ '.repeat(Math.floor(itemData.format) - itemData.format.slice(itemData.format.indexOf('.') + 1));
            let primeNum = '_ '.repeat(itemData.format.slice(itemData.format.indexOf('.') + 1));

            return num === '' ? '_ . ' + primeNum : num + '. ' + primeNum;
        } else if (itemData.dataType === "Integer") {
            return "_ ".repeat(itemData.format);
        } else if (itemData.dataType === "Text") {
            return "__________";
        }
    }, [itemData.dataType, itemData.format]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     getItemComp
     *  @return       {Element} Item Value render
     *  @description  ACRF의 해당하는 Item의 Value 그려주는 함수
     */
    const getItemComp = useCallback(() => {
        if (itemData.componentType === "Text") { //componentType이 Text 일때
            return (
                <div
                    style={{
                        display: "flex",
                        lineHeight: "2.5",
                        alignItems: "center",
                        wordBreak: 'break-all'
                    }}>

                    {/*추후 TEXTBOX 변경 시*/}
                    {/*<input*/}
                    {/*    style={{*/}
                    {/*        border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "2px solid #ff0000" : "",*/}
                    {/*        width: "100%",*/}
                    {/*        // padding: "5px"*/}
                    {/*    }}*/}
                    {/*    type="text"*/}
                    {/*    className="form-control"*/}
                    {/*    defaultValue={*/}
                    {/*        itemData.dataType === "Datetime" && itemData.format !== "" ? itemData.format :*/}
                    {/*            itemData.dataType === "Float" ?*/}
                    {/*                '_ '.repeat(itemData.format.toString().slice(itemData.format.indexOf('.') - 1) - 1)*/}
                    {/*                + '.' + '_ '.repeat(itemData.format.slice(itemData.format.indexOf('.') + 1)) :*/}
                    {/*                itemData.dataType === "Integer" ? "_ ".repeat(itemData.format) : "" }/>*/}

                    <div style={{
                        border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "1px solid #ff0000" : "",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div
                            style={{border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "1px solid #ff0000" : ""}}>
                            {getDefaultValue()}
                        </div>
                        {itemData.hasOwnProperty("fixedUnit") && itemData.fixedUnit !== "" && (
                            <div style={{
                                flex: 1,
                                marginLeft: "10px",
                                fontSize: "9pt",
                                fontFamily: "Arial, sans-serif"
                            }}>{itemData.fixedUnit}</div>
                        )}
                    </div>
                </div>
            );
        } else if (itemData.componentType === "CheckBox") { //componentType이 CheckBox 일때
            return itemData.codelists?.map((code, idx) => (
                <ACRFCodeList2
                    key={idx}
                    type={"checkbox"}
                    codeListData={code}
                    isEdit={isEdit}
                    domainList={domainList}
                    onChangeDataList={changeDataList}
                    formID={formID}
                    itemGroupID={itemGroupID}
                    itemID={itemData.ID}
                    formMode={formMode}
                    itemData={itemData}/>
            ));
        } else if (itemData.componentType === "DropDownList") { //componentType이 DropDownList 일때
            let defaultValueArr = []; // itemData가 componentType이 DDL 이고 question이 Time Point 인 경우의 codelist의 label 배열

            if (itemData.element.includes("TPT") && itemData.defaultValue.includes("|")) {
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label)
                })
            }

            if (itemData.element.includes("LOC") && itemData.defaultValue.includes("|")) {
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("SPID") && itemData.defaultValue.includes("|")) { // element 별로 codelist의 label값이 백엔드에서 들어오는 item이 있고, 안 들어오는 item이 있음. SPID는 label값이 안 들어오기 때문에 label값 대신 defaultValue값을 사용.
                itemData.codelists.forEach(codelist => {
                    defaultValueArr.push(codelist.label);
                });
            }

            if (itemData.element.includes("TPT") || itemData.element.includes("SPID") || itemData.element.includes("LOC") || itemData.element.includes("TESTCD") || itemData.element.includes("SCAT")) { // item의 element가 "TPT"/ "SPID" / "LOC 인 경우 : codelist의 label/ defaultValue 출력
                return (
                    <div
                        style={{
                            display: "flex",
                            lineHeight: "2.5",
                            flex: 1,
                            maxWidth: "160px",
                            // wordBreak: "break-all"
                        }}>
                        <div style={{
                            border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "1px solid #ff0000" : "",
                            padding: "10px",
                            width: "100%",
                            fontSize: "9pt"
                        }}>
                            {defaultValueArr[columnIdx] ? defaultValueArr[columnIdx] :
                                <select style={{width: "100%"}}>
                                    <option value="">...</option>
                                </select>}
                        </div>
                    </div>
                )
            } else { // 아닌 경우 일반 DDL 출력
                return (
                    <div
                        style={{
                            display: "flex",
                            lineHeight: "2.5",
                            flex: 1,
                            maxWidth: "160px",
                            // wordBreak: "break-all"
                        }}>
                        {/*추후 TEXTBOX 변경 시*/}

                        {/*<input*/}
                        {/*    style={{*/}
                        {/*        border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "2px solid #ff0000" : ""*/}
                        {/*    }}*/}
                        {/*    type="text"*/}
                        {/*    className="form-control"*/}
                        {/*    defaultValue={"DDL"}/>*/}
                        <div style={{
                            border: itemData.hasOwnProperty("isResultDiff") && itemData.isResultDiff ? "1px solid #ff0000" : "",
                            padding: "10px",
                            width: "100%",
                            fontSize: "9pt"
                        }}>
                            <select style={{width: "100%"}}>
                                <option value="">...</option>
                            </select>
                        </div>
                    </div>
                );
            }
        } else if (itemData.componentType?.includes("RadioButton")) {//componentType이 RadioButton이나 RadioButton (Vertical) 일때
            return (
                <div style={!itemData.componentType?.includes("Vertical") ? {
                    display: "flex",
                    padding: "10px",
                    wordBreak: 'break-all'
                } : {
                    display: "block",
                    padding: "10px",
                    wordBreak: 'break-all'
                }}>
                    {itemData.codelists?.map((code, idx) => (
                        <ACRFCodeList2
                            key={idx}
                            type={"radio"}
                            codeListData={code}
                            isEdit={isEdit}
                            domainList={domainList}
                            isVertical={itemData.componentType.includes("Vertical")}
                            onChangeDataList={changeDataList}
                            formID={formID}
                            itemGroupID={itemGroupID}
                            itemID={itemData.ID}
                            formMode={formMode}
                            itemData={itemData}/>
                    ))}
                </div>
            );
        } else if (itemData.componentType === "SearchList" || itemData.componentType === "Dynamic SearchList") {
            return (
                <div style={{
                    whiteSpace: "nowrap",
                    maxWidth: "160px",
                    padding: "0px 20px 0px 10px"
                }}>
                    <input
                        type="text"
                        style={{
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRightWidth: "0px",
                            borderColor: "#a7a6aa",
                            paddingLeft: "2px",
                            paddingRight: "2px",
                            backgroundColor: "white",
                            height: "20px",
                            width: "100%"
                        }}
                        readOnly={true}/>
                    <input
                        style={{
                            backgroundImage: `url("data:image/png;base64,R0lGODlhCQAFAIABAKemqv///yH+FUNyZWF0ZWQgd2l0aCBUaGUgR0lNUAAh+QQBCgABACwAAAAACQAFAAACCoR/gRmKDRlsYBYAOw==")`,
                            backgroundColor: "white",
                            borderStyle: "solid",
                            borderColor: "#a7a6aa",
                            borderWidth: "1px",
                            backgroundPosition: "2px .5em",
                            backgroundRepeat: "no-repeat",
                            width: "15px",
                            height: "20px",
                            cursor: "pointer",
                        }}
                        readOnly={true}/>
                </div>
            )
        }
    }, [changeDataList, columnIdx, domainList, formID, formMode, getDefaultValue, isEdit, itemData, itemGroupID]);

    /**
     *  @memberOf     ACRFItem2
     *  @function     getItemLayout
     *  @return       {Element} Item Data render
     *  @description  Item의 LogDirection, repeating 여부에 따라 Item 구성 그려주는 함수
     *  @description   뉴로 바이오젠 과제에 해당하는 ID의 디자인들은 (DEGN000000000093/ DEGN000000000100/ DEGN000000000112/) Landscape 표 형식을 적용하지 않음
     */
    const getItemLayout = useCallback(() => {
        // 뉴로 바이오젠 ID 에 해당하는 디자인들은 현재 진행 중인 과제이므로 landscape 표를 적용하면 안 됨
        if (itemData.designID === "DEGN000000000093" || itemData.designID === "DEGN000000000100" || itemData.designID === "DEGN000000000112") { // 뉴로 바이오젠 ID 에 해당하는 디자인들은 현재 진행 중인 과제이므로 landscape 표를 적용하면 안 됨
            return (
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #000000",
                        marginTop: '-1px',
                        fontSize: "9pt",
                        breakInside: "avoid"
                    }}>
                        {/*item의 question 영역*/}
                        <div style={{display: "flex", flex: 1}}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                padding: "20px",
                                fontFamily: "Arial, sans-serif"
                            }}>
                            <span
                                style={{border: itemData.hasOwnProperty("isQuestionDiff") && itemData.isQuestionDiff ? "1px solid #ff0000" : ""}}>
                                {itemData.question?.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif"
                                        }}>
                                            {designVisit[itemGroupID]?.map((each, idx) => {
                                                if (each) {
                                                    return (
                                                        <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);
                                                }
                                            })}
                                            {itemData.question?.replaceAll('<br/>', '\n')}
                                            {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <>
                                        {designVisit[itemGroupID]?.map((each, idx) => {
                                            if (each) {
                                                return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);
                                            }
                                        })}
                                        {itemData.question}
                                        {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                    </>
                                }
                            </span>
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("QUESTION")}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center",
                                            }}
                                            alt=""/>
                                    )}

                                    {/*question의 annotaion 영역*/}
                                    {itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/*item의 value 영역*/}
                        <div style={{
                            display: "flex",
                            flex: 1,
                            borderLeft: "1px solid #000000",
                        }}>
                            <div style={{
                                flex: 1,
                                alignSelf: "center",
                                padding: "10px",
                            }}>
                                {getItemComp()}
                            </div>

                            {formMode !== FORM_COMPARE && (
                                <>
                                    {isEdit && (
                                        <img
                                            src={addIcon}
                                            onClick={() => handleAdd("RESULT")}
                                            style={{
                                                marginLeft: "5px",
                                                paddingRight: "15px",
                                                height: "1rem",
                                                alignSelf: "center"
                                            }}
                                            alt=""/>
                                    )}

                                    {/*item의 annotation 영역*/}
                                    {itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                        <div style={{flex: 1, alignSelf: "center"}}>
                                            <AnnotationComponent
                                                annotationList={itemData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                isEdit={isEdit}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {isModal && (
                        <ACRFEditModal
                            onClose={() => setIsModal(false)}
                            onSave={handleSave}
                            type={type}
                            data={itemData}
                            domainList={domainList}
                            annotationInfo={currentAnnotation}/>
                    )}
                </>
            )
        } else { // 뉴로 바이오젠 외의 모든 디자인들
            // [Landscape 표 형태] logDirection이 Landscape 이고 repeating Yes 일 때, item 의 question 없이 item 의 value값만 출력
            if (itemData?.logInfo?.logDirection === 'Landscape' && (itemData?.logInfo?.repeating === "Yes" || itemData?.logInfo?.repeating === undefined)) {
                return (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {getItemComp()}
                    </div>
                )
            } else { // [일반 폼 형태] landscape 조건에 해당하지 않는 나머지 경우에는 question, value 모두 출력
                return (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            border: "1px solid #000000",
                            marginTop: "-1px",
                            fontSize: "9pt",
                            pageBreakInside: "avoid"
                        }}>
                            {/*item의 question 영역*/}
                            <div style={{display: "flex", flex: 1}}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                    padding: "20px",
                                    fontFamily: "Arial, sans-serif",

                                }}>
                            <span
                                style={{border: itemData.hasOwnProperty("isQuestionDiff") && itemData.isQuestionDiff ? "1px solid #ff0000" : ""}}>
                                {itemData.question?.includes('<br/>') ?
                                    <div style={{wordBreak: "break-all", wordWrap: "break-word"}}>
                                        <pre style={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: "9pt",
                                            fontFamily: "Arial, sans-serif",
                                        }}>
                                            {designVisit[itemGroupID]?.map((each, idx) => {
                                                if (each) {
                                                    return (
                                                        <span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);
                                                }
                                            })}
                                            {itemData.question?.replaceAll('<br/>', '\n')}
                                            {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                        </pre>
                                    </div> :
                                    <div style={{
                                        wordBreak: 'break-all'
                                    }}>
                                        {designVisit[itemGroupID]?.map((each, idx) => {
                                            if (each) {
                                                return (<span key={idx} style={{color: 'red'}}>({each})&nbsp;</span>);
                                            }
                                        })}
                                        {itemData.question}
                                        {itemData.hasOwnProperty("measurementUnit") && itemData.measurementUnit !== "" && ` (${itemData.measurementUnit})`}
                                    </div>
                                }
                            </span>
                                </div>

                                {formMode !== FORM_COMPARE && (
                                    <>
                                        {isEdit && (
                                            <img
                                                src={addIcon}
                                                onClick={() => handleAdd("QUESTION")}
                                                style={{
                                                    marginLeft: "5px",
                                                    paddingRight: "15px",
                                                    height: "1rem",
                                                    alignSelf: "center",
                                                }}
                                                alt=""/>
                                        )}

                                        {/*question의 annotaion 영역*/}
                                        {itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION).length > 0 && (
                                            <div style={{flex: 1, alignSelf: "center"}}>
                                                <AnnotationComponent
                                                    annotationList={itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.QUESTION)}
                                                    onClick={handleClick}
                                                    onDelete={handleDelete}
                                                    isEdit={isEdit}/>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            {/*item의 value 영역*/}
                            <div style={{
                                display: "flex",
                                flex: 1,
                                borderLeft: "1px solid #000000"
                            }}>
                                <div style={{
                                    flex: 1,
                                    alignSelf: "center",
                                }}>
                                    {getItemComp()}
                                </div>

                                {formMode !== FORM_COMPARE && (
                                    <>
                                        {isEdit && (
                                            <img
                                                src={addIcon}
                                                onClick={() => handleAdd("RESULT")}
                                                style={{
                                                    marginLeft: "5px",
                                                    paddingRight: "15px",
                                                    height: "1rem",
                                                    alignSelf: "center"
                                                }}
                                                alt=""/>
                                        )}

                                        {/*item의 annotation 영역*/}
                                        {itemData.annotations?.filter(annotation => annotation.type === TYPE_DEFINE.RESULT).length > 0 && (
                                            <div style={{flex: 1, alignSelf: "center"}}>
                                                <AnnotationComponent
                                                    annotationList={itemData.annotations.filter(annotation => annotation.type === TYPE_DEFINE.RESULT)}
                                                    onClick={handleClick}
                                                    onDelete={handleDelete}
                                                    isEdit={isEdit}/>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        {/*item의 description 영역*/}
                        {(itemData.hasOwnProperty("comment") && itemData.comment !== "" && (itemData.componentType !== "DropDownList" && itemData.componentType !== "SearchList")) && (
                            <div
                                style={{
                                    border: "1px solid #000",
                                    marginTop: "-1px",
                                    backgroundColor: "#F2F2F2",
                                }}>
                                <div style={{
                                    fontSize: "9pt",
                                    fontFamily: "Arial, sans-serif",
                                    whiteSpace: "pre-wrap",
                                    marginTop: "-1px",
                                    padding: "10px",
                                }}>
                                    {(itemData.componentType === "DropDownList" || itemData.componentType === "SearchList") ?
                                        <div><span
                                            style={{fontWeight: 600}}>{itemData.question}</span>{`=DDL [${itemData.codelists?.map(e => e.label).join([', '])}]`}
                                        </div> :
                                        <span style={{
                                            fontWeight: 600,
                                        }}>
                                            {itemData.comment.replaceAll('<br/>', '\n')}
                                        </span>
                                    }
                                </div>
                            </div>
                        )}

                        {isModal && (
                            <ACRFEditModal
                                onClose={() => setIsModal(false)}
                                onSave={handleSave}
                                type={type}
                                data={itemData}
                                domainList={domainList}
                                annotationInfo={currentAnnotation}/>
                        )}
                    </>
                )
            }
        }
    }, [currentAnnotation, designVisit, domainList, formMode, getItemComp, handleAdd, handleClick, handleDelete, handleSave, isEdit, isModal, itemData, itemGroupID, type]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/

    // itemGroup의 question 앞에 visit 정보 붙임
    useEffect(() => {
        if (armList && itemGroupVisit && formVisit) {
            // 오브젝트 초기화
            const _IGArmObj = {}; // itemgroup으로 먼저 묶고 그 안에 arm별로 묶인 오브젝트
            for (const armID in itemGroupVisit) {
                Object.keys(itemGroupVisit[armID]).forEach((itemGroupID) => {
                    _IGArmObj[itemGroupID] = {};
                });
            }

            // 각각의 arm 별로 각 itemgroup이 formVisit 대비 다르다면 (적게 체크되있다면)
            // _IGArmObj에 저장
            for (const armID in formVisit) {
                if (itemGroupVisit[armID]) {
                    for (const itemGroupID in itemGroupVisit[armID]) {
                        if (formVisit[armID].join("") !== itemGroupVisit[armID][itemGroupID].join("")) {
                            _IGArmObj[itemGroupID][armID] = itemGroupVisit[armID][itemGroupID];
                        }
                    }
                }
            }

            // armList를 사전형으로 변환
            let _armObj = [];
            armList.forEach(e => _armObj[e.ID] = e.studySchedule);

            // 전체 arm개수가 1개인지 파악
            let _isArmSingle = false;
            const _armNameArray = [];
            armList.forEach((e) => {
                _armNameArray.push(e.studySchedule)
            })
            if ([...new Set(_armNameArray)].length === 1) {
                _isArmSingle = true
            }

            const _IGVisitObj = {};
            for (const itemGroupID in _IGArmObj) {
                let _temp = [];
                let _valid = [];
                Object.keys(_armObj).forEach((key) => {
                    if (Object.keys(_IGArmObj[itemGroupID]).includes(key)) {
                        // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                        if (_isArmSingle) {
                            _temp.push(_IGArmObj[itemGroupID][key].join(', '));
                        } else {
                            _temp.push(`${_armObj[key]} : ${_IGArmObj[itemGroupID][key].join(', ')}`);
                        }
                        _valid.push(`${_IGArmObj[itemGroupID][key].join(', ')}`);
                    }
                })

                // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                const _isAllEqual = arr => arr.every(v => v === arr[0])
                if (_isAllEqual(_valid) && _valid.length !== 1) {
                    _temp = [_valid[0]];
                }
                _IGVisitObj[itemGroupID] = _temp;
            }
            setDesignVisit(_IGVisitObj);
        }
    }, [armList, itemGroupVisit, formVisit]);
    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            {getItemLayout()}
        </>
    );
};

export default React.memo(ACRFItem2);