import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {getSessionState} from "../../../../common/commonFunction";
import useWindowSize from "hooks/useWindowSize";
import deleteIcon from "../../../../assets/icons/delete.svg";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @author       김한나
 *  @version      1.0
 *  @component    EndPointSearchList
 *  @param        {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description  concept의 Objective & Endpoint에서  endpoint 리스트를 grid로 표시하는 컴포넌트
 */
const EndPointSearchList = (props) => {
    /**
     *  @memberOf     EndPointSearchList
     *  @type         {Object} props
     *  @property     {Array} dataList - 화면에 표시할 데이터 리스트
     *  @property     {Number} itemKey - item Key
     *  @property     {String} ID - grid ID
     *  @property     {Function} onChange - grid 변경 시(수정, Drag & Drop, 삭제) 호출 될 상위 이벤트 함수
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {dataList, itemKey, ID, onChange} = props;

    /**
     *  @memberOf      EndPointSearchList
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description   session 에서 받아오는 정보
     */
    const {lock, language} = getSessionState();

    /**
     *  @memberOf     EndPointSearchList
     *  @var          {HTMLDivElement} tableRef
     *  @description  AUI grid ref 영역
     */
    const tableRef = useRef(null);

    /**
     *  @memberOf     EndPointSearchList
     *  @var          {Array<Number>} [width, height]
     *  @description  현재 브라우저 창 사이즈
     */
    const [width, height] = useWindowSize();

    /**
     *  @memberOf     EndPointSearchList
     *  @constant     {Object} auiGridProps
     *  @description  Form 리스트를 표시하는 aui grid 의 설정값
     */
    const auiGridProps = {
        showRowNumColumn: true,  // no 표시
        wrapSelectionMove: false,
        enableSorting: false,
        editable: lock !== 2,
        enableDrag: lock !== 2,
        enableDragByCellDrag: lock !== 2,
        enableDrop: lock !== 2,
        autoGridHeight: true,
        wordWrap: false
    };

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     EndPointSearchList
     *  @function     handleDeleteTableRow
     *  @param        {Object} e - event 객체
     *  @description  grid에서 delete 버튼 클릭 시 실행되는 함수
     */
    const handleDeleteTableRow = useCallback((e) => {
        window.AUIGrid.removeRow(tableRef.current, e.rowIndex); //removeRow 이벤트 발생 시킴
    }, []);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // grid 데이터 setting 작업
    useEffect(() => {
        tableRef.current = window.AUIGrid.create(`#${ID}`, columnLayout, auiGridProps);

        if (dataList.length > 0) {
            window.AUIGrid.setGridData(tableRef.current, dataList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList]);

    useEffect(() => {
        return () => {
            window.AUIGrid.destroy(`#${ID}`);
        }
    }, [ID]);

    // 브라우저 창 크기 변화 시 grid resizing 필요
    useLayoutEffect(() => {
        window.AUIGrid.resize(tableRef.current, '100%');
    }, [width, height, tableRef]);

    // AUI Grid 이벤트
    useEffect(() => {
        // cell edit 이벤트
        window.AUIGrid.bind(tableRef.current, "cellEditEnd", (event) => {
            if (onChange !== undefined) {
                window.AUIGrid.updateRow(tableRef.current, event.rowIndex);

                const changeGridData = window.AUIGrid.getGridData(tableRef.current);

                onChange(changeGridData, itemKey);
            }
        });

        // 드랍 종료 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "dropEnd", (event) => {
            if (onChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, itemKey);
            }
        });

        // row 삭제 이벤트 바인딩
        window.AUIGrid.bind(tableRef.current, "removeRow", (event) => {
            window.AUIGrid.removeSoftRows(tableRef.current);// 실제로 그리드에서 삭제 함.

            if (onChange !== undefined) {
                const changeGridData = window.AUIGrid.getGridData(tableRef.current); // 변경된 grid 데이터 가져오기

                onChange(changeGridData, itemKey);
            }
        });
    }, [onChange, itemKey]);

    /**
     *  @memberOf     ItemGroupList
     *  @constant     {Array} columnLayout
     *  @description  aui grid 화면에 표시할 데이터
     */
    const columnLayout = [
        {
            dataField: 'category',
            headerText: (language === 'en' ? 'Category' : '분류'),
            editable: false,
            width: '12%'
        },
        {
            dataField: 'name',
            headerText: (language === 'en' ? 'Endpoint' : '평가항목'),
            editable: lock !== 2
        },
        {
            dataField: 'description',
            headerText: (language === 'en' ? 'Details' : '비고'),
            editable: lock !== 2
        },
        {
            dataField: '',
            headerText: '',
            width: "5%",
            editable: false,
            renderer: {
                type: "IconRenderer",
                iconPosition: "aisleCenter",  // 아이콘 위치
                iconTableRef: { // icon 값 참조할 테이블 레퍼런스
                    "default": deleteIcon // default
                },
                onClick(event) {
                    if (lock !== 2) {
                        handleDeleteTableRow(event);
                    }
                }
            }
        }
    ];

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <React.Fragment>
            <div id={ID}/>
        </React.Fragment>
    );
};

export default React.memo(EndPointSearchList);