import React, {useCallback, useEffect, useRef, useState} from "react";
import useToast from "hooks/useToast";
import html2canvas from "html2canvas";
import {produce} from "immer";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AXIOS_GET, AXIOS_PUT_FORM, copyObject, getSessionState, isDataExist} from "common/commonFunction";
import {COMMAND} from "common/dataProcessAgent";
import NetworkLayout from "common/NetworkLayout";
import {YN_OPTION} from "constant/ConstantList";
import {DESIGN_URL} from "constant/ConstantURL";
import RadioButton from "imtrial/components/RadioButton";
import DesignBottom from "../DesignBottom";
import IpConfigTableList, {FIELD_SHAPE, FIELD_SHAPE_OTHER, FIELD_UNIT, FIELD_UNIT_OTHER} from "./IpConfigTableList";

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf       IPConfig
 *  @constant       {Object} IP_DATA_DEFINE
 *  @description    ip field 명 정의
 */
export const IP_DATA_DEFINE = {
    CHECK_ADMINISTRATION: 'checkAdministration',
    CHECK_PRE_TREATMENT: 'checkPretreatment',
    CHECK_OTHERS: 'checkOthers',
    INVESTIGATION: 'investigation',
    ACTIVE: 'active',
    CONCOMITANT: 'concomitant',
    PRE_TREATMENT: 'pretreatment',
    OTHERS: 'others',
    PLACEBO: 'placebo'
};

/**
 *  @memberOf       IPConfig
 *  @constant       {Object} FIELD_DATA_DEFINE
 *  @description    ip 아이템 추가 시 필요한 데이터 default 값
 */
const FIELD_DATA_DEFINE = {
    shape: '',
    name: '',
    dose: '',
    unit: '',
    shape_other: '',
    unit_other: ''
};

/**
 *  @memberOf       IPConfig
 *  @constant       {Object} INPUT_DATA_FILED
 *  @description    input 값 입력에 필요한 default 초기 필드값
 */
const INPUT_DATA_FIELD = {
    [IP_DATA_DEFINE.INVESTIGATION]: FIELD_DATA_DEFINE,
    [IP_DATA_DEFINE.ACTIVE]: FIELD_DATA_DEFINE,
    [IP_DATA_DEFINE.PLACEBO]: FIELD_DATA_DEFINE,
    [IP_DATA_DEFINE.CONCOMITANT]: FIELD_DATA_DEFINE,
    [IP_DATA_DEFINE.PRE_TREATMENT]: FIELD_DATA_DEFINE,
    [IP_DATA_DEFINE.OTHERS]: FIELD_DATA_DEFINE
};

/**
 *  @memberOf       IPConfig
 *  @constant       {Object} FIELD_DATA_VALID
 *  @description    개별 IP input validation 체크에 사용 되는 필드
 */
const FIELD_DATA_VALID = {
    shape: true,
    name: true,
    dose: true,
    unit: true,
    shape_other: true,
    unit_other: true
};

/**
 *  @memberOf       IPConfig
 *  @constant       {Object} DEFAULT_VALID_FIELD
 *  @description    데이터 전체 validation 체크에 사용 되는 필드
 */
const DEFAULT_VALID_FIELD = {
    [IP_DATA_DEFINE.INVESTIGATION]: FIELD_DATA_VALID,
    [IP_DATA_DEFINE.CHECK_ADMINISTRATION]: true,
    [IP_DATA_DEFINE.CHECK_PRE_TREATMENT]: true,
    [IP_DATA_DEFINE.CHECK_OTHERS]: true
};

/**
 *  @memberOf       IPConfig
 *  @constant       {String} FIELD_ITEM_DATA
 *  @description    ITEM 데이터 영역 구분 키
 */
export const FIELD_ITEM_DATA = 'ITEM';

/**
 *  @memberOf       IPConfig
 *  @constant       {String} FIELD_ITEM_DATA
 *  @description    validation 영역 구분 키
 */
export const FIELD_VALID_DATA = 'VALID';

/**
 *  @memberOf       IPConfig
 *  @constant       {Object} YES_NO_DICTIONARY
 *  @description    language 가 한글일 경우 사용할 화면 언어 변환 테이블
 */
export const YES_NO_DICTIONARY = {
    'kr': {
        'YES': '예',
        'NO': '아니요'
    }
};

/**
 *  @memberOf       IPConfig
 *  @async          dataProcess
 *  @param          {String} command - 통신 데이터 처리 action type
 *  @param          {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return         {Object} response.data - 서버 응답 데이터
 *  @description    command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, sendObject} = params;
    let response = null;
    let url = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO :
            url = `${requestUrl}/synopsis/ip/${ID}`;
            response = await AXIOS_GET(url);
            break;

        // 데이터 수정 요청
        case COMMAND.DATA_UPDATE :
            url = `${requestUrl}/synopsis/ip/${ID}`;
            response = await AXIOS_PUT_FORM(url, sendObject);
            break;

        // Design 데이터 상세 정보 요청
        case COMMAND.DESIGN_INFO :
            url = `${requestUrl}/synopsis/design/${ID}`;
            response = await AXIOS_GET(url);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author         주예리나
 *  @version        1.0
 *  @see            html2canvas (https://github.com/niklasvh/html2canvas)
 *  @component      IPConfig
 *  @param          {Object} props - 상위 컴포넌트에서 전달받은 property
 *  @description    design information 의 IP 편집 컴포넌트
 */
const IPConfig = (props) => {
    /*################################################################################*/
    //## data 영역
    //##  - props, state
    /*################################################################################*/
    /**
     *  @memberOf      IPConfig
     *  @type          {Object} props
     *  @property      {String} ID - 리스트 ID
     *  @property      {Object} history - url 이동을 위해 사용
     *  @property      {Function} referencePopup - reference 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onList - list 버튼 클릭 시 실행될 상위 이벤트 함수
     *  @property      {Function} onChangeData - 변경 전 데이터, 변경 후 데이터 호출하는 함수
     *  @property      {Boolean } isSaved - isDataChange Modal에서 Yes 버튼 상태
     *  @property      {Boolean} onSetSave - isDataChange Modal Yes 버튼 클릭 상태 처리
     *  @property      {Boolean} isClosed - isDataChange Modal에서 No 버튼 상태
     *  @property      {Boolean} onSetClose - isDataChange Modal No 버튼 클릭 상태 처리
     *  @property      {Boolean} isChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태
     *  @property      {Boolean} isChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태
     *  @property      {Boolean} onChangeTabSave - isDataCheckModal의 Yes버튼 클릭 시 Save 상태 저장
     *  @property      {Boolean} onChangeTabClose - isDataCheckModal의 No버튼 클릭 시 Close 상태 저장
     *  @property      {Boolean} onChangeDataCheckModal - Top Tab의 isDataChange Modal 상태 처리
     *  @property      {Boolean} onDataChangeModal - Top Tab의 isDataChange Modal 상태 처리
     *  @description   상위 컴포넌트로부터 전달 받은 props
     */
    const {
        ID,
        history,
        referencePopup,
        onList,
        onChangeData,
        isSaved,
        onSetSave,
        isClosed,
        onSetClose,
        onDataChangeModal,
        isChangeTabSave,
        isChangeTabClose,
        onChangeTabSave,
        onChangeTabClose,
        onChangeDataCheckModal
    } = props;

    /**
     *  @memberOf      IPConfig
     *  @type          {Object} getSessionState
     *  @property      {String} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @property     {String} language - study 화면 언어 정보
     *  @description   session 에서 받아오는 정보
     */
    const {lock, language} = getSessionState();

    /**
     *  @memberOf     IPConfig
     *  @var          {*} netWorkAgent
     *  @description  netWorkLayout 컴포넌트 Ref
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     IPConfig
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     IPConfig
     *  @var          {HTMLDivElement} captureRef
     *  @description  저장 시 file 객체로 전송할 이미지 캡쳐 영역 ref
     */
    const captureRef = useRef(null);

    /**
     *  @memberOf       IPConfig
     *  @var            {Object} dataInfo
     *  @description    화면에 표시 될 상세 정보 및 validation 필드 table
     */
    const [dataInfo, setDataInfo] = useState({ITEM: {}, VALID: DEFAULT_VALID_FIELD});

    /**
     *  @memberOf       IPConfig
     *  @var            {Object} originDataInfo
     *  @description    원본 데이터
     */
    const [originDataInfo, setOriginDataInfo] = useState({ITEM: {}, VALID: DEFAULT_VALID_FIELD});

    /**
     *  @memberOf       IPConfig
     *  @var            {Object} ipInputFieldData
     *  @description    현재 입력된 ip input 데이터
     */
    const [ipInputFieldData, setIpInputFieldData] = useState(INPUT_DATA_FIELD);

    /**
     *  @memberOf       IPConfig
     *  @var            {Boolean} designModelType
     *  @description    design tab의 active control type 값 확인
     */
    const [designModelType, setDesignModelType] = useState('');

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     IPConfig
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO;
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     IPConfig
     *  @function     getDesignDataInfo
     *  @description  디자인 상세 정보 api 호출
     */
    const getDesignDataInfo = useCallback(() => {
        const command = COMMAND.DESIGN_INFO;
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf       IPConfig
     *  @function       getConvertSelectList
     *  @param          {Array} selectList - 화면에 표시할 옵션 리스트
     *  @return         {Array} convertList - 언어 정보에 따라 변경된 데이터 리스트
     *  @description    언어를 적용하여 데이터 리스트를 변경
     */
    const getConvertSelectList = useCallback((selectList) => {
        let originList = copyObject(selectList);
        let convertList = [];

        let item;
        for (item of originList) {
            if (YES_NO_DICTIONARY.hasOwnProperty(language)) {
                const dict = YES_NO_DICTIONARY[language];

                if (dict.hasOwnProperty(item.text)) {
                    let newItem = copyObject(item)
                    newItem['text'] = dict[item.text];
                    convertList.push(newItem);
                } else {
                    convertList.push(item);
                }
            } else {
                convertList.push(item);
            }
        }

        return convertList;
    }, [language]);


    /**
     *  @memberOf     IPConfig
     *  @function     handleSelect
     *  @param        {String} name - 선택 요소의 tag name
     *  @param        {String} value - 선택 되거나 변경 된 값
     *  @description  radio 버튼 클릭 시 호출되는 함수
     */
    const handleSelect = useCallback((name, value) => {
        setDataInfo(
            produce(dataInfo, draft => {
                draft[FIELD_ITEM_DATA][name] = value;
            })
        );
    }, [dataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleChange
     *  @param        {String} name   - 입력 요소의 tag name
     *  @param        {String} fieldName  - 입력 요소의 field name
     *  @param        {String} value  -  입력 되거나 변경 된 값
     *  @description  input 필드 변경 시 호출되는 함수
     */
    const handleChange = useCallback((name, fieldName, value) => {
        setIpInputFieldData(
            produce(ipInputFieldData, draft => {
                draft[name][fieldName] = value;

                // option 변경 시 other data 지우기
                if (fieldName === FIELD_SHAPE && value !== 'Other' && draft[name][FIELD_SHAPE_OTHER] !== '') {
                    draft[name][FIELD_SHAPE_OTHER] = '';
                }

                if (fieldName === FIELD_UNIT && value !== 'Other' && draft[name][FIELD_UNIT_OTHER] !== '') {
                    draft[name][FIELD_UNIT_OTHER] = '';
                }
            })
        );
    }, [ipInputFieldData]);

    /**
     *  @memberOf     IPConfig
     *  @function     validInputFieldData
     *  @param        {String} name - ip field name
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  table input 값 입력 시 입력 된 값들에 대한 validation 처리 함수
     */
    const validInputFieldData = useCallback((name) => {
        const validationField = ipInputFieldData[name];
        const validateKeys = Object.keys(validationField);
        let inputValidObject = {};
        let returnBool = true;

        for (const key of validateKeys) {
            switch (key) {
                case 'shape_other':
                    if (validationField.shape !== 'Other') {
                        if (isDataExist(validationField.shape)) {
                            inputValidObject[key] = true;
                        }
                    } else {
                        inputValidObject[key] = isDataExist(validationField.shape_other);
                        // isDataExist(validationField.shape_other) ? inputValidObject['shape_other'] = true : inputValidObject['shape_other'] = false;
                    }
                    break;

                case 'unit_other':
                    if (validationField.unit !== 'Other') {
                        if (isDataExist(validationField.unit)) {
                            inputValidObject[key] = true;
                        }
                    } else {
                        inputValidObject[key] = isDataExist(validationField.unit_other);
                        // isDataExist(validationField.unit_other) ? inputValidObject['unit_other'] = true : inputValidObject['unit_other'] = false;
                    }
                    break;

                default:
                    inputValidObject[key] = isDataExist(validationField[key]);
                    break;
            }
        }

        setDataInfo(produce(dataInfo, draft => {
            draft[FIELD_VALID_DATA][name] = inputValidObject
        }));

        returnBool = !JSON.stringify(inputValidObject).includes('false'); // 객체 false 찾기 용이하게 하려고 stringify 처리

        return returnBool;
    }, [dataInfo, ipInputFieldData]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleAdd
     *  @param        {String} name - 입력 요소의 tag name
     *  @description  추가 버튼 클릭 시 실행되는 함수
     */
    const handleAdd = useCallback((name) => {
        const inputField = ipInputFieldData[name];
        const validation = validInputFieldData(name);
        // dataTable에 추가할 데이터 필드 정의
        let filteredData = {
            shape: inputField.shape,
            name: inputField.name,
            dose: inputField.dose,
            unit: inputField.unit
        };

        if (!validation) {
            return;
        }

        // shape 가 other로 설정됐을 경우,
        if (ipInputFieldData[name].shape === 'Other') {
            filteredData.shape = inputField.shape_other;
        }

        // unit 이 other로 설정됐을 경우,
        if (ipInputFieldData[name].unit === 'Other') {
            filteredData.unit = inputField.unit_other;
        }

        // 데이터 추가
        setDataInfo(
            produce(dataInfo, draft => {
                // 데이터 없으면 name에 빈 배열 추가한 뒤, 데이터 넣기
                if (!dataInfo[FIELD_ITEM_DATA].hasOwnProperty(name)) {
                    draft[FIELD_ITEM_DATA][name] = [];
                }
                draft[FIELD_ITEM_DATA][name].push(filteredData);
            })
        );

        // add 후 입력 필드 초기화
        setIpInputFieldData(
            produce(ipInputFieldData, draft => {
                draft[name] = FIELD_DATA_DEFINE;
            })
        );
    }, [dataInfo, ipInputFieldData, validInputFieldData]);

    /**
     *  @memberOf     IPConfig
     *  @function     validateData
     *  @return       {Boolean} returnBool - validation 값 (true - validation success, false - validation fail)
     *  @description  입력 값 validation
     */
    const validateData = useCallback(() => {
        const validation = dataInfo[FIELD_VALID_DATA];
        const validateKeys = Object.keys(validation); // validation 할 key
        let validData = {};
        let returnBool = true;

        for (let key of validateKeys) {
            // active control 목록이 있는 지 확인
            if (key === 'active' && designModelType !== 'Active') {
                validData[key] = true;
                continue;
            }

            if (key === 'placebo' && designModelType !== 'Placebo') {
                validData[key] = true;
                continue;
            }

            // CONCOMITANT drug 목록이 있는 지 확인
            if (key === 'concomitant' && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_ADMINISTRATION] !== "Y") {
                validData[key] = true;
                continue;
            }

            // pre-treatment 목록이 있는 지 확인
            if (key === 'pretreatment' && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_PRE_TREATMENT] !== "Y") {
                validData[key] = true;
                continue;
            }

            // Other 목록이 있는 지 확인
            if (key === 'others' && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_OTHERS] !== "Y") {
                validData[key] = true;
                continue;
            }

            validData[key] = isDataExist(dataInfo[FIELD_ITEM_DATA][key]);

            if (key === 'investigation' || key === 'active' || key === 'placebo' || key === 'concomitant' || key === 'pretreatment' || key === 'others') {
                if (!isDataExist(dataInfo[FIELD_ITEM_DATA][key])) {
                    const inputObject = {};
                    const currentInputInfo = ipInputFieldData[key];
                    const validInputKeys = Object.keys(currentInputInfo);

                    for (let validInputKey of validInputKeys) {
                        switch (validInputKey) {
                            case 'shape_other':
                                if (currentInputInfo.shape !== 'Other') {
                                    if (isDataExist(currentInputInfo.shape)) {
                                        inputObject[validInputKey] = true;
                                    }
                                } else {
                                    inputObject[validInputKey] = isDataExist(currentInputInfo.shape_other);
                                    // isDataExist(currentInputInfo.shape_other) ? inputObject[validInputKey] = true : inputObject[validInputKey] = false;
                                }
                                break;

                            case 'unit_other':
                                if (currentInputInfo.unit !== 'Other') {
                                    if (isDataExist(currentInputInfo.unit)) {
                                        inputObject[validInputKey] = true;
                                    }
                                } else {
                                    inputObject[validInputKey] = isDataExist(currentInputInfo.unit_other);
                                    // isDataExist(currentInputInfo.unit_other) ? inputObject[validInputKey] = true : inputObject[validInputKey] = false;
                                }
                                break;

                            default:
                                inputObject[validInputKey] = isDataExist(currentInputInfo[validInputKey]);
                                break;
                        }
                    }
                    validData[key] = inputObject;
                }
            }
        }

        // validateData
        setDataInfo(
            produce(dataInfo, draft => {
                draft[FIELD_VALID_DATA] = validData;
            })
        );

        // validation 값이 true 인지 체크
        returnBool = !JSON.stringify(validData).includes('false');

        return returnBool;
    }, [dataInfo, designModelType, ipInputFieldData]);

    /**
     *  @memberOf     IPConfig
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        if (onList !== undefined) {
            onList();
        }
    }, [onList]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleReference
     *  @description  ref 버튼 클릭시 실행되는 함수
     */
    const handleReference = useCallback(() => {
        if (referencePopup !== undefined) {
            referencePopup();
        }
    }, [referencePopup]);

    /**
     *  @memberOf     IPConfig
     *  @function     checkInputList
     *  @return       {Array} returnList - 새로운 dataList
     *  @description  데이터 저장 시 실행 되어 inputInfo 입력 값을 체크해서 dataList에 합치기
     */
    const checkInputList = useCallback(() => {
        const dataTableKeys = ['investigation', 'active', 'placebo', 'concomitant', 'pretreatment', 'others'];  // input 영역이 존재하는 필드명들
        let returnObject = copyObject(dataInfo[FIELD_ITEM_DATA]); // 데이터 원본 훼손 방지 위한 복사

        dataTableKeys.forEach((key) => {
            const inputObject = {};
            const currentInputInfo = ipInputFieldData[key];
            const validInputKeys = Object.keys(currentInputInfo);

            for (let validInputKey of validInputKeys) {
                switch (validInputKey) {
                    case 'shape_other': // shape other 체크 시 other 필드 validation 처리
                        if (currentInputInfo.shape !== 'Other') {
                            if (isDataExist(currentInputInfo.shape)) {
                                inputObject[validInputKey] = true;
                            }
                        } else {
                            inputObject[validInputKey] = isDataExist(currentInputInfo.shape_other);
                            // isDataExist(currentInputInfo.shape_other) ? inputObject[validInputKey] = true : inputObject[validInputKey] = false;
                        }
                        break;

                    case 'unit_other': // unit other 체크 시 other 필드 validation 처리
                        if (currentInputInfo.unit !== 'Other') {
                            if (isDataExist(currentInputInfo.unit)) {
                                inputObject[validInputKey] = true;
                            }
                        } else {
                            inputObject[validInputKey] = isDataExist(currentInputInfo.unit_other);
                            // isDataExist(currentInputInfo.unit_other) ? inputObject[validInputKey] = true : inputObject[validInputKey] = false;
                        }
                        break;

                    default:
                        inputObject[validInputKey] = isDataExist(currentInputInfo[validInputKey]);
                        break;
                }
            }

            const stringObject = JSON.stringify(inputObject);

            if (!stringObject.includes('false')) {
                const inputField = ipInputFieldData[key];
                // dataTable에 추가할 데이터 필드 정의
                let filteredData = {
                    ID: '',
                    shape: inputField.shape,
                    name: inputField.name,
                    dose: inputField.dose,
                    unit: inputField.unit,
                }

                // shape 가 other로 설정됐을 경우,
                if (inputField.shape === 'Other') {
                    filteredData.shape = inputField.shape_other;
                }

                // unit 이 other로 설정됐을 경우,
                if (inputField.unit === 'Other') {
                    filteredData.unit = inputField.unit_other;
                }

                // handleAdd하기 전 데이터 객체가 없다면 빈배열 먼저 생성
                if (returnObject[key] === undefined) {
                    returnObject[key] = [];
                }

                returnObject[key].push(filteredData);
            }
        });

        return returnObject;
    }, [ipInputFieldData, dataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleGridChange
     *  @param        {Array}  list  - Endpoint 변경 된 List
     *  @param        {String} name - ip data name
     *  @description  grid 변경 시(수정, Drag & Drop, 삭제) 실행되는 함수
     */
    const handleGridChange = useCallback((list, name) => {
        let _dataInfo = copyObject(dataInfo);

        _dataInfo[FIELD_ITEM_DATA][name] = [...list];

        setDataInfo(_dataInfo);
    }, [dataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     getImageBlob
     *  @param        {Function} callback - image 저장 후 실행시킬 callback 함수
     *  @description  save 버튼 클릭 시 실행되어 현재 캡쳐 영역 image 생성 후 callback 함수 실행
     */
    const getImageBlob = useCallback((callback) => {
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0);
            const blobBin = window.atob(image.split(',')[1]);	// base64 데이터 디코딩
            const array = [];

            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }

            const blobImage = new Blob([new Uint8Array(array)], {type: 'image/png'});	// Blob 생성

            callback(blobImage);
        });
    }, []);

    /**
     *  @memberOf     IPConfig
     *  @function     saveDataInfo
     *  @param        {Blob} image - 서버에 보낼 image file 객체
     *  @description  image 생성 시 callback 으로 실행되어 데이터 저장 api 호출
     */
    const saveDataInfo = useCallback((image) => {
        const newSendList = checkInputList();

        const command = COMMAND.DATA_UPDATE;// 데이터 업데이트 command
        const formData = new FormData();
        const sendObject = {
            checkAdministration: newSendList[IP_DATA_DEFINE.CHECK_ADMINISTRATION],
            checkPretreatment: newSendList[IP_DATA_DEFINE.CHECK_PRE_TREATMENT],
            checkOthers: newSendList[IP_DATA_DEFINE.CHECK_OTHERS],
            investigation: newSendList[IP_DATA_DEFINE.INVESTIGATION],
            concomitant: newSendList[IP_DATA_DEFINE.CHECK_ADMINISTRATION] === 'Y' ? newSendList[IP_DATA_DEFINE.CONCOMITANT] : [],
            pretreatment: newSendList[IP_DATA_DEFINE.CHECK_PRE_TREATMENT] === 'Y' ? newSendList[IP_DATA_DEFINE.PRE_TREATMENT] : [],
            others: newSendList[IP_DATA_DEFINE.CHECK_OTHERS] === 'Y' ? newSendList[IP_DATA_DEFINE.OTHERS] : [],
            active: designModelType === 'Active' || 'Active/Placebo control' ? newSendList[IP_DATA_DEFINE.ACTIVE] : [],
            placebo: designModelType === 'Placebo' || 'Active/Placebo control' ? newSendList[IP_DATA_DEFINE.PLACEBO] : []
        };

        formData.append("data", JSON.stringify(sendObject));
        formData.append("file", image, "ipconfig.png");

        // 데이터 생성에 필요한 parameter
        const params = {
            requestUrl: DESIGN_URL,
            ID: ID,
            sendObject: formData
        };

        (netWorkAgent.current !== null && netWorkAgent.current.request(command, params));
    }, [ID, checkInputList, designModelType]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleChangeData
     *  @description  원본 데이터와 화면에 보이는 데이터를 전달하는 함수
     */
    const handleChangeData = useCallback(() => {
        onChangeData(dataInfo, originDataInfo);
    }, [dataInfo, onChangeData, originDataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleSave
     *  @description  Save 버튼 클릭시 호출 되는 함수
     */
    const handleSave = useCallback(() => {
        const valid = validateData();
        if (valid === false) {
            return;
        }

        getImageBlob(saveDataInfo);
    }, [validateData, getImageBlob, saveDataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     handleFocus
     *  @param        {String} name - ip name
     *  @param        {String} inputName  - Focus 된 요소의 tag name
     *  @description  validation invalid 처리를 위한 기능
     */
    const handleFocus = useCallback((name, inputName) => {
        const validation = dataInfo[FIELD_VALID_DATA];

        // validation 데이터에 포함되어 있는지 체크
        if (validation.hasOwnProperty(name)) {
            if (inputName && validation[name][inputName] === false) {
                setDataInfo(
                    produce(dataInfo, draft => {
                        draft[FIELD_VALID_DATA][name][inputName] = true;
                    })
                );
            } else if (!inputName && validation[name] === false) {
                setDataInfo(
                    produce(dataInfo, draft => {
                        draft[FIELD_VALID_DATA][name] = true;
                    })
                );
            }
        }
    }, [dataInfo]);

    /**
     *  @memberOf     IPConfig
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 검색어
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command} = action;

            switch (command) {
                // 상세 데이터 조회에 대한 응답 시
                case COMMAND.DATA_INFO :
                    if (data.hasOwnProperty('data')) {
                        if (data.data.constructor === Object && Object.keys(data.data).length !== 0) {
                            let validDataSet = {}; // validation 정보

                            for (let key in data.data) {
                                switch (key) {
                                    case IP_DATA_DEFINE.ACTIVE:
                                    case IP_DATA_DEFINE.PLACEBO:
                                    case IP_DATA_DEFINE.CONCOMITANT:
                                    case IP_DATA_DEFINE.INVESTIGATION:
                                    case IP_DATA_DEFINE.OTHERS:
                                    case IP_DATA_DEFINE.PRE_TREATMENT:
                                        validDataSet[key] = FIELD_DATA_VALID;
                                        break;

                                    default:
                                        validDataSet[key] = true;
                                        break;
                                }
                            }

                            // state 데이터 세팅
                            setDataInfo(
                                produce(dataInfo, draft => {
                                    draft[FIELD_ITEM_DATA] = data.data;
                                    draft[FIELD_VALID_DATA] = validDataSet;
                                })
                            );

                            setOriginDataInfo(
                                produce(dataInfo, draft => {
                                    draft[FIELD_ITEM_DATA] = data.data;
                                    draft[FIELD_VALID_DATA] = validDataSet;
                                })
                            );
                        }
                    }
                    break;

                // 데이터 수정에 대한 응답시
                case COMMAND.DATA_UPDATE:
                    showToast((() => {
                        getDataInfo();
                        // 필드 데이터 초기화
                        setIpInputFieldData(INPUT_DATA_FIELD);
                    })());
                    break;

                case COMMAND.DESIGN_INFO :
                    if (data.hasOwnProperty('data')) {
                        setDesignModelType(data.data.controlType);
                    }
                    break;

                // no default
            }
        }
    }, [dataInfo, getDataInfo, showToast]);

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    // 수정 모드일 때 list 데이터 요청
    useEffect(() => {
        getDataInfo();
    }, [getDataInfo]);

    // design Tab 상세 정보 요청
    useEffect(() => {
        getDesignDataInfo();
    }, [getDesignDataInfo]);

    // design model이 active일 경우 validation 데이터 추가 설정
    useEffect(() => {
        if (designModelType === 'Active') {
            setDataInfo(data => {
                return (
                    {
                        ...data,
                        [FIELD_VALID_DATA]: {
                            ...data[FIELD_VALID_DATA],
                            active: FIELD_DATA_VALID,
                            placebo: FIELD_DATA_VALID
                        }
                    }
                );
            });
        } else if (designModelType === 'Placebo') {
            setDataInfo(data => {
                return (
                    {
                        ...data,
                        [FIELD_VALID_DATA]: {
                            ...data[FIELD_VALID_DATA],
                            placebo: FIELD_DATA_VALID
                        }
                    }
                );
            });
        }
    }, [designModelType]);

    useEffect(() => {
        handleChangeData();
    }, [handleChangeData]);

    //데이터 변경 후 Tab 이동 시 저장하라고 나오는 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isSaved === true) {
            onSetClose(false);
            handleSave();
            onSetSave(false);
        }

        if (isClosed === true && isSaved === false) {
            getDataInfo();
            onSetClose(false);

        }

        onDataChangeModal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosed, isSaved, onDataChangeModal, onSetClose, onSetSave]);

    // Design Top Tab에서 데이터 변경 후 이동 시 저장 알림 Modal에서 Yes / NO를 클릭 한 경우
    useEffect(() => {
        if (isChangeTabSave === true) {
            onChangeTabClose(false);
            handleSave();
            onChangeTabSave(false);
        }

        if (isChangeTabClose === true && isChangeTabSave === false) {
            getDataInfo();
            onChangeTabClose(false);
        }
        onChangeDataCheckModal(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeTabClose, isChangeTabSave, onChangeDataCheckModal, onChangeTabClose, onChangeTabSave]);
    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box-row">
                <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                        <PerfectScrollbar className="height-full p-30" options={{suppressScrollX: true}}>
                            <div ref={captureRef}>
                                {/* Investigational Product */}
                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Investigational Product' : '임상시험용 의약품'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <IpConfigTableList
                                                name={IP_DATA_DEFINE.INVESTIGATION}
                                                dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.INVESTIGATION) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.INVESTIGATION] : []}
                                                currentValue={ipInputFieldData[IP_DATA_DEFINE.INVESTIGATION]}
                                                onAddItem={handleAdd}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.INVESTIGATION]}
                                                disabled={lock === 2}
                                                onGridChange={handleGridChange}/>
                                        </div>
                                    </div>
                                </div>

                                {/* Active control medication */}
                                {(designModelType === 'Active' || designModelType === 'Active/Placebo control') && (
                                    <div className="form-group row align-items-center m-b-20 ">
                                        <label className="col-2">
                                            {language === 'en' ? 'Active control medication' : '활성대조약'}
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </label>

                                        <div className="col-10">
                                            <div>
                                                <IpConfigTableList
                                                    name={IP_DATA_DEFINE.ACTIVE}
                                                    dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.ACTIVE) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.ACTIVE] : []}
                                                    currentValue={ipInputFieldData[IP_DATA_DEFINE.ACTIVE]}
                                                    onAddItem={handleAdd}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.ACTIVE]}
                                                    disabled={lock === 2}
                                                    onGridChange={handleGridChange}/>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* placebo */}
                                {(designModelType === 'Placebo' || designModelType === 'Active/Placebo control') && (
                                    <div className="form-group row align-items-center m-b-20 ">
                                        <label className="col-2">
                                            {language === 'en' ? 'Placebo' : '위약'}
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </label>

                                        <div className="col-10">
                                            <div>
                                                <IpConfigTableList
                                                    name={IP_DATA_DEFINE.PLACEBO}
                                                    dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.PLACEBO) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.PLACEBO] : []}
                                                    currentValue={ipInputFieldData[IP_DATA_DEFINE.PLACEBO]}
                                                    onAddItem={handleAdd}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.PLACEBO]}
                                                    disabled={lock === 2}
                                                    onGridChange={handleGridChange}/>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Are there any medications administered with Investigational product (IP)?' : '임상시험용 의약품과 함께 투여될 약물(연구 계획서에 정의된)이 있습니까?'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={IP_DATA_DEFINE.CHECK_ADMINISTRATION}
                                                dataList={getConvertSelectList(YN_OPTION)}
                                                onChange={handleSelect}
                                                validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.CHECK_ADMINISTRATION]}
                                                onFocus={(name, value) => handleFocus(name, undefined, value)}
                                                currentValue={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_ADMINISTRATION) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_ADMINISTRATION] : []}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_ADMINISTRATION) && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_ADMINISTRATION] === "Y" && (
                                    <div className="form-group row align-items-center m-b-20 ">
                                        <label className="col-2">
                                            {language === 'en' ? 'Medication administered with IP' : '병행투여 약물'}
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </label>

                                        <div className="col-10">
                                            <div className=' '>
                                                <IpConfigTableList
                                                    name={IP_DATA_DEFINE.CONCOMITANT}
                                                    dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CONCOMITANT) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CONCOMITANT] : []}
                                                    currentValue={ipInputFieldData[IP_DATA_DEFINE.CONCOMITANT]}
                                                    onAddItem={handleAdd}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.CONCOMITANT]}
                                                    disabled={lock === 2}
                                                    onGridChange={handleGridChange}/>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Are there any pre-treatment medications?' : '임상시험용 의약품 투여 전 사용된 전처치 약물이 있습니까?'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={IP_DATA_DEFINE.CHECK_PRE_TREATMENT}
                                                dataList={getConvertSelectList(YN_OPTION)}
                                                onChange={handleSelect}
                                                onFocus={(name, value) => handleFocus(name, undefined, value)}
                                                validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.CHECK_PRE_TREATMENT]}
                                                currentValue={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_PRE_TREATMENT) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_PRE_TREATMENT] : []}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_PRE_TREATMENT) && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_PRE_TREATMENT] === "Y" && (
                                    <div className="form-group row align-items-center m-b-20 ">
                                        <label className="col-2">
                                            {language === 'en' ? 'Pre-treatment medication' : '전처치 약물'}
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </label>

                                        <div className="col-10">
                                            <div>
                                                <IpConfigTableList
                                                    name={IP_DATA_DEFINE.PRE_TREATMENT}
                                                    dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.PRE_TREATMENT) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.PRE_TREATMENT] : []}
                                                    onAddItem={handleAdd}
                                                    currentValue={ipInputFieldData[IP_DATA_DEFINE.PRE_TREATMENT]}
                                                    onFocus={handleFocus}
                                                    validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.PRE_TREATMENT]}
                                                    onChange={handleChange}
                                                    disabled={lock === 2}
                                                    onGridChange={handleGridChange}/>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="form-group row align-items-center m-b-20 ">
                                    <label className="col-2">
                                        {language === 'en' ? 'Are there any rescue medication?' : '연구에 사용된 구제약이 있습니까?'}
                                        <span className='text-red p-l-2 p-r-2'>*</span>
                                    </label>

                                    <div className="col-10">
                                        <div>
                                            <RadioButton
                                                name={IP_DATA_DEFINE.CHECK_OTHERS}
                                                dataList={getConvertSelectList(YN_OPTION)}
                                                onChange={handleSelect}
                                                validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.CHECK_OTHERS]}
                                                onFocus={(name, value) => handleFocus(name, undefined, value)}
                                                currentValue={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_OTHERS) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_OTHERS] : []}
                                                disabled={lock === 2}/>
                                        </div>
                                    </div>
                                </div>

                                {dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.CHECK_OTHERS) && dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.CHECK_OTHERS] === "Y" && (
                                    <div className="form-group row align-items-center m-b-20 ">
                                        <label className="col-2">
                                            {language === 'en' ? 'Rescue medication' : '구제약'}
                                            <span className='text-red p-l-2 p-r-2'>*</span>
                                        </label>

                                        <div className="col-10">
                                            <div>
                                                <IpConfigTableList
                                                    name={IP_DATA_DEFINE.OTHERS}
                                                    dataList={dataInfo[FIELD_ITEM_DATA] && dataInfo[FIELD_ITEM_DATA].hasOwnProperty(IP_DATA_DEFINE.OTHERS) ? dataInfo[FIELD_ITEM_DATA][IP_DATA_DEFINE.OTHERS] : []}
                                                    currentValue={ipInputFieldData[IP_DATA_DEFINE.OTHERS]}
                                                    onAddItem={handleAdd}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    validation={dataInfo[FIELD_VALID_DATA][IP_DATA_DEFINE.OTHERS]}
                                                    disabled={lock === 2}
                                                    onGridChange={handleGridChange}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>

            {/* 하단 처리 버튼 */}
            <DesignBottom onList={goToList} onRef={handleReference} onSave={handleSave}/>
        </>
    );
};

export default React.memo(IPConfig);
