import React from 'react';
import { Collapse } from 'reactstrap';

class DropdownMegaMenu extends React.Component {
    render() {
        return (
            <Collapse className="collapse navbar-collapse" isOpen={this.props.collapse}>
                <ul className="nav navbar-nav">
                </ul>
            </Collapse>
        )
    }
}

export default DropdownMegaMenu;
