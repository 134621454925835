import React, {useCallback, useEffect, useRef, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    AXIOS_GET,
    AXIOS_POST_FORM,
    AXIOS_PUT,
    copyObject,
    getSessionState,
    isDataExist
} from "../../../../../common/commonFunction";
import {COMMAND} from "../../../../../common/dataProcessAgent";
import NetworkLayout from "../../../../../common/NetworkLayout";
import {SDTM_MAPPING_URL} from "../../../../../constant/ConstantURL";
import DnDList from "../../../../components/DnDList";
import cn from "classnames";
import axios from "axios";
import ACRFTableOfContents from "./ACRFTableOfContents";
import useToast from "../../../../../hooks/useToast";
import ACRFCover from "./ACRFCover";
import ACRFForm2 from "./ACRFForm2";
import ECRFSchedule from "../../../design/crf/ECRFSchedule";

//Annotation 추가, 수정, 삭제 전역으로 데이터 관리
export let SEND_ANNOTATION_INFO = {};

/*################################################################################*/
//## constant 관련
/*################################################################################*/
/**
 *  @memberOf     ACRFMain
 *  @constant     {Object} MAXIMUM_ANNOTATION
 *  @description  Annotation 최대 갯수
 */
export const MAXIMUM_ANNOTATION = 4;

/**
 *  @memberOf     ACRFMain
 *  @constant     {Object} BOX_COLOR
 *  @description  Annotation 색 정의
 */
export const BOX_COLOR = [
    "#BFFFFF", //BLUE
    "#FFFF96", //YELLOW
    "#96FF96", //GREEN
    "#FFBE9B" //ORANGE
];

/**
 *  @memberOf     ACRFMain
 *  @async        dataProcess
 *  @param        {String} command - 통신 데이터 처리 action type
 *  @param        {Object} params -  통신 데이터 처리를 위한 parameter 객체
 *  @return       {Object} response.data - 서버 응답 데이터
 *  @description  command 에 따른 통신 데이터 처리
 */
async function dataProcess(command, params) {
    const {requestUrl, ID, sendObject, type} = params;
    let url = null;
    let response = null;

    switch (command) {
        // 데이터 상세 정보 요청
        case COMMAND.DATA_INFO:
            url = `${requestUrl}/${ID}`;
            response = await AXIOS_GET(url);
            break;

        //pdf 다운로드
        case COMMAND.FILE_DOWNLOAD:
            url = `${requestUrl}/${ID}?type=${type}`;
            response = await AXIOS_POST_FORM(url, sendObject, 60000);
            break;

        case COMMAND.DATA_UPDATE:
            url = `${requestUrl}/${ID}`;
            response = await AXIOS_PUT(url, sendObject);
            break;

        default:
            return null;
    }

    return response.data;
}

/**
 *  @author       백도형
 *  @version      1.0
 *  @component    ACRFMain
 *  @param        {Object} props - 상위 컴포넌트에서 전달 받은 property
 *  @description  Mapping List 의 상세화면 내 ACRF Tab 컴포넌트
 */
const ACRFMain = (props) => {
    /**
     *  @memberOf     ACRFMain
     *  @type         {Object} props
     *  @property     {Object} history - url 이동을 위해 사용
     *  @description  상위 컴포넌트로부터 전달 받은 props
     */
    const {history} = props;

    /**
     *  @memberOf     ACRFMain
     *  @type         {Object} getSessionState
     *  @property     {String} ID - 목록에서 선택한 mapping의 ID
     *  @property     {Number} lock - 화면 수정 가능 여부(1: 수정 가능, 2: 수정 불가(Lock))
     *  @description  session 에서 받아오는 정보
     */
    const {ID, lock} = getSessionState();

    /**
     *  @memberOf     ACRFMain
     *  @var          {*} netWorkAgent
     *  @description  통신 괸련된 처리를 해주는 agent 컴포넌트
     */
    const netWorkAgent = useRef(null);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Function} showToast
     *  @description  toast 알림창을 실행하기 위한 함수
     */
    const [showToast] = useToast();

    /**
     *  @memberOf     ACRFMain
     *  @var          {Object} dataList
     *  @description  API에서 response 받은 data 목록
     */
    const [dataList, setDataList] = useState([]);

    /**
     *  @memberOf     ACRFMain
     *  @var          {String} selectedForm
     *  @description  form list 에서 선택한 form 의 ID 값
     */
    const [selectedForm, setSelectedForm] = useState(null);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Array} formList
     *  @description  왼쪽 Form List에 표시될 리스트
     */
    const [formList, setFormList] = useState();

    /**
     *  @memberOf     ACRFMain
     *  @var          {Array} processedDataList
     *  @description  화면을 그리기 위해 가공한 data 리스트
     */
    const [processedDataList, setProcessedDataList] = useState([]);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Boolean} isEdit
     *  @description  acrf 수정 관련 값(false: view, true: edit)
     */
    const [isEdit, setIsEdit] = useState(false);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Array} formContentsList
     *  @description  ACRF의 Forms의 목차에 해당하는 목록
     */
    const [formContentsList, setFormContentsList] = useState([]);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Array} visitContentsList
     *  @description  ACRF의 Forms의 목차에 해당하는 목록
     */
    const [visitContentsList, setVisitContentsList] = useState([]);

    /**
     *  @memberOf     ACRFMain
     *  @var          {Object} coverInfo
     *  @description  ACRF의 Cover 정보
     */
    const [coverInfo, setCoverInfo] = useState({});

    /**
     *  @memberOf     ACRFMain
     *  @var          {Object} eScheduleList
     *  @description  eCRF Schedule 데이터
     */
    const [eScheduleList, setEScheduleList] = useState({});

    /**
     *  @memberOf     ACRFMain
     *  @var          {Object} armList
     *  @description  armList 데이터
     */
    const [armList, setArmList] = useState();

    /**
     *  @memberOf     ACRFMain
     *  @var          {Object} visitList
     *  @description  visitList 데이터
     */
    const [visitList, setVisitList] = useState([]);

    /*################################################################################*/
    //## function define 영역
    //## - useCallback
    /*################################################################################*/
    /**
     *  @memberOf     ACRFMain
     *  @function     getDataInfo
     *  @description  상세 정보 api 호출
     */
    const getDataInfo = useCallback(() => {
        const command = COMMAND.DATA_INFO; // 데이터 리스트 요청 command
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/crf-json`,
            ID: ID
        };

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID]);

    /**
     *  @memberOf     ACRFMain
     *  @function     changeDataList
     *  @param        {Object} data - 하위에서 전달 받은 annotation 정보
     *  @description  Annotation 추가, 수정, 삭제 관련하여 화면 다시 그리는 state 변경 해주는 함수
     */
    const changeDataList = useCallback((data) => {
        let _dataList = copyObject(dataList);

        if (data.type === "FORM" || data.type === "FORMCOMMENT") { //type이 FORM 이나 FORMCOMMENT 일때
            const formInfo = _dataList.find(form => form.ID === data.path); //parentID와 같은 ID의 form 정보를 찾음

            if (formInfo !== undefined) { //form 정보를 찾았다면
                const annotationIdx = formInfo.allAnnotations.findIndex(annotation => annotation.ID === data.ID); //allAnnotations에 삭제될 data ID 비교해서 index 반환

                if (data.dataType === "add") { //annotation 추가 됐을 때
                    formInfo.annotationIDs.push(data.ID); //form에 포함된 annotationIDs에 ID를 추가
                    formInfo.allAnnotations.push(data); //allAnnotations에 data 추가
                } else if (data.dataType === "update") { //annotation 수정 됐을 때
                    if (annotationIdx > -1) {
                        formInfo.allAnnotations[annotationIdx].value = data.value; //찾은 index로 annotation에서 수정 된 value 값 변경
                        formInfo.allAnnotations[annotationIdx].borderType = data.borderType; //찾은 index로 annotation에서 수정 된 borderType 값 변경
                        formInfo.allAnnotations[annotationIdx].domain = data.domain; //찾은 index로 annotation에서 수정 된 domain 값 변경
                    }
                } else { //annotation 삭제 됐을 때
                    const IDFindIdx = formInfo.annotationIDs.findIndex(ID => ID === data.ID); //form에 포함된 annotationIDs에서 삭제될 index 반환

                    if (IDFindIdx > -1) {
                        formInfo.annotationIDs.splice(IDFindIdx, 1); //찾은 index로 annotationIDs에서 삭제
                    }

                    if (annotationIdx > -1) {
                        formInfo.allAnnotations.splice(annotationIdx, 1); //찾은 index로 allAnnotations에서 삭제
                    }

                    if (data.type === "FORM") {
                        formInfo.allAnnotations = formInfo.allAnnotations.filter(annoataion => annoataion.domain.substr(-2) !== data.domain.substr(-2)); //annotation 리스트에서 삭제된 domain을 포함한 data를 제외하고 filter
                    }
                }
            }
        } else if (data.type === "ITEMGROUP") { //type이 ITEMGROUP 일때
            const pathIDs = data.path.split("/"); //path "/" 분리
            const formID = pathIDs[0]; //form ID 저장
            const itemGroupID = pathIDs[1]; //ItemGroup ID 저장
            const formInfo = _dataList.find(form => form.ID === formID); //form 정보를 찾음

            if (formInfo !== undefined) {
                const igIdx = formInfo.itemGroups.findIndex(itemGroup => itemGroup.ID === itemGroupID); //path에 있는 ItemGroup ID의 itemGroup의 인덱스를 찾음
                const annotationIdx = formInfo.allAnnotations.findIndex(annotation => annotation.ID === data.ID); //allAnnotations에 삭제될 data ID 비교해서 index 반환

                if (igIdx > -1) { //index 값이 있다면
                    if (data.dataType === "add") { //annotation 추가 됐을 때
                        formInfo.itemGroups[igIdx].annotationIDs.push(data.ID); //itemGroups의 annotationsIDs리스트에 ID를 추가
                        formInfo.allAnnotations.push(data); //allAnnotations에 annotation 정보 추가
                    } else if (data.dataType === "update") {
                        if (annotationIdx > -1) {
                            formInfo.allAnnotations[annotationIdx].value = data.value; //찾은 index로 annotation에서 수정 된 value 값 변경
                            formInfo.allAnnotations[annotationIdx].domain = data.domain; //찾은 index로 annotation에서 수정 된 domain 값 변경
                            formInfo.allAnnotations[annotationIdx].borderType = data.borderType; //찾은 index로 annotation에서 수정 된 borderType 값 변경
                        }
                    } else {
                        const IDFindIdx = formInfo.itemGroups[igIdx].annotationIDs.findIndex(ID => ID === data.ID); //itemGroups에 포함된 annotationIDs에서 삭제될 index 반환

                        if (IDFindIdx > -1) {
                            formInfo.itemGroups[igIdx].annotationIDs.splice(IDFindIdx, 1); //찾은 index로 annotationIDs에서 삭제
                        }

                        if (annotationIdx > -1) {
                            formInfo.allAnnotations.splice(annotationIdx, 1); //찾은 index로 allAnnotations에서 삭제
                        }
                    }
                }
            }
        } else if (data.type === "QUESTION" || data.type === "RESULT") { //type이 QUESTION 이나 RESULT 일때
            const pathIDs = data.path.split("/"); //path "/" 분리
            const formID = pathIDs[0]; //form ID 저장
            const itemGroupID = pathIDs[1]; //ItemGroup ID 저장
            const itemID = pathIDs[2]; //Item ID 저장
            const formInfo = _dataList.find(form => form.ID === formID); //parentID에 포함된 formID 같은 ID의 form 정보를 찾음

            if (formInfo !== undefined) {
                const itemGroupInfo = formInfo.itemGroups.find(itemGroup => itemGroup.ID === itemGroupID); //parentID에 포함된 itemGroupID 같은 ID의 itemGroup 정보를 찾음
                if (itemGroupInfo !== undefined) {
                    const itemIdx = itemGroupInfo.items.findIndex(item => item.ID === itemID); //relID와 같은 ID의 item의 인덱스를 찾음
                    const annotationIdx = formInfo.allAnnotations.findIndex(annotation => annotation.ID === data.ID); //allAnnotations에 삭제될 data ID 비교해서 index 반환

                    if (itemIdx > -1) {
                        if (data.dataType === "add") {
                            itemGroupInfo.items[itemIdx].annotationIDs.push(data.ID); //items의 annotationsIDs리스트에 ID를 추가
                            formInfo.allAnnotations.push(data); //allAnnotations에 annotation 정보 추가
                        } else if (data.dataType === "update") {

                            if (annotationIdx > -1) {
                                formInfo.allAnnotations[annotationIdx].value = data.value; //찾은 index로 annotation에서 수정 된 value 값 변경
                                formInfo.allAnnotations[annotationIdx].domain = data.domain; //찾은 index로 annotation에서 수정 된 domain 값 변경
                                formInfo.allAnnotations[annotationIdx].borderType = data.borderType; //찾은 index로 annotation에서 수정 된 borderType 값 변경
                            }
                        } else if (data.dataType === "delete") {
                            const IDFindIdx = itemGroupInfo.items[itemIdx].annotationIDs.findIndex(ID => ID === data.ID); //items에 포함된 annotationIDs에서 삭제될 index 반환

                            if (IDFindIdx > -1) {
                                itemGroupInfo.items[itemIdx].annotationIDs.splice(IDFindIdx, 1); //찾은 index로 annotationIDs에서 삭제
                            }

                            if (annotationIdx > -1 && !data.ID.startsWith("NOTS")) {
                                formInfo.allAnnotations.splice(annotationIdx, 1); //찾은 index로 allAnnotations에서 삭제
                            }
                        }
                    }
                }
            }
        } else if (data.type === "CODELIST") {
            const pathIDs = data.path.split("/"); //path "/" 분리
            const formID = pathIDs[0]; //form ID 저장
            const codeListID = pathIDs[3]; //CodeList ID 저장
            const formInfo = _dataList.find(form => form.ID === formID); //parentID에 포함된 formID 같은 ID의

            if (formInfo !== undefined) {
                const codeListIdx = formInfo.codelists.findIndex(codelist => codelist.ID === codeListID); //relID와 같은 ID의 CodeList의 인덱스를 찾음
                const annotationIdx = formInfo.allAnnotations.findIndex(annotation => annotation.ID === data.ID); //allAnnotations에 삭제될 data ID 비교해서 index 반환

                if (codeListIdx > -1) {
                    if (data.dataType === "add") {
                        formInfo.codelists[codeListIdx].annotationIDs.push(data.ID); //codelists의 annotationsIDs리스트에 ID를 추가
                        formInfo.allAnnotations.push(data); //allAnnotations에 annotation 정보 추가
                    } else if (data.dataType === "update") {
                        if (annotationIdx > -1) {
                            formInfo.allAnnotations[annotationIdx].value = data.value; //찾은 index로 annotation에서 수정 된 value 값 변경
                            formInfo.allAnnotations[annotationIdx].domain = data.domain; //찾은 index로 annotation에서 수정 된 domain 값 변경
                            formInfo.allAnnotations[annotationIdx].borderType = data.borderType; //찾은 index로 annotation에서 수정 된 borderType 값 변경
                        }
                    } else if (data.dataType === "delete") {
                        const IDFindIdx = formInfo.codelists[codeListIdx].annotationIDs.findIndex(ID => ID === data.ID); //items에 포함된 annotationIDs에서 삭제될 index 반환

                        if (IDFindIdx > -1) {
                            formInfo.codelists[codeListIdx].annotationIDs.splice(IDFindIdx, 1); //찾은 index로 annotationIDs에서 삭제
                        }

                        if (annotationIdx > -1) {
                            formInfo.allAnnotations.splice(annotationIdx, 1); //찾은 index로 allAnnotations에서 삭제
                        }
                    }
                }
            }
        }

        setDataList(_dataList);
    }, [dataList]);

    /**
     *  @memberOf     ACRFMain
     *  @function     handleClickItem
     *  @param        {String} formID - edc list 의 ID
     *  @param        {String} formName - edc list 의 name
     *  @description  form list item 클릭 시 실행
     */
    const handleClickItem = useCallback((formID, formName) => {
        document.getElementById("form-container").scrollTop = 0;

        setSelectedForm(formID);
    }, []);

    /**
     *  @memberOf     ACRFMain
     *  @function     getFormComp
     *  @return       {Element} Form Data render
     *  @description  ACRF의 해당하는 Form 그려주는 함수
     */
    const getFormComp = useCallback(() => {
        if (processedDataList.length > 0 && dataList.length > 0) {
            return processedDataList.map((data, idx) => (
                <ACRFForm2
                    key={idx}
                    formID={selectedForm}
                    formData={data}
                    isEdit={isEdit}
                    onChangeDataList={changeDataList}
                    allAnnotations={dataList[idx].allAnnotations}
                    visitList={visitList[idx]}
                    armList={armList}
                    EDCType={coverInfo.edcType}
                />
            ));
        }
    }, [processedDataList, dataList, selectedForm, isEdit, changeDataList, visitList, armList]);

    /**
     *  @memberOf     ACRFMain
     *  @function     goToList
     *  @description  리스트 화면으로 이동하는 함수
     */
    const goToList = useCallback(() => {
        const pathname = '/sdtm/mapping';

        history.push({
            pathname: pathname,
            searchParams: history.location.searchParams
        });
    }, [history]);

    /**
     *  @memberOf     ACRFMain
     *  @function     handleDownload
     *  @param        {String} - Page Numbering 버튼인지 Download 버튼인지 구분하는 값
     *  @description  aCRF pdf로 다운로드 받는 함수
     */
    const handleDownload = useCallback((type) => {
        const acrfMain = document.getElementById("acrf-main-area"); //acrf-main-area를 id로 가지고 있는 element 정보를 가지고옴
        let outerACRFMain = acrfMain.outerHTML; //acrf element를 string으로 변경
        outerACRFMain = outerACRFMain.replaceAll('display: none', 'display: block'); //string으로 변경된 acrf element에서 'display: none' 속성을 'display: block'로 변경 작업
        //서버로 전달할 html, body로 감싼 string 코드 생성
        let strHTML = `<html lang="en">
                            <head>
                                <meta charset="utf-8" />
                                <title></title>
                            </head>
                            <body>${outerACRFMain}</body>
                        </html>`;
        const command = COMMAND.FILE_DOWNLOAD;
        const formData = new FormData();
        const file = new Blob([strHTML], {
            type: 'text/plain',
        });
        formData.append('html', file);
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/crf-pdf`,
            ID: ID,
            sendObject: formData,
            type: type
        };

        netWorkAgent.current.request(command, params);
    }, [ID]);

    /**
     *  @memberOf     ACRFMain
     *  @function     handleEdit
     *  @description  Edit 버튼 클릭 실행되는 함수
     */
    const handleEdit = useCallback(() => {
        if (lock !== 2) {
            setIsEdit(true);
        }
    }, [lock]);

    /**
     *  @memberOf     ACRFMain
     *  @function     handleSave
     *  @description  저장 버튼 클릭 실행되는 함수
     */
    const handleSave = useCallback(() => {
        let deleteIDList = [];
        SEND_ANNOTATION_INFO.delete.forEach((deleteData) => {
            deleteIDList.push(deleteData.ID);
        });

        //add -> delete 경우
        const addResult = SEND_ANNOTATION_INFO.add.filter(addData =>
            !deleteIDList.includes(addData.ID)
        );
        SEND_ANNOTATION_INFO.add = addResult;


        //update -> delete 경우
        const updateResult = SEND_ANNOTATION_INFO.update.filter(updateData =>
            !deleteIDList.includes(updateData.ID)
        );
        SEND_ANNOTATION_INFO.update = updateResult;

        //add -> update 경우
        let addIDList = [];
        SEND_ANNOTATION_INFO.add.forEach((addData) => {
            addIDList.push(addData.ID);
        });

        //add ID에 포함하는 UpdateList
        let updateID = [];
        const AddUpdateResult = SEND_ANNOTATION_INFO.update.filter(updateData =>
            addIDList.includes(updateData.ID)
        );

        AddUpdateResult.forEach((updateData) => {
            updateID.push(updateData.ID);
        });

        //add ID에 포함하지 않는 UpdateList
        const UpdateResult = SEND_ANNOTATION_INFO.update.filter(updateData =>
            !addIDList.includes(updateData.ID)
        );
        SEND_ANNOTATION_INFO.update = UpdateResult;

        //add에 updateID가 포함되지 않는 리스트
        const filterAddData = SEND_ANNOTATION_INFO.add.filter(addData =>
            !updateID.includes(addData.ID)
        );
        SEND_ANNOTATION_INFO.add = filterAddData;

        //add에 update 데이터 추가
        AddUpdateResult.forEach((data) => {
            SEND_ANNOTATION_INFO.add.push(data);
        });

        const command = COMMAND.DATA_UPDATE;
        const params = {
            requestUrl: `${SDTM_MAPPING_URL}/crf-json`,
            ID: ID,
            sendObject: JSON.stringify(SEND_ANNOTATION_INFO)
        }; // 데이터 수정에 필요한 parameter

        netWorkAgent.current.request(command, params); // back-end 데이터 처리 요청
    }, [ID]);

    /**
     *  @memberOf     ACRFMain
     *  @function     dataResponse
     *  @param        {Object} action - 요청시 보낸 정보(command, params)
     *  @param        {Object} data   - 서버에서 받은 response data
     *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
     */
    const dataResponse = useCallback((action, data) => {
        if (data) {
            const {command, params} = action;

            switch (command) {
                case COMMAND.DATA_INFO:
                    if (data.hasOwnProperty('data') && data.data !== null) {
                        const _formList = data.data.forms.map(form => {
                            return {
                                ID: form.ID,
                                name: form.name
                            };
                        }); //Form List에 표시된 리스트 생성

                        _formList.unshift({
                                ID: 'ACRFCover',
                                name: 'CRF Cover'
                            },
                            {
                                ID: 'eCRFSchedule',
                                name: 'Scheduled of Activities'
                            },
                            {
                                ID: 'ACRFTableOfContents',
                                name: 'Table of Contents'
                            });

                        //선택된 form OID 초깃값으로 저장
                        if (isDataExist(_formList)) {
                            let _selectedForm = selectedForm === null ? _formList[0].ID : selectedForm;

                            setSelectedForm(_selectedForm);
                        }

                        setFormList(_formList);
                        setDataList(data.data.forms);
                        setCoverInfo(data.data.coverInfo);
                        setEScheduleList(data.data.eSchedule);
                        setArmList(data.data.arm);
                    }
                    break;

                case COMMAND.FILE_DOWNLOAD:
                    if (data.hasOwnProperty('data')) {
                        if (params.type === "download") {
                            /**
                             * url을 가지고 axios로 blob으로 받아서 처리한다.
                             */
                            axios({
                                url: data.data.url,
                                method: 'GET',
                                responseType: 'blob'
                            }).then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', data.data.filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            });
                        } else {
                            showToast();
                        }
                    }
                    break;

                case COMMAND.DATA_UPDATE:
                    SEND_ANNOTATION_INFO = {
                        add: [],
                        update: [],
                        delete: []
                    }; //전역 변수 초기화

                    showToast(getDataInfo);

                    setIsEdit(false);
                    break;

                //no default
            }
        }
    }, [getDataInfo, selectedForm, showToast]);

    /**
     *  @memberOf     ACRFMain
     *  @function     setCommonDefaultValue
     *  @param        {Object} data - 수정할 데이터
     *  @param        {String} designID - default 값을 넣을 designID
     *  @param        {String} formID - default 값을 넣을 formID
     *  @param        {String} value - annotation에 들어갈 값
     *  @description  common question에 default값을 설정함
     */
    const setCommonDefaultValue = (data, designID, formID, value) => {
        const _data = copyObject(data);
        if (_data !== undefined && _data.length > 0) {
            if (_data[0].designID === designID) {
                _data.forEach((form) => {
                    if (form.ID === formID) {
                        form.itemGroups.forEach((itemGroup) => {
                            if (itemGroup.ID === 'COMMON') {
                                itemGroup.items.forEach((item) => {
                                    item.annotations = [];
                                    item.annotations.push({
                                        "type": "QUESTION",
                                        "borderType": "solid",
                                        "value": value,
                                        "domain": '',
                                        "background": BOX_COLOR[0]
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }
        return _data
    }

    /*################################################################################*/
    //## rerender effect 영역
    //## - useEffect
    /*################################################################################*/
    //acrf 정보 호출
    useEffect(() => {
        SEND_ANNOTATION_INFO = {
            add: [],
            update: [],
            delete: []
        };

        getDataInfo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDataInfo]);

    //Table of Contents를 위한 데이터 가공 하는 곳
    useEffect(() => {
        let _formContentsList = []; //Table of Contents(목차)에 표시 될 Form 목록
        let _visitContentsList = []; //Table of Contents(목차)에 표시 될 Visit 목록

        //Forms의 목차에 뿌려질 데이터 구성
        if (dataList.length > 0) {
            dataList.forEach(form => {
                let formObj = {
                    name: form.name
                }; //form 정보 생성
                let _studyEventList = []; //study event 목록 저장 변수

                form.studyEvents.forEach(studyEvent => {
                    if (studyEvent.itemGroupIDs.length > 0) {
                        let studyEventObj = {
                            name: studyEvent.name
                        }; //study event 정보 생성

                        _studyEventList.push(studyEventObj); //study event 목록에 study event 정보 저장
                    }
                });

                formObj.children = _studyEventList; //form 정보에 children으로 stduy event 목록 추가

                _formContentsList.push(formObj); //Table of Contents(목차)에 표시 될 Form 목록 추가
            });

            //Visits의 목차에 뿌려질 데이터 구성
            if (dataList.length > 0) {
                let setVisitName = new Set(); //중복 된 study event 이름 제거하여 저장

                //study event 돌면서 study event 이름 저장
                dataList.forEach(form => {
                    form.studyEvents.forEach(studyEvent => {
                        if (studyEvent.itemGroupIDs.length > 0) {
                            setVisitName.add(studyEvent.name);
                        }
                    });
                });

                //중복 제거 된 study event 이름으로 돌면서 visit 기준 목자 생성
                Array.from(setVisitName).forEach(studyEventName => {
                    let _formList = [];
                    let studyEventObj = {
                        name: studyEventName
                    };

                    dataList.forEach(form => {
                        let findIndex = form.studyEvents.findIndex(studyEvent => studyEvent.name === studyEventName && studyEvent.itemGroupIDs.length > 0);

                        if (findIndex > -1) {
                            _formList.push({
                                name: form.name
                            });
                        }
                    });

                    studyEventObj.children = _formList;

                    _visitContentsList.push(studyEventObj);
                });
            }

            setFormContentsList(_formContentsList);
            setVisitContentsList(_visitContentsList);
        }
    }, [dataList]);

    //화면에 그리기 위한 data list 가공하는 곳
    useEffect(() => {
        let _processDataList = [];

        if (dataList.length > 0) {
            //Form 기준으로 화면 만듬
            dataList.forEach(form => {
                let _formObj = copyObject(form);
                let _itemGroupIDs = []; //studyEvents에서 itemGroupIDs만 뽑아서 중복 제거 한 배열
                let _studyEventList = []; //가공한 studyEvents 배열

                //Form의 Annotation에 색깔 지정
                //Form에 해당하는 Form Annotation을 찾음
                let filterAnnotations = []; //form에 해당하는 annotation을 저장
                let boxColorObj; //Annotation의 색깔 도메인별로 지정하는 변수

                //form에 해당하는 annotation들을 추출
                if (form.hasOwnProperty("annotationIDs")) {
                    form.annotationIDs.forEach(ID => {
                        form.allAnnotations.forEach(annotation => {
                            if (annotation.ID === ID && annotation.type === "FORM") {
                                filterAnnotations.push(annotation);
                            }
                        });
                    });
                }

                //추출한 annotation 리스트가지고 도메인별로 색깔 지정
                filterAnnotations.forEach((annotation, idx) => {
                    boxColorObj = {
                        ...boxColorObj,
                        [annotation.domain]: BOX_COLOR[idx]
                    };
                });

                //form에 annotations 추가
                let formAnnotations = []; //form에 해당하는 annotation을 저장하기 위한 배열 변수

                if (_formObj.hasOwnProperty("annotationIDs")) {
                    if (_formObj.annotationIDs.length > 0) { //form에 annotationIDs의 길이가 0개면 추가 필요가 없음
                        //form 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                        _formObj.annotationIDs.forEach(ID => {
                            let findObj = form.allAnnotations.find(annotation => annotation.ID === ID && annotation.value !== "");

                            if (findObj !== undefined) {
                                findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가

                                formAnnotations.push(findObj);
                            }
                        });
                    }
                }

                _formObj.annotations = formAnnotations;

                //itemGroupIDs 만 안겹치게 뽑아내기
                form.studyEvents.forEach(studyEvent => {
                    let findIdx = _itemGroupIDs.findIndex(data => JSON.stringify(studyEvent.itemGroupIDs) === JSON.stringify(data)); //없는 데이터 찾기 위함

                    //찾는 인덱스가 없을 때만 추가
                    if (findIdx === -1) {
                        _itemGroupIDs.push(studyEvent.itemGroupIDs); //_itemGroupIDs에 itemGroupIDs 값을 넣는다.
                    }
                });

                //안겹치게 뽑아낸 itemGroupIDs에 해당하는 study event 값들 넣기
                _itemGroupIDs.forEach(itemGroupIDList => {
                    if (itemGroupIDList.length > 0) { //itemGroupID가 있는 경우에만
                        let studyEventObj; //studyEvents에서 Study Event 정보 저장해놓기 위한 변수
                        let studyEventIDs = []; //Study Event의 ID들 저장하기 위한 변수
                        let studyEventNames = []; //Study Event의 Name들 저장하기 위한 변수

                        //같은 itemGroup을 가지고 있는 경우 Study Event의 Name을 합치기 위함
                        form.studyEvents.forEach(studyEvent => {
                            if (JSON.stringify(itemGroupIDList) === JSON.stringify(studyEvent.itemGroupIDs)) { //itemGroupID와 study event에 저장된 itemGroupIDs 와 같으면
                                studyEventObj = copyObject(studyEvent); //Study Event 정보를 저장
                                studyEventIDs.push(studyEvent.ID); //Study Event ID를 배열에 추가
                                studyEventNames.push(studyEvent.name); //Study Event Name을 배열에 추가
                            }
                        });

                        studyEventObj.ID = studyEventIDs.join(', '); //Study Event ID 배열에 ','로 join 하여 study event 정보에 추가
                        studyEventObj.name = studyEventNames.join(', '); //Study Event Name 배열에 ','로 join 하여 study event 정보에 추가

                        _studyEventList.push(studyEventObj); //가공된 study event 정보를 배열에 추가
                    }
                });

                //가공된 studyEvents에 itemGroup 정보 포함
                _studyEventList.forEach(studyEvent => {
                    let _itemGroupList = []; //가공한 itemGroups 배열
                    let _itemGroupDescArr = []; // itemGroup의 description 모은 배열
                    let _itemCommentArr = []; // item의 comment 모은 배열


                    //itemGroups 배열을 반복
                    form.itemGroups.forEach(itemGroup => {
                        let _itemGroupObj = copyObject(itemGroup); //itemGroup을 저장
                        let _logDirection = '';
                        let _repeating = '';

                        //studyEvent 정보에 itemGroupIDs을 반복
                        for (let igID of studyEvent.itemGroupIDs) {
                            //Study Event와 ItemGroup의 ID를 비교하여 ID가 같으면 정보 저장
                            if (igID === itemGroup.ID) { //ID가 같다면
                                let _itemList = []; //itemGroup에 포함된 item들 저장

                                _logDirection = form.logDirection;
                                _repeating = itemGroup.repeating;

                                // itemgroup에 logDirection, repeating 정보 저장
                                _itemGroupObj.logInfo = {logDirection: _logDirection, repeating: _repeating};

                                //itemGroup에 포함된 items를 반복
                                // eslint-disable-next-line no-loop-func
                                _itemGroupObj.items.forEach(item => {
                                    let _itemObj = copyObject(item);
                                    let _codelistList = []; //item에 포함된 codeList ID를 보고 만들 codeList 배열

                                    //item의 componentType이 CheckBox나 DropDownList나 RadioButton(RadioButton, RadioButton (Vertical))이 포함되어 있다면
                                    if (_itemObj.componentType === "CheckBox" || _itemObj.componentType === "DropDownList" || _itemObj.componentType.includes("RadioButton")) {
                                        //item에 포함된 codelistIDs를 돌면서 codelists 배열의 ID와 비교
                                        item.codelistIDs.forEach(ID => {
                                            form.codelists.forEach(code => {
                                                let _codeObj = copyObject(code);
                                                //ID가 같다면 codelist 정보를 배열에 추가
                                                if (ID === _codeObj.ID) {
                                                    let codeListAnnotation = [];

                                                    if (_codeObj.hasOwnProperty("annotationIDs")) {
                                                        //CodeList 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                                                        _codeObj.annotationIDs.forEach(ID => {
                                                            //obj 찾는 조건이 annotation의 ID와 같고, value가 빈값이 아니고 boxColorObj가 undefined가 아니고 domain이 boxColorObj에 키값으로 있다면
                                                            let findObj = form.allAnnotations.find(annotation => {
                                                                /**
                                                                 * annotations의 path에
                                                                 * 서버에 저장된 값이 아니라면 string 으로 "formID/igID/itemID/codelistID" 이렇게오고,
                                                                 * 서버에 저장된 값은 path에 object로 key, value로 옴 ex){formID : "formID", itemGroupID: "igID", itemID: "itemID", codeListID: "codeListID"}
                                                                 *  annotations path에 type이 string 이던 object 이던 itemID를 찾아내기 위한 작업
                                                                 */
                                                                let annotationPathItemID = typeof annotation.path === 'string' ? annotation.path.split("/")[2] : annotation.path.itemID;

                                                                return annotation.ID === ID && annotationPathItemID === _itemObj.ID && annotation.value !== "" && boxColorObj !== undefined && boxColorObj.hasOwnProperty(annotation.domain.substr(-2));
                                                            });

                                                            if (findObj !== undefined) {
                                                                findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가
                                                                codeListAnnotation.push(findObj);
                                                            }
                                                        });
                                                    }

                                                    _codeObj.annotations = codeListAnnotation;
                                                    _codelistList.push(_codeObj);
                                                }
                                            });
                                        });

                                        _itemObj.codelists = _codelistList; //item에 codelists키를 추가하여 codelist 리스트를 저장
                                    }
                                    _itemObj.logInfo = _itemGroupObj.logInfo; // item에 logDirection, repeating 정보 저장

                                    // item의 comment를 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item comment 렌더링 시, 배열의 갯수를 사용함
                                    if ((_itemObj?.logInfo?.repeating === "Yes" || _itemObj?.logInfo?.repeating === undefined) && _itemObj?.comment !== "") {
                                        _itemCommentArr.push(_itemObj.comment);
                                    }

                                    _itemObj.itemCommentNum = _itemCommentArr.length;

                                    //item에 annotations 추가
                                    let itemAnnotations = []; //item에 해당하는 annotation을 저장하기 위한 배열 변수

                                    if (_itemObj.hasOwnProperty("annotationIDs")) {
                                        if (_itemObj.annotationIDs.length > 0) { //item에 annotationIDs의 길이가 0개면 추가 필요가 없음
                                            //item 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                                            _itemObj.annotationIDs.forEach(ID => {
                                                //obj 찾는 조건이 annotation의 ID와 같고, value가 빈값이 아니고 boxColorObj가 undefined가 아니고 domain이 boxColorObj에 키값으로 있다면
                                                let findObj = form.allAnnotations.find(annotation => annotation.ID === ID && annotation.value !== "" && boxColorObj !== undefined && boxColorObj.hasOwnProperty(annotation.domain.substr(-2)));

                                                if (findObj !== undefined) {
                                                    findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가

                                                    itemAnnotations.push(findObj);
                                                }
                                            });
                                        }
                                    }

                                    _itemObj.annotations = itemAnnotations;

                                    _itemList.push(_itemObj); //가공한 item 정보를 item 리스트에 저장
                                });

                                //itemGroup에 annotations 추가
                                let igAnnotations = []; //itemGroup에 해당하는 annotation을 저장하기 위한 배열 변수

                                if (_itemGroupObj.hasOwnProperty("annotationIDs")) {
                                    if (_itemGroupObj.annotationIDs.length > 0) { //itemGroup에 annotationIDs의 길이가 0개면 추가 필요가 없음
                                        //itemGroup 정보에 annotationIDs를 한개 씩 돌면서 포함된 ID를 저장
                                        // eslint-disable-next-line no-loop-func
                                        _itemGroupObj.annotationIDs.forEach(ID => {
                                            //obj 찾는 조건이 annotation의 ID와 같고, value가 빈값이 아니고 boxColorObj가 undefined가 아니고 domain이 boxColorObj에 키값으로 있다면
                                            let findObj = form.allAnnotations.find(annotation => annotation.ID === ID && annotation.value !== "" && boxColorObj !== undefined && boxColorObj.hasOwnProperty(annotation.domain.substr(-2)));

                                            if (findObj !== undefined) {
                                                findObj.background = boxColorObj[findObj.domain.substr(-2)]; //box color 추가

                                                igAnnotations.push(findObj);
                                            }
                                        });
                                    }
                                }

                                _itemGroupObj.annotations = igAnnotations;
                                _itemGroupObj.items = _itemList; //itemGroup의 items 키에 가공된 item 리스트를 저장

                                // item group의 description을 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item group description 부분 렌더링 시, 배열의 갯수를 사용함
                                if ((_itemGroupObj?.logInfo?.repeating === "Yes" || _itemGroupObj?.logInfo?.repeating === undefined) && _itemGroupObj?.description !== "") {
                                    _itemGroupDescArr.push(_itemGroupObj.description);
                                }

                                _itemGroupObj.descriptionNum = _itemGroupDescArr.length;

                                _itemGroupList.push(_itemGroupObj); //가공된 itemGroup 정보를 itmeGroup 리스트에 저장
                            }
                        }
                    });

                    studyEvent.itemGroups = _itemGroupList; //studyEvent의 itemGroups 키에 가공된 itemGroup 리스트 저장
                    studyEvent.itemGroupDesNum = _itemGroupDescArr.length;
                    studyEvent.itemCommentNum = _itemCommentArr.length;
                });

                //화면 그리기 위해 필요한 JSON 생성
                _processDataList.push({
                    ..._formObj,
                    studyEvents: _studyEventList
                });
            });
        }

        // logDirection은 form꺼 가져오고
        // repeating 은 개별로 가져오면 됨.

        _processDataList.forEach((data) => {
            data.repeating = "No";

            // const logInfo = {logDirection: data.logDirection, repeating: data.repeating}
            // const logInfo = {logDirection: data.logDirection, repeating: 'No'}

            let _logDirection = '';
            let _repeating = '';
            let _itemCommentArr = [];  // item의 comment 모은 배열 (landscape 표)
            let _itemGroupDescArr = []; // itemGroup의 description 모은 배열 (landscape 표)
            let _itemTPDDLNum = 0; // item의 question이 "Time Point" 이고, control type이 "DropDownList" 인 item 의 갯수 (landscape 표)

            // 배경색 정보 넣기
            const _annotations = [];
            let annoIdx = 0;
            data.annotationIDs.forEach((ID) => {
                data.allAnnotations.forEach((eachAnno) => {
                    if (eachAnno.ID === ID) {
                        eachAnno.background = BOX_COLOR[annoIdx];
                        _annotations.push(eachAnno);
                        if (eachAnno.type === 'FORM') {
                            annoIdx++;
                        }
                    }
                });
            });
            data.annotations = _annotations;

            data.annotations.forEach((parent) => {
                data.allAnnotations.forEach((child) => {
                    if (child.domain.includes(parent.domain)) {
                        child.background = parent.background;
                    }
                });
            });

            data.itemGroups.forEach((itemGroup) => {
                itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                for (let formVisitID in data.itemGroupVisit) { // formVisitID 비교하면서 ID가 같을 때 아래의 정보들 저장
                    if (formVisitID === itemGroup.armID) {
                        let _logDirection = data.logDirection;
                        let _repeating = itemGroup.repeating;

                        itemGroup.logInfo = {logDirection: _logDirection, repeating: _repeating};

                        itemGroup.items.forEach((item) => {
                            if (item.codelistIDs.length) {
                                item.codelists = data.codelists.filter(code => item.codelistIDs.includes(code.ID));

                                // codelistID 순서에 맞게 정렬
                                const _codelists = [];
                                item.codelistIDs.forEach((ID) => {
                                    item.codelists.forEach((code) => {
                                        if (ID === code.ID) {
                                            _codelists.push(code);
                                        }
                                    });
                                });
                                item.codelists = _codelists;

                                // codelist에 annotation 추가
                                item.codelists.forEach((code) => {
                                    const _annotations = [];
                                    data.allAnnotations.forEach((eachAnno) => {
                                        code.annotationIDs.forEach((ID) => {
                                            if (eachAnno.ID === ID) {
                                                _annotations.push(eachAnno);
                                            }
                                        });
                                    });
                                    code.annotations = _annotations;
                                });
                            }
                            item.logInfo = itemGroup.logInfo;

                            // item의 comment를 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item comment 렌더링 시, 배열의 갯수를 사용함
                            if ((item?.logInfo?.repeating === "Yes" || item?.logInfo?.repeating === undefined) && item?.comment !== "") {
                                _itemCommentArr.push(item.comment);
                            }

                            // componentType이 "DDL" 이고, item의 element 가 "TPT"를 포함하거나 "SPID"를 포함하며, item의 defaultValue가 "|" 구분자를 포함할 때
                            if (item?.componentType === "DropDownList" && item.element.includes("TPT")) {
                                if (item?.defaultValue.includes("|")) {
                                    _itemTPDDLNum += 1;
                                }
                            }

                            item.itemCommentNum = _itemCommentArr.length;
                            item.itemTPDDLNum = _itemTPDDLNum;

                            itemGroup.itemCommentNum = item.itemCommentNum;
                            itemGroup.itemTPDDLNum = item.itemTPDDLNum;

                            // item에 annotation 추가
                            const _annotations = [];
                            item.annotationIDs.forEach((ID) => {
                                data.allAnnotations.forEach((eachAnno) => {
                                    if (eachAnno.ID === ID) {
                                        _annotations.push(eachAnno);
                                    }
                                });
                            });
                            item.annotations = _annotations;
                        });
                        // item group의 description을 배열에 저장하여 StudyEvent 파일에서 landscape일 경우의 item group description 부분 렌더링 시, 배열의 갯수를 사용함
                        if ((itemGroup?.logInfo?.repeating === "Yes" || itemGroup?.logInfo?.repeating === undefined) && itemGroup?.description !== "" && itemGroup?.description !== undefined) {
                            _itemGroupDescArr.push(itemGroup.description);
                        }

                        itemGroup.itemGroupDesNum = _itemGroupDescArr.length;
                    }
                }
                itemGroup.logInfo = itemGroup.logInfo;
                data.itemCommentNum = itemGroup.itemCommentNum;
                data.itemGroupDesNum = itemGroup.itemGroupDesNum;

                data.itemTPDDLNum = itemGroup.itemTPDDLNum;
            });
        })

        // `NB_KDS_ADP1 - MAD`에 common을 기본값으로 고정
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000100', 'CRFM000000011052', 'VSDTC');
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000100', 'CRFM000000011028', 'EGDTC');
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000100', 'CRFM000000011058', 'NVDTC');

        // `NB_KDS_ADP1 - SAD`에 common을 기본값으로 고정
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000093', 'CRFM000000010488', 'VSDTC');
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000093', 'CRFM000000010464', 'EGDTC');
        _processDataList = setCommonDefaultValue(_processDataList, 'DEGN000000000093', 'CRFM000000010494', 'NVDTC');

        setProcessedDataList(_processDataList);
    }, [dataList]);

    useEffect(() => {
        if (dataList && armList) {
            // armList를 사전형으로 변환
            let _armObj = [];
            armList.forEach(e => _armObj[e.ID] = e.studySchedule);

            // 전체 arm개수가 1개인지 파악
            let _isArmSingle = false;
            const _armNameArray = [];
            armList.forEach((e) => {
                _armNameArray.push(e.studySchedule)
            })
            if ([...new Set(_armNameArray)].length === 1) {
                _isArmSingle = true
            }

            // visit 목록 추가
            const _visitList = [];
            dataList.map(e => e.formVisit).forEach((e) => {
                let _temp = [];
                let _valid = [];
                Object.keys(_armObj).forEach((key) => {
                    if (Object.keys(e).includes(key)) {
                        // arm이 1개일 경우 디자인 표시 안함, arm이 2개인데 값이 한 곳만 있을 경우 표시함
                        if (_isArmSingle) {
                            _temp.push(e[key].join(', '));
                        } else {
                            _temp.push(`${_armObj[key]} : ${e[key].join(', ')}`)
                        }
                        _valid.push(`${e[key].join(', ')}`)
                    }
                });

                // 만약 모든 visit 리스트가 동일하다면 visit 리스트 한 개만 남김
                const _isAllEqual = arr => arr.every(v => v === arr[0])
                if (_isAllEqual(_valid) && _valid.length !== 1) {
                    _temp = [_valid[0]];
                }
                _visitList.push(_temp);
            })
            setVisitList(_visitList);
        }
    }, [dataList, armList]);

    /*################################################################################*/
    //## component view 영역
    //## - JSX return
    /*################################################################################*/
    return (
        <>
            <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
            <div className="vertical-box with-grid inbox bg-light" style={{height: "calc(100% - 82px)"}}>
                <div className="vertical-box-column bg-white">
                    <div className="vertical-box">
                        <div className="vertical-box-row">
                            <div className="vertical-box-cell">
                                <div className="vertical-box-inner-cell">
                                    <div className="row p-30">
                                        <div className="col-3 px-3 border-right">
                                            <h5 className={"text-grey-darker"} style={{lineHeight: '2'}}>Form List</h5>
                                            <div className="m-t-20">
                                                <PerfectScrollbar
                                                    options={{suppressScrollX: true}}
                                                    className="p-10"
                                                    style={{height: 'calc(100vh - 250px'}}>
                                                    <DnDList
                                                        readOnly={true}
                                                        list={formList}
                                                        onClickItem={handleClickItem}
                                                        selectedItem={selectedForm}/>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>

                                        <div className={"col-9"}>
                                            <PerfectScrollbar
                                                id={"form-container"}
                                                options={{suppressScrollX: true}}
                                                style={{height: 'calc(100vh - 250px'}}>
                                                <div id={"acrf-main-area"}
                                                     style={{padding: "0 30px 0 30px"}}>
                                                    <ACRFCover
                                                        display={selectedForm === 'ACRFCover' ? 'block' : 'none'}
                                                        coverInfo={coverInfo}/>
                                                    <div
                                                        style={{display: `${selectedForm === 'eCRFSchedule' ? 'block' : 'none'}`}}>
                                                        <ECRFSchedule dataList={eScheduleList}/>
                                                        {/*<SofTable dataList={eScheduleList}/>*/}
                                                    </div>

                                                    <ACRFTableOfContents
                                                        display={selectedForm === 'ACRFTableOfContents' ? 'block' : 'none'}
                                                        formContentsList={formContentsList}
                                                        visitContentsList={visitContentsList}/>

                                                    {getFormComp()}
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between border-top bg-light p-10">
                            <button
                                className="btn btn-list btn-inner-shadow px-3 px-md-5 mx-1"
                                onClick={goToList}>
                                List
                            </button>

                            <div>
                                {!isEdit ? (
                                    <>
                                        <button
                                            className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                                            onClick={() => handleDownload("paging")}>
                                            Page add to Define
                                        </button>

                                        <button
                                            className="btn btn-lock btn-inner-shadow px-3 px-md-5 mx-1"
                                            onClick={() => handleDownload("download")}>
                                            Download
                                        </button>

                                        <button
                                            className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                            onClick={handleEdit}>
                                            Edit
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className={cn("btn btn-new btn-inner-shadow px-3 px-md-5 mx-1", {'disabled': lock === 2})}
                                        onClick={handleSave}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(ACRFMain);